import * as ActionPagination from '../../../core/action/Pagination/ActionPagination';
import * as ActionRestoreData from '../../../core/action/RestoreData/ActionRestoreData';
import * as toggle from '../../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import FormRestoreDataComponent from '../../../components/Content/RestoreData/FormRestoreDataComponent';
import { connect } from 'react-redux';

interface IProps {
  actionRestoreData: any;
  UploadDate: any;
  UploadDateW: any;
  RadioButton: any;
  RadioButtonW: any;
  LastAccess: any;
  actionToggle: any;
  actionPagination: any;
}
interface IState {
  ValidateUplaodDate: any;
  ValidateUplaodDateW: any;
}
class FormRestoreDataContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateUplaodDate: null,
      ValidateUplaodDateW: null,
    };
    this.handleValidateUploadDate = this.handleValidateUploadDate.bind(this);
    this.handleValidateUploadDateW = this.handleValidateUploadDateW.bind(this);
    this.handlePutUploadDate = this.handlePutUploadDate.bind(this);
    this.handlePutUploadDateW = this.handlePutUploadDateW.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.handleChangeRadioButton = this.handleChangeRadioButton.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  handleValidateUploadDate(event: any) {
    this.setState({
      ValidateUplaodDate: event,
    });
    if (this.state.ValidateUplaodDate === true) {
      if (
        this.props.UploadDate === null ||
        this.props.UploadDate === undefined ||
        this.props.UploadDate === 'Invalid date'
      ) {
        this.setState({
          ValidateUplaodDate: 'error',
        });
      } else {
        this.setState({
          ValidateUplaodDate: null,
        });
      }
    } else {
    }
  }

  handlePutUploadDate(event: any) {
    this.props.actionRestoreData.setUploadDateRestoreData(event);
    if (event === '' || event === null) {
      this.setState({
        ValidateUplaodDate: 'error',
      });
    } else {
      this.setState({
        ValidateUplaodDate: null,
      });
    }
  }

  handleValidateUploadDateW(event: any) {
    this.setState({
      ValidateUplaodDateW: event,
    });
    if (this.state.ValidateUplaodDateW === true) {
      if (
        this.props.UploadDateW === null ||
        this.props.UploadDateW === undefined ||
        this.props.UploadDateW === 'Invalid date'
      ) {
        this.setState({
          ValidateUplaodDateW: 'error',
        });
      } else {
        this.setState({
          ValidateUplaodDateW: null,
        });
      }
    } else {
    }
  }

  handlePutUploadDateW(event: any) {
    this.props.actionRestoreData.setUploadDateWRestoreData(event);
    if (event === '' || event === null) {
      this.setState({
        ValidateUplaodDateW: 'error',
      });
    } else {
      this.setState({
        ValidateUplaodDateW: null,
      });
    }
  }

  handleModal() {
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle('RestoreDataByWaybill');
  }

  handleChangeRadioButton(event) {
    if (
      this.props.LastAccess === null ||
      this.props.LastAccess === 'restoreDataCsv'
    ) {
      this.props.actionRestoreData.setRadioRestoreData(event.target.value);
      this.props.actionPagination.setPagePagination(1);
      if (this.props.UploadDate !== null) {
        this.handleGetData();
      }
    } else {
      this.props.actionPagination.setPagePagination(1);
      this.props.actionRestoreData.setRadioRestoreDataW(event.target.value);
      if (this.props.UploadDateW !== null) {
        this.handleGetData();
      }
    }
  }

  handleGetData() {
    if (
      this.props.LastAccess === null ||
      this.props.LastAccess === 'restoreDataCsv'
    ) {
      this.props.actionToggle.changeJenisToggle('searchMenuHapusRestore');
      this.props.actionRestoreData.clearListRestoreData();
      this.props.actionRestoreData.submitGetDataRestoreData();
    } else {
      this.props.actionToggle.changeJenisToggle('searchMenuHapusRestore');
      this.props.actionRestoreData.clearListWaybillRestoreData();
      this.props.actionRestoreData.submitGetDataRestoreData();
    }
  }

  render() {
    return (
      <FormRestoreDataComponent
        lastAccess={this.props.LastAccess}
        uploadDate={this.props.UploadDate}
        uploadDateW={this.props.UploadDateW}
        radioButton={this.props.RadioButton}
        radioButtonW={this.props.RadioButtonW}
        validateUploadDate={
          this.state.ValidateUplaodDate === 'error' ? 'error' : ''
        }
        validateUploadDateW={
          this.state.ValidateUplaodDateW === 'error' ? 'error' : ''
        }
        helpValidateUploadDate={
          this.state.ValidateUplaodDate === 'error' ? 'Tanggal wajib diisi' : ''
        }
        helpValidateUploadDateW={
          this.state.ValidateUplaodDateW === 'error'
            ? 'Tanggal wajib diisi'
            : ''
        }
        handleValidateUploadDate={this.handleValidateUploadDate}
        handleValidateUploadDateW={this.handleValidateUploadDateW}
        handleChangeRadioButton={this.handleChangeRadioButton}
        handleGetData={this.handleGetData}
        handlePutUploadDate={this.handlePutUploadDate}
        handlePutUploadDateW={this.handlePutUploadDateW}
        handleModal={this.handleModal}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  UploadDate: state.RestoreData.FormRestoreData.UploadDate,
  UploadDateW: state.RestoreData.FormRestoreData.UploadDateW,
  RadioButton: state.RestoreData.FormRestoreData.RadioButton,
  RadioButtonW: state.RestoreData.FormRestoreData.RadioButtonW,
  LastAccess: state.LastAccess.Fiture,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionRestoreData: bindActionCreators(ActionRestoreData, dispatch),
  actionToggle: bindActionCreators(toggle, dispatch),
  actionPagination: bindActionCreators(ActionPagination, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FormRestoreDataContainer);
