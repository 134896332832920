import * as ActionComplaintReport from '../Store/ComplaintReportAction';
import * as ActionGeneralData from '../../../App/Store/GeneralDataAction';
import * as SelectorsComplaintReport from '../Selector/ComplaintReportSelector';
import * as SelectorsComponent from '../../../App/Selector/AppSelector';
import * as SelectorsGeneralData from '../../../App/Selector/GeneralDataSelector';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { change, getFormSyncErrors, getFormValues } from 'redux-form';
import { connect, useDispatch } from 'react-redux';

import ComplaintReportComponent from '../Component/ComplaintReportComponent';
import { createStructuredSelector } from 'reselect';

function ComplaintReportContainer(props: any) {
  const { actionGeneralData, actionComplaintReport } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(change('complaintReportForm', 'complaintStatus', [1]));
    dispatch(change('complaintReportForm', 'dateType', 'cycle'));
    dispatch(change('complaintReportForm', 'product', []));
    dispatch(change('complaintReportForm', 'allCustomer', false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleGetCustomerList = () => {
    actionGeneralData.getCustomerList();
  };
  const handleChangeCustomerName = (event, label) => {
    actionComplaintReport.changeCustomerName(label.props.children);
    dispatch(change('complaintReportForm', 'product', []));
  };
  const handleGetCustomerProductList = () => {
    actionComplaintReport.getCustomerProductList();
  };
  const handleDownload = () => {
    actionComplaintReport.downloadComplaintReport();
  };
  const handleClearForm = () => {
    actionComplaintReport.clearComplaintReportForm();
    dispatch(change('complaintReportForm', 'complaintStatus', [1]));
    dispatch(change('complaintReportForm', 'dateType', 'cycle'));
    dispatch(change('complaintReportForm', 'product', []));
  };
  const handleClearSelectedProduct = () => {
    dispatch(change('complaintReportForm', 'customer', null));
    dispatch(change('complaintReportForm', 'product', []));
  };
  return (
    <React.Fragment>
      <ComplaintReportComponent
        handleGetCustomerList={handleGetCustomerList}
        handleChangeCustomerName={handleChangeCustomerName}
        handleGetCustomerProductList={handleGetCustomerProductList}
        handleDownload={handleDownload}
        handleClearForm={handleClearForm}
        handleClearSelectedProduct={handleClearSelectedProduct}
        {...props}
      />
    </React.Fragment>
  );
}

const mapStateToProps = () =>
  createStructuredSelector({
    isLoading: SelectorsComponent.makeIsLoading(),
    customerList: SelectorsGeneralData.makeCustomerListSelector(),
    customerProductList:
      SelectorsComplaintReport.makeCustomerProductComplaintReportListSelector(),
    formValue: getFormValues('complaintReportForm'),
    errorFormValue: getFormSyncErrors('complaintReportForm'),
  });
const mapDispatchToProps = dispatch => ({
  actionComplaintReport: bindActionCreators(ActionComplaintReport, dispatch),
  actionGeneralData: bindActionCreators(ActionGeneralData, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ComplaintReportContainer);
