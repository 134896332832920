import React from 'react';
import FormLevelGroup from 'components/FormLevelGroup';

function DetailLevelFormComponent(props) {
  return (
    <FormLevelGroup
      handleBtnBack={props.handleBtnBack}
      dataAllMenus={props.dataAllMenus}
      dataLevel={props.dataLevel}
      isView
      isLoadingMenus={props.isLoadingMenus}
    />
  );
}

export default DetailLevelFormComponent;
