import * as ActionAddCustomCsvCustomer from '../../../core/action/Customer/ActionAddCustomCsvCustomer';
import * as ActionCustomCsvCustomer from '../../../core/action/Customer/ActionSetCustomCsvCustomer';
import * as ActionDetailCustomCsvCustomer from '../../../core/action/Customer/ActionDetailCustomCsvCustomer';
import * as ActionEditCustomCsvCustomer from '../../../core/action/Customer/ActionEditCustomCsvCustomer';
import * as ActionLastAccess from '../../../core/action/LastAccess';
import * as ActionNavigation from '../../../core/action/Router/ActionRouter';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import FormCsvCustomerComponent from '../../../components/Content/Customer/FormCsvCustomerComponent';
import { Modal } from 'antd';
import { connect } from 'react-redux';

interface IProps {
  actionLastAccess: any;
  actionCustomCsvCustomer: any;
  actionAddCustomCsvCustomer: any;
  actionEditCustomCsvCustomer: any;
  actionDetailCustomCsvCustomer: any;
  actionNavigation: any;
  FileSettingName: any;
  DelimitedCsv: any;
  FileType: any;
  StatusDefault: any;
  ReverseBarcode: any;
  CycleCsv: any;
  FormatCycleCsv: any;
  CityCsv: any;
  AddressCsv: any;
  ListAddressCsv: any;
  ListAddressOffice: any;
  BarcodeCsv: any;
  OwnerNameCsv: any;
  PostalCodeCsv: any;
  NoTelpCsv: any;
  CallLinkCsv: any;
  AdditionalDataCsv: any;
  ListAdditionalDataCsv: any;
  TypeFormCsv: any;
  CustomerNameCsv: any;
  GroupingNameCsv: any;
  SearchTypeCsv: any;
  SearchKeyCsv: any;
  CheckboxCallCenter: any;
  Waybill: any;
  AddressUpdate: any;
}
interface IState {
  ValidateDelimitedCsv: any;
  ValidateFileType: any;
  ValidateFileSettingNameCsv: any;
  ValidateCycleCsv: any;
  ValidateFormatCycleCsv: any;
  ValidateCityCsv: any;
  ValidateAddressCsv: any;
  ValidateBarcodeCsv: any;
  ValidateOwnerNameCsv: any;
  ValidatePostalCodeCsv: any;
  ValidateNoTelpCsv: any;
  ValidateGroupingDelivery: any;
  ValidateWaybill: any;
  ValidateAddressUpdate: any;
  CheckboxCycle: any;
}
class FormCsvCustomerContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateDelimitedCsv: null,
      ValidateFileType: null,
      ValidateFileSettingNameCsv: null,
      ValidateCycleCsv: null,
      ValidateFormatCycleCsv: null,
      ValidateCityCsv: null,
      ValidateAddressCsv: null,
      ValidateBarcodeCsv: null,
      ValidateOwnerNameCsv: null,
      ValidatePostalCodeCsv: null,
      ValidateNoTelpCsv: null,
      ValidateGroupingDelivery: null,
      CheckboxCycle: false,
      ValidateWaybill: null,
      ValidateAddressUpdate: null,
    };
    this.handleStatusDefaultCsv = this.handleStatusDefaultCsv.bind(this);
    this.handleReverseBarcodeCsv = this.handleReverseBarcodeCsv.bind(this);
    this.handleChangeDelimitedCsv = this.handleChangeDelimitedCsv.bind(this);
    this.handleChangeFileType = this.handleChangeFileType.bind(this);
    this.handleChangeFileSettingName =
      this.handleChangeFileSettingName.bind(this);
    this.handleChangeCycleCsv = this.handleChangeCycleCsv.bind(this);
    this.handleChangeFormatCycleCsv =
      this.handleChangeFormatCycleCsv.bind(this);
    this.handleChangeCityCsv = this.handleChangeCityCsv.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
    this.handleChangeAddressOffice = this.handleChangeAddressOffice.bind(this);
    this.handleChangeBarcodeCsv = this.handleChangeBarcodeCsv.bind(this);
    this.handleChangeOwnerNameCsv = this.handleChangeOwnerNameCsv.bind(this);
    this.handleChangePostalCodeCsv = this.handleChangePostalCodeCsv.bind(this);
    this.handleChangeNoTelpCsv = this.handleChangeNoTelpCsv.bind(this);
    this.handleChangeCallLinkCsv = this.handleChangeCallLinkCsv.bind(this);
    this.handleChangeAdditionalDataCsv =
      this.handleChangeAdditionalDataCsv.bind(this);
    this.handleChangeGroupNameCsv = this.handleChangeGroupNameCsv.bind(this);
    this.handleBlurDelimitedCsv = this.handleBlurDelimitedCsv.bind(this);
    this.handleBlurFileType = this.handleBlurFileType.bind(this);
    this.handleChangeSearchTypeCsv = this.handleChangeSearchTypeCsv.bind(this);
    this.handleChangeSearchKeyCsv = this.handleChangeSearchKeyCsv.bind(this);
    this.handleEditCsv = this.handleEditCsv.bind(this);
    this.handleAddAddress = this.handleAddAddress.bind(this);
    this.handleAddAddressOffice = this.handleAddAddressOffice.bind(this);
    this.handleAddAdditionalData = this.handleAddAdditionalData.bind(this);
    this.handleCheckboxCycle = this.handleCheckboxCycle.bind(this);
    this.handleRemoveAddressCsv = this.handleRemoveAddressCsv.bind(this);
    this.handleRemoveAddressOffice = this.handleRemoveAddressOffice.bind(this);
    this.handleRemoveAdditionalDataCsv =
      this.handleRemoveAdditionalDataCsv.bind(this);
    this.handleChangeCheckboxCallCenter =
      this.handleChangeCheckboxCallCenter.bind(this);
    this.handleChangeWaybillCsv = this.handleChangeWaybillCsv.bind(this);
    this.handleChangeAddressUpdateCsv =
      this.handleChangeAddressUpdateCsv.bind(this);
    this.handleSubmiteAddressCsv = this.handleSubmiteAddressCsv.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  handleBack() {
    this.props.actionLastAccess.changeClearLastAccessFiture();
    this.props.actionCustomCsvCustomer.clearAllDataCustomCsvCustomer();
  }
  handleCancel() {
    this.props.actionDetailCustomCsvCustomer.getDetailCustomCsvCustomer();
  }
  handleStatusDefaultCsv(event: any) {
    this.props.actionCustomCsvCustomer.setStatusDefaultCsv(
      event.target.checked
    );
  }
  handleReverseBarcodeCsv(event: any) {
    this.props.actionCustomCsvCustomer.setReverseBarcodeCsv(
      event.target.checked
    );
  }
  handleChangeDelimitedCsv(event: any) {
    this.props.actionCustomCsvCustomer.setDelimitedCsv(event);
    this.setState({
      ValidateDelimitedCsv: '',
    });
  }
  handleChangeFileType(event: any) {
    this.props.actionCustomCsvCustomer.changeFileType(event);
    this.setState({
      ValidateFileType: '',
    });
    if (event === 'Excel' || 'ExcelXLSX') {
      this.props.actionCustomCsvCustomer.setDelimitedCsv(null);
      this.setState({
        ValidateDelimitedCsv: '',
      });
    }
  }
  handleChangeFileSettingName(event: any) {
    this.props.actionCustomCsvCustomer.setFileSettingNameCsv(
      event.target.value.replace(/^\s/, '')
    );

    if (event.target.value === '') {
      this.setState({
        ValidateFileSettingNameCsv: 'error',
      });
    } else if (event.target.value.length < 4) {
      this.setState({
        ValidateFileSettingNameCsv: 'notMinChar',
      });
    } else {
      this.setState({
        ValidateFileSettingNameCsv: '',
      });
    }
  }
  handleChangeCycleCsv(event: any) {
    if (event.target.value.startsWith(' ') === true) {
      this.props.actionCustomCsvCustomer.setCycleCsv(event.target.value.trim());
    } else {
      this.props.actionCustomCsvCustomer.setCycleCsv(event.target.value);
    }
    if (event.target.value === '') {
      this.setState({
        ValidateCycleCsv: 'error',
      });
    } else if (event.target.value.length < 4) {
      this.setState({
        ValidateCycleCsv: 'notMinChar',
      });
    } else {
      this.setState({
        ValidateCycleCsv: '',
      });
    }
  }
  handleChangeFormatCycleCsv(event: any) {
    if (event.target.value.startsWith(' ') === true) {
      this.props.actionCustomCsvCustomer.setFormatCycleCsv(
        event.target.value.trim()
      );
    } else {
      this.props.actionCustomCsvCustomer.setFormatCycleCsv(event.target.value);
    }
    if (event.target.value === '') {
      this.setState({
        ValidateFormatCycleCsv: 'error',
      });
    } else {
      this.setState({
        ValidateFormatCycleCsv: '',
      });
    }
  }
  handleCheckboxCycle(event: any) {
    this.setState({
      CheckboxCycle: event.target.checked,
    });
    this.setState({
      ValidateCycleCsv: null,
    });
    if (event.target.checked === true) {
      this.props.actionCustomCsvCustomer.clearCycleCsv();
    } else if (event.target.checked === false) {
      this.props.actionCustomCsvCustomer.setCycleCsv('');
    }
  }

  validateCheckbox = (props: any) => {
    let checkboxValue = false;

    if (props.TypeFormCsv === 'detail') {
      if (props.CycleCsv === null) {
        return (checkboxValue = true);
      } else {
        return (checkboxValue = false);
      }
    } else if (props.TypeFormCsv === 'edit') {
      if (props.CycleCsv === null) {
        this.setState({
          CheckboxCycle: true,
        });
        this.setState({
          ValidateCycleCsv: null,
        });
        return (checkboxValue = true);
      } else if (props.CycleCsv !== null) {
        this.setState({
          CheckboxCycle: false,
        });
        this.setState({
          ValidateCycleCsv: null,
        });
        return (checkboxValue = this.state.CheckboxCycle);
      }
    } else if (props.TypeFormCsv === 'add') {
      return (checkboxValue = this.state.CheckboxCycle);
    }
    return checkboxValue;
  };

  handleChangeCityCsv(event: any) {
    if (event.target.value.startsWith(' ') === true) {
      this.props.actionCustomCsvCustomer.setCityCsv(event.target.value.trim());
    } else {
      this.props.actionCustomCsvCustomer.setCityCsv(event.target.value);
    }
    if (event.target.value === '') {
      this.setState({
        ValidateCityCsv: 'error',
      });
    } else if (event.target.value.length < 4) {
      this.setState({
        ValidateCityCsv: 'notMinChar',
      });
    } else {
      this.setState({
        ValidateCityCsv: '',
      });
    }
  }

  handleChangeAddress(e: any, index: any) {
    const valueAddress = e.target.value;
    if (valueAddress.startsWith(' ') === true) {
      this.props.ListAddressCsv[index] = valueAddress.trim();
    } else {
      this.props.ListAddressCsv[index] = valueAddress;
    }

    this.props.actionCustomCsvCustomer.setListAddressCsv(
      this.props.ListAddressCsv
    );
    this.props.actionCustomCsvCustomer.setAddressCsv(
      this.props.ListAddressCsv.join(';')
    );
  }

  handleChangeAddressOffice(e: any, index: any) {
    const valueAddress = e.target.value;
    const value = [...this.props.ListAddressOffice];
    if (valueAddress.startsWith(' ') === true) {
      value[index] = valueAddress.trim();
    } else {
      value[index] = valueAddress;
    }

    this.props.actionCustomCsvCustomer.setListAddressOffice(value);
    this.props.actionCustomCsvCustomer.setAddressOffice(
      this.props.ListAddressOffice.join(';')
    );
  }

  handleChangeAdditionalDataCsv(e: any, index: any) {
    const valueAdditionalData = e.target.value;
    if (valueAdditionalData.startsWith(' ') === true) {
      this.props.ListAdditionalDataCsv[index] = valueAdditionalData.trim();
    } else {
      this.props.ListAdditionalDataCsv[index] = valueAdditionalData;
    }

    this.props.actionCustomCsvCustomer.setListAdditionalDataCsv(
      this.props.ListAdditionalDataCsv
    );
    this.props.actionCustomCsvCustomer.setAdditionalDataCsv(
      this.props.ListAdditionalDataCsv.join(';')
    );
  }
  handleChangeBarcodeCsv(event: any) {
    if (event.target.value.startsWith(' ') === true) {
      this.props.actionCustomCsvCustomer.setBarcodeCsv(
        event.target.value.trim()
      );
    } else {
      this.props.actionCustomCsvCustomer.setBarcodeCsv(event.target.value);
    }
    if (event.target.value === '') {
      this.setState({
        ValidateBarcodeCsv: 'error',
      });
    } else if (event.target.value.length < 4) {
      this.setState({
        ValidateBarcodeCsv: 'notMinChar',
      });
    } else {
      this.setState({
        ValidateBarcodeCsv: '',
      });
    }
  }
  handleChangeOwnerNameCsv(event: any) {
    if (event.target.value.startsWith(' ') === true) {
      this.props.actionCustomCsvCustomer.setOwnerNameCsv(
        event.target.value.trim()
      );
    } else {
      this.props.actionCustomCsvCustomer.setOwnerNameCsv(event.target.value);
    }
    if (event.target.value === '') {
      this.setState({
        ValidateOwnerNameCsv: 'error',
      });
    } else if (event.target.value.length < 4) {
      this.setState({
        ValidateOwnerNameCsv: 'notMinChar',
      });
    } else {
      this.setState({
        ValidateOwnerNameCsv: '',
      });
    }
  }
  handleChangePostalCodeCsv(event: any) {
    if (event.target.value.startsWith(' ') === true) {
      this.props.actionCustomCsvCustomer.setPostalCodeCsv(
        event.target.value.trim()
      );
    } else {
      this.props.actionCustomCsvCustomer.setPostalCodeCsv(event.target.value);
    }
    if (event.target.value === '') {
      this.setState({
        ValidatePostalCodeCsv: 'error',
      });
    } else if (event.target.value.length < 4) {
      this.setState({
        ValidatePostalCodeCsv: 'notMinChar',
      });
    } else {
      this.setState({
        ValidatePostalCodeCsv: '',
      });
    }
  }
  handleChangeGroupNameCsv(event: any) {
    if (event.target.value.startsWith(' ') === true) {
      this.props.actionCustomCsvCustomer.setGroupName(
        event.target.value.trim()
      );
      if (event.target.value.length < 4) {
        this.setState({
          ValidateGroupingDelivery: 'notMinChar',
        });
      } else {
        this.setState({
          ValidateGroupingDelivery: '',
        });
      }
    } else {
      this.props.actionCustomCsvCustomer.setGroupName(event.target.value);
      if (event.target.value.length < 4) {
        this.setState({
          ValidateGroupingDelivery: 'notMinChar',
        });
      } else {
        this.setState({
          ValidateGroupingDelivery: '',
        });
      }
    }
  }
  handleChangeCallLinkCsv(event: any) {
    if (event.target.value.startsWith(' ') === true) {
      this.props.actionCustomCsvCustomer.setCallLinkCsv(
        event.target.value.trim()
      );
    } else {
      this.props.actionCustomCsvCustomer.setCallLinkCsv(event.target.value);
    }
  }
  handleChangeNoTelpCsv(event: any) {
    if (event.target.value.startsWith(' ') === true) {
      this.props.actionCustomCsvCustomer.setNoTelpCsv(
        event.target.value.trim()
      );
    } else {
      this.props.actionCustomCsvCustomer.setNoTelpCsv(event.target.value);
    }
    if (event.target.value === '') {
      this.setState({
        ValidateNoTelpCsv: 'error',
      });
    } else if (event.target.value.length < 4) {
      this.setState({
        ValidateNoTelpCsv: 'notMinChar',
      });
    } else {
      this.setState({
        ValidateNoTelpCsv: '',
      });
    }
  }
  handleBlurDelimitedCsv() {
    if (this.props.DelimitedCsv == null) {
      this.setState({
        ValidateDelimitedCsv: 'error',
      });
    } else {
      this.setState({
        ValidateDelimitedCsv: '',
      });
    }
  }
  handleBlurFileType() {
    if (this.props.FileType == null) {
      this.setState({
        ValidateFileType: 'error',
      });
    } else {
      this.setState({
        ValidateFileType: '',
      });
    }
  }
  handleChangeSearchTypeCsv(event: any) {
    if (event === null) {
      this.props.actionCustomCsvCustomer.setSearchTypeCsv('Empty');
    } else {
      this.props.actionCustomCsvCustomer.setSearchTypeCsv(event);
    }
  }
  handleChangeSearchKeyCsv(event: any) {
    if (event.target.value.startsWith(' ') === true) {
      this.props.actionCustomCsvCustomer.setSearchKeyCsv(
        event.target.value.trim()
      );
    } else {
      this.props.actionCustomCsvCustomer.setSearchKeyCsv(event.target.value);
    }
  }
  handleAddAddress() {
    this.props.actionCustomCsvCustomer.setListAddressCsv([
      ...this.props.ListAddressCsv,
      '',
    ]);
  }
  handleAddAddressOffice() {
    this.props.actionCustomCsvCustomer.setListAddressOffice([
      ...this.props.ListAddressOffice,
      '',
    ]);
  }
  handleAddAdditionalData() {
    this.props.actionCustomCsvCustomer.setListAdditionalDataCsv([
      ...this.props.ListAdditionalDataCsv,
      '',
    ]);
  }
  handleRemoveAddressCsv(event: any) {
    this.props.actionCustomCsvCustomer.setListAddressCsv(
      this.props.ListAddressCsv.splice(event, 1)
    );
    if (event > 0) {
      this.props.actionCustomCsvCustomer.setListAddressCsv(
        this.props.ListAddressCsv
      );
    } else if (event === 0 && this.props.ListAddressCsv.length > 1) {
      this.props.actionCustomCsvCustomer.setListAddressCsv(
        this.props.ListAddressCsv
      );
    } else if (event === 0 && this.props.ListAddressCsv.length === 0) {
      this.props.actionCustomCsvCustomer.setListAddressCsv(
        this.props.ListAddressCsv.splice(event, 1)
      );
    }
    this.props.actionCustomCsvCustomer.setAddressCsv(
      this.props.ListAddressCsv.join(';')
    );
  }
  handleRemoveAddressOffice(event: any) {
    this.props.actionCustomCsvCustomer.setListAddressOffice(
      this.props.ListAddressOffice.splice(event, 1)
    );
    if (event > 0) {
      this.props.actionCustomCsvCustomer.setListAddressOffice(
        this.props.ListAddressOffice
      );
    } else if (event === 0 && this.props.ListAddressOffice.length > 1) {
      this.props.actionCustomCsvCustomer.setListAddressOffice(
        this.props.ListAddressOffice
      );
    } else if (event === 0 && this.props.ListAddressOffice.length === 0) {
      this.props.actionCustomCsvCustomer.setListAddressOffice(
        this.props.ListAddressOffice.splice(event, 1)
      );
    }
    this.props.actionCustomCsvCustomer.setAddressOffice(
      this.props.ListAddressOffice.join(';')
    );
  }

  handleRemoveAdditionalDataCsv(event: any) {
    this.props.actionCustomCsvCustomer.setListAdditionalDataCsv(
      this.props.ListAdditionalDataCsv.splice(event, 1)
    );
    if (event > 0) {
      this.props.actionCustomCsvCustomer.setListAdditionalDataCsv(
        this.props.ListAdditionalDataCsv
      );
      this.props.actionCustomCsvCustomer.setAdditionalDataCsv(
        this.props.ListAdditionalDataCsv.join(';')
      );
    } else if (event === 0 && this.props.ListAdditionalDataCsv.length > 1) {
      this.props.actionCustomCsvCustomer.setListAdditionalDataCsv(
        this.props.ListAdditionalDataCsv
      );
      this.props.actionCustomCsvCustomer.setAdditionalDataCsv(
        this.props.ListAdditionalDataCsv.join(';')
      );
    } else if (event === 0 && this.props.ListAdditionalDataCsv.length === 0) {
      this.props.actionCustomCsvCustomer.clearListAdditionalDataCsv();
      this.props.actionCustomCsvCustomer.clearAdditionalDataCsv();
    }
  }
  handleChangeCheckboxCallCenter(event: any) {
    this.props.actionCustomCsvCustomer.setCheckboxCallCenterCsv(
      event.target.checked
    );
    this.props.actionCustomCsvCustomer.setWaybillCsv(null);
    this.props.actionCustomCsvCustomer.setAdrressUpdateCsv(null);
    this.setState({
      ValidateWaybill: '',
      ValidateAddressUpdate: '',
    });
  }
  handleChangeWaybillCsv(event: any) {
    this.props.actionCustomCsvCustomer.setWaybillCsv(event.target.value);
    if (event.target.value.length < 4) {
      this.setState({
        ValidateWaybill: 'error',
      });
    } else {
      this.setState({
        ValidateWaybill: '',
      });
    }
  }
  handleChangeAddressUpdateCsv(event: any) {
    this.props.actionCustomCsvCustomer.setAdrressUpdateCsv(event.target.value);
    if (event.target.value.length < 4) {
      this.setState({
        ValidateAddressUpdate: 'error',
      });
    } else {
      this.setState({
        ValidateAddressUpdate: '',
      });
    }
  }
  handleEditCsv() {
    this.props.actionCustomCsvCustomer.setTypeFormCsv('edit');
  }
  handleSubmiteAddressCsv(e: any) {
    if (
      this.props.ListAddressCsv.indexOf('') >= 0 &&
      this.props.ListAdditionalDataCsv.indexOf('') >= 0
    ) {
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            Kolom Input Alamat Dan Data Tambahan Tidak Boleh Kosong!!!
          </p>
        ),
        content: (
          <p style={{ color: '#C0C0C0' }}>Silakan isi atau hapus kolom input</p>
        ),
      });
    } else if (this.props.ListAdditionalDataCsv.indexOf('') >= 0) {
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            Kolom Input Data Tambahan Tidak Boleh Kosong!!!
          </p>
        ),
        content: (
          <p style={{ color: '#C0C0C0' }}>Silakan isi atau hapus kolom input</p>
        ),
      });
    } else if (this.props.ListAddressCsv.indexOf('') >= 0) {
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            Kolom Input Alamat Tidak Boleh Kosong!!!
          </p>
        ),
        content: (
          <p style={{ color: '#C0C0C0' }}>Silakan isi atau hapus kolom input</p>
        ),
      });
    } else if (
      this.props.ListAddressCsv.indexOf('') === -1 &&
      this.props.ListAdditionalDataCsv.indexOf('') === -1
    ) {
      let AdditionalDataCsv = this.props.ListAdditionalDataCsv;
      let AddressCsv = this.props.ListAddressCsv;
      let AddressOffice = this.props.ListAddressOffice;
      let resultAdditionalDataCsv = false;
      let resultAddressCsv = false;
      let resultAddressOffice = false;

      const additionaldatacsv = new Set(AdditionalDataCsv);
      const addresscsv = new Set(AddressCsv);
      const addressoffice = new Set(AddressOffice);

      if (
        AddressCsv.length !== addresscsv.size &&
        AdditionalDataCsv.length !== additionaldatacsv.size
      ) {
        resultAddressCsv = true;
        resultAdditionalDataCsv = true;
      } else if (AdditionalDataCsv.length !== additionaldatacsv.size) {
        resultAdditionalDataCsv = true;
      } else if (AddressCsv.length !== addresscsv.size) {
        resultAddressCsv = true;
      } else if (AddressOffice.length !== addressoffice.size) {
        resultAddressOffice = true;
      }

      if (resultAddressCsv === true && resultAdditionalDataCsv === true) {
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              Kolom Alamat Dan Kolom Data Tambahan Tidak Boleh Ada Yang Sama!!!
            </p>
          ),
          content: (
            <p style={{ color: '#C0C0C0' }}>
              Silakan ubah atau hapus kolom input
            </p>
          ),
        });
      } else if (resultAdditionalDataCsv === true) {
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              Kolom Data Tambahan Tidak Boleh Ada Yang Sama!!!
            </p>
          ),
          content: (
            <p style={{ color: '#C0C0C0' }}>
              Silakan ubah atau hapus kolom input
            </p>
          ),
        });
      } else if (resultAddressCsv === true) {
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              Kolom Alamat Tidak Boleh Ada Yang Sama!!!
            </p>
          ),
          content: (
            <p style={{ color: '#C0C0C0' }}>
              Silakan ubah atau hapus kolom input
            </p>
          ),
        });
      } else if (resultAddressOffice === true) {
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              Kolom Alamat Kantor Tidak Boleh Ada Yang Sama!!!
            </p>
          ),
          content: (
            <p style={{ color: '#C0C0C0' }}>
              Silakan ubah atau hapus kolom input
            </p>
          ),
        });
      } else {
        this.props.TypeFormCsv === 'edit'
          ? this.props.actionEditCustomCsvCustomer.submitEditCustomCsvCustomer()
          : this.props.actionAddCustomCsvCustomer.submitAddCustomCsvCustomer();
      }
    }
  }
  componentWillUnmount() {
    if (this.props.CycleCsv === null) {
      this.setState({
        CheckboxCycle: true,
      });
    }
  }

  componentWillReceiveProps(nextProps: Readonly<IProps>): void {
    this.validateCheckbox(nextProps);
  }

  render() {
    return (
      <div>
        <FormCsvCustomerComponent
          handleChangeFileSettingName={this.handleChangeFileSettingName}
          handleReverseBarcodeCsv={this.handleReverseBarcodeCsv}
          handleStatusDefaultCsv={this.handleStatusDefaultCsv}
          handleChangeDelimitedCsv={this.handleChangeDelimitedCsv}
          handleChangeCycleCsv={this.handleChangeCycleCsv}
          handleChangeFormatCycleCsv={this.handleChangeFormatCycleCsv}
          handleChangeCityCsv={this.handleChangeCityCsv}
          handleChangeAddress={this.handleChangeAddress}
          handleChangeAddressOffice={this.handleChangeAddressOffice}
          handleChangeBarcodeCsv={this.handleChangeBarcodeCsv}
          handleChangeOwnerNameCsv={this.handleChangeOwnerNameCsv}
          handleChangePostalCodeCsv={this.handleChangePostalCodeCsv}
          handleChangeNoTelpCsv={this.handleChangeNoTelpCsv}
          handleChangeCallLinkCsv={this.handleChangeCallLinkCsv}
          handleChangeAdditionalDataCsv={this.handleChangeAdditionalDataCsv}
          handleChangeGroupNameCsv={this.handleChangeGroupNameCsv}
          handleBlurDelimitedCsv={this.handleBlurDelimitedCsv}
          handleAddAddress={this.handleAddAddress}
          handleAddAddressOffice={this.handleAddAddressOffice}
          handleRemoveAddressCsv={this.handleRemoveAddressCsv}
          handleRemoveAddressOffice={this.handleRemoveAddressOffice}
          handleRemoveAdditionalDataCsv={this.handleRemoveAdditionalDataCsv}
          handleAddAdditionalData={this.handleAddAdditionalData}
          handleChangeCheckboxCallCenter={this.handleChangeCheckboxCallCenter}
          handleChangeWaybillCsv={this.handleChangeWaybillCsv}
          handleChangeAddressUpdateCsv={this.handleChangeAddressUpdateCsv}
          handleEditCsv={this.handleEditCsv}
          handleSubmiteAddressCsv={this.handleSubmiteAddressCsv}
          handleBack={this.handleBack}
          handleCancel={this.handleCancel}
          handleBlurFileType={this.handleBlurFileType}
          handleChangeFileType={this.handleChangeFileType}
          FileType={this.props.FileType}
          FileSettingName={this.props.FileSettingName}
          ReverseBarcode={this.props.ReverseBarcode}
          StatusDefault={this.props.StatusDefault}
          DelimitedCsv={this.props.DelimitedCsv}
          CycleCsv={this.props.CycleCsv}
          FormatCycleCsv={this.props.FormatCycleCsv}
          CityCsv={this.props.CityCsv}
          ListAddressCsv={this.props.ListAddressCsv}
          ListAddressOffice={this.props.ListAddressOffice}
          AddressCsv={this.props.AddressCsv}
          BarcodeCsv={this.props.BarcodeCsv}
          OwnerNameCsv={this.props.OwnerNameCsv}
          PostalCodeCsv={this.props.PostalCodeCsv}
          NoTelpCsv={this.props.NoTelpCsv}
          CallLinkCsv={this.props.CallLinkCsv}
          AdditionalDataCsv={this.props.AdditionalDataCsv}
          ListAdditionalDataCsv={this.props.ListAdditionalDataCsv}
          TypeFormCsv={this.props.TypeFormCsv}
          GroupingNameCsv={this.props.GroupingNameCsv}
          CustomerNameCsv={this.props.CustomerNameCsv}
          SearchTypeCsv={this.props.SearchTypeCsv}
          SearchKeyCsv={this.props.SearchKeyCsv}
          Waybill={this.props.Waybill}
          AddressUpdate={this.props.AddressUpdate}
          ChekboxCallCenter={this.props.CheckboxCallCenter}
          CheckboxCycle={this.state.CheckboxCycle}
          handleChangeSearchKeyCsv={this.handleChangeSearchKeyCsv}
          handleChangeSearchTypeCsv={this.handleChangeSearchTypeCsv}
          handleCheckboxCycle={this.handleCheckboxCycle}
          ValidateDelimitedCsv={this.state.ValidateDelimitedCsv}
          ValidateFileType={this.state.ValidateFileType}
          ValidateFileSettingNameCsv={this.state.ValidateFileSettingNameCsv}
          ValidateCycleCsv={this.state.ValidateCycleCsv}
          ValidateFormatCycleCsv={this.state.ValidateFormatCycleCsv}
          ValidateCityCsv={this.state.ValidateCityCsv}
          ValidateAddressCsv={this.state.ValidateAddressCsv}
          ValidateBarcodeCsv={this.state.ValidateBarcodeCsv}
          ValidateOwnerNameCsv={this.state.ValidateOwnerNameCsv}
          ValidatePostalCodeCsv={this.state.ValidatePostalCodeCsv}
          ValidateNoTelpCsv={this.state.ValidateNoTelpCsv}
          ValidateGroupingDelivery={this.state.ValidateGroupingDelivery}
          ValidateWaybill={this.state.ValidateWaybill}
          ValidateAddressUpdate={this.state.ValidateAddressUpdate}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  FileSettingName: state.Customer.FormCustomCsvCustomer.FileSettingName,
  StatusDefault: state.Customer.FormCustomCsvCustomer.StatusDefault,
  ReverseBarcode: state.Customer.FormCustomCsvCustomer.ReverseBarcode,
  DelimitedCsv: state.Customer.FormCustomCsvCustomer.DelimitedCsv,
  FileType: state.Customer.FormCustomCsvCustomer.FileType,
  CycleCsv: state.Customer.FormCustomCsvCustomer.CycleCsv,
  FormatCycleCsv: state.Customer.FormCustomCsvCustomer.FormatCycleCsv,
  CityCsv: state.Customer.FormCustomCsvCustomer.CityCsv,
  AddressCsv: state.Customer.FormCustomCsvCustomer.AddressCsv,
  ListAddressCsv: state.Customer.FormCustomCsvCustomer.ListAddressCsv,
  ListAddressOffice: state.Customer.FormCustomCsvCustomer.ListAddressOffice,
  BarcodeCsv: state.Customer.FormCustomCsvCustomer.BarcodeCsv,
  OwnerNameCsv: state.Customer.FormCustomCsvCustomer.OwnerNameCsv,
  PostalCodeCsv: state.Customer.FormCustomCsvCustomer.PostalCodeCsv,
  NoTelpCsv: state.Customer.FormCustomCsvCustomer.NoTelpCsv,
  CallLinkCsv: state.Customer.FormCustomCsvCustomer.CallLinkCsv,
  AdditionalDataCsv: state.Customer.FormCustomCsvCustomer.AdditionalDataCsv,
  ListAdditionalDataCsv:
    state.Customer.FormCustomCsvCustomer.ListAdditionalDataCsv,
  GroupingNameCsv: state.Customer.FormCustomCsvCustomer.GroupNameCsv,
  SearchTypeCsv: state.Customer.FormCustomCsvCustomer.SearchTypeCsv,
  SearchKeyCsv: state.Customer.FormCustomCsvCustomer.SearchKeyCsv,
  TypeFormCsv: state.Customer.FormCustomCsvCustomer.TypeFormCsv,
  CheckboxCallCenter: state.Customer.FormCustomCsvCustomer.CheckboxCallCenter,
  Waybill: state.Customer.FormCustomCsvCustomer.Waybill,
  AddressUpdate: state.Customer.FormCustomCsvCustomer.AddressUpdate,
  CustomerNameCsv: state.Customer.DetailCustomer.CustomerName,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionCustomCsvCustomer: bindActionCreators(
    ActionCustomCsvCustomer,
    dispatch
  ),
  actionAddCustomCsvCustomer: bindActionCreators(
    ActionAddCustomCsvCustomer,
    dispatch
  ),
  actionEditCustomCsvCustomer: bindActionCreators(
    ActionEditCustomCsvCustomer,
    dispatch
  ),
  actionNavigation: bindActionCreators(ActionNavigation, dispatch),
  actionDetailCustomCsvCustomer: bindActionCreators(
    ActionDetailCustomCsvCustomer,
    dispatch
  ),
  actionLastAccess: bindActionCreators(ActionLastAccess, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FormCsvCustomerContainer);
