export function changeRadioButtonMisReport(RadioButton: any) {
  return {
    type: 'CHANGE_RADIO_BUTTON_MIS_REPORT_FORM',
    RadioButton,
  };
}
export function changeStartDateMisReport(StartDate: any) {
  return {
    type: 'CHANGE_START_DATE_MIS_REPORT_FORM',
    StartDate,
  };
}
export function changeFinishDateMisReport(FinishDate: any) {
  return {
    type: 'CHANGE_FINISH_DATE_MIS_REPORT_FORM',
    FinishDate,
  };
}

export function changeDistrictMisReport(District: any) {
  return {
    type: 'CHANGE_DISTRICT_MIS_REPORT_FORM',
    District,
  };
}

export function changeCustomerIdMisReport(CustomerId: any) {
  return {
    type: 'CHANGE_CUSTOMER_ID_MIS_REPORT_FORM',
    CustomerId,
  };
}

export function changeCustomerNameMisReport(CustomerName: any) {
  return {
    type: 'CHANGE_CUSTOMER_NAME_MIS_REPORT_FORM',
    CustomerName,
  };
}

export function changeCustomerProductIdMisReport(CustomerProductId: any) {
  return {
    type: 'CHANGE_CUSTOMER_PRODUCT_ID_MIS_REPORT_FORM',
    CustomerProductId,
  };
}

export function clearCustomerIdMisReportForm() {
  return {
    type: 'CLEAR_CUSTOMER_ID_MIS_REPORT_FROM',
  };
}

export function clearMisReportForm() {
  return {
    type: 'CLEAR_MIS_REPORT_FROM',
  };
}

export function downloadMisReportForm() {
  return {
    type: 'DOWNLOAD_MIS_REPORT_FORM',
  };
}
