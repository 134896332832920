import * as ActionResendSingleData from '../../../../../core/action/Delivery/Resend/ActionResendSingleData';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import SingleDataComponent from '../../../../../components/Content/Delivery/Resend/SingleData/SingleDataComponent';
import { connect } from 'react-redux';
import moment from 'moment'

interface IProps
{
    actionResendSingleData:any
    getDisableForm:any
    getWaybill:any
    getDeliveryDate:any
    getName:any
    getPhoneNumber:any
    getaddress:any
    disableForm:any
    isLoading:any
}
interface IState
{
    stateLocalDate:any
    validateWaybill:any
    validateName:any
    ValidatePhoneNumber:any
}
class SingleDataContainer extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            validateWaybill: null,
            validateName: null,
            ValidatePhoneNumber: null,
            stateLocalDate: null,
        };
        this.handleSearchWaybill = this.handleSearchWaybill.bind(this);
        this.handlePutWaybill = this.handlePutWaybill.bind(this);
        this.handlePutName = this.handlePutName.bind(this);
        this.handlePutPhoneNumber = this.handlePutPhoneNumber.bind(this);
        this.handlePutDeliveryDate = this.handlePutDeliveryDate.bind(this);
        this.handlePutAddress = this.handlePutAddress.bind(this);
        this.handleGenerateWaybill = this.handleGenerateWaybill.bind(this);
        this.handleReset = this.handleReset.bind(this);
      }
    
    handleSearchWaybill(){
        this.props.actionResendSingleData.searchWaybill()
    }
    handleGenerateWaybill(){
        this.props.actionResendSingleData.generateNewWaybill()
    }
    handleReset(){
        this.props.actionResendSingleData.clearClearAllForm()
        this.props.actionResendSingleData.changeDisableForm(true)
        this.setState({
            validateWaybill: null,
            validateName: null,
            stateLocalDate: null,
        });
    }
    handlePutWaybill(event:any){
        this.props.actionResendSingleData.changeWaybill(event.target.value)
        if (event.target.value === null || event.target.value === '') {
            this.setState({
                validateWaybill: 'error',
            });
        }else if(event.target.value <=8){
        this.setState({
            validateWaybill: 'notMinChar',
        });
        } else {
        this.setState({
            validateWaybill: null,
        });
        }
    }
    handlePutName(event:any){
        this.props.actionResendSingleData.changeName(event.target.value.toUpperCase())
        if (event.target.value === null || event.target.value === '') {
            this.setState({
                validateName: 'error',
            });
        }else if(event.target.value <=8){
            this.setState({
                validateName: 'notMinChar',
            });
        } else {
            this.setState({
                validateName: null,
            });
        }
    }
    handlePutPhoneNumber(event:any){
        const number = parseInt(event.target.value || 1, 10);
        const regex = /^[0-9\b]+$/
        
        if (isNaN(number)) {
            return;
        }
        
        if (!('value' in this.props)) {
            this.props.actionResendSingleData.changePhoneNumber(event.target.value.replace(/^0+/, ''))
         if(event.target.value===''){
            this.setState({
                ValidatePhoneNumber:''
            })
         }else if(!regex.test(event.target.value)){
                this.setState({
                    ValidatePhoneNumber:'nonumeric'
                })
            }else{
                this.setState({
                    ValidatePhoneNumber:''
                })
            }
        }
    }
    handlePutDeliveryDate(event:any){
        if(event){
            const dateValue = event._d?(moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')):event
            this.props.actionResendSingleData.changeDeliveryDate(dateValue)
            
          }else{
            this.props.actionResendSingleData.changeDeliveryDate(null)
          }
        this.setState({
            stateLocalDate: event
        });
    }
    handlePutAddress(event:any,data:any){
    const valueAddress = event.target.value;
    if (valueAddress.startsWith(' ') === true) {
      this.props.getaddress[data] = valueAddress.trim();
    } else {
      this.props.getaddress[data] = valueAddress;
    }

    this.props.actionResendSingleData.changeAddress(
      this.props.getaddress
    );
    }
    render() {
        return (
            <React.Fragment>
                <SingleDataComponent
                stateLocalDate={this.state.stateLocalDate}
                    handleSearchWaybill={this.handleSearchWaybill}
                    handleDeliveryDate={this.handlePutDeliveryDate}
                    handlePutWaybill={this.handlePutWaybill}
                    getWaybill={this.props.getWaybill}
                    validateSearchButton={this.props.getWaybill===null||this.props.getWaybill===''?true:false}
                    valideWaybill={this.state.validateWaybill==='error'?'error':null}
                    helpValidateWaybill={
                        this.state.validateWaybill==='error'?'Waybill wajib diisi':
                        this.state.validateWaybill==='notMinChar'?'Data tidak boleh kurang dari 7 karakter':''}
                    isLoading={this.props.isLoading}
                    handlePutName={this.handlePutName}
                    validateName={this.state.validateName==='error'?'error':null}
                    helpValidateName={
                        this.state.validateName==='error'?'Nama wajib diisi':
                        this.state.validateName==='notMinChar'?'Data tidak boleh kurang dari 3 karakter':''}
                    getName={this.props.getName}
                    validateFormName={this.props.getDisableForm===true&&(this.props.getName===null||this.props.getName==='')?true:false}
                    getPhoneNumber={this.props.getPhoneNumber}
                    validateFormPhoneNumber={this.props.getDisableForm===true&&(this.props.getPhoneNumber===null||this.props.getPhoneNumber==='')?true:false}
                    getDeliveryDate={this.props.getDeliveryDate}
                    getaddress={this.props.getaddress}
                    handlePutAddress={this.handlePutAddress}
                    handleGenerateWaybill={this.handleGenerateWaybill}
                    validateDeliveryDate={this.props.getDisableForm===true&&(this.props.getDeliveryDate===null||this.props.getDeliveryDate==='')?true:false}
                    handleReset={this.handleReset}
                    validateResetButton={(this.props.getWaybill!==null&&this.props.disableForm===true)||(this.props.getName!==null||
                        this.props.getWaybill!==null||
                        this.props.disableForm===false)?false:true
                    }
                    validateGenerateWaybill={
                        this.props.getWaybill===''||
                        this.props.getWaybill===null||
                        this.props.getName===''||
                        this.props.getName===null||this.state.ValidatePhoneNumber==='nonumeric'?true:false
                    }
                    handlePutPhoneNumber={this.handlePutPhoneNumber}
                    validatePhoneNumber={this.state.ValidatePhoneNumber==='nonumeric'?'error':''}
                    helpValidatePhoneNumber={this.state.ValidatePhoneNumber==='nonumeric'?'Tidak boleh selain angka':''}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: any) => ({
    getDeliveryDate: state.ResendSingleData.propertiesResendSingleData.deliveryDate,
    disableForm: state.ResendSingleData.propertiesResendSingleData.disableForm,
    getWaybill: state.ResendSingleData.propertiesResendSingleData.waybill,
    getDisableForm: state.ResendSingleData.propertiesResendSingleData.disableForm,
    getName: state.ResendSingleData.name,
    getPhoneNumber: state.ResendSingleData.phoneNumber,
    getaddress: state.ResendSingleData.address,
    isLoading: state.Toggle.isLoading,
  });
  
  const mapDispatchToProps = (dispatch: any) => ({
    actionResendSingleData: bindActionCreators(ActionResendSingleData, dispatch),
  });

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SingleDataContainer);