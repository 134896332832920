import { Col, Row, Table } from 'antd';
import { Field, reduxForm } from 'redux-form';
import {
  InputSelect,
  InputSelectPrefix,
  InputText,
} from '../../../apps/assets/components/CInput';
import {
  complaintStatus,
  prefixComplaintSearchForm,
} from '../../../apps/enums';

import CButton from '../../../apps/assets/components/CButton';
import CCard from '../../../apps/assets/components/CCard';
import CSpin from '../../../apps/assets/components/CSpin';
import ModalCreateComplaintContainer from '../Container/ModalCreateComplaintContainer';
import React from 'react';
import validate from '../Validation/FilterComplaintValidation';

function MainComplaintComponent(props) {
  const {
    columns,
    complaintList,
    pagePagination,
    totalPagination,
    handlePage,
    handleModalCreateComplaint,
    isLoading,
    handleSubmitSearch,
    formValue,
    handleReset,
    createComplaintModalIsShow,
  } = props;

  const valueStatusComplaint = formValue
    ? formValue.complaintStatus.length
    : '';
  const valueSearchFilter = formValue ? formValue.searchValueFilter : '';
  const validateBtnReset =
    valueSearchFilter || valueStatusComplaint !== 0 ? false : true;
  const validateBtnSearch = valueStatusComplaint !== 0 ? false : true;
  const buttonDataSearch = [
    {
      type: 'primary',
      className: 'btnResetDefault',
      onClick: handleReset,
      isLoading: isLoading,
      content: 'Reset',
      id: 'btnResetComplaintFilter',
      disabled: validateBtnReset,
    },
    {
      type: 'primary',
      className: 'btnDefaultSubmit',
      onClick: handleSubmitSearch,
      isLoading: isLoading,
      content: 'Cari',
      id: 'btnSearchComplaint',
      disabled: validateBtnSearch,
    },
  ];
  const buttonData = [
    {
      type: 'link',
      className: 'btnLinkAdd',
      onClick: handleModalCreateComplaint,
      isLoading: isLoading,
      content: <b>TAMBAH KELUHAN TIKET</b>,
      id: 'btnPopupAddComplaint',
    },
    {
      type: 'circle',
      className: 'btnTableAdd',
      onClick: handleModalCreateComplaint,
      isLoading: isLoading,
      icon: 'plus',
      id: 'btnPopupAddComplaint',
    },
  ];
  const prefixSelector = (
    <Field
      name="filterValue"
      component={InputSelectPrefix}
      idComponent="inputFilterValue"
      data={prefixComplaintSearchForm}
    />
  );
  const contentCard = () => {
    return (
      <div>
        <Row>
          <Col span={12} className="spacingRight">
            <Field
              name="complaintStatus"
              component={InputSelect}
              mode="multiple"
              idComponent="inputComplaintStatus"
              data={complaintStatus}
              label={'Status Keluhan'}
              dafaultItemName=""
            />
          </Col>
          <Col span={12}>
            <Field
              className={'searchValueComplaintList'}
              name="searchValueFilter"
              component={InputText}
              addonBefore={prefixSelector}
              idComponent="inputSearchValueFilter"
              maxLength={50}
            />
          </Col>
        </Row>
        <Row className="floatRight">
          <CButton buttonData={buttonDataSearch} />
        </Row>
      </div>
    );
  };
  const contentAddCard = () => {
    return (
      <div>
        <Row>
          <Col span={12}>
            <div className="cardSpacingTop">
              <span className="defaultCardTableAdd">SEMUA DATA</span>
            </div>
          </Col>
          <Col span={12}>
            <div className={'floatRight'}>
              <CButton buttonData={buttonData} />
            </div>
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <div className="defaultSpace">
      <CSpin
        spinning={isLoading}
        size={'large'}
        content={
          <React.Fragment>
            <CCard
              cardClassName={'defaultCard'}
              cardTitle={<span className="lightBlueColor">DATA KELUHAN</span>}
              cardContent={<React.Fragment>{contentCard()}</React.Fragment>}
            />
            <CCard
              cardClassName={'tableComplaintList'}
              cardContent={<React.Fragment>{contentAddCard()}</React.Fragment>}
            />
            <Table
              pagination={{
                onChange: page => {
                  handlePage(page);
                },
                pageSize: 10,
                total: totalPagination,
                current: pagePagination === 0 ? 1 : pagePagination,
              }}
              bordered={false}
              dataSource={complaintList}
              columns={columns}
            />
          </React.Fragment>
        }
      />
      {createComplaintModalIsShow ? <ModalCreateComplaintContainer /> : ''}
    </div>
  );
}
const ComplaintComponent = reduxForm({
  form: 'complaint',
  shouldError: () => {
    return true;
  },
  validate,
})(MainComplaintComponent);
export default ComplaintComponent;
