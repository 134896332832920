import instance from 'config/interceptor';
import moment from 'moment-timezone';

export const postReportCourier = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/Delivery/Report/courier`,
      data,
      { responseType: 'arraybuffer' }
    );
  } catch (error) {
    return error;
  }
};

export const getDataTableSla = async params => {
  try {
    const { page, typeFilter, dateSend, dateEnd, searchValue, branch } = params;
    let link = `/Delivery/Sla/Performance/${typeFilter}/${
      dateSend ? moment(dateSend).format('DDMMYYYY') : null
    }/${dateEnd ? moment(dateEnd).format('DDMMYYYY') : null}/${page}/10?`;

    if (searchValue) link = link.concat(`&ValueSearch=${searchValue}`);
    if (branch?.length > 0) {
      const queryParam = `Branch=${branch.join('&Branch=')}`;
      link = link.concat(`&${queryParam}`);
    }

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const downloadSlaDocument = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/Delivery/Sla/Download`,
      data,
      { responseType: 'arraybuffer' }
    );
  } catch (error) {
    return error;
  }
};

export const getDataTableHour = async params => {
  try {
    const { page, dateSend, dateEnd, searchValue, branch } = params;
    let link = `/Delivery/Hour/Performance/${
      dateSend ? moment(dateSend).format('DDMMYYYY') : null
    }/${dateEnd ? moment(dateEnd).format('DDMMYYYY') : null}/${page}/10?`;

    if (searchValue) link = link.concat(`&ValueSearch=${searchValue}`);
    if (branch?.length > 0) {
      const queryParam = `Branch=${branch.join('&Branch=')}`;
      link = link.concat(`&${queryParam}`);
    }

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const downloadHourDocument = async (data, onDownloadProgress: any) => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/Delivery/Hour/Download`,
      data,
      { responseType: 'arraybuffer', onDownloadProgress }
    );
  } catch (error) {
    return error;
  }
};
