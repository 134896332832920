import * as ActionCustomerAccount from '../../../../core/action/ManageAccount/Customer/ActionCustomerAccount';
import * as ActionListCustomerProduct from '../../../../core/action/CustomerProduct/ActionListCustomerProduct';
import * as GenerateAdminCode from '../../../../core/action/ManageAccount/AdminBranch/ActionGenerateAdminBranch';
import * as addAdmin from '../../../../core/action/ManageAccount/Customer/ActionAddCustomerAccount';
import * as editAdmin from '../../../../core/action/ManageAccount/Customer/ActionEditCustomerAccount';
import * as toggle from '../../../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import AddCustomerAccountComponent from '../../../../components/Content/ManageAccount/Customer/AddCustomerAccountComponent';
import { connect } from 'react-redux';

interface IProps {
  actionToggle: any;
  visible: any;
  jenisToggle: any;
  actionCustomerAccount: any;
  actionListCustomerProduct: any;

  GetAccountFirstName: any;
  GetAccountLastName: any;
  GetAccountPhoneNumber: any;
  GetAccountEmail: any;
  GetAccountUsername: any;
  GetAccountAddress: any;
  GetAccountStatus: any;
  GetCustomerProductId: any;
  listCustomerProduct: any;

  addAdmin: any;
  editAdmin: any;
  generateAdminCode: any;
  LoadingSelectCustomerProduct: any;
  LoadingTypeSelectCustomerProduct: any;

  isLoading: any;
}

interface IState {
  ValidateAccountFirstName: any;
  ValidateAccountLastName: any;
  ValidateAccountEmail: any;
  ValidateAccountPhoneNumber: any;
  ValidateAccountAddress: any;
  ArrayValue: any;
}

class AddAdminContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateAccountFirstName: null,
      ValidateAccountLastName: null,
      ValidateAccountEmail: null,
      ValidateAccountPhoneNumber: null,
      ValidateAccountAddress: null,
      ArrayValue: null,
    };
    this.handleClearDataAccount = this.handleClearDataAccount.bind(this);
    this.handlePutAccountFirstName = this.handlePutAccountFirstName.bind(this);
    this.handlePutAccountLastName = this.handlePutAccountLastName.bind(this);
    this.handlePutAccountEmail = this.handlePutAccountEmail.bind(this);
    this.handlePutAccountPhoneNumber = this.handlePutAccountPhoneNumber.bind(
      this
    );
    this.handlePutAccountAddress = this.handlePutAccountAddress.bind(this);
    this.handlePutAccountStatus = this.handlePutAccountStatus.bind(this);
    this.handlePutProduct = this.handlePutProduct.bind(this);
    this.handleGetProduct = this.handleGetProduct.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
  }

  handleClearDataAccount() {
    this.props.actionToggle.changeModal();
    this.props.actionToggle.clearJenisToggle();
    this.props.actionCustomerAccount.clearDataCustomerAccount();
    this.props.actionListCustomerProduct.clearListCustomerProduct();
    this.setState({
      ValidateAccountFirstName: '',
      ValidateAccountLastName: '',
      ValidateAccountEmail: '',
      ValidateAccountPhoneNumber: '',
      ValidateAccountAddress: '',
      ArrayValue: '',
    });
  }
  handleGetProduct() {
    this.props.actionListCustomerProduct.getListCustomerProductNoType();
  }
  handlePutProduct(event: any) {
    if (event[0] === 'Pilih Semua Produk') {
      this.props.actionCustomerAccount.changeCustomerProduct(
        this.props.listCustomerProduct.map((data: any, index: any) => data.id)
      );
    } else {
      this.props.actionCustomerAccount.changeCustomerProduct(event);
    }
  }
  handlePutAccountFirstName(event: any) {
    this.props.actionCustomerAccount.changeFirstNameAccount(
      event.target.value.replace(/^\s/, '')
    );
    const RegexNotAlphabet = /^[A-Za-z]+$/;
    if (event.target.value === '') {
      this.setState({
        ValidateAccountFirstName: 'error',
      });
    } else if (!RegexNotAlphabet.test(event.target.value)) {
      this.setState({
        ValidateAccountFirstName: 'notAlphabet',
      });
    } else if (event.target.value.length <= 3) {
      this.setState({
        ValidateAccountFirstName: 'notMinChar',
      });
    } else {
      this.setState({
        ValidateAccountFirstName: '',
      });
    }
  }
  handlePutAccountLastName(event: any) {
    this.props.actionCustomerAccount.changeLastNameAccount(
      event.target.value.replace(/^\s/, '')
    );
    const RegexNotAlphabet = /^[A-Za-z]+$/;
    if (event.target.value === '') {
      this.setState({
        ValidateAccountLastName: 'error',
      });
    } else if (!RegexNotAlphabet.test(event.target.value)) {
      this.setState({
        ValidateAccountFirstName: 'notAlphabet',
      });
    } else if (event.target.value.length <= 3) {
      this.setState({
        ValidateAccountLastName: 'notMinChar',
      });
    } else {
      this.setState({
        ValidateAccountLastName: '',
      });
    }
  }
  handlePutAccountEmail(event: any) {
    const RegexEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

    this.props.actionCustomerAccount.changeEmailAccount(
      event.target.value.replace(/^\s/, '')
    );

    if (event.target.value === '') {
      this.setState({
        ValidateAccountEmail: 'error',
      });
    } else if (!RegexEmail.test(event.target.value)) {
      this.setState({
        ValidateAccountEmail: 'NotEmail',
      });
    } else if (event.target.value.length < 11) {
      this.setState({
        ValidateAccountEmail: 'notMinChar',
      });
    } else {
      this.setState({
        ValidateAccountEmail: '',
      });
    }
  }
  handlePutAccountPhoneNumber(event: any) {
    const number = parseInt(event.target.value || 0, 10);
    const RegexPhone = /^[0-9\b]+$/;

    if (isNaN(number)) {
      return;
    }

    if (!('value' in this.props)) {
      this.props.actionCustomerAccount.changePhoneNumberAccount(
        event.target.value.replace(/^0+/, '')
      );
    }

    if (event.target.value === '') {
      this.setState({
        ValidateAccountPhoneNumber: 'error',
      });
    } else if (!RegexPhone.test(event.target.value)) {
      this.setState({
        ValidateAccountPhoneNumber: 'NonNumeric',
      });
    } else if (event.target.value.length < 8) {
      this.setState({
        ValidateAccountPhoneNumber: 'notMinChar',
      });
    } else {
      this.setState({
        ValidateAccountPhoneNumber: '',
      });
    }
  }

  handlePutAccountAddress(event: any) {
    this.props.actionCustomerAccount.changeAddressAccount(
      event.target.value.replace(/^\s/, '')
    );
    if (event.target.value === '') {
      this.setState({
        ValidateAccountAddress: 'error',
      });
    } else if (event.target.value.length <= 10) {
      this.setState({
        ValidateAccountAddress: 'notMinChar',
      });
    } else {
      this.setState({
        ValidateAccountAddress: '',
      });
    }
  }

  handlePutAccountStatus(event: any) {
    this.props.actionCustomerAccount.changeStatusAccount(event.target.checked);
  }

  handleSubmit() {
    this.props.addAdmin.addCustomerAccount();
  }
  handleEditSubmit() {
    this.props.editAdmin.editCustomerAccount();
  }
  render() {
    return (
      <div>
        <AddCustomerAccountComponent
          visible={this.props.visible}
          jenisToggle={this.props.jenisToggle}
          LoadingSelectCustomerProduct={this.props.LoadingSelectCustomerProduct}
          ValidateButton={
            this.props.GetAccountFirstName === null ||
            this.props.GetAccountFirstName === '' ||
            this.state.ValidateAccountFirstName === 'notMinChar' ||
            this.state.ValidateAccountFirstName === 'notAlphabet' ||
            this.props.GetAccountLastName === null ||
            this.props.GetAccountLastName === '' ||
            this.state.ValidateAccountLastName === 'notMinChar' ||
            this.state.ValidateAccountLastName === 'notAlphabet' ||
            this.props.GetAccountEmail === null ||
            this.props.GetAccountEmail === '' ||
            this.state.ValidateAccountEmail === 'NotEmail' ||
            this.state.ValidateAccountEmail === 'notMinChar' ||
            this.props.GetAccountPhoneNumber === null ||
            this.props.GetAccountPhoneNumber === '' ||
            this.state.ValidateAccountPhoneNumber === 'NonNumeric' ||
            this.state.ValidateAccountPhoneNumber === 'notMinChar' ||
            this.props.GetAccountAddress === null ||
            this.props.GetAccountAddress === '' ||
            this.state.ValidateAccountAddress === 'notMinChar'
              ? true
              : false
          }
          handleClearDataAccount={this.handleClearDataAccount}
          PutAccountStatus={this.handlePutAccountStatus}
          PutAccountFirstName={this.handlePutAccountFirstName}
          ValidateAccountFirstName={
            this.state.ValidateAccountFirstName === 'error' ||
            this.state.ValidateAccountFirstName === 'notMinChar' ||
            this.state.ValidateAccountFirstName === 'notAlphabet'
              ? 'error'
              : ''
          }
          helpAccountFirstName={
            this.state.ValidateAccountFirstName === 'error'
              ? 'Nama Wajib Diisi'
              : this.state.ValidateAccountFirstName === 'notMinChar'
              ? 'Nama depan harus lebih dari 3 karakter'
              : this.state.ValidateAccountFirstName === 'notAlphabet'
              ? 'Tidak boleh selain huruf'
              : ''
          }
          GetAccountFirstName={this.props.GetAccountFirstName}
          PutAccountLastName={this.handlePutAccountLastName}
          ValidateAccountLastName={
            this.state.ValidateAccountLastName === 'error' ||
            this.state.ValidateAccountLastName === 'notMinChar' ||
            this.state.ValidateAccountLastName === 'notAlphabet'
              ? 'error'
              : ''
          }
          helpAccountLastName={
            this.state.ValidateAccountLastName === 'error'
              ? 'Nama Belakang Wajib Diisi'
              : this.state.ValidateAccountLastName === 'notMinChar'
              ? 'Nama belakang harus lebih dari 3 karakter'
              : this.state.ValidateAccountLastName === 'notAlphabet'
              ? 'Tidak boleh selain huruf'
              : ''
          }
          GetAccountLastName={this.props.GetAccountLastName}
          PutAccountEmail={this.handlePutAccountEmail}
          ValidateAccountEmail={
            this.state.ValidateAccountEmail === 'error' ||
            this.state.ValidateAccountEmail === 'NotEmail' ||
            this.state.ValidateAccountEmail === 'notMinChar'
              ? 'error'
              : ''
          }
          helpAccountEmail={
            this.state.ValidateAccountEmail === 'error'
              ? 'Email Wajib Diisi'
              : '' || this.state.ValidateAccountEmail === 'NotEmail'
              ? 'Maaf format email salah'
              : this.state.ValidateAccountEmail === 'notMinChar'
              ? 'Email harus lebih dari 10 karakter'
              : ''
          }
          GetAccountEmail={this.props.GetAccountEmail}
          PutAccountPhoneNumber={this.handlePutAccountPhoneNumber}
          ValidateAccountPhoneNumber={
            this.state.ValidateAccountPhoneNumber === 'error' ||
            this.state.ValidateAccountPhoneNumber === 'NonNumeric' ||
            this.state.ValidateAccountPhoneNumber === 'notMinChar'
              ? 'error'
              : ''
          }
          helpAccountPhoneNumber={
            this.state.ValidateAccountPhoneNumber === 'error'
              ? 'No telpon wajib diisi'
              : '' || this.state.ValidateAccountPhoneNumber === 'NonNumeric'
              ? 'Tidak boleh selain angka!'
              : this.state.ValidateAccountPhoneNumber === 'notMinChar'
              ? 'Nomor telepon harus lebih dari 7 karakter'
              : ''
          }
          GetAccountPhoneNumber={this.props.GetAccountPhoneNumber}
          PutAccountAddress={this.handlePutAccountAddress}
          ValidateAccountAddress={
            this.state.ValidateAccountAddress === 'error' ||
            this.state.ValidateAccountAddress === 'notMinChar'
              ? 'error'
              : ''
          }
          helpAccountAddress={
            this.state.ValidateAccountAddress === 'error'
              ? 'Alamat wajib diisi'
              : this.state.ValidateAccountAddress === 'notMinChar'
              ? 'Alamat harus lebih dari 10 karakter'
              : ''
          }
          GetAccountAddress={this.props.GetAccountAddress}
          GetAccountUsername={this.props.GetAccountUsername}
          GetAccountStatus={this.props.GetAccountStatus}
          GetProduct={this.handleGetProduct}
          PutProduct={this.handlePutProduct}
          GetCustomerProductId={this.props.GetCustomerProductId}
          listCustomerProduct={this.props.listCustomerProduct}
          isLoading={this.props.isLoading}
          handleSubmit={this.handleSubmit}
          handleEditSubmit={this.handleEditSubmit}
          ArrayValue={this.state.ArrayValue}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  jenisToggle: state.Toggle.jenisToggle,
  GetAccountFirstName: state.Customer.CustomerAccount.FirstName,
  GetAccountLastName: state.Customer.CustomerAccount.LastName,
  GetAccountPhoneNumber: state.Customer.CustomerAccount.PhoneNumber,
  GetAccountEmail: state.Customer.CustomerAccount.Email,
  GetAccountUsername: state.Customer.CustomerAccount.Username,
  GetAccountAddress: state.Customer.CustomerAccount.Address,
  GetAccountStatus: state.Customer.CustomerAccount.StatusAccount,
  GetCustomerProductId: state.Customer.CustomerAccount.CustomerProductId,
  LoadingSelectCustomerProduct:
    state.CustomerProduct.PropertiesCustomerProduct.LoadingCustomerProduct,
  LoadingTypeSelectCustomerProduct:
    state.CustomerProduct.PropertiesCustomerProduct.TypeLoadingCustomerProduct,
  listCustomerProduct: state.CustomerProduct.ListCustomerProduct,
  isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(toggle, dispatch),
  actionCustomerAccount: bindActionCreators(ActionCustomerAccount, dispatch),
  actionListCustomerProduct: bindActionCreators(
    ActionListCustomerProduct,
    dispatch
  ),
  addAdmin: bindActionCreators(addAdmin, dispatch),
  editAdmin: bindActionCreators(editAdmin, dispatch),
  generateAdminCode: bindActionCreators(GenerateAdminCode, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AddAdminContainer);
