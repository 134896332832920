export function setPasswordResetPassword(Password: any) {
  return {
    type: 'SET_PASSWORD_RESET_PASSWORD',
    Password,
  };
}
export function setNewPasswordResetPassword(NewPassword: any) {
  return {
    type: 'SET_NEW_PASSWORD_RESET_PASSWORD',
    NewPassword,
  };
}
export function setReNewPasswordResetPassword(ReNewPassword: any) {
  return {
    type: 'SET_RE_NEW_PASSWORD_RESET_PASSWORD',
    ReNewPassword,
  };
}
export function submitEditResetPassword() {
  return {
    type: 'SUBMIT_EDIT_RESET_PASSWORD',
  };
}
export function submitEditResetAccountPassword() {
  return {
    type: 'SUBMIT_EDIT_RESET_ACCOUNT_PASSWORD',
  };
}
export function changeClearResetPassword() {
  return {
    type: 'CHANGE_CLEAR_RESET_PASSWORD',
  };
}
export function changeClearDataResetPassword() {
  return {
    type: 'CHANGE_CLEAR_DATA_RESET_PASSWORD',
  };
}
