import 'styles/TableStyle/index.css';

import { Col, Row, Table } from 'antd';

import ButtonContainer from 'module/Branch/Container/ButtonBranchContainer';
import ButtonMaterial from 'materials/ButtonMaterial';
import CardMaterial from 'materials/CardMaterial';
import FormBranchContainer from 'module/Branch/Container/FormBranchContainer';
import React from 'react';
import SearchMatrial from 'materials/SearchMatrial';
import SpinMaterial from 'materials/SpinMaterial';

interface IProps {
  data: any;
  dataSource: any;
  nameSearch: any;
  isLoading: any;
  PagePagination: any;
  TotalPagePagination: any;
  page: any;
  ValueSearch: any;
  handlePage: any;
  handleSearch: any;
  handleModal: any;
  handleResetSearch: any;
  handleSubmitSearch: any;
  handleBranchDetail: any;
}
export default function BranchComponent({
  data,
  dataSource,
  nameSearch,
  isLoading,
  PagePagination,
  TotalPagePagination,
  page,
  ValueSearch,
  handlePage,
  handleSearch,
  handleModal,
  handleResetSearch,
  handleSubmitSearch,
  handleBranchDetail,
}: IProps) {
  const looppage = (page - 1) * 10 + 1;
  const columns = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <b>No</b>
        </div>
      ),
      width: 50,
      render: (text: any, record: any, index: any) => (
        <span>{page > 1 ? index + looppage : index + 1}</span>
      ),
      key: 'no',
    },
    {
      title: <b>Nama Posko</b>,
      dataIndex: 'name',
      key: 'name',
      width: 120,
    },
    {
      title: <b>Kode Wilayah</b>,
      dataIndex: 'uniqueCode',
      key: 'uniqueCode',
      render: (uniqueCode: any) => (
        <span style={{ color: 'red' }}>{uniqueCode}</span>
      ),
      width: 120,
    },
    {
      title: <b>Alamat</b>,
      dataIndex: 'address',
      key: 'alamat',
      width: 200,
    },
    {
      title: <b>No Telp</b>,
      dataIndex: 'phoneNumber',
      key: 'notelp',
      render: (phoneNumber: any) => (
        <span style={{ color: 'red' }}>+62{phoneNumber}</span>
      ),
      width: 120,
    },
    {
      title: <b>Aksi</b>,
      key: 'action',
      width: 130,
      render: (record: any) => (
        <div>
          <Row>
            <Col span={12} style={{ paddingRight: '15px' }}>
              <ButtonContainer idBranch={record.id} typeButton={'detail'} />
            </Col>

            <Col span={12}>
              <ButtonContainer idBranch={record.id} typeButton={'edit'} />
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: '35px' }}>
      <FormBranchContainer />
      <div>
        <SpinMaterial
          spinning={isLoading}
          style={{}}
          size={'large'}
          content={
            <div>
              <CardMaterial
                extra={''}
                title={''}
                style={{}}
                content={
                  <div>
                    <Row>
                      <Col span={12}>
                        <div>
                          <span
                            style={{ color: '#11BEFF', paddingRight: '20px' }}
                          >
                            <b>SEMUA POSKO</b>
                          </span>
                          <SearchMatrial
                            value={ValueSearch}
                            style={{ borderRadius: '10px', width: '70%' }}
                            placeholder={'Cari nama atau kode'}
                            onChange={handleSearch}
                            onPressEnter={handleSubmitSearch}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div style={{ float: 'right' }}>
                          <ButtonMaterial
                            disabled={''}
                            handleSubmit={handleModal}
                            teksButton={<b>TAMBAHKAN POSKO</b>}
                            size={''}
                            shape={''}
                            className={''}
                            type={'link'}
                            icon={''}
                            style={{
                              color: '#11BEFF',
                              paddingRight: '15px',
                            }}
                          />

                          <ButtonMaterial
                            disabled={''}
                            handleSubmit={handleModal}
                            teksButton={''}
                            size={''}
                            shape={'circle'}
                            className={''}
                            type={'primary'}
                            icon={'plus'}
                            style={{
                              color: '#fafafa',
                              paddingBottom: '3px',
                              background: '#31E116',
                              borderColor: '#31E116',
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                }
              ></CardMaterial>
              <Table
                rowKey={record => record.id}
                pagination={{
                  onChange: page => {
                    handlePage(page);
                  },
                  pageSize: 10,
                  // total:TotalPagePagination,
                  // current:PagePagination===0?1:PagePagination
                }}
                bordered={false}
                dataSource={data}
                columns={columns}
              />
            </div>
          }
        ></SpinMaterial>
      </div>
    </div>
  );
}
