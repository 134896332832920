import * as ActionConfigReport from '../../action/Customer/ActionDetailConfigReport';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_customerConfigReport() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const IdCustomer = state.Customer.PropertiesCustomer.IdCustomer;
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Setting/CustomReportFile/Customer/' +
        IdCustomer +
        '/None',
      { headers: config }
    );

    if (data.length === 0) {
      yield put(
        ActionConfigReport.changeTypeFormConfigReport('AddConfigReport')
      );
    } else {
      yield put(
        ActionConfigReport.changeTypeFormConfigReport('DetailConfigReport')
      );
    }
    yield put(ActionConfigReport.setListCustomerReport(data.data));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      const propsError = ErrorMassage('Detail format laporan');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error?.response?.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* SM_customerConfigReport() {
  yield takeLatest(
    'CHANGE_DETAIL_CUSTOMER_CONFIG_REPORT',
    S_customerConfigReport
  );
}
