import '../../../module/Login/Component/LoginComponent';

import * as ActionDetailLocationCourier from '../../action/Maps/CourierTracing/ActionCourierTracking';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionToggleMaps from '../../action/Maps/ActionToggleMaps';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

export function* S_getIndividuLocationCourier() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  // const idBranch = state.Branch.PropertiesBranch.idBranch
  const selectCourierId = state.Maps.CourierTracking.SelectCourier;
  try {
    yield put(toggle.changeJenisToggle('locationCourier'));
    yield put(toggle.changeLoadingTrue());
    const data = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Courier/location/' + selectCourierId,
      { headers: config }
    );
    yield put(toggle.changeLoadingFalse());
    yield put(toggle.clearJenisToggle());
    yield put(ActionDetailLocationCourier.detailCourier([data.data]));
    yield put(ActionDetailLocationCourier.setCourirCode(data.data.courierCode));
    yield put(
      ActionDetailLocationCourier.setCourirPhone(data.data.courierPhone)
    );
    yield put(
      ActionDetailLocationCourier.setCourirBranch(data.data.branchName)
    );
    yield put(
      ActionDetailLocationCourier.setCourirLastSeen(
        Date.parse(moment(data.data.lastSeen).format('YYYY-MM-DD HH:mm:ss'))
      )
    );
    yield put(
      ActionDetailLocationCourier.setDeliverySuccess(data.data.success)
    );
    yield put(ActionDetailLocationCourier.setDeliveryReturn(data.data.return));
    yield put(
      ActionDetailLocationCourier.setDeliveryPending(data.data.pending)
    );
    yield put(ActionDetailLocationCourier.setDeliveryTotal(data.data.total));
    yield put(ActionToggleMaps.clearToggleMaps());
    yield put(ActionToggleMaps.clearErrorConnectionMaps());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
    } else {
      yield put(toggle.changeLoadingFalse());
      yield put(toggle.clearJenisToggle());
      yield put(ActionToggleMaps.clearToggleMaps());
      yield put(ActionDetailLocationCourier.clearCourierDeliveryData());
      yield put(ActionToggleMaps.setErrorConnectionMaps('Error'));
    }
  }
}
export function* S_getAllLocationCourier() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const idBranch = state.Branch.PropertiesBranch.idBranch;
  // const selectCourierId = state.Maps.CourierTracking.SelectCourier
  try {
    if (idBranch !== null) {
      const data = yield call(
        axios.get,
        process.env.REACT_APP_URL + '/Courier/location/branch/' + idBranch,
        { headers: config }
      );
      yield put(ActionDetailLocationCourier.detailCourier(data.data.data));
      yield put(ActionToggleMaps.clearErrorConnectionMaps());
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
    } else {
      yield put(ActionToggleMaps.setErrorConnectionMaps('Error'));
    }
  }
}

export function* SM_getIndividuLocationCourier() {
  yield takeLatest('GET_COURIER_PERSON', S_getIndividuLocationCourier);
}
export function* SM_getAllLocationCourier() {
  yield takeLatest('GET_ALL_COURIER_LOCATION', S_getAllLocationCourier);
}
