import { Action } from 'redux';

const initialState = {
  FormRestoreData: {
    Id: null,
    UploadDate: null,
    UploadDateW: null,
    RadioButton: 'Delete',
    RadioButtonW: 'Delete',
    ProductType: null,
    Waybill: null,
    SelectAll: false,
    SelectAllW: false,
  },
  CountFile: null,
  CountWaybill: null,
  ListResultDeleteData: null,
  ListRestoreData: [],
  ListWaybillRestoreData: [],
};

export interface IAuthAction extends Action<string> {}

export interface ISetRestoreDataId extends IAuthAction {
  Id: any;
}
export interface ISetRestoreDataProductType extends IAuthAction {
  ProductType: any;
}
export interface ISetRestoreDataUploadDate extends IAuthAction {
  UploadDate: any;
}
export interface ISetRestoreDataUploadDateW extends IAuthAction {
  UploadDateW: any;
}

export interface ISetRestoreDataRadio extends IAuthAction {
  RadioButton: any;
}
export interface ISetRestoreDataRadioW extends IAuthAction {
  RadioButtonW: any;
}
export interface ISetRestoreDataWaybill extends IAuthAction {
  Waybill: any;
}
export interface ISetRestoreDataSelectAll extends IAuthAction {
  SelectAll: any;
}
export interface ISetRestoreDataSelectAllW extends IAuthAction {
  SelectAllW: any;
}
export interface ISetRestoreDataCount extends IAuthAction {
  CountFile: any;
}
export interface ISetRestoreDataCountWaybill extends IAuthAction {
  CountWaybill: any;
}
export interface ISetRestoreDataListRestoreData extends IAuthAction {
  ListRestoreData: any;
}
export interface ISetRestoreDataListResultDeleteData extends IAuthAction {
  ListResultDeleteData: any;
}
export interface ISetRestoreDataListWaybillRestoreData extends IAuthAction {
  ListWaybillRestoreData: any;
}

export default function AuthReducer(state = initialState, action: IAuthAction) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'SET_ID_RESTORE_DATA':
      const setRestoreId = action as ISetRestoreDataId;
      newState.FormRestoreData.Id = setRestoreId.Id;
      return { ...newState };

    case 'SET_PRODUCT_TYPE_RESTORE_DATA':
      const setRestoreProductType = action as ISetRestoreDataProductType;
      newState.FormRestoreData.ProductType = setRestoreProductType.ProductType;
      return { ...newState };

    case 'SET_UPLOAD_DATE_RESTORE_DATA':
      const setRestoreUploadDate = action as ISetRestoreDataUploadDate;
      newState.FormRestoreData.UploadDate = setRestoreUploadDate.UploadDate;
      return { ...newState };

    case 'SET_UPLOAD_W_DATE_RESTORE_DATA':
      const setRestoreUploadDateW = action as ISetRestoreDataUploadDateW;
      newState.FormRestoreData.UploadDateW = setRestoreUploadDateW.UploadDateW;
      return { ...newState };

    case 'SET_RADIO_RESTORE_DATA':
      const setRestoreRadio = action as ISetRestoreDataRadio;
      newState.FormRestoreData.RadioButton = setRestoreRadio.RadioButton;
      return { ...newState };

    case 'SET_RADIO_W_RESTORE_DATA':
      const setRestoreRadioW = action as ISetRestoreDataRadioW;
      newState.FormRestoreData.RadioButtonW = setRestoreRadioW.RadioButtonW;
      return { ...newState };

    case 'SET_SELECT_ALL_RESTORE_DATA':
      const setSelectAll = action as ISetRestoreDataSelectAll;
      newState.FormRestoreData.SelectAll = setSelectAll.SelectAll;
      return { ...newState };

    case 'SET_SELECT_ALL_W_RESTORE_DATA':
      const setSelectAllW = action as ISetRestoreDataSelectAllW;
      newState.FormRestoreData.SelectAllW = setSelectAllW.SelectAllW;
      return { ...newState };

    case 'SET_WAYBILL_RESTORE_DATA':
      const setWaybill = action as ISetRestoreDataWaybill;
      newState.FormRestoreData.Waybill = setWaybill.Waybill;
      return { ...newState };

    case 'SET_COUNT_RESTORE_DATA':
      const setRestoreDataCount = action as ISetRestoreDataCount;
      newState.CountFile = setRestoreDataCount.CountFile;
      return { ...newState };

    case 'SET_COUNT_WAYBILL_RESTORE_DATA':
      const setRestoreDataCountWaybill = action as ISetRestoreDataCountWaybill;
      newState.CountWaybill = setRestoreDataCountWaybill.CountWaybill;
      return { ...newState };

    case 'SET_LIST_RESTORE_DATA':
      const setRestoreDataList = action as ISetRestoreDataListRestoreData;
      newState.ListRestoreData = setRestoreDataList.ListRestoreData;
      return { ...newState };
    case 'SET_LIST_RESULT_DELETE_DATA':
      const setResultDeleteData = action as ISetRestoreDataListResultDeleteData;
      newState.ListResultDeleteData = setResultDeleteData.ListResultDeleteData;
      return { ...newState };

    case 'SET_LIST_WAYBILL_RESTORE_DATA':
      const setWaybillList = action as ISetRestoreDataListWaybillRestoreData;
      newState.ListWaybillRestoreData = setWaybillList.ListWaybillRestoreData;
      return { ...newState };

    case 'CLEAR_WAYBILL_RESTORE_DATA':
      newState.FormRestoreData.Waybill = null;
      return { ...newState };

    case 'CLEAR_LIST_RESTORE_DATA':
      newState.CountFile = null;
      newState.ListRestoreData = [];
      return { ...newState };

    case 'CLEAR_LIST_RESULT_DELETE_DATA':
      newState.ListResultDeleteData = null;
      return { ...newState };

    case 'CLEAR_LIST_WAYBILL_RESTORE_DATA':
      newState.CountWaybill = null;
      newState.ListWaybillRestoreData = [];
      return { ...newState };

    case 'CLEAR_FORM_RESTORE_DATA':
      newState.FormRestoreData.UploadDate = null;
      newState.FormRestoreData.UploadDateW = null;
      newState.FormRestoreData.Waybill = null;
      newState.FormRestoreData.RadioButton = 'Delete';
      newState.FormRestoreData.RadioButtonW = 'Delete';
      newState.FormRestoreData.SelectAll = false;
      newState.FormRestoreData.SelectAllW = false;
      newState.FormRestoreData.Id = null;
      newState.FormRestoreData.ProductType = null;
      return { ...newState };

    case 'CLEAR_DATA_RESTORE_DATA':
      newState.FormRestoreData.Id = null;
      newState.FormRestoreData.ProductType = null;
      newState.FormRestoreData.Waybill = null;
      return { ...newState };
  }
  return { ...newState };
}
