import React from 'react';
import styles from './AcceptedBagFilter.module.css';
import { Row, Col } from 'antd';

import { InputText } from 'components/InputField';
import CountData from 'components/CountData';
import SelectField from 'components/SelectField';
import SpinMaterial from 'materials/SpinMaterial';
import CardMaterial from 'materials/CardMaterial';

type AcceptedBugFilterProps = {
  dataAllBranch: Array<any>;
  isLoadingScan?: boolean;
  isLoadingBranch?: boolean;
  roleAccount: string;
  selectedBranchFrom: number | null;
  setSelectedBranchFrom: (value: number) => void;
  barcodeBag: string;
  setBarcodeBag: (value: string) => void;
  handleEnterBarcodeBag: (value: string) => void;
  responseSuccess: string;
  deliveryCount: number;
};

export default function AcceptedBugFilterFilter({
  dataAllBranch,
  isLoadingBranch,
  roleAccount,
  selectedBranchFrom,
  setSelectedBranchFrom,
  barcodeBag,
  setBarcodeBag,
  handleEnterBarcodeBag,
  isLoadingScan = false,
  responseSuccess,
  deliveryCount,
}: AcceptedBugFilterProps) {
  const isBranch = roleAccount === 'Branch';

  return (
    <CardMaterial
      title={<b style={{ color: '#11BEFF' }}>TERIMA BAG</b>}
      content={
        <SpinMaterial
          spinning={isLoadingScan}
          size={'large'}
          content={
            <div className={styles.wrapperFilter}>
              <Row gutter={24}>
                <Col md={12} className={styles.filterColumn}>
                  <SelectField
                    validate
                    label="Posko"
                    placeholder="Pilih Posko"
                    data={dataAllBranch}
                    loading={isLoadingBranch}
                    disabled={isBranch}
                    value={selectedBranchFrom}
                    onChange={value => setSelectedBranchFrom(value)}
                  />
                </Col>
                <Col md={12} className={styles.filterColumn}>
                  <InputText
                    label="Scan Barcode Bag"
                    placeholder="Masukan barcode bag disini"
                    maxlength={20}
                    value={barcodeBag}
                    onChange={value => setBarcodeBag(value)}
                    additionalHandleEnterFunc={e =>
                      handleEnterBarcodeBag(e.target.value)
                    }
                    disabled={!selectedBranchFrom}
                  />
                </Col>
              </Row>
              <CountData
                label="Dalam Pengiriman"
                variant="warning"
                value={deliveryCount || 0}
              />
            </div>
          }
        />
      }
      actions={[
        <h1
          style={{ color: '#11BEFF', fontSize: 16, fontWeight: 700, margin: 0 }}
        >
          STATUS :{' '}
          {responseSuccess && (
            <span style={{ color: '#31E116' }}>{responseSuccess}</span>
          )}
        </h1>,
      ]}
      style={{ borderRadius: '10px' }}
    />
  );
}
