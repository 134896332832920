import * as ActionAuth from '../../../action/Auth';
import * as ActionGroupManifest from '../../../action/Manifest/ScanManifest/ActionGroupManifest';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_AddGroupName(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
  };
  const GroupName = state.ScanManifest.GroupNameForm;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/GroupManifest/AddName`,
      {
        nameManifest: GroupName,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionGroupManifest.clearGroupList());
    yield put(ActionGroupManifest.clearGroupName());
    yield put(ActionGroupManifest.getGroupList());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: <p style={{ color: '#31E116' }}>Berhasil ditambahkan</p>,

      onOk() {},
    });
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_AddGroupName() {
  yield takeLatest('ADD_GROUP_LIST', S_AddGroupName);
}
