import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionServerVoip from '../../../action/Voip/Server/ActionServer';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_editServerVoip(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;

  const idServer = state.Voip.ServerVoip.Id;
  const nameServer = state.Voip.ServerVoip.Name;
  const IpServer1 = state.Voip.ServerVoip.IpServer1;
  const IpServer2 = state.Voip.ServerVoip.IpServer2;
  const IpServer3 = state.Voip.ServerVoip.IpServer3;
  const IpServer4 = state.Voip.ServerVoip.IpServer4;
  const IpPort = state.Voip.ServerVoip.IpPort;
  const IpAddress = `${IpServer1}.${IpServer2}.${IpServer3}.${IpServer4}:${IpPort}`;
  const Kuota = state.Voip.ServerVoip.Kuota;
  const Position = state.Voip.ServerVoip.Position;
  const Status = state.Voip.ServerVoip.ToggleServer;

  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/VoipSetting/update/${idServer}`,
      {
        nameServer: nameServer,
        ipAddress: IpAddress,
        kuota: Kuota,
        status: Status,
        position: Position,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(ActionServerVoip.changeClearAllFormServerVoip());
    yield put(ActionServerVoip.changeClearListServerVoip());
    yield put(ActionServerVoip.getListServerVoip());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil di ubah</b>
        </p>
      ),

      onOk() {},
    });
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}

export function* SM_editServerVoip() {
  yield takeLatest('SUBMIT_EDIT_SERVER_VOIP', S_editServerVoip);
}
