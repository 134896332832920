import * as ActionDetailCustomerProduct from '../../../../core/action/CustomerProduct/ActionDetailCustomerProduct';
import * as ActionFieldCustomerProduct from '../../../../core/action/CustomerProduct/ActionFieldCustomerProduct';
import * as ActionSetIdCustomerProduct from '../../../../core/action/CustomerProduct/ActionIdCustomerProduct';
import * as ActionToggle from '../../../../core/action/toggle/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import ButtonCustomerProductComponent from '../../../../components/Content/Customer/Product/ButtonCustomerProductComponent';
import { connect } from 'react-redux';

interface IProps {
  idGroupProduct: any;
  groupingName: any;
  typeToggle: any;
  idCustomerProduct: any;
  actionDetailCustomerProduct: any;
  actionSetIdCustomerProduct: any;
  actionFieldCustomerProduct: any;
  actionToggle: any;
}
class ButtonCustomerProductContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);

    this.handleCustomerProductEdit = this.handleCustomerProductEdit.bind(this);
    this.handleGetGroupingDetail = this.handleGetGroupingDetail.bind(this);
  }
  handleCustomerProductEdit() {
    this.props.actionSetIdCustomerProduct.setIdCustomerProduct(
      this.props.idCustomerProduct
    );
    this.props.actionFieldCustomerProduct.getGroupNameList();
    this.props.actionDetailCustomerProduct.changeDetailCustomerProduct();
  }
  handleGetGroupingDetail() {
    this.props.actionFieldCustomerProduct.setGroupProductId(
      this.props.idGroupProduct
    );
    this.props.actionFieldCustomerProduct.setGroupName(this.props.groupingName);
  }
  render() {
    return (
      <div>
        <ButtonCustomerProductComponent
          typeToggle={this.props.typeToggle}
          handleCustomerProductEdit={this.handleCustomerProductEdit}
          handleGetGroupingDetail={this.handleGetGroupingDetail}
        />
      </div>
    );
  }
}
const mapStateProps = (state: any) => ({
  typeToggle: state.Toggle.jenisToggle2,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionDetailCustomerProduct: bindActionCreators(
    ActionDetailCustomerProduct,
    dispatch
  ),
  actionSetIdCustomerProduct: bindActionCreators(
    ActionSetIdCustomerProduct,
    dispatch
  ),
  actionFieldCustomerProduct: bindActionCreators(
    ActionFieldCustomerProduct,
    dispatch
  ),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(ButtonCustomerProductContainer);
