import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import history from 'core/utils/History';

import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import CustomerReportComponents from '../components';
import {
  getCustomerConfigReport,
  getCustomerDetailCsvList,
} from 'services/CustomerService';
import AddCustomerFormatReport from './AddCustomerFormatReport';
import EditCustomerFormatReport from './EditCustomerFormatReport';
import * as FormConfigReport from '../../../../core/action/Customer/ActionDetailConfigReport';
import * as ActionTableCustomReportDataCustomer from '../../../../core/action/Customer/ActionTableCustomReportDataCustomer';
import * as ActionLastAccess from '../../../../core/action/LastAccess';

type CustomerReportProps = {
  IdCustomer: any;
  CustomerName: string;
  actionFormConfigReport: any;
  actionTableCustomReportDataCustomer: any;
  actionLastAccess: any;
};

export type CustomerReportType = {
  id: number;
  name: string;
  format: string;
  delimited: string;
  periode: string;
  customerId: number;
  customerName: string;
  customCsvId: number;
  nameCustomCsv: string;
  headerHide: boolean;
  reportPortal: boolean;
  isActive: boolean;
};

function CustomerReport({
  IdCustomer,
  CustomerName,
  actionFormConfigReport,
  actionTableCustomReportDataCustomer,
  actionLastAccess,
}: CustomerReportProps) {
  const [dataFormatReport, setDataFormatReport] = useState<
    CustomerReportType[]
  >([]);
  const [dataCsvList, setDataCsvList] = useState<any[]>([]);
  const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
  const [showModalEditId, setShowModalEditId] = useState<number | null>(null);
  const [isActiveType, setIsActiveType] = useState<string>('Active');

  const { isFetching: isLoading, refetch } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataCustomerConfig', IdCustomer, isActiveType],
    queryFn: () => getCustomerConfigReport({ IdCustomer, isActiveType }),
    enabled: !!IdCustomer,
    refetchOnWindowFocus: false,
    onSuccess: res => {
      if (res.data?.length > 0) {
        setDataFormatReport(res.data);
      } else {
        setDataFormatReport([]);
      }
    },
  });

  const { isFetching: isLoadingCsvList } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataCustomerCsvList', IdCustomer],
    queryFn: () => getCustomerDetailCsvList({ IdCustomer }),
    enabled: !!IdCustomer,
    refetchOnWindowFocus: false,
    onSuccess: res => {
      if (res?.data.length > 0) {
        setDataCsvList(res.data);
      } else {
        setDataCsvList([]);
      }
    },
  });

  const handleBack = () => {
    setDataFormatReport([]);
    history.push('/customer');
  };

  const handleCancelModalAdd = () => {
    setShowModalAdd(false);
    setShowModalEditId(null);
  };

  const handleDetailConfig = (id: number) => {
    actionFormConfigReport.changeIdCustomerReport(id);
    actionFormConfigReport.changeDetailEditCustomerConfigReport();
    actionTableCustomReportDataCustomer.GetTableCustomReportDataCustomer();
    actionLastAccess.changeLastAccessFiture('DetailConfigReport');
  };

  return (
    <>
      <CustomerReportComponents
        CustomerName={CustomerName}
        dataFormatReport={dataFormatReport}
        isLoading={isLoading || isLoadingCsvList}
        handleBack={handleBack}
        setShowModalAdd={() => setShowModalAdd(true)}
        setShowModalEdit={id => setShowModalEditId(id)}
        handleDetailConfig={handleDetailConfig}
        setIsActiveType={setIsActiveType}
        isActiveType={isActiveType}
      />
      <AddCustomerFormatReport
        show={showModalAdd}
        onClickCancel={handleCancelModalAdd}
        customerName={CustomerName}
        dataCsvList={dataCsvList}
        customerId={IdCustomer}
        refetch={refetch}
      />
      <EditCustomerFormatReport
        show={showModalEditId}
        onClickCancel={handleCancelModalAdd}
        customerName={CustomerName}
        dataCsvList={dataCsvList}
        customerId={IdCustomer}
        refetch={refetch}
      />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  CustomerName: state.Customer.DetailCustomer.CustomerName,
  IdCustomer: state.Customer.PropertiesCustomer.IdCustomer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionFormConfigReport: bindActionCreators(FormConfigReport, dispatch),
  actionTableCustomReportDataCustomer: bindActionCreators(
    ActionTableCustomReportDataCustomer,
    dispatch
  ),
  actionLastAccess: bindActionCreators(ActionLastAccess, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(CustomerReport);
