import React, { Component } from 'react';

import HeaderContainer from '../containers/Header/HeaderContainer';
import { Layout } from 'antd';
import Navigation from '../navigation';
import PrivacyPolicyContainer from '../containers/Content/PrivacyPolicy/PrivacyPolicyContainer';
import SidebarComponent from '../containers/Sidebar/SidebarContainer';
import { compose } from 'redux';
import { connect } from 'react-redux';

// import a from '../containers/Sidebar2/Sidebar2Container'

interface IProps {
  Token: any;
  Route: any;
}
class IndexNavigation extends Component<IProps> {
  render() {
    return this.props.Token === null ? (
      this.props.Route === '/PrivacyPolicy' ? (
        <PrivacyPolicyContainer />
      ) : (
        <Navigation />
      )
    ) : this.props.Route === '/PrivacyPolicy' ? (
      <PrivacyPolicyContainer />
    ) : (
      <Layout style={{ minHeight: '100vh' }}>
        <SidebarComponent />
        <Layout>
          <HeaderContainer />
          <Layout>
            <Navigation />
          </Layout>
        </Layout>
      </Layout>
    );
  }
}
const mapStateToProps = (state: any) => ({
  Token: state.Auth.Token,
  Route: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch: any) => ({});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(IndexNavigation);
