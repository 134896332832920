import * as ActionAuth from '../../../../core/action//Auth';
import * as ActionComponent from '../../../App/Store/ComponentAction';
import * as ActionLevel from '../../Level/Store/LevelAction';
import * as ActionLogin from '../../../Login/Store';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';
import { getFormValues } from 'redux-form';

// import * as SelectorApp from '../../../App/Selector/AppSelector';

function* S_getLevelListProcess(action: any) {
  const state = yield select();
  //   const token = state.Auth.Token;
  //   const config = {
  //     Authorization: `bearer ${token}`,
  //     'Content-type': 'application/json',
  //   };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;

  const levelDataDummy = {
    totalDatas: 0,
    totalPages: 0,
    page: 0,
    data: [
      {
        id: 1,
        levelName: 'Staff',
        status: true,
      },
      {
        id: 2,
        levelName: 'Manager',
        status: true,
      },
    ],
  };
  try {
    // apiLink();
    yield put(ActionComponent.processLoading(true));
    // const { data } = yield call(
    //   axios.get,
    //   `${process.env.REACT_APP_URL}/LevelPermission/{id}`,
    //   { headers: config }
    // );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(
      ActionComponent.setTotalPagePagination(levelDataDummy.totalPages * 10)
    );

    yield put(ActionLevel.fetchLevelListData(levelDataDummy.data));
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

function* S_getModuleListProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;

  try {
    yield put(ActionComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Menus/All`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(ActionLevel.fetchModuleListData(data));
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

function* S_submitLevelProcess(action: any) {
  const state = yield select();
  // const token = state.Auth.Token;
  // const config = {
  //   Authorization: `bearer ${token}`,
  //   'Content-type': 'application/json',
  // };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;

  const formValue = yield select(getFormValues('addLevelFormComplaint'));
  const menuSelected = () => {
    // const valueMenu: any = [];
    formValue.treeValue.forEach((element, index) => {
      // const data: any = element;
      // data.name = listKey[index];
      // data.secondName = secondName;
      // listObj.push(data);
      console.log('element ', element);
      console.log('index ', index);
      if (element.length > 2) {
      }
    });
  };
  // const postData = {
  //   levelName: formValue.levelName,
  //   isActive: formValue.checkboxStatus,
  //   menus: [
  //     {
  //       menu: {
  //         id: 0,
  //       },
  //       permission: {
  //         view: true,
  //       },
  //       subMenu: [
  //         {
  //           id: 0,
  //           permission: {
  //             view: true,
  //           },
  //         },
  //       ],
  //     },
  //   ],
  // };
  try {
    menuSelected();
    yield put(ActionComponent.processLoading(true));
    // const { data } = yield call(
    //   axios.post,
    //   `${process.env.REACT_APP_URL}/LevelPermission`,
    //   {
    //     postData,
    //   },
    //   { headers: config }
    // );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    Modal.success({
      title: <p className={'titleModalNotifSuccess'}>popup sukses</p>,

      onOk() {},
    });
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

export function* SM_submitLevelAction() {
  yield takeLatest('SUBMIT_LEVEL_REQUESTED', S_submitLevelProcess);
}
export function* SM_getModuleListAction() {
  yield takeLatest('GET_MODULE_LIST_REQUESTED', S_getModuleListProcess);
}

export function* SM_getLevelListAction() {
  yield takeLatest('GET_LEVEL_DATA_LIST_REQUESTED', S_getLevelListProcess);
}
