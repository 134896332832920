import * as ActionAuth from '../../action/Auth';
import * as ActionCustomerConfig from '../../action/Customer/ActionCustomerStatusConfig';
import * as ActionDetailCustomStatus from '../../action/Customer/ActionDetailCustomStatus';
import * as ActionDetailCustomer from '../../action/Customer/ActionDetailCustomer';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_detailCustomStatus(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const IdStatus = state.Customer.PropertiesCustomer.IdStatus;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Setting/CustomStatus/' + IdStatus,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.changeModal());
    yield put(toggle.changeJenisToggle('EditStatus'));
    yield put(ActionDetailCustomStatus.changeIdStatus(data.id));
    yield put(ActionCustomerConfig.changeCode(data.code));
    yield put(ActionCustomerConfig.changeDescription(data.description));
    yield put(ActionCustomerConfig.changeLabel(data.label));
    yield put(ActionCustomerConfig.changePosition(data.position));
    yield put(ActionCustomerConfig.changeAttempt(data.attempt));
    yield put(ActionCustomerConfig.changeFreeText(data.additionalText));
    yield put(ActionCustomerConfig.changeYBS(data.ybs));
    yield put(ActionDetailCustomer.changeCustomerId(data.customerId));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}

export function* SM_detailCustomStatus() {
  yield takeLatest('CHANGE_DETAIL_STATUS', S_detailCustomStatus);
}
