import React from 'react';
import moment from 'moment';
import Button from 'components/Button';
import styles from './ModalDownloadListManifest.module.css';
import ModalMaterial from 'materials/ModalMaterial';

type ModalDownloadListManifestComponentProps = {
  selectedData: any;
  isShow: boolean;
  isLoadingDownload?: boolean;
  onCancel: () => void;
  onClickDownload?: () => void;
};

const RenderContentInfo = ({ label, value }: { label: string; value: any }) => {
  return (
    <div className={styles.wrapperContentInfo}>
      <p className={styles.labelContentInfo}>{label}</p>
      <p className={styles.valueContentInfo}>: {value}</p>
    </div>
  );
};

export default function ModalDownloadListManifestComponent({
  isShow,
  selectedData,
  onCancel,
  onClickDownload = () => undefined,
  isLoadingDownload = false,
}: ModalDownloadListManifestComponentProps) {
  return (
    <ModalMaterial
      teksTitle="DOWNLOAD DAFTAR MANIFEST"
      visible={isShow}
      onCancel={onCancel}
      width="30%"
      content={
        <div className={styles.wrapperContent}>
          <p className={styles.titleContent}>
            Anda akan mendownload daftar manifest
          </p>
          <div className={styles.infoData}>
            <RenderContentInfo
              label="Kode Brankas"
              value={selectedData?.brankasCode}
            />
            <RenderContentInfo
              label="Kode Bundel"
              value={selectedData?.bundleCode}
            />
            <RenderContentInfo
              label="Pelanggan"
              value={selectedData?.customerName}
            />
            <RenderContentInfo
              label="Produk"
              value={selectedData?.customerProductName}
            />
            <RenderContentInfo
              label="Jumlah dokumen"
              value={selectedData?.totalDocument}
            />
            <RenderContentInfo
              label="Dibuat Oleh"
              value={selectedData?.createdBy}
            />
            <RenderContentInfo
              label="Tanggal Manifest"
              value={moment(selectedData?.createdAt).format('YYYY-MM-DD')}
            />
          </div>
          <p className={styles.bottomInfo}>
            Silahkan klik tombol dibawah untuk mendownload file
          </p>
          <Button
            variant="info"
            text="Download file"
            endIcon="download"
            loading={isLoadingDownload}
            onClick={onClickDownload}
          />
        </div>
      }
    />
  );
}
