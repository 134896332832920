import React, { useState } from 'react';
import styles from './JamDataTable.module.css';
import SpinMaterial from 'materials/SpinMaterial';
import CardMaterial from 'materials/CardMaterial';
import SearchMatrial from 'materials/SearchMatrial';
import ButtonMaterial from 'materials/ButtonMaterial';
import SelectField from 'components/SelectField';
import { ColumnAlignTable } from 'interface/general';
import { DownloadReportSLATypes } from 'lib/constants';
import { Row, Col, Table } from 'antd';
import { DataTableResProps } from 'interface/general';
import { trimText } from 'lib/util';

type JamDataTableProps = {
  dataTableJam: DataTableResProps;
  isLoadingTable: boolean;
  handlePage: (value: number) => void;
  handleSearchValue: (value: string) => void;
  selectedDownloadJam: string;
  setSelectedDownloadJam: (value: string) => void;
  handleDownloadFile: () => void;
};

export default function JamDataTable({
  isLoadingTable,
  dataTableJam,
  handlePage,
  handleSearchValue,
  selectedDownloadJam,
  setSelectedDownloadJam,
  handleDownloadFile,
}: JamDataTableProps) {
  const [valueSearch, setValueSearch] = useState('');

  const pagePagination = dataTableJam?.page + 1 || 0;
  const totalPagination = dataTableJam?.totalPages * 10 || 0;
  const looppage = (pagePagination - 1) * 10 + 1;

  const formatAgingText = (aging: any, index: any) => {
    return JSON.parse(aging)[index] || 0;
  };
  const columnsTable = [
    {
      title: 'No',
      render: (text: any, record: any, index: any) => (
        <span>{pagePagination > 1 ? index + looppage : index + 1}</span>
      ),
      width: 80,
    },
    {
      title: 'Nama Kurir',
      dataIndex: 'courierName',
    },
    {
      title: 'Kode Kurir',
      align: 'center' as ColumnAlignTable,
      dataIndex: 'courierCode',
    },

    {
      title: 'Posko',
      dataIndex: 'branchName',
      align: 'center' as ColumnAlignTable,
    },

    {
      title: 'Jam',
      className: styles.columnTableHead,
      children: [
        {
          title: '<8',
          dataIndex: 'hour',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, '<8');
          },
        },
        {
          title: '8',
          dataIndex: 'hour',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 8);
          },
        },
        {
          title: '9',
          dataIndex: 'hour',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 9);
          },
        },
        {
          title: '10',
          dataIndex: 'hour',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 10);
          },
        },
        {
          title: '11',
          dataIndex: 'hour',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 11);
          },
        },
        {
          title: '12',
          dataIndex: 'hour',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 12);
          },
        },
        {
          title: '13',
          dataIndex: 'hour',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 13);
          },
        },
        {
          title: '14',
          dataIndex: 'hour',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 14);
          },
        },
        {
          title: '15',
          dataIndex: 'hour',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 15);
          },
        },
        {
          title: '16',
          dataIndex: 'hour',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 16);
          },
        },
        {
          title: '17',
          dataIndex: 'hour',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 17);
          },
        },
        {
          title: '>17',
          dataIndex: 'hour',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, '>17');
          },
        },
      ],
    },
    {
      title: 'Total',
      align: 'center' as ColumnAlignTable,
      dataIndex: 'total',
    },
  ];
  return (
    <SpinMaterial spinning={isLoadingTable} size="large">
      <CardMaterial style={{ borderRadius: '10px 10px 0 0' }}>
        <Row>
          <Col span={18}>
            <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
              <span className="titleSearchCard" style={{ padding: 0 }}>
                SEMUA DATA
              </span>
              <SearchMatrial
                style={{ borderRadius: '10px', width: '50%' }}
                placeholder={'Cari berdasarkan nama/kode kurir'}
                value={valueSearch}
                onChange={e => setValueSearch(trimText(e.target.value))}
                onPressEnter={e => handleSearchValue(e.target.value)}
              />
            </div>
          </Col>
        </Row>
      </CardMaterial>
      <Table
        columns={columnsTable}
        bordered
        dataSource={dataTableJam?.data || []}
        pagination={{
          onChange: page => {
            handlePage(page - 1);
          },
          pageSize: 10,
          total: totalPagination,
          current: pagePagination === 0 ? 1 : pagePagination,
        }}
        footer={() => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              gap: 8,
            }}
          >
            <SelectField
              placeholder="Pilih Jenis Form"
              style={{ width: '200px' }}
              value={selectedDownloadJam}
              data={DownloadReportSLATypes}
              onChange={value => setSelectedDownloadJam(value)}
            />
            <ButtonMaterial
              icon="download"
              type="download"
              style={{
                ...(selectedDownloadJam &&
                  dataTableJam?.data.length >= 0 && {
                    background: '#874FFF',
                    color: 'white',
                  }),
              }}
              disabled={
                !selectedDownloadJam ||
                !dataTableJam?.data ||
                dataTableJam?.data.length === 0
              }
              handleSubmit={handleDownloadFile}
            />
            <p style={{ margin: 0, fontWeight: 500 }}>
              Total Entri ({dataTableJam?.totalDatas || 0})
            </p>
          </div>
        )}
      />
    </SpinMaterial>
  );
}
