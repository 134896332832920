import React from 'react';
import { List } from 'antd';
import styles from './ModalDocumentScan.module.css';
import ModalMaterial from 'materials/ModalMaterial';
import SpinMaterial from 'materials/SpinMaterial';

type ModalDocumentScanProps = {
  isScan: boolean;
  show: boolean;
  isLoading: boolean;
  onClose: () => void;
  dataScan: any[];
};

export default function ModalDocumentScan({
  isScan = false,
  show = false,
  isLoading = false,
  onClose = () => undefined,
  dataScan = [],
}: ModalDocumentScanProps) {
  return (
    <ModalMaterial
      teksTitle={`DOKUMEN ${isScan ? 'SUDAH' : 'BELUM'} DI SCAN`}
      width="70%"
      visible={show}
      onCancel={onClose}
    >
      <div className={styles.wrapperTitle}>
        <p>
          {isScan ? 'Scan' : 'Belum di scan'} {dataScan.length}
        </p>
      </div>
      <div className={styles.wrapperBody}>
        <SpinMaterial
          spinning={isLoading}
          style={{}}
          size={'large'}
          content={
            <List
              size="small"
              itemLayout="horizontal"
              dataSource={dataScan}
              pagination={{
                pageSize: 10,
              }}
              renderItem={(item: any) => (
                <List.Item>
                  <List.Item.Meta
                    description={
                      <span
                        style={{
                          color: isScan ? '#11BEFF' : '#FF5858',
                        }}
                      >
                        {item.waybillCode}
                      </span>
                    }
                  />
                </List.Item>
              )}
            />
          }
        />
      </div>
    </ModalMaterial>
  );
}
