import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsPickUpProcess from '../../PickUpProcess/Selector/PickUpProcessSelector';
import * as actionPickUpComponent from '../Store/PickUpProcessAction';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import CButton from '../../../apps/assets/components/CButton';
import PickUpProcessComponent from '../Component/PickUpProcessComponent';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import history from '../../../core/utils/History';

function PickUpProcessContainer(props: any) {
  const { isLoading, pickUpAction } = props;
  useEffect(() => {
    pickUpAction.getPickUpCardList();
  }, [pickUpAction]);
  const handleSyncron = () => {
    pickUpAction.getPickUpCardList();
  };

  const buttonDetail = item => {
    const detailClick = () => {
      pickUpAction.setCustId(item.custId);
      pickUpAction.getDetailPickUpList();
      history.push('/processPickUp/detailPickUp');
    };
    const dataButton = [
      {
        type: 'primary',
        id: 'btnDetailQa',
        className: 'btnDetailPickupProcess',
        isLoading: isLoading,
        content: 'Lihat Detail',
        onClick: detailClick,
      },
    ];
    return <CButton buttonData={dataButton} />;
  };
  return (
    <React.Fragment>
      <PickUpProcessComponent
        isLoading={isLoading}
        handleSyncron={handleSyncron}
        handleDetailPickUp={buttonDetail}
        {...props}
      />
    </React.Fragment>
  );
}

const mapStateToProps = createStructuredSelector({
  isLoading: SelectorsComponent.makeIsLoading(),
  pickUpProcessList: SelectorsPickUpProcess.makePickUpProcessListSelector(),
  formValue: getFormValues('pickUpProcess'),
});

const mapDispatchToProps = (dispatch: any) => ({
  pickUpAction: bindActionCreators(actionPickUpComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PickUpProcessContainer);
