import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import ModalStatus from 'materials/ModalStatus';
import {
  postManifestSaveCode,
  putManifestSaveCode,
} from 'services/ManifestSaveCodeServices';
import ModalConfirmation from 'components/ModalConfirmation';
import ModalAddCodeReturComponent from '../Component';
import { SelectOptionsInterface } from 'interface/general';

type ModalAddCodeReturProps = {
  onCancel: () => void;
  refetchManifestRetur: () => void;
  isShow: boolean;
  dataManifestRetur: SelectOptionsInterface[];
};

export default function ModalAddCodeRetur({
  isShow,
  onCancel,
  refetchManifestRetur,
  dataManifestRetur,
}: ModalAddCodeReturProps) {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [manifestSafeCode, setManifestSafeCode] = useState<string>('');
  const [manifestSafeCodeId, setManifestSafeCodeId] = useState<number | null>(
    null
  );

  const { mutate: submitManifestSafeCode, isLoading: isLoadingSubmit } =
    useMutation(postManifestSaveCode, {
      onSuccess: res => {
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Berhasil diubah',
            content: 'Kode brankas berhasil diubah.',
          });
          handleClickSave();
          onCancel();
          handleClearForm();
          refetchManifestRetur();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Menjalankan Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
          handleClearForm();
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
        handleClearForm();
      },
    });
  const { mutate: putManifestSafeCode, isLoading: isLoadingPutSubmit } =
    useMutation(putManifestSaveCode, {
      onSuccess: res => {
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Berhasil diubah',
            content: 'Kode brankas berhasil diubah.',
          });
          handleClickSave();
          onCancel();
          handleClearForm();
          refetchManifestRetur();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Menjalankan Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
          handleClearForm();
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
        handleClearForm();
      },
    });

  const handleClickSave = () => {
    setShowConfirm(!showConfirm);
  };

  const handleClearForm = () => {
    setManifestSafeCode('');
    setManifestSafeCodeId(null);
  };

  const handleSaveData = () => {
    const data = {
      code: manifestSafeCode,
      ...(manifestSafeCodeId && { id: manifestSafeCodeId, isDeleted: false }),
    };

    if (manifestSafeCodeId) {
      putManifestSafeCode(data);
    } else {
      submitManifestSafeCode(data);
    }
  };

  return (
    <>
      <ModalConfirmation
        visibleModal={showConfirm}
        title="Apakah anda yakin?"
        description="Pastikan anda telah memeriksa kembali hasil input data."
        onConfirm={handleClickSave}
        onCancel={handleSaveData}
        isLoadingYa={isLoadingSubmit || isLoadingPutSubmit}
        okeText="TIDAK"
        cancelText="YA"
      />
      <ModalAddCodeReturComponent
        isShow={isShow}
        onCancel={onCancel}
        dataManifestRetur={dataManifestRetur}
        onSave={handleClickSave}
        manifestSafeCode={manifestSafeCode}
        setManifestSafeCode={value => setManifestSafeCode(value)}
        setManifestSafeCodeId={value => setManifestSafeCodeId(value)}
      />
    </>
  );
}
