import { Col, DatePicker, Form, Radio, Row, Select } from 'antd';

import ButtonMaterial from 'materials/ButtonMaterial';
import CardMaterial from 'materials/CardMaterial';
import DigitalWaybillByUploadContainer from 'module/Report/Container/DigitalWaybill/DigitalWaybillByUploadContainer';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';

interface IProps {
  handleRadioButton: any;
  getRadioButton: any;
  handlePutFirstDate: any;
  handleValidateFirstDate: any;
  valueFirstDate: any;
  getFirstDate: any;
  validateFirstDate: any;
  helpFirstDate: any;
  handlePutLastDate: any;
  handleValidateLastDate: any;
  getLastDate: any;
  valueFinishDate: any;
  validateLastDate: any;
  helpLastDate: any;
  handleGetCustomer: any;
  listCustomer: any;
  listCustomerProduct: any;
  loadingSelectCustomer: any;
  handlePutCustomer: any;
  validateCustomer: any;
  helpCustomer: any;
  getCustomer: any;
  handleBlurCustomer: any;
  handlePutCustomerProduct: any;
  handleGetCustomerProduct: any;
  handleBlurCustomerProduct: any;
  getCustomerProduct: any;
  validateCustomerProduct: any;
  helpCustomerProduct: any;
  getDeliveryType: any;
  getDeliveryStatus: any;
  handlePutDeliveryType: any;
  validateDeliveryType: any;
  helpDeliveryType: any;
  handlePutDeliveryStatus: any;
  validateDeliveryStatus: any;
  helpDeliveryStatus: any;
  validateFlagFileName: any;
  helpFlagFileName: any;
  handlePutFlagFileName: any;
  handleBlurFlagFilename: any;
  getFlagFileName: any;
  handleClearCustProduct: any;
  handleDownload: any;
  handleReset: any;
  validateDownloadButton: any;
  validateResetButton: any;
  handleDowloadByUpload: any;
  isLoading: any;
  jenisToggle: any;
}
export default function DigitalWaybillComponent({
  handleRadioButton,
  getRadioButton,
  handlePutFirstDate,
  handleValidateFirstDate,
  valueFirstDate,
  getFirstDate,
  validateFirstDate,
  helpFirstDate,
  handlePutLastDate,
  handleValidateLastDate,
  getLastDate,
  valueFinishDate,
  validateLastDate,
  helpLastDate,
  handleGetCustomer,
  listCustomer,
  listCustomerProduct,
  loadingSelectCustomer,
  handlePutCustomer,
  validateCustomer,
  helpCustomer,
  getCustomer,
  handleBlurCustomer,
  handlePutCustomerProduct,
  handleGetCustomerProduct,
  handleBlurCustomerProduct,
  getCustomerProduct,
  validateCustomerProduct,
  helpCustomerProduct,
  getDeliveryType,
  getDeliveryStatus,
  handlePutDeliveryType,
  validateDeliveryType,
  helpDeliveryType,
  handlePutDeliveryStatus,
  validateDeliveryStatus,
  helpDeliveryStatus,
  validateFlagFileName,
  helpFlagFileName,
  handlePutFlagFileName,
  handleBlurFlagFilename,
  getFlagFileName,
  handleClearCustProduct,
  handleDownload,
  handleReset,
  validateDownloadButton,
  validateResetButton,
  handleDowloadByUpload,
  isLoading,
  jenisToggle,
}: IProps) {
  const { Option } = Select;
  const formatDate = 'YYYY-MM-DD';
  const formatDateShow = 'DD-MM-YYYY';
  const firstDateV = getFirstDate;
  const minDate2 = (d: any) =>
    !d || d.isAfter(moment(new Date()).add(1, 'day').format(formatDate));
  const minDate = (d: any) =>
    !d ||
    d.isBefore(moment(getFirstDate).add(0, 'day').format(formatDate)) ||
    d.isAfter(moment(getFirstDate).add(30, 'day').format(formatDate));
  const validateProductForm = getCustomer === null ? true : false;

  const labelFromDate = (dateType?: string) => {
    switch (dateType) {
      case 'Cycle':
        return 'Tanggal Cycle Dari';
      case 'Process':
        return 'Tanggal Upload Dari';
      default:
        return 'Tanggal Cycle Dari';
    }
  };

  return (
    <div>
      <SpinMaterial
        spinning={
          isLoading === true && jenisToggle === 'WaybillDigital' ? true : false
        }
        style={{}}
        size={'large'}
        content={
          <CardMaterial
            extra={''}
            title={<b className="titleCard">Waybill Digital</b>}
            style={{ borderRadius: '10px' }}
            content={
              <div>
                <Row>
                  <Col span={12} className="spacingRight">
                    <div className="spacingTopRadioDigitalWb">
                      <Radio.Group
                        onChange={handleRadioButton}
                        value={getRadioButton}
                      >
                        <Radio value={'Cycle'}>Cycle</Radio>
                        <Radio value={'Process'}>Upload</Radio>
                      </Radio.Group>
                    </div>
                    <div className="spacingTopLeftReport">
                      <span>
                        <b>{labelFromDate(getRadioButton)}</b>
                      </span>
                      <Form.Item
                        validateStatus={validateFirstDate}
                        help={helpFirstDate}
                      >
                        <DatePicker
                          className={'datePickerDefault'}
                          locale={locale}
                          format={formatDateShow}
                          placeholder={'Pilih tanggal'}
                          onOpenChange={handleValidateFirstDate}
                          value={valueFirstDate ? valueFirstDate : getFirstDate}
                          onChange={handlePutFirstDate}
                          disabledDate={minDate2}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Pelanggan</b>
                      </span>
                      <Form.Item
                        validateStatus={validateCustomer}
                        help={helpCustomer}
                      >
                        <Select
                          loading={loadingSelectCustomer}
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          showSearch
                          placeholder={'Pilih Pelanggan'}
                          onSelect={handleClearCustProduct}
                          onChange={handlePutCustomer}
                          onBlur={handleBlurCustomer}
                          value={
                            getCustomer === null ? (
                              <span className="colorPlaceholderDropDown">
                                Pilih Pelanggan
                              </span>
                            ) : (
                              getCustomer
                            )
                          }
                          onFocus={handleGetCustomer}
                        >
                          {listCustomer.map((data: any, index: any) => (
                            <Option
                              key={index}
                              value={data.id}
                              label={data.name}
                            >
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Tipe Pengiriman</b>
                      </span>
                      <Form.Item
                        validateStatus={validateDeliveryType}
                        help={helpDeliveryType}
                      >
                        <Select
                          style={{ width: '100%' }}
                          mode="multiple"
                          disabled={false}
                          onBlur={handlePutDeliveryType}
                          onChange={handlePutDeliveryType}
                          placeholder="Pilih Tipe Pengiriman"
                          value={getDeliveryType}
                        >
                          <Option key={''} value={'reguler'}>
                            Reguler
                          </Option>
                          <Option key={''} value={'KU'}>
                            Kirim Ulang
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Penamaan File</b>
                      </span>
                      <Form.Item
                        validateStatus={validateFlagFileName}
                        help={helpFlagFileName}
                      >
                        <Select
                          style={{ width: '100%' }}
                          disabled={false}
                          onBlur={handleBlurFlagFilename}
                          onChange={handlePutFlagFileName}
                          placeholder="Pilih penamaan file"
                          value={
                            getFlagFileName === null ? (
                              <span className="colorPlaceholderDropDown">
                                Pilih penamaan file
                              </span>
                            ) : (
                              getFlagFileName
                            )
                          }
                        >
                          <Option key={''} value={'waybill'}>
                            Waybill
                          </Option>
                          <Option key={''} value={'polis'}>
                            No Polis
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12} className="spacingLeft">
                    <div className="warningNoticeBox">
                      <span className="warningNotice">
                        Mohon untuk tidak mendownload data pada jam sibuk.
                        Disarankan untuk melakukan proses download diakhir hari
                        atau setelah tidak ada proses lagi
                      </span>
                    </div>
                    <div className="spacingTopDigitalWb">
                      <span>
                        <b>Sampai Tanggal</b>
                      </span>
                      <Form.Item
                        validateStatus={validateLastDate}
                        help={helpLastDate}
                      >
                        <DatePicker
                          className={'datePickerDefault'}
                          locale={locale}
                          format={formatDateShow}
                          placeholder={'Pilih tanggal'}
                          onOpenChange={handleValidateLastDate}
                          defaultPickerValue={moment(firstDateV, formatDate)}
                          value={
                            valueFinishDate ? valueFinishDate : getLastDate
                          }
                          onChange={handlePutLastDate}
                          disabledDate={minDate}
                          disabled={
                            getFirstDate === null || getFirstDate === ''
                              ? true
                              : false
                          }
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Produk</b>
                      </span>
                      <Form.Item
                        validateStatus={validateCustomerProduct}
                        help={helpCustomerProduct}
                      >
                        <Select
                          mode="multiple"
                          placeholder="Pilih Produk Pelanggan"
                          showSearch
                          allowClear={true}
                          style={{ width: '100%' }}
                          disabled={validateProductForm}
                          onFocus={handleGetCustomerProduct}
                          onSelect={handleBlurCustomerProduct}
                          onBlur={handlePutCustomerProduct}
                          onChange={handlePutCustomerProduct}
                          value={getCustomerProduct}
                        >
                          {getCustomerProduct.length === 0 ? (
                            <Option key={''} value={'Pilih Semua Produk'}>
                              Pilih Semua Produk
                            </Option>
                          ) : null}
                          {listCustomerProduct.map((data: any, index: any) => (
                            <Option key={index} value={data.id}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Status Pengiriman</b>
                      </span>
                      <Form.Item
                        validateStatus={validateDeliveryStatus}
                        help={helpDeliveryStatus}
                      >
                        <Select
                          style={{ width: '100%' }}
                          mode="multiple"
                          disabled={false}
                          onBlur={handlePutDeliveryStatus}
                          onChange={handlePutDeliveryStatus}
                          placeholder="Pilih Status"
                          value={getDeliveryStatus}
                        >
                          {/* <Option value={'OnProcces'}>Proses</Option> */}
                          <Option value={'Success'}>Diterima</Option>
                          <Option value={'Return'}>Dikembalikan</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}></Col>
                  <Col span={12}>
                    <ButtonMaterial
                      disabled={false}
                      handleSubmit={handleDowloadByUpload}
                      teksButton={<u>Export Berdasarkan Waybill/Barcode</u>}
                      size={''}
                      shape={''}
                      className={'linkWBUpload'}
                      type={'link'}
                      icon={''}
                      style={{}}
                    />
                  </Col>
                </Row>
                <Row>
                  <div className={'spacingDownloadButton'}>
                    <ButtonMaterial
                      disabled={validateDownloadButton}
                      handleSubmit={handleDownload}
                      teksButton={<b>Download</b>}
                      size={'middle'}
                      shape={'round'}
                      className={
                        validateDownloadButton === false
                          ? 'validateDownloadButtonEnable'
                          : 'validateDownloadButtonDisabled'
                      }
                      type={'primary'}
                      icon={''}
                      style={{}}
                    />
                  </div>
                  <div>
                    <ButtonMaterial
                      disabled={validateResetButton}
                      handleSubmit={handleReset}
                      teksButton={<b>Reset</b>}
                      size={'middle'}
                      shape={'round'}
                      className={
                        validateResetButton === false
                          ? 'validateResetButtonEnable'
                          : 'validateResetButtonDisabled'
                      }
                      type={'primary'}
                      icon={''}
                      style={{}}
                    />
                  </div>
                </Row>
              </div>
            }
          />
        }
      />
      <DigitalWaybillByUploadContainer />
    </div>
  );
}
