import React from 'react';
import { Table } from 'antd';
import styles from './ModalSelectBarcode.module.css';
import SpinMaterial from 'materials/SpinMaterial';
import ModalMaterial from 'materials/ModalMaterial';
import { ScanBarcodeMultiple } from '../../module/DeliveryBetweenPosts/Component/BaggingDocument/Components/ModalScanWaybil';
import { ColumnAlignTable } from 'interface/general';

type ModalSelectBarcodeProps = {
  isShowModal: boolean;
  handleCloseModal: () => void;
  dataScanBarcode: ScanBarcodeMultiple[];
  handleEnterWaybil: (value: any) => void;
};

export default function ModalSelectBarcode({
  isShowModal,
  handleCloseModal,
  dataScanBarcode,
  handleEnterWaybil,
}: ModalSelectBarcodeProps) {
  const columnsTable = [
    {
      title: 'Waybill',
      dataIndex: 'waybill',
    },
    {
      title: 'Barcode',
      dataIndex: 'barcode',
      width: 500,
    },
    {
      title: 'Tanggal Cycle',
      dataIndex: 'cycle',
    },
    {
      title: 'Nama',
      dataIndex: 'name',
    },
    {
      title: 'Aksi',
      align: 'center' as ColumnAlignTable,
      render: (record: any) => (
        <p
          style={{
            color: '#0096EE',
            fontSize: 14,
            fontWeight: 700,
            textDecoration: 'underline',
            margin: 0,
            cursor: 'pointer',
          }}
          onClick={() => handleEnterWaybil(record.waybill)}
        >
          Pilih
        </p>
      ),
    },
  ];
  return (
    <ModalMaterial
      teksTitle="Pilih Barcode untuk di-Scan"
      width="80%"
      visible={isShowModal}
      onCancel={handleCloseModal}
    >
      <SpinMaterial spinning={false} size={'large'}>
        <div style={{ marginTop: 24, padding: '0 24px' }}>
          <p className={styles.textDesc}>
            Karena Barcode duplikat, pilih salah satu data
          </p>
          <Table
            columns={columnsTable}
            bordered
            dataSource={dataScanBarcode}
            scroll={{ x: 'auto' }}
          />
        </div>
      </SpinMaterial>
    </ModalMaterial>
  );
}
