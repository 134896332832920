import * as ActionLogin from '../../../module/Login/Store';
import * as clear from '../../../module/Login/Store/index';

import { ChangeRole, changeBranchAuth } from '../../action/Auth';
import { call, put, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_role(action) {
  const token = action.token;
  const usernameLogin = action.username;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    const response = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Profile/Role/${usernameLogin}`,
      { headers: config }
    );
    yield put(ChangeRole(response.data.roleAccount));
    yield put(changeBranchAuth(response.data.branch));

    yield put(clear.changeClearUsername());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
    }
  }
}

export function* SM_role() {
  yield takeLatest('GET_ROLE_REQUESTED', S_role);
}
