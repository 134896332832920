import * as ActionCustomDataReporCustomer from '../../../core/action/Customer/ActionCustomDataReportCustomer';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionTableCustomReporCustomer from '../../../core/action/Customer/ActionTableCustomReportDataCustomer';
import * as ActionToggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_deleteCustomReportDataCustomer() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
  };
  const IdCustomReportCustomer =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .IdCustomReportCustomer;

  try {
    yield put(ActionToggle.changeLoadingTrue());
    yield call(
      axios.put,
      process.env.REACT_APP_URL +
        '/Setting/CustomReportData/deactivate/' +
        IdCustomReportCustomer,
      {},
      { headers: config }
    );

    yield put(ActionToggle.clearJenisToggle());
    yield put(ActionCustomDataReporCustomer.clearAllCdrc());
    yield put(
      ActionTableCustomReporCustomer.ClearTableCustomReportDataCustomer()
    );
    yield put(
      ActionTableCustomReporCustomer.GetTableCustomReportDataCustomer()
    );
    yield put(ActionToggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(ActionToggle.changeLoadingFalse());
    } else {
      yield put(ActionToggle.changeLoadingFalse());
    }
  }
}

export function* SM_deleteCustomReportDataCustomer() {
  yield takeLatest(
    'CHANGE_DELETE_CUSTOM_REPORT_CUSTOMER',
    S_deleteCustomReportDataCustomer
  );
}
