import React, { useState } from 'react';
import { DatePicker, Icon, Card, Skeleton, Empty } from 'antd';
import SelectField from 'components/SelectField';
import styles from './styles.module.css';
import { SelectOptionsInterface } from 'interface/general';
import { useHistory } from 'react-router-dom';
import {
  DataFilterProps,
  DataPerformanceProps,
} from 'module/Dashboard/Container/Kpi';
import moment from 'moment-timezone';
import cx from 'classnames';
import { SingleCheckbox } from 'components/SingleCheckbox';
import PopoverElement from 'components/PopoverElement';

type DashboardKpiComponentProps = {
  isLoading: boolean;
  isLoadingCustomers: boolean;
  isLoadingProducts: boolean;
  dataCustomers: SelectOptionsInterface[];
  dataProducs: SelectOptionsInterface[];
  dataBranch: SelectOptionsInterface[];
  dataFilter: DataFilterProps;
  dataPerformance: DataPerformanceProps[];
  handleChangeFilter: (name: string, value: any) => void;
  selectedProducts: number[];
  handleChangeProducts: (value: number | null) => void;
  handleSubmitProduct: () => void;
};

export const HomeLocationIcon = ({ fill = '#616161' }: { fill?: string }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3831_115557)">
        <path
          d="M12 3.5L2 12.5H5V20.5H19V12.5H22L12 3.5ZM12 8.2C14.1 8.2 15.8 9.9 15.8 12C15.8 15 12 18.5 12 18.5C12 18.5 8.2 15 8.2 12C8.2 9.9 9.9 8.2 12 8.2ZM12 10.5C11.6022 10.5 11.2206 10.658 10.9393 10.9393C10.658 11.2206 10.5 11.6022 10.5 12C10.5 12.3978 10.658 12.7794 10.9393 13.0607C11.2206 13.342 11.6022 13.5 12 13.5C12.3978 13.5 12.7794 13.342 13.0607 13.0607C13.342 12.7794 13.5 12.3978 13.5 12C13.5 11.6022 13.342 11.2206 13.0607 10.9393C12.7794 10.658 12.3978 10.5 12 10.5Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3831_115557">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const ItemContentPerformance = ({
  label,
  value,
}: {
  label: string;
  value: any;
}) => {
  return (
    <>
      <div className={styles.wrapperItemContent}>
        <p className={styles.labelItemContent}>{label}</p>
        <p className={styles.valueItemContent}>{value}</p>
      </div>
      <hr className={styles.lineContent} />
    </>
  );
};

export const CardPerformance = ({
  index,
  onClick,
  header = '0',
  totalInbound = '0',
  inbound = '0',
  overallSla = '0',
  successRate = '0',
  productivityCourier = '0',
  returnRate = '0',
  hitRate = '0',
}: {
  index: number;
  onClick: () => void;
  header: string;
  totalInbound: string;
  inbound: string;
  overallSla: string;
  successRate: string;
  productivityCourier: string;
  returnRate: string;
  hitRate: string;
}) => {
  return (
    <div
      className={
        index === 0
          ? styles.wrapperCardPerformanceZero
          : styles.wrapperCardPerformance
      }
    >
      <div
        className={
          index === 0
            ? styles.headerCardPerformanceZero
            : styles.headerCardPerformance
        }
      >
        <HomeLocationIcon fill={index === 0 ? '#fff' : '#616161'} />
        <p className={styles.textHeader}>{header}</p>
      </div>
      <div className={styles.bodyCardPerformance}>
        <div className={styles.wrapperContentBody}>
          <ItemContentPerformance label="Total Inbound" value={totalInbound} />
          <ItemContentPerformance label="Inbound/ day" value={inbound} />
          <ItemContentPerformance label="Overall SLA %" value={overallSla} />
          <ItemContentPerformance label="Success Rate %" value={successRate} />
          <ItemContentPerformance
            label="Productivity Kurir"
            value={productivityCourier}
          />
          <ItemContentPerformance label="Return Rate %" value={returnRate} />
          <ItemContentPerformance label="Hit Rate" value={hitRate} />
        </div>
        <div className={styles.wrapperLinkDetail} onClick={onClick}>
          <p className={styles.detailTextLink}>Lihat detail</p>
          <Icon type="right" className={styles.detailIconLink} />
        </div>
      </div>
    </div>
  );
};

export const FilterProduct = ({
  dataProducs,
  value,
  onChange,
}: {
  dataProducs: SelectOptionsInterface[];
  value: number[];
  onChange: (value: number) => void;
}) => {
  return (
    <div>
      {dataProducs.map(product => (
        <SingleCheckbox
          labelRight={product.name}
          value={!!value.find(item => item === +product.id)}
          onChange={() => onChange(+product.id)}
        />
      ))}
    </div>
  );
};

export default function DashboardKpiComponent({
  dataFilter,
  isLoading,
  isLoadingCustomers,
  isLoadingProducts,
  dataBranch,
  dataCustomers,
  handleChangeFilter,
  dataPerformance,
  dataProducs,
  selectedProducts,
  handleChangeProducts,
  handleSubmitProduct,
}: DashboardKpiComponentProps) {
  const [showFilterProduct, setShowFilterProduct] = useState<boolean>(false);
  const history = useHistory();

  const handleClickDetail = (item: DataPerformanceProps) => {
    let link = `/laporan/ringkasan?activeTab=3&datePeriod=${dataFilter.selectedDates[0].format(
      'DD-MM-YYYY'
    )}&dateUntil=${dataFilter.selectedDates[1].format('DD-MM-YYYY')}&customer=${
      dataFilter.customerId
    }&branch=${item.branchId}`;

    if (dataFilter.typeBranch !== '0' && !item.branchId) {
      const branch = dataFilter.typeBranch === '1' ? 'internal' : 'vendor';
      link = link.concat(`&typeBranch=${branch}`);
    }

    history.push(link);
  };

  const isEmpty = !isLoading && dataPerformance.length === 0;

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperHeader}>
        <p className={styles.textFilter}>PERFORMA POSKO</p>
        <div className={styles.wrapperFilter}>
          <div className={styles.wrapperFilterItem}>
            <SelectField
              placeholder="Semua Posko"
              style={{ width: 190 }}
              data={dataBranch}
              value={dataFilter.typeBranch}
              onChange={value => handleChangeFilter('typeBranch', value)}
            />
            <SelectField
              placeholder="Semua Pelanggan"
              style={{ width: 190 }}
              loading={isLoadingCustomers}
              data={dataCustomers}
              value={dataFilter.customerId}
              onChange={value => {
                handleChangeProducts(null);
                handleChangeFilter('productIds', null);
                handleChangeFilter('customerId', value);
              }}
              allowClear
            />
            <PopoverElement
              placement="bottom"
              content={
                <FilterProduct
                  value={selectedProducts}
                  dataProducs={dataProducs}
                  onChange={value => handleChangeProducts(value)}
                />
              }
              show={showFilterProduct}
              onShow={setShowFilterProduct}
              disabled={!dataFilter.customerId || isLoadingProducts}
              onClickCancel={() => {
                handleChangeProducts(null);
                handleChangeFilter('productIds', null);
              }}
              onClickOke={handleSubmitProduct}
              labelCancel="Reset"
            >
              <div
                className={cx(
                  styles.wrapperFilterProduct,
                  !dataFilter.customerId && styles.disableFilterProduct
                )}
              >
                <Icon type="filter" theme="filled" />
                <p className={styles.textFilterProduct}>Filter Produk</p>
              </div>
            </PopoverElement>
          </div>
          <div className={styles.wrapperFilterItem}>
            <div className={styles.wrapperRangePicker}>
              <p>Periode</p>
              <DatePicker.RangePicker
                placeholder={['Hari ini', 'Hari ini']}
                value={dataFilter.selectedDates}
                onChange={value => handleChangeFilter('selectedDates', value)}
                onCalendarChange={dates =>
                  handleChangeFilter('selectedDates', dates)
                }
                format="DD-MM-YYYY"
                allowClear
                disabledDate={(current: any) => {
                  const startDate = dataFilter.selectedDates[0]; // Assuming selectedDates is an array [startDate, endDate]
                  const maxEndDate = moment(startDate).endOf('month');

                  // Disable dates that are not in the range [startDate, maxEndDate]
                  return current && current > maxEndDate;
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={isEmpty ? styles.wrapperBodyEmpty : styles.wrapperBody}>
        {isLoading &&
          [1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
            <Card style={{ borderRadius: 8, minHeight: 320 }} key={index}>
              <Skeleton loading={isLoading} active />
            </Card>
          ))}
        {!isLoading && dataPerformance.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Data Tidak Ditemukan"
            className={styles.wrapperEmpty}
          />
        )}
        {!isLoading &&
          dataPerformance.map((item: DataPerformanceProps, index: number) => (
            <CardPerformance
              index={index}
              key={index}
              header={item.branchName.replace('RDS Express', '')}
              totalInbound={item.totalInbound}
              inbound={item.inboundDay}
              overallSla={item.overalSla}
              successRate={item.successRate}
              productivityCourier={item.productivity}
              returnRate={item.returnRate}
              hitRate={item.hitRate}
              onClick={() => handleClickDetail(item)}
            />
          ))}
      </div>
    </div>
  );
}
