import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  Row,
  Select,
} from 'antd';
import {
  ModeEmergency,
  deliveryType,
  districtCode,
} from '../../../../core/enumerable/General';
import React, { Component } from 'react';

import ButtonGroup from 'antd/lib/button/button-group';
import ButtonMaterial from '../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../materials/CardMaterial';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../../materials/ModalMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';
import { ValidationMessage } from '../../../../core/function/Validasi';
import locale from 'antd/es/date-picker/locale/en_US';

const { Option } = Select;

interface IProps {
  loadingSelectBranch: any;
  loadingSelectCourier: any;
  // GetBranch:any
  // GetCourier:any
  ListBranch: any;
  ListCourier: any;
  ListKoli: any;
  isLoading: any;
  ValueScan: any;
  ValueCheckBoxKoli: any;
  fileName: any;
  ValueWeight: any;
  ValueMode: any;
  ValueModeProps: any;
  ValueDocumentType: any;
  ValueDistrict: any;
  ValueBranch: any;
  ValueBranchProps: any;
  ValueCourier: any;
  ValueDeliveryTime: any;
  ValueFinishTime: any;
  ValueNumberResi: any;
  ValueWaybill: any;
  ValueCsv: any;
  ValueBarcodeColumn: any;
  ValueTypeDelivery: any;
  ValueReceiverName: any;
  ValueDeliveryNote: any;
  validateReceiverName: any;
  validateDeliveryNote: any;
  validateMode: any;
  validateAmplopType: any;
  validateDistrict: any;
  validateBranch: any;
  validateCourier: any;
  validateDeliveryTime: any;
  validateFinishTime: any;
  validateResiNumber: any;
  validateWaybill: any;
  validateCsv: any;
  validateBarcodeColumn: any;
  validateTypeDelivery: any;
  helpValidateReceiverName: any;
  helpValidateDeliveryNote: any;
  handleGetBranch: any;
  handlePutBranch: any;
  handleGetCourier: any;
  handleSetValueScan: any;
  handleSelectKoli: any;
  handlePutMode: any;
  handlePutDocumentType: any;
  handlePutDistict: any;
  handlePutCourier: any;
  handlePutDeliveryTime: any;
  handlePutFinishTime: any;
  handlePutNumberResi: any;
  handlePutWaybill: any;
  handlePutCsv: any;
  handleChangeBarcodeColumn: any;
  handleChangeReceiverName: any;
  handleChangeDeliveryNote: any;
  handlePutTypeDelivery: any;
  handlePutKoli: any;
  handleRemoveKoli: any;
  handleAddListKoli: any;
  handleResetFormEmergency: any;
  handleSubmitFormEmergency: any;
  handleBlurMode: any;
  handleBlurAmplopType: any;
  handleBlurDistrict: any;
  handleBlurBranch: any;
  handleBlurCourier: any;
  handleBlurDeliveryTime: any;
  handleBlurFinishTime: any;
  handleBlurResiNumber: any;
  handleBlurWaybill: any;
  handleBlurCsv: any;
  handleBlurTypeDelivery: any;
  handleResetMode: any;
  handleResetAmplopType: any;
  handleResetDistrict: any;
  handleResetBranch: any;
  handleResetCourier: any;
  handleResetCsv: any;
  handleResetTypeDelivery: any;
  handleValidateDeliveryDate: any;
  handleValidateFinishDate: any;
  handleDownloadSample: any;
  ConditionButton: any;
  valueLDeliveryTime: any;
  valueLFinishTime: any;
  countSuccess: any;
  countFailed: any;
  handleDownloadResult: any;
  visible: any;
  jenisToggle: any;
  jenisToggle2: any;
  handleClearModal: any;
  handleClearResult: any;
}
export class EmergencyProcessComponent extends Component<IProps> {
  render() {
    return (
      <div style={{ paddingBottom: '35px' }}>
        <SpinMaterial
          spinning={this.props.isLoading}
          style={{}}
          size={'large'}
          content={
            <CardMaterial
              extra={''}
              title={<b style={{ color: '#11BEFF' }}>PROSES EMERGENCY</b>}
              style={{ borderRadius: '10px' }}
              content={
                <div>
                  <Row>
                    <Col span={12} style={{ paddingRight: '20px' }}>
                      <div>
                        <span>
                          <b>Mode</b>
                        </span>
                        <Form.Item
                          validateStatus={this.props.validateMode}
                          help={ValidationMessage(
                            this.props.validateMode,
                            'Mode wajib diisi !!!'
                          )}
                        >
                          <Select
                            style={{ width: '100%' }}
                            disabled={false}
                            placeholder="Pilih Mode"
                            onSelect={this.props.handleResetMode}
                            onBlur={this.props.handleBlurMode}
                            optionFilterProp="children"
                            value={this.props.ValueMode}
                            onChange={this.props.handlePutMode}
                          >
                            {ModeEmergency.map((data: any, index: any) => (
                              <Option key={index} value={data.value}>
                                {data.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <span>
                          <b>Wilayah</b>
                        </span>
                        <Form.Item
                          validateStatus={
                            this.props.validateDistrict === 'error'
                              ? 'error'
                              : ''
                          }
                          help={ValidationMessage(
                            this.props.validateDistrict,
                            'Wilayah wajib diisi !!!'
                          )}
                        >
                          <Select
                            style={{ width: '100%' }}
                            disabled={
                              this.props.ValueMode === 'UpdateArea'
                                ? this.props.ValueMode === 'UpdateArea' &&
                                  this.props.ValueScan === true
                                  ? false
                                  : true
                                : this.props.ValueMode === 'NoScan'
                                ? false
                                : true
                            }
                            placeholder="Pilih Wilayah"
                            onSelect={this.props.handleResetDistrict}
                            optionFilterProp="children"
                            value={this.props.ValueDistrict}
                            onChange={this.props.handlePutDistict}
                            onBlur={this.props.handleBlurDistrict}
                          >
                            {districtCode.map((data: any, index: any) => (
                              <Option key={index} value={data.value}>
                                {data.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <span>
                          <b>Posko</b>
                        </span>
                        <Form.Item
                          validateStatus={
                            this.props.validateBranch === 'error' ? 'error' : ''
                          }
                          help={ValidationMessage(
                            this.props.validateBranch,
                            'Posko wajib diisi !!!'
                          )}
                        >
                          <Select
                            loading={this.props.loadingSelectBranch}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            disabled={
                              this.props.ValueMode === 'UpdateKurir'
                                ? this.props.ValueMode === 'UpdateKurir' &&
                                  this.props.ValueScan === true
                                  ? false
                                  : true
                                : this.props.ValueMode === 'NoScan'
                                ? false
                                : true
                            }
                            showSearch
                            placeholder="Pilih Posko"
                            onSelect={this.props.handleResetBranch}
                            onChange={this.props.handlePutBranch}
                            onBlur={this.props.handleBlurBranch}
                            value={this.props.ValueBranch}
                            onFocus={this.props.handleGetBranch}
                          >
                            {this.props.ListBranch.map(
                              (data: any, index: any) => (
                                <Option key={index} value={data.id}>
                                  {data.name}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                        <span>
                          <b>Kurir</b>
                        </span>
                        <Form.Item
                          validateStatus={
                            this.props.validateCourier === 'error'
                              ? 'error'
                              : ''
                          }
                          help={ValidationMessage(
                            this.props.validateCourier,
                            'Kurir wajib diisi !!!'
                          )}
                        >
                          <Select
                            loading={this.props.loadingSelectCourier}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            disabled={
                              this.props.ValueBranchProps === null
                                ? true
                                : false
                            }
                            showSearch
                            onSelect={this.props.handleResetCourier}
                            placeholder="Pilih Kurir"
                            onChange={this.props.handlePutCourier}
                            onBlur={this.props.handleBlurCourier}
                            value={this.props.ValueCourier}
                            onFocus={this.props.handleGetCourier}
                          >
                            {this.props.ListCourier.map(
                              (data: any, index: any) => (
                                <Option key={index} value={data.id}>
                                  {data.firstName}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                        <span>
                          <b>Jenis Pengiriman</b>
                        </span>
                        <Form.Item
                          validateStatus={
                            this.props.validateTypeDelivery === 'error'
                              ? 'error'
                              : ''
                          }
                          help={ValidationMessage(
                            this.props.validateTypeDelivery,
                            'Jenis pengiriman wajib diisi !!!'
                          )}
                        >
                          <Select
                            style={{ width: '100%' }}
                            disabled={
                              this.props.ValueMode === 'NoScan' ? false : true
                            }
                            placeholder="Pilih Jenis Pengiriman"
                            onSelect={this.props.handleResetTypeDelivery}
                            optionFilterProp="children"
                            value={this.props.ValueTypeDelivery}
                            onChange={this.props.handlePutTypeDelivery}
                            onBlur={this.props.handleBlurTypeDelivery}
                          >
                            {deliveryType.map((data: any, index: any) => (
                              <Option key={index} value={data.value}>
                                {data.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <span>
                          <b>Nama Penerima</b>
                        </span>
                        <FormInputTextMaterial
                          validateType={true}
                          value={this.props.ValueReceiverName}
                          disabled={
                            this.props.ValueMode === 'UpdateReceiveName'
                              ? false
                              : true
                          }
                          placeholder={'Masukan Nama Penerima'}
                          maxLength={'40'}
                          style={{ borderRadius: '10px' }}
                          onChange={this.props.handleChangeReceiverName}
                          onBlur={this.props.handleChangeReceiverName}
                          validatestatus={this.props.validateReceiverName}
                          help={this.props.helpValidateReceiverName}
                          onPressEnter={''}
                        />
                      </div>
                    </Col>
                    <Col span={12} style={{ paddingLeft: '20px' }}>
                      <div>
                        <span>
                          <b>Tanggal Kirim</b>
                        </span>
                        <Form.Item
                          validateStatus={
                            this.props.validateDeliveryTime === 'error'
                              ? 'error'
                              : ''
                          }
                          help={ValidationMessage(
                            this.props.validateDeliveryTime,
                            'Tanggal kirim wajib diisi !!!'
                          )}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            locale={locale}
                            disabled={
                              this.props.ValueMode === 'UpdateTglKirim' &&
                              this.props.ValueScan === true
                                ? false
                                : true
                            }
                            format={'DD/MM/YYYY'}
                            placeholder={'Pilih tanggal'}
                            onOpenChange={this.props.handleValidateDeliveryDate}
                            value={this.props.valueLDeliveryTime}
                            onBlur={this.props.handleBlurDeliveryTime}
                            onChange={this.props.handlePutDeliveryTime}
                          />
                        </Form.Item>
                        <span>
                          <b>Tanggal Selesai</b>
                        </span>
                        <Form.Item
                          validateStatus={
                            this.props.validateFinishTime === 'error'
                              ? 'error'
                              : ''
                          }
                          help={ValidationMessage(
                            this.props.validateFinishTime,
                            'Tanggal selesai wajib diisi !!!'
                          )}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            locale={locale}
                            disabled={
                              this.props.ValueMode === 'UpdateTglSampai' &&
                              this.props.ValueScan === true
                                ? false
                                : true
                            }
                            format={'DD/MM/YYYY'}
                            placeholder={'Pilih tanggal'}
                            onOpenChange={this.props.handleValidateFinishDate}
                            onBlur={this.props.handleBlurFinishTime}
                            value={this.props.valueLFinishTime}
                            onChange={this.props.handlePutFinishTime}
                          />
                        </Form.Item>
                        <span>
                          <b>Jenis Amplop</b>
                        </span>
                        <Form.Item
                          validateStatus={
                            this.props.validateAmplopType === 'error'
                              ? 'error'
                              : ''
                          }
                          help={ValidationMessage(
                            this.props.validateAmplopType,
                            'Jenis amplop wajib diisi !!!'
                          )}
                        >
                          <Select
                            style={{ width: '100%' }}
                            disabled={
                              this.props.ValueMode === 'UpdateAmplop'
                                ? this.props.ValueMode === 'UpdateAmplop' &&
                                  this.props.ValueScan === true
                                  ? false
                                  : true
                                : this.props.ValueMode === 'NoScan'
                                ? false
                                : true
                            }
                            placeholder="Pilih Jenis Amplop"
                            onSelect={this.props.handleResetAmplopType}
                            optionFilterProp="children"
                            value={this.props.ValueDocumentType}
                            onChange={this.props.handlePutDocumentType}
                            onBlur={this.props.handleBlurAmplopType}
                          >
                            <Option value="Standar">Standar</Option>
                            <Option value="A4">A4</Option>
                            <Option value="A5">A5</Option>
                          </Select>
                        </Form.Item>
                        <span>
                          <b>Nomor Resi</b>
                        </span>
                        <Form.Item
                          validateStatus={
                            this.props.validateResiNumber === 'error'
                              ? 'error'
                              : ''
                          }
                          help={ValidationMessage(
                            this.props.validateResiNumber,
                            'No Resi wajib diisi !!!'
                          )}
                        >
                          <Input
                            placeholder="Masukkan Nomor Resi"
                            disabled={
                              this.props.ValueMode === 'UpdateNoResi' &&
                              this.props.ValueScan === true
                                ? false
                                : true
                            }
                            value={this.props.ValueNumberResi}
                            maxLength={40}
                            onBlur={this.props.handleBlurResiNumber}
                            onChange={this.props.handlePutNumberResi}
                            // onPressEnter={this.props.handleSetScan}
                          />
                        </Form.Item>
                        <span>
                          <b>Barcode terdapat pada kolom ke - </b>
                        </span>
                        <FormInputTextMaterial
                          validateType={true}
                          value={this.props.ValueBarcodeColumn}
                          disabled={
                            this.props.ValueMode === 'NoScan' ? false : true
                          }
                          placeholder={'Masukan Nomor Index Kolom Barcode'}
                          maxLength={'2'}
                          style={{ borderRadius: '10px' }}
                          onChange={this.props.handleChangeBarcodeColumn}
                          onBlur={this.props.handleChangeBarcodeColumn}
                          validatestatus={this.props.validateBarcodeColumn}
                          help={ValidationMessage(
                            this.props.validateBarcodeColumn,
                            'Index kolom barcode wajib diisi !!!'
                          )}
                          onPressEnter={''}
                        />
                        <span>
                          <b>Keterangan</b>
                        </span>
                        <FormInputTextMaterial
                          validateType={true}
                          value={this.props.ValueDeliveryNote}
                          disabled={
                            this.props.ValueMode === 'UpdateReceiveName'
                              ? false
                              : true
                          }
                          placeholder={'Masukan Keterangan'}
                          maxLength={'150'}
                          style={{ borderRadius: '10px' }}
                          onChange={this.props.handleChangeDeliveryNote}
                          onBlur={this.props.handleChangeDeliveryNote}
                          validatestatus={this.props.validateDeliveryNote}
                          help={this.props.helpValidateDeliveryNote}
                        />
                      </div>
                      <div>
                        <Row>
                          <Col span={16}>
                            {this.props.ValueScan === true ? (
                              <div>
                                <span>
                                  <b>Waybill</b>
                                </span>
                                <Form.Item
                                  validateStatus={
                                    this.props.validateWaybill === 'error'
                                      ? 'error'
                                      : ''
                                  }
                                  help={ValidationMessage(
                                    this.props.validateWaybill,
                                    'Waybill wajib diisi !!!'
                                  )}
                                >
                                  <Input
                                    placeholder="Masukkan Waybill"
                                    value={this.props.ValueWaybill}
                                    onChange={this.props.handlePutWaybill}
                                    onBlur={this.props.handleBlurWaybill}
                                    // onPressEnter={this.props.handleSetScan}
                                  />
                                </Form.Item>
                              </div>
                            ) : (
                              <div>
                                <span>
                                  <b>Unggah Csv</b>
                                </span>
                                <div
                                  className="fileUploadInput"
                                  style={{ marginTop: 3 }}
                                >
                                  <Form.Item
                                    validateStatus={
                                      this.props.validateCsv === 'error'
                                        ? 'error'
                                        : ''
                                    }
                                    help={ValidationMessage(
                                      this.props.validateCsv,
                                      'Csv wajib diisi !!!'
                                    )}
                                  >
                                    <Input
                                      onChange={this.props.handlePutCsv}
                                      value={this.props.fileName}
                                      suffix={<Icon type="upload" />}
                                      multiple={false}
                                      onBlur={this.props.handleBlurCsv}
                                      type="file"
                                      accept=".csv"
                                    />
                                  </Form.Item>
                                </div>
                                <div>
                                  <Button
                                    disabled={
                                      this.props.ValueModeProps === null
                                        ? true
                                        : false
                                    }
                                    onClick={this.props.handleDownloadSample}
                                    type="link"
                                    style={{
                                      float: 'left',
                                      marginLeft: '-10px',
                                    }}
                                  >
                                    <u style={{ fontStyle: 'italic' }}>
                                      Download sample file
                                    </u>
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Col>
                          <Col span={8}>
                            <div style={{ float: 'right' }}>
                              <Checkbox
                                style={{
                                  paddingTop: 30,
                                  paddingLeft: '24px',
                                }}
                                disabled={
                                  this.props.ValueMode === 'NoScan' ||
                                  this.props.ValueMode === 'UpdateBarcode' ||
                                  this.props.ValueMode === 'UpdateCycle' ||
                                  this.props.ValueMode === 'UpdateReceiveName'
                                    ? true
                                    : false
                                }
                                checked={this.props.ValueScan}
                                onChange={this.props.handleSetValueScan}
                              />
                              <span
                                style={{
                                  paddingLeft: '15px',
                                  fontStyle: 'italic',
                                  color: '#969696',
                                }}
                              >
                                Scan
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <div
                    style={{
                      textAlign: 'right',
                      marginRight: '20px',
                      paddingTop: 25,
                    }}
                  >
                    <ButtonGroup>
                      <div style={{ float: 'left', paddingRight: '15px' }}>
                        <ButtonMaterial
                          disabled={false}
                          // disabled={this.props.validateResetButton}
                          handleSubmit={this.props.handleResetFormEmergency}
                          teksButton={'Reset'}
                          shape={'round'}
                          type={'primary'}
                          style={{
                            borderRadius: '20px',
                            color: '#fafafa',
                            background: '#FF5858',
                            borderColor: '#FF5858',
                          }}
                        />
                      </div>
                      <div style={{ float: 'right' }}>
                        <ButtonMaterial
                          disabled={this.props.ConditionButton}
                          // disabled={ValidateButtonSave}
                          handleSubmit={this.props.handleSubmitFormEmergency}
                          teksButton={'Simpan'}
                          shape={'round'}
                          type={'primary'}
                          style={{ borderRadius: '20px' }}
                        />
                      </div>
                    </ButtonGroup>
                  </div>
                </div>
              }
            />
          }
        />
        <ModalMaterial
          className={''}
          width={'30%'}
          style={{}}
          teksTitle={
            <span className={'greenText'}>{'Status Update Emergency'}</span>
          }
          visible={this.props.jenisToggle === 'ModalEmergency' ? true : false}
          onCancel={this.props.handleClearResult}
          content={
            <SpinMaterial
              spinning={
                this.props.jenisToggle2 === 'DownloadResultEmergency'
                  ? true
                  : false
              }
              style={{}}
              size={'large'}
              content={
                <Row>
                  <div>
                    <div className={'modalResultWD'}>
                      <p>
                        <span className={'subTitleBlack'}>
                          Berhasil : {this.props.countSuccess}
                        </span>
                      </p>
                      <p>
                        <span className={'subTitleBlack'}>
                          Gagal : {this.props.countFailed}
                        </span>
                      </p>
                    </div>
                    <Col>
                      <div className={'layoutButtonResultWD'}>
                        <ButtonMaterial
                          disabled={false}
                          handleSubmit={this.props.handleClearResult}
                          teksButton={<b>Tutup</b>}
                          size={'small'}
                          className={'buttonCloseModalResultWD'}
                          type={'primary'}
                        />
                      </div>
                      <div className={'layoutButtonResultWD'}>
                        <ButtonMaterial
                          handleSubmit={this.props.handleDownloadResult}
                          teksButton={<b>Detail Laporan</b>}
                          size={'small'}
                          className={'buttonDownloadModalResultWD'}
                          type={'primary'}
                        />
                      </div>
                    </Col>
                  </div>
                </Row>
              }
            />
          }
        />
      </div>
    );
  }
}

export default EmergencyProcessComponent;
