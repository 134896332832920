import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionScanManfiset from '../../../action/Manifest/ScanManifest/ActionScanManifest';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_GetGroup(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const TypeToggle = state.Toggle.jenisToggle;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    if (TypeToggle === 'AddNewGrup') {
      yield put(toggle.changeLoadingTrue());
    } else {
      yield put(
        ActionScanManfiset.changeLoadingTypeGroupName('selectGroupName')
      );
      yield put(ActionScanManfiset.changeLoadingGroupName());
    }
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/GroupManifest',
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionScanManfiset.changeListGroup(data.data));
    if (TypeToggle === 'AddNewGrup') {
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(ActionScanManfiset.clearLoadingTypeGroupName());
      yield put(ActionScanManfiset.changeLoadingGroupName());
    }
  } catch (error) {
    if (error.response.status !== 401) {
      if (TypeToggle === 'AddNewGrup') {
        yield put(toggle.changeLoadingFalse());
      } else {
        yield put(ActionScanManfiset.clearLoadingTypeGroupName());
        yield put(ActionScanManfiset.changeLoadingGroupName());
      }
    }
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_GetGroup() {
  yield takeLatest('GET_GROUP_LIST', S_GetGroup);
}
