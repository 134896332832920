import * as ActionComponent from '../../../App/Store/ComponentAction';
import * as ActionPickupStatus from '../../PickupStatus/Store/PickupStatusAction';
import * as SelectorsComponent from '../../../App/Selector/AppSelector';
import * as SelectorsPickupStatus from '../../PickupStatus/Selector/PickupStatusSelector';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect, useDispatch } from 'react-redux';

import PickupStatusComponent from '../Component/PickupStatusComponent';
import { change } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { getFormValues } from 'redux-form';

function PickupStatusContainer(props) {
  const { componentAction, actionPickupStatus, locationSearch } = props;
  const dispatch = useDispatch();
  const locationSearchValue = locationSearch.split('?')[1];
  useEffect(() => {
    if (locationSearchValue !== undefined) {
      dispatch(
        change('pickupStatusForm', 'valueSearchPickup', locationSearchValue)
      );
      actionPickupStatus.getPickupStatus();
    }
    dispatch(change('pickupStatusForm', 'filterValue', 'waybill'));
    // eslint-disable-next-line
  }, [componentAction]);
  const handleGetPickupStatus = () => {
    actionPickupStatus.getPickupStatus();
  };
  return (
    <React.Fragment>
      <PickupStatusComponent
        handleGetPickupStatus={handleGetPickupStatus}
        {...props}
      />
    </React.Fragment>
  );
}

const mapStateToProps = () =>
  createStructuredSelector({
    pickupStatusDoc: SelectorsPickupStatus.makePickUpStatusDocSelector(),
    isLoading: SelectorsComponent.makeIsLoading(),
    locationSearch: SelectorsComponent.makeIsLocationSearch(),
    formValue: getFormValues('pickupStatusForm'),
    // valueSearchPickup
  });
const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
  actionPickupStatus: bindActionCreators(ActionPickupStatus, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PickupStatusContainer);
