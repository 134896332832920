export function getAllCourierLocation()
{
    return({
        type:"GET_ALL_COURIER_LOCATION"
    })
}
export function getCourierPerson()
{
    return({
        type:"GET_COURIER_PERSON"
    })
}
export function detailCourier(detailCourier:any)
{
    return({
        type:"DETAIL_COURIER",
        detailCourier
    })
}
export function selectCourier(selectCourier:any)
{
    return({
        type:"SELECT_COURIER",
        selectCourier
    })
}
export function setCourirCode(CourierCode:any)
{
    return({
        type:"SET_COURIER_CODE",
        CourierCode
    })
}
export function setCourirPhone(CourierPhone:any)
{
    return({
        type:"SET_COURIER_PHONE",
        CourierPhone
    })
}
export function setCourirBranch(CourierBranch:any)
{
    return({
        type:"SET_COURIER_BRANCH",
        CourierBranch
    })
}
export function setCourirLastSeen(CourierLastSeen:any)
{
    return({
        type:"SET_COURIER_LAST_SEEN",
        CourierLastSeen
    })
}
export function setDeliverySuccess(DeliverySuccess:any)
{
    return({
        type:"SET_DELIVERY_SUCCESS",
        DeliverySuccess
    })
}
export function setDeliveryReturn(DeliveryReturn:any)
{
    return({
        type:"SET_DELIVERY_RETURN",
        DeliveryReturn
    })
}
export function setDeliveryPending(DeliveryPending:any)
{
    return({
        type:"SET_DELIVERY_PENDING",
        DeliveryPending
    })
}
export function setDeliveryTotal(DeliveryTotal:any)
{
    return({
        type:"SET_DELIVERY_TOTAL",
        DeliveryTotal
    })
}
export function clearSelectCourier()
{
    return({
        type:"CLEAR_SELECT_COURIER"
    })
}
export function clearDetailCourier()
{
    return({
        type:"CLEAR_DETAIL_COURIER"
    })
}
export function clearCourierDeliveryData()
{
    return({
        type:"CLEAR_COURIER_DELIVERY_DATA"
    })
}