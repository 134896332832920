import * as ActionAuth from '../../../core/action//Auth';
import * as ActionFieldCustomerProduct from '../../action/CustomerProduct/ActionFieldCustomerProduct';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_GetDetailCustomerProduct(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const IdCustomerProduct =
    state.CustomerProduct.PropertiesCustomerProduct.idCustomerProduct;
  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Customer/product/${IdCustomerProduct}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.changeModal());
    yield put(toggle.changeJenisToggle('EditCustomerProduct'));
    yield put(
      ActionFieldCustomerProduct.setGroupNameProduct(data.groupProductId)
    );
    yield put(ActionFieldCustomerProduct.setCustomerProductName(data.name));
    yield put(
      ActionFieldCustomerProduct.setCustomerIsDisabled(data.isDisabled)
    );
    yield put(
      ActionFieldCustomerProduct.setCustomerProductCode(data.productCode)
    );
    yield put(
      ActionFieldCustomerProduct.setCustomerProductType(data.productType)
    );
    yield put(ActionFieldCustomerProduct.setCustomerProductLogo(data.image));
    yield put(ActionFieldCustomerProduct.setCustomerProductBonus(data.bonus));
    yield put(
      ActionFieldCustomerProduct.setListCustomerProductBonus(
        data.bonus.split(';')
      )
    );

    const input = data.sla;
    const fields = input.split(';');
    const d1 = fields[0];
    const d2 = fields[1];
    const d3 = fields[2];
    const d4 = fields[3];
    const d5 = fields[4];
    const d6 = fields[5];
    const d7 = fields[6];
    const d8 = fields[7];
    const d9 = fields[8];
    const d10 = fields[9];
    const d11 = fields[10];
    const d12 = fields[11];

    yield put(ActionFieldCustomerProduct.setCustomerProductDistrict1(d1));
    yield put(ActionFieldCustomerProduct.setCustomerProductDistrict2(d2));
    yield put(ActionFieldCustomerProduct.setCustomerProductDistrict3(d3));
    yield put(ActionFieldCustomerProduct.setCustomerProductDistrict4(d4));
    yield put(ActionFieldCustomerProduct.setCustomerProductDistrict5(d5));
    yield put(ActionFieldCustomerProduct.setCustomerProductDistrict6(d6));
    yield put(ActionFieldCustomerProduct.setCustomerProductDistrict7(d7));
    yield put(ActionFieldCustomerProduct.setCustomerProductDistrict8(d8));
    yield put(ActionFieldCustomerProduct.setCustomerProductDistrict9(d9));
    yield put(ActionFieldCustomerProduct.setCustomerProductDistrict10(d10));
    yield put(ActionFieldCustomerProduct.setCustomerProductDistrict11(d11));
    yield put(ActionFieldCustomerProduct.setCustomerProductDistrict12(d12));

    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}

export function* SM_GetDetailCustomerProduct() {
  yield takeLatest(
    'CHANGE_SET_DETAIL_CUSTOMER_PRODUCT',
    S_GetDetailCustomerProduct
  );
}
