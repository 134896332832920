import React, { useState } from 'react';
import styles from './dataTable.module.css';
import { Row, Col, Table } from 'antd';
import SpinMaterial from 'materials/SpinMaterial';
import SearchMatrial from 'materials/SearchMatrial';
import CardMaterial from 'materials/CardMaterial';
import SelectField from 'components/SelectField';
import ButtonMaterial from 'materials/ButtonMaterial';
import { ColumnAlignTable, ColumnFixedTable } from 'interface/general';
import { DataTableResProps } from 'interface/general';

type DataTablePerformaProps = {
  isLoadingTable: boolean;
  dataTable: DataTableResProps;
  handlePage: (value: number) => void;
  handleChangeFilter: (name: string, value: any) => void;
  handleDownload: () => void;
  downloadForm: string;
  setDownloadForm: (value: string) => void;
};

export default function DataTablePerforma({
  dataTable,
  isLoadingTable,
  handlePage,
  handleChangeFilter,
  handleDownload,
  downloadForm,
  setDownloadForm,
}: DataTablePerformaProps) {
  const [nameSearch, setNameSearch] = useState('');
  const pagePagination = dataTable?.page + 1 || 0;
  const totalPagination = dataTable?.totalPages * 10 || 0;

  const columnsTable = [
    {
      title: 'Nama Kurir',
      dataIndex: 'courierName',
      fixed: 'left' as ColumnFixedTable,
      width: 150,
    },
    {
      title: 'Kode Kurir',
      align: 'center' as ColumnAlignTable,
      dataIndex: 'courierCode',
      fixed: 'left' as ColumnFixedTable,
      width: 100,
    },
    {
      title: 'Posko',
      dataIndex: 'branchName',
      align: 'center' as ColumnAlignTable,
      fixed: 'left' as ColumnFixedTable,
      width: 200,
    },
    {
      title: 'Variabel',
      className: styles.columnTableHead,
      children: [
        {
          title: 'Total InBound',
          dataIndex: 'variabel.totalInbound',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
        },
        {
          title: 'Success < = 3',
          dataIndex: 'variabel.successSLA',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
        },
        {
          title: 'Total Success',
          dataIndex: 'variabel.totalSuccess',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
        },
        {
          title: 'Total Retur',
          dataIndex: 'variabel.totalReturn',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
        },
        {
          title: 'Total In Process',
          dataIndex: 'variabel.totalInProcess',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
        },
        {
          title: 'Total Success by POD Date',
          dataIndex: 'variabel.totalSuccessByPodDate',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
        },
        {
          title: 'Total Return by POD Date',
          dataIndex: 'variabel.totalReturnByPodDate',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
        },
        {
          title: 'Total Succes POD D+0',
          dataIndex: 'variabel.totalSuccesPod',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
        },
      ],
    },
    {
      title: 'KPI',
      className: styles.columnTableHead,
      children: [
        {
          title: 'Overall SLA %',
          dataIndex: 'kpi.overallSLA',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
        },
        {
          title: 'Success Rate %',
          dataIndex: 'kpi.successRate',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
        },

        {
          title: 'Productivity',
          dataIndex: 'kpi.productivity',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
        },
        {
          title: 'Return Rate %',
          dataIndex: 'kpi.returnRate',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
        },
        {
          title: 'Hit Rate %',
          dataIndex: 'kpi.hitRate',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
        },
      ],
    },
  ];
  return (
    <SpinMaterial spinning={isLoadingTable} size="large">
      <CardMaterial style={{ borderRadius: '10px 10px 0 0' }}>
        <Row>
          <Col span={18}>
            <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
              <span className="titleSearchCard" style={{ padding: 0 }}>
                SEMUA DATA
              </span>
              <SearchMatrial
                style={{ borderRadius: '10px', width: '50%' }}
                placeholder={'Cari berdasarkan nama, kode kurir, atau posko'}
                value={nameSearch}
                onChange={e => setNameSearch(e.target.value)}
                onPressEnter={e =>
                  handleChangeFilter('searchValue', e.target.value)
                }
              />
            </div>
          </Col>
        </Row>
      </CardMaterial>
      <Table
        columns={columnsTable}
        bordered
        dataSource={dataTable?.data || []}
        pagination={{
          onChange: page => {
            handlePage(page - 1);
          },
          pageSize: 10,
          total: totalPagination,
          current: pagePagination === 0 ? 1 : pagePagination,
        }}
        scroll={{ x: 'auto' }}
        footer={() => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              gap: 8,
            }}
          >
            <SelectField
              placeholder="Pilih Jenis Laporan"
              style={{ width: '200px' }}
              data={[
                { id: 'Reguler', name: 'Reguler (.xlsx)' },
                { id: 'Daily', name: 'Daily (.xlsx)' },
              ]}
              value={downloadForm}
              onChange={value => setDownloadForm(value)}
              disabled={!dataTable?.data || dataTable?.data?.length === 0}
            />
            <ButtonMaterial
              icon="download"
              type="download"
              handleSubmit={handleDownload}
              style={{
                ...(dataTable?.data?.length >= 0 &&
                  downloadForm && {
                    background: '#874FFF',
                    color: 'white',
                  }),
              }}
              disabled={
                !dataTable?.data ||
                dataTable?.data?.length === 0 ||
                !downloadForm
              }
            />
            <p style={{ margin: 0, fontWeight: 500 }}>
              Total Entri ({dataTable?.totalDatas || 0})
            </p>
          </div>
        )}
      />
    </SpinMaterial>
  );
}
