import * as ActionDeliveryPriority from '../../action/DeliveryPriority/ActionDeliveryPriority';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_addTicketDP() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };

  const deliveryId = state.DeliveryPriority.Properties.deliveryId;
  const waybillForm = state.DeliveryPriority.DeliveryPriorityForm.waybillForm;

  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.post,
      process.env.REACT_APP_URL + '/DeliveryUrgent',
      {
        deliveryId: deliveryId,
        waybillCode: waybillForm,
        statusTicket: true,
      },
      { headers: config }
    );
    yield put(ActionDeliveryPriority.clearDataForm());
    yield put(ActionDeliveryPriority.clearDataTable());
    yield put(ActionDeliveryPriority.getDataTableDP());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil di simpan</b>
        </p>
      ),

      onOk() {},
    });
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      const propsError = ErrorMassage('Tambah ticket pengiriman prioritas');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
    yield put(toggle.changeLoadingFalse());
  }
}

export function* SM_addTicketDP() {
  yield takeLatest('ADD_TICKET_DELIVERY_PRIORITY', S_addTicketDP);
}
