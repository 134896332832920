import { Action } from 'redux';
import { IFetchPickupDataAction } from './PickupDataAction';

const initialState: any = {
  customerProductList: [],
  courierList: [],
  countingDataStatus: null,
  pickupDataList: [],
};

export default function PickUpProcessReducer(
  state = initialState,
  action: Action
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_CUSTOMER_PRODUCT_LIST': {
      const fetchCustomerProductListAction = action as IFetchPickupDataAction;
      newState.customerProductList = fetchCustomerProductListAction.data;
      return { ...newState };
    }
    case 'FETCH_COURIER_LIST': {
      const fetchCourierListAction = action as IFetchPickupDataAction;
      newState.courierList = fetchCourierListAction.data;
      return { ...newState };
    }
    case 'FETCH_COUNTING_DATA_STATUS_PICKUP': {
      const fetchCountingDataStatusAction = action as IFetchPickupDataAction;
      newState.countingDataStatus = fetchCountingDataStatusAction.data;
      return { ...newState };
    }
    case 'FETCH_PICKUP_DATA_LIST': {
      const fetchPickupDataListAction = action as IFetchPickupDataAction;
      newState.pickupDataList = fetchPickupDataListAction.data;
      return { ...newState };
    }
    case 'CLEAR_CUSTOMER_PRODUCT_LIST_PICKUP': {
      newState.customerProductList = [];
      return { ...newState };
    }
    case 'CLEAR_COURIER_LIST_PICKUP': {
      newState.courierList = [];
      return { ...newState };
    }
    case 'CLEAR_DATA_TABLE_PICKUP': {
      newState.pickupDataList = [];
      newState.countingDataStatus = [];
      return { ...newState };
    }
  }
  return state;
}
