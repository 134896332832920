import React, { Component } from 'react'
import ButtonMaterial from "../../../materials/ButtonMaterial";

interface IProps{
    handleCustomerDetail:any
    handleCustomerEdit:any
    handleCustomerConfig:any
    typeButton:any
}
export default class ButtonCustomerComponent extends Component<IProps> {
    render() {
        return (
            this.props.typeButton==='detail'?
            <ButtonMaterial
                disabled={""}
                handleSubmit={this.props.handleCustomerDetail}
                teksButton={""}
                size={""}
                shape={""}
                className={""}
                type={"primary"}
                icon={"eye"}
                style={{
                    float:'right',
                    borderRadius:'8px',
                    color: '#fafafa' ,
                    paddingBottom:'3px',
                    background:'#7956EE',
                    borderColor:'#7956EE'}}/>:
            this.props.typeButton==='edit'?
            <ButtonMaterial
                disabled={""}
                handleSubmit={this.props.handleCustomerEdit}
                teksButton={""}
                size={""}
                shape={""}
                className={""}
                type={"primary"}
                icon={"edit"}
                style={{
                    float:'right',
                    borderRadius:'8px',
                    color: '#fafafa' ,
                    paddingBottom:'3px',
                    background:'#FCC642',
                    borderColor:'#FCC642'}}/>:
            <ButtonMaterial
            disabled={""}
            handleSubmit={this.props.handleCustomerConfig}
            teksButton={""}
            size={""}
            shape={""}
            className={""}
            type={"primary"}
            icon={"setting"}
            style={{
                float:'left',
                borderRadius:'8px',
                color: '#fafafa' ,
                paddingBottom:'3px',
                background:'#A52A2A',
                borderColor:'#A52A2A'}}/>
        )
    }
}
