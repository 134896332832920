export function setCycleDocument() {
  return {
    type: 'SET_CYCLE_DOCUMENT',
  };
}
export function setDeliveryDocument() {
  return {
    type: 'SET_DELIVERY_DOCUMENT',
  };
}
export function setRadioButton(RadioButton: any) {
  return {
    type: 'SET_RADIO_BUTTON',
    RadioButton,
  };
}
export function setCheckbox(PhysicalDocument: any) {
  return {
    type: 'SET_PHYSICAL_DOCUMENT',
    PhysicalDocument,
  };
}
export function setCycleStart(CycleStart: any) {
  return {
    type: 'SET_CYCLE_START',
    CycleStart,
  };
}
export function setCycleEnd(CycleEnd: any) {
  return {
    type: 'SET_CYCLE_END',
    CycleEnd,
  };
}
export function setStatusDelivery(DeliveryStatus: any) {
  return {
    type: 'SET_DELIVERY_STATUS',
    DeliveryStatus,
  };
}
export function setCustomerName(CustomerName: any) {
  return {
    type: 'SET_CUSTOMER_NAME',
    CustomerName,
  };
}
export function setCustomer(Customer: any) {
  return {
    type: 'SET_CUSTOMER',
    Customer,
  };
}
export function setCustomerProductName(CustomerProductName: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_NAME',
    CustomerProductName,
  };
}
export function setCustomerProduct(CustomerProduct: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT',
    CustomerProduct,
  };
}
export function setBranch(Branch: any) {
  return {
    type: 'SET_BRANCH_FILTER',
    Branch,
  };
}
export function setCourierName(CourierName: any) {
  return {
    type: 'SET_COURIER_NAME_FILTER',
    CourierName,
  };
}
export function setCourier(Courier: any) {
  return {
    type: 'SET_COURIER_FILTER',
    Courier,
  };
}
export function setDownloadType(DownloadType: any) {
  return {
    type: 'SET_DOWNLOAD_TYPE_DD',
    DownloadType,
  };
}
export function setTableDeliveryFilter(TableDeliveryFilter: any) {
  return {
    type: 'SET_TABLE_DELIVERY_FILTER',
    TableDeliveryFilter,
  };
}
export function setTotalSuccess(TotalSuccess: any) {
  return {
    type: 'SET_TOTAL_SUCCESS',
    TotalSuccess,
  };
}
export function setTotalOnProcess(TotalOnProcess: any) {
  return {
    type: 'SET_TOTAL_ON_PROCESS',
    TotalOnProcess,
  };
}
export function setTotalReturn(TotalReturn: any) {
  return {
    type: 'SET_TOTAL_RETURN',
    TotalReturn,
  };
}
export function setTotalDelivery(TotalDelivery: any) {
  return {
    type: 'SET_TOTAL_DELIVERY',
    TotalDelivery,
  };
}
export function getSearchDataDelivery() {
  return {
    type: 'GET_SEARCH_DATA_DELIVERY',
  };
}
export function downloadDeliveryData() {
  return {
    type: 'DOWNLOAD_DELIVERY_DATA',
  };
}
export function clearTableDeliveryFilter() {
  return {
    type: 'CLEAR_TABLE_DELIVERY_FILTER',
  };
}
export function searchDelivery() {
  return {
    type: 'SEARCH_DELIVERY',
  };
}
export function searchDeliveryCount() {
  return {
    type: 'SEARCH_DELIVERY_COUNT',
  };
}
export function clearCourier() {
  return {
    type: 'CLEAR_COURIER',
  };
}
export function clearBranch() {
  return {
    type: 'CLEAR_BRANCH',
  };
}
export function clearCustomerProduct() {
  return {
    type: 'CLEAR_CUSTOMER_PRODUCT',
  };
}
export function resetFilter() {
  return {
    type: 'RESET_FILTER',
  };
}
export function resetFilterBranch() {
  return {
    type: 'RESET_FILTER_BRANCH',
  };
}
export function resetFilterExceptRadiobutton() {
  return {
    type: 'RESET_FILTER_EXCEPT_RADIOBUTTON',
  };
}
export function clearCustomerId() {
  return {
    type: 'CLEAR_CUSTOMER_DD',
  };
}
export function clearAllFilter() {
  return {
    type: 'CLEAR_ALL_FILTER',
  };
}
