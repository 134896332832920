import { Col, Row, Table } from 'antd';
import React, { Component } from 'react';

import AddAccountCustomerContainer from '../../../../containers/Content/ManageAccount/Customer/AddCustomerAccountContainer';
import ButtonManageCustomerAccountContainer from '../../../../containers/Content/ManageAccount/Customer/ButtonManageCustomerAccountContainer';
import ButtonMaterial from '../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../materials/CardMaterial';
import ResetPasswordContainer from '../../../../containers/Content/ResetPassword/ResetPasswordContainer';
import SearchMaterial from '../../../../materials/SearchMatrial';
import SpinMaterial from '../../../../materials/SpinMaterial';

interface IProps {
  handlePage: any;
  handlePutSearch: any;
  handleSubmitSearch: any;
  handleModal: any;
  data: any;
  isLoading: any;
  ValueSearch: any;
  PagePagination: any;
  TotalPagePagination: any;
}

export default class TableCustomerAccountComponent extends Component<IProps> {
  render() {
    let looppage = (this.props.PagePagination - 1) * 10 + 1;
    const columns = [
      {
        title: (
          <div style={{ textAlign: 'center' }}>
            <b>No</b>
          </div>
        ),
        width: 50,
        render: (text: any, record: any, index: any) => (
          <span>
            {this.props.PagePagination > 1 ? index + looppage : index + 1}
          </span>
        ),
        key: 'no',
      },
      {
        title: <b>Nama</b>,
        dataIndex: 'firstName',
        key: 'firstName',
        width: 120,
      },
      {
        title: <b>Nama Akun</b>,
        dataIndex: 'userName',
        key: 'userName',
        render: (userName: any) => (
          <span style={{ color: 'red' }}>{userName}</span>
        ),
        width: 80,
      },
      {
        title: <b>Status</b>,
        dataIndex: 'isActived',
        key: 'isActived',
        render: (isActived: any) => (
          <span>{isActived === true ? 'Aktif' : 'Tidak Aktif'}</span>
        ),
        width: 100,
      },
      {
        title: <b>Alamat Email</b>,
        dataIndex: 'email',
        key: 'email',
        render: (email: any) => <span style={{ color: 'red' }}>{email}</span>,
        width: 100,
      },
      {
        title: <b>Aksi</b>,
        key: 'action',
        width: 90,
        render: (record: any) => (
          <div>
            <Row>
              <Col span={12}>
                <div style={{ paddingRight: '5px' }}>
                  <ButtonManageCustomerAccountContainer
                    idCustomer={''}
                    idCustomerAccount={record.code}
                    typeButton={'ResetPasswordCustomer'}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div style={{ paddingLeft: '5px' }}>
                  <ButtonManageCustomerAccountContainer
                    idCustomer={''}
                    idCustomerAccount={record.code}
                    typeButton={'editAccount'}
                  />
                </div>
              </Col>
            </Row>
          </div>
        ),
      },
    ];
    return (
      <div
        style={{ paddingLeft: '3px', paddingRight: '3px', paddingTop: '20px' }}
      >
        <ResetPasswordContainer />
        <AddAccountCustomerContainer />

        <div>
          <SpinMaterial
            spinning={this.props.isLoading}
            style={{}}
            size={'large'}
            content={
              <div>
                <CardMaterial
                  extra={''}
                  title={''}
                  style={{}}
                  content={
                    <div>
                      <Row>
                        <Col span={12}>
                          <div>
                            <span className={'titleSearchCard'}>
                              <b>PELANGGAN</b>
                            </span>
                            <SearchMaterial
                              value={this.props.ValueSearch}
                              style={{ borderRadius: '10px', width: '50%' }}
                              placeholder={'Cari nama atau kode'}
                              onChange={this.props.handlePutSearch}
                              onPressEnter={this.props.handleSubmitSearch}
                            />
                          </div>
                        </Col>
                        <Col
                          span={12}
                          style={{ paddingLeft: '160px', float: 'right' }}
                        >
                          <div>
                            <ButtonMaterial
                              disabled={''}
                              handleSubmit={this.props.handleModal}
                              teksButton={<b>TAMBAHKAN AKUN PELANGGAN</b>}
                              size={''}
                              shape={''}
                              className={''}
                              type={'link'}
                              icon={''}
                              style={{
                                color: '#11BEFF',
                                paddingRight: '15px',
                              }}
                            />

                            <ButtonMaterial
                              disabled={''}
                              handleSubmit={this.props.handleModal}
                              teksButton={''}
                              size={''}
                              shape={'circle'}
                              className={''}
                              type={'primary'}
                              icon={'plus'}
                              style={{
                                color: '#fafafa',
                                paddingBottom: '3px',
                                background: '#31E116',
                                borderColor: '#31E116',
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  }
                ></CardMaterial>
                <Table
                  rowKey={record => record.id}
                  pagination={{
                    onChange: page => {
                      this.props.handlePage(page);
                    },
                    pageSize: 10,
                    total: this.props.TotalPagePagination,
                    current:
                      this.props.PagePagination === 0
                        ? 1
                        : this.props.PagePagination,
                  }}
                  bordered={false}
                  dataSource={this.props.data}
                  columns={columns}
                />
              </div>
            }
          ></SpinMaterial>
        </div>
      </div>
    );
  }
}
