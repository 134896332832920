// DY: DEV CHANGES 4 JUL 24

import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getAllCustomers } from 'services/GeneralService';
import ModalStatus from 'materials/ModalStatus';
import { getDataTableBrankas } from 'services/ManifestSaveBrankas';
import {
  getAllManifestSaveCode,
  deleteManifestSaveCode,
} from 'services/ManifestSaveCodeServices';
import FilterManifestBrankas from '../components/FilterManifestBrankas';
import TableManifestBrankas from '../components/TableManifestBrankas';
import { SelectOptionsInterface } from 'interface/general';
import ModalConfirmation from 'components/ModalConfirmation';

import ModalEditCodeRetur from 'module/Manifest/PrintManifest/ModalEditCodeRetur/Container';
import ModalAddCodeRetur from 'module/Manifest/PrintManifest/ModalAddCodeRetur/Container';
import ModalDownloadListManifest from 'module/Manifest/PrintManifest/ModalDownloadListManifest/Container';
import ModalAddManifestBrankas from './ModalAddManifestBrankas';
import ModalEditManifestBrankas from './ModalEditManifestBrankas';
import ModalScanManifestBrankas from './ModalScanManifestBrankas';

export type FilterManifestBrankasProps = {
  typeManifest: string;
  customer: number | null;
  periodeManifest: any[];
  search: string;
};
const initialValuesFilter = {
  typeManifest: 'Return',
  customer: null,
  periodeManifest: [null, null],
  search: '',
};

export default function ManifestBrankas() {
  const [dataCustomers, setDataCustomers] = useState<SelectOptionsInterface[]>(
    []
  );
  const [dataManifestRetur, setDataManifestRetur] = useState<
    SelectOptionsInterface[]
  >([]);
  const [filterManifestBrankas, setFilterManifestBrankas] =
    useState<FilterManifestBrankasProps>(initialValuesFilter);
  const [filterStatisManifestBrankas, setFilterStatisManifestBrankas] =
    useState<FilterManifestBrankasProps>(initialValuesFilter);
  const [page, setPage] = useState<number>(0);

  const [selectedData, setSelectedData] = useState<any>(null);
  const [isShowEditKode, setIsShowEditKode] = useState<boolean>(false);
  const [isShowAddKode, setIsShowAddKode] = useState<boolean>(false);
  const [isShowModalAdd, setIsShowModalAdd] = useState<boolean>(false);
  const [isShowModalEdit, setIsShowModalEdit] = useState<number | null>(null);
  const [isShowModalScan, setIsShowModalScan] = useState<number | null>(null);
  const [isShowModalDelete, setIsShowModalDelete] = useState<number | null>(
    null
  );
  const [isShowDownloadListManifest, setIsShowDownloadListManifest] =
    useState<boolean>(false);

  const {
    data: dataTableManifestBrankas,
    isFetching: isLoadingTableManifestBrankas,
    refetch: refetchTable,
  } = useQuery(
    ['TableManifestBrankas', page, filterManifestBrankas],
    () => getDataTableBrankas({ page, filterManifestBrankas }),
    {
      enabled: !!filterManifestBrankas.customer,
      cacheTime: 0,
    }
  );

  const { isLoading: isLoadingCustomers } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataCustomers'],
    queryFn: () => getAllCustomers(),
    onSuccess(data) {
      const datas = [] as any;
      data.data.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
        });
      });
      setDataCustomers(datas);
    },
    refetchOnWindowFocus: false,
  });

  const {
    refetch: refetchManifestRetur,
    isLoading: isLoadingManifest,
    isFetching: isFetchingManifest,
  } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataManifestSaveCopde'],
    queryFn: () => getAllManifestSaveCode(),
    onSuccess(data) {
      if (data && data?.data?.length > 0) {
        const arrManifest = [] as any;
        data.data.forEach(item => {
          arrManifest.push({
            id: item.id,
            name: item.code,
          });
        });
        setDataManifestRetur(arrManifest);
      }
    },
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateDeleteBundle, isLoading: isLoadingDelete } =
    useMutation(deleteManifestSaveCode, {
      onSuccess: (res: any) => {
        if (res.status === 200 || res.status === 204) {
          refetchTable();
          setIsShowModalDelete(null);
          ModalStatus({
            status: 'success',
            title: 'Berhasil Dihapus',
            content: `Bundle telah di hapus`,
          });
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
          setIsShowModalDelete(null);
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
        setIsShowModalDelete(null);
      },
    });

  const handleChangeFilter = (name: string, value: any) => {
    setFilterStatisManifestBrankas(prevState => ({
      ...prevState,
      [name]: value,
    }));
    if (name === 'search') {
      setFilterManifestBrankas(prevState => ({
        ...prevState,
        search: value,
      }));
    }
  };

  const handleResetFilter = () => {
    setFilterManifestBrankas(initialValuesFilter);
    setFilterStatisManifestBrankas(initialValuesFilter);
  };

  const handleSubmitFilter = (value: FilterManifestBrankasProps) => {
    setFilterManifestBrankas(value);
  };

  const handleShowModalEditRetur = (data?: any) => {
    setIsShowEditKode(!isShowEditKode);
    setSelectedData(isShowEditKode ? null : data);
  };

  const handleShowModalDownloadListManifest = (data?: any) => {
    setIsShowDownloadListManifest(!isShowDownloadListManifest);
    setSelectedData(isShowDownloadListManifest ? null : data);
  };

  const handleShowModalAdd = () => {
    setIsShowAddKode(!isShowAddKode);
  };

  const handleShowModalEdit = (id: number) => {
    setIsShowModalEdit(id);
  };

  const handleScanModal = (id: number) => {
    setIsShowModalScan(id);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        marginBottom: 48,
      }}
    >
      <FilterManifestBrankas
        filterManifestBrankas={filterStatisManifestBrankas}
        handleChangeFilter={handleChangeFilter}
        handleResetFilter={handleResetFilter}
        isLoadingCustomers={isLoadingCustomers}
        dataCustomers={dataCustomers}
        handleSubmitFilter={handleSubmitFilter}
      />
      <TableManifestBrankas
        page={page}
        handlePage={setPage}
        handleSearchName={value => handleChangeFilter('search', value)}
        dataTable={dataTableManifestBrankas?.data}
        handleShowModalDownloadListManifest={
          handleShowModalDownloadListManifest
        }
        handleShowModalEdit={handleShowModalEdit}
        isLoadingTableManifestBrankas={
          isLoadingTableManifestBrankas || isLoadingDelete
        }
        setIsShowModalAdd={() => setIsShowModalAdd(!isShowModalAdd)}
        setIsShowModalScan={id => handleScanModal(id)}
        setIsShowModalDelete={setIsShowModalDelete}
      />
      <ModalConfirmation
        title="Apakah anda yakin?"
        description="Bundle yang dihapus tidak bisa dikembalikan"
        visibleModal={!!isShowModalDelete}
        onConfirm={() => setIsShowModalDelete(null)}
        onCancel={() => mutateDeleteBundle(isShowModalDelete)}
        centered
        cancelText="YA"
        okeText="TIDAK"
      />
      <ModalEditCodeRetur
        isShow={isShowEditKode}
        selectedData={selectedData}
        onCancel={handleShowModalEditRetur}
        onClickAddRetur={handleShowModalAdd}
        dataManifestRetur={dataManifestRetur}
        refetchManifestRetur={refetchManifestRetur}
        loadingManifestRetur={isLoadingManifest || isFetchingManifest}
        handleGetDataManifest={refetchTable}
      />
      <ModalAddCodeRetur
        isShow={isShowAddKode}
        dataManifestRetur={dataManifestRetur}
        onCancel={handleShowModalAdd}
        refetchManifestRetur={refetchManifestRetur}
      />
      <ModalDownloadListManifest
        isShow={isShowDownloadListManifest}
        selectedData={selectedData}
        onCancel={handleShowModalDownloadListManifest}
      />
      {isShowModalAdd && (
        <ModalAddManifestBrankas
          dataManifestRetur={dataManifestRetur}
          isShowModalAdd={isShowModalAdd}
          setIsShowModalAdd={() => setIsShowModalAdd(!isShowModalAdd)}
          setIsShowModalScan={value => setIsShowModalScan(value)}
          loadingManifestRetur={isLoadingManifest || isFetchingManifest}
          onClickAddRetur={handleShowModalAdd}
          dataCustomers={dataCustomers}
          refetchTable={refetchTable}
        />
      )}
      <ModalEditManifestBrankas
        dataManifestRetur={dataManifestRetur}
        isShowModalEdit={isShowModalEdit}
        setIsShowModalEdit={() => setIsShowModalEdit(null)}
        loadingManifestRetur={isLoadingManifest || isFetchingManifest}
        onClickAddRetur={handleShowModalAdd}
        dataCustomers={dataCustomers}
        refetchTable={refetchTable}
      />
      <ModalScanManifestBrankas
        isShowModalScan={isShowModalScan}
        setIsShowModalScan={() => setIsShowModalScan(null)}
        refetchTable={refetchTable}
      />
    </div>
  );
}
