import React from 'react';
import styles from './ModalFormBagging.module.css';
import { Row, Col } from 'antd';
import moment from 'moment-timezone';
import { Form, Field } from 'react-final-form';
import { InputText } from 'components/InputField';
import SelectField from 'components/SelectField';
import InputDatePicker from 'components/InputDatePicker';
import Button from 'components/Button';
import ModalMaterial from 'materials/ModalMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import { BaggingDocumentFormType } from 'module/DeliveryBetweenPosts/Component';
import { BagTypes } from 'lib/constants';

type ModalFormBaggingProps = {
  dataAllBranch: Array<any>;
  dataAllCourier: Array<any>;
  isLoadingBranch?: boolean;
  isLoadingCourier?: boolean;
  roleAccount: string;
  isShowModal: boolean;
  isEdit?: boolean;
  isDetail?: boolean;
  isLoadingSubmitForm?: boolean;
  handleChangeFormBagging: (key: string, value: any) => void;
  handleCloseModal: () => void;
  dataFormBagging: BaggingDocumentFormType;
  handleSubmitForm: (value: BaggingDocumentFormType) => void;
};

export default function ModalFormBagging({
  dataAllBranch,
  dataAllCourier,
  isLoadingBranch,
  roleAccount,
  isShowModal = false,
  isEdit = false,
  isDetail = false,
  isLoadingSubmitForm = false,
  handleChangeFormBagging,
  handleCloseModal,
  handleSubmitForm,
  dataFormBagging,
}: ModalFormBaggingProps) {
  function disabledDate(current) {
    return current && current.isBefore(moment().subtract(1, 'day'));
  }
  const isBranch = roleAccount === 'Branch';
  const modalTitle = isEdit
    ? 'EDIT BAG'
    : isDetail
    ? 'DETAIL BARCODE BAG'
    : 'TAMBAH BAG BARU';

  return (
    <div>
      <ModalMaterial
        teksTitle={modalTitle}
        width="70%"
        visible={isShowModal}
        onCancel={handleCloseModal}
        content={
          <SpinMaterial
            spinning={isLoadingSubmitForm}
            size={'large'}
            content={
              <div style={{ marginTop: 24, padding: '0 24px' }}>
                <Form
                  initialValues={{
                    typeBag: dataFormBagging.typeBag,
                    dateSend: dataFormBagging.dateSend,
                    courier: dataFormBagging.courier,
                    branchFrom: dataFormBagging.branchFrom,
                    branchTo: dataFormBagging.branchTo,
                    barcodeBag: dataFormBagging.barcodeBag,
                  }}
                  validate={values => {
                    const errors = {} as any;
                    if (!values.typeBag) {
                      errors.typeBag = 'Jenis Bag wajib dipilih';
                    }
                    if (!values.dateSend) {
                      errors.dateSend = 'Tanggal Kirim wajib dipilih';
                    }
                    if (!values.courier) {
                      errors.courier = 'Kurir wajib dipilih';
                    }
                    if (!values.branchFrom) {
                      errors.branchFrom = 'Posko asal wajib dipilih';
                    }
                    if (!values.branchTo) {
                      errors.branchTo = 'Posko tujuan wajib dipilih';
                    }
                    return errors;
                  }}
                  onSubmit={handleSubmitForm}
                >
                  {({ handleSubmit, submitting, valid, values }) => (
                    <div className={styles.wrapperFilter}>
                      <Row gutter={24}>
                        <Col md={12} className={styles.filterColumn}>
                          <Field name="typeBag">
                            {({ input, meta }) => (
                              <SelectField
                                validate
                                label="Jenis Bag"
                                placeholder="Pilih Jenis Bag"
                                data={BagTypes}
                                value={input.value}
                                onChange={value => {
                                  handleChangeFormBagging('typeBag', value);
                                  input.onChange(value);
                                }}
                                onBlur={value => {
                                  input.onBlur(value);
                                }}
                                validateStatus={
                                  meta.error && meta.touched
                                    ? 'error'
                                    : 'validating'
                                }
                                errorMessage={
                                  meta.error && meta.touched && meta.error
                                }
                              />
                            )}
                          </Field>
                          <Field name="barcodeBag">
                            {input => (
                              <InputText
                                label="Barcode Bag"
                                placeholder="Masukan barcode bag disini"
                                formStyle={{ margin: 0 }}
                                disabled
                                {...input}
                              />
                            )}
                          </Field>
                          <Field name="courier">
                            {({ input, meta }) => (
                              <SelectField
                                validate
                                label="Kurir"
                                placeholder="Pilih Kurir"
                                data={dataAllCourier}
                                value={input.value}
                                onChange={value => {
                                  handleChangeFormBagging('courier', value);
                                  input.onChange(value);
                                }}
                                onBlur={value => {
                                  input.onBlur(value);
                                }}
                                validateStatus={
                                  meta.error && meta.touched
                                    ? 'error'
                                    : 'validating'
                                }
                                errorMessage={
                                  meta.error && meta.touched && meta.error
                                }
                                disabled={
                                  values.typeBag === 'Delivery'
                                    ? !dataFormBagging.branchTo
                                    : !dataFormBagging.branchFrom
                                }
                              />
                            )}
                          </Field>
                        </Col>
                        <Col md={12} className={styles.filterColumn}>
                          <Field name="dateSend">
                            {({ input, meta }) => (
                              <InputDatePicker
                                label="Tanggal Kirim"
                                placeholder="Pilih Tanggal"
                                onOpenChange={input.onBlur}
                                onChange={value => {
                                  handleChangeFormBagging('dateSend', value);
                                  input.onChange(value);
                                }}
                                value={input.value}
                                error={
                                  meta.touched &&
                                  meta.error !== undefined &&
                                  meta.error
                                }
                                disabledDate={disabledDate}
                              />
                            )}
                          </Field>
                          <Field name="branchFrom">
                            {({ input, meta }) => (
                              <SelectField
                                validate
                                label="Posko Asal"
                                placeholder="Pilih Posko"
                                data={dataAllBranch.filter(
                                  value => value.id !== values.branchTo
                                )}
                                loading={isLoadingBranch}
                                disabled={isBranch || isEdit}
                                value={input.value}
                                onChange={value => {
                                  input.onChange(value);
                                  handleChangeFormBagging('branchFrom', value);
                                }}
                                onBlur={value => {
                                  input.onBlur(value);
                                }}
                                validateStatus={
                                  meta.error && meta.touched
                                    ? 'error'
                                    : 'validating'
                                }
                                errorMessage={
                                  meta.error && meta.touched && meta.error
                                }
                              />
                            )}
                          </Field>
                          <Field name="branchTo">
                            {({ input, meta }) => (
                              <SelectField
                                validate
                                label="Posko Tujuan"
                                placeholder="Pilih Posko"
                                data={dataAllBranch.filter(
                                  value => value.id !== values.branchFrom
                                )}
                                loading={isLoadingBranch}
                                value={input.value}
                                onChange={value => {
                                  handleChangeFormBagging('branchTo', value);
                                  input.onChange(value);
                                }}
                                onBlur={value => {
                                  input.onBlur(value);
                                }}
                                validateStatus={
                                  meta.error && meta.touched
                                    ? 'error'
                                    : 'validating'
                                }
                                errorMessage={
                                  meta.error && meta.touched && meta.error
                                }
                              />
                            )}
                          </Field>
                        </Col>
                      </Row>

                      <div className={styles.actionFilter}>
                        {!isDetail && (
                          <Button
                            text={isEdit ? 'Simpan' : 'Simpan & Scan Waybill'}
                            onClick={handleSubmit}
                            disabled={submitting || !valid}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            }
          />
        }
      />
    </div>
  );
}
