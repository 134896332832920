import { Action } from 'redux';

const initialState = {
  PropertiesAdminBranch: {
    idAdmin: null,
    LoadingAdminBranch: false,
    LoadingTypeAdminBranch: null,
  },
  FormAdmin: {
    AdminName: null,
    AdminLastName: null,
    AdminEmail: null,
    AccountType: null,
    AdminPhoneNumber: null,
    AdminDob: null,
    AdminAddress: null,
    AdminPhoto: null,
    AdminBranchPosition: null,
    Code: null,
    Username: null,
    RolePermission: null,
    IsActive: false,
  },
  AdminBranchAll: [],
};

export interface IAuthAction extends Action<string> {}

export interface IChangeIdAdmin extends IAuthAction {
  idAdmin: any;
}
export interface ISetAdminBranchAll extends IAuthAction {
  AdminBranchAll: any;
}

export interface IChangeAdminName extends IAuthAction {
  AdminName: any;
}
export interface IChangeAccountType extends IAuthAction {
  AccountType: any;
}

export interface IChangeAdminLastName extends IAuthAction {
  AdminLastName: any;
}

export interface IChangeAdminEmail extends IAuthAction {
  AdminEmail: any;
}
export interface IChangeAdminPhoneNumber extends IAuthAction {
  AdminPhoneNumber: any;
}

export interface IChangeAdminDob extends IAuthAction {
  AdminDob: any;
}
export interface IChangeAdminAddress extends IAuthAction {
  AdminAddress: any;
}
export interface IChangeAdminPhoto extends IAuthAction {
  AdminPhoto: any;
}

export interface IChangeAdminBranchPosition extends IAuthAction {
  AdminBranchPosition: any;
}

export interface IChangeAdminCode extends IAuthAction {
  Code: any;
}
export interface IChangeAdminRolePermission extends IAuthAction {
  RolePermission: any;
}

export interface IChangeAdminStatus extends IAuthAction {
  IsActive: any;
}

export interface IChangeAdminUsername extends IAuthAction {
  Username: any;
}
export interface IChangeLoadingTypeAdminBranch extends IAuthAction {
  LoadingTypeAdminBranch: any;
}

export default function AuthReducerDetailAdmin(
  state = initialState,
  action: IAuthAction
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'SET_ALL_ADMIN_BRANCH':
      const setAdminBranchAll = action as ISetAdminBranchAll;
      newState.AdminBranchAll = setAdminBranchAll.AdminBranchAll;

      return { ...newState };
    case 'CHANGE_ADMIN_NAME_FORM':
      const changeAdminName = action as IChangeAdminName;
      newState.FormAdmin.AdminName = changeAdminName.AdminName;

      return { ...newState };

    case 'CHANGE_ADMIN_ACCOUNT_TYPE_FORM':
      const changeAccountType = action as IChangeAccountType;
      newState.FormAdmin.AccountType = changeAccountType.AccountType;

      return { ...newState };

    case 'CHANGE_ADMIN_LAST_NAME_FORM':
      const changeAdminLastName = action as IChangeAdminLastName;
      newState.FormAdmin.AdminLastName = changeAdminLastName.AdminLastName;

      return { ...newState };

    case 'CHANGE_ADMIN_EMAIL_FORM':
      const changeAdminEmail = action as IChangeAdminEmail;
      newState.FormAdmin.AdminEmail = changeAdminEmail.AdminEmail;

      return { ...newState };

    case 'CHANGE_ADMIN_PHONE_NUMBER_FORM':
      const changeAdminPhoneNumber = action as IChangeAdminPhoneNumber;
      newState.FormAdmin.AdminPhoneNumber =
        changeAdminPhoneNumber.AdminPhoneNumber;

      return { ...newState };

    case 'CHANGE_ADMIN_DOB_FORM':
      const changeAdminDob = action as IChangeAdminDob;
      newState.FormAdmin.AdminDob = changeAdminDob.AdminDob;

      return { ...newState };

    case 'CHANGE_ADMIN_ADDRESS_FORM':
      const changeAdminAddress = action as IChangeAdminAddress;
      newState.FormAdmin.AdminAddress = changeAdminAddress.AdminAddress;

      return { ...newState };

    case 'CHANGE_ADMIN_PHOTO_FORM':
      const changeAdminPhoto = action as IChangeAdminPhoto;
      newState.FormAdmin.AdminPhoto = changeAdminPhoto.AdminPhoto;

      return { ...newState };

    case 'CHANGE_ADMIN_BRANCH_POSITION_FORM':
      const changeAdminBranchPosition = action as IChangeAdminBranchPosition;
      newState.FormAdmin.AdminBranchPosition =
        changeAdminBranchPosition.AdminBranchPosition;

      return { ...newState };

    case 'CHANGE_ADMIN_CODE_FORM':
      const changeAdminCode = action as IChangeAdminCode;
      newState.FormAdmin.Code = changeAdminCode.Code;

      return { ...newState };

    case 'CHANGE_ADMIN_ROLE_FORM':
      const changeAdminRole = action as IChangeAdminRolePermission;
      newState.FormAdmin.RolePermission = changeAdminRole.RolePermission;

      return { ...newState };

    case 'CHANGE_ADMIN_STATUS_FORM':
      const changeAdminStatus = action as IChangeAdminStatus;
      newState.FormAdmin.IsActive = changeAdminStatus.IsActive;

      return { ...newState };

    case 'CHANGE_ADMIN_USERNAME_FORM':
      const changeAdminUsername = action as IChangeAdminUsername;
      newState.FormAdmin.Username = changeAdminUsername.Username;

      return { ...newState };

    case 'CHANGE_ADMIN_DETAIL':
      const changeAdminDetail = action as IChangeIdAdmin;
      newState.PropertiesAdminBranch.idAdmin = changeAdminDetail.idAdmin;

      return { ...newState };

    case 'SET_LOADING_ADMIN_BRANCH':
      newState.PropertiesAdminBranch.LoadingAdminBranch =
        !newState.PropertiesAdminBranch.LoadingAdminBranch;

      return { ...newState };

    case 'SET_LOADING_TYPE_ADMIN_BRANCH':
      const changeLoadingTypeAdminBranch =
        action as IChangeLoadingTypeAdminBranch;
      newState.PropertiesAdminBranch.LoadingTypeAdminBranch =
        changeLoadingTypeAdminBranch.LoadingTypeAdminBranch;

      return { ...newState };

    case 'CLEAR_LOADING_TYPE_ADMIN_BRANCH':
      newState.PropertiesAdminBranch.LoadingTypeAdminBranch = null;

      return { ...newState };

    case 'CLEAR_ALL_ADMIN_BRANCH':
      newState.AdminBranchAll = [];

      return { ...newState };

    case 'CHANGE_CLEAR_DATA_ADMIN_FORM':
      newState.FormAdmin.AdminName = null;
      newState.FormAdmin.AccountType = null;
      newState.FormAdmin.AdminLastName = null;
      newState.FormAdmin.AdminEmail = null;
      newState.FormAdmin.AdminPhoneNumber = null;
      newState.FormAdmin.AdminDob = null;
      newState.FormAdmin.AdminAddress = null;
      newState.FormAdmin.AdminPhoto = null;
      newState.FormAdmin.Code = null;
      newState.FormAdmin.RolePermission = null;
      newState.FormAdmin.IsActive = false;
      newState.AdminBranchAll = [];
      newState.PropertiesAdminBranch.idAdmin = null;
      return { ...newState };
  }
  return state;
}
