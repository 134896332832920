import { Action } from 'redux';

const initialState = {
  Photo: null,
  Initials: null,
  FirstName: null,
  LastName: null,
  UserName: null,
  NoTelp: null,
  Email: null,
  Address: null,
};

export interface IAuthAction extends Action<string> {}

export interface ISetPhotoProfile extends IAuthAction {
  Photo: any;
}
export interface ISetInitialsProfile extends IAuthAction {
  Initials: any;
}
export interface ISetFirstNameProfile extends IAuthAction {
  FirstName: any;
}
export interface ISetLastNameProfile extends IAuthAction {
  LastName: any;
}
export interface ISetUserNameProfile extends IAuthAction {
  UserName: any;
}
export interface ISetNoTelpProfile extends IAuthAction {
  NoTelp: any;
}
export interface ISetEmailProfile extends IAuthAction {
  Email: any;
}
export interface ISetAddressProfile extends IAuthAction {
  Address: any;
}

export default function AuthReducer(state = initialState, action: IAuthAction) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'SET_PHOTO_PROFILE':
      const setPhotoProfile = action as ISetPhotoProfile;
      newState.Photo = setPhotoProfile.Photo;
      return { ...newState };

    case 'SET_INITIALS_PROFILE':
      const setInitialsProfile = action as ISetInitialsProfile;
      newState.Initials = setInitialsProfile.Initials;
      return { ...newState };

    case 'SET_FIRST_NAME_PROFILE':
      const setFirstNameProfile = action as ISetFirstNameProfile;
      newState.FirstName = setFirstNameProfile.FirstName;
      return { ...newState };

    case 'SET_LAST_NAME_PROFILE':
      const setLastNameProfile = action as ISetLastNameProfile;
      newState.LastName = setLastNameProfile.LastName;
      return { ...newState };

    case 'SET_USER_NAME_PROFILE':
      const setUserNameProfile = action as ISetUserNameProfile;
      newState.UserName = setUserNameProfile.UserName;
      return { ...newState };

    case 'SET_NO_TELP_PROFILE':
      const setNoTelpProfile = action as ISetNoTelpProfile;
      newState.NoTelp = setNoTelpProfile.NoTelp;
      return { ...newState };

    case 'SET_EMAIL_PROFILE':
      const setEmailProfile = action as ISetEmailProfile;
      newState.Email = setEmailProfile.Email;
      return { ...newState };

    case 'SET_ADDRESS_PROFILE':
      const setAddressProfile = action as ISetAddressProfile;
      newState.Address = setAddressProfile.Address;
      return { ...newState };

    case 'CHANGE_CLEAR_DATA_PROFILE':
      newState.Photo = null;
      newState.Initials = null;
      newState.FirstName = null;
      newState.LastName = null;
      newState.UserName = null;
      newState.NoTelp = null;
      newState.Email = null;
      newState.Address = null;
      return { ...newState };
  }
  return { ...newState };
}
