import instance from 'config/interceptor';

export const getCustomerConfigReport = async params => {
  try {
    const { searchValue, IdCustomer, isActiveType = 'None' } = params;
    let link = `/Setting/CustomReportFile/Customer/${IdCustomer}`;
    if (isActiveType) link = link.concat(`/${isActiveType}`);
    if (searchValue) link = link.concat(`&ValueSearch=${searchValue}`);
    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const getCustomerDetailCsvList = async params => {
  try {
    const { IdCustomer, isActiveType = 'None' } = params;
    let link = `/Setting/CustomCsv/Customer/${IdCustomer}`;
    if (isActiveType) link = link.concat(`/${isActiveType}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const postCustomerFormatReport = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/Setting/CustomReportFile`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const editCustomerFormatReport = async data => {
  try {
    return await instance.put(
      process.env.REACT_APP_URL + `/Setting/CustomReportFile/${data.id}`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const detailCustomerFormatReport = async id => {
  try {
    return await instance.get(
      process.env.REACT_APP_URL + `/Setting/CustomReportFile/${id}`
    );
  } catch (error) {
    return error;
  }
};

export const changeCustomerStatusTemplateReport = async id => {
  try {
    return await instance.put(
      process.env.REACT_APP_URL + `/Setting/CustomCsv/ChangeStatus/${id}`
    );
  } catch (error) {
    return error;
  }
};
