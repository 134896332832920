import * as ActionAuth from '../../action/Auth';
import * as ActionComplaintList from '../../action/Complaint/ActionComplaintList';
import * as ActionLogin from '../../../module/Login/Store';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../action/toggle';

import axios from 'axios';

export default function* S_GetDataTableComplaint(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const filterStatus = state.Complaint.complaintProperties.filterStatus;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Complaint/GetDataTiket/0/100000/' +
        filterStatus,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComplaintList.setComplaintList(data.data));
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* SM_GetDataTableComplaint() {
  yield takeLatest('GET_COMPLAINT_LIST', S_GetDataTableComplaint);
}
