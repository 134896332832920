export function changeDDDelliveryTime(DeliveryTime:any)
{
    return({
        type:"CHANGE_DD_DELIVERY_TIME",
        DeliveryTime
    })
}
export function changeDDBranchId(BranchId:any)
{
    return({
        type:"CHANGE_DD_BRANCH_ID",
        BranchId
    })
}
export function changeDDBranchName(BranchName:any)
{
    return({
        type:"CHANGE_DD_BRANCH_NAME",
        BranchName
    })
}
export function changeDDCourierId(CourierId:any)
{
    return({
        type:"CHANGE_DD_COURIER_ID",
        CourierId
    })
}
export function changeDDCourierCode(CourierCode:any)
{
    return({
        type:"CHANGE_DD_COURIER_CODE",
        CourierCode
    })
}
export function changeDDCycle(Cycle:any)
{
    return({
        type:"CHANGE_DD_CYCLE",
        Cycle
    })
}
export function changeDDPosition(Position:any)
{
    return({
        type:"CHANGE_DD_POSITION",
        Position
    })
}
export function changeClearDataDownload()
{
    return({
        type:"CLEAR_DATA_DOWNLOAD",
    })
}
