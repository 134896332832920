import * as ActionListResendSubmit from 'core/action/Attempt/ActionListResendSubmit';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import HistoryListResendComponent from '../Component/HistoryListResendComponent';
import { connect } from 'react-redux';

interface IProps {
  getListResend: any;
  actionListResendSubmit: any;
}
function HistoryListResendContainer({
  getListResend,
  actionListResendSubmit,
}: IProps) {
  const [CollapsListResend, setCollapsListResend] = useState(false);
  const [CollapsKeyListResend, setCollapsKeyListResend] = useState(null);

  function handleCollapsListResend(event: any) {
    setCollapsListResend(!CollapsListResend);
    setCollapsKeyListResend(event);
  }
  function handleViewStatusDelivery(event: any) {
    const url = '/delivery/status?' + event;
    window.open(url, '_blank');
  }
  function handleRemoveAllListResend() {
    actionListResendSubmit.clearListResendSubmit();
  }

  return (
    <div>
      <HistoryListResendComponent
        handleCollapsListResend={handleCollapsListResend}
        handleRemoveAllListResend={handleRemoveAllListResend}
        handleViewStatusDelivery={handleViewStatusDelivery}
        CollapsListResend={CollapsListResend}
        CollapsKeyListResend={CollapsKeyListResend}
        getListResend={getListResend}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  getListResend: state.Resend.ListSubmitResendDelivery,
  address: state.Resend.ListSubmitResendDelivery,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionListResendSubmit: bindActionCreators(ActionListResendSubmit, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(HistoryListResendContainer);
