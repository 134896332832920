import {
  IFetchPickUpListAction,
  IFetchResultUploadAction,
  ISetCsvPickupProcessDataAction,
  ISetCustIdAction,
  ISetFileNameAction,
} from './PickUpProcessAction';

import { Action } from 'redux';

const initialState: any = {
  list: [],
  detailPickupList: [],
  allBranchList: [],
  courierBranchList: [],
  resultUpload: null,
  custId: null,
  fileName: null,
  csvPickupProcess: null,
};

export default function PickUpProcessReducer(
  state = initialState,
  action: Action
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_PICKUP_LIST': {
      const fetchPickUpListAction = action as IFetchPickUpListAction;
      newState.list = fetchPickUpListAction.data;
      return { ...newState };
    }
    case 'FETCH_DETAIL_PICKUP_LIST': {
      const fetchDetailPickUpListAction = action as IFetchPickUpListAction;
      newState.detailPickupList = fetchDetailPickUpListAction.data;
      return { ...newState };
    }
    case 'FETCH_ALL_BRANCH_LIST': {
      const fetchAllBranchListAction = action as IFetchPickUpListAction;
      newState.allBranchList = fetchAllBranchListAction.data;
      return { ...newState };
    }
    case 'FETCH_COURIER_BRANCH_LIST': {
      const fetchAllBranchListAction = action as IFetchPickUpListAction;
      newState.courierBranchList = fetchAllBranchListAction.data;
      return { ...newState };
    }
    case 'SET_CUST_ID_PICKUP': {
      const setCustIdAction = action as ISetCustIdAction;
      newState.custId = setCustIdAction.data;
      return { ...newState };
    }
    case 'FETCH_RESULT_UPLOAD_PICKUP': {
      const fetchResultUploadAction = action as IFetchResultUploadAction;
      newState.resultUpload = fetchResultUploadAction.data;
      return { ...newState };
    }
    case 'SET_FILE_NAME_ASSIGN_CSV': {
      const setFileNameAction = action as ISetFileNameAction;
      newState.fileName = setFileNameAction.data;
      return { ...newState };
    }
    case 'SET_PICKUP_PROCESS_DATA_CSV': {
      const setCsvPickupProcessAction =
        action as ISetCsvPickupProcessDataAction;
      newState.csvPickupProcess = setCsvPickupProcessAction.data;
      return { ...newState };
    }
    case 'CLEAR_FORM_UPLOAD_PICKUP_PROCESS': {
      newState.fileName = null;
      newState.csvPickupProcess = null;
      return { ...newState };
    }
    case 'CLEAR_FILE_NAME_PICKUP_PROCESS': {
      newState.fileName = null;
      return { ...newState };
    }
    case 'CLEAR_ALL_BRANCH_PICKUP_PROCESS': {
      newState.allBranchList = [];
      return { ...newState };
    }
    case 'CLEAR_COURIER_BRANCH_PICKUP_PROCESS': {
      newState.courierBranchList = [];
      return { ...newState };
    }
    case 'CLEAR_RESULT_UPLOAD_PICKUP_PROCESS': {
      newState.resultUpload = null;
      return { ...newState };
    }
    case 'CLEAR_PICKUP_PROCESS_DATA': {
      newState.list = [];
      newState.detailPickupList = [];
      newState.allBranchList = [];
      newState.courierBranchList = [];
      newState.custId = null;
      newState.fileName = null;
      newState.csvPickupProcess = null;
      return { ...newState };
    }
  }
  return state;
}
