export function setRouter(router:any)
{
    return({
        type:"SET_ROUTER",
        router
    })
}

export function setComponentRouter(componentRouter:any)
{
    return({
        type:"SET_COMPONENT_ROUTER",
        componentRouter
    })
}