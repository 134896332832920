export function changeUploadDateSoftcopy(UploadDate: any) {
  return {
    type: 'CHANGE_UPLOAD_DATE_SOFTCOPY',
    UploadDate,
  };
}
export function submitUploadSoftcopy() {
  return {
    type: 'SUBMIT_UPLOAD_SOFTCOPY',
  };
}
export function clearUploadSoftcopy() {
  return {
    type: 'CLEAR_UPLOAD_SOFTCOPY',
  };
}
