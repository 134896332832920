import { Col, Row, Table } from 'antd';
import ModalMaterial from 'materials/ModalMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import React from 'react';

interface IProps {
  modalDetailUnscanDocument: boolean;
  handleCancel: () => void;
  detailUnscanDataTable: Array<any>;
  isFetchingDetailUnscanDataTable: boolean;
  currentPage: number;
  totalDatas: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  columnData: Array<any>;
}
export default function ModalDetailUnscanDocumentComponent(props: IProps) {
  const {
    modalDetailUnscanDocument,
    handleCancel,
    isFetchingDetailUnscanDataTable,
    detailUnscanDataTable = [],
    setCurrentPage,
    totalDatas,
    columnData,
    currentPage,
  } = props;
  return (
    <ModalMaterial
      width={'70%'}
      teksTitle="DOKUMEN BELUM DI SCAN"
      visible={modalDetailUnscanDocument}
      onCancel={() => handleCancel()}
    >
      <SpinMaterial spinning={isFetchingDetailUnscanDataTable} size="large">
        <Row style={{ padding: '0 36px', marginTop: '20px' }} gutter={[0, 24]}>
          <Col span={24}>
            <p
              style={{
                margin: 0,
                color: '#EA1A1A',
                fontWeight: 'bold',
              }}
            >
              Jumlah: {totalDatas || 0}
            </p>
          </Col>
        </Row>
        <Table
          style={{ padding: '16px 24px' }}
          rowKey={(record: any) => record.id}
          scroll={{ x: 'auto' }}
          pagination={{
            onChange: page => setCurrentPage(page - 1),

            pageSize: 10,
            total: totalDatas,
            current: currentPage === 0 ? 1 : currentPage + 1,
          }}
          bordered={false}
          dataSource={detailUnscanDataTable || []}
          columns={columnData}
        />
      </SpinMaterial>
    </ModalMaterial>
  );
}
