import React, { useState } from 'react';
import { Row, Col, Form, DatePicker, Radio } from 'antd';
import locale from 'antd/es/date-picker/locale/id_ID';
import CardMaterial from '../../../materials/CardMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';
import ButtonMaterial from '../../../materials/ButtonMaterial';
import FormRestoreDataByWaybillContainer from '../../../containers/Content/RestoreData/FormRestoreDataByWaybillContainer';

interface IProps {
  lastAccess: any;
  uploadDate: any;
  uploadDateW: any;
  radioButton: any;
  radioButtonW: any;
  validateUploadDate: any;
  validateUploadDateW: any;
  helpValidateUploadDate: any;
  helpValidateUploadDateW: any;
  handleValidateUploadDate: any;
  handleValidateUploadDateW: any;
  handlePutUploadDate: any;
  handlePutUploadDateW: any;
  handleGetData: any;
  handleChangeRadioButton: any;
  handleModal: any;
}

export default function FormRestoreDataComponent(props: IProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const validateButtonSearch =
    props.lastAccess === 'restoreDataCsv' || props.lastAccess === null
      ? props.uploadDate === null || props.validateUploadDate === 'error'
        ? true
        : false
      : props.uploadDateW === null || props.validateUploadDateW === 'error'
      ? true
      : false;

  return (
    <div>
      <SpinMaterial spinning={false} size={'large'}>
        <CardMaterial
          extra={''}
          title={<b style={{ color: '#11BEFF' }}>Hapus & Restore Data</b>}
          style={{ borderRadius: '10px' }}
          content={
            <Row>
              <Col span={12} style={{ paddingRight: '20px' }}>
                <div>
                  <Row>
                    <div style={{ marginBottom: '25px' }}>
                      <Radio.Group
                        onChange={props.handleChangeRadioButton}
                        value={
                          props.lastAccess === 'restoreDataCsv' ||
                          props.lastAccess === null
                            ? props.radioButton
                            : props.radioButtonW
                        }
                      >
                        <Radio value={'Delete'}>Hapus Data</Radio>
                        <Radio value={'Restore'}>Restore Data</Radio>
                      </Radio.Group>
                    </div>
                  </Row>
                  <Row>
                    <Col span={8} style={{ paddingTop: 10 }}>
                      <span>
                        <b>Tanggal Upload File</b>
                      </span>
                    </Col>
                    <Col span={12}>
                      <div>
                        {props.lastAccess === 'restoreDataCsv' ||
                        props.lastAccess === null ? (
                          <Form.Item
                            validateStatus={props.validateUploadDate}
                            help={props.helpValidateUploadDate}
                          >
                            <DatePicker
                              className={'datePickerDefault'}
                              locale={locale}
                              format={'YYYY-MM-DD'}
                              placeholder={'Pilih Tanggal'}
                              onOpenChange={props.handleValidateUploadDate}
                              value={props.uploadDate}
                              onChange={props.handlePutUploadDate}
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            validateStatus={props.validateUploadDateW}
                            help={props.helpValidateUploadDateW}
                          >
                            <DatePicker
                              className={'datePickerDefault'}
                              locale={locale}
                              format={'YYYY-MM-DD'}
                              placeholder={'Pilih Tanggal'}
                              onOpenChange={props.handleValidateUploadDateW}
                              value={props.uploadDateW}
                              onChange={props.handlePutUploadDateW}
                            />
                          </Form.Item>
                        )}
                      </div>
                    </Col>
                    <Col span={4} style={{ paddingTop: 5 }}>
                      <ButtonMaterial
                        disabled={validateButtonSearch}
                        handleSubmit={props.handleGetData}
                        teksButton={'Cari'}
                        size={''}
                        shape={'round'}
                        className={'btnSearchRestoreData'}
                        type={'primary'}
                        icon={''}
                        style={{}}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={12}>
                {props.lastAccess === 'restoreDataWaybill' ? (
                  <ButtonMaterial
                    disabled={false}
                    handleSubmit={() => setShowModal(!showModal)}
                    teksButton={<u>Berdasarkan Waybill</u>}
                    size={''}
                    shape={''}
                    className={'linkWaybillRestoreData'}
                    type={'link'}
                    icon={''}
                    style={{}}
                  />
                ) : null}
              </Col>
            </Row>
          }
        />
      </SpinMaterial>
      <FormRestoreDataByWaybillContainer
        showModal={showModal}
        handleShowHideModal={() => setShowModal(!showModal)}
      />
    </div>
  );
}
