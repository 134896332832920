import {Action} from 'redux'

const initialState = 
{
    Fiture:null,
    Step:0
}

export interface IAuthAction extends Action<string>
{

}

export interface ILastAccessFiture extends IAuthAction
{
    Fiture : any
}
export interface IChangeStep extends IAuthAction
{
    Step : any
}

export default function AuthReducer(state=initialState, action:IAuthAction){
    const newState = Object.assign({}, state)
    switch(action.type)
    {
        case "CHANGE_LAST_ACCESS_FITURE":
            const changeLastAccessFiture = action as ILastAccessFiture
            newState.Fiture = changeLastAccessFiture.Fiture
            
        return {...newState}

        case "CHANGE_CLEAR_LAST_ACCESS_FITURE":
            newState.Fiture = null
        return {...newState}

        case "CHANGE_STEP":
            const changeStep = action as IChangeStep
            newState.Step = changeStep.Step
            
        return {...newState}

        case "CHANGE_CLEAR_STEP":
            newState.Step = 0
        return {...newState}
    }
    return state
}