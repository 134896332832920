import { Alert, Col, Row, Table } from 'antd';
import { Field, reduxForm } from 'redux-form';

import CButton from '../../../apps/assets/components/CButton';
import CCard from '../../../apps/assets/components/CCard';
import CIcon from '../../../apps/assets/components/CIcon';
import CModal from '../../../apps/assets/components/CModal';
import CSpin from '../../../apps/assets/components/CSpin';
import { InputDatePicker } from '../../../apps/assets/components/CInputDate';
import { InputFile } from '../../../apps/assets/components/CInput';
import { InputSelect } from '../../../apps/assets/components/CInput';
import { InputText } from '../../../apps/assets/components/CInput';
import React from 'react';
import moment from 'moment';
import { singleCheckbox } from '../../../apps/assets/components/CCheckbox';
import validate from 'module/PickUpProcess/Validation/PickupValidation';

function DetailPickUpComponent(props) {
  const {
    isLoading,
    pagePagination,
    totalPagination,
    detailPickupList,
    columns,
    handlePage,
    handleModal,
    handleGetCourierBranch,
    handleModalUpload,
    handleCloseModalUpload,
    assignCourierModalIsShow,
    uploadForAssignModalIsShow,
    handleCloseModal,
    submitAssignCourier,
    allBranch,
    courierBranch,
    resultUpload,
    resultUploadModalIsShow,
    handleBack,
    handleDownload,
    handleDownloadTemplate,
    handleConvertCsv,
    handleUpload,
    handleClearCourier,
    handleCloseModalResultUpload,
    handleDownloadResult,
    errorFormValue,
    formValue,
    valueCsvFile,
  } = props;

  const branchValue = formValue ? formValue.branch : null;
  const courierValue = formValue ? formValue.courier : null;
  const checkboxValue = formValue ? formValue.checkboxWaybill : null;
  const pickupDateValue = formValue ? formValue.pickupDate : null;
  const identityValue = formValue ? formValue.waybillPickup : null;
  const labelValue = !checkboxValue ? 'Waybill' : 'Barcode';
  const minDate = (d: any) => !d || d.isBefore(moment(new Date()).add(-1, 'day'));
  const validateFormDisabled = branchValue ? false : true;
  const validateButtonDisabled =
    !courierValue || !pickupDateValue || !identityValue === true ? true : false;

  const validateButtonUpload =
    valueCsvFile === null || errorFormValue.fileCsv ? true : false;
  const validateButtonDownload = detailPickupList.length !== 0 ? false : true;
  const buttonData = [
    {
      type: 'circle',
      className: 'btnPickupProcessUploadCourier',
      onClick: handleModalUpload,
      isLoading: isLoading,
      icon: 'upload',
      id: 'btnUploadAssignCourier',
      tooltipTitle: 'Unggah Data',
    },
    {
      type: 'circle',
      className: 'btnPickupProcessAssignCourier',
      onClick: handleModal,
      isLoading: isLoading,
      icon: 'user-add',
      id: 'btnAssignCourier',
      tooltipTitle: 'Tentukan Kurir',
    },
    {
      type: 'circle',
      className: `btnPickupProcessDownloadData${validateButtonDownload}`,
      onClick: handleDownload,
      isLoading: isLoading,
      icon: 'download',
      id: 'btnDownloadData',
      tooltipTitle: 'Unduh Data',
      disabled: validateButtonDownload,
    },
  ];

  const buttonAssign = [
    {
      type: 'primary',
      className: 'borderRadiusDefault',
      onClick: submitAssignCourier,
      isLoading: isLoading,
      content: 'Simpan',
      id: 'btnAssignCourier',
      disabled: validateButtonDisabled,
    },
  ];
  const buttonUploadForAssign = [
    {
      type: 'primary',
      className: 'borderRadiusDefault',
      onClick: handleUpload,
      isLoading: isLoading,
      content: 'Unggah',
      id: 'btnAssignCourier',
      disabled: validateButtonUpload,
    },
  ];
  const buttonBack = [
    {
      type: 'primary',
      className: 'btnBack',
      onClick: handleBack,
      isLoading: isLoading,
      content: 'Kembali',
      id: 'btnAssignCourier',
    },
  ];
  const btnDownloadTemplate = [
    {
      type: 'link',
      className: 'btnLinkItalic',
      onClick: handleDownloadTemplate,
      isLoading: isLoading,
      content: 'Download sample template',
      id: 'btnDownloadTemplatePickup',
    },
  ];
  const btnModalResultUpload = [
    {
      type: 'primary',
      className: 'btnDownloadDefault',
      onClick: handleDownloadResult,
      isLoading: isLoading,
      content: 'Download',
      id: 'btnDownloadResult',
    },
    {
      type: 'primary',
      className: 'btnDefaultSubmit',
      onClick: handleCloseModalResultUpload,
      isLoading: isLoading,
      content: 'Tutup',
      id: 'btnCloseResult',
    },
  ];
  const modalResultUpload = () => {
    const resultSuccess = resultUpload ? resultUpload.success : null;
    const resultFailed = resultUpload ? resultUpload.failed : null;
    const textAlertSuccess = () => {
      return (
        <React.Fragment>
          <Col span={6}>
            <CIcon className={'iconNotifSuccess'} type="check-square" />
          </Col>
          <Col span={18} className="greenText spacingTop">
            <Row>
              Berhasil : <b>{resultSuccess}</b>
            </Row>
          </Col>
        </React.Fragment>
      );
    };
    const textAlertFailed = () => {
      return (
        <React.Fragment>
          <Col span={6}>
            <CIcon className={'iconNotifFailed'} type="close-square" />
          </Col>
          <Col span={18} className="redText spacingTop">
            <Row>
              Gagal : <b>{resultFailed}</b>
            </Row>
          </Col>
        </React.Fragment>
      );
    };
    return (
      <CModal
        teksTitle={'STATUS UNGGAH DATA'}
        visible={resultUploadModalIsShow}
        onCancel={handleCloseModalResultUpload}
        content={
          <div className="defaultPaddingModal">
            <Row>
              <Col span={12} className="spacingRight">
                <Alert
                  className="alertSuccess"
                  message={textAlertSuccess()}
                  type="success"
                />
              </Col>
              <Col span={12} className="spacingLeft">
                <Alert
                  className="alertSuccess"
                  message={textAlertFailed()}
                  type="error"
                />
              </Col>
            </Row>
            <Row className="rowButtonCallCenter">
              <CButton buttonData={btnModalResultUpload} />
            </Row>
          </div>
        }
      />
    );
  };
  const modalAssignCourier = () => {
    return (
      <React.Fragment>
        <CModal
          className={'modalProcessPickupAssignCourier'}
          teksTitle={'ASSIGN KURIR'}
          visible={assignCourierModalIsShow}
          onCancel={handleCloseModal}
          content={
            <div className={'formAssignCourierPickup'}>
              <Field
                name="branch"
                component={InputSelect}
                idComponent="inputBranch"
                validateType={true}
                data={allBranch}
                dafaultItemName="Pilih Posko"
                additionalHandleChangeFunc={handleClearCourier}
                label={'Posko'}
              />
              <Field
                name="courier"
                component={InputSelect}
                customOption={'labelAndCode'}
                idComponent="inputCourier"
                data={courierBranch}
                dafaultItemName="Pilih Kurir"
                label={'Kurir'}
                additionalHandleOnFocusFunc={handleGetCourierBranch}
                disabled={validateFormDisabled}
              />
              <Field
                name="pickupDate"
                component={InputDatePicker}
                idComponent="inputPickupDate"
                validateType={true}
                label="Tanggal Ambil"
                typeDate="datePicker"
                disabledDate={minDate}
                placeholder={'Pilih Tanggal Ambil'}
              />
              <Row>
                <Col span={22}>
                  <Field
                    name="waybillPickup"
                    placeholder={`Masukan ${labelValue}`}
                    validateType={true}
                    label={labelValue}
                    component={InputText}
                    idComponent="inputWaybillPickup"
                    maxlength={24}
                  />
                </Col>
                <Col span={2}>
                  <Field
                    className="spacingMarginCheckbox"
                    name={'checkboxWaybill'}
                    component={singleCheckbox}
                    idComponent="inputRefreshment"
                  />
                </Col>
              </Row>
              <CButton buttonData={buttonAssign} buttonFloat={'right'} />
            </div>
          }
        />
      </React.Fragment>
    );
  };

  const modalUploadForAssignCourier = () => {
    return (
      <React.Fragment>
        <CModal
          className={'modalProcessPickupAssignCourier'}
          teksTitle={'Unggah Kurir'}
          visible={uploadForAssignModalIsShow}
          onCancel={handleCloseModalUpload}
          content={
            <div className={'formUploadAssignCourierPickup'}>
              <Row>
                <div className="fileUploadInput">
                  <Field
                    additionalHandleChangeFunc={handleConvertCsv}
                    name="fileCsv"
                    component={InputFile}
                    idComponent="inputFile"
                    multiple={false}
                    accept=".csv"
                    label={'Unggah Dokumen'}
                  />
                  <CButton
                    buttonData={btnDownloadTemplate}
                    buttonFloat={'left'}
                  />
                </div>
                <CButton
                  buttonData={buttonUploadForAssign}
                  buttonFloat={'right'}
                />
              </Row>
            </div>
          }
        />
      </React.Fragment>
    );
  };

  const content = () => {
    return (
      <div>
        <Row>
          <Col span={12}>
            <div className="cardSpacingTop">
              <span className="cardPickupProcessTitle">
                DETAIL PROSES PICK UP
              </span>
            </div>
          </Col>
          <Col span={12}>
            <div className={'floatRight'}>
              <CButton buttonData={buttonData} />
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="paddingTable">
      <CSpin
        spinning={isLoading}
        size={'large'}
        content={
          <React.Fragment>
            <CCard
              cardClassName={'cardHeaderProcessAsignCourier'}
              cardTitle={''}
              cardContent={content()}
            />
            <Table
              pagination={{
                onChange: page => {
                  handlePage(page);
                },
                pageSize: 10,
                total: totalPagination,
                current: pagePagination === 0 ? 1 : pagePagination,
              }}
              bordered={false}
              dataSource={detailPickupList}
              columns={columns}
              footer={() => <CButton buttonData={buttonBack} />}
            />
            {modalAssignCourier()}
            {modalUploadForAssignCourier()}
            {modalResultUpload()}
          </React.Fragment>
        }
      />
    </div>
  );
}

const ModalAssignCourierComponent = reduxForm({
  form: 'assignCourierForm',
  shouldError: () => {
    return true;
  },
  validate,
})(DetailPickUpComponent);
export default ModalAssignCourierComponent;
