import { Action } from 'redux';

const initialState = {
  PropertiesCustomerProduct: {
    idCustomerProduct: null,
    LoadingCustomerProduct: false,
    TypeLoadingCustomerProduct: null,
  },
  DetailCustomerProduct: {
    GroupProductId: null,
    GroupName: null,
    GroupNameProduct: null,
    CustomerProductName: null,
    isDisabled: true,
    CustomerProductCode: null,
    CustomerProductType: null,
    CustomerProductTypeOther: null,
    CustomerProductLogo: null,
    CustomerProductBonus: null,
    District1: null,
    District2: null,
    District3: null,
    District4: null,
    District5: null,
    District6: null,
    District7: null,
    District8: null,
    District9: null,
    District10: null,
    District11: null,
    District12: null,
    ListCustomerProductBonus: [],
  },
  ListCustomerProduct: [],
  ListGroupName: [],
};

export interface IAuthAction extends Action<string> {}

export interface ISetIdCustomerProduct extends IAuthAction {
  idCustomerProduct: any;
}
export interface ISetGroupProductId extends IAuthAction {
  GroupProductId: any;
}
export interface ISetGroupName extends IAuthAction {
  GroupName: any;
}
export interface ISetGroupNameProduct extends IAuthAction {
  GroupNameProduct: any;
}
export interface ISetCustomerProductName extends IAuthAction {
  CustomerProductName: any;
}
export interface ISetCustomerIsDisabled extends IAuthAction {
  isDisabled: any;
}
export interface ISetCustomerProductCode extends IAuthAction {
  CustomerProductCode: any;
}
export interface ISetCustomerProductType extends IAuthAction {
  CustomerProductType: any;
}
export interface ISetCustomerProductTypeOther extends IAuthAction {
  CustomerProductTypeOther: any;
}
export interface ISetCustomerProductLogo extends IAuthAction {
  CustomerProductLogo: any;
}
export interface ISetCustomerProductBonus extends IAuthAction {
  CustomerProductBonus: any;
}
export interface ISetCustomerProductDistrict1 extends IAuthAction {
  District1: any;
}
export interface ISetCustomerProductDistrict2 extends IAuthAction {
  District2: any;
}
export interface ISetCustomerProductDistrict3 extends IAuthAction {
  District3: any;
}
export interface ISetCustomerProductDistrict4 extends IAuthAction {
  District4: any;
}
export interface ISetCustomerProductDistrict5 extends IAuthAction {
  District5: any;
}
export interface ISetCustomerProductDistrict6 extends IAuthAction {
  District6: any;
}
export interface ISetCustomerProductDistrict7 extends IAuthAction {
  District7: any;
}
export interface ISetCustomerProductDistrict8 extends IAuthAction {
  District8: any;
}
export interface ISetCustomerProductDistrict9 extends IAuthAction {
  District9: any;
}
export interface ISetCustomerProductDistrict10 extends IAuthAction {
  District10: any;
}

export interface ISetCustomerProductDistrict11 extends IAuthAction {
  District11: any;
}
export interface ISetCustomerProductDistrict12 extends IAuthAction {
  District12: any;
}
export interface ISetListCustomerProductBonus extends IAuthAction {
  ListCustomerProductBonus: any;
}
export interface ISetListCustomerProduct extends IAuthAction {
  ListCustomerProduct: any;
}
export interface ISetListGroupName extends IAuthAction {
  ListGroupName: any;
}
export interface ISetTypeLoadingCustomerProduct extends IAuthAction {
  TypeLoadingCustomerProduct: any;
}
export default function AuthReducerDetailId(
  state = initialState,
  action: IAuthAction
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'SET_ID_CUSTOMER_PRODUCT':
      const setIdCustomerProduct = action as ISetIdCustomerProduct;
      newState.PropertiesCustomerProduct.idCustomerProduct =
        setIdCustomerProduct.idCustomerProduct;

      return { ...newState };

    case 'SET_CUSTOMER_PRODUCT_NAME':
      const setCustomerProductName = action as ISetCustomerProductName;
      newState.DetailCustomerProduct.CustomerProductName =
        setCustomerProductName.CustomerProductName;

      return { ...newState };

    case 'SET_CUSTOMER_IS_DISABLED':
      const setCustomerIsDisabled = action as ISetCustomerIsDisabled;
      newState.DetailCustomerProduct.isDisabled =
        setCustomerIsDisabled.isDisabled;

      return { ...newState };

    case 'SET_GROUP_PRODUCT_ID':
      const setGroupProductId = action as ISetGroupProductId;
      newState.DetailCustomerProduct.GroupProductId =
        setGroupProductId.GroupProductId;

      return { ...newState };
    case 'SET_GROUP_NAME':
      const setGroupName = action as ISetGroupName;
      newState.DetailCustomerProduct.GroupName = setGroupName.GroupName;

      return { ...newState };
    case 'SET_GROUP_NAME_PRODUCT':
      const changeGroupName = action as ISetGroupNameProduct;
      newState.DetailCustomerProduct.GroupNameProduct =
        changeGroupName.GroupNameProduct;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_CODE':
      const setCustomerProductCode = action as ISetCustomerProductCode;
      newState.DetailCustomerProduct.CustomerProductCode =
        setCustomerProductCode.CustomerProductCode;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_TYPE':
      const setCustomerProductType = action as ISetCustomerProductType;
      newState.DetailCustomerProduct.CustomerProductType =
        setCustomerProductType.CustomerProductType;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_TYPE_OUTHER':
      const setCustomerProductTypeOther =
        action as ISetCustomerProductTypeOther;
      newState.DetailCustomerProduct.CustomerProductTypeOther =
        setCustomerProductTypeOther.CustomerProductTypeOther;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_LOGO':
      const setCustomerProductLogo = action as ISetCustomerProductLogo;
      newState.DetailCustomerProduct.CustomerProductLogo =
        setCustomerProductLogo.CustomerProductLogo;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_BONUS':
      const setCustomerProductBonus = action as ISetCustomerProductBonus;
      newState.DetailCustomerProduct.CustomerProductBonus =
        setCustomerProductBonus.CustomerProductBonus;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_DISTRICT_1':
      const setCustomerProductDistrict1 =
        action as ISetCustomerProductDistrict1;
      newState.DetailCustomerProduct.District1 =
        setCustomerProductDistrict1.District1;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_DISTRICT_2':
      const setCustomerProductDistrict2 =
        action as ISetCustomerProductDistrict2;
      newState.DetailCustomerProduct.District2 =
        setCustomerProductDistrict2.District2;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_DISTRICT_3':
      const setCustomerProductDistrict3 =
        action as ISetCustomerProductDistrict3;
      newState.DetailCustomerProduct.District3 =
        setCustomerProductDistrict3.District3;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_DISTRICT_4':
      const setCustomerProductDistrict4 =
        action as ISetCustomerProductDistrict4;
      newState.DetailCustomerProduct.District4 =
        setCustomerProductDistrict4.District4;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_DISTRICT_5':
      const setCustomerProductDistrict5 =
        action as ISetCustomerProductDistrict5;
      newState.DetailCustomerProduct.District5 =
        setCustomerProductDistrict5.District5;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_DISTRICT_6':
      const setCustomerProductDistrict6 =
        action as ISetCustomerProductDistrict6;
      newState.DetailCustomerProduct.District6 =
        setCustomerProductDistrict6.District6;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_DISTRICT_7':
      const setCustomerProductDistrict7 =
        action as ISetCustomerProductDistrict7;
      newState.DetailCustomerProduct.District7 =
        setCustomerProductDistrict7.District7;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_DISTRICT_8':
      const setCustomerProductDistrict8 =
        action as ISetCustomerProductDistrict8;
      newState.DetailCustomerProduct.District8 =
        setCustomerProductDistrict8.District8;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_DISTRICT_9':
      const setCustomerProductDistrict9 =
        action as ISetCustomerProductDistrict9;
      newState.DetailCustomerProduct.District9 =
        setCustomerProductDistrict9.District9;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_DISTRICT_10':
      const setCustomerProductDistrict10 =
        action as ISetCustomerProductDistrict10;
      newState.DetailCustomerProduct.District10 =
        setCustomerProductDistrict10.District10;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_DISTRICT_11':
      const setCustomerProductDistrict11 =
        action as ISetCustomerProductDistrict11;
      newState.DetailCustomerProduct.District11 =
        setCustomerProductDistrict11.District11;

      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_DISTRICT_12':
      const setCustomerProductDistrict12 =
        action as ISetCustomerProductDistrict12;
      newState.DetailCustomerProduct.District12 =
        setCustomerProductDistrict12.District12;

      return { ...newState };
    case 'SET_LIST_CUSTOMER_PRODUCT_BONUS':
      const setListCustomerProductBonus =
        action as ISetListCustomerProductBonus;
      newState.DetailCustomerProduct.ListCustomerProductBonus =
        setListCustomerProductBonus.ListCustomerProductBonus;

      return { ...newState };
    case 'SET_LIST_CUSTOMER_PRODUCT':
      const setListCustomerProduct = action as ISetListCustomerProduct;
      newState.ListCustomerProduct = setListCustomerProduct.ListCustomerProduct;

      return { ...newState };
    case 'SET_LIST_GROUP_NAME':
      const setListGroupName = action as ISetListGroupName;
      newState.ListGroupName = setListGroupName.ListGroupName;

      return { ...newState };
    case 'SET_LOADING_CUSTOMER_PRODUCT':
      newState.PropertiesCustomerProduct.LoadingCustomerProduct =
        !newState.PropertiesCustomerProduct.LoadingCustomerProduct;

      return { ...newState };
    case 'SET_TYPE_LOADING_CUSTOMER_PRODUCT':
      const setTypeLoadingCustomerProduct =
        action as ISetTypeLoadingCustomerProduct;
      newState.PropertiesCustomerProduct.TypeLoadingCustomerProduct =
        setTypeLoadingCustomerProduct.TypeLoadingCustomerProduct;

      return { ...newState };

    case 'CLEAR_LOADING_CUSTOMER_PRODUCT':
      newState.PropertiesCustomerProduct.LoadingCustomerProduct = false;

      return { ...newState };

    case 'CLEAR_TYPE_LOADING_CUSTOMER_PRODUCT':
      newState.PropertiesCustomerProduct.TypeLoadingCustomerProduct = null;

      return { ...newState };
    case 'CLEAR_GROUP_PRODUCT_ID':
      newState.DetailCustomerProduct.GroupProductId = null;

      return { ...newState };

    case 'CHANGE_CLEAR_FIELD_CUSTOMER_PRODUCT':
      newState.DetailCustomerProduct.GroupProductId = null;
      newState.DetailCustomerProduct.GroupName = null;
      newState.DetailCustomerProduct.GroupNameProduct = null;
      newState.DetailCustomerProduct.CustomerProductName = null;
      newState.DetailCustomerProduct.isDisabled = true;
      newState.DetailCustomerProduct.CustomerProductCode = null;
      newState.DetailCustomerProduct.CustomerProductType = null;
      newState.DetailCustomerProduct.CustomerProductTypeOther = null;
      newState.DetailCustomerProduct.CustomerProductLogo = null;
      newState.DetailCustomerProduct.CustomerProductBonus = null;
      newState.DetailCustomerProduct.District1 = null;
      newState.DetailCustomerProduct.District2 = null;
      newState.DetailCustomerProduct.District3 = null;
      newState.DetailCustomerProduct.District4 = null;
      newState.DetailCustomerProduct.District5 = null;
      newState.DetailCustomerProduct.District6 = null;
      newState.DetailCustomerProduct.District7 = null;
      newState.DetailCustomerProduct.District8 = null;
      newState.DetailCustomerProduct.District9 = null;
      newState.DetailCustomerProduct.District10 = null;
      newState.DetailCustomerProduct.District11 = null;
      newState.DetailCustomerProduct.District12 = null;
      newState.DetailCustomerProduct.ListCustomerProductBonus = [];

      return { ...newState };
    case 'CLEAR_DETAIL_CUSTOMER_PRODUCT':
      newState.PropertiesCustomerProduct.idCustomerProduct = null;

      return { ...newState };
    case 'CLEAR_CUSTOMER_PRODUCT_TYPE_OTHER':
      newState.DetailCustomerProduct.CustomerProductTypeOther = null;

      return { ...newState };

    case 'CHANGE_CLEAR_CUSTOMER_PRODUCT_BONUS':
      newState.DetailCustomerProduct.CustomerProductBonus = null;

      return { ...newState };

    case 'CHANGE_CLEAR_LIST_CUSTOMER_PRODUCT_BONUS':
      newState.DetailCustomerProduct.ListCustomerProductBonus = [];

      return { ...newState };
    case 'CLEAR_LIST_CUSTOMER_PRODUCT':
      newState.ListCustomerProduct = [];

      return { ...newState };
    case 'CLEAR_LIST_GROUP_NAME':
      newState.ListGroupName = [];

      return { ...newState };
    case 'CLEAR_GROUP_NAME':
      newState.DetailCustomerProduct.GroupName = null;

      return { ...newState };

    case 'CHANGE_CLEAR_CUSTOMER_PRODUCT':
      newState.PropertiesCustomerProduct.idCustomerProduct = null;
      newState.DetailCustomerProduct.GroupProductId = null;
      newState.DetailCustomerProduct.GroupName = null;
      newState.DetailCustomerProduct.GroupNameProduct = null;
      newState.DetailCustomerProduct.CustomerProductCode = null;
      newState.DetailCustomerProduct.CustomerProductType = null;
      newState.DetailCustomerProduct.CustomerProductTypeOther = null;
      newState.DetailCustomerProduct.CustomerProductLogo = null;
      newState.DetailCustomerProduct.CustomerProductBonus = null;
      newState.DetailCustomerProduct.District1 = null;
      newState.DetailCustomerProduct.District2 = null;
      newState.DetailCustomerProduct.District3 = null;
      newState.DetailCustomerProduct.District4 = null;
      newState.DetailCustomerProduct.District5 = null;
      newState.DetailCustomerProduct.District6 = null;
      newState.DetailCustomerProduct.District7 = null;
      newState.DetailCustomerProduct.District8 = null;
      newState.DetailCustomerProduct.District9 = null;
      newState.DetailCustomerProduct.District10 = null;
      newState.DetailCustomerProduct.District11 = null;
      newState.DetailCustomerProduct.District12 = null;
      newState.DetailCustomerProduct.ListCustomerProductBonus = [];
      newState.ListCustomerProduct = [];

      return { ...newState };
  }
  return state;
}
