import React from 'react';
import { Icon } from 'antd';
import styles from './CountResult.module.css';
import Button from 'components/Button';

type CountResultProps = {
  onClickDownload?: () => void;
  onClickOke?: () => void;
  success?: number;
  error?: number;
  disabledButtonDownload?: boolean;
};

export default function CountResult({
  onClickDownload = () => undefined,
  onClickOke = () => undefined,
  success = 0,
  error = 0,
  disabledButtonDownload = false,
}: CountResultProps) {
  const WrapperCount = ({
    status,
    count = 0,
  }: {
    status: 'success' | 'error';
    count?: number;
  }) => {
    return (
      <div
        style={{ background: status === 'success' ? '#eaf7ee' : '#fcede9' }}
        className={styles.sectionCount}
      >
        <div
          style={{ background: status === 'success' ? '#00ab44' : '#EA4E2C' }}
          className={styles.wrapperIcon}
        >
          {status === 'success' ? (
            <Icon
              type="check-circle"
              theme="filled"
              className={styles.iconCount}
            />
          ) : (
            <Icon
              type="close-circle"
              theme="filled"
              className={styles.iconCount}
            />
          )}
        </div>
        <p
          style={{ color: status === 'success' ? '#00ab44' : '#EA4E2C' }}
          className={styles.textCount}
        >
          {status === 'success' ? 'Berhasil' : 'Gagal'}: {count}
        </p>
      </div>
    );
  };

  return (
    <div className={styles.wrapperCount}>
      <div className={styles.wrapperCountContent}>
        <WrapperCount status="success" count={success} />
        <WrapperCount status="error" count={error} />
      </div>
      <div className={styles.wrapperAction}>
        <Button
          text="Download"
          variant="info"
          onClick={onClickDownload}
          disabled={disabledButtonDownload}
        />
        <Button text="OK" variant="primary" onClick={onClickOke} />
      </div>
    </div>
  );
}
