export function setSwitchFormHoliday()
{
    return({
        type:"SET_SWITCH_FORM_HOLIDAY"
    })
}
export function setIdHoliday(IdHoliday:any)
{
    return({
        type:"SET_ID_HOLIDAY",
        IdHoliday
    })
}
export function setSynchronizeWeekly()
{
    return({
        type:"SET_SYNCHRONIZE_WEEKLY"
    })
}