import 'styles/FormInputStyle/index.css';

import { Button, Card, Col, DatePicker, List, Row, Skeleton } from 'antd';

import ButtonMaterial from 'materials/ButtonMaterial';
import CardMaterial from 'materials/CardMaterial';
import MapsContainer from 'module/Map/Container/MapContainer';
import React from 'react';
import SearchMatrial from 'materials/SearchMatrial';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';

interface IProps {
  listProgressCourier: any;
  StartDate: any;
  isloading: any;
  toggleType: any;
  ValueSearch: any;
  searchProgressCourier: any;
  nameSearch: any;
  handlePutStartDate: any;
  handleSearch: any;
  handleResetSearch: any;
  handleGetDataOnSearch: any;
  handleDetailCourier: any;
}
export default function ProgressCourierComponent({
  ValueSearch,
  handleSearch,
  StartDate,
  handlePutStartDate,
  handleGetDataOnSearch,
  listProgressCourier,
  isloading,
  toggleType,
  handleDetailCourier,
}: IProps) {
  const listData: any = [];
  for (let i = 0; i < 6; i++) {
    listData.push({ name: '', code: '', onProccess: '', total: '' });
  }
  return (
    <div
      style={{
        paddingRight: '35px',
        paddingLeft: '35px',
        paddingTop: '35px',
      }}
    >
      <div>
        <CardMaterial
          extra={''}
          style={{ borderRadius: '10px' }}
          title={''}
          content={
            <div>
              <Row>
                <Col span={12}>
                  <div>
                    <Col
                      span={6}
                      style={{ color: '#11BEFF', paddingTop: '6px' }}
                    >
                      <span>
                        <b>SEMUA KURIR</b>
                      </span>
                    </Col>
                    <Col span={18}>
                      <SearchMatrial
                        value={ValueSearch}
                        style={{ borderRadius: '10px', width: '80%' }}
                        placeholder={'Cari nama atau kode kurir'}
                        onChange={handleSearch}
                      />
                    </Col>
                  </div>
                </Col>
                <Col span={12}>
                  <div style={{ float: 'right' }}>
                    <Col span={20} style={{ paddingRight: '20px' }}>
                      <DatePicker
                        style={{
                          width: '100%',
                          backgroundColor: '#F5F5F5',
                          fontWeight: 'bold',
                        }}
                        locale={locale}
                        format={'YYYY-MM-DD'}
                        placeholder={'Pilih Tanggal'}
                        // onOpenChange={handleValidateStartDate}
                        value={StartDate}
                        onChange={handlePutStartDate}
                        disabledDate={(current: any) => {
                          return (
                            moment().add(0, 'days') <= current ||
                            moment().add(-32, 'days') >= current
                          );
                        }}
                      />
                    </Col>
                    <Col span={4}>
                      <Button
                        disabled={StartDate === null ? true : false}
                        onClick={handleGetDataOnSearch}
                        type="primary"
                        shape="circle"
                        icon="search"
                      />
                    </Col>
                  </div>
                </Col>
              </Row>
            </div>
          }
        />
      </div>
      <div style={{ paddingTop: '25px' }}>
        {isloading === true && toggleType !== 'locationCourier' ? (
          <List
            grid={{ gutter: 15, column: 3 }}
            dataSource={listData}
            renderItem={item => (
              <List.Item>
                <Card
                  style={{
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                  }}
                >
                  <Skeleton loading={true} active />
                </Card>
              </List.Item>
            )}
          />
        ) : (
          <List
            grid={{ gutter: 15, column: 3 }}
            pagination={{
              onChange: page => {},
              pageSize: 6,
            }}
            dataSource={listProgressCourier
              .sort((a: any, b: any) => b.total - a.total)
              .filter(
                (state: any) =>
                  state.name.toString().toLowerCase().includes(ValueSearch) ||
                  state.code.toString().toLowerCase().includes(ValueSearch)
              )}
            renderItem={(item: any) => (
              <List.Item>
                <CardMaterial
                  extra={''}
                  title={''}
                  style={{
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                    height: '200px',
                  }}
                  content={
                    <div>
                      <div
                        style={{
                          fontWeight: 'bold',
                          color: '#11BEFF',
                          fontSize: '20px',
                          textAlign: 'center',
                        }}
                      >
                        {item.code}
                      </div>
                      <div
                        style={{
                          fontSize: '16px',
                          textAlign: 'center',
                          marginBottom: 10,
                        }}
                      >
                        {item.name}
                      </div>
                      <div>
                        <div
                          style={{
                            fontSize: '30px',
                            textAlign: 'center',
                            background: '#f5f5f5',
                          }}
                        >
                          <span style={{ color: 'black' }}>
                            {item.onProccess}
                          </span>
                          <span>/</span>
                          <span style={{ color: 'black' }}>
                            <b>{item.total}</b>
                          </span>
                        </div>
                        <div
                          style={{
                            fontSize: '16px',
                            textAlign: 'center',
                            paddingTop: '10px',
                          }}
                        >
                          <div>
                            Diterima{' '}
                            <b style={{ color: '#31E116' }}>{item.success}</b>
                          </div>
                          <div>
                            Dikembalikan{' '}
                            <b style={{ color: 'red' }}>{item.return}</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
                <div>
                  <ButtonMaterial
                    disabled={''}
                    handleSubmit={() => handleDetailCourier(item.courierId)}
                    teksButton={'Lihat Detail'}
                    size={''}
                    shape={''}
                    className={''}
                    type={'primary'}
                    icon={''}
                    style={{ width: '100%' }}
                  />
                </div>
              </List.Item>
            )}
          ></List>
        )}
      </div>
      <div style={{ paddingTop: '35px' }}>
        <MapsContainer />
      </div>
    </div>
  );
}
