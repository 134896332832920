import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import history from 'core/utils/History';
import { getMenusLevelPermission } from 'services/LevelAccessServices';
import { postRole } from 'services/RoleServices';
import AddRoleFormComponent from '../Component/AddRoleFormComponent';
import ModalStatus from 'materials/ModalStatus';
import { reduceRoleFormat } from 'lib/util';

export default function AddRoleFormContainer() {
  const defaultDataLevel = {
    levelAkses: '',
    roleName: '',
    accountType: '',
    isActive: true,
  };

  const [dataLevel, setDataLevel] = useState<any>(defaultDataLevel);
  const [dataMenus, setDataAllMenus] = useState<Array<any>>([]);
  const [selectedLevel, setSelectedLevel] = useState<any>(null);

  const { data: dataAllMenus, isFetching: isLoadingMenus } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataAllMenus', { id: selectedLevel }],
    queryFn: ({ queryKey }) => getMenusLevelPermission(queryKey),
    enabled: !!selectedLevel,
  });

  const { mutate: submitLevelGroup, isLoading } = useMutation(postRole, {
    onSuccess: res => {
      if (res.status === 200) {
        ModalStatus({
          status: 'success',
          title: 'Data berhasil disimpan',
        });
        setDataLevel(defaultDataLevel);
        history.push('/role');
      } else {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan!!!',
          content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
        });
      }
    },
    onError: () => {
      ModalStatus({
        status: 'error',
        title: 'Internal Server Error',
        content: 'Silahkan Hubungi Admin',
      });
    },
  });

  useEffect(() => {
    if (dataAllMenus?.data && dataAllMenus.data.length > 0) {
      setDataAllMenus(dataAllMenus.data);
    }
  }, [dataAllMenus]);

  const handleBtnBack = () => {
    history.push('/role');
  };

  const handleSubmit = value => {
    const { levelAkses, roleName, isActive, accountType } = value;

    if (dataMenus.filter(value => value.permission.view === true).length <= 0) {
      ModalStatus({
        status: 'error',
        title: 'Terjadi Kesalahan!!!',
        content: 'Wajib pilih salah satu hak akses',
      });
      return;
    }

    const menus = reduceRoleFormat(dataMenus);

    const data = {
      levelId: levelAkses,
      accountType,
      roleName,
      isActive,
      menus,
    };

    submitLevelGroup(data);
  };

  const handleChangeForm = (name: string, value: any) => {
    setDataLevel(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <AddRoleFormComponent
      handleBtnBack={handleBtnBack}
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoading}
      isLoadingMenus={isLoadingMenus}
      dataAllMenus={dataMenus || []}
      dataLevel={dataLevel}
      handleSetDataMenu={value => setDataAllMenus(value)}
      handleSelectedlevel={value => setSelectedLevel(value)}
      handleChangeForm={handleChangeForm}
    />
  );
}
