import * as ActionCourierBonus from 'core/action/Report/CourierBonusReport/ActionCourierBonusReport';
import * as Branch from 'core/action/Branch/ActionAllBranch';
import * as CourierTable from 'core/action/ManageAccount/Courier/ActionTableCourier';
import * as LastAccess from 'core/action/LastAccess/index';

import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import CourierBonusReport from 'module/Report/Component/CourierBonusReport/CourierBonusReportComponent';
import { connect } from 'react-redux';
import moment from 'moment';

interface IProps {
  roleAccount: any;
  branchAuth: any;
  GetRadiobutton: any;
  GetFirstDate: any;
  GetLastDate: any;
  GetBranch: any;
  GetCourier: any;
  BranchList: any;
  ListCourier: any;
  actionCourierBonus: any;
  actionBranch: any;
  actionCourierTable: any;
  LoadingSelectBranch: any;
  LoadingSelectCourier: any;
  TypeLoadingSelectBranch: any;
  TypeLoadingSelectCourier: any;
  isLoading: any;
  Fiture: any;
}

function CourierBonusReportContainer({
  roleAccount,
  branchAuth,
  GetRadiobutton,
  GetFirstDate,
  GetLastDate,
  GetBranch,
  GetCourier,
  BranchList,
  ListCourier,
  actionCourierBonus,
  actionBranch,
  actionCourierTable,
  LoadingSelectBranch,
  LoadingSelectCourier,
  TypeLoadingSelectBranch,
  TypeLoadingSelectCourier,
  isLoading,
  Fiture,
}: IProps) {
  const [ValueFirstDate, setValueFirstDate] = useState(null);
  const [ValidateFirstDate, setValidateFirstDate]: any = useState(null);
  const [ValidateFirstDate2, setValidateFirstDate2] = useState(null);
  const [ValueLastDate, setValueLastDate] = useState(null);
  const [ValidateLastDate, setValidateLastDate]: any = useState(null);
  const [ValidateLastDate2, setValidateLastDate2] = useState(null);
  const [ValidateBranch, setValidateBranch]: any = useState(null);
  const [ValidateCourier, setValidateCourier]: any = useState(null);

  useEffect(() => {
    if (roleAccount === 'Branch') {
      actionBranch.getAllBranch();
      actionCourierBonus.changeBranchCBForm(branchAuth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(ValidateBranch, ValidateCourier);

  function handleDownload() {
    actionCourierBonus.downloadReportCourierBonus();
  }
  function handleChangeRadiobutton(event: any) {
    actionCourierBonus.changeRadioButtonCBForm(event.target.value);
    if (roleAccount === 'Branch') {
      actionCourierBonus.clearCourierBonusBranchFormExceptRadiobutton();
    } else {
      actionCourierBonus.clearCourierBonusFormExceptRadiobutton();
    }
    setValueFirstDate(null);
    setValidateFirstDate(null);
    setValidateFirstDate2(null);
    setValueLastDate(null);
    setValidateLastDate2(null);
    setValidateBranch(null);
    setValidateCourier(null);
  }
  function handleChangeFirstDate(event: any) {
    setValueFirstDate(event);

    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      const dateValueConvert = moment(dateValue).format('YYYYMMDD');
      const lastDateValueConvert = moment(GetLastDate).format('YYYYMMDD');
      actionCourierBonus.changeFirstDateCBForm(dateValue);
      if (dateValueConvert > lastDateValueConvert) {
        setValueLastDate(event);
        actionCourierBonus.changeLastDateCBForm(dateValue);
      } else if (dateValueConvert === lastDateValueConvert) {
        setValueLastDate(event);
      } else {
        setValueLastDate(null);
        actionCourierBonus.changeLastDateCBForm(null);
      }
      setValidateFirstDate(null);
    } else {
      setValidateFirstDate('error');
      setValidateLastDate(null);
      setValueLastDate(null);
      actionCourierBonus.changeLastDateCBForm(null);
      actionCourierBonus.changeFirstDateCBForm(null);
    }
  }
  function handleValidateFirstDate(event: any) {
    setValidateFirstDate2(event);

    if (ValidateFirstDate2 === true) {
      if (
        GetFirstDate === null ||
        GetFirstDate === undefined ||
        GetFirstDate === 'Invalid date'
      ) {
        setValidateFirstDate('error');
      } else {
        setValidateFirstDate('');
      }
    }
  }
  function handleChangeLastDate(event: any) {
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      actionCourierBonus.changeLastDateCBForm(dateValue);
    } else {
      actionCourierBonus.changeLastDateCBForm(null);
    }
    setValueLastDate(event);

    if (event === '') {
      setValidateLastDate('errror');
    } else {
      setValidateLastDate('');
    }
  }

  function handleValidateLastDate(event: any) {
    setValidateLastDate2(event);

    if (ValidateLastDate2 === true) {
      if (
        GetLastDate === null ||
        GetLastDate === undefined ||
        GetLastDate === 'Invalid date'
      ) {
        setValidateLastDate('error');
      } else {
        setValidateLastDate('');
      }
    } else {
    }
  }
  function handleGetBranch() {
    actionBranch.getAllBranch();
  }
  function handlePutBranch(event: any) {
    actionCourierBonus.changeBranchCBForm(event);
    if (event === null) {
      setValidateBranch('error');
    } else {
      setValidateBranch(null);
    }
  }
  function handleBlurBranch() {
    if (GetBranch === null) {
      setValidateBranch('error');
    } else {
      setValidateBranch(null);
    }
  }
  function handleResetCourier() {
    actionCourierBonus.clearCourierCBForm();
    actionCourierTable.clearCourierTable();
  }
  function handleGetCourier() {
    actionCourierTable.getCourierTableBranch();
  }
  function handleSelectCourier(event: any, label: any) {
    const searchSelectAll = event.toString().search('SetAllCourier');
    if (searchSelectAll >= 0) {
      actionCourierBonus.changeCourierCBForm(
        ListCourier.map((data: any, index: any) => data.id)
      );
    } else {
      actionCourierBonus.changeCourierCBForm(event);
    }

    if (GetCourier === null || GetCourier === [] || event.length === 0) {
      setValidateCourier('error');
    } else {
      setValidateCourier(null);
    }
  }
  function handleBlurSelectKurir() {
    if (
      GetCourier === null ||
      GetCourier.length === 0 ||
      GetCourier.length === undefined
    ) {
      setValidateCourier('error');
    } else {
      setValidateCourier(null);
    }
  }
  function handleReset() {
    if (roleAccount === 'Branch') {
      actionCourierBonus.clearCourierBonusBranchForm();
    } else {
      actionCourierBonus.clearCourierBonusForm();
    }
    setValueFirstDate(null);
    setValidateFirstDate(null);
    setValidateFirstDate2(null);
    setValueLastDate(null);
    setValidateLastDate(null);
    setValidateLastDate2(null);
    setValidateBranch(null);
    setValidateCourier(null);
  }

  return (
    <CourierBonusReport
      handleChangeRadiobutton={handleChangeRadiobutton}
      handleChangeFirstDate={handleChangeFirstDate}
      handleChangeLastDate={handleChangeLastDate}
      handleValidateFirstDate={handleValidateFirstDate}
      ValidateFirstDate={ValidateFirstDate === 'error' ? 'error' : ''}
      helpFirstDate={ValidateFirstDate === 'error' ? 'Tanggal wajib diisi' : ''}
      handleValidateLastDate={handleValidateLastDate}
      ValidateLastDate={ValidateLastDate === 'error' ? 'error' : ''}
      helpLastDate={ValidateLastDate === 'error' ? 'Tanggal wajib diisi' : ''}
      GetRadiobutton={GetRadiobutton === null ? 'Detail' : GetRadiobutton}
      ValueFirstDate={ValueFirstDate}
      GetFirstDate={GetFirstDate}
      ValueLastDate={ValueLastDate}
      GetLastDate={GetLastDate}
      BranchList={BranchList}
      handleGetBranch={handleGetBranch}
      handlePutBranch={handlePutBranch}
      handleBlurBranch={handleBlurBranch}
      ValueGetBranch={
        GetBranch === null ? (
          <span style={{ color: '#969696' }}>Pilih Posko</span>
        ) : (
          GetBranch
        )
      }
      handleResetCourier={handleResetCourier}
      handleGetCourier={handleGetCourier}
      handleSelectCourier={handleSelectCourier}
      handleBlurSelectKurir={handleBlurSelectKurir}
      ValueGetCourier={
        GetCourier === null ? (
          <span style={{ color: '#969696' }}>Pilih Kurir</span>
        ) : (
          GetCourier
        )
      }
      GetCourier={GetCourier}
      loadingSelectBranch={
        LoadingSelectBranch === true &&
        TypeLoadingSelectBranch === 'selectbranch'
          ? true
          : false
      }
      loadingSelectCourier={
        LoadingSelectCourier === true &&
        TypeLoadingSelectCourier === 'selectCourier'
          ? true
          : false
      }
      validateLastDate={GetFirstDate === null ? true : false}
      validateCourierForm={
        GetRadiobutton === 'Detail'
          ? GetBranch === null
            ? true
            : false
          : GetRadiobutton === 'Summary'
          ? true
          : false
      }
      validateDownloadButton={
        GetRadiobutton === 'Detail'
          ? GetFirstDate === null ||
            GetLastDate === null ||
            GetBranch === null ||
            GetCourier === null
            ? true
            : false
          : GetRadiobutton === 'Summary'
          ? GetFirstDate === null || GetLastDate === null || GetBranch === null
            ? true
            : false
          : ''
      }
      handleReset={handleReset}
      ListCourier={ListCourier}
      lastAccessFiture={Fiture}
      isLoading={isLoading}
      roleAccount={roleAccount}
      branchAuth={branchAuth}
      handleDownload={handleDownload}
    />
  );
}

const mapStateToProps = (state: any) => ({
  roleAccount: state.Auth.Role,
  branchAuth: state.Auth.BranchAuth,
  GetRadiobutton: state.Report.CourierBonusReportForm.RadioButton,
  GetFirstDate: state.Report.CourierBonusReportForm.FirstDate,
  GetLastDate: state.Report.CourierBonusReportForm.LastDate,
  GetBranch: state.Report.CourierBonusReportForm.Branch,
  GetCourier: state.Report.CourierBonusReportForm.Courier,

  ListCourier: state.Table.TableCourier,
  BranchList: state.Branch.AllBranch,
  Fiture: state.LastAccess.Fiture,
  isLoading: state.Toggle.isLoading,

  LoadingSelectBranch: state.Branch.PropertiesBranch.LoadingBranch,
  TypeLoadingSelectBranch: state.Branch.PropertiesBranch.TypeLoadingBranch,

  LoadingSelectCourier: state.Courier.PropertiesCourier.LoadingCourier,
  TypeLoadingSelectCourier: state.Courier.PropertiesCourier.TypeLoadingCourier,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionCourierBonus: bindActionCreators(ActionCourierBonus, dispatch),
  actionBranch: bindActionCreators(Branch, dispatch),
  actionCourierTable: bindActionCreators(CourierTable, dispatch),
  actionLastAccess: bindActionCreators(LastAccess, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CourierBonusReportContainer);
