import * as ActionPagination from '../../../core/action/Pagination/ActionPagination';
import * as ActionTableCustomer from '../../../core/action/Customer/ActionTableCustomer';
import * as ActionToggle from '../../../core/action/toggle/index';

import React, { useState, useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import CustomerComponent from '../../../components/Content/Customer/CustomerComponent';
import { connect } from 'react-redux';

interface IProps {
  data: any;
  isLoading: any;
  actionTableCustomer: any;
  actionToggle: any;
  actionPagination: any;
  ValueSearch: any;
  PagePagination: any;
  TotalPagePagination: any;
}

const CustomerContainer = ({
  data,
  isLoading,
  actionTableCustomer,
  actionToggle,
  actionPagination,
  ValueSearch,
  PagePagination,
  TotalPagePagination,
}: IProps) => {
  const [dataSource, setDataSource] = useState([]);
  const [nameSearch] = useState(false);

  const handlePage = (event: any) => {
    actionPagination.setPagePagination(event);
    actionTableCustomer.GetTableCustomer();
  };
  const handleSearch = (event: any) => {
    if (event.target.value.length === 0) {
      actionToggle.changeValueSearch(null);
      actionTableCustomer.GetTableCustomer();
    } else {
      actionToggle.changeValueSearch(event.target.value.replace(/^\s/, ''));
    }
  };
  const handleSubmitSearch = () => {
    if (ValueSearch === null || ValueSearch === '') {
    } else {
      actionPagination.setPagePagination(0);
      actionTableCustomer.SubmitSearchCustomer();
    }
  };
  const handleModal = () => {
    actionToggle.changeModal();
    actionToggle.changeJenisToggle('AddCustomer');
  };

  useEffect(() => {
    if (data.length > 0) {
      setDataSource(data);
    } else {
      actionTableCustomer.GetTableCustomer();
    }
  }, [data, actionTableCustomer]);

  return (
    <div>
      <CustomerComponent
        data={data}
        isLoading={isLoading}
        nameSearch={nameSearch}
        dataSource={dataSource}
        ValueSearch={ValueSearch}
        PagePagination={PagePagination}
        TotalPagePagination={TotalPagePagination}
        handlePage={handlePage}
        handleSearch={handleSearch}
        handleSubmitSearch={handleSubmitSearch}
        handleModal={handleModal}
      />
    </div>
  );
};
const mapStateProps = (state: any) => ({
  data: state.Table.TableCustomer,
  isLoading: state.Toggle.isLoading,
  ValueSearch: state.Toggle.ValueSearch,
  PagePagination: state.Pagination.PagePagination,
  TotalPagePagination: state.Pagination.TotalPagePagination,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionTableCustomer: bindActionCreators(ActionTableCustomer, dispatch),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionPagination: bindActionCreators(ActionPagination, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(CustomerContainer);
