import { Col, Row, Table } from 'antd';
import React, { Component } from 'react';

import ButtonGroupContainer from 'module/Branch/Container/ButtonGroupingBranchContainer';
import ButtonMaterial from 'materials/ButtonMaterial';
import FormInputTextMaterial from 'materials/FormInputTextMaterial';
import ModalMaterial from 'materials/ModalMaterial';
import SpinMaterial from 'materials/SpinMaterial';

interface IProps {
  visible: any;
  handleCloseModal: any;
  page: any;
  isLoading: any;
  getListGroupName: any;
  groupBranchId: any;
  groupName: any;
  stateValidate: any;
  helpValidateGroupName: any;
  handlePutGroupName: any;
  validateButton: any;
  handleCancel: any;
  handleSubmit: any;
}

export default class GroupBranchFormComponent extends Component<IProps> {
  render() {
    const lengthGroupName =
      this.props.groupName === null ? 0 : this.props.groupName.length;
    const validateBtnCancel = this.props.groupBranchId === null ? true : false;
    const validateForm = () => {
      let validate;
      if (this.props.groupBranchId === null) {
        this.props.groupName === '' ||
        this.props.stateValidate === 'notMinChar' ||
        this.props.stateValidate === 'error'
          ? (validate = 'error')
          : (validate = '');
        return validate;
      } else {
        this.props.groupName === '' || this.props.stateValidate === 'notMinChar'
          ? (validate = 'error')
          : (validate = '');
        return validate;
      }
    };
    const validateNotifForm = () => {
      let validate;
      if (this.props.groupBranchId === null) {
        validate = this.props.helpValidateGroupName;
        return validate;
      } else {
        if (this.props.groupName === '') {
          validate = 'Nama Wajib diisi';
        } else if (lengthGroupName < 3) {
          validate = 'Nama minimal 3 karakter';
        } else {
          validate = '';
        }
        return validate;
      }
    };
    const loopPage = (this.props.page - 1) * 10 + 1;
    const columns = [
      {
        title: (
          <div style={{ textAlign: 'center' }}>
            <b>No</b>
          </div>
        ),
        width: 60,
        render: (text: any, record: any, index: any) => (
          <span>{this.props.page > 1 ? index + loopPage : index + 1}</span>
        ),
        key: 'no',
      },
      {
        title: <b>Nama</b>,
        dataIndex: 'groupingName',
        key: 'groupingName',
        render: (groupingName: any) => (
          <span style={{ color: 'red' }}>{groupingName}</span>
        ),
        width: 200,
      },
      {
        title: <b>Aksi</b>,
        key: 'action',
        width: 90,
        render: (record: any) => (
          <div>
            <Row>
              <Col>
                <div className="btnEditGroup">
                  <ButtonGroupContainer
                    idGroupProduct={record.id}
                    groupingName={record.groupingName}
                  />
                </div>
              </Col>
            </Row>
          </div>
        ),
      },
    ];
    return (
      <React.Fragment>
        <ModalMaterial
          className={''}
          width={'40%'}
          style={{}}
          teksTitle={'TAMBAHKAN GRUP POSKO'}
          visible={this.props.visible}
          onCancel={this.props.handleCloseModal}
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={
                <div className="defaultPaddingModal">
                  <Table
                    pagination={false}
                    bordered={false}
                    dataSource={this.props.getListGroupName}
                    columns={columns}
                    className="scrollGroupingTbl"
                  />
                  <Row className="spacingTopDefault">
                    <span>
                      <b>Nama Grup</b>
                    </span>
                    <div>
                      <FormInputTextMaterial
                        validateType={true}
                        value={this.props.groupName}
                        className={'borderRadiusDefault'}
                        disabled={false}
                        addonBefore={''}
                        placeholder={'Masukan nama grup'}
                        prefix={''}
                        maxLength={'30'}
                        style={{}}
                        size=""
                        type={''}
                        onChange={this.props.handlePutGroupName}
                        onBlur={''}
                        validatestatus={validateForm()}
                        help={validateNotifForm()}
                        onPressEnter={''}
                      />
                    </div>
                    <div className="floatRight">
                      <ButtonMaterial
                        disabled={this.props.validateButton}
                        handleSubmit={this.props.handleSubmit}
                        teksButton={
                          this.props.groupBranchId === null ? 'Simpan' : 'Ubah'
                        }
                        size={''}
                        shape={'round'}
                        className={
                          this.props.validateButton === true
                            ? 'buttonDisableDefault'
                            : 'validateSubmitButtonEnable'
                        }
                        type={'primary'}
                        icon={''}
                        style={{}}
                      />
                    </div>

                    <div className="btnCancelGrouping">
                      <ButtonMaterial
                        disabled={validateBtnCancel}
                        handleSubmit={this.props.handleCancel}
                        teksButton={'Batal'}
                        size={''}
                        shape={'round'}
                        className={
                          validateBtnCancel === true
                            ? 'validateResetButtonDisabled'
                            : 'validateResetButtonEnable'
                        }
                        type={'primary'}
                        style={{}}
                        icon={''}
                      />
                    </div>
                  </Row>
                </div>
              }
            />
          }
        />
      </React.Fragment>
    );
  }
}
