import React, { useState } from 'react';
import RoleComponent from '../Component/RoleComponent';
import history from 'core/utils/History';
import { renderActionComponent } from 'apps/assets/components/CButton';
import { getParams } from 'lib/util';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { toInteger } from 'lodash';
import { getRoles } from 'services/RoleServices';
import { accountTypes } from 'lib/constants';

export default function RoleContainer() {
  const [page, setPage] = useState<number>(toInteger(getParams('page')) || 0);
  const [search, setSearch] = useState<string>(getParams('search') || '');
  const [searchGroup, setSearchGroup] = useState<string>(
    getParams('search') || ''
  );
  const [statusGroup, setStatusGroup] = useState<any>(
    getParams('statusKey')
      ? { label: getParams('statusLabel'), key: getParams('statusKey') }
      : null
  );

  const { data, isLoading, isFetching } = useQuery<AxiosResponse, Error>({
    queryKey: [
      'dataLevelGroup',
      { page, roleName: search, isActive: statusGroup?.key },
    ],
    queryFn: ({ queryKey }) => getRoles(queryKey),
  });

  const looppage = (page > 1 ? page : page) * 10 + 1;

  const renderAction = record => {
    const editClick = () => {
      history.push(`/role/editRole/${record.idRolePermission}`);
    };

    const handleDetail = () => {
      history.push(`/role/detailRole/${record.idRolePermission}`);
    };

    return renderActionComponent(
      false,
      record.id,
      {
        renderUpdate: true,
        renderInfo: true,
      },
      {
        handleUpdate: editClick,
        handleDetail: handleDetail,
      },
      {}
    );
  };

  const handlePageAddNewLevel = () => {
    history.push('/role/addRole');
  };

  const handlePage = (event: any) => {
    setPage(event !== 0 ? event - 1 : event);
    let link = `?page=${event !== 0 ? event - 1 : event}`;
    if (search) link = link.concat(`&search=${search}`);
    if (statusGroup)
      link = link.concat(
        `&statusLabel=${statusGroup.label}&statusKey=${statusGroup.key}`
      );
    history.push(link);
  };

  const handleFilterStatus = value => {
    setPage(0);
    setStatusGroup({ label: value.item.props.children, key: value.key });
    setSearch('');
    setSearchGroup('');
    history.push(
      `?page=${0}&statusLabel=${value.item.props.children}&statusKey=${
        value.key
      }`
    );
  };

  const handleFilterSearch = value => {
    setSearch(value);
    setPage(0);
    let link = `?page=${0}&search=${value}`;
    if (statusGroup)
      link = link.concat(
        `&statusLabel=${statusGroup.label}&statusKey=${statusGroup.key}`
      );
    history.push(link);
  };

  const columns = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <b>No</b>
        </div>
      ),
      width: 50,
      render: (text: any, record: any, index: any) => (
        <span>{index + looppage}</span>
      ),
      key: 'no',
    },
    {
      title: 'Tipe',
      dataIndex: 'accountType',
      key: 'accountType',
      width: 120,
      render: (text: any) =>
        accountTypes.find(value => value.id === text)?.name,
    },
    {
      title: 'Level Akses',
      dataIndex: 'levelName',
      key: 'levelName',
      width: 120,
    },
    {
      title: 'Role',
      dataIndex: 'roleName',
      key: 'roleName',
      width: 120,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (status: any) => (status === true ? 'Aktif' : 'Tidak aktif'),
      width: 120,
    },
    {
      title: 'Aksi',
      render: (record: any) => renderAction(record),
      width: 120,
    },
  ];

  return (
    <RoleComponent
      columns={columns}
      handlePageAddNewLevel={handlePageAddNewLevel}
      handlePage={handlePage}
      handleSearchLevel={handleFilterSearch}
      roles={data?.data?.data || []}
      pagePagination={data?.data?.page + 1 || 0}
      totalPagination={data?.data?.totalDatas || 0}
      isLoading={isLoading || isFetching}
      searchGroup={searchGroup}
      handleSearchGroup={value => setSearchGroup(value)}
      statusGroup={statusGroup}
      handleStatusGroup={handleFilterStatus}
    />
  );
}
