import { Col, Row } from 'antd';
import React from 'react';

import ButtonMaterial from '../../../materials/ButtonMaterial';
import CardMaterial from '../../../materials/CardMaterial';
import FormCustomerReportContainer from '../../../containers/Content/Customer/FormCustomerReportContainer';
import SpinMaterial from '../../../materials/SpinMaterial';
import TableDetailConfigReportContainer from '../../../containers/Content/Customer/TableDetailConfigReportContainer';
import { InitialValueFormCustomerFormat } from 'module/Customer/Report/components/FormCustomerFormatReport';
import FormCustomerFormatReport from 'module/Customer/Report/components/FormCustomerFormatReport';

interface IProps {
  GetCustomerName: any;
  GetCustomerNameReport: any;
  GetFormatReport: any;
  GetPeriodeReport: any;
  GetDelimitedReport: any;
  GetSettingReport: any;
  GetSettingReportName: any;
  GetWithoutHeader: any;
  GetShowInPortal: any;
  isLoading: any;
  handleEditConfigReport: any;
  handleBackConfigReport: any;
  customerFormatData: InitialValueFormCustomerFormat;
  dataCsvList: any[];
}
export default function CustomerDetailConfigReportComponent(props: IProps) {
  return (
    <span>
      <FormCustomerReportContainer />
      <SpinMaterial
        spinning={props.isLoading}
        style={{}}
        size={'large'}
        content={
          <CardMaterial
            extra={
              <Row>
                <Col span={12}>
                  <div style={{ float: 'right', paddingRight: '10px' }}>
                    <ButtonMaterial
                      disabled={''}
                      handleSubmit={props.handleEditConfigReport}
                      teksButton={<b>Ubah</b>}
                      size={'small'}
                      shape={''}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={{
                        colorFont: '#11BEFF',
                        height: '30px',
                        width: '70px',
                        borderRadius: '12px',
                        background: '#FCC642',
                        borderColor: '#FCC642',
                      }}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div style={{ float: 'right' }}>
                    <ButtonMaterial
                      disabled={''}
                      handleSubmit={props.handleBackConfigReport}
                      teksButton={<b>Kembali</b>}
                      size={'small'}
                      shape={''}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={{
                        colorFont: '#11BEFF',
                        height: '30px',
                        borderRadius: '12px',
                        background: '#F5487D',
                        borderColor: '#F5487D',
                      }}
                    />
                  </div>
                </Col>
              </Row>
            }
            title={
              <span style={{ color: '#11BEFF' }}>
                <b>{props.GetCustomerName}</b>
              </span>
            }
            style={{ borderRadius: '10px' }}
          >
            <FormCustomerFormatReport
              isView
              initialValue={props.customerFormatData}
              isNotModal
              dataCsvList={props.dataCsvList}
            />
          </CardMaterial>
        }
      />
      <div style={{ paddingTop: '25px' }}>
        <TableDetailConfigReportContainer />
      </div>
    </span>
  );
}
