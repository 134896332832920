import React, { Component } from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import history from '../../../core/utils/History';
import * as ActionNavigation from '../../../core/action/Router/ActionRouter'
import * as ActionDetailCustomer from '../../../core/action/Customer/ActionDetailCustomer';
import * as ActionToggle from '../../../core/action/toggle/index';
import CustomerDetailComponent from '../../../components/Content/Customer/CustomerDetailComponent';

interface IProps
{
    CustomerId:any
    CustomerName:any
    CustomerCode:any
    CustomerType:any
    CustomerTelephone:any
    CustomerEmail:any
    CustomerAddress:any
    CustomerAccess:any
    isLoading:any
    typeToggle:any
    idCustomer:any
    actionToggle:any
    actionCustomerDetail:any
    actionNavigation:any
}
class CustomerDetailContainer extends Component<IProps> {
    constructor(props:any)
    {
        super(props)
        
        this.handleEditDetailCustomer = this.handleEditDetailCustomer.bind(this)
        this.handleBackDetailCustomer = this.handleBackDetailCustomer.bind(this)
        this.handleCustomerConfig = this.handleCustomerConfig.bind(this)
    }
    handleBackDetailCustomer()
    {
        history.push("/customer")
    }
    handleEditDetailCustomer()
    {
        this.props.actionCustomerDetail.changeCustomerId(this.props.idCustomer)
        this.props.actionToggle.changeModal()
        this.props.actionToggle.changeJenisToggle("EditCustomer")
        this.props.actionCustomerDetail.changeDetailCustomer()

    }
    handleCustomerConfig()
    {
        this.props.actionCustomerDetail.changeCustomerId(this.props.idCustomer)
        this.props.actionCustomerDetail.changeDetailCustomer()
        history.push("/customer/config")
        this.props.actionNavigation.setRouter("/customer/config")
    }
    componentWillMount(){
        if(this.props.CustomerId==null){
            history.push('/customer')
        }else{
            this.props.actionCustomerDetail.changeDetailCustomer()
        }
    }
    render() {
        return (
            <CustomerDetailComponent
            CustomerName={this.props.CustomerName}
            CustomerCode={this.props.CustomerCode}
            CustomerType={this.props.CustomerType}
            CustomerTelephone={this.props.CustomerTelephone}
            CustomerEmail={this.props.CustomerEmail}
            CustomerAddress={this.props.CustomerAddress}
            CustomerAccess={this.props.CustomerAccess}
            isLoading={this.props.isLoading}
            typeToggle={this.props.typeToggle}
            handleBackDetail={this.handleBackDetailCustomer}
            handleEditDetail={this.handleEditDetailCustomer}
            handleCustomerConfig={this.handleCustomerConfig}
            />
        )
    }
}
const mapStateProps = (state:any) => ({
    CustomerId: state.Customer.PropertiesCustomer.IdCustomer,
    CustomerName:state.Customer.DetailCustomer.CustomerName,
    CustomerCode:state.Customer.DetailCustomer.CustomerCode,
    CustomerType:state.Customer.DetailCustomer.CustomerType,
    CustomerTelephone:state.Customer.DetailCustomer.CustomerTelephone,
    CustomerEmail:state.Customer.DetailCustomer.CustomerEmail,
    CustomerAddress:state.Customer.DetailCustomer.CustomerAddress,
    CustomerAccess:state.Customer.DetailCustomer.CustomerAccess,
    isLoading:state.Toggle.isLoading,
    typeToggle:state.Toggle.jenisToggle,
    idCustomer:state.Customer.PropertiesCustomer.IdCustomer
})

const mapDispatchToProps = (dispatch:any) => ({
    actionCustomerDetail : bindActionCreators(ActionDetailCustomer, dispatch),
    actionToggle : bindActionCreators(ActionToggle, dispatch),
    actionNavigation : bindActionCreators(ActionNavigation, dispatch),
})

const withConnect = connect(mapStateProps, mapDispatchToProps)

export default compose(
    withConnect
)(CustomerDetailContainer)
