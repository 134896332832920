import * as CustomerManifest from '../../../../core/action/Manifest/CustomerManifest/ActionCustomerManifest';
import * as toggle from '../../../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import FormCustomerManifestComponent from '../../../../components/Content/Manifest/CustomerManifest/FormCustomerManifestComponent';
import { connect } from 'react-redux';

interface IProps {
  actionCustomerManifest: any;
  typeToggle: any;
  visible: any;
  isLoading: any;
  TotalDocument: any;
  ReturnName: any;
  ReturnDate: any;
  actionToggle: any;
  Address: any;
  City: any;
  statusTreatmentDocument: any;
  handleStatusTreatmentDocument: any;
}
interface IState {
  ValidateReturnName: any;
  ValidateReturnDate: any;
  ValidateAddress: any;
  ValidateCity: any;
}
class FormCustomerManifestContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateReturnName: null,
      ValidateReturnDate: null,
      ValidateAddress: null,
      ValidateCity: null,
    };
    this.handlePutReturnName = this.handlePutReturnName.bind(this);
    this.handlePutReturnDate = this.handlePutReturnDate.bind(this);
    this.handlePutAddress = this.handlePutAddress.bind(this);
    this.handlePutCity = this.handlePutCity.bind(this);
    this.handleValidateReturnDate = this.handleValidateReturnDate.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  handlePutReturnName(event: any) {
    this.props.actionCustomerManifest.changeReturnNameCustomerManifestForm(
      event.target.value
    );
    const RegexMinChar = /(?=^.{3,}$)/;
    if (event.target.value === '') {
      this.setState({
        ValidateReturnName: 'error',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        ValidateReturnName: 'Length',
      });
    } else {
      this.setState({
        ValidateReturnName: '',
      });
    }
  }

  handleValidateReturnDate(event: any) {
    this.setState({
      ValidateReturnDate: event,
    });
    if (this.state.ValidateReturnDate === true) {
      if (
        this.props.ReturnDate === null ||
        this.props.ReturnDate === undefined ||
        this.props.ReturnDate === 'Invalid date'
      ) {
        this.setState({
          ValidateReturnDate: 'error',
        });
      } else {
        this.setState({
          ValidateReturnDate: null,
        });
      }
    } else {
    }
  }

  handlePutReturnDate(event: any) {
    this.props.actionCustomerManifest.changeReturnDateCustomerManifestForm(
      event
    );
    if (event === '' || event === null) {
      this.setState({
        ValidateReturnDate: 'error',
      });
    } else {
      this.setState({
        ValidateReturnDate: null,
      });
    }
  }
  handlePutAddress(event: any) {
    this.props.actionCustomerManifest.changeReturnAddressCustomerManifestForm(
      event.target.value
    );
    const RegexMinChar = /(?=^.{3,}$)/;
    if (!event.target.value) {
      this.setState({
        ValidateAddress: 'error',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        ValidateAddress: 'Length',
      });
    } else {
      this.setState({
        ValidateAddress: null,
      });
    }
  }
  handlePutCity(event: any) {
    this.props.actionCustomerManifest.changeReturnCityCustomerManifestForm(
      event.target.value
    );
    const RegexMinChar = /(?=^.{3,}$)/;
    if (!event.target.value) {
      this.setState({
        ValidateCity: 'error',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        ValidateCity: 'Length',
      });
    } else {
      this.setState({
        ValidateCity: null,
      });
    }
  }

  handleCloseModal() {
    this.props.actionCustomerManifest.clearCustomerManifestPopupForm();
    this.props.actionToggle.changeModal();
    this.props.actionToggle.clearJenisToggle();
    this.setState({
      ValidateReturnName: null,
      ValidateReturnDate: null,
      ValidateAddress: null,
      ValidateCity: null,
    });
    this.props.handleStatusTreatmentDocument(null);
  }

  handleDownload() {
    this.props.actionCustomerManifest.downloadCustomerManifestForm(
      this.props.statusTreatmentDocument
    );
  }

  render() {
    return (
      <FormCustomerManifestComponent
        typeToggle={this.props.typeToggle}
        visible={this.props.visible}
        isLoading={this.props.isLoading}
        totalDocument={this.props.TotalDocument}
        returnName={this.props.ReturnName}
        returnDate={this.props.ReturnDate}
        validateReturnName={
          this.state.ValidateReturnName === 'error'
            ? 'error'
            : '' || this.state.ValidateReturnName === 'Length'
            ? 'error'
            : ''
        }
        validateReturnDate={
          this.state.ValidateReturnDate === 'error' ? 'error' : ''
        }
        helpValidateReturnName={
          this.state.ValidateReturnName === 'error'
            ? 'Nama PIC wajib diisi'
            : '' || this.state.ValidateReturnName === 'Length'
            ? 'Minimal 3 karakter'
            : ''
        }
        helpValidateRetrunDate={
          this.state.ValidateReturnDate === 'error' ? 'Tanggal wajib diisi' : ''
        }
        handlePutReturnName={this.handlePutReturnName}
        handlePutReturnDate={this.handlePutReturnDate}
        handlePutAddress={this.handlePutAddress}
        helpValidateAddress={
          this.state.ValidateAddress === 'error'
            ? 'Alamat wajib diisi'
            : this.state.ValidateAddress === 'Length'
            ? 'Minimal 3 karakter'
            : ''
        }
        validateAddress={
          this.state.ValidateAddress === 'error' ||
          this.state.ValidateAddress === 'Length'
            ? 'error'
            : ''
        }
        handlePutCity={this.handlePutCity}
        helpValidateCity={
          this.state.ValidateCity === 'error'
            ? 'Kota wajib diisi'
            : this.state.ValidateCity === 'Length'
            ? 'Minimal 3 karakter'
            : ''
        }
        validateCity={
          this.state.ValidateCity === 'error' ||
          this.state.ValidateCity === 'Length'
            ? 'error'
            : ''
        }
        handleValidateReturnDate={this.handleValidateReturnDate}
        handleCloseModal={this.handleCloseModal}
        handleDownload={this.handleDownload}
        address={this.props.Address}
        city={this.props.City}
        statusTreatmentDocument={this.props.statusTreatmentDocument}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  typeToggle: state.Toggle.jenisToggle,
  isLoading: state.Toggle.isLoading,
  TotalDocument: state.Manifest.CustomerManifestForm.Count,
  ReturnName: state.Manifest.CustomerManifestForm.ReturnName,
  ReturnDate: state.Manifest.CustomerManifestForm.ReturnDate,
  Address: state.Manifest.CustomerManifestForm.Address,
  City: state.Manifest.CustomerManifestForm.City,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionCustomerManifest: bindActionCreators(CustomerManifest, dispatch),
  actionToggle: bindActionCreators(toggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FormCustomerManifestContainer);
