import * as _ from 'lodash';

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';

import Address from '../reducer/Address/index';
import AdminBranch from '../reducer/ManageAccount/AdminBranch/ReducerFormDetailAdmin';
import AssigningCourier from '../reducer/AssigningCourier/ReducerAssigningCourier';
import Auth from '../reducer/Auth/index';
import Beranda from '../reducer/Beranda/ReducerBeranda';
import Branch from '../reducer/Branch/ReducerDetailBranch';
import CallCenterState from '../../module/CallCenter/Store/CallCenterReducer';
import Complaint from '../reducer/Complaint/ReducerComplaint';
import ComplaintNew from '../../module/ComplaintNew/Store/ComplaintReducer';
import ComponentState from '../../module/App/Store/ComponentReducer';
import Courier from '../reducer/ManageAccount/Courier/ReducerFromDetailBranch';
import Customer from '../reducer/Customer/ReducerDetailCustomer';
import CustomerProduct from '../reducer/CustomerProduct/ReducerDetailCustomerProduct';
import DataDelivery from '../reducer/DataDelivery/ReducerDataDelivery';
import Delivery from '../reducer/Delivery/Document/ReducerDocument';
import DeliveryPriority from '../reducer/DeliveryPriority/ReducerDeliveryPriority';
import DeliveryStatus from '../reducer/DeliveryStatus/ReducerDeliveryStatus';
import GeneralDataListState from '../../module/App/Store/GeneralDataReducer';
import Holiday from '../reducer/Holiday/ReducerHoliday';
import LastAccess from '../reducer/LastAccess/index';
import LevelState from '../../module/ManageAccountNew/Level/Store/LevelReducer';
import LogActivityState from '../../module/LogActivity/Store/LogActivityReducer';
import LoginForm from '../reducer/Login/index';
import Manifest from '../reducer/Manifest/ReducerManifest';
import ManualProcess from '../reducer/ManualProcess/ManualProcess';
import Maps from '../reducer/Maps/ReducerTrackingMaps';
import Navigation from '../reducer/Router/ReducerRouter';
import Pagination from '../reducer/Pagination/ReducerPagination';
import PickUpDataState from '../../module/Pickup/PickupData/Store/PickupDataReducer';
import PickUpProcessState from '../../module/PickUpProcess/Store/PickUpProcessReducer';
import PickUpStatusState from '../../module/Pickup/PickupStatus/Store/PickupStatusReducer';
import PrintWaybill from '../reducer/Delivery/PrintWaybill/ReducerFormPrintWaybill';
import Profile from '../reducer/Profile/ReducerProfile';
import Report from '../reducer/Report/ReduceReport';
import ReportBast from '../reducer/Report/BastReport/ReducerBastReport';
import ReportComplaintState from '../../module/Report/ComplaintReport/Store/ComplaintReportReducer';
import Resend from '../reducer/Attempt/ReducerResend';
import ResendSingleData from '../reducer/Delivery/Resend/ReducerSingleData';
import ResetPassword from '../reducer/ResetPassword/ReducerResetPassword';
import RestoreData from '../reducer/RestoreData/ReducerRestoreData';
import Saga from '../saga/SagaMiddleware';
import ScanManifest from '../reducer/Manifest/ReducerScanManifest';
import Table from '../reducer/Table/index';
import Toggle from '../reducer/Toggle';
import UploadSoftcopy from '../reducer/Delivery/UploadSoftcopy/ReducerUploadSoftcopy';
import Voip from '../reducer/Voip/ReducerVoip';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { reducer as formReducer } from 'redux-form';
import history from '../utils/History';
import storage from 'redux-persist/lib/storage';

const sagaMiddleware = createSagaMiddleware();
const combinedReducers = combineReducers({
  LoginForm,
  Auth,
  Table,
  Address,
  Navigation,
  Toggle,
  Pagination,
  PickUpDataState,
  PickUpStatusState,
  PickUpProcessState,
  Beranda,
  Branch,
  Courier,
  AdminBranch,
  Customer,
  CustomerProduct,
  ComponentState,
  LastAccess,
  Delivery,
  DeliveryStatus,
  Resend,
  ResendSingleData,
  ManualProcess,
  Maps,
  Report,
  ReportBast,
  ReportComplaintState,
  Holiday,
  LogActivityState,
  LevelState,
  GeneralDataListState,
  DataDelivery,
  CallCenterState,
  Complaint,
  ComplaintNew,
  Profile,
  ResetPassword,
  PrintWaybill,
  ScanManifest,
  Manifest,
  AssigningCourier,
  DeliveryPriority,
  UploadSoftcopy,
  Voip,
  RestoreData,
  router: connectRouter(history),
  form: formReducer.plugin({
    complaintForm: (state, action) => {
      if (action.type === 'RESET_COMPLAINTNEW_FORM') {
        return undefined;
      } else {
        return state;
      }
    },
    detailFormComplaint: (state, action) => {
      if (action.type === 'RESET_COMPLAIN_DETAIL_FORM') {
        return undefined;
      } else {
        return state;
      }
    },
    callCenterForm: (state, action) => {
      if (action.type === 'CLEAR_FORM_CALL_CENTER') {
        return undefined;
      } else {
        return state;
      }
    },
    logActivityForm: (state, action) => {
      if (action.type === 'RESET_FORM_LOG_ACTIVITY') {
        return undefined;
      } else {
        return state;
      }
    },
    assignCourierForm: (state, action) => {
      if (action.type === 'RESET_PICKUP_FORM') {
        return undefined;
      } else {
        return state;
      }
    },
    complaintReportForm: (state, action) => {
      if (action.type === 'RESET_COMPLAINT_REPORT_FORM') {
        return undefined;
      } else {
        return state;
      }
    },
    pickupDataForm: (state, action) => {
      if (action.type === 'RESET_PICKUP_DATA_FORM') {
        return undefined;
      } else {
        return state;
      }
    },
  }),
});

const composeEnhacers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  _.has(window, '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__')
    ? composeWithDevTools({ trace: true, traceLimit: 1000 })
    : compose;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'Navigation',
    'LastAccess',
    'DetailCourier',
    'Auth',
    'Branch',
    'Customer',
    'PickUpProcessState',
  ],
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);
export const store = createStore(
  persistedReducer,
  composeEnhacers(applyMiddleware(sagaMiddleware))
);

export const persistor = persistStore(store);
sagaMiddleware.run(Saga);
