import React, { useState } from 'react';
import { Icon, Row, Col } from 'antd';
import cx from 'classnames';
import { Form, Field } from 'react-final-form';
import styles from './DistributionComponent.module.css';
import CardMaterial from 'materials/CardMaterial';
import InputDatePicker from 'components/InputDatePicker';
import SelectField from 'components/SelectField';
import {
  DataDeliveryDistributionType,
  DataScanDeliveryType,
} from '../../container';
import { typeDocument } from 'apps/enums';
import { SelectOptionsInterface } from 'interface/general';
import SpinMaterial from 'materials/SpinMaterial';

type DistributionComponentProps = {
  dataDeliveryDistribution: DataDeliveryDistributionType;
  dataCustomers: SelectOptionsInterface[];
  dataProducts: any[];
  dataScanDelivery: DataScanDeliveryType;
  isLoadingCustomers: boolean;
  isLoadingProducts: boolean;
  isLoadingScan: boolean;
  handleChangeDeliveryDistribution: (name: string, value: any) => void;
  setModalScan: (value: string) => void;
};

export default function DistributionComponent({
  dataDeliveryDistribution,
  dataCustomers,
  dataProducts,
  isLoadingProducts = false,
  isLoadingCustomers = false,
  isLoadingScan = false,
  handleChangeDeliveryDistribution,
  dataScanDelivery,
  setModalScan,
}: DistributionComponentProps) {
  const [showDistributionContent, setShowDistributionContent] =
    useState<boolean>(false);
  return (
    <SpinMaterial spinning={isLoadingScan} size="large">
      <CardMaterial
        title={
          <b
            style={{ color: '#11BEFF', cursor: 'pointer' }}
            onClick={() => setShowDistributionContent(!showDistributionContent)}
          >
            DISTRIBUSI PENGIRIMAN
          </b>
        }
        extra={
          <Icon
            type={showDistributionContent ? 'up' : 'down'}
            style={{ color: '#11BEFF', cursor: 'pointer' }}
            onClick={() => setShowDistributionContent(!showDistributionContent)}
          />
        }
        style={{ borderRadius: '10px' }}
      >
        {showDistributionContent && (
          <Form
            onSubmit={() => {}}
            initialValues={{ ...dataDeliveryDistribution }}
          >
            {({ handleSubmit, values }) => (
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Field name="cycleDate">
                    {({ input, meta }) => (
                      <InputDatePicker
                        label="Cycle Date"
                        placeholder="Pilih Tanggal Cycle"
                        onChange={value => {
                          handleChangeDeliveryDistribution('cycleDate', value);
                        }}
                        value={input.value}
                        error={
                          meta.touched && meta.error !== undefined && meta.error
                        }
                      />
                    )}
                  </Field>
                </Col>
                <Col span={12}>
                  <Field name="typeDocument">
                    {({ input, meta }) => (
                      <SelectField
                        label="Jenis Dokumen"
                        placeholder="Pilih Jenis Dokumen"
                        data={typeDocument}
                        value={input.value}
                        disabled={!values.customer}
                        onChange={value =>
                          handleChangeDeliveryDistribution(
                            'typeDocument',
                            value
                          )
                        }
                        onBlur={value => {
                          input.onBlur(value);
                        }}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={12}>
                  <Field name="customer">
                    {({ input, meta }) => (
                      <SelectField
                        label="Pelanggan"
                        placeholder="Pilih Pelanggan"
                        data={dataCustomers}
                        loading={isLoadingCustomers}
                        value={input.value}
                        disabled={!values.cycleDate}
                        onChange={value => {
                          handleChangeDeliveryDistribution('customer', value);
                        }}
                        onBlur={value => {
                          input.onBlur(value);
                        }}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={12}>
                  <Field name="product">
                    {({ input, meta }) => (
                      <SelectField
                        label="Produk"
                        placeholder="Pilih Produk"
                        data={dataProducts.filter(
                          product => product.productType === values.typeDocument
                        )}
                        loading={isLoadingProducts}
                        value={input.value}
                        disabled={!values.typeDocument}
                        onChange={value =>
                          handleChangeDeliveryDistribution('product', value)
                        }
                        onBlur={value => {
                          input.onBlur(value);
                        }}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                      />
                    )}
                  </Field>
                </Col>
                <Col span={24} style={{ display: 'flex', gap: 24 }}>
                  <div
                    className={cx(
                      !dataScanDelivery.scan &&
                        styles.wrapperActionLinkDisabled,
                      styles.wrapperActionLink
                    )}
                    onClick={() =>
                      !!dataScanDelivery.scan && setModalScan('scan')
                    }
                  >
                    <p>Sudah di Scan:</p>
                    <p>{dataScanDelivery.scan}</p>
                  </div>
                  <div
                    className={cx(
                      !dataScanDelivery.unScan &&
                        styles.wrapperActionLinkDisabled,
                      styles.wrapperActionLink
                    )}
                    onClick={() =>
                      !!dataScanDelivery.unScan && setModalScan('unscan')
                    }
                  >
                    <p>Belum di Scan:</p>
                    <p style={{ color: '#ff5858' }}>
                      {dataScanDelivery.unScan}
                    </p>
                  </div>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </CardMaterial>
    </SpinMaterial>
  );
}
