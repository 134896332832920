import React from 'react';
import { Row, Col, Select } from 'antd';
import { Form, Field } from 'react-final-form';
import CardMaterial from 'materials/CardMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import SelectField from 'components/SelectField';
import { deliveryTypes, paperTypes, districtCode } from 'lib/constants';
import InputTextField from 'components/InputTextField';
import { SingleCheckbox } from 'components/SingleCheckbox';

type AssignDocumentProps = {
  handleChange: (name: string, value: any) => void;
  handleAssignDocument: () => void;
  initialValues: any;
  isLoadingBranch: boolean;
  isLoadingCourier: boolean;
  isLoadingAssign: boolean;
  dataBranchs: any[];
  dataCourier: any[];
  successScan: { waybill: string; courierName: string };
};

export default function AssignDocument({
  handleChange = () => undefined,
  handleAssignDocument,
  initialValues,
  isLoadingBranch,
  isLoadingCourier,
  isLoadingAssign = false,
  dataBranchs,
  dataCourier,
  successScan,
}: AssignDocumentProps) {
  return (
    <SpinMaterial spinning={isLoadingAssign} size="large">
      <CardMaterial style={{ borderRadius: '10px' }}>
        <Form
          validate={values => {
            const errors = {} as any;
            if (!values.deliveryType) {
              errors.deliveryType = 'Jenis Pengiriman wajib diisi';
            }
            if (!values.branch) {
              errors.branch = 'Posko wajib diisi';
            }
            if (!values.courier) {
              errors.courier = 'Kurir wajib diisi';
            }
            if (!values.paperType) {
              errors.paperType = 'Jenis Amplop wajib diisi';
            }
            if (!values.district) {
              errors.district = 'Wilayah wajib diisi';
            }
            return errors;
          }}
          initialValues={{ ...initialValues }}
          onSubmit={() => {}}
        >
          {({ values, valid }) => (
            <Row gutter={[24, 24]}>
              <Col
                span={12}
                style={{ display: 'flex', flexDirection: 'column', gap: 24 }}
              >
                <Field name="deliveryType">
                  {({ input, meta }) => (
                    <SelectField
                      label="Jenis Pengiriman"
                      placeholder="Pilih Jenis Pengiriman"
                      data={deliveryTypes}
                      value={input.value}
                      onChange={value => {
                        handleChange('deliveryType', value);
                      }}
                      onBlur={input.onBlur}
                      validateStatus={
                        meta.error && meta.touched ? 'error' : 'validating'
                      }
                      validate
                      errorMessage={meta.error && meta.touched && meta.error}
                    />
                  )}
                </Field>
                <Field name="paperType">
                  {({ input, meta }) => (
                    <SelectField
                      label="Jenis Amplop"
                      placeholder="Pilih Jenis Amplop"
                      data={paperTypes}
                      value={input.value}
                      validate
                      onChange={value => {
                        handleChange('paperType', value);
                      }}
                      onBlur={value => {
                        input.onBlur(value);
                      }}
                      validateStatus={
                        meta.error && meta.touched ? 'error' : 'validating'
                      }
                      errorMessage={meta.error && meta.touched && meta.error}
                    />
                  )}
                </Field>
                <Field name="district">
                  {({ input, meta }) => (
                    <SelectField
                      label="Wilayah"
                      placeholder="Pilih Wilayah"
                      data={districtCode}
                      value={input.value}
                      validate
                      onChange={value => {
                        handleChange('district', value);
                      }}
                      onBlur={value => {
                        input.onBlur(value);
                      }}
                      validateStatus={
                        meta.error && meta.touched ? 'error' : 'validating'
                      }
                      errorMessage={meta.error && meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Col>
              <Col
                span={12}
                style={{ display: 'flex', flexDirection: 'column', gap: 24 }}
              >
                <Field name="branch">
                  {({ input, meta }) => (
                    <SelectField
                      label="Posko"
                      placeholder="Pilih Posko"
                      data={dataBranchs}
                      value={input.value}
                      validate
                      onChange={value => {
                        handleChange('branch', value);
                      }}
                      onBlur={value => {
                        input.onBlur(value);
                      }}
                      validateStatus={
                        meta.error && meta.touched ? 'error' : 'validating'
                      }
                      errorMessage={meta.error && meta.touched && meta.error}
                      loading={isLoadingBranch}
                    />
                  )}
                </Field>
                <Field name="courier">
                  {({ input, meta }) => (
                    <SelectField
                      label="Kurir"
                      placeholder="Pilih Kurir"
                      data={dataCourier}
                      value={input.value}
                      validate
                      onChange={value => {
                        handleChange('courier', value);
                      }}
                      onBlur={value => {
                        input.onBlur(value);
                      }}
                      validateStatus={
                        meta.error && meta.touched ? 'error' : 'validating'
                      }
                      errorMessage={meta.error && meta.touched && meta.error}
                      loading={isLoadingCourier}
                      disabled={!values.branch}
                      valueKey="id"
                      labelKey="firstName"
                      secondLabelKey="code"
                    />
                  )}
                </Field>
                <div
                  style={{ display: 'flex', alignItems: 'flex-end', gap: 12 }}
                >
                  <Field name="scan">
                    {({ input, meta }) => (
                      <InputTextField
                        label="Scan"
                        disabled={!valid}
                        placeholder={
                          'Masukkan ' +
                          (values.typeScan === 'waybill'
                            ? 'Waybill'
                            : 'Barcode')
                        }
                        value={initialValues.scan}
                        onChange={value => {
                          handleChange('scan', value);
                        }}
                        onBlur={value => {
                          input.onBlur(value);
                        }}
                        onPressEnter={handleAssignDocument}
                        error={meta.error && meta.touched && meta.error}
                        width="100%"
                        addonBefore={
                          <Field name="typeScan">
                            {({ input: inputTypeScan }) => (
                              <Select
                                onChange={value => {
                                  if (value !== 'barcode') {
                                    handleChange('isReserseBarcode', false);
                                  }
                                  handleChange('typeScan', value);
                                }}
                                value={inputTypeScan.value}
                              >
                                <Select.Option value="waybill">
                                  Waybill
                                </Select.Option>
                                <Select.Option value="barcode">
                                  Barcode
                                </Select.Option>
                              </Select>
                            )}
                          </Field>
                        }
                      />
                    )}
                  </Field>
                  <Field name="isReverseBarcode">
                    {({ input, meta }) => (
                      <SingleCheckbox
                        labelRight="Barcode Terbalik"
                        input={input}
                        meta={meta}
                        disabled={values.typeScan === 'waybill'}
                        onChange={() => {
                          handleChange('isReverseBarcode', !input.value);
                        }}
                      />
                    )}
                  </Field>
                </div>
              </Col>
              <Col span={24}>
                <b>Status</b>
                {successScan.waybill && (
                  <p style={{ fontWeight: 700 }}>
                    <span style={{ color: '#0096EE' }}>
                      {successScan.waybill}
                    </span>{' '}
                    <span style={{ color: '#31E116' }}>
                      telah berhasil di-assign ke kurir{' '}
                      {successScan.courierName}
                    </span>
                  </p>
                )}
              </Col>
            </Row>
          )}
        </Form>
      </CardMaterial>
    </SpinMaterial>
  );
}
