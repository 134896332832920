export function changeClearIdStatus()
{
    return({
        type:"CHANGE_CLEAR_ID_STATUS"
    })
}
export function changeDetailStatus()
{
    return({
        type:"CHANGE_DETAIL_STATUS"
    })
}

export function changeIdStatus(IdStatus:any)
{
    return({
        type:"CHANGE_ID_STATUS",
        IdStatus
    })
}