import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionUpdateAddress from '../../../action/ManualProcess/ActionUpdateAddress';
import * as toggle from '../../../action/toggle/index';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import React from 'react';
import axios from 'axios';
import moment from 'moment';

export function* S_getWaybillUpdateAddress(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const waybill = state.ManualProcess.updateAddress.waybill;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(toggle.changeLoadingTrue());
    yield put(ActionUpdateAddress.clearFormExceptWaybill());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + `/Delivery/ChangeAddress?waybill=${waybill}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionUpdateAddress.changeName(data.shipToName));
    yield put(ActionUpdateAddress.changePhoneNumber(data.shipToPhone));
    yield put(
      ActionUpdateAddress.changeAddress(JSON.parse(data.shipToAddress))
    );
    yield put(ActionUpdateAddress.changeWaybillForm(waybill));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_submitUpdateAddress(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const waybill = state.ManualProcess.updateAddress.waybill;
  const address = state.ManualProcess.updateAddress.address;
  const addressPost = JSON.stringify(address);
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(toggle.changeLoadingTrue());
    yield call(
      axios.put,
      process.env.REACT_APP_URL + `/Delivery/ChangeAddress`,
      {
        waybill: waybill,
        shipToAddress: addressPost,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionUpdateAddress.clearAllForm());
    yield put(toggle.changeLoadingFalse());
    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil Mengubah Data</b>
        </p>
      ),
      onOk() {},
    });
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_GetListCustomerProductUpdateAddress(action: any) {
  try {
    const state = yield select();
    const token = state.Auth.Token;
    const statusTokenRefresh = state.Auth.StatusTokenRefresh;
    const config = {
      Authorization: `bearer ${token}`,
    };
    const IdCustomer = state.ManualProcess.updateAddress.customer;
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Customer/product/customer/${IdCustomer}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionUpdateAddress.fetchCustomerProductList(data.data));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* S_GetListTemplateUpload(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const IdCustomer = state.ManualProcess.updateAddress.customer;
  try {
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Setting/CustomCsv/Customer/${IdCustomer}`,
      { headers: config }
    );
    yield put(ActionUpdateAddress.fetchTemplateUploadList(data.data));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* S_SubmitUpdateAddressUpload(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const customerProduct = state.ManualProcess.updateAddress.customerProduct;
  const templateUpload = state.ManualProcess.updateAddress.templateUpload;
  const fileUpload = state.ManualProcess.updateAddress.fileUpload;
  const fileName = state.ManualProcess.updateAddress.fileName;
  const cycleDate = moment(state.ManualProcess.updateAddress.cycleDate).format(
    'YYYYMMDD'
  );
  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/Delivery/ChangeAddress/Upload`,
      {
        fileBase64: fileUpload,
        cycle: cycleDate,
        customerProductId: customerProduct,
        customFileId: templateUpload,
        fileName: fileName,
      },
      { headers: config }
    );
    yield put(ActionUpdateAddress.clearMultipleDataForm());
    yield put(ActionUpdateAddress.fetchResultUpload(data));
    yield put(toggle.changeModal());
    yield put(toggle.changeJenisToggle('resultUploadUpdateAddress'));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}

function* S_downloadResultUploadAddress(action) {
  const state = yield select();
  const key = 'download';
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const resultUpload = state.ManualProcess.updateAddress.resultUpload;
  const DateNow = moment(new Date()).format('DDMMYYYY');
  const fileName2 = `ResultUpdateAddress_${DateNow}.txt`;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const fileName = fileName2;
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Delivery/upload/downloadResult`,
      {
        listResult: resultUpload.listResultCsv,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const percentProgress = Math.floor((loaded * 100) / total);

          if (percentProgress < 100) {
            message.loading({
              content: `Loading ${percentProgress}%'`,
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.data.Message === 'Internal Server Error') {
      message.warning({
        content: 'Data Tidak Tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(toggle.changeLoadingFalse());
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}
export function* SM_downloadResultUploadAddress() {
  yield takeLatest(
    'DOWNLOAD_RESULT_UPLOAD_ADDRESS_UA',
    S_downloadResultUploadAddress
  );
}
export function* SM_SubmitUpdateAddressUpload() {
  yield takeLatest('SUBMIT_UPDATE_ADDRESS_UPLOAD', S_SubmitUpdateAddressUpload);
}
export function* SM_GetListTemplateUpload() {
  yield takeLatest('GET_TEMPLATE_UPLOAD_UA', S_GetListTemplateUpload);
}

export function* SM_GetListCustomerProductUpdateAddress() {
  yield takeLatest(
    'GET_LIST_CUSTOMER_PRODUCT_UPDATE_ADDRESS',
    S_GetListCustomerProductUpdateAddress
  );
}

export function* SM_getWaybillUpdateAddress() {
  yield takeLatest('SEARCH_WAYBILL_UA', S_getWaybillUpdateAddress);
}

export function* SM_submitUpdateAddress() {
  yield takeLatest('SUBMIT_UPDATE_ADDRESS', S_submitUpdateAddress);
}
