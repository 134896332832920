import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionFormPrintWaybill from '../../../action/Delivery/PrintWaybill/ActionFormPrintWaybill';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionPagination from '../../../action/Pagination/ActionPagination';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../../action/toggle';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { setDataPrintWaybill } from '../../../action/Delivery/PrintWaybill/ActionFormPrintWaybill';

export function* S_getDataPrintWaybill(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const UploadDate = moment(
    state.PrintWaybill.FormPrintWaybill.UploadDate
  ).format('YYYYMMDD');
  const RadioButton = state.PrintWaybill.FormPrintWaybill.RadioButton;
  const BranchId = state.PrintWaybill.FormPrintWaybill.BranchId;
  const CustomerProduct = state.PrintWaybill.FormPrintWaybill.CustomerProductId;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const condtionRadioButton = RadioButton === 'Upload' ? false : true;
  const CPJoin = CustomerProduct.join('&customerProducts=');

  const conditionEndPoint =
    BranchId !== null && CustomerProduct.length !== 0
      ? process.env.REACT_APP_URL +
        '/Delivery/File/CetakWaybill/' +
        UploadDate +
        '/' +
        condtionRadioButton +
        '?idPosko=' +
        BranchId +
        '&customerProducts=' +
        CPJoin
      : BranchId === null && CustomerProduct.length !== 0
      ? process.env.REACT_APP_URL +
        '/Delivery/File/CetakWaybill/' +
        UploadDate +
        '/' +
        condtionRadioButton +
        '?customerProducts=' +
        CPJoin
      : BranchId !== null && CustomerProduct.length === 0
      ? process.env.REACT_APP_URL +
        '/Delivery/File/CetakWaybill/' +
        UploadDate +
        '/' +
        condtionRadioButton +
        '?idPosko=' +
        BranchId
      : process.env.REACT_APP_URL +
        '/Delivery/File/CetakWaybill/' +
        UploadDate +
        '/' +
        condtionRadioButton;

  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(axios.get, conditionEndPoint, {
      headers: config,
    });
    if (data.length === 0) {
      Modal.error({
        title: <p style={{ color: '#FF0000' }}>Data Tidak Tersedia</p>,

        onOk() {},
      });
      // yield put(ActionFormPrintWaybill.ClearPrintWaybillFormPW())
    }
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionFormPrintWaybill.ClearAllCheckbox());
    yield put(setDataPrintWaybill(data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));

    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(changeLoadingFalse());
    // yield put(ActionFormPrintWaybill.ClearPrintWaybillFormPW())
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* SM_getDataPrintWaybill() {
  yield takeLatest('GET_DATA_PRINT_WAYBILL', S_getDataPrintWaybill);
}
