export function changeUsername(Username: any) {
  return {
    type: 'CHANGE_USERNAME_FORM_LOGIN',
    Username,
  };
}

export function changePassword(Password: any) {
  return {
    type: 'CHANGE_PASSWORD_FORM_LOGIN',
    Password,
  };
}

export function changeClearUsername() {
  return {
    type: 'CHANGE_CLEAR_USERNAME',
  };
}

export function changeClearPassword() {
  return {
    type: 'CHANGE_CLEAR_PASSWORD',
  };
}

export function submitLogin() {
  return {
    type: 'LOGIN_REQUESTED',
  };
}
export function submitTokenRefrash() {
  return {
    type: 'REQUEST_TOKEN_REFRESH_TEMPORARY',
  };
}
export function submitTokenRefresh(lastAction: any = null, url = null) {
  return {
    type: 'REQUEST_TOKEN_REFRASH',
    lastAction,
    url,
  };
}
export function errorHandling(actionData: any, errorData: any) {
  console.log('isi ',actionData)
  return {
    type: 'ERROR_HANDLING',
    actionData,
    errorData,
  };
}
