import * as ActionComplaint from 'core/action/Complaint/ActionFormComplaint';
import * as ActionGetDeliveryStatus from 'module/DeliveryStatus/Store/ActionGetDeliveryStatus';
import * as ActionToggle from 'core/action/toggle/index';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import FormComplaintComponent from 'module/Complaint/Component/FormComplaintComponent';
import { connect } from 'react-redux';

interface IProps {
  isLoading: any;
  visible: any;
  typeToggle: any;
  actionToggle: any;
  actionComplaint: any;
  actionGetDeliveryStatus: any;

  getComplaintStatus: any;
  getComplaintTicketCode: any;
  getComplaintWaybill: any;
  getComplaintWaybillDetail: any;
  getComplaintNoTelp: any;
  getComplaintDetail: any;
  getComplaintResultInvestigation: any;
  getComplaintOwnerName: any;
  getComplaintAddress: any;
  getComplaintBarcodeDetail: any;
  getComplaintNoTelpDetail: any;
  getComplaintPostCode: any;
  getComplaintCity: any;
}
function FormComplainContainer(props: IProps) {
  const {
    isLoading,
    visible,
    typeToggle,
    actionToggle,
    actionComplaint,
    actionGetDeliveryStatus,
    getComplaintStatus,
    getComplaintTicketCode,
    getComplaintWaybill,
    getComplaintWaybillDetail,
    getComplaintNoTelp,
    getComplaintDetail,
    getComplaintResultInvestigation,
    getComplaintOwnerName,
    getComplaintAddress,
    getComplaintBarcodeDetail,
    getComplaintNoTelpDetail,
    getComplaintPostCode,
    getComplaintCity,
  } = props;

  const [ValidateWaybillForm, setValidateWaybillForm]: any = useState(null);
  const [ValidatePhoneNumberForm, setValidatePhoneNumberForm]: any =
    useState(null);
  const [ValidateComplaintDetailForm, setValidateComplaintDetailForm]: any =
    useState(null);
  const [
    ValidateResultInvestigationForm,
    setValidateResultInvestigationForm,
  ]: any = useState(null);

  function handleCloseModal() {
    setValidateWaybillForm(null);
    setValidatePhoneNumberForm(null);
    setValidateComplaintDetailForm(null);
    setValidateResultInvestigationForm(null);
    actionComplaint.clearComplaintForm();
    actionToggle.changeModal();
    actionToggle.clearJenisToggle();
  }
  function handleSearchWaybill(event: any) {
    actionGetDeliveryStatus.getDeliveryStatus();
  }
  function handlePutWaybill(event: any) {
    actionComplaint.changeComplaintWaybill(event.target.value);
    if (event.target.value === '') {
      setValidateWaybillForm('error');
    } else {
      setValidateWaybillForm('');
    }
  }
  function handlePutPhoneNumber(event: any) {
    const number = parseInt(event.target.value || 0, 10);
    const RegexPhone = /^[0-9\b]+$/;

    if (isNaN(number)) {
      return;
    }

    if (!('value' in props)) {
      actionComplaint.changeComplaintNoTelp(
        event.target.value.replace(/^0+/, '')
      );
    }

    if (event.target.value === '') {
      setValidatePhoneNumberForm('error');
    } else if (!RegexPhone.test(event.target.value)) {
      setValidatePhoneNumberForm('NonNumeric');
    } else if (event.target.value.length < 11) {
      setValidatePhoneNumberForm('notMinChar');
    } else {
      setValidatePhoneNumberForm('');
    }
  }
  function handlePutComplaintDetail(event: any) {
    actionComplaint.changeComplaintDetail(event.target.value);
    if (event.target.value === '') {
      setValidateComplaintDetailForm('error');
    } else if (event.target.value.length < 11) {
      setValidateComplaintDetailForm('notMinChar');
    } else {
      setValidateComplaintDetailForm('');
    }
  }
  function handlePutResultInvestigation(event: any) {
    actionComplaint.changeComplaintResultInvestigation(event.target.value);
    if (event.target.value === '') {
      setValidateResultInvestigationForm('error');
    } else if (event.target.value.length < 11) {
      setValidateResultInvestigationForm('notMinChar');
    } else {
      setValidateResultInvestigationForm('');
    }
  }
  function handleUpdate() {
    actionComplaint.updateTicket();
  }
  function handleUpdateTicketForm() {
    actionComplaint.submitEditComplaint();
  }
  function handleSubmit() {
    actionComplaint.generateTicketCode();
    actionComplaint.submitComplaint();
  }
  function handleDownload() {
    actionComplaint.downloadTicket();
  }
  return (
    <React.Fragment>
      <FormComplaintComponent
        visible={visible}
        isLoading={isLoading}
        typeToggle={typeToggle}
        handleCloseModal={handleCloseModal}
        handleUpdate={handleUpdate}
        handleUpdateTicketForm={handleUpdateTicketForm}
        handleSubmit={handleSubmit}
        handleDownload={handleDownload}
        validateIcon={
          getComplaintOwnerName === null ||
          getComplaintBarcodeDetail === null ||
          getComplaintNoTelpDetail === null
            ? true
            : false
        }
        ValidateButton={
          getComplaintWaybill === null ||
          getComplaintWaybill === '' ||
          getComplaintOwnerName === null ||
          getComplaintOwnerName === '' ||
          // getComplaintNoTelp===null||getComplaintNoTelp===""||ValidatePhoneNumberForm==="notMinChar"||ValidatePhoneNumberForm==="NonNumeric"||
          getComplaintDetail === null ||
          getComplaintDetail === '' ||
          getComplaintDetail === 'notMinChar'
            ? true
            : false
        }
        ValidateButtonDownload={
          getComplaintWaybill === null ||
          getComplaintWaybill === '' ||
          getComplaintOwnerName === null ||
          getComplaintOwnerName === '' ||
          getComplaintDetail === null ||
          getComplaintDetail === '' ||
          getComplaintDetail === 'notMinChar'
            ? true
            : false
        }
        ValidateButtonUpdate={
          getComplaintDetail === null ||
          getComplaintDetail === '' ||
          ValidateComplaintDetailForm === 'notMinChar' ||
          getComplaintResultInvestigation === null ||
          getComplaintResultInvestigation === '' ||
          ValidateResultInvestigationForm === 'notMinChar'
            ? true
            : false
        }
        ValidateButtonUpdateForm={
          getComplaintDetail === null ||
          getComplaintDetail === '' ||
          ValidateComplaintDetailForm === 'notMinChar' ||
          ValidateResultInvestigationForm === 'notMinChar'
            ? true
            : false
        }
        getComplaintPostCode={getComplaintPostCode}
        getComplaintCity={getComplaintCity}
        getComplaintWaybillDetail={getComplaintWaybillDetail}
        getComplaintOwnerName={getComplaintOwnerName}
        getComplaintAddress={getComplaintAddress}
        getComplaintBarcodeDetail={getComplaintBarcodeDetail}
        getComplaintNoTelpDetail={getComplaintNoTelpDetail}
        getComplaintStatus={
          getComplaintStatus === 'new'
            ? 'Baru'
            : getComplaintStatus === 'process'
            ? 'Proses'
            : 'Selesai'
        }
        handleSearchWaybill={handleSearchWaybill}
        getComplaintTicketCode={getComplaintTicketCode}
        PutWaybill={handlePutWaybill}
        getComplaintWaybill={getComplaintWaybill}
        ValidateWaybillForm={ValidateWaybillForm === 'error' ? 'error' : ''}
        helpWaybillForm={
          ValidateWaybillForm === 'error' ? 'Waybill wajib diisi' : ''
        }
        disableWaybillForm={typeToggle === 'EditTicket' ? true : false}
        PutPhoneNumber={handlePutPhoneNumber}
        getComplaintNoTelp={getComplaintNoTelp}
        ValidatePhoneNumberForm={
          ValidatePhoneNumberForm === 'error' ||
          ValidatePhoneNumberForm === 'notMinChar' ||
          ValidatePhoneNumberForm === 'NonNumeric'
            ? 'error'
            : ''
        }
        helpPhoneNumberForm={
          ValidatePhoneNumberForm === 'error'
            ? 'No telp wajib diisi'
            : ValidatePhoneNumberForm === 'NonNumeric'
            ? 'Tidak boleh selain angka'
            : ValidatePhoneNumberForm === 'notMinChar'
            ? 'No telp harus lebih dari 10 karakter'
            : ''
        }
        PutComplaintDetail={handlePutComplaintDetail}
        getComplaintDetail={getComplaintDetail}
        ValidateComplaintDetailForm={
          ValidateComplaintDetailForm === 'error' ||
          ValidateComplaintDetailForm === 'notMinChar'
            ? 'error'
            : ''
        }
        helpComplaintDetailForm={
          ValidateComplaintDetailForm === 'error'
            ? 'Komplain wajib diisi'
            : ValidateComplaintDetailForm === 'notMinChar'
            ? 'Komplain harus lebih dari 10 karakter'
            : ''
        }
        disableComplaintDetailForm={typeToggle === 'EditTicket' ? true : false}
        PutResultInvestigation={handlePutResultInvestigation}
        getComplaintResultInvestigation={getComplaintResultInvestigation}
        ValidateResultInvestigationForm={
          ValidateResultInvestigationForm === 'error' ||
          ValidateResultInvestigationForm === 'notMinChar'
            ? 'error'
            : ''
        }
        helpResultInvestigationForm={
          ValidateResultInvestigationForm === 'error'
            ? 'Hasil investigasi wajib diisi'
            : ValidateResultInvestigationForm === 'notMinChar'
            ? 'Hasil Investigasi harus lebih dari 10 karakter'
            : ''
        }
      />
    </React.Fragment>
  );
}
const mapStateToProps = (state: any) => ({
  isLoading: state.Toggle.isLoading,
  visible: state.Toggle.modal,
  typeToggle: state.Toggle.jenisToggle,
  getComplaintStatus: state.Complaint.complaintProperties.complaintStatus,
  getComplaintTicketCode: state.Complaint.complaintTicketCode,
  getComplaintWaybill: state.Complaint.complaintWaybill,
  getComplaintWaybillDetail: state.Complaint.complaintWaybillDetail,
  getComplaintBarcodeDetail: state.Complaint.complaintBarcodeDetail,
  getComplaintNoTelp: state.Complaint.complaintNoTelp,
  getComplaintNoTelpDetail: state.Complaint.complaintNoTelpDetail,
  getComplaintDetail: state.Complaint.complaintDetail,
  getComplaintResultInvestigation: state.Complaint.complaintResultInvestigation,
  getComplaintOwnerName: state.Complaint.complaintOwnerName,
  getComplaintPostCode: state.Complaint.complaintPostCode,
  getComplaintCity: state.Complaint.complaintCity,
  getComplaintAddress: state.Complaint.complaintAddress,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionComplaint: bindActionCreators(ActionComplaint, dispatch),
  actionGetDeliveryStatus: bindActionCreators(
    ActionGetDeliveryStatus,
    dispatch
  ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FormComplainContainer);
