import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';

import MisReportComponent from 'module/Report/Component/Mis/MisReportComponent';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  getProductByCustomers,
  getAllCustomers,
} from 'services/GeneralService';
import { downloadDeliveryMIS } from 'services/ReportMISService';
import moment from 'moment';
import { processProgressRequest, downloadBlob } from 'lib/util';
import ModalStatus from 'materials/ModalStatus';
import { Modal } from 'antd';
import ModalDownloadProgress from 'materials/ModalDownloadProgress';

export type InitialFormType = {
  typeReport: string;
  customer: number | null;
  district: any[];
  datePickup: any[];
  product: any[];
};

function MisReportContainer() {
  const defaultForm = {
    typeReport: 'mis',
    customer: null,
    district: ['Jabodetabek'],
    datePickup: [null, null],
    product: [],
  };
  const [initialForm, setInitialForm] = useState(defaultForm);
  const [dataProducts, setDataProdutcs] = useState([]);
  const [dataCustomers, setDataCustomers] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const progressHandler = processProgressRequest(setLoadingProgress);

  const { isLoading: isLoadingCustomers } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataCustomers'],
    queryFn: () => getAllCustomers(),
    onSuccess(data) {
      const datas = [] as any;
      data.data.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
        });
      });
      setDataCustomers(datas);
    },
    refetchOnWindowFocus: false,
  });

  const { isFetching: isLoadingProducts } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataProducts', { id: initialForm.customer }],
    queryFn: ({ queryKey }) => getProductByCustomers(queryKey),
    onSuccess(data) {
      const datas = [{ id: 0, name: 'Pilih Semua Produk' }] as any;
      data.data.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
        });
      });
      setDataProdutcs(datas);
    },
    enabled: !!initialForm.customer,
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateDownload, isLoading: isLoadingDownload } = useMutation(
    (data: any) => downloadDeliveryMIS(data, progressHandler),
    {
      onSuccess: res => {
        if (res.status === 200) {
          const startDate = moment(initialForm.datePickup[0]).format(
            'YYYYMMDD'
          );
          const endDate = moment(initialForm.datePickup[1]).format('YYYYMMDD');
          const customer = dataCustomers.find(
            (value: any) => value.id === initialForm.customer
          ) as any;
          downloadBlob(
            res.data,
            `Laporan ${initialForm.typeReport.toUpperCase()} ${
              customer.name || null
            } ${startDate} ${endDate}`,
            'xls'
          );
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
            content:
              res.response?.data?.Message || 'Data Pengiriman tidak ditemukan',
          });
          setLoadingProgress(0);
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    }
  );

  useEffect(() => {
    if (loadingProgress === 100) {
      Modal.destroyAll();
      setLoadingProgress(0);
    }
  }, [loadingProgress]);

  const handleChangeForm = (name: string, value: any) => {
    setInitialForm(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResetForm = () => {
    setInitialForm(defaultForm);
  };

  const handleDownload = () => {
    const data = {
      itemDeliveryTimeFrom: moment(initialForm.datePickup[0]).format(
        'YYYYMMDD'
      ),
      itemDeliveryTimeTo: moment(initialForm.datePickup[1]).format('YYYYMMDD'),
      customer: initialForm.customer,
      district: initialForm.district,
      customerProducts: initialForm.product,
      typeReport: initialForm.typeReport,
    };

    mutateDownload(data);
  };

  return (
    <>
      <MisReportComponent
        initialForm={initialForm}
        handleChangeForm={handleChangeForm}
        isLoading={isLoadingDownload}
        handleResetForm={handleResetForm}
        handleDownload={handleDownload}
        isLoadingProducts={isLoadingProducts}
        isLoadingCustomers={isLoadingCustomers}
        dataProducts={dataProducts}
        dataCustomers={dataCustomers}
      />
      <ModalDownloadProgress
        loading={isLoadingDownload}
        loadingProgress={loadingProgress}
      />
    </>
  );
}

export default MisReportContainer;
