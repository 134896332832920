import { Action } from 'redux';
import { IFetchCallCenterAction } from './CallCenterAction';

const initialState: any = {
  formatCsvList: [],
  customerProductList: [],
  courierList: [],
  successAttempt: null,
  successKu: null,
  failedAttempt: null,
  failedKu: null,
  listResultCsv: [],
  deliveryDate: null,
  fileName: null,
  csvFile: null,
};

export default function PickUpProcessReducer(
  state = initialState,
  action: Action
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_FORMAT_CSV_LIST': {
      const fetchFormatCsvListAction = action as IFetchCallCenterAction;
      newState.formatCsvList = fetchFormatCsvListAction.data;
      return { ...newState };
    }
    case 'FETCH_CUSTOMER_PRODUCT_LIST': {
      const fetchCustomerProductListAction = action as IFetchCallCenterAction;
      newState.customerProductList = fetchCustomerProductListAction.data;
      return { ...newState };
    }
    case 'FETCH_COURIER_LIST': {
      const fetchCourierListAction = action as IFetchCallCenterAction;
      newState.courierList = fetchCourierListAction.data;
      return { ...newState };
    }
    case 'CHANGE_CALL_CENTER_DELIVERY_DATE': {
      const changeDeliveryDateAction = action as IFetchCallCenterAction;
      newState.deliveryDate = changeDeliveryDateAction.data;
      return { ...newState };
    }
    case 'CHANGE_CALL_CENTER_CSV_FILE_NAME': {
      const setCsvFileNameAction = action as IFetchCallCenterAction;
      newState.fileName = setCsvFileNameAction.data;
      return { ...newState };
    }
    case 'SET_CALL_CENTER_CSV': {
      const setCsvFileAction = action as IFetchCallCenterAction;
      newState.csvFile = setCsvFileAction.data;
      return { ...newState };
    }
    case 'FETCH_DATA_SUCCESS_ATTEMPT_CSV_CALL_CENTER': {
      const fetchDataSuccessAttemptCsvAction = action as IFetchCallCenterAction;
      newState.successAttempt = fetchDataSuccessAttemptCsvAction.data;
      return { ...newState };
    }
    case 'FETCH_DATA_SUCCESS_KU_CSV_CALL_CENTER': {
      const fetchDataSuccessCsvAction = action as IFetchCallCenterAction;
      newState.successKu = fetchDataSuccessCsvAction.data;
      return { ...newState };
    }
    case 'FETCH_DATA_FAILED_ATTEMPT_CSV_CALL_CENTER': {
      const fetchDataFailedAttemptCsvAction = action as IFetchCallCenterAction;
      newState.failedAttempt = fetchDataFailedAttemptCsvAction.data;
      return { ...newState };
    }
    case 'FETCH_DATA_FAILED_KU_CSV_CALL_CENTER': {
      const fetchDataFailedCsvAction = action as IFetchCallCenterAction;
      newState.failedKu = fetchDataFailedCsvAction.data;
      return { ...newState };
    }
    case 'FETCH_DATA_LIST_CSV_CALL_CENTER': {
      const fetchDataListCsvAction = action as IFetchCallCenterAction;
      newState.listResultCsv = fetchDataListCsvAction.data;
      return { ...newState };
    }
    case 'CLEAR_LIST_RESULT_CSV_CALL_CENTER': {
      newState.listResultCsv = [];
      return { ...newState };
    }
    case 'CLEAR_ALL_FORM_CALL_CENTER': {
      newState.listResultCsv = [];
      newState.formatCsvList = [];
      newState.customerProductList = [];
      newState.courierList = [];
      newState.successAttempt = null;
      newState.successKu = null;
      newState.failedAttempt = null;
      newState.failedKu = null;
      newState.deliveryDate = null;
      newState.fileName = null;
      newState.csvFile = null;
      return { ...newState };
    }
  }
  return state;
}
