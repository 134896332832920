import instance from 'config/interceptor';
import moment from 'moment-timezone';

export const getDashboardKpi = async params => {
  try {
    const { selectedDates, customerId, productId, typeBranch, productIds } =
      params;
    const startDate = selectedDates[0];
    const endDate = selectedDates[1];

    let link = `/Delivery/Dashboard/Performance?`;
    if (startDate)
      link = link.concat(`startDate=${moment(startDate).format('DDMMYYYY')}`);
    if (endDate)
      link = link.concat(`&endDate=${moment(endDate).format('DDMMYYYY')}`);
    if (customerId) link = link.concat(`&customerId=${customerId}`);
    if (productId) {
      link = link.concat(`&productId=${productId}`);
    }
    if (typeBranch) {
      link = link.concat(`&branchType=${typeBranch}`);
    }
    if (productIds?.length > 0) {
      const queryParam = `customerProductIds=${productIds.join(
        '&customerProductIds='
      )}`;
      link = link.concat(`&${queryParam}`);
    }
    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};
