export function GetTableBranch()
{
    return({
        type:"GET_TABLE_BRANCH"
    })
}
export function SubmitSearchBranch() {
    return({
        type: "SUBMIT_SEARCH_BRANCH"
    })
}
export function SetTableBranch(TableBranch:any)
{
    return({
        type:"SET_TABLE_BRANCH",
        TableBranch
    })
}
export function ClearTableBranch()
{
    return({
        type:"CLEAR_TABLE_BRANCH"
    })
}