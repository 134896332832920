import * as ActionManualProcessVendor from '../../../../core/action/ManualProcess/ActionManualProcessVendor';
import * as ActionGeneralData from '../../../../module/App/Store/GeneralDataAction';
import * as Branch from '../../../../core/action/Branch/ActionAllBranch';
import * as CourierTable from '../../../../core/action/ManageAccount/Courier/ActionTableCourier';
import * as LastAccess from '../../../../core/action/LastAccess/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import ManualProcessVendorComponent from '../../../../components/Content/ManualProcess/Vendor/ManualProcessVendorComponent';
import { connect } from 'react-redux';

interface IProps {
  GetBranch: any;
  GetCourier: any;
  GetFileData: any;
  GetFormatDate: any;
  GetCheckBox: any;
  GetFileName: any;
  GetTypeUpload: any;
  BranchList: any;
  InternalBranchList: any;
  ListCourier: any;
  actionManualProcessVendor: any;
  actionBranch: any;
  actionCourierTable: any;
  actionGeneralData: any;
  LoadingSelectBranch: any;
  LoadingSelectCourier: any;
  TypeLoadingSelectBranch: any;
  TypeLoadingSelectCourier: any;
  isLoading: any;
  Fiture: any;
  GetIsChangeCourier: any;
}
interface IState {
  ValidateBranch: any;
  ValidateCourier: any;
  ValidateFormatDate: any;
  file: any;
  base64URL: any;
}
class ManualProcessVendorContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateBranch: null,
      ValidateCourier: null,
      ValidateFormatDate: null,
      file: null,
      base64URL: null,
    };

    this.handleGetBranch = this.handleGetBranch.bind(this);
    this.handlePutBranch = this.handlePutBranch.bind(this);
    this.handleResetCourier = this.handleResetCourier.bind(this);
    this.handleBlurBranch = this.handleBlurBranch.bind(this);
    this.handleGetCourier = this.handleGetCourier.bind(this);
    this.handleBlurSelectKurir = this.handleBlurSelectKurir.bind(this);
    this.handleSelectCourier = this.handleSelectCourier.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDownloadSample = this.handleDownloadSample.bind(this);
    this.handleConvert = this.handleConvert.bind(this);
    this.handleGetBase64 = this.handleGetBase64.bind(this);
    this.handlePutFormatDate = this.handlePutFormatDate.bind(this);
    this.handleChangeTypeUpload = this.handleChangeTypeUpload.bind(this);
    this.handleCheckBoxCourier = this.handleCheckBoxCourier.bind(this);
  }

  handleGetBranch() {
    if (this.props.GetTypeUpload === 'Internal') {
      this.props.actionGeneralData.getInternalBranchList();
    } else {
      this.props.actionManualProcessVendor.getBranchManualProcessVendorForm();
    }
  }

  handlePutBranch(event: any) {
    this.props.actionManualProcessVendor.changeBranchManualProcessVendor(event);
    if (event === null) {
      this.setState({
        ValidateBranch: 'error',
      });
    } else {
      this.setState({
        ValidateBranch: null,
      });
    }
  }

  handleBlurBranch() {
    if (this.props.GetBranch === null) {
      this.setState({
        ValidateBranch: 'error',
      });
    } else {
      this.setState({
        ValidateBranch: null,
      });
    }
  }

  handleResetCourier() {
    this.props.actionManualProcessVendor.clearCourierManualProcessVendorForm();
    this.props.actionCourierTable.clearCourierTable();
  }

  handleGetCourier() {
    this.props.actionCourierTable.getCourierTableBranch();
  }

  handleSelectCourier(event: any, label: any) {
    this.props.actionManualProcessVendor.changeCourierManualProcessVendor(
      event
    );

    if (event === null) {
      this.setState({
        ValidateCourier: 'error',
      });
    } else {
      this.setState({
        ValidateCourier: null,
      });
    }
  }

  handleBlurSelectKurir() {
    if (this.props.GetCourier === null) {
      this.setState({
        ValidateCourier: 'error',
      });
    } else {
      this.setState({
        ValidateCourier: null,
      });
    }
  }

  handleConvert(e: any) {
    this.props.actionManualProcessVendor.changeFileNameManualProcessVendor(
      e.target.value
    );
    let { file } = this.state;
    file = e.target.files[0];
    this.handleGetBase64(file)
      .then((result: any) => {
        file['base64'] = result.toString().split(',')[1];
        this.props.actionManualProcessVendor.changeFileDataManualProcessVendor(
          result.split(',')[1]
        );
        this.setState({
          base64URL: result.split(',')[1],
          file,
        });
      })
      .catch(err => {
        console.log(err);
      });

    this.setState({
      file: e.target.files[0],
    });
  }

  handleGetBase64 = (file: any) => {
    return new Promise(resolve => {
      let baseURL;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  handlePutFormatDate(event: any) {
    this.props.actionManualProcessVendor.changeFormatDateManualProcessVendor(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateFormatDate: 'error',
      });
    } else {
      this.setState({
        ValidateFormatDate: '',
      });
    }
  }

  handleReset() {
    this.props.actionManualProcessVendor.clearManualProcessVendorForm();
    this.setState({
      ValidateBranch: null,
      ValidateCourier: null,
      ValidateFormatDate: null,
    });
  }

  handleCheckBox() {
    this.props.actionManualProcessVendor.changeCheckBoxManualProcessVendor();
  }

  handleCheckBoxCourier() {
    this.props.actionManualProcessVendor.changeCheckBoxCourierManualProcessVendor();
  }

  handleDownloadSample() {
    if (this.props.GetFormatDate === null || this.props.GetFormatDate === '') {
      this.setState({
        ValidateFormatDate: 'error',
      });
    } else {
      this.props.actionManualProcessVendor.downloadSampleManualProcessVendorForm();
    }
  }

  handleChangeTypeUpload(event: any) {
    this.props.actionManualProcessVendor.changeTypeUploadManualProcessVendor(
      event.target.value
    );
    this.props.actionManualProcessVendor.changeBranchManualProcessVendor(null);
  }

  handleSave() {
    this.props.actionManualProcessVendor.saveManualProcessVendorForm();
  }

  render() {
    return (
      <ManualProcessVendorComponent
        BranchId={this.props.GetBranch}
        CourierId={this.props.GetCourier}
        FileData={this.props.GetFileData}
        FormatDate={this.props.GetFormatDate}
        BranchList={
          this.props.GetTypeUpload === 'Internal'
            ? this.props.InternalBranchList
            : this.props.BranchList
        }
        CheckBox={this.props.GetCheckBox}
        FileName={this.props.GetFileName}
        TypeUpload={this.props.GetTypeUpload}
        handleGetBranch={this.handleGetBranch}
        handlePutBranch={this.handlePutBranch}
        handlePutFormatDate={this.handlePutFormatDate}
        handleBlurBranch={this.handleBlurBranch}
        handleResetCourier={this.handleResetCourier}
        handleCheckBox={this.handleCheckBox}
        handleGetCourier={this.handleGetCourier}
        handleSelectCourier={this.handleSelectCourier}
        handleBlurSelectKurir={this.handleBlurSelectKurir}
        handleChangeTypeUpload={this.handleChangeTypeUpload}
        validateBranch={
          this.props.GetIsChangeCourier && this.state.ValidateBranch === 'error'
            ? 'error'
            : ''
        }
        validateCourier={
          this.props.GetIsChangeCourier &&
          this.state.ValidateCourier === 'error'
            ? 'error'
            : ''
        }
        validateFormatDate={
          this.state.ValidateFormatDate === 'error' ? 'error' : ''
        }
        helpValidateBranch={
          this.props.GetIsChangeCourier && this.state.ValidateBranch === 'error'
            ? this.props.GetTypeUpload === 'Internal'
              ? 'Posko wajib diisi'
              : 'Rekanan wajib diisi'
            : ''
        }
        helpValidateCourier={
          this.props.GetIsChangeCourier &&
          this.state.ValidateCourier === 'error'
            ? 'Kurir wajib diisi'
            : ''
        }
        helpValidateFormatDate={
          this.state.ValidateFormatDate === 'error'
            ? 'Format tanggal wajib diisi'
            : ''
        }
        loadingSelectBranch={
          this.props.LoadingSelectBranch === true &&
          this.props.TypeLoadingSelectBranch === 'selectbranch'
            ? true
            : false
        }
        loadingSelectCourier={
          this.props.LoadingSelectCourier === true &&
          this.props.TypeLoadingSelectCourier === 'selectCourier'
            ? true
            : false
        }
        ListCourier={this.props.ListCourier}
        isLoading={this.props.isLoading}
        isChangeCourier={this.props.GetIsChangeCourier}
        handleConvert={this.handleConvert}
        handleSave={this.handleSave}
        handleDownloadSample={this.handleDownloadSample}
        handleCheckBoxCourier={this.handleCheckBoxCourier}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  GetBranch: state.ManualProcess.ManualProcessVendorForm.BranchId,
  GetCourier: state.ManualProcess.ManualProcessVendorForm.CourierId,
  GetFileData: state.ManualProcess.ManualProcessVendorForm.FileData,
  GetFormatDate: state.ManualProcess.ManualProcessVendorForm.FormatDate,
  GetCheckBox: state.ManualProcess.ManualProcessVendorForm.CheckBox,
  GetIsChangeCourier:
    state.ManualProcess.ManualProcessVendorForm.isChangeCourier,
  GetFileName: state.ManualProcess.ManualProcessVendorForm.FileName,
  GetTypeUpload: state.ManualProcess.ManualProcessVendorForm.TypeUpload,

  ListCourier: state.Table.TableCourier,
  BranchList: state.Branch.AllBranch,
  InternalBranchList: state.GeneralDataListState.internalBranchList,
  Fiture: state.LastAccess.Fiture,
  isLoading: state.Toggle.isLoading,

  LoadingSelectBranch: state.Branch.PropertiesBranch.LoadingBranch,
  TypeLoadingSelectBranch: state.Branch.PropertiesBranch.TypeLoadingBranch,

  LoadingSelectCourier: state.Courier.PropertiesCourier.LoadingCourier,
  TypeLoadingSelectCourier: state.Courier.PropertiesCourier.TypeLoadingCourier,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionManualProcessVendor: bindActionCreators(
    ActionManualProcessVendor,
    dispatch
  ),
  actionBranch: bindActionCreators(Branch, dispatch),
  actionGeneralData: bindActionCreators(ActionGeneralData, dispatch),
  actionCourierTable: bindActionCreators(CourierTable, dispatch),
  actionLastAccess: bindActionCreators(LastAccess, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ManualProcessVendorContainer);
