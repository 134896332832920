import { Action } from 'redux';

const initialState = {
  CourierReportForm: {
    PickupDateCourierR: null,
    RadioButtonCourierR: 'Process',
    DeliveryDateCourierR: null,
    BranchCourierR: [],
    CourierR: null,
    StatusR: null,
    FormatFileR: 'xls',
    CustomerIdR: null,
    CustomerProductIdR: [],
    RingkasanR: false,
    SlaR: false,
  },
  DeliveryReportForm: {
    CycleDateStart: null,
    CycleDateLast: null,
    CustomerId: [],
    BranchId: [],
    CheckBoxToday: false,
  },
  InvoiceReport: {
    CycleDateStart: null,
    CheckboxIsMonth: false,
    RadiobuttonIsDetailInvoice: false,
    CustomerId: null,
    CustomerProductId: [],
  },
  CustomerReportForm: {
    RadioButton: 'Cycle',
    DatePickerMonth: false,
    PickupDate: null,
    CustomerId: null,
    CustomerName: null,
    CustomerProductId: [],
    ReportFormat: null,
    DeliveryStatus: ['OnProcces', 'Success', 'Return'],
    TreatmentDocument: ['ReturnCustomer', 'Destroy'],
    ListStatusSuccess: [],
    StatusSuccess: [],
    ListStatusReturn: [],
    StatusReturn: [],
    TypeDelivery: ['reguler'],
    FileData: null,
    IndexColumn: null,
    CheckBoxBarcode: false,
    CheckBoxFileName: false,
    CheckBoxFullData: false,
    CheckBoxToDayData: false,
  },
  MisReportForm: {
    RadioButton: 'mis',
    StartDate: null,
    FinishDate: null,
    District: ['Jabodetabek'],
    CustomerId: null,
    CustomerName: null,
    CustomerProductId: [],
  },
  CourierBonusReportForm: {
    RadioButton: 'Detail',
    FirstDate: null,
    LastDate: null,
    Branch: null,
    Courier: [],
    CourierCode: null,
  },
  ValidateReportForm: {
    RadioButtonDelivery: true,
    ReportType: 'Summary',
    FirstDate: null,
    LastDate: null,
    Branch: [],
    Status: null,
    GeoTagging: false,
    Foto: false,
    Signature: false,
    MultipleSelect: false,
  },
  DigitalWaybillReportForm: {
    RadioButtonDate: 'Cycle',
    FirstDate: null,
    LastDate: null,
    Customer: null,
    CustomerName: null,
    CustomerProduct: [],
    TypeOfName: null,
    DeliveryType: [],
    DeliveryStatus: [],
    FileCsvDw: null,
    CheckboxBarcode: false,
    CheckboxBarcodeReverse: false,
    FileName: null,
    FlagFileName: null,
    FlagFileNameUpload: null,
    CountSuccess: null,
    CountFailed: null,
  },
};

export interface IAuthAction extends Action<string> {}

export interface IChangePickupDateCourierR extends IAuthAction {
  PickupDateCourierR: any;
}

export interface IChangeRadioButtonCourierR extends IAuthAction {
  RadioButtonCourierR: any;
}

export interface IChangeDeliveryDateCourierR extends IAuthAction {
  DeliveryDateCourierR: any;
}

export interface IChangeBranchCourierR extends IAuthAction {
  BranchCourierR: any;
}

export interface IChangeCourierR extends IAuthAction {
  CourierR: any;
}

export interface IChangeStatusR extends IAuthAction {
  StatusR: any;
}

export interface IChangeFormatFileR extends IAuthAction {
  FormatFileR: any;
}

export interface IChangeCustomerIdR extends IAuthAction {
  CustomerIdR: any;
}

export interface IChangeCustomerProductIdR extends IAuthAction {
  CustomerProductIdR: any;
}
export interface IChangeRingkasanR extends IAuthAction {
  RingkasanR: any;
}
export interface IChangeSlaR extends IAuthAction {
  SlaR: any;
}
export interface IChangeCycleDateStart extends IAuthAction {
  CycleDateStart: any;
}
export interface IChangeCheckboxIsMonth extends IAuthAction {
  CheckboxIsMonth: any;
}
export interface IChangeRadiobuttonIsDetailInvoice extends IAuthAction {
  RadiobuttonIsDetailInvoice: any;
}
export interface IChangeCustomerId extends IAuthAction {
  CustomerId: any;
}
export interface IChangeCustomerProductId extends IAuthAction {
  CustomerProductId: any;
}

export interface IChangeRadioButtonCustomerReport extends IAuthAction {
  RadioButton: any;
}
export interface IChangeCycleCustomerReport extends IAuthAction {
  Cycle: any;
}

export interface IChangeProcessCustomerReport extends IAuthAction {
  Process: any;
}

export interface IChangeStatusCustomerReport extends IAuthAction {
  Status: any;
}

export interface IChangePickupDateCustomerReport extends IAuthAction {
  PickupDate: any;
}

export interface IChangeCustomerIdCustomerReport extends IAuthAction {
  CustomerId: any;
}

export interface IChangeCustomerNameCustomerReport extends IAuthAction {
  CustomerName: any;
}

export interface IChangeCustomerProductIdCustomerReport extends IAuthAction {
  CustomerProductId: any;
}

export interface IChangeReportFormatCustomerReport extends IAuthAction {
  ReportFormat: any;
}

export interface IChangeDeliveryStatusCustomerReport extends IAuthAction {
  DeliveryStatus: any;
}
export interface IChangeTreatmentDocumentCustomerReport extends IAuthAction {
  TreatmentDocument: any;
}

export interface IChangeFileDataCustomerReport extends IAuthAction {
  FileData: any;
}

export interface IChangeIndexColumnCustomerReport extends IAuthAction {
  IndexColumn: any;
}
export interface IChangeListStatusSuccessCustomerReport extends IAuthAction {
  ListStatusSuccess: any;
}
export interface IChangeStatusSuccessCustomerReport extends IAuthAction {
  StatusSuccess: any;
}
export interface IChangeListStatusReturnCustomerReport extends IAuthAction {
  ListStatusReturn: any;
}
export interface IChangeStatusReturnCustomerReport extends IAuthAction {
  StatusReturn: any;
}

export interface IChangeTypeDeliveryCustomerReport extends IAuthAction {
  TypeDelivery: any;
}

export interface IChangeRadioButtonMisReport extends IAuthAction {
  RadioButton: any;
}

export interface IChangeStartDateMisReport extends IAuthAction {
  StartDate: any;
}

export interface IChangeFinishDateMisReport extends IAuthAction {
  FinishDate: any;
}

export interface IChangeDistrictMisReport extends IAuthAction {
  District: any;
}

export interface IChangeCustomerIdMisReport extends IAuthAction {
  CustomerId: any;
}

export interface IChangeCustomerNameMisReport extends IAuthAction {
  CustomerName: any;
}

export interface IChangeCustomerProductIdMisReport extends IAuthAction {
  CustomerProductId: any;
}
export interface IChangeRadioButtonCBReport extends IAuthAction {
  RadioButton: any;
}
export interface IChangeFirstDateCBReport extends IAuthAction {
  FirstDate: any;
}
export interface IChangeLastDateCBReport extends IAuthAction {
  LastDate: any;
}
export interface IChangeBranchCBReport extends IAuthAction {
  Branch: any;
}
export interface IChangeCourierCBReport extends IAuthAction {
  Courier: any;
}
export interface IChangeCourierCodeCBReport extends IAuthAction {
  CourierCode: any;
}
export interface IChangeRadioButtonDeliveryVReport extends IAuthAction {
  RadioButtonDelivery: any;
}
export interface IChangeReportTypeVReport extends IAuthAction {
  ReportType: any;
}
export interface IChangeFirstDateVReport extends IAuthAction {
  FirstDate: any;
}
export interface IChangeLastDateVReport extends IAuthAction {
  LastDate: any;
}
export interface IChangeBranchVReport extends IAuthAction {
  Branch: any;
}
export interface IChangeStatusVReport extends IAuthAction {
  Status: any;
}
export interface IChangeGeoTaggingVReport extends IAuthAction {
  GeoTagging: any;
}
export interface IChangeFotoVReport extends IAuthAction {
  Foto: any;
}
export interface IChangeSignatureVReport extends IAuthAction {
  Signature: any;
}
export interface IChangeMultipleSelectVReport extends IAuthAction {
  MultipleSelect: any;
}
export interface IChangeRadioButtonDateDw extends IAuthAction {
  RadioButtonDate: any;
}
export interface IChangeFirstDateDw extends IAuthAction {
  FirstDate: any;
}
export interface IChangeLastDateDw extends IAuthAction {
  LastDate: any;
}
export interface IChangeCustomerDw extends IAuthAction {
  Customer: any;
}
export interface IChangeCustomerProductDw extends IAuthAction {
  CustomerProduct: any;
}
export interface IChangeCustomerNameDw extends IAuthAction {
  CustomerName: any;
}
export interface IChangeDeliveryTypeDw extends IAuthAction {
  DeliveryType: any;
}
export interface IChangeDeliveryStatusDw extends IAuthAction {
  DeliveryStatus: any;
}

export interface IChangeFileCsvDw extends IAuthAction {
  FileCsvDw: any;
}
export interface IChangeCbBarcodeDw extends IAuthAction {
  CheckboxBarcode: any;
}
export interface IChangeCbBarcodeReverseDw extends IAuthAction {
  CheckboxBarcodeReverse: any;
}
export interface IChangeTypeOfNameDw extends IAuthAction {
  TypeOfName: any;
}

export interface IChangeFileNameDw extends IAuthAction {
  FileName: any;
}
export interface IChangeFlagFileNameDw extends IAuthAction {
  FlagFileName: any;
}
export interface IChangeFlagFileNameUploadDw extends IAuthAction {
  FlagFileNameUpload: any;
}
export interface IChangeCountSuccessDw extends IAuthAction {
  CountSuccess: any;
}

export interface IChangeCountFailedDw extends IAuthAction {
  CountFailed: any;
}

export interface IChangeCycleDateStartDR extends IAuthAction {
  CycleDateStart: any;
}
export interface IChangeCycleDateLastDR extends IAuthAction {
  CycleDateLast: any;
}
export interface IChangeCustomerIdDR extends IAuthAction {
  CustomerId: any;
}
export interface IChangeBranchIdDR extends IAuthAction {
  BranchId: any;
}
export interface IChangeCheckBoxTodayDR extends IAuthAction {
  CheckBoxToday: any;
}

export default function AuthReducerDetailCourier(
  state = initialState,
  action: IAuthAction
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_PICKUP_DATE_COURIER_R_FORM':
      const changePickupDateCourierR = action as IChangePickupDateCourierR;
      newState.CourierReportForm.PickupDateCourierR =
        changePickupDateCourierR.PickupDateCourierR;

      return { ...newState };

    case 'CHANGE_RADIO_BUTTON_COURIER_R_FORM':
      const changeRadioButtonR = action as IChangeRadioButtonCourierR;
      newState.CourierReportForm.RadioButtonCourierR =
        changeRadioButtonR.RadioButtonCourierR;
      return { ...newState };

    case 'CHANGE_DELIVERY_DATE_COURIER_R_FORM':
      const changeDeliveryDateCourierR = action as IChangeDeliveryDateCourierR;
      newState.CourierReportForm.DeliveryDateCourierR =
        changeDeliveryDateCourierR.DeliveryDateCourierR;

      return { ...newState };

    case 'CHANGE_BRANCH_COURIER_R_FORM':
      const changeBranchCourierR = action as IChangeBranchCourierR;
      newState.CourierReportForm.BranchCourierR =
        changeBranchCourierR.BranchCourierR;

      return { ...newState };

    case 'CHANGE_COURIER_R_FORM':
      const changeCourierR = action as IChangeCourierR;
      newState.CourierReportForm.CourierR = changeCourierR.CourierR;

      return { ...newState };

    case 'CHANGE_STATUS_R_FORM':
      const changeStatusR = action as IChangeStatusR;
      newState.CourierReportForm.StatusR = changeStatusR.StatusR;

      return { ...newState };

    case 'CHANGE_FORMAT_FILE_R_FORM':
      const changeFormatFileR = action as IChangeFormatFileR;
      newState.CourierReportForm.FormatFileR = changeFormatFileR.FormatFileR;

      return { ...newState };

    case 'CHANGE_CUSTOMER_ID_R_FORM':
      const changeCustomerIdR = action as IChangeCustomerIdR;
      newState.CourierReportForm.CustomerIdR = changeCustomerIdR.CustomerIdR;

      return { ...newState };

    case 'CHANGE_CUSTOMER_PRODUCT_ID_R_FORM':
      const changeCustomerProductIdR = action as IChangeCustomerProductIdR;
      newState.CourierReportForm.CustomerProductIdR =
        changeCustomerProductIdR.CustomerProductIdR;

      return { ...newState };

    case 'CHANGE_RINGKASAN_R_FORM':
      const changeRingkasanR = action as IChangeRingkasanR;
      newState.CourierReportForm.RingkasanR = changeRingkasanR.RingkasanR;

      return { ...newState };

    case 'CHANGE_SLA_R_FORM':
      const changeSlaR = action as IChangeSlaR;
      newState.CourierReportForm.SlaR = changeSlaR.SlaR;

      return { ...newState };

    case 'SET_CYCLE_DATE_START_DR':
      const cycleDateStartDR = action as IChangeCycleDateStartDR;
      newState.DeliveryReportForm.CycleDateStart =
        cycleDateStartDR.CycleDateStart;
      return { ...newState };

    case 'SET_CYCLE_DATE_LAST_DR':
      const cycleDateLastDR = action as IChangeCycleDateLastDR;
      newState.DeliveryReportForm.CycleDateLast = cycleDateLastDR.CycleDateLast;
      return { ...newState };

    case 'SET_CUSTOMER_ID_DR':
      const customerIdDR = action as IChangeCustomerIdDR;
      newState.DeliveryReportForm.CustomerId = customerIdDR.CustomerId;
      return { ...newState };

    case 'SET_BRANCH_ID_DR':
      const branchIdDR = action as IChangeBranchIdDR;
      newState.DeliveryReportForm.BranchId = branchIdDR.BranchId;
      return { ...newState };

    case 'SET_CHECKBOX_TODAY_DR':
      newState.DeliveryReportForm.CheckBoxToday =
        !newState.DeliveryReportForm.CheckBoxToday;
      return { ...newState };

    case 'SET_CYCLE_DATE_START':
      const changeCycleDateStart = action as IChangeCycleDateStart;
      newState.InvoiceReport.CycleDateStart =
        changeCycleDateStart.CycleDateStart;

      return { ...newState };

    case 'SET_CHECKBOX_IS_MONTH':
      const changeCheckboxIsMonth = action as IChangeCheckboxIsMonth;
      newState.InvoiceReport.CheckboxIsMonth =
        changeCheckboxIsMonth.CheckboxIsMonth;

      return { ...newState };

    case 'SET_RADIOBUTTON_IS_DETAIL_INVOICE':
      const changeRadiobuttonIsDetailInvoice =
        action as IChangeRadiobuttonIsDetailInvoice;
      newState.InvoiceReport.RadiobuttonIsDetailInvoice =
        changeRadiobuttonIsDetailInvoice.RadiobuttonIsDetailInvoice;

      return { ...newState };

    case 'SET_CUSTOMER_ID':
      const changeCustomerId = action as IChangeCustomerId;
      newState.InvoiceReport.CustomerId = changeCustomerId.CustomerId;

      return { ...newState };

    case 'SET_CUSTOMER_PRODUCT_ID':
      const changeCustomerProductId = action as IChangeCustomerProductId;
      newState.InvoiceReport.CustomerProductId =
        changeCustomerProductId.CustomerProductId;

      return { ...newState };

    case 'CHANGE_RADIO_BUTTON_CUSTOMER_REPORT_FORM':
      const changeRadioButtonCustomerReport =
        action as IChangeRadioButtonCustomerReport;
      newState.CustomerReportForm.RadioButton =
        changeRadioButtonCustomerReport.RadioButton;
      return { ...newState };

    case 'CHANGE_DATE_PICKER_MONTH_CUSTOMER_REPORT_FORM':
      newState.CustomerReportForm.DatePickerMonth =
        !newState.CustomerReportForm.DatePickerMonth;
      return { ...newState };

    case 'CHANGE_PICKUP_DATE_CUSTOMER_REPORT_FORM':
      const changePickupDateCustomerReport =
        action as IChangePickupDateCustomerReport;
      newState.CustomerReportForm.PickupDate =
        changePickupDateCustomerReport.PickupDate;
      return { ...newState };

    case 'CHANGE_CUSTOMER_ID_CUSTOMER_REPORT_FORM':
      const changeCustomerIdCustomerReport =
        action as IChangeCustomerIdCustomerReport;
      newState.CustomerReportForm.CustomerId =
        changeCustomerIdCustomerReport.CustomerId;
      return { ...newState };

    case 'CHANGE_CUSTOMER_NAME_CUSTOMER_REPORT_FORM':
      const changeCustomerNameCustomerReport =
        action as IChangeCustomerNameCustomerReport;
      newState.CustomerReportForm.CustomerName =
        changeCustomerNameCustomerReport.CustomerName;
      return { ...newState };

    case 'CHANGE_CUSTOMER_PRODUCT_ID_CUSTOMER_REPORT_FORM':
      const changeCustomerProductIdCustomerReport =
        action as IChangeCustomerProductIdCustomerReport;
      newState.CustomerReportForm.CustomerProductId =
        changeCustomerProductIdCustomerReport.CustomerProductId;
      return { ...newState };

    case 'CHANGE_REPORT_FORMAT_CUSTOMER_REPORT_FORM':
      const changeReportFormatCustomerReport =
        action as IChangeReportFormatCustomerReport;
      newState.CustomerReportForm.ReportFormat =
        changeReportFormatCustomerReport.ReportFormat;
      return { ...newState };

    case 'CHANGE_DELIVERY_STATUS_CUSTOMER_REPORT_FORM':
      const changeDeliveryStatusCustomerReport =
        action as IChangeDeliveryStatusCustomerReport;
      newState.CustomerReportForm.DeliveryStatus =
        changeDeliveryStatusCustomerReport.DeliveryStatus;
      return { ...newState };

    case 'CHANGE_TREATMENT_DOCUMENT_CUSTOMER_REPORT_FORM':
      const changeTreatmentDocumentCustomerReport =
        action as IChangeTreatmentDocumentCustomerReport;
      newState.CustomerReportForm.TreatmentDocument =
        changeTreatmentDocumentCustomerReport.TreatmentDocument;
      return { ...newState };

    case 'CHANGE_FILE_DATA_CUSTOMER_REPORT_FORM':
      const changeFileDataCustomerReport =
        action as IChangeFileDataCustomerReport;
      newState.CustomerReportForm.FileData =
        changeFileDataCustomerReport.FileData;
      return { ...newState };

    case 'CHANGE_INDEX_COLUMN_CUSTOMER_REPORT_FORM':
      const changeIndexColumnCustomerReport =
        action as IChangeIndexColumnCustomerReport;
      newState.CustomerReportForm.IndexColumn =
        changeIndexColumnCustomerReport.IndexColumn;
      return { ...newState };

    case 'CHANGE_LIST_STATUS_SUCCESS_CUSTOMER_REPORT_FORM':
      const changeListStatusSuccessCustomerReport =
        action as IChangeListStatusSuccessCustomerReport;
      newState.CustomerReportForm.ListStatusSuccess =
        changeListStatusSuccessCustomerReport.ListStatusSuccess;
      return { ...newState };

    case 'CHANGE_STATUS_SUCCESS_CUSTOMER_REPORT_FORM':
      const changeStatusSuccessCustomerReport =
        action as IChangeStatusSuccessCustomerReport;
      newState.CustomerReportForm.StatusSuccess =
        changeStatusSuccessCustomerReport.StatusSuccess;
      return { ...newState };

    case 'CHANGE_LIST_STATUS_RETURN_CUSTOMER_REPORT_FORM':
      const changeListStatusReturnCustomerReport =
        action as IChangeListStatusReturnCustomerReport;
      newState.CustomerReportForm.ListStatusReturn =
        changeListStatusReturnCustomerReport.ListStatusReturn;
      return { ...newState };

    case 'CHANGE_STATUS_RETURN_CUSTOMER_REPORT_FORM':
      const changeStatusReturnCustomerReport =
        action as IChangeStatusReturnCustomerReport;
      newState.CustomerReportForm.StatusReturn =
        changeStatusReturnCustomerReport.StatusReturn;
      return { ...newState };

    case 'CHANGE_TYPE_DELIVERY_CUSTOMER_REPORT_FORM':
      const changeTypeDeliveryCustomerReport =
        action as IChangeTypeDeliveryCustomerReport;
      newState.CustomerReportForm.TypeDelivery =
        changeTypeDeliveryCustomerReport.TypeDelivery;
      return { ...newState };

    case 'CHANGE_CHECKBOX_BARCODE_CUSTOMER_REPORT_FORM':
      newState.CustomerReportForm.CheckBoxBarcode =
        !newState.CustomerReportForm.CheckBoxBarcode;
      return { ...newState };

    case 'CHANGE_CHECK_BOX_FILE_NAME_CUSTOMER_REPORT_FORM':
      newState.CustomerReportForm.CheckBoxFileName =
        !newState.CustomerReportForm.CheckBoxFileName;
      return { ...newState };

    case 'CHANGE_CHECK_BOX_FULL_DATA_CUSTOMER_REPORT_FORM':
      newState.CustomerReportForm.CheckBoxFullData =
        !newState.CustomerReportForm.CheckBoxFullData;
      return { ...newState };

    case 'CHANGE_CHECK_TO_DAY_DATA_CUSTOMER_REPORT_FORM':
      newState.CustomerReportForm.CheckBoxToDayData =
        !newState.CustomerReportForm.CheckBoxToDayData;
      return { ...newState };

    case 'CHANGE_RADIO_BUTTON_MIS_REPORT_FORM':
      const changeRadioButtonMisReport = action as IChangeRadioButtonMisReport;
      newState.MisReportForm.RadioButton =
        changeRadioButtonMisReport.RadioButton;
      return { ...newState };

    case 'CHANGE_START_DATE_MIS_REPORT_FORM':
      const changeStartDateMisReport = action as IChangeStartDateMisReport;
      newState.MisReportForm.StartDate = changeStartDateMisReport.StartDate;
      return { ...newState };

    case 'CHANGE_FINISH_DATE_MIS_REPORT_FORM':
      const changeFinishDateMisReport = action as IChangeFinishDateMisReport;
      newState.MisReportForm.FinishDate = changeFinishDateMisReport.FinishDate;
      return { ...newState };

    case 'CHANGE_DISTRICT_MIS_REPORT_FORM':
      const changeDistrictMisReport = action as IChangeDistrictMisReport;
      newState.MisReportForm.District = changeDistrictMisReport.District;
      return { ...newState };

    case 'CHANGE_CUSTOMER_ID_MIS_REPORT_FORM':
      const changeCustomerIdMisReport = action as IChangeCustomerIdMisReport;
      newState.MisReportForm.CustomerId = changeCustomerIdMisReport.CustomerId;
      return { ...newState };

    case 'CHANGE_CUSTOMER_NAME_MIS_REPORT_FORM':
      const changeCustomerNameMisReport =
        action as IChangeCustomerNameMisReport;
      newState.MisReportForm.CustomerName =
        changeCustomerNameMisReport.CustomerName;
      return { ...newState };

    case 'CHANGE_CUSTOMER_PRODUCT_ID_MIS_REPORT_FORM':
      const changeCustomerProductIdMisReport =
        action as IChangeCustomerProductIdMisReport;
      newState.MisReportForm.CustomerProductId =
        changeCustomerProductIdMisReport.CustomerProductId;
      return { ...newState };

    case 'CLEAR_CUSTOMER_ID_MIS_REPORT_FROM':
      newState.MisReportForm.CustomerProductId = [];
      return { ...newState };

    case 'CHANGE_RADIOBUTTON_CB_REPORT_FORM':
      const changeRadioButtonCB = action as IChangeRadioButtonCBReport;
      newState.CourierBonusReportForm.RadioButton =
        changeRadioButtonCB.RadioButton;
      return { ...newState };

    case 'CHANGE_FIRST_DATE_CB_REPORT_FORM':
      const changeFirstDateCB = action as IChangeFirstDateCBReport;
      newState.CourierBonusReportForm.FirstDate = changeFirstDateCB.FirstDate;
      return { ...newState };

    case 'CHANGE_LAST_DATE_CB_REPORT_FORM':
      const changeLastDateCB = action as IChangeLastDateCBReport;
      newState.CourierBonusReportForm.LastDate = changeLastDateCB.LastDate;
      return { ...newState };

    case 'CHANGE_BRANCH_CB_REPORT_FORM':
      const changeBranchCB = action as IChangeBranchCBReport;
      newState.CourierBonusReportForm.Branch = changeBranchCB.Branch;
      return { ...newState };

    case 'CHANGE_COURIER_CB_REPORT_FORM':
      const changeCourierCB = action as IChangeCourierCBReport;
      newState.CourierBonusReportForm.Courier = changeCourierCB.Courier;
      return { ...newState };

    case 'CHANGE_COURIER_CODE_CB_REPORT_FORM':
      const changeCourierCodeCB = action as IChangeCourierCodeCBReport;
      newState.CourierBonusReportForm.CourierCode =
        changeCourierCodeCB.CourierCode;
      return { ...newState };

    case 'CHANGE_FIRST_DATE_V_REPORT_FORM':
      const changeFirstDateV = action as IChangeFirstDateVReport;
      newState.ValidateReportForm.FirstDate = changeFirstDateV.FirstDate;
      return { ...newState };

    case 'CHANGE_RADIO_BUTTON_DELIVERY_V_REPORT_FORM':
      const changeRadioButtonDeliveryV =
        action as IChangeRadioButtonDeliveryVReport;
      newState.ValidateReportForm.RadioButtonDelivery =
        changeRadioButtonDeliveryV.RadioButtonDelivery;
      return { ...newState };

    case 'CHANGE_REPORT_TYPE_V_REPORT_FORM':
      const changeReportTypeV = action as IChangeReportTypeVReport;
      newState.ValidateReportForm.ReportType = changeReportTypeV.ReportType;
      return { ...newState };

    case 'CHANGE_LAST_DATE_V_REPORT_FORM':
      const changeLastDateV = action as IChangeLastDateVReport;
      newState.ValidateReportForm.LastDate = changeLastDateV.LastDate;
      return { ...newState };

    case 'CHANGE_BRANCH_V_REPORT_FORM':
      const changeBranchV = action as IChangeBranchVReport;
      newState.ValidateReportForm.Branch = changeBranchV.Branch;
      return { ...newState };

    case 'CHANGE_STATUS_V_REPORT_FORM':
      const changeStatusV = action as IChangeStatusVReport;
      newState.ValidateReportForm.Status = changeStatusV.Status;
      return { ...newState };

    case 'CHANGE_GEO_TAGGING_V_REPORT_FORM':
      const changeGeoTaggingV = action as IChangeGeoTaggingVReport;
      newState.ValidateReportForm.GeoTagging = changeGeoTaggingV.GeoTagging;
      return { ...newState };

    case 'CHANGE_FOTO_V_REPORT_FORM':
      const changeFotoV = action as IChangeFotoVReport;
      newState.ValidateReportForm.Foto = changeFotoV.Foto;
      return { ...newState };

    case 'CHANGE_SIGNATURE_V_REPORT_FORM':
      const changeSignatureV = action as IChangeSignatureVReport;
      newState.ValidateReportForm.Signature = changeSignatureV.Signature;
      return { ...newState };

    case 'CHANGE_MULTIPLE_SELECT_V_REPORT_FORM':
      const changeMultipleSelectV = action as IChangeMultipleSelectVReport;
      newState.ValidateReportForm.MultipleSelect =
        changeMultipleSelectV.MultipleSelect;
      return { ...newState };

    case 'CHANGE_RADIO_BUTTON_DATE_DW':
      const changeRadioButtonDw = action as IChangeRadioButtonDateDw;
      newState.DigitalWaybillReportForm.RadioButtonDate =
        changeRadioButtonDw.RadioButtonDate;
      return { ...newState };

    case 'CHANGE_FIRST_DATE_DW':
      const changeFirstDateDw = action as IChangeFirstDateDw;
      newState.DigitalWaybillReportForm.FirstDate = changeFirstDateDw.FirstDate;
      return { ...newState };

    case 'CHANGE_LAST_DATE_DW':
      const changeLastDateDw = action as IChangeLastDateDw;
      newState.DigitalWaybillReportForm.LastDate = changeLastDateDw.LastDate;
      return { ...newState };

    case 'CHANGE_CUSTOMER_DW':
      const changeCustomerDw = action as IChangeCustomerDw;
      newState.DigitalWaybillReportForm.Customer = changeCustomerDw.Customer;
      return { ...newState };

    case 'CHANGE_CUSTOMER_NAME_DW':
      const changeCustomerNameDw = action as IChangeCustomerNameDw;
      newState.DigitalWaybillReportForm.CustomerName =
        changeCustomerNameDw.CustomerName;
      return { ...newState };

    case 'CHANGE_CUSTOMER_PRODUCT_DW':
      const changeCustomerProductDw = action as IChangeCustomerProductDw;
      newState.DigitalWaybillReportForm.CustomerProduct =
        changeCustomerProductDw.CustomerProduct;
      return { ...newState };

    case 'CHANGE_DELIVERY_TYPE_DW':
      const changeDeliveryTypeDw = action as IChangeDeliveryTypeDw;
      newState.DigitalWaybillReportForm.DeliveryType =
        changeDeliveryTypeDw.DeliveryType;
      return { ...newState };

    case 'CHANGE_DELIVERY_STATUS_DW':
      const changeDeliveryStatusDw = action as IChangeDeliveryStatusDw;
      newState.DigitalWaybillReportForm.DeliveryStatus =
        changeDeliveryStatusDw.DeliveryStatus;
      return { ...newState };

    case 'CHANGE_FILE_CSV_DW':
      const changeFileCsvDw = action as IChangeFileCsvDw;
      newState.DigitalWaybillReportForm.FileCsvDw = changeFileCsvDw.FileCsvDw;
      return { ...newState };

    case 'CHANGE_CB_BARCODE_DW':
      newState.DigitalWaybillReportForm.CheckboxBarcode =
        !newState.DigitalWaybillReportForm.CheckboxBarcode;
      return { ...newState };

    case 'CHANGE_CB_BARCODE_REVERSE_DW':
      newState.DigitalWaybillReportForm.CheckboxBarcodeReverse =
        !newState.DigitalWaybillReportForm.CheckboxBarcodeReverse;
      return { ...newState };

    case 'CHANGE_TYPE_OF_NAME_DW':
      const changeTypeOfName = action as IChangeTypeOfNameDw;
      newState.DigitalWaybillReportForm.TypeOfName =
        changeTypeOfName.TypeOfName;
      return { ...newState };

    case 'CHANGE_FILE_NAME_DW':
      const changeFileNameDw = action as IChangeFileNameDw;
      newState.DigitalWaybillReportForm.FileName = changeFileNameDw.FileName;
      return { ...newState };

    case 'CHANGE_FLAG_FILE_NAME_DW':
      const changeFlagFileNameDw = action as IChangeFlagFileNameDw;
      newState.DigitalWaybillReportForm.FlagFileName =
        changeFlagFileNameDw.FlagFileName;
      return { ...newState };

    case 'CHANGE_FLAG_FILE_NAME_UPLOAD_DW':
      const changeFlagFileNameUploadDw = action as IChangeFlagFileNameUploadDw;
      newState.DigitalWaybillReportForm.FlagFileNameUpload =
        changeFlagFileNameUploadDw.FlagFileNameUpload;
      return { ...newState };

    case 'CHANGE_COUNT_SUCCESS_DW':
      const changeCountSuccessDw = action as IChangeCountSuccessDw;
      newState.DigitalWaybillReportForm.CountSuccess =
        changeCountSuccessDw.CountSuccess;
      return { ...newState };

    case 'CHANGE_COUNT_FAILED_DW':
      const changeCountFailedDw = action as IChangeCountFailedDw;
      newState.DigitalWaybillReportForm.CountFailed =
        changeCountFailedDw.CountFailed;
      return { ...newState };

    case 'CLEAR_FORM_BY_UPLOAD_DW':
      newState.DigitalWaybillReportForm.FileCsvDw = null;
      newState.DigitalWaybillReportForm.CheckboxBarcode = false;
      newState.DigitalWaybillReportForm.CheckboxBarcodeReverse = false;
      newState.DigitalWaybillReportForm.FileName = null;
      newState.DigitalWaybillReportForm.FlagFileNameUpload = null;
      newState.DigitalWaybillReportForm.CountSuccess = null;
      newState.DigitalWaybillReportForm.CountFailed = null;
      return { ...newState };

    case 'CLEAR_FORM_DW':
      newState.DigitalWaybillReportForm.RadioButtonDate = 'Cycle';
      newState.DigitalWaybillReportForm.FirstDate = null;
      newState.DigitalWaybillReportForm.LastDate = null;
      newState.DigitalWaybillReportForm.Customer = null;
      newState.DigitalWaybillReportForm.CustomerName = null;
      newState.DigitalWaybillReportForm.TypeOfName = null;
      newState.DigitalWaybillReportForm.CustomerProduct = [];
      newState.DigitalWaybillReportForm.DeliveryType = [];
      newState.DigitalWaybillReportForm.DeliveryStatus = [];
      newState.DigitalWaybillReportForm.FlagFileName = null;
      return { ...newState };

    case 'CLEAR_CUSTOMER_PRODUCT_DW':
      newState.DigitalWaybillReportForm.CustomerProduct = [];
      return { ...newState };

    case 'CLEAR_BRANCH_V_REPORT':
      newState.ValidateReportForm.Branch = [];
      return { ...newState };

    case 'CLEAR_VALIDATE_REPORT_FROM':
      newState.ValidateReportForm.RadioButtonDelivery = true;
      newState.ValidateReportForm.ReportType = 'Summary';
      newState.ValidateReportForm.FirstDate = null;
      newState.ValidateReportForm.LastDate = null;
      newState.ValidateReportForm.Branch = [];
      newState.ValidateReportForm.Status = null;
      newState.ValidateReportForm.GeoTagging = false;
      newState.ValidateReportForm.Foto = false;
      newState.ValidateReportForm.Signature = false;
      newState.ValidateReportForm.MultipleSelect = false;
      return { ...newState };

    case 'CLEAR_COURIER_BONUS_REPORT_FROM':
      newState.CourierBonusReportForm.RadioButton = 'Detail';
      newState.CourierBonusReportForm.FirstDate = null;
      newState.CourierBonusReportForm.LastDate = null;
      newState.CourierBonusReportForm.Branch = null;
      newState.CourierBonusReportForm.Courier = [];
      newState.CourierBonusReportForm.CourierCode = null;
      return { ...newState };

    case 'CLEAR_COURIER_BONUS_BRANCH_REPORT_FROM':
      newState.CourierBonusReportForm.RadioButton = 'Detail';
      newState.CourierBonusReportForm.FirstDate = null;
      newState.CourierBonusReportForm.LastDate = null;
      newState.CourierBonusReportForm.Courier = [];
      newState.CourierBonusReportForm.CourierCode = null;
      return { ...newState };

    case 'CLEAR_COURIER_BONUS_REPORT_FROM_EXCEPT_RADIOBUTTON':
      newState.CourierBonusReportForm.FirstDate = null;
      newState.CourierBonusReportForm.LastDate = null;
      newState.CourierBonusReportForm.Branch = null;
      newState.CourierBonusReportForm.Courier = [];
      newState.CourierBonusReportForm.CourierCode = null;
      return { ...newState };

    case 'CLEAR_COURIER_BONUS_BRANCH_REPORT_FROM_EXCEPT_RADIOBUTTON':
      newState.CourierBonusReportForm.FirstDate = null;
      newState.CourierBonusReportForm.LastDate = null;
      newState.CourierBonusReportForm.Courier = [];
      newState.CourierBonusReportForm.CourierCode = null;
      return { ...newState };

    case 'CLEAR_COURIER_CB_REPORT_FORM':
      newState.CourierBonusReportForm.Courier = [];
      return { ...newState };

    case 'CLEAR_MIS_REPORT_FROM':
      newState.MisReportForm.RadioButton = 'mis';
      newState.MisReportForm.StartDate = null;
      newState.MisReportForm.FinishDate = null;
      newState.MisReportForm.District = ['Jabodetabek'];
      newState.MisReportForm.CustomerId = null;
      newState.MisReportForm.CustomerName = null;
      newState.MisReportForm.CustomerProductId = [];
      return { ...newState };

    case 'CLEAR_CUSTOMER_ID_CUSTOMER_REPORT_FROM':
      newState.CustomerReportForm.CustomerProductId = [];
      return { ...newState };

    case 'CLEAR_REPORT_FORMAT_CUSTOMER_REPORT_FROM':
      newState.CustomerReportForm.ReportFormat = null;
      return { ...newState };

    case 'CLEAR_LIST_STATUS_DELIVERY_CUSTOMER_REPORT_FROM':
      newState.CustomerReportForm.StatusSuccess = [];
      newState.CustomerReportForm.ListStatusSuccess = [];
      newState.CustomerReportForm.StatusReturn = [];
      newState.CustomerReportForm.ListStatusReturn = [];
      return { ...newState };

    case 'CLEAR_CUSTOMER_REPORT_FROM':
      newState.CustomerReportForm.RadioButton = 'Cycle';
      newState.CustomerReportForm.PickupDate = null;
      newState.CustomerReportForm.DatePickerMonth = false;
      newState.CustomerReportForm.CustomerId = null;
      newState.CustomerReportForm.CustomerName = null;
      newState.CustomerReportForm.CustomerProductId = [];
      newState.CustomerReportForm.ReportFormat = null;
      newState.CustomerReportForm.DeliveryStatus = [
        'OnProcces',
        'Success',
        'Return',
      ];
      newState.CustomerReportForm.TreatmentDocument = [
        'ReturnCustomer',
        'Destroy',
      ];
      newState.CustomerReportForm.ListStatusSuccess = [];
      newState.CustomerReportForm.StatusSuccess = [];
      newState.CustomerReportForm.ListStatusReturn = [];
      newState.CustomerReportForm.StatusReturn = [];
      newState.CustomerReportForm.TypeDelivery = ['reguler'];
      newState.CustomerReportForm.CheckBoxFileName = false;
      newState.CustomerReportForm.CheckBoxFullData = false;
      newState.CustomerReportForm.CheckBoxToDayData = false;
      return { ...newState };

    case 'CLEAR_ALL_FORM_INVOICE':
      newState.InvoiceReport.CycleDateStart = null;
      newState.InvoiceReport.CheckboxIsMonth = false;
      newState.InvoiceReport.RadiobuttonIsDetailInvoice = false;
      newState.InvoiceReport.CustomerId = null;
      newState.InvoiceReport.CustomerProductId = [];
      return { ...newState };

    case 'CLEAR_CUSTOMER_PRODUCT_ID':
      newState.InvoiceReport.CustomerProductId = [];
      return { ...newState };

    case 'CLEAR_COURIER_R':
      newState.CourierReportForm.CourierR = null;
      return { ...newState };

    case 'CLEAR_CUSTOMER_PRODUCT_R_FROM':
      newState.CourierReportForm.CustomerProductIdR = [];
      return { ...newState };

    case 'CLEAR_COURIER_R_FORM':
      newState.CourierReportForm.DeliveryDateCourierR = null;
      newState.CourierReportForm.RadioButtonCourierR = 'Process';
      newState.CourierReportForm.BranchCourierR = [];
      newState.CourierReportForm.CourierR = null;
      newState.CourierReportForm.StatusR = null;
      newState.CourierReportForm.FormatFileR = 'xls';
      newState.CourierReportForm.CustomerIdR = null;
      newState.CourierReportForm.CustomerProductIdR = [];
      newState.CourierReportForm.RingkasanR = false;
      newState.CourierReportForm.SlaR = false;
      return { ...newState };

    case 'CLEAR_COURIER_BRANCH_R_FORM':
      newState.CourierReportForm.DeliveryDateCourierR = null;
      newState.CourierReportForm.RadioButtonCourierR = 'Process';
      newState.CourierReportForm.CourierR = null;
      newState.CourierReportForm.StatusR = null;
      newState.CourierReportForm.FormatFileR = 'xls';
      newState.CourierReportForm.CustomerIdR = null;
      newState.CourierReportForm.CustomerProductIdR = [];
      newState.CourierReportForm.RingkasanR = false;
      newState.CourierReportForm.SlaR = false;
      return { ...newState };

    case 'CLEAR_ALL_FORM_DR':
      newState.DeliveryReportForm.CycleDateStart = null;
      newState.DeliveryReportForm.CycleDateLast = null;
      newState.DeliveryReportForm.CustomerId = [];
      newState.DeliveryReportForm.BranchId = [];
      newState.DeliveryReportForm.CheckBoxToday = false;
      return { ...newState };
  }
  return state;
}
