import React, { Component } from 'react'

import ButtonMaterial from '../../../../materials/ButtonMaterial';

interface IProps {
    handleChangeAndPopUpModal:any
    handleChangeAndPopUpModal2:any
    typeButton:any
}

export default class ButtonDownload extends Component <IProps> {
    render() {
        return (
            <div>
                {this.props.typeButton==='download'?
                <ButtonMaterial
                    disabled={''}
                    handleSubmit={this.props.handleChangeAndPopUpModal}
                    teksButton={''}
                    size={''}
                    shape={''}
                    className={''}
                    type={'primary'}
                    icon={'select'}
                    style={{
                    float: 'right',
                    borderRadius: '8px',
                    color: '#fafafa',
                    paddingBottom: '3px',
                    background: '#7956EE',
                    borderColor: '#7956EE',
                    }}
                />
                :
                <ButtonMaterial
                    disabled={''}
                    handleSubmit={this.props.handleChangeAndPopUpModal2}
                    teksButton={''}
                    size={''}
                    shape={''}
                    className={''}
                    type={'primary'}
                    icon={'calendar'}
                    style={{
                    float: 'right',
                    borderRadius: '8px',
                    color: '#fafafa',
                    paddingBottom: '3px',
                    background: '#FCC642',
                    borderColor: '#FCC642',
                    }}
                />
                }
            </div>
        )
    }
}
