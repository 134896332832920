import React from 'react';
import { Spin } from 'antd';

interface IProps {
  spinning: any;
  size: any;
  content: any;
}

export default function CSpin(props: IProps) {
  const { spinning, size, content } = props;
  return (
    <Spin spinning={spinning} size={size}>
      {content}
    </Spin>
  );
}
