import * as ActionAuth from '../../action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionProgressCourier from '../../action/Beranda/ActionProgressCourier';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../action/toggle';

import axios from 'axios';
import moment from 'moment';

export function* S_getProgressCourier(action: any) {
  try {
    yield put(changeLoadingTrue());
    const state = yield select();
    const token = state.Auth.Token;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-type': 'application/json',
    };
    const statusTokenRefresh = state.Auth.StatusTokenRefresh;
    const BranchId = state.Auth.BranchAuth;
    const StartDate = moment(state.Beranda.ProgressCourier.StartDate).format(
      'YYYYMMDD'
    );

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Courier/monitor/' +
        BranchId +
        '/' +
        StartDate,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionProgressCourier.setListProgressCourier(data));
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(changeLoadingFalse());
  }
}

export function* SM_getProgressCourier() {
  yield takeLatest('GET_DATA_PROGRESS_COURIER', S_getProgressCourier);
}
