import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionPagination from '../../../action/Pagination/ActionPagination';
import * as actionFormUnggahCsv from '../../../action/Delivery/Document/ActionFormUnggahDocument';
import * as actionListCustomer from '../../../action/Customer/ActionListCustomer';
import * as actionUnggahDocument from '../../../action/Delivery/Document/ActionFormUnggahDocument';
import * as actionListCustomerProduct from '../../../action/CustomerProduct/ActionListCustomerProduct';
import * as actionStep from '../../../action/LastAccess/index';
import * as toggle from '../../../action/toggle/index';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import React from 'react';
import axios from 'axios';
import moment from 'moment';

export function* S_UploadCsv(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const CustomerProductId = state.Delivery.UnggahDocument.CustomerProductId;
  const Csv = state.Delivery.UnggahDocument.csv;
  const CustomCsvCustomer = state.Delivery.UnggahDocument.CustomCsvCustomer;
  const DeliveryDate = moment(
    state.Delivery.UnggahDocument.DeliveryDate
  ).format('YYYY-MM-DDTHH:mm:ss.SSSz');
  const UploadDate = state.Delivery.UnggahDocument.UploadDate;
  const FileName = state.Delivery.UnggahDocument.FileName;
  let DateNow = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
  const postDate =
    UploadDate !== null
      ? UploadDate.substring(0, 11) + DateNow.substring(11, 19)
      : UploadDate;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;

  try {
    yield put(toggle.changeLoadingTrue());
    const response = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Delivery/upload`,
      {
        fileCsv: Csv,
        deliveryTime: DeliveryDate === 'Invalid date' ? null : DeliveryDate,
        customerProductId: CustomerProductId,
        customerCsvId: CustomCsvCustomer,
        fileName: FileName,
        processTime: UploadDate === null ? DateNow : postDate,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.changeLoadingFalse());
    yield put(actionUnggahDocument.fetchDocumentId(response.data.id));
    yield put(actionUnggahDocument.getDetailDocumentStatusUpload());
    yield put(toggle.changeModal2());
    yield put(toggle.changeJenisToggle2('DetailStatusUpload'));

    yield put(actionUnggahDocument.clearAllFormUnggahCsv());
    yield put(actionListCustomer.clearListCustomer());
    yield put(actionListCustomerProduct.clearListCustomerProduct());

    yield put(ActionPagination.setPagePagination(0));
    yield put(ActionPagination.setTotalPagePagination(1));
    yield put(actionUnggahDocument.getListDataStatusUpload());

    yield put(actionFormUnggahCsv.changeCountSuccess(response.data.success));
    yield put(actionFormUnggahCsv.changeCountFailed(response.data.failed));
    yield put(
      actionFormUnggahCsv.changeResultUploadData(
        response.data.success !== 0 ? 'Berhasil Mengunggah' : 'Gagal Mengunggah'
      )
    );
    yield put(
      actionFormUnggahCsv.changeListResultUploadData(
        response.data.listResultCsv
      )
    );
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_changeNextUploadCsv() {
  yield put(actionFormUnggahCsv.clearAllFormUnggahCsv());
  yield put(actionListCustomer.clearListCustomer());
  yield put(actionListCustomerProduct.clearListCustomerProduct());
  yield put(toggle.changeLoadingFalse());
  yield put(toggle.clearJenisToggle());
  yield put(actionStep.changeStep(1));
  yield put(actionStep.changeLastAccessFiture('deliveryDistribution'));
}

export function* S_DownloadResultUploadCsv(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const list = state.Delivery.UnggahDocument.ListResultUploadData;
  const UploadDate = state.Delivery.UnggahDocument.UploadDate;
  const key = 'download';

  const params = {
    listResult: list,
  };
  try {
    Modal.info({
      title: <p style={{ color: '#11beff' }}>{<b>Download Data</b>}</p>,
      content: (
        <div>
          <p>Sedang mendownload data, mohon tunggu</p>
        </div>
      ),
    });
    axios({
      url: `${process.env.REACT_APP_URL}/Delivery/upload/downloadResult`,
      method: 'POST',
      responseType: 'blob',
      data: params,
      headers: config,
      onDownloadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        let percentProgress = Math.floor((loaded * 100) / total);

        if (percentProgress < 100) {
          message.loading({
            content: 'Loading ' + percentProgress + '%',
            key,
          });
        } else if (percentProgress === 100) {
          Modal.destroyAll();
          message.success({
            content: 'Download Berhasil',
            key,
            duration: 1.5,
          });
        } else if (percentProgress === Infinity) {
          Modal.destroyAll();
          message.error({
            content: 'Download Gagal',
            key,
            duration: 3,
          });
        }
      },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Result Upload Softcopy (${moment(UploadDate).format(
          'YYYY-MM-DD'
        )}).txt`
      );
      document.body.appendChild(link);
      link.click();
    });
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* S_getDataStatusUpload(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const pagePagination = state.Pagination.PagePagination - 1;
  const sizeDataPagination = state.Pagination.SizeDataPagination;
  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Delivery/Dokumen/StatusUnggah/${pagePagination}/${sizeDataPagination}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(actionFormUnggahCsv.fetchListDataStatusUpload(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}

export function* S_getDetailDocumentStatusUpload(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const documentId = state.Delivery.StatusUploadDocument.documentId;
  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Delivery/Dokumen/StatusUnggah/Detail?id=${documentId}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(actionFormUnggahCsv.fetchDetailStatusUploadDocument(data));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}

export function* SM_changeNextUploadCsv() {
  yield takeLatest('CHANGE_NEXT_UPLOAD_CSV', S_changeNextUploadCsv);
}

export function* SM_DownloadResultUploadCsv() {
  yield takeLatest('DOWNLOAD_RESULT_UPLOAD_CSV', S_DownloadResultUploadCsv);
}

export function* SM_UploadCsv() {
  yield takeLatest('UPLOAD_CSV', S_UploadCsv);
}
export function* SM_getDataStatusUpload() {
  yield takeLatest(
    'GET_LIST_DATA_STATUS_UPLOAD_DOCUMENT',
    S_getDataStatusUpload
  );
}
export function* SM_getDetailDocumentStatusUpload() {
  yield takeLatest(
    'GET_DETAIL_DOCUMENT_STATUS_UPLOAD_DOCUMENT',
    S_getDetailDocumentStatusUpload
  );
}
