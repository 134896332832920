import React from 'react';
import ButtonMaterial from '../../../materials/ButtonMaterial';

interface IProps {
  handleEditHoliday: any;
}

export default function ButtonEditHolidayComponent({
  handleEditHoliday,
}: IProps) {
  return (
    <div>
      <ButtonMaterial
        disabled={''}
        handleSubmit={handleEditHoliday}
        teksButton={''}
        size={''}
        shape={''}
        className={'btnEditH'}
        type={''}
        icon={'edit'}
        style={{}}
      />
    </div>
  );
}
