import React from 'react';
import { Form, Upload, Button } from 'antd';

type InputFileProps = {
  validateFile?:
    | ''
    | 'error'
    | 'success'
    | 'warning'
    | 'validating'
    | undefined;
  required?: boolean;
  handleChangeFile?: (e: any) => void;
  value?: any;
  label: string;
  accept?: string;
  placeholder?: string;
  error?: string;
  extra?: string;
};

export default function InputFile({
  required = false,
  validateFile,
  handleChangeFile = () => undefined,
  value,
  label,
  accept,
  placeholder = 'Click to Upload',
  error = 'File Wajib diisi',
  extra,
}: InputFileProps) {
  return (
    <div>
      <span>
        <b>{label}</b>
      </span>
      <Form.Item
        validateStatus={required ? validateFile : ''}
        help={required && validateFile === 'error' ? error : ''}
        style={{ margin: 0 }}
        extra={extra}
      >
        <Upload
          accept={accept}
          onChange={e => handleChangeFile(e)}
          onBlur={e => handleChangeFile(e)}
          value={value || ''}
          showUploadList={false}
        >
          <Button
            icon={'upload'}
            style={{
              width: '100%',
              height: 32,
              borderRadius: 10,
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'center',
              justifyContent: 'space-between',
              overflow: 'hidden',
            }}
          >
            {value ? value : placeholder}
          </Button>
        </Upload>
      </Form.Item>
    </div>
  );
}
