import React from 'react';
import '../../../styles/TableStyle/index.css';

import { Col, Row, Table } from 'antd';
import ButtonMaterial from '../../../materials/ButtonMaterial';
import CardMaterial from '../../../materials/CardMaterial';
import SearchMatrial from '../../../materials/SearchMatrial';
import SpinMaterial from '../../../materials/SpinMaterial';
import FormManualHoliday from '../Container/FormManualHolidayContainer';
import ButtonEditHoliday from '../Container/ButtonEditHolidayContainer';
import moment from 'moment';
import { HolidayProps } from '../Interface';

export default function HolidayComponent(props: HolidayProps) {
  const columns = [
    {
      title: 'Tanggal',
      dataIndex: 'date',
      render: (date: any) => (
        <span className="redColor">{moment(date).format('DD-MM-YYYY')}</span>
      ),
      key: 'date',
      width: 120,
    },
    {
      title: 'Tipe',
      dataIndex: 'holidayType',
      render: (date: any) => (
        <span>
          {date === 0 ? 'Custom Mingguan' : date === 1 ? 'Custom' : 'Mingguan'}
        </span>
      ),
      key: 'holidayType',
      width: 110,
    },
    {
      title: 'Libur Event',
      dataIndex: 'eventName',
      render: (holidayEvent: any) => (
        <span className="redColor">{holidayEvent}</span>
      ),
      key: 'eventName',
      width: 120,
    },
    {
      title: 'Status',
      dataIndex: 'isDisabled',
      render: (isDisabled: any) => (
        <span
          className={isDisabled === false ? 'lightBlueColor' : 'teksInactive'}
        >
          {isDisabled === false ? 'Aktif' : 'Tidak Aktif'}
        </span>
      ),
      key: 'eventName',
      width: 120,
    },
    {
      title: 'Aksi',
      key: 'aksi',
      width: 80,
      render: (record: any) => (
        <div>
          <ButtonEditHoliday idHoliday={record.id} />
        </div>
      ),
    },
  ];
  return (
    <div className="paddingTable">
      <FormManualHoliday refetch={props.refetch} />
      <SpinMaterial
        spinning={props.isLoading}
        style={{}}
        size={'large'}
        content={
          <div>
            <CardMaterial
              extra={''}
              title={''}
              style={{}}
              content={
                <div>
                  <Row>
                    <Col span={16}>
                      <div>
                        <span className="titleSearchCard">
                          MODUL HARI LIBUR
                        </span>
                        <SearchMatrial
                          value={props.ValueSearch}
                          style={{ borderRadius: '10px', width: '50%' }}
                          placeholder={'Cari hari libur'}
                          onChange={props.handleSearch}
                        />
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="floatRight">
                        <ButtonMaterial
                          disabled={''}
                          handleSubmit={props.handleModal}
                          teksButton={<b>TAMBAHKAN HARI LIBUR</b>}
                          size={''}
                          shape={''}
                          className={'btnLink'}
                          type={'link'}
                          icon={''}
                          style={{}}
                        />

                        <ButtonMaterial
                          disabled={''}
                          handleSubmit={props.handleModal}
                          teksButton={''}
                          size={''}
                          shape={'circle'}
                          className={'btnAdd'}
                          type={''}
                          icon={'plus'}
                          style={{}}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              }
            ></CardMaterial>
            <Table
              pagination={{
                onChange: page => {
                  props.handlePage(page);
                },
                pageSize: 10,
                total: props.TotalPagePagination,
                current: props.PagePagination === 0 ? 1 : props.PagePagination,
              }}
              bordered={false}
              dataSource={props.DataTableHoliday.filter(
                (data: any) =>
                  data.date
                    .toString()
                    .toLowerCase()
                    .includes(props.ValueSearch) ||
                  data.eventName
                    .toString()
                    .toLowerCase()
                    .includes(props.ValueSearch)
              )}
              columns={columns}
            />
          </div>
        }
      ></SpinMaterial>
    </div>
  );
}
