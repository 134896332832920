export function setAllAdminBranch(AdminBranchAll:any)
{
    return({
        type:"SET_ALL_ADMIN_BRANCH",
        AdminBranchAll
    })
}
export function clearAllAdminBranch()
{
    return({
        type:"CLEAR_ALL_ADMIN_BRANCH"
    })
}