import React, { Component } from 'react';
import DetailComponentMatrial from '../../../materials/DetailComponentMatrial';
import FormDetailCustomerComponent from './FormDetailCustomerComponent';
import CustomerProductContainer from '../../../containers/Content/Customer/Product/CustomerProductContainer';
import ButtonMaterial from '../../../materials/ButtonMaterial';

interface IProps {
  CustomerName: any;
  CustomerCode: any;
  CustomerType: any;
  CustomerTelephone: any;
  CustomerEmail: any;
  CustomerAddress: any;
  CustomerAccess: any;
  isLoading: any;
  typeToggle: any;
  handleBackDetail: any;
  handleEditDetail: any;
  handleCustomerConfig: any;
}
export default class CustomerDetailComponent extends Component<IProps> {
  render() {
    return (
      <div
        style={{
          paddingTop: '35px',
          paddingLeft: '35px',
          paddingRight: '35px',
        }}
      >
        <div>
          <DetailComponentMatrial
            Deskripsi={''}
            isLoading={this.props.isLoading}
            Modul={'PELANGGAN'}
            ButtonHeader={
              <div>
                <ButtonMaterial
                  disabled={''}
                  handleSubmit={this.props.handleCustomerConfig}
                  teksButton={'Pengaturan'}
                  size={''}
                  shape={'round'}
                  className={''}
                  type={'primary'}
                  icon={''}
                  style={{
                    width: '100px',
                    color: '#fafafa',
                    background: '#A52A2A',
                    borderColor: '#A52A2A',
                  }}
                />
                <ButtonMaterial
                  disabled={''}
                  handleSubmit={this.props.handleEditDetail}
                  teksButton={'Ubah'}
                  size={''}
                  shape={'round'}
                  className={''}
                  type={'primary'}
                  icon={''}
                  style={{
                    width: '100px',
                    marginLeft: '10px',
                    marginRight: '10px',
                    color: '#fafafa',
                    background: '#FCC642',
                    borderColor: '#FCC642',
                  }}
                />
                <ButtonMaterial
                  disabled={''}
                  handleSubmit={this.props.handleBackDetail}
                  teksButton={'Kembali'}
                  size={''}
                  shape={'round'}
                  className={''}
                  type={'primary'}
                  icon={''}
                  style={{
                    width: '100px',
                    color: '#fafafa',
                    background: '#F5487D',
                    borderColor: '#F5487D',
                  }}
                />
              </div>
            }
            formText={
              <FormDetailCustomerComponent
                CustomerName={this.props.CustomerName}
                CustomerCode={this.props.CustomerCode}
                CustomerType={this.props.CustomerType}
                CustomerTelephone={this.props.CustomerTelephone}
                CustomerEmail={this.props.CustomerEmail}
                CustomerAddress={this.props.CustomerAddress}
                CustomerAccess={this.props.CustomerAccess}
              />
            }
          />
          <div style={{ paddingTop: '35px' }}>
            <CustomerProductContainer />
          </div>
        </div>
      </div>
    );
  }
}
