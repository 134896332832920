import React, { useState, useEffect } from 'react';

import DetailLevelFormComponent from '../Component/DetailLevelFormComponent';
import history from '../../../../core/utils/History';
import { getLevelGroupSingle } from 'services/LevelAccessServices';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

function DetailLevelFromContainer() {
  const { id }: { id: string } = useParams();

  const { data, isLoading: isLoadingSingle } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataLevelGroupSingle', { id }],
    queryFn: ({ queryKey }) => getLevelGroupSingle(queryKey),
  });

  const defaultDataLevel = {
    levelName: '',
    accountType: '',
    isActive: true,
    menus: [],
  };
  const [dataLevel, setDataLevel] = useState<any>(defaultDataLevel);
  const [dataMenus, setDataAllMenus] = useState<Array<any>>([]);

  useEffect(() => {
    if (data) {
      setDataLevel({
        levelName: data?.data?.levelName || '',
        accountType: data?.data?.accountType || '',
        isActive: data?.data?.isActive,
        menus: data?.data?.selected || [],
      });
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const dataAllMenus = data?.data?.menus || [];
      setDataAllMenus(dataAllMenus || []);
      const dataMenus = [] as any;
      dataAllMenus.forEach(menu => {
        menu['disableCheckbox'] = true;
        if (menu?.tabMenus?.length > 0) {
          menu?.tabMenus.map(tabmenu => {
            tabmenu['disableCheckbox'] = true;
          });
        }
        if (menu?.subMenus?.length > 0) {
          menu?.subMenus.map(submenu => {
            submenu['disableCheckbox'] = true;
            if (submenu?.tabMenus?.length > 0) {
              submenu?.tabMenus.map(tabmenu => {
                tabmenu['disableCheckbox'] = true;
              });
            }
          });
        }
        dataMenus.push(menu);
      });
      setDataAllMenus(dataMenus);
    }
  }, [data]);

  const handleBtnBack = () => {
    history.goBack();
  };

  return (
    <DetailLevelFormComponent
      handleBtnBack={handleBtnBack}
      dataAllMenus={dataMenus || []}
      isLoadingMenus={isLoadingSingle}
      dataLevel={dataLevel}
    />
  );
}

export default DetailLevelFromContainer;
