import { Col, DatePicker, Form, Row, Select } from 'antd';

import ButtonMaterial from 'materials/ButtonMaterial';
import CardMaterial from 'materials/CardMaterial';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';

interface IProps {
  loadingSelectCustomer: any;
  handlePutFirstDate: any;
  handleValidateFirstDate: any;
  valueFirstDate: any;
  getFirstDate: any;
  validateFirstDate: any;
  helpFirstDate: any;
  handlePutLastDate: any;
  handleValidateLastDate: any;
  getLastDate: any;
  valueLastDate: any;
  validateLastDate: any;
  helpLastDate: any;
  handlePutCustomer: any;
  handleBlurCustomer: any;
  handleClearCustProduct: any;
  getCustomer: any;
  listCustomer: any;
  handleGetCustomer: any;
  validateCustomer: any;
  helpCustomer: any;
  loadingSelectCustomerProduct: any;
  listCustomerProduct: any;
  handlePutCustomerProduct: any;
  handleGetCustomerProduct: any;
  getCustomerProduct: any;
  handleBlurCustomerProduct: any;
  validateCustomerProduct: any;
  helpCustomerProduct: any;
  validateDownloadButton: any;
  handleDownload: any;
  validateResetButton: any;
  handleReset: any;
  isLoading: any;
}

export default function BastReportComponent({
  loadingSelectCustomer,
  handlePutFirstDate,
  handleValidateFirstDate,
  valueFirstDate,
  getFirstDate,
  validateFirstDate,
  helpFirstDate,
  handlePutLastDate,
  handleValidateLastDate,
  getLastDate,
  valueLastDate,
  validateLastDate,
  helpLastDate,
  handlePutCustomer,
  handleBlurCustomer,
  handleClearCustProduct,
  getCustomer,
  listCustomer,
  handleGetCustomer,
  validateCustomer,
  helpCustomer,
  loadingSelectCustomerProduct,
  listCustomerProduct,
  handlePutCustomerProduct,
  handleGetCustomerProduct,
  getCustomerProduct,
  handleBlurCustomerProduct,
  validateCustomerProduct,
  helpCustomerProduct,
  validateDownloadButton,
  handleDownload,
  validateResetButton,
  handleReset,
  isLoading,
}: IProps) {
  const { Option } = Select;
  const formatDate = 'YYYY-MM-DD';
  const formatDateShow = 'DD-MM-YYYY';
  const minDate2 = (d: any) =>
    !d || d.isAfter(moment(new Date()).add(1, 'day').format(formatDate));
  const minDate = (d: any) =>
    !d ||
    d.isBefore(moment(getFirstDate).add(0, 'day').format(formatDate)) ||
    d.isAfter(moment(getFirstDate).add(30, 'day').format(formatDate));
  const validateProductForm = getCustomer === null ? true : false;
  return (
    <React.Fragment>
      <SpinMaterial
        spinning={isLoading}
        style={{}}
        size={'large'}
        content={
          <React.Fragment>
            <CardMaterial
              extra={''}
              title={<b className="titleCard">BAST SMS</b>}
              style={{ borderRadius: '10px' }}
              content={
                <React.Fragment>
                  <Row>
                    <Col span={12} className="spacingRight">
                      <div>
                        <span>
                          <b>Tanggal Cycle</b>
                        </span>
                        <Form.Item
                          validateStatus={validateFirstDate}
                          help={helpFirstDate}
                        >
                          <DatePicker
                            className={'datePickerDefault'}
                            locale={locale}
                            format={formatDateShow}
                            placeholder={'Pilih tanggal'}
                            onOpenChange={handleValidateFirstDate}
                            value={
                              valueFirstDate ? valueFirstDate : getFirstDate
                            }
                            onChange={handlePutFirstDate}
                            disabledDate={minDate2}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <span>
                          <b>Pelanggan</b>
                        </span>
                        <Form.Item
                          validateStatus={validateCustomer}
                          help={helpCustomer}
                        >
                          <Select
                            loading={loadingSelectCustomer}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            showSearch
                            placeholder={'Pilih Pelanggan'}
                            onSelect={handleClearCustProduct}
                            onChange={handlePutCustomer}
                            onBlur={handleBlurCustomer}
                            value={
                              getCustomer === null ? (
                                <span className="colorPlaceholderDropDown">
                                  Pilih Pelanggan
                                </span>
                              ) : (
                                getCustomer
                              )
                            }
                            onFocus={handleGetCustomer}
                          >
                            {listCustomer.map((data: any, index: any) => (
                              <Option key={index} value={data.id}>
                                {data.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12} className="spacingLeft">
                      <div>
                        <span>
                          <b>Sampai Tanggal</b>
                        </span>
                        <Form.Item
                          validateStatus={validateLastDate}
                          help={helpLastDate}
                        >
                          <DatePicker
                            className={'datePickerDefault'}
                            locale={locale}
                            format={formatDateShow}
                            placeholder={'Pilih tanggal'}
                            onOpenChange={handleValidateLastDate}
                            defaultPickerValue={moment(
                              getFirstDate,
                              formatDate
                            )}
                            value={valueLastDate ? valueLastDate : getLastDate}
                            onChange={handlePutLastDate}
                            disabledDate={minDate}
                            disabled={
                              getFirstDate === null || getFirstDate === ''
                                ? true
                                : false
                            }
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <span>
                          <b>Produk</b>
                        </span>
                        <Form.Item
                          validateStatus={validateCustomerProduct}
                          help={helpCustomerProduct}
                        >
                          <Select
                            loading={loadingSelectCustomerProduct}
                            mode="multiple"
                            placeholder="Pilih Produk Pelanggan"
                            showSearch
                            allowClear={true}
                            style={{ width: '100%' }}
                            disabled={validateProductForm}
                            onFocus={handleGetCustomerProduct}
                            onSelect={handleBlurCustomerProduct}
                            onBlur={handlePutCustomerProduct}
                            onChange={handlePutCustomerProduct}
                            value={getCustomerProduct}
                          >
                            {getCustomerProduct.length === 0 ? (
                              <Option key={''} value={'Pilih Semua Produk'}>
                                Pilih Semua Produk
                              </Option>
                            ) : null}
                            {listCustomerProduct.map(
                              (data: any, index: any) => (
                                <Option key={index} value={data.id}>
                                  {data.name}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className={'spacingDownloadButton'}>
                      <ButtonMaterial
                        disabled={validateDownloadButton}
                        handleSubmit={handleDownload}
                        teksButton={<b>Download</b>}
                        size={'middle'}
                        shape={'round'}
                        className={
                          validateDownloadButton === false
                            ? 'validateDownloadButtonEnable'
                            : 'validateDownloadButtonDisabled'
                        }
                        type={'primary'}
                        icon={''}
                        style={{}}
                      />
                    </div>
                    <div>
                      <ButtonMaterial
                        disabled={validateResetButton}
                        handleSubmit={handleReset}
                        teksButton={<b>Reset</b>}
                        size={'middle'}
                        shape={'round'}
                        className={
                          validateResetButton === false
                            ? 'validateResetButtonEnable'
                            : 'validateResetButtonDisabled'
                        }
                        type={'primary'}
                        icon={''}
                        style={{}}
                      />
                    </div>
                  </Row>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
