export function changePosition(StatusPosition:any)
{
    return({
        type:"CHANGE_STATUS_CUSTOMER_POSITION",
        StatusPosition
    })
}
export function changeCode(StatusCode:any)
{
    return({
        type:"CHANGE_STATUS_CUSTOMER_CODE",
        StatusCode
    })
}
export function changeLabel(StatusLabel:any)
{
    return({
        type:"CHANGE_STATUS_CUSTOMER_LABEL",
        StatusLabel
    })
}
export function changeDescription(StatusDescription:any)
{
    return({
        type:"CHANGE_STATUS_CUSTOMER_DESCRIPTION",
        StatusDescription
    })
}
export function changeAttempt(StatusAttempt:any)
{
    return({
        type:"CHANGE_STATUS_CUSTOMER_ATTEMPT",
        StatusAttempt
    })
}
export function changeFreeText(StatusFreeText:any)
{
    return({
        type:"CHANGE_STATUS_CUSTOMER_FREE_TEXT",
        StatusFreeText
    })
}
export function changeYBS(StatusYBS:any)
{
    return({
        type:"CHANGE_STATUS_CUSTOMER_YBS",
        StatusYBS
    })
}
export function submitDefaultStatus()
{
    return({
        type:"SUBMIT_DEFAULT_STATUS"
    })
}
export function changeClearStatusConfigForm()
{
    return({
        type:"CLEAR_DATA_CUSTOMER_STATUS_FORM"
    })
}