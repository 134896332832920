import { Action } from 'redux';

const initialState = {
  UnggahDocument: {
    csv: null,
    CustomerId: null,
    ProductType: null,
    CustomerProductId: null,
    CustomCsvCustomer: null,
    DeliveryDate: null,
    UploadDate: null,
    FileName: null,
    CountSuccess: null,
    CountFailed: null,
    ResultUploadData: null,
    ListResultUploadData: [],
  },
  StatusUploadDocument: {
    documentId: null,
    detailStatusUploadDocument: null,
    listStatusUploadDocument: [],
  },
  DistributionDocument: {
    propertiesDistributionDocument: {
      cardFilterData: false,
      cardAdditionalData: false,
    },
    CycleDate: null,
    Customer: null,
    ProductType: null,
    CustomerProduct: null,
    DeliveryType: null,
    AmplopType: null,
    DeliveryDistrictType: null,
    BranchId: null,
    VendorBranch: false,
    CourierId: null,
    CourierCode: null,
    CourierName: null,
    Waybill: null,
    ReceiptNumber: null,
    Status: null,
    Checkbox: false,
    IsReverseBarcode: false,
    CheckboxKoli: false,
    weight: 0,
    koli: 0,
    Scan: 0,
    UnScan: 0,
    ListKoli: [0],
    ListScan: [],
    ListUnScan: [],
  },
  PrintWaybill: {
    propertiesPrintWaybill: {
      FileUploadDate: null,
    },
    ListData: [],
    DataDownload: {
      BranchId: null,
      CourierId: null,
      CourierCode: null,
      Cycle: null,
      Position: null,
      DeliveryTime: null,
      BranchName: null,
    },
  },
};

export interface IAuthAction extends Action<string> {}

export interface IChangeCsv extends IAuthAction {
  Csv: any;
}
export interface IChangeCustomerId extends IAuthAction {
  CustomerId: any;
}
export interface IChangeProductType extends IAuthAction {
  ProductType: any;
}
export interface IChangeCustomerProductId extends IAuthAction {
  CustomerProductId: any;
}
export interface IChangeCustomCsvCustomer extends IAuthAction {
  CustomCsvCustomer: any;
}
export interface IChangeDeliveryDate extends IAuthAction {
  DeliveryDate: any;
}
export interface IChangeUploadDate extends IAuthAction {
  UploadDate: any;
}
export interface IChangeFileName extends IAuthAction {
  FileName: any;
}
export interface IChangeCountSuccess extends IAuthAction {
  CountSuccess: any;
}
export interface IChangeCountFailed extends IAuthAction {
  CountFailed: any;
}
export interface IChangeResultUploadData extends IAuthAction {
  ResultUploadData: any;
}
export interface IChangeListResultUploadData extends IAuthAction {
  ListResultUploadData: any;
}
export interface IFetchListStatusUploadDocument extends IAuthAction {
  listStatusUploadDocument: any;
}
export interface IFetchDocumentId extends IAuthAction {
  documentId: any;
}
export interface IFetchDetailStatusUploadDocument extends IAuthAction {
  detailStatusUploadDocument: any;
}
export interface IChangeCardFilterData extends IAuthAction {
  cardFilterData: any;
}
export interface IChangeCardAdditionalData extends IAuthAction {
  cardAdditionalData: any;
}
export interface IChangeCycleDate extends IAuthAction {
  CycleDate: any;
}
export interface IChangeCustomer extends IAuthAction {
  Customer: any;
}
export interface IChangeProductTypeDistribution extends IAuthAction {
  ProductTypeDistribution: any;
}
export interface IChangeCustomerProduct extends IAuthAction {
  CustomerProduct: any;
}
export interface IChangeDeliveryType extends IAuthAction {
  DeliveryType: any;
}
export interface IChangeAmplopType extends IAuthAction {
  AmplopType: any;
}
export interface IChangeDeliveryDistrictType extends IAuthAction {
  DeliveryDistrictType: any;
}
export interface IChangeBranchId extends IAuthAction {
  BranchId: any;
}
export interface IChangeReceiptNumber extends IAuthAction {
  ReceiptNumber: any;
}
export interface IChangeVendorBranch extends IAuthAction {
  VendorBranch: any;
}
export interface IChangeCourierId extends IAuthAction {
  CourierId: any;
}
export interface IChangeWaybill extends IAuthAction {
  Waybill: any;
}
export interface IChangeStatus extends IAuthAction {
  Status: any;
}
export interface IChangeCheckboxD extends IAuthAction {
  Checkbox: any;
}
export interface IChangeIsReverceBarcodeD extends IAuthAction {
  IsReverseBarcode: any;
}
export interface IChangeCheckboxKoliD extends IAuthAction {
  CheckboxKoli: any;
}
export interface IChangeWeightD extends IAuthAction {
  weight: any;
}
export interface IChangeKoliD extends IAuthAction {
  koli: any;
}
export interface IChangeListKoliD extends IAuthAction {
  ListKoli: any;
}
export interface IChangeScan extends IAuthAction {
  Scan: any;
}
export interface IChangeUnScan extends IAuthAction {
  UnScan: any;
}
export interface IChangeFileUploadDate extends IAuthAction {
  FileUploadDate: any;
}
export interface IChangeListData extends IAuthAction {
  ListData: any;
}
export interface IChangeDDBranchId extends IAuthAction {
  BranchId: any;
}
export interface IChangeDDCourierId extends IAuthAction {
  CourierId: any;
}
export interface IChangeCourierCode extends IAuthAction {
  CourierCode: any;
}
export interface IChangeCourierName extends IAuthAction {
  CourierName: any;
}
export interface IChangeScan extends IAuthAction {
  Scan: any;
}
export interface IChangeUnScan extends IAuthAction {
  UnScan: any;
}
export interface IChangeListScan extends IAuthAction {
  ListScan: any;
}
export interface IChangeListUnScan extends IAuthAction {
  ListUnScan: any;
}
export interface IChangeDDCycle extends IAuthAction {
  Cycle: any;
}
export interface IChangeDDPosition extends IAuthAction {
  Position: any;
}
export interface IChangeDDDeliveryTime extends IAuthAction {
  DeliveryTime: any;
}
export interface IChangeDDBranchName extends IAuthAction {
  BranchName: any;
}
export interface IChangeDDCourierCode extends IAuthAction {
  CourierCode: any;
}

export default function AuthReducer(state = initialState, action: IAuthAction) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_FILE_CSV':
      const changeFileCsv = action as IChangeCsv;
      newState.UnggahDocument.csv = changeFileCsv.Csv;

      return { ...newState };

    case 'CHANGE_CUSTOMER_ID':
      const changeCustomerId = action as IChangeCustomerId;
      newState.UnggahDocument.CustomerId = changeCustomerId.CustomerId;

      return { ...newState };

    case 'CHANGE_PRODUCT_TYPE':
      const changeProductType = action as IChangeProductType;
      newState.UnggahDocument.ProductType = changeProductType.ProductType;

      return { ...newState };

    case 'CHANGE_CUSTOMER_PRODUCT_ID':
      const changeCustomerProductId = action as IChangeCustomerProductId;
      newState.UnggahDocument.CustomerProductId =
        changeCustomerProductId.CustomerProductId;

      return { ...newState };

    case 'CHANGE_CUSTOM_CSV_CUSTOMER':
      const changeCustomCsvCustomer = action as IChangeCustomCsvCustomer;
      newState.UnggahDocument.CustomCsvCustomer =
        changeCustomCsvCustomer.CustomCsvCustomer;

      return { ...newState };

    case 'CHANGE_CUSTOMER_DELIVERY_DATE':
      const changeDeliveryDate = action as IChangeDeliveryDate;
      newState.UnggahDocument.DeliveryDate = changeDeliveryDate.DeliveryDate;

      return { ...newState };

    case 'CHANGE_CUSTOMER_UPLOAD_DATE':
      const changeUploadDate = action as IChangeUploadDate;
      newState.UnggahDocument.UploadDate = changeUploadDate.UploadDate;

      return { ...newState };

    case 'CHANGE_CUSTOMER_FILE_NAME':
      const changeFileName = action as IChangeFileName;
      newState.UnggahDocument.FileName = changeFileName.FileName;

      return { ...newState };

    case 'CHANGE_COUNT_SUCCESS':
      const changeCountSuccess = action as IChangeCountSuccess;
      newState.UnggahDocument.CountSuccess = changeCountSuccess.CountSuccess;

      return { ...newState };

    case 'CHANGE_COUNT_FAILED':
      const changeCountFailed = action as IChangeCountFailed;
      newState.UnggahDocument.CountFailed = changeCountFailed.CountFailed;

      return { ...newState };

    case 'CHANGE_LIST_RESULT_UPLOAD_DATA':
      const changeListResultUploadData = action as IChangeListResultUploadData;
      newState.UnggahDocument.ListResultUploadData =
        changeListResultUploadData.ListResultUploadData;

      return { ...newState };
    case 'CHANGE_RESULT_UPLOAD_DATA':
      const changeResultUploadData = action as IChangeResultUploadData;
      newState.UnggahDocument.ResultUploadData =
        changeResultUploadData.ResultUploadData;

      return { ...newState };

    case 'FETCH_LIST_DATA_STATUS_UPLOAD_DOCUMENT':
      const fetchListDataStatusUploadDocument =
        action as IFetchListStatusUploadDocument;
      newState.StatusUploadDocument.listStatusUploadDocument =
        fetchListDataStatusUploadDocument.listStatusUploadDocument;

      return { ...newState };

    case 'FETCH_DETAIL_STATUS_UPLOAD_DOCUMENT':
      const fetchDetailStatusUpload =
        action as IFetchDetailStatusUploadDocument;
      newState.StatusUploadDocument.detailStatusUploadDocument =
        fetchDetailStatusUpload.detailStatusUploadDocument;

      return { ...newState };

    case 'FETCH_DOCUMENT_ID':
      const fetchDocumentId = action as IFetchDocumentId;
      newState.StatusUploadDocument.documentId = fetchDocumentId.documentId;

      return { ...newState };

    case 'CLEAR_DETAIL_STATUS_UPLOAD_DOCUMENT':
      newState.StatusUploadDocument.documentId = null;
      newState.StatusUploadDocument.detailStatusUploadDocument = null;

      return { ...newState };

    // ================ INI UNTUK ACTION DATA DOWNLOAD ================

    case 'CHANGE_DD_BRANCH_ID':
      const changeDDBranchId = action as IChangeDDBranchId;
      newState.PrintWaybill.DataDownload.BranchId = changeDDBranchId.BranchId;
      return { ...newState };

    case 'CHANGE_DD_COURIER_ID':
      const changeDDCourierId = action as IChangeDDCourierId;
      newState.PrintWaybill.DataDownload.CourierId =
        changeDDCourierId.CourierId;
      return { ...newState };

    case 'CHANGE_DD_CYCLE':
      const changeDDCycle = action as IChangeDDCycle;
      newState.PrintWaybill.DataDownload.Cycle = changeDDCycle.Cycle;
      return { ...newState };

    case 'CHANGE_DD_POSITION':
      const changeDDPosition = action as IChangeDDPosition;
      newState.PrintWaybill.DataDownload.Position = changeDDPosition.Position;
      return { ...newState };

    case 'CHANGE_DD_DELIVERY_TIME':
      const changeDDDeliveryTime = action as IChangeDDDeliveryTime;
      newState.PrintWaybill.DataDownload.DeliveryTime =
        changeDDDeliveryTime.DeliveryTime;
      return { ...newState };

    case 'CHANGE_DD_BRANCH_NAME':
      const changeDDBranchName = action as IChangeDDBranchName;
      newState.PrintWaybill.DataDownload.BranchName =
        changeDDBranchName.BranchName;
      return { ...newState };

    case 'CHANGE_DD_COURIER_CODE':
      const changeDDCourierCode = action as IChangeDDCourierCode;
      newState.PrintWaybill.DataDownload.CourierCode =
        changeDDCourierCode.CourierCode;
      return { ...newState };

    case 'SET_CARD_FILTER_DATA':
      const changeCardFilterData = action as IChangeCardFilterData;
      newState.DistributionDocument.propertiesDistributionDocument.cardFilterData =
        changeCardFilterData.cardFilterData;

      return { ...newState };

    case 'SET_CARD_ADDITIONAL_DATA':
      const changeCardAdditionalData = action as IChangeCardAdditionalData;
      newState.DistributionDocument.propertiesDistributionDocument.cardAdditionalData =
        changeCardAdditionalData.cardAdditionalData;

      return { ...newState };

    case 'SET_CYCLE_DATE':
      const changeCycleDate = action as IChangeCycleDate;
      newState.DistributionDocument.CycleDate = changeCycleDate.CycleDate;

      return { ...newState };

    case 'SET_CUSTOMER':
      const changeCustomer = action as IChangeCustomer;
      newState.DistributionDocument.Customer = changeCustomer.Customer;

      return { ...newState };

    case 'SET_PRODUCT_TYPE_DISTRIBUTION':
      const changeProductTypeDistribution =
        action as IChangeProductTypeDistribution;
      newState.DistributionDocument.ProductType =
        changeProductTypeDistribution.ProductTypeDistribution;

      return { ...newState };

    case 'SET_CUSTOMER_PRODUCT':
      const changeCustomerProduct = action as IChangeCustomerProduct;
      newState.DistributionDocument.CustomerProduct =
        changeCustomerProduct.CustomerProduct;

      return { ...newState };

    case 'SET_DELIVERY_TYPE':
      const changeDeliveryType = action as IChangeDeliveryType;
      newState.DistributionDocument.DeliveryType =
        changeDeliveryType.DeliveryType;

      return { ...newState };
    case 'SET_AMPLOP_TYPE':
      const changeAmplopType = action as IChangeAmplopType;
      newState.DistributionDocument.AmplopType = changeAmplopType.AmplopType;

      return { ...newState };
    case 'SET_DELIVERY_DISTRICT_TYPE':
      const changeDeliveryDistrictType = action as IChangeDeliveryDistrictType;
      newState.DistributionDocument.DeliveryDistrictType =
        changeDeliveryDistrictType.DeliveryDistrictType;

      return { ...newState };
    case 'SET_VENDOR_BRANCH':
      const changeVendorBranch = action as IChangeVendorBranch;
      newState.DistributionDocument.VendorBranch =
        changeVendorBranch.VendorBranch;

      return { ...newState };
    case 'SET_RECEIPT_NUMBER':
      const changeReceiptNumber = action as IChangeReceiptNumber;
      newState.DistributionDocument.ReceiptNumber =
        changeReceiptNumber.ReceiptNumber;

      return { ...newState };
    case 'SET_BRANCH_ID':
      const changeBranchId = action as IChangeBranchId;
      newState.DistributionDocument.BranchId = changeBranchId.BranchId;

      return { ...newState };
    case 'SET_COURIER_ID':
      const changeCourierId = action as IChangeCourierId;
      newState.DistributionDocument.CourierId = changeCourierId.CourierId;

      return { ...newState };
    case 'SET_WAYBILL':
      const changeWaybill = action as IChangeWaybill;
      newState.DistributionDocument.Waybill = changeWaybill.Waybill;

      return { ...newState };
    case 'SET_STATUS':
      const changeStatus = action as IChangeStatus;
      newState.DistributionDocument.Status = changeStatus.Status;

      return { ...newState };
    case 'SET_CHECKBOX_D':
      const changeCheckbox = action as IChangeCheckboxD;
      newState.DistributionDocument.Checkbox = changeCheckbox.Checkbox;
      return { ...newState };

    case 'SET_IS_REVERSE_BARCODE_D':
      const changeIsReverseBarcode = action as IChangeIsReverceBarcodeD;
      newState.DistributionDocument.IsReverseBarcode =
        changeIsReverseBarcode.IsReverseBarcode;
      return { ...newState };

    case 'SET_CHECKBOX_KOLI_D':
      const changeCheckboxKoli = action as IChangeCheckboxKoliD;
      newState.DistributionDocument.CheckboxKoli =
        changeCheckboxKoli.CheckboxKoli;

      return { ...newState };
    case 'SET_WEIGHT_D':
      const changeWeight = action as IChangeWeightD;
      newState.DistributionDocument.weight = changeWeight.weight;

      return { ...newState };
    case 'SET_KOLI_D':
      const changeKoli = action as IChangeKoliD;
      newState.DistributionDocument.koli = changeKoli.koli;

      return { ...newState };
    case 'SET_LIST_KOLI_D':
      const changeListKoli = action as IChangeListKoliD;
      newState.DistributionDocument.ListKoli = changeListKoli.ListKoli;

      return { ...newState };
    case 'SET_SCAN':
      const changeScan = action as IChangeScan;
      newState.DistributionDocument.Scan = changeScan.Scan;

      return { ...newState };

    case 'SET_UN_SCAN':
      const changeUnScan = action as IChangeUnScan;
      newState.DistributionDocument.UnScan = changeUnScan.UnScan;

      return { ...newState };
    case 'SET_LIST_SCAN':
      const changeListScan = action as IChangeListScan;
      newState.DistributionDocument.ListScan = changeListScan.ListScan;

      return { ...newState };
    case 'SET_LIST_UN_SCAN':
      const changeListUnScan = action as IChangeListUnScan;
      newState.DistributionDocument.ListUnScan = changeListUnScan.ListUnScan;

      return { ...newState };

    case 'CLEAR_COURIER_DATA':
      newState.DistributionDocument.CourierId = null;
      newState.DistributionDocument.CourierCode = null;
      newState.DistributionDocument.CourierName = null;
      return { ...newState };

    case 'SET_FILE_UPLOAD_DATE':
      const changeFileUploadDate = action as IChangeFileUploadDate;
      newState.PrintWaybill.propertiesPrintWaybill.FileUploadDate =
        changeFileUploadDate.FileUploadDate;
      return { ...newState };
    case 'SET_LIST_DATA_UPLOAD':
      const changeListData = action as IChangeListData;
      newState.PrintWaybill.ListData = changeListData.ListData;
      return { ...newState };

    case 'CLEAR_FILE_UPLOAD_DATE':
      newState.PrintWaybill.propertiesPrintWaybill.FileUploadDate = null;
      return { ...newState };

    case 'CLEAR_LIST_DATA_UPLOAD':
      newState.PrintWaybill.ListData = [];
      return { ...newState };

    case 'CLEAR_FILE_CSV':
      newState.UnggahDocument.csv = null;

      return { ...newState };
    case 'CLEAR_WAYBILL':
      newState.DistributionDocument.Waybill = null;

      return { ...newState };
    case 'SET_COURIER_CODE':
      const changeCourierCode = action as IChangeCourierCode;
      newState.DistributionDocument.CourierCode = changeCourierCode.CourierCode;

      return { ...newState };
    case 'SET_COURIER_NAME':
      const changeCourierName = action as IChangeCourierName;
      newState.DistributionDocument.CourierName = changeCourierName.CourierName;

      return { ...newState };

    case 'CLEAR_CUSTOMER_ID':
      newState.UnggahDocument.CustomerId = null;

      return { ...newState };

    case 'CLEAR_PRODUCT_TYPE':
      newState.UnggahDocument.ProductType = null;

      return { ...newState };

    case 'CLEAR_CUSTOMER_PRODUCT':
      newState.UnggahDocument.CustomerProductId = null;

      return { ...newState };

    case 'CLEAR_CUSTOM_CSV_CUSTOMER':
      newState.UnggahDocument.CustomCsvCustomer = null;

      return { ...newState };

    case 'CLEAR_ALL_FORM_UNGGAH_CSV':
      newState.UnggahDocument.csv = null;
      newState.UnggahDocument.CustomerId = null;
      newState.UnggahDocument.ProductType = null;
      newState.UnggahDocument.CustomerProductId = null;
      newState.UnggahDocument.CustomCsvCustomer = null;
      newState.UnggahDocument.DeliveryDate = null;
      newState.UnggahDocument.FileName = null;
      newState.UnggahDocument.CountSuccess = null;
      newState.UnggahDocument.CountFailed = null;
      newState.UnggahDocument.ResultUploadData = null;
      newState.UnggahDocument.ListResultUploadData = [];

      return { ...newState };
    case 'CLEAR_CUSTOMER_DISTRIBUTION':
      newState.DistributionDocument.Customer = null;

      return { ...newState };
    case 'CLEAR_PRODUCT_TYPE_DISTRIBUTION':
      newState.DistributionDocument.ProductType = null;

      return { ...newState };
    case 'CLEAR_PRODUCT_DISTRIBUTION':
      newState.DistributionDocument.CustomerProduct = null;

      return { ...newState };
    case 'CLEAR_LIST_SCAN_OR_UNSCAN':
      newState.DistributionDocument.Scan = 0;
      newState.DistributionDocument.UnScan = 0;

      return { ...newState };

    case 'CLEAR_DATA_DOWNLOAD':
      newState.PrintWaybill.DataDownload.DeliveryTime = null;
      newState.PrintWaybill.DataDownload.Cycle = null;
      newState.PrintWaybill.DataDownload.Position = null;
      newState.PrintWaybill.DataDownload.BranchId = null;
      newState.PrintWaybill.DataDownload.BranchName = null;
      newState.PrintWaybill.DataDownload.CourierCode = null;
      newState.PrintWaybill.DataDownload.CourierId = null;
      return { ...newState };
    case 'CLEAR_ALL_FORM_DISTRIBUTION':
      newState.DistributionDocument.CycleDate = null;
      newState.DistributionDocument.Customer = null;
      newState.DistributionDocument.ProductType = null;
      newState.DistributionDocument.CustomerProduct = null;
      newState.DistributionDocument.DeliveryType = null;
      newState.DistributionDocument.AmplopType = null;
      newState.DistributionDocument.DeliveryDistrictType = null;
      newState.DistributionDocument.BranchId = null;
      newState.DistributionDocument.CourierId = null;
      newState.DistributionDocument.CourierCode = null;
      newState.DistributionDocument.CourierName = null;
      newState.DistributionDocument.Waybill = null;
      newState.DistributionDocument.Status = null;
      newState.DistributionDocument.Checkbox = false;
      newState.DistributionDocument.IsReverseBarcode = false;
      newState.DistributionDocument.CheckboxKoli = false;
      newState.DistributionDocument.ReceiptNumber = null;
      newState.DistributionDocument.weight = 0;
      newState.DistributionDocument.koli = 0;
      newState.DistributionDocument.ListKoli = [0];
      newState.DistributionDocument.CourierCode = null;
      newState.DistributionDocument.CourierName = null;
      newState.DistributionDocument.ListScan = [];
      newState.DistributionDocument.ListUnScan = [];
      newState.DistributionDocument.propertiesDistributionDocument.cardAdditionalData =
        false;
      newState.DistributionDocument.propertiesDistributionDocument.cardFilterData =
        false;
      return { ...newState };
    case 'CLEAR_CARD_FILTER_DATA':
      newState.DistributionDocument.propertiesDistributionDocument.cardFilterData =
        false;
      return { ...newState };

    case 'CLEAR_CARD_ADDITIONAL_DATA':
      newState.DistributionDocument.propertiesDistributionDocument.cardAdditionalData =
        false;
      return { ...newState };
    case 'CLEAR_KOLI_OR_WEIGHT_D':
      newState.DistributionDocument.weight = 0;
      newState.DistributionDocument.ListKoli = [0];
      newState.DistributionDocument.koli = 0;
      return { ...newState };
    case 'CLEAR_LIST_KOLI_D':
      newState.DistributionDocument.ListKoli = [0];
      return { ...newState };

    case 'CLEAR_CHECKBOX_KOLI_D':
      newState.DistributionDocument.CheckboxKoli = false;
      return { ...newState };

    case 'CLEAR_RECEIPT_NUMBER_D':
      newState.DistributionDocument.ReceiptNumber = null;
      return { ...newState };
    case 'CLEAR_CYCLE_DATE':
      newState.DistributionDocument.CycleDate = null;
      return { ...newState };

    case 'CLEAR_KOLI_D':
      newState.DistributionDocument.koli = 0;
      return { ...newState };
  }
  return { ...newState };
}
