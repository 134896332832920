import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators, compose } from 'redux';
import * as CourierManifest from '../../../../core/action/Manifest/CourierManifest/ActionCourierManifest';
import * as Branch from '../../../../core/action/Branch/ActionAllBranch';
import * as toggle from '../../../../core/action/toggle';
import CourierManifestFormComponent from '../../../../components/Content/Manifest/CourierManifest/CourierManifestFormComponent';

interface IProps {
  actionCourierManifest: any;
  actionBranch: any;
  listBranch: any;
  DeliveryDate: any;
  Branch: any;
  IdBranch: any;
  roleAccount: any;
  LoadingSelectBranch: any;
  actionToggle: any;
}
interface IState {
  ValueDeliveryDate: any;
  ValidateDeliveryDate: any;
  ValidateBranch: any;
}
class CourierManifestFormContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValueDeliveryDate: null,
      ValidateDeliveryDate: null,
      ValidateBranch: null,
    };
    this.handleValidateDeliveryDate = this.handleValidateDeliveryDate.bind(
      this
    );
    this.handlePutDeliveryDate = this.handlePutDeliveryDate.bind(this);
    this.handleGetBranch = this.handleGetBranch.bind(this);
    this.handlePutBranch = this.handlePutBranch.bind(this);
    this.handleBlurBranch = this.handleBlurBranch.bind(this);
    this.handleResetBranch = this.handleResetBranch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleGetDataManifest = this.handleGetDataManifest.bind(this);
  }

  handleValidateDeliveryDate(event: any) {
    this.setState({
      ValidateDeliveryDate: event,
    });
    if (this.state.ValidateDeliveryDate === true) {
      if (
        this.props.DeliveryDate === null ||
        this.props.DeliveryDate === undefined ||
        this.props.DeliveryDate === 'Invalid date'
      ) {
        this.setState({
          ValidateDeliveryDate: 'error',
        });
      } else {
        this.setState({
          ValidateDeliveryDate: null,
        });
      }
    } else {
    }
  }

  handlePutDeliveryDate(event: any) {
    if(event){
      const dateValue = event._d?(moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')):event
      this.props.actionCourierManifest.changeDeliveryDateCourierManifestForm(dateValue);
    }else{
      this.props.actionCourierManifest.changeDeliveryDateCourierManifestForm(null);
    }
    this.setState({
      ValueDeliveryDate: event,
    });
    if (event === '' || event === null) {
      this.setState({
        ValidateDeliveryDate: 'error',
      });
    } else {
      this.setState({
        ValidateDeliveryDate: null,
      });
    }
  }

  handleGetBranch() {
    this.props.actionBranch.getAllBranch();
  }

  handlePutBranch(event: any, label: any) {
    this.props.actionCourierManifest.changeBranchIdCourierManifestForm(event);
    if (label === undefined) {
      this.props.actionCourierManifest.changeBranchNameCourierManifestForm(
        null
      );
    } else {
      this.props.actionCourierManifest.changeBranchNameCourierManifestForm(
        label.props.label
      );
    }

    if (event === null) {
      this.setState({
        ValidateBranch: 'error',
      });
    } else {
      this.setState({
        ValidateBranch: null,
      });
    }
  }

  handleBlurBranch() {
    if (this.props.Branch === null) {
      this.setState({
        ValidateBranch: 'error',
      });
    } else {
      this.setState({
        ValidateBranch: null,
      });
    }
  }

  handleResetBranch() {}

  handleReset() {
    if (this.props.roleAccount === 'Branch') {
      this.props.actionCourierManifest.changeDeliveryDateCourierManifestForm(
        null
      );
    } else {
      this.props.actionCourierManifest.clearCourierManifestForm();
    }

    this.props.actionCourierManifest.clearTableCourierManifestForm();
    this.props.actionCourierManifest.clearListCourierManifestForm();
    this.setState({
      ValidateBranch: null,
      ValidateDeliveryDate: null,
      ValueDeliveryDate:null,
    });
  }

  handleGetDataManifest() {
    this.props.actionCourierManifest.changeCountCourierManifestForm(null);
    this.props.actionCourierManifest.clearTableCourierManifestForm();
    this.props.actionCourierManifest.clearListCourierManifestForm();
    this.props.actionCourierManifest.changeGetDataCourierManifest();
  }

  async componentWillMount() {
    if (this.props.roleAccount === 'Branch') {
      await this.props.actionBranch.getAllBranch();
      await this.props.actionCourierManifest.changeBranchIdCourierManifestForm(
        this.props.IdBranch
      );
    }
  }

  render() {
    return (
      <CourierManifestFormComponent
        roleAccount={this.props.roleAccount}
        valueDeliveryDate={this.state.ValueDeliveryDate}
        deliveryDate={this.props.DeliveryDate}
        IdBranch={this.props.IdBranch}
        branch={this.props.Branch}
        listBranch={this.props.listBranch}
        loadingSelectBranch={this.props.LoadingSelectBranch}
        validateDeliveryDate={
          this.state.ValidateDeliveryDate === 'error' ? 'error' : ''
        }
        validateBranch={null}
        helpValidateDeliveryDate={
          this.state.ValidateDeliveryDate === 'error'
            ? 'Tanggal pengiriman wajib diisi'
            : ''
        }
        helpValidateBranch={null}
        handleValidateDeliveryDate={this.handleValidateDeliveryDate}
        handleBlurBranch={this.handleBlurBranch}
        handleGetBranch={this.handleGetBranch}
        handleGetDataManifest={this.handleGetDataManifest}
        handlePutBranch={this.handlePutBranch}
        handleResetBranch={this.handleResetBranch}
        handlePutDeliveryDate={this.handlePutDeliveryDate}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  roleAccount: state.Auth.Role,
  IdBranch: state.Auth.BranchAuth,
  DeliveryDate: state.Manifest.CourierManifestForm.DeliveryDate,
  Branch: state.Manifest.CourierManifestForm.BranchId,
  listBranch: state.Branch.AllBranch,

  LoadingSelectBranch: state.Branch.PropertiesBranch.LoadingBranch,
  TypeLoadingSelectBranch: state.Branch.PropertiesBranch.TypeLoadingBranch,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionCourierManifest: bindActionCreators(CourierManifest, dispatch),
  actionBranch: bindActionCreators(Branch, dispatch),
  actionToggle: bindActionCreators(toggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CourierManifestFormContainer);
