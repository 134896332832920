import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';

interface IProps {
  handleChangeAndPopUpModal: any;
}

export default class ButtonDownload extends Component<IProps> {
  render() {
    return (
      <div>
        <ButtonMaterial
          handleSubmit={this.props.handleChangeAndPopUpModal}
          type={'primary'}
          icon={'download'}
          style={{
            float: 'right',
            borderRadius: '8px',
            color: '#fafafa',
            paddingBottom: '3px',
            background: '#7956EE',
            borderColor: '#7956EE',
          }}
          tooltip="Download Manifest Pelanggan"
        />
      </div>
    );
  }
}
