import { Col, Row, Table } from 'antd';
import React, { Component } from 'react';

import AddAdminContainer from '../../../../containers/Content/ManageAccount/AdminBranch/AddAdminContainer';
import ButtonAdminBranchContainer from '../../../../containers/Content/ManageAccount/AdminBranch/ButtonAdminBranchContainer';
import ButtonMaterial from '../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../materials/CardMaterial';
import DetailAdminBranchContainer from '../../../../containers/Content/ManageAccount/AdminBranch/DetailAdminBranchContainer';
import EditAdminBranchContainer from '../../../../containers/Content/ManageAccount/AdminBranch/EditAdminBranchContainer';
import ResetPasswordContainer from '../../../../containers/Content/ResetPassword/ResetPasswordContainer';
import SearchMaterial from '../../../../materials/SearchMatrial';
import SpinMaterial from '../../../../materials/SpinMaterial';
import { DropdownBasic } from 'components/DropdownBasic';
import { accountTypes } from 'lib/constants';
interface IProps {
  data: any;
  PagePagination: any;
  TotalPagePagination: any;
  handlePage: any;
  handleSearchName: any;
  handleModal: any;
  searchGroup: any;
  isLoading: any;
  handleSearchGroup: any;
  statusGroup: any;
  handleStatusGroup: any;
  refetch: any;
}

export default class TableAdminBranchComponent extends Component<IProps> {
  render() {
    let loopPage = (this.props.PagePagination - 1) * 10 + 1;
    const dataN = [
      {
        label: 'Aktif',
        key: 'Active',
      },
      {
        label: 'Tidak aktif',
        key: 'Disable',
      },
      {
        label: 'Semua',
        key: 'None',
      },
    ];
    const columns = [
      {
        title: <b>No</b>,
        width: 50,
        render: (text: any, record: any, index: any) => (
          <span>
            {this.props.PagePagination > 1 ? index + loopPage : index + 1}
          </span>
        ),
      },
      {
        title: <b>Tipe</b>,
        dataIndex: 'accountType',
        key: 'accountType',
        render: (text: any) =>
          accountTypes.find(type => type.id === text)?.name,
        width: 120,
      },
      {
        title: <b>Username</b>,
        dataIndex: 'code',
        render: (code: any) => <span className={'redColor'}>{code}</span>,
        key: 'code',
        width: 120,
      },
      {
        title: <b>Nama</b>,
        dataIndex: 'firstName',
        key: 'name',
        width: 120,
      },
      {
        title: <b>Email</b>,
        dataIndex: 'email',
        render: (email: any) => <span className={'redColor'}>{email}</span>,
        key: 'email',
        width: 200,
      },
      {
        title: <b>No Telp</b>,
        dataIndex: 'phone',
        render: (phone: any) => <span>+62{phone}</span>,
        key: 'phone',
        width: 120,
      },
      {
        title: 'Status',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (status: any) => (status === true ? 'Aktif' : 'Tidak aktif'),
        width: 120,
      },
      {
        title: <b>Aksi</b>,
        key: 'action',
        width: 130,
        render: (record: any) => (
          <div>
            <Row>
              <Col span={8}>
                <div className={'btnResetForm'}>
                  <ButtonAdminBranchContainer
                    idAdmin={record.id}
                    typeButton={'reset'}
                  />
                </div>
              </Col>

              <Col span={8}>
                <div className={'paddingRight'}>
                  <ButtonAdminBranchContainer
                    typeButton={'detail'}
                    idAdmin={record.id}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className={'floatRight'}>
                  <ButtonAdminBranchContainer
                    typeButton={'edit'}
                    idAdmin={record.id}
                  />
                </div>
              </Col>
            </Row>
          </div>
        ),
      },
    ];
    return (
      <div>
        <AddAdminContainer refetch={this.props.refetch} />
        <CardMaterial
          title={''}
          extra={''}
          style={{}}
          content={
            <div>
              <Row>
                <Col span={12}>
                  <div>
                    <span className={'titleSearchCard'}>ADMIN</span>
                    <SearchMaterial
                      value={this.props.searchGroup}
                      style={{ width: '70%' }}
                      placeholder={'Cari nama atau username'}
                      onChange={e =>
                        this.props.handleSearchGroup(
                          e.target.value.replace(/^\s/, '')
                        )
                      }
                      onPressEnter={() =>
                        this.props.handleSearchName(this.props.searchGroup)
                      }
                    />
                  </div>
                </Col>
                <Col span={4}>
                  <DropdownBasic
                    name="filterValue"
                    idComponent="filterValue"
                    dropdownMenu={dataN}
                    triggerAction={'click'}
                    labelTitle={'Aktif'}
                    className="floatLeft"
                    value={this.props.statusGroup}
                    onChange={this.props.handleStatusGroup}
                  />
                </Col>
                <Col span={8}>
                  <div className={'floatRight'}>
                    <ButtonMaterial
                      disabled={''}
                      handleSubmit={this.props.handleModal}
                      teksButton={<b>TAMBAHKAN ADMIN</b>}
                      size={''}
                      shape={''}
                      className={'btnLink'}
                      type={'link'}
                      icon={''}
                      style={{}}
                    />
                    <ButtonMaterial
                      disabled={''}
                      handleSubmit={this.props.handleModal}
                      teksButton={''}
                      size={''}
                      shape={'circle'}
                      className={'btnAdd'}
                      type={''}
                      icon={'plus'}
                      style={{}}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          }
        />
        <SpinMaterial
          spinning={this.props.isLoading}
          style={{}}
          size={'large'}
          content={
            <Table
              rowKey={record => record.id}
              pagination={{
                onChange: page => {
                  this.props.handlePage(page);
                },
                pageSize: 10,
                total: this.props.TotalPagePagination,
                current:
                  this.props.PagePagination === 0
                    ? 1
                    : this.props.PagePagination,
              }}
              bordered={false}
              dataSource={this.props.data}
              columns={columns}
            />
          }
        />
        <DetailAdminBranchContainer />
        <EditAdminBranchContainer refetch={this.props.refetch} />
        <ResetPasswordContainer />
      </div>
    );
  }
}
