import { Action } from 'redux';

const initialState = {
  FormResendDelivery: {
    ResendBranch: null,
    ResendCourier: null,
    ResendDeliveryDate: null,
    ResendWaybill: null,
    ResendVendor: false,
    ResendExcelBase64: null,
    ResendExcelName: null,
    isChangeCourier: false,
    isScan: false,
    isSuccess: false,
    ResendCounting: {
      error: 0,
      success: 0,
    },
    ResultListCsv: [],
  },
  ListSubmitResendDelivery: [],
};

export interface IAuthAction extends Action<string> {}

export interface ISetResendBranch extends IAuthAction {
  ResendBranch: any;
}
export interface ISetResendCourier extends IAuthAction {
  ResendCourier: any;
}
export interface ISetResendDeliveryDate extends IAuthAction {
  ResendDeliveryDate: any;
}
export interface ISetResendWaybill extends IAuthAction {
  ResendWaybill: any;
}
export interface ISetListResendSubmit extends IAuthAction {
  ListResendSubmit: any;
}

export interface ISetListResult extends IAuthAction {
  ListResult: any;
}

export interface ISetFileExcel extends IAuthAction {
  data: {
    ResendExcelBase64: any;
    ResendExcelName: any;
  };
}

export default function AuthReducer(state = initialState, action: IAuthAction) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'SET_RESEND_BRANCH':
      const setResendBranch = action as ISetResendBranch;
      newState.FormResendDelivery.ResendBranch = setResendBranch.ResendBranch;
      return { ...newState };

    case 'SET_RESEND_COURIER':
      const setResendCourier = action as ISetResendCourier;
      newState.FormResendDelivery.ResendCourier =
        setResendCourier.ResendCourier;
      return { ...newState };

    case 'SET_RESEND_DELIVERY_DATE':
      const setResendDeliveryDate = action as ISetResendDeliveryDate;
      newState.FormResendDelivery.ResendDeliveryDate =
        setResendDeliveryDate.ResendDeliveryDate;
      return { ...newState };

    case 'SET_RESEND_WAYBILL':
      const setResendWaybill = action as ISetResendWaybill;
      newState.FormResendDelivery.ResendWaybill =
        setResendWaybill.ResendWaybill;
      return { ...newState };

    case 'SET_RESEND_VENDOR':
      newState.FormResendDelivery.ResendVendor =
        !newState.FormResendDelivery.ResendVendor;
      return { ...newState };

    case 'SET_LIST_RESULT':
      const setListResult = action as ISetListResult;
      newState.FormResendDelivery.ResultListCsv = setListResult.ListResult;
      return { ...newState };

    case 'SET_LIST_RESEND_SUBMIT':
      const setListResendSubmit = action as ISetListResendSubmit;
      newState.ListSubmitResendDelivery = setListResendSubmit.ListResendSubmit;
      return { ...newState };

    case 'SET_CHECKBOX_COURIER_RESEND':
      newState.FormResendDelivery.isChangeCourier =
        !newState.FormResendDelivery.isChangeCourier;
      if (!newState.FormResendDelivery.isChangeCourier) {
        newState.FormResendDelivery.ResendBranch = null;
        newState.FormResendDelivery.ResendCourier = null;
      }
      return { ...newState };

    case 'SET_CHECKBOX_SCAN_RESEND':
      newState.FormResendDelivery.isScan = !newState.FormResendDelivery.isScan;
      if (!newState.FormResendDelivery.isScan) {
        newState.FormResendDelivery.ResendWaybill = null;
      } else {
        newState.FormResendDelivery.ResendExcelBase64 = null;
        newState.FormResendDelivery.ResendExcelName = null;
      }
      return { ...newState };

    case 'SET_STATUS_SUCCESS':
      const setResendCounting = action as any;
      newState.FormResendDelivery.isSuccess =
        !newState.FormResendDelivery.isSuccess;
      if (newState.FormResendDelivery.isSuccess) {
        newState.FormResendDelivery.ResendCounting = setResendCounting.data;
      } else {
        newState.FormResendDelivery.ResendCounting = {
          error: 0,
          success: 0,
        };
      }
      return { ...newState };

    case 'SET_FILE_EXCEL_RESEND':
      const setResendExcel = action as ISetFileExcel;
      newState.FormResendDelivery.ResendExcelBase64 =
        setResendExcel.data.ResendExcelBase64;
      newState.FormResendDelivery.ResendExcelName =
        setResendExcel.data.ResendExcelName;
      return { ...newState };

    case 'CLEAR_RESEND_WAYBILL':
      newState.FormResendDelivery.ResendWaybill = null;
      return { ...newState };

    case 'CLEAR_RESEND_COURIER':
      newState.FormResendDelivery.ResendCourier = null;
      return { ...newState };

    case 'CLEAR_LIST_RESEND_SUBMIT':
      newState.ListSubmitResendDelivery = [];
      return { ...newState };

    case 'CLEAR_ALL_FORM_RESEND':
      newState.FormResendDelivery.ResendBranch = null;
      newState.FormResendDelivery.ResendCourier = null;
      newState.FormResendDelivery.ResendDeliveryDate = null;
      newState.FormResendDelivery.ResendWaybill = null;
      newState.FormResendDelivery.ResendVendor = false;
      newState.FormResendDelivery.isChangeCourier = false;
      newState.FormResendDelivery.isScan = false;
      newState.FormResendDelivery.ResendExcelBase64 = null;
      newState.FormResendDelivery.ResendExcelName = null;
      newState.FormResendDelivery.ResultListCsv = [];
      return { ...newState };
  }
  return { ...newState };
}
