import * as GenerateAdminCode from '../../../../core/action/ManageAccount/AdminBranch/ActionGenerateAdminBranch';
import * as addAdmin from '../../../../core/action/ManageAccount/AdminBranch/ActionAddAdminBranch';
import * as formAdmin from '../../../../core/action/ManageAccount/AdminBranch/ActionFormDetailAdminBranch';
import * as toggle from '../../../../core/action/toggle';
import * as detailAdmin from '../../../../core/action/ManageAccount/AdminBranch/ActionIdAdminBranch';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import moment from 'moment-timezone';

import DetailAdminBranchComponent from 'components/Content/ManageAccount/AdminBranch/DetailAdminBranchComponent';
import { connect } from 'react-redux';
import { FormAdminType } from 'components/Content/ManageAccount/AdminBranch/FormAdminComponent';
import { getDetailAdmin } from 'services/AccountServices';

interface IProps {
  actionToggle: any;
  visible: any;
  jenisToggle: any;
  actionFormAdmin: any;

  isLoading: any;
  idAdmin: any;
}

const DetailAdminContainer = (props: IProps) => {
  const defaultValue = {
    firstName: '',
    lastName: '',
    email: '',
    typeAccount: '',
    rolePermission: null,
    phone: null,
    dob: null,
    address: '',
    branchId: 0,
    status: false,
  };
  const [dataFormAdmin, setDataFormAdmin] =
    useState<FormAdminType>(defaultValue);

  const { isLoading } = useQuery<AxiosResponse, Error>({
    queryKey: ['detailAdmin', props.idAdmin],
    queryFn: () => getDetailAdmin(props.idAdmin),
    onSuccess: res => {
      const response = res.data;
      setDataFormAdmin({
        firstName: response?.firstName,
        lastName: response?.lastName,
        email: response?.email,
        typeAccount: response?.accountType,
        rolePermission: response?.rolePermission,
        phone: response?.phone,
        dob: moment(response?.dob),
        address: response?.address,
        branchId: response?.branchId,
        status: response?.isActive,
      });
    },
    enabled: !!props.idAdmin,
  });

  const handleClear = () => {
    props.actionToggle.changeModal();
    props.actionToggle.clearJenisToggle();
    props.actionFormAdmin.changeClearDataAdminForm();
    setDataFormAdmin(defaultValue);
  };

  return (
    <div>
      <DetailAdminBranchComponent
        isLoading={isLoading}
        visible={
          props.jenisToggle === 'DetailAdmin' && props.visible === true
            ? true
            : false
        }
        jenisToggle={props.jenisToggle}
        initialData={dataFormAdmin}
        handleClear={handleClear}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  jenisToggle: state.Toggle.jenisToggle,
  GetAdminName: state.AdminBranch.FormAdmin.AdminName,
  AccountType: state.AdminBranch.FormAdmin.AccountType,
  GetAdminLastName: state.AdminBranch.FormAdmin.AdminLastName,
  GetAdminEmail: state.AdminBranch.FormAdmin.AdminEmail,
  GetAdminPhoneNumber: state.AdminBranch.FormAdmin.AdminPhoneNumber,
  GetAdminDob: state.AdminBranch.FormAdmin.AdminDob,
  GetAdminAddress: state.AdminBranch.FormAdmin.AdminAddress,
  GetAdminPhoto: state.AdminBranch.FormAdmin.AdminPhoto,
  GetAdminCode: state.AdminBranch.FormAdmin.Code,
  GetAdminUsername: state.AdminBranch.FormAdmin.Username,
  GetRoleAkses: state.AdminBranch.FormAdmin.RolePermission,
  GetStatus: state.AdminBranch.FormAdmin.IsActive,
  isLoading: state.Toggle.isLoading,
  idAdmin: state.AdminBranch.PropertiesAdminBranch.idAdmin,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(toggle, dispatch),
  actionFormAdmin: bindActionCreators(formAdmin, dispatch),
  addAdmin: bindActionCreators(addAdmin, dispatch),
  IdAdmin: bindActionCreators(detailAdmin, dispatch),
  generateAdminCode: bindActionCreators(GenerateAdminCode, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DetailAdminContainer);
