import { Col, DatePicker, Form, Radio, Row, Select } from 'antd';

import ButtonMaterial from 'materials/ButtonMaterial';
import CardMaterial from 'materials/CardMaterial';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';

const { Option } = Select;

interface IProps {
  roleAccount: any;
  branchAuth: any;
  GetCourier: any;
  GetRadiobutton: any;
  ValueFirstDate: any;
  ValueLastDate: any;
  GetFirstDate: any;
  GetLastDate: any;
  handleChangeRadiobutton: any;
  lastAccessFiture: any;
  ValidateFirstDate: any;
  helpFirstDate: any;
  ValidateLastDate: any;
  BranchList: any;
  helpLastDate: any;
  handleValidateLastDate: any;
  handleChangeFirstDate: any;
  handleChangeLastDate: any;
  handleValidateFirstDate: any;
  handleGetBranch: any;
  handlePutBranch: any;
  handleBlurBranch: any;
  handleResetCourier: any;
  handleGetCourier: any;
  handleSelectCourier: any;
  handleBlurSelectKurir: any;
  ValueGetBranch: any;
  ValueGetCourier: any;
  loadingSelectBranch: any;
  loadingSelectCourier: any;
  validateLastDate: any;
  validateCourierForm: any;
  validateDownloadButton: any;
  handleReset: any;
  handleDownload: any;
  ListCourier: any;
  isLoading: any;
}

export default function CourierBonusReportComponent({
  roleAccount,
  branchAuth,
  GetCourier,
  GetRadiobutton,
  ValueFirstDate,
  ValueLastDate,
  GetFirstDate,
  handleChangeRadiobutton,
  ValidateFirstDate,
  helpFirstDate,
  ValidateLastDate,
  BranchList,
  helpLastDate,
  handleValidateLastDate,
  handleChangeFirstDate,
  handleChangeLastDate,
  handleValidateFirstDate,
  handleGetBranch,
  handlePutBranch,
  handleBlurBranch,
  handleResetCourier,
  handleGetCourier,
  handleSelectCourier,
  handleBlurSelectKurir,
  ValueGetBranch,
  loadingSelectBranch,
  loadingSelectCourier,
  validateLastDate,
  validateCourierForm,
  validateDownloadButton,
  handleReset,
  handleDownload,
  ListCourier,
  isLoading,
}: IProps) {
  const formatDate = 'YYYY-MM-DD';
  const firstDateV = GetFirstDate;
  const minDate = (d: any) =>
    !d ||
    d.isBefore('2020-01-01') ||
    d.isAfter(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
  const minDate2 = (d: any) =>
    !d ||
    d.isBefore(moment(GetFirstDate).add(0, 'day').format(formatDate)) ||
    d.isAfter(moment(GetFirstDate).add(30, 'day').format(formatDate));

  return (
    <div style={{ paddingBottom: '35px' }}>
      <SpinMaterial
        spinning={isLoading}
        style={{}}
        size={'large'}
        content={
          <CardMaterial
            extra={''}
            title={<b className="titleCard">INSENTIF KURIR</b>}
            style={{ borderRadius: '10px' }}
            content={
              <div>
                <Row>
                  <Col span={12} className="spacingRight">
                    <div>
                      <Radio.Group
                        onChange={handleChangeRadiobutton}
                        value={GetRadiobutton}
                      >
                        <Radio value={'Detail'}>Detail</Radio>
                        <Radio value={'Summary'}>Rekap</Radio>
                      </Radio.Group>
                    </div>
                    <div className="spacingTopLeftReport">
                      <span>
                        <b>Dari Tanggal</b>
                      </span>
                      <Form.Item
                        validateStatus={ValidateFirstDate}
                        help={helpFirstDate}
                      >
                        <DatePicker
                          className={'datePickerDefault'}
                          locale={locale}
                          format={'YYYY-MM-DD'}
                          placeholder={'Pilih Tanggal'}
                          onOpenChange={handleValidateFirstDate}
                          value={ValueFirstDate}
                          onChange={handleChangeFirstDate}
                          disabledDate={minDate}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Sampai Tanggal</b>
                      </span>
                      <Form.Item
                        validateStatus={ValidateLastDate}
                        help={helpLastDate}
                      >
                        <DatePicker
                          className={'datePickerDefault'}
                          locale={locale}
                          defaultPickerValue={moment(firstDateV, formatDate)}
                          format={'YYYY-MM-DD'}
                          placeholder={'Pilih Tanggal'}
                          onOpenChange={handleValidateLastDate}
                          value={ValueLastDate}
                          onChange={handleChangeLastDate}
                          disabledDate={minDate2}
                          disabled={validateLastDate}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12} className="spacingLeft">
                    <div className="spacingTopRightReport">
                      <span>
                        <b>Posko</b>
                      </span>
                      <Form.Item>
                        <Select
                          loading={loadingSelectBranch}
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          disabled={roleAccount === 'Branch' ? true : false}
                          showSearch
                          onChange={handlePutBranch}
                          onSelect={handleResetCourier}
                          onBlur={handleBlurBranch}
                          value={
                            branchAuth !== null && branchAuth !== 0
                              ? branchAuth
                              : ValueGetBranch
                          }
                          onFocus={handleGetBranch}
                        >
                          {BranchList.map((data: any, index: any) => (
                            <Option key={index} value={data.id}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Kurir</b>
                      </span>
                      <Form.Item>
                        <Select
                          loading={loadingSelectCourier}
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          disabled={validateCourierForm}
                          showSearch
                          mode="multiple"
                          placeholder="Pilih Kurir"
                          allowClear={true}
                          onDeselect={handleBlurSelectKurir}
                          onSelect={handleBlurSelectKurir}
                          onChange={handleSelectCourier}
                          onBlur={handleBlurSelectKurir}
                          value={GetCourier}
                          onFocus={handleGetCourier}
                        >
                          {ListCourier.length === GetCourier.length ? null : (
                            <Option key={''} value={'SetAllCourier'}>
                              {'Pilih Semua Kurir'}
                            </Option>
                          )}
                          {ListCourier.map((data: any, index: any) => (
                            <Option key={index} value={data.id}>
                              {data.firstName} ({data.code})
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className={'spacingDownloadButton'}>
                    <ButtonMaterial
                      disabled={validateDownloadButton}
                      handleSubmit={handleDownload}
                      teksButton={<b>Download</b>}
                      size={'middle'}
                      shape={'round'}
                      className={
                        validateDownloadButton === true
                          ? 'validateDownloadButtonDisabled'
                          : 'validateDownloadBtnEnable2'
                      }
                      type={'primary'}
                      icon={''}
                      style={{}}
                    />
                  </div>
                  <div>
                    <ButtonMaterial
                      disabled={GetFirstDate === null ? true : false}
                      handleSubmit={handleReset}
                      teksButton={<b>Reset</b>}
                      size={'middle'}
                      shape={'round'}
                      className={
                        GetFirstDate === null
                          ? 'validateResetButtonDisabled'
                          : 'validateResetButtonEnable'
                      }
                      type={'primary'}
                      icon={''}
                      style={{}}
                    />
                  </div>
                </Row>
              </div>
            }
          />
        }
      />
    </div>
  );
}
