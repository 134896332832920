import BastReportContainer from 'module/Report/Container/BastReport/BastReportContainer';
import ComplaintReportContainer from '../ComplaintReport/Container/ComplaintReportContainer';
import CourierBonusReportContainer from 'module/Report/Container/CourierBonusReport/CourierBonusReportContainer';
import CourierReportFormContainer from 'module/Report/Container/CourierReport/CourierReportContainer';
import CustomerReportContainer from 'module/Report/Container/CustomerReport/CustomerReportContainer';
import DeliveryReportContainer from 'module/Report/Container/DeliveryReport/DeliveryReportContainer';
import DigitalWaybillContainer from 'module/Report/Container/DigitalWaybill/DigitalWaybillContainer';
import InvoiceContainer from 'module/Report/Container/Invoice/InvoiceContainer';
import MisReportContainer from 'module/Report/Container/Mis/MisReportContainer';
import React from 'react';
import { getTabMenus } from 'lib/util';
import { Tabs } from 'antd';
import ValidateContainer from 'module/Report/Container/Validate/ValidateContainer';

const { TabPane } = Tabs;

interface IProps {
  roleAccount: any;
  lastAccessFiture: any;
  handleChangeTabsReport: any;
  sideMenus: any;
}

export default function Index({
  roleAccount,
  lastAccessFiture,
  handleChangeTabsReport,
  sideMenus,
}: IProps) {
  let index: any;
  const isBranch = roleAccount === 'Branch';
  const tabMenus = getTabMenus(sideMenus, 'Laporan', 'Download') || [];

  const componentTabMenus = {
    'Laporan Kurir': <CourierReportFormContainer />,
    'Laporan Pengiriman': <DeliveryReportContainer />,
    Faktur: <InvoiceContainer />,
    'Laporan Pelanggan': <CustomerReportContainer />,
    MIS: <MisReportContainer />,
    'Insentif Kurir': <CourierBonusReportContainer />,
    'Laporan Validasi': <ValidateContainer />,
    'Laporan Keluhan': <ComplaintReportContainer />,
    'Waybill Digital': <DigitalWaybillContainer />,
    'BAST SMS': <BastReportContainer />,
  };

  switch (lastAccessFiture) {
    case 'courierReport':
      index = '1';
      break;
    case 'deliveryReport':
      index = '2';
      break;
    case 'invoiceReport':
      index = '3';
      break;
    case 'customerReport':
      index = '4';
      break;
    case 'misReport':
      index = '5';
      break;
    case 'courierBonusReport':
      index = '6';
      break;
    case 'validateReport':
      index = '7';
      break;
    case 'complaintReport':
      index = '8';
      break;
    case 'waybillDigital':
      index = '9';
      break;
    case 'bastSms':
      index = '10';
      break;
    default:
      index = '1';
  }

  return (
    <Tabs
      className="tabDefault"
      defaultActiveKey={index}
      onChange={handleChangeTabsReport}
    >
      {tabMenus
        .filter((value: any) => {
          if (
            isBranch &&
            (value.label === 'Laporan Kurir' ||
              value.label === 'Insentif Kurir')
          ) {
            return value;
          }

          if (!isBranch) {
            return value;
          }

          return null;
        })
        .map((item, index) => (
          <TabPane
            tab={<b>{item.label}</b>}
            key={isBranch && item.label === 'Insentif Kurir' ? 6 : index + 1}
          >
            {componentTabMenus[item.label]}
          </TabPane>
        ))}
    </Tabs>
  );
}
