import * as ActionAuth from '../../../core/action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionRestoreData from '../../action/RestoreData/ActionRestoreData';
import * as ActionToggle from '../../action/toggle';

import { Modal, message } from 'antd';
import { put, select, takeLatest } from 'redux-saga/effects';

import React from 'react';
import axios from 'axios';
import moment from 'moment';

export function* S_downloadRestoreData(action: any) {
  const state = yield select();
  const key = 'download';
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const LastAccess = state.LastAccess.Fiture;
  const IdRestoreData = state.RestoreData.FormRestoreData.Id;
  const ProductType = state.RestoreData.FormRestoreData.ProductType;
  const Waybill = state.RestoreData.FormRestoreData.Waybill;
  const UploadDate = moment(
    LastAccess === null || LastAccess === 'restoreDataCsv'
      ? state.RestoreData.FormRestoreData.UploadDate
      : state.RestoreData.FormRestoreData.UploadDateW
  ).format('YYYYMMDD');

  try {
    Modal.info({
      title: <p style={{ color: '#11beff' }}>{<b>Download Data</b>}</p>,
      content: (
        <div>
          <p>Sedang mendownload data, mohon tunggu</p>
        </div>
      ),
    });
    const url =
      LastAccess === null || LastAccess === 'restoreDataCsv'
        ? `${process.env.REACT_APP_URL}/Delivery/Download/ResultDelivery/${IdRestoreData}`
        : `${process.env.REACT_APP_URL}/Delivery/Download/ResultDeliveryByWaybill/${Waybill}`;
    axios({
      url: url,
      method: 'POST',
      responseType: 'blob',
      data: '',
      headers: config,
      onDownloadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        let percentProgress = Math.floor((loaded * 100) / total);

        if (percentProgress < 100) {
          message.loading({
            content: 'Loading ' + percentProgress + '%',
            key,
          });
        } else if (percentProgress === 100) {
          Modal.destroyAll();
          message.success({
            content: 'Download Berhasil',
            key,
            duration: 3,
          });
        } else if (percentProgress === Infinity) {
          Modal.destroyAll();
          message.error({
            content: 'Download Gagal',
            key,
            duration: 3,
          });
        }
      },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${UploadDate}_${ProductType}.csv`);
      document.body.appendChild(link);
      link.click();
    });
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionToggle.clearJenisToggle());
    yield put(ActionRestoreData.clearDataRestoreData());
  } catch (error) {
    yield put(ActionToggle.changeLoadingFalse());
    if (error.response.data.Message === 'No delivery found!') {
      message.warning({
        content: 'Restore Data Tidak Tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* SM_downloadRestoreData() {
  yield takeLatest('SUBMIT_DOWNLOAD_RESTORE_DATA', S_downloadRestoreData);
}
