import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionServerVoip from '../../../action/Voip/Server/ActionServer';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_getDetailServerVoip(action: any) {
  const state = yield select();
  const Id = state.Voip.ServerVoip.Id;
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/VoipSetting/${Id}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    const input = data.ipAddress;
    const fields = input.split('.');
    const ip1 = fields[0];
    const ip2 = fields[1];
    const ip3 = fields[2];
    const ip4 = fields[3];
    const fields1 = input.split(':');
    const ipPort = fields1[1];
    const ip4new = ip4.replace(':' + ipPort, '');

    yield put(ActionServerVoip.setServerVoipName(data.nameServer));
    yield put(ActionServerVoip.setServerVoipIpServer1(ip1));
    yield put(ActionServerVoip.setServerVoipIpServer2(ip2));
    yield put(ActionServerVoip.setServerVoipIpServer3(ip3));
    yield put(ActionServerVoip.setServerVoipIpServer4(ip4new));
    yield put(ActionServerVoip.setServerVoipIpPort(ipPort));
    yield put(ActionServerVoip.setServerVoipKuota(data.kuota));
    yield put(ActionServerVoip.setServerVoipPosition(data.position));
    yield put(ActionServerVoip.setServerVoipToggle(data.status));
    yield put(toggle.changeModal());
    yield put(toggle.changeJenisToggle('EditServerVoip'));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}

export function* SM_getDetailServerVoip() {
  yield takeLatest('SUBMIT_DETAIL_SERVER_VOIP', S_getDetailServerVoip);
}
