import { Checkbox, Form } from 'antd';

import React from 'react';

interface IPropsCheckBox {
  input?;
  meta?;
  label?;
  disabled?;
  defaultValues?;
  className?;
  classNameLabel?;
  formClassName?;
  labelRight?;
  onChange?: () => void;
}
export const singleCheckbox = (props: IPropsCheckBox) => {
  const {
    input,
    meta: { touched, error },
    label,
    className,
    classNameLabel,
    disabled,
    labelRight,
    onChange = () => undefined,
  } = props;

  const handleOnChange = e => {
    onChange();
    input.onChange(e);
  };
  const labelTitle = label ? (
    <span>
      <b className={classNameLabel}>{label}</b>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      style={{ marginBottom: '0px' }}
    >
      <Checkbox
        onChange={handleOnChange}
        className={className}
        checked={input.value}
        disabled={disabled}
      >
        {labelRight}
      </Checkbox>
    </Form.Item>
  );
};
