import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

export function* S_getDownloadDigitalWaybill(action: any) {
  const state = yield select();
  const radioButtonDate = state.Report.DigitalWaybillReportForm.RadioButtonDate;
  const firstDate = moment(
    state.Report.DigitalWaybillReportForm.FirstDate
  ).format('YYYYMMDD');
  const lastDate = moment(
    state.Report.DigitalWaybillReportForm.LastDate
  ).format('YYYYMMDD');
  const firstDateName = moment(
    state.Report.DigitalWaybillReportForm.FirstDate
  ).format('D MMM YYYY');
  const lastDateName = moment(
    state.Report.DigitalWaybillReportForm.LastDate
  ).format('D MMM YYYY');
  const customerId = state.Report.DigitalWaybillReportForm.Customer;
  const customerName = state.Report.DigitalWaybillReportForm.CustomerName;
  const customerProductId =
    state.Report.DigitalWaybillReportForm.CustomerProduct;
  const deliveryType = state.Report.DigitalWaybillReportForm.DeliveryType;
  const deliveryStatus = state.Report.DigitalWaybillReportForm.DeliveryStatus;
  const flagFileName = state.Report.DigitalWaybillReportForm.FlagFileName;
  const key = 'download';
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = `${customerName}, ${firstDateName} - ${lastDateName}.zip`;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }
  const token = state.Auth.Token;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Delivery/DownloadWaybillDigital',
      {
        customerId: customerId,
        filterDateType: radioButtonDate,
        dateStart: firstDate,
        dateEnd: lastDate,
        customerProducts: customerProductId,
        deliveryStats: deliveryStatus,
        deliveryType: deliveryType,
        fileNameWaybill: flagFileName,
      },
      {
        responseType: 'arraybuffer',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);
          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    if (error.response.status === 500) {
      message.error({
        content: 'Download Gagal',
        key,
        duration: 3,
      });
    }
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_getDownloadDigitalWaybill() {
  yield takeLatest('DOWNLOAD_DIGITAL_WAYBILL', S_getDownloadDigitalWaybill);
}
