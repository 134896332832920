export function setIdCsv(IdCsv: any) {
  return {
    type: 'CHANGE_Id_CSV',
    IdCsv,
  };
}

export function setFileSettingNameCsv(FileSettingName: any) {
  return {
    type: 'CHANGE_FILE_SETTING_NAME_CSV',
    FileSettingName,
  };
}
export function setStatusDefaultCsv(StatusDefault: any) {
  return {
    type: 'CHANGE_STATUS_DEFAULT_CSV',
    StatusDefault,
  };
}
export function setReverseBarcodeCsv(ReverseBarcode: any) {
  return {
    type: 'CHANGE_REVERSE_BARCODE_CSV',
    ReverseBarcode,
  };
}
export function setCustomerNameCsv(CustomerNameCsv: any) {
  return {
    type: 'CHANGE_CUSTOMER_NAME_CSV',
    CustomerNameCsv,
  };
}
export function setDelimitedCsv(DelimitedCsv: any) {
  return {
    type: 'CHANGE_DELIMITED_CSV',
    DelimitedCsv,
  };
}
export function changeFileType(FileType: any) {
  return {
    type: 'CHANGE_FILE_TYPE',
    FileType,
  };
}
export function setCycleCsv(CycleCsv: any) {
  return {
    type: 'CHANGE_CYCLE_CSV',
    CycleCsv,
  };
}
export function setFormatCycleCsv(FormatCycleCsv: any) {
  return {
    type: 'CHANGE_FORMAT_CYCLE_CSV',
    FormatCycleCsv,
  };
}
export function setCityCsv(CityCsv: any) {
  return {
    type: 'CHANGE_CITY_CSV',
    CityCsv,
  };
}
export function setAddressCsv(AddressCsv: any) {
  return {
    type: 'CHANGE_ADDRESS_CSV',
    AddressCsv,
  };
}
export function setAddressOffice(AddressOffice: any) {
  return {
    type: 'CHANGE_ADDRESS_OFFICE',
    AddressOffice,
  };
}
export function setListAddressCsv(ListAddressCsv: any) {
  return {
    type: 'CHANGE_LIST_ADDRESS_CSV',
    ListAddressCsv,
  };
}
export function setListAddressOffice(ListAddressOffice: any) {
  return {
    type: 'CHANGE_LIST_ADDRESS_OFFICE',
    ListAddressOffice,
  };
}
export function setBarcodeCsv(BarcodeCsv: any) {
  return {
    type: 'CHANGE_BARCODE_CSV',
    BarcodeCsv,
  };
}
export function setOwnerNameCsv(OwnerNameCsv: any) {
  return {
    type: 'CHANGE_OWNER_NAME_CSV',
    OwnerNameCsv,
  };
}
export function setPostalCodeCsv(PostalCodeCsv: any) {
  return {
    type: 'CHANGE_POSTAL_CODE_CSV',
    PostalCodeCsv,
  };
}
export function setNoTelpCsv(NoTelpCsv: any) {
  return {
    type: 'CHANGE_NO_TELP_CSV',
    NoTelpCsv,
  };
}
export function setCallLinkCsv(CallLinkCsv: any) {
  return {
    type: 'CHANGE_CALL_LINK_CSV',
    CallLinkCsv,
  };
}
export function setAdditionalDataCsv(AdditionalDataCsv: any) {
  return {
    type: 'CHANGE_ADDITIONAL_DATA_CSV',
    AdditionalDataCsv,
  };
}
export function setListAdditionalDataCsv(ListAdditionalDataCsv: any) {
  return {
    type: 'CHANGE_LIST_ADDITIONAL_DATA_CSV',
    ListAdditionalDataCsv,
  };
}
export function setTypeFormCsv(TypeFormCsv: any) {
  return {
    type: 'CHANGE_TYPE_FORM_CSV',
    TypeFormCsv,
  };
}
export function setGroupName(GroupNameCsv: any) {
  return {
    type: 'CHANGE_GROUP_NAME_FORM_CSV',
    GroupNameCsv,
  };
}
export function setSearchTypeCsv(SearchTypeCsv: any) {
  return {
    type: 'CHANGE_SEARCH_TYPE_FORM_CSV',
    SearchTypeCsv,
  };
}
export function setSearchKeyCsv(SearchKeyCsv: any) {
  return {
    type: 'CHANGE_KEY_TYPE_FORM_CSV',
    SearchKeyCsv,
  };
}
export function setCheckboxCallCenterCsv(CheckboxCallCenter: any) {
  return {
    type: 'CHANGE_CB_CALL_CENTER_FORM_CSV',
    CheckboxCallCenter,
  };
}
export function setWaybillCsv(Waybill: any) {
  return {
    type: 'CHANGE_WAYBILL_FORM_CSV',
    Waybill,
  };
}
export function setAdrressUpdateCsv(AddressUpdate: any) {
  return {
    type: 'CHANGE_ADDRESS_UPDATE_FORM_CSV',
    AddressUpdate,
  };
}
export function clearCycleCsv() {
  return {
    type: 'CLEAR_CYCLE_CSV',
  };
}
export function clearAddressCsv() {
  return {
    type: 'CLEAR_ADDRESS_CSV',
  };
}
export function clearAddressOffice() {
  return {
    type: 'CLEAR_ADDRESS_OFFICE',
  };
}
export function clearAdditionalDataCsv() {
  return {
    type: 'CLEAR_ADDITIONAL_DATA_CSV',
  };
}
export function clearListAddressCsv() {
  return {
    type: 'CLEAR_LIST_ADDRESS_CSV',
  };
}
export function clearListAddressOffice() {
  return {
    type: 'CLEAR_LIST_ADDRESS_OFFICE',
  };
}
export function clearListAdditionalDataCsv() {
  return {
    type: 'CLEAR_LIST_ADDITIONAL_DATA_CSV',
  };
}
export function clearAllDataCustomCsvCustomer() {
  return {
    type: 'CLEAR_ALL_DATA_CUSTOM_CSV_CUSTOMER',
  };
}
