import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as adminTable from '../../../action/ManageAccount/AdminBranch/ActionTableAdminBranch';
import * as formAdmin from '../../../action/ManageAccount/AdminBranch/ActionFormDetailAdminBranch';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_submitEditAdminPosko(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const IdAdmin = state.AdminBranch.PropertiesAdminBranch.idAdmin;
  const AdminName = state.AdminBranch.FormAdmin.AdminName;
  const AdminLastName = state.AdminBranch.FormAdmin.AdminLastName;
  const AdminEmail = state.AdminBranch.FormAdmin.AdminEmail;
  const AdminPhoneNumber = state.AdminBranch.FormAdmin.AdminPhoneNumber;
  const AdminDob = state.AdminBranch.FormAdmin.AdminDob;
  const AdminAddress = state.AdminBranch.FormAdmin.AdminAddress;
  const AdminPhoto = state.AdminBranch.FormAdmin.AdminPhoto;
  try {
    yield put(toggle.changeLoadingTrue());
    yield call(
      axios.put,
      process.env.REACT_APP_URL + '/Profile/' + IdAdmin,

      {
        id: IdAdmin,
        firstName: AdminName,
        lastName: AdminLastName,
        photo: AdminPhoto,
        email: AdminEmail,
        phone: AdminPhoneNumber,
        dob: AdminDob,
        branchId: 0,
        code: null,
        address: AdminAddress,
        username: null,
        password: null,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(formAdmin.changeClearDataAdminForm());
    yield put(toggle.changeModal());
    yield put(adminTable.clearAdminTable());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil Mengubah Data Admin</b>
        </p>
      ),

      onOk() {},
    });
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* SM_submitEditAdmin() {
  yield takeLatest('SUBMIT_EDIT_ADMIN', S_submitEditAdminPosko);
}
