import { createSelector } from 'reselect';

const selectorData = (state: any) => state.DataDelivery.Filter;

// SELECTOR DELIVERY DATA
export const makeDeliveryDataCourierSelector = () =>
  createSelector(selectorData, state => state.Courier);
export const makeDeliveryDataBranchSelector = () =>
  createSelector(selectorData, state => state.Branch);
export const makeDeliveryDataCustomerProductSelector = () =>
  createSelector(selectorData, state => state.CustomerProduct);
export const makeDeliveryDataCustomerSelector = () =>
  createSelector(selectorData, state => state.Customer);
export const makeDeliveryDataCycleEndSelector = () =>
  createSelector(selectorData, state => state.CycleEnd);
export const makeDeliveryDataCycleStartSelector = () =>
  createSelector(selectorData, state => state.CycleStart);
export const makeDeliveryDataRadioButtonSelector = () =>
  createSelector(selectorData, state => state.RadioButton);
export const makeDeliveryDataStatusSelector = () =>
  createSelector(selectorData, state => state.DeliveryStatus);
export const makeDeliveryDataPhysicalDocumentSelector = () =>
  createSelector(selectorData, state => state.PhysicalDocument);
