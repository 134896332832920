// DY: DEV CHANGES 4 JUL 24

import React from 'react';
import InputFile from 'components/InputFile';
import ModalMaterial from 'materials/ModalMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import Button from 'components/Button';
import styles from './ModalUpdateLocation.module.css';
import { Form, Field } from 'react-final-form';
import { FormUpdateLocationType } from 'module/SettingLocation/container/UpdateLocation';
import ContentAlertWarning from 'components/ContentAlertWarning';

type ModalUpdateLocationProps = {
  handleModal: () => void;
  mutateDownloadFile: () => void;
  show: boolean;
  isLoadingDownload: boolean;
  isLoading: boolean;
  handleSubmit: () => void;
  handleUploadFile: (value: File) => void;
  initialValue: FormUpdateLocationType;
};

export default function ModalUpdateLocation({
  handleModal,
  mutateDownloadFile,
  show = false,
  isLoadingDownload = false,
  isLoading = false,
  handleSubmit,
  handleUploadFile,
  initialValue,
}: ModalUpdateLocationProps) {
  return (
    <ModalMaterial
      width={'50%'}
      teksTitle={'UPDATE WILAYAH'}
      visible={show}
      onCancel={handleModal}
    >
      <SpinMaterial spinning={isLoading} size="large">
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValue}
          validate={value => {
            const maxSize = 15 * 1024 * 1024;
            const error = {} as any;
            if (
              initialValue.fileUpload &&
              initialValue.fileUpload.size > maxSize
            ) {
              error.fileName = 'File tidak boleh lebih dari 15mb';
            }

            if (!value.fileName) {
              error.fileName = 'Dokumen tidak boleh kosong';
            }
            return error;
          }}
        >
          {({ handleSubmit, valid }) => (
            <form className={styles.wrapper} onSubmit={handleSubmit}>
              <ContentAlertWarning title="Setiap kode pos adalah unik dan hanya dapat dikaitkan dengan satu sort code. Pastikan sort code yang Anda perbarui hanya dipakai untuk satu kode pos.">
                <p>Contoh:</p>
                <p>
                  Kode pos Gambir 10110 dengan Sortcode GMB1.001{' '}
                  <b>penulisannya “10110;GMB1.001”</b>
                </p>
                <p>
                  Kode pos Kebon Kelapa 10120 dengan Sortcode GMB2.001{' '}
                  <b>penulisannya “10120;GMB2.001”</b>
                </p>
              </ContentAlertWarning>
              <div>
                <Field name="fileName">
                  {({ input, meta }) => (
                    <InputFile
                      label="Unggah Dokumen"
                      required
                      accept=".csv"
                      extra="File excel (csv). Ukuran Max. 15 MB"
                      validateFile={meta.touched && meta.error && 'error'}
                      error={meta.error && meta.touched && meta.error}
                      handleChangeFile={handleUploadFile}
                      value={input.value}
                    />
                  )}
                </Field>
                <div style={{ margin: '5px 0 10px' }}>
                  <span className="fontItalicaAndUnderline">
                    {/* eslint-disable-next-line */}
                    <a
                      style={{ padding: 0 }}
                      onClick={mutateDownloadFile}
                      className="fontItalicaAndUnderline"
                    >
                      {isLoadingDownload
                        ? 'Loading...'
                        : 'Download sample file'}
                    </a>
                  </span>
                </div>
              </div>

              <Button
                text="Simpan"
                className={styles.buttonAction}
                htmlType="submit"
                disabled={!valid}
              />
            </form>
          )}
        </Form>
      </SpinMaterial>
    </ModalMaterial>
  );
}
