// DY: DEV CHANGES 4 JUL 24

import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { SelectOptionsInterface } from 'interface/general';
import {
  getBundleCode,
  postManifestBrankas,
} from 'services/ManifestSaveBrankas';
import FormManifestBrankas from '../components/FormManifestBrankas';
import ModalStatus from 'materials/ModalStatus';

type ModalAddManifestBrankasProps = {
  isShowModalAdd: boolean;
  setIsShowModalAdd: () => void;
  setIsShowModalScan: (value: number) => void;
  dataManifestRetur: SelectOptionsInterface[];
  dataCustomers: SelectOptionsInterface[];
  loadingManifestRetur: boolean;
  onClickAddRetur: () => void;
  refetchTable: () => void;
};

export type DataManifestBrankasProps = {
  cycleDate: any | null;
  isMonthly: boolean;
  bundleCode: string;
  type: string;
  maxBundle: number;
  customer: number | null;
  customerProducts: number[];
  manifestSafeId: number | null;
};

const initialValues = {
  cycleDate: null,
  isMonthly: false,
  bundleCode: '',
  type: 'Return',
  maxBundle: 1,
  customer: null,
  customerProducts: [],
  manifestSafeId: null,
};

export default function ModalAddManifestBrankas({
  isShowModalAdd,
  setIsShowModalAdd,
  dataManifestRetur,
  loadingManifestRetur,
  onClickAddRetur,
  dataCustomers,
  refetchTable,
  setIsShowModalScan,
}: ModalAddManifestBrankasProps) {
  const [dataManifestBrankas, setDataManifestBrankas] =
    useState<DataManifestBrankasProps>(initialValues);

  const { mutate: submitManifestBrankas, isLoading: isLoadingSubmitForm } =
    useMutation(postManifestBrankas, {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Data berhasil disimpan',
            handleOke: () => setIsShowModalScan(res.data),
          });
          setIsShowModalAdd();
          refetchTable();
          setDataManifestBrankas(initialValues);
          refetchBundleCode();
        } else {
          if (
            res.response?.data?.Message === 'BundleCode sudah pernah diinput.'
          ) {
            refetchBundleCode();
          }
          ModalStatus({
            status: 'error',
            title: 'Gagal Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const { isFetching: isLoadingBundle, refetch: refetchBundleCode } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataBundleCodeManifest'],
    queryFn: () => getBundleCode(),
    onSuccess(res) {
      handleChangeField('bundleCode', res.data);
    },
  });

  const handleChangeField = (name: string, value: any) => {
    setDataManifestBrankas(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (value: DataManifestBrankasProps) => {
    submitManifestBrankas(value);
  };

  return (
    <FormManifestBrankas
      isShowModalAdd={isShowModalAdd}
      setIsShowModalAdd={setIsShowModalAdd}
      dataManifestRetur={dataManifestRetur}
      loadingManifestRetur={loadingManifestRetur}
      onClickAddRetur={onClickAddRetur}
      dataCustomers={dataCustomers}
      dataManifestBrankas={dataManifestBrankas}
      handleChangeField={handleChangeField}
      isLoadingForm={isLoadingBundle || isLoadingSubmitForm}
      handleSubmit={handleSubmit}
    />
  );
}
