// DY: DEV CHANGES 4 JUL 24

import React, { useState } from 'react';
import SpinMaterial from 'materials/SpinMaterial';
import CardMaterial from 'materials/CardMaterial';
import SearchMatrial from 'materials/SearchMatrial';
import ButtonMaterial from 'materials/ButtonMaterial';
import { DropdownBasic } from 'components/DropdownBasic';
import { Col, Row, Table } from 'antd';
import { DataTableResProps } from 'interface/general';
import { trimText } from 'lib/util';
import ExpandRowItemCard, {
  customExpandIcon,
} from 'components/ExpandRowItemCard';

type SettingLocationComponentProps = {
  handleModalUpdate: () => void;
  dataTable: DataTableResProps;
  isLoadingTable: boolean;
  isActiveType: string;
  handlePage: (value: number) => void;
  handleSearchValue: (value: string) => void;
  handleIsActiveType: (value: string) => void;
  handleShowModalEdit: (value: number) => void;
};

export default function SettingLocationComponent({
  handleModalUpdate,
  dataTable,
  isLoadingTable,
  handleSearchValue,
  handlePage,
  handleIsActiveType,
  isActiveType,
  handleShowModalEdit,
}: SettingLocationComponentProps) {
  const [valueSearch, setValueSearch] = useState('');
  const pagePagination = dataTable?.page + 1 || 0;
  const totalPagination = dataTable?.totalPages * 10 || 0;
  const looppage = (pagePagination - 1) * 10 + 1;

  const columns = [
    {
      title: 'No',
      render: (text: any, record: any, index: any) => (
        <span>{pagePagination > 1 ? index + looppage : index + 1}</span>
      ),
      width: 80,
    },
    {
      title: 'Kode Pos',
      dataIndex: 'postalCode',
    },
    {
      title: 'Sortcode',
      dataIndex: 'sortCode',
    },
    {
      title: 'Posko',
      dataIndex: 'branch',
      render: (branch: any) => {
        return <span style={{ color: '#1890FF' }}>{branch}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (status: boolean) => (
        <p style={{ color: status ? '#00AB44' : '#EA1A1A', margin: 0 }}>
          {status ? 'Aktif' : 'Nonaktif'}
        </p>
      ),
    },
    {
      title: 'Provinsi',
      dataIndex: 'province',
    },
    {
      title: 'Kota/Kabupaten',
      dataIndex: 'city',
    },
    {
      title: 'Kecamatan',
      dataIndex: 'subDistrict',
    },
    {
      title: 'Aksi',
      key: 'aksi',
      width: 80,
      render: (record: any) => (
        <div>
          <ButtonMaterial
            icon="edit"
            style={{
              borderRadius: 8,
              color: '#ffffff',
              background: '#FCC642',
            }}
            handleSubmit={() => handleShowModalEdit(record.id)}
          />
        </div>
      ),
    },
  ];
  const optionsFilter = [
    {
      label: 'Aktif',
      key: 'Active',
    },
    {
      label: 'Tidak aktif',
      key: 'Disable',
    },
    {
      label: 'Semua',
      key: 'None',
    },
  ];
  return (
    <div className="paddingTable">
      <SpinMaterial spinning={isLoadingTable} size={'large'}>
        <CardMaterial>
          <Row>
            <Col span={16}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                <span className="titleSearchCard" style={{ padding: 0 }}>
                  DATA WILAYAH
                </span>
                <SearchMatrial
                  style={{ borderRadius: '10px', width: '50%' }}
                  placeholder="Cari berdasarkan kode pos, sortcode, dan kelurahan"
                  value={valueSearch}
                  onChange={e => setValueSearch(trimText(e.target.value))}
                  onPressEnter={e => {
                    handlePage(0);
                    handleSearchValue(e.target.value);
                  }}
                />
                <DropdownBasic
                  name="filterValue"
                  idComponent="filterValue"
                  dropdownMenu={optionsFilter}
                  triggerAction={'click'}
                  className="floatLeft"
                  labelTitle={
                    optionsFilter.find(value => value.key === isActiveType)
                      ?.label
                  }
                  onChange={value => {
                    handlePage(0);
                    handleIsActiveType(value.key);
                  }}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className="floatRight">
                <ButtonMaterial
                  handleSubmit={handleModalUpdate}
                  teksButton={<b>UPDATE WILAYAH</b>}
                  className={'btnLink'}
                  type={'link'}
                />

                <ButtonMaterial
                  handleSubmit={handleModalUpdate}
                  shape={'circle'}
                  className={'btnAdd'}
                  icon={'plus'}
                  style={{}}
                />
              </div>
            </Col>
          </Row>
        </CardMaterial>
        <Table
          pagination={{
            onChange: page => {
              handlePage(page - 1);
            },
            pageSize: 10,
            total: totalPagination,
            current: pagePagination === 0 ? 1 : pagePagination,
          }}
          bordered={false}
          dataSource={dataTable?.data || []}
          columns={columns}
          expandIcon={props => customExpandIcon(props)}
          expandedRowRender={record => (
            <ExpandRowItemCard
              label="Kelurahan/Desa"
              datas={record?.villages || []}
            />
          )}
        />
      </SpinMaterial>
    </div>
  );
}
