import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionComponent from '../../../App/Store/ComponentAction';
import * as ActionLogin from '../../../Login/Store';
import * as ActionPickupStatus from '../Store/PickupStatusAction';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { getFormValues } from 'redux-form';

function* S_getPickupStatusProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const formValue = yield select(getFormValues('pickupStatusForm'));
  try {
    yield put(ActionComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/RequestPickup/Status/Waybill/${formValue.valueSearchPickup}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionPickupStatus.fetchPickupStatusDoc(data));
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}
export function* SM_getPickupStatusAction() {
  yield takeLatest('GET_PICKUP_STATUS_REQUESTED', S_getPickupStatusProcess);
}
