import * as ActionLogin from '../../../module/Login/Store';
import * as ActionAuth from '../../../core/action/Auth';
import * as ActionPagination from '../../action/Pagination/ActionPagination';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  changeLoadingFalse,
  changeLoadingTrue,
} from '../../action/toggle/index';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import { SetTableBranch } from '../../action/Branch/ActionTableBranch';
import axios from 'axios';

export function* S_GetDataTableBranch() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Branch/0/100',
      { headers: config }
    );

    yield put(SetTableBranch(data.data));

    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      yield put(changeLoadingFalse());
      const propsError = ErrorMassage('table posko');
      const code = propsError.filter((data: any) =>
        data.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* S_searchTableBranch(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const searchValue = state.Toggle.ValueSearch;
  // const pagePagination = state.Pagination.PagePagination;
  // const sizeDataPagination = state.Pagination.SizeDataPagination;
  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/SearchDataBranch/0/10000?NameOrCode=${searchValue}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(SetTableBranch(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(changeLoadingFalse());
  }
}

export function* SM_GetDataTableBranch() {
  yield takeLatest('GET_TABLE_BRANCH', S_GetDataTableBranch);
}

export function* SM_SearchTableBranch() {
  yield takeLatest('SUBMIT_SEARCH_BRANCH', S_searchTableBranch);
}
