import 'index.css';
import 'styles/DeliveryStatus/index.css';
import 'styles/ProductLogoStyle/index.css';

import { Button, Card, Col, Dropdown, Icon, Menu, Row, Tag } from 'antd';
import {
  EnumDeliveryType,
  EnumLabelSearchType,
} from 'core/enumerable/DeliveryType';

import ModalMaterial from '../../../materials/ModalMaterial';
import React from 'react';

interface IProps {
  rotation: any;
  visible: any;
  typeToggle: any;
  getDataDocument: any;
  getFilterTypeDS: any;
  getValueTypeDS: any;
  getDistrictDS: any;
  getDimention: any;
  getAttempt: any;
  getReceiptNumber: any;
  getTotalGroupDocument: any;
  getTotalWeight: any;
  getKoli: any;
  getProcessTime: any;
  getDeliveryTime: any;
  getFinishTime: any;
  getDateEstimation: any;
  getBarcode: any;
  getWaybill: any;
  getWaybillForm: any;
  getDocumentType: any;
  getDeliveryType: any;
  getDeliveryPosition: any;
  getCourierCode: any;
  getCourierName: any;
  getOwnerName: any;
  getNoTelp: any;
  getAddress: any;
  getCity: any;
  getZipCode: any;
  getDeliveryDuration: any;
  getImage: any;
  getSignature: any;
  getProductImage: any;
  getStatusDelivery: any;
  getReceiverName: any;
  getReceiverTitle: any;
  getReturnedReason: any;
  getReceiverLocation: any;
  getAdditionalData: any;
  getOtherData: any;
  getExportDeliveryStatus: any;
  getComplaint: any;
  CollapsListOther: any;
  CollapsKeyListOther: any;
  iconSlection: any;
  handleModalImage: any;
  handleModalSignature: any;
  handleModalAdditionalData: any;
  handleCloseModal: any;
  handleCollapsListOther: any;
  handleSlectionExportPdf: any;
  handleIconSlection: any;
  handleExportPdf: any;
}

export default function DocumentInfoComponent({
  visible,
  typeToggle,
  getDataDocument,
  getFilterTypeDS,
  getValueTypeDS,
  getTotalWeight,
  getKoli,
  getProcessTime,
  getBarcode,
  getDocumentType,
  getDeliveryType,
  getAddress,
  getCity,
  getZipCode,
  getImage,
  getSignature,
  getProductImage,
  getAdditionalData,
  getExportDeliveryStatus,
  handleModalImage,
  handleModalSignature,
  handleModalAdditionalData,
  handleCloseModal,
  handleSlectionExportPdf,
  handleExportPdf,
}: IProps) {
  const validateButtonPdf = getExportDeliveryStatus === null ? true : false;
  const placeholderFormPdf =
    getExportDeliveryStatus === null ? (
      <span style={{ color: '#969696', borderRadius: '15px' }}>
        Pilih Download
      </span>
    ) : getExportDeliveryStatus === 'waybillPelanggan' ? (
      'Waybill Pelanggan'
    ) : (
      'Waybill Kurir'
    );
  const showValue = getDataDocument === null ? '-' : getDataDocument;
  const showImgValue = (img: any) => {
    if (img !== null) {
      const showImgValue =
        img.substring(0, 4) !== 'http' ? `data:image/jpeg;base64,${img}` : img;
      return showImgValue;
    } else {
      return img;
    }
  };
  const menu = (
    <Menu onClick={handleSlectionExportPdf}>
      <Menu.Item key="waybillPelanggan">Waybill Pelanggan</Menu.Item>
    </Menu>
  );
  const renderData = (label: any, content: any) => {
    return (
      <Row style={{ paddingBottom: '6px', paddingTop: '1px' }}>
        <Col span={7}>
          <div>
            <span>{label}</span>
          </div>
        </Col>
        <Col span={1}>
          <span>:</span>
        </Col>
        <Col span={16} style={{ paddingLeft: '10px' }}>
          <div>
            <span>
              <b>{content}</b>
            </span>
          </div>
        </Col>
      </Row>
    );
  };
  let address =
    getAddress === null ? '' : `${Object.values(getAddress).join(' ')}`;

  var myObj = getAdditionalData;
  const renderLooping = () => {
    const dataComponent: any = [];
    for (const key in myObj) {
      if (Object.prototype.hasOwnProperty.call(myObj, key)) {
        const element = myObj[key];
        dataComponent.push(
          <Row style={{ marginLeft: 20 }}>
            <Col style={{ marginTop: 10 }} span={8}>
              <b>{`${key}`}</b>
            </Col>
            <Col style={{ marginTop: 10 }} span={16}>
              {`: ${element}`}
            </Col>
          </Row>
        );
      }
    }
    return dataComponent;
  };
  const showWeight =
    getTotalWeight !== null && getKoli !== null
      ? `${getTotalWeight} Kg ( ${getKoli} )`
      : getTotalWeight !== null && getKoli === null
      ? `${getTotalWeight} Kg`
      : '';

  const styles = {
    cardBody: {
      maxHeight: '100%',
      overflow: 'auto',
    },
  };
  return (
    <div>
      <div>
        {typeToggle === 'signature' ? (
          <ModalMaterial
            className={''}
            width={'40%'}
            style={{ paddingTop: '20px' }}
            teksTitle={'SIGNATURE'}
            visible={
              visible === true && typeToggle === 'signature' ? true : false
            }
            onCancel={handleCloseModal}
            content={
              <div>
                <div>
                  <img
                    className="image-gallery-slide"
                    alt="Signature"
                    src={`data:image/jpeg;base64,${getSignature}`}
                  />
                </div>
              </div>
            }
          />
        ) : typeToggle === 'image' ? (
          <ModalMaterial
            className={''}
            width={'40%'}
            style={{ paddingTop: '20px' }}
            teksTitle={'FOTO'}
            visible={visible === true && typeToggle === 'image' ? true : false}
            onCancel={handleCloseModal}
            content={
              <div>
                <img
                  className="image-gallery-slide"
                  alt="imagePhoto"
                  src={showImgValue(getImage)}
                />
              </div>
            }
          />
        ) : typeToggle === 'additionalData' ? (
          <ModalMaterial
            className={''}
            width={'40%'}
            style={{ paddingTop: '20px' }}
            teksTitle={'Data Lainnya'}
            visible={
              visible === true && typeToggle === 'additionalData' ? true : false
            }
            onCancel={handleCloseModal}
            content={renderLooping()}
          />
        ) : (
          ''
        )}
      </div>
      <Card
        bodyStyle={styles.cardBody}
        style={{ borderRadius: '10px', height: '520px', maxHeight: '100%' }}
      >
        <div>
          <span style={{ color: '#11BEFF', fontSize: 15 }}>
            <b>Informasi Dokumen</b>
          </span>
          <div style={{ fontSize: 13, paddingTop: '10px' }}>
            {getProductImage && (
              <div style={{ paddingBottom: '15px', paddingTop: '10px' }}>
                <img
                  className={'img'}
                  height={'50px'}
                  width={'75%'}
                  alt="productImage"
                  src={`data:image/png;base64, ${getProductImage}`}
                />
              </div>
            )}
            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: 12,
                justifyContent: 'space-between',
                padding: '10px 0',
              }}
            >
              {showValue.image && (
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 12,
                  }}
                >
                  <img
                    src={showImgValue(showValue.image)}
                    alt=""
                    style={{
                      width: '100%',
                      height: '200px',
                      objectFit: 'cover',
                      borderRadius: 10,
                      cursor: 'pointer',
                    }}
                    onClick={handleModalImage}
                  />
                  <p>Foto</p>
                </div>
              )}
              {showValue.deliveryStatus !== 'Return' &&
                showValue.deliveryStatus !== 'Return3x' &&
                showValue.signature && (
                  <div
                    style={{
                      width: '50%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 12,
                    }}
                  >
                    <img
                      src={showImgValue(showValue.signature)}
                      alt=""
                      style={{
                        width: '100%',
                        height: '200px',
                        objectFit: 'cover',
                        borderRadius: 10,
                        cursor: 'pointer',
                      }}
                      onClick={handleModalSignature}
                    />
                    <p>Tanda Tangan</p>
                  </div>
                )}
            </div>
            {getProcessTime && (
              <Row style={{ paddingBottom: '6px', paddingTop: '1px' }}>
                <Col span={7}>
                  <div>
                    <span>Status</span>
                  </div>
                </Col>
                <Col span={1}>
                  <span>:</span>
                </Col>
                <Col span={16} style={{ paddingLeft: '10px' }}>
                  <div>
                    <Tag
                      color={
                        showValue.deliveryStatus === 'Success'
                          ? 'green'
                          : showValue.deliveryStatus === 'Return' ||
                            showValue.deliveryStatus === 'Return3x'
                          ? 'red'
                          : 'gray'
                      }
                    >
                      <span style={{ fontSize: 14 }}>
                        {showValue.deliveryStatus === 'Success'
                          ? 'Diterima'
                          : showValue.deliveryStatus === 'Return' ||
                            showValue.deliveryStatus === 'Return3x'
                          ? 'Dikembalikan'
                          : showValue.deliveryStatus === 'OnProcces'
                          ? 'Diproses'
                          : showValue.deliveryStatus}
                      </span>
                    </Tag>
                    {showValue.complaint === null ? null : (
                      <Tag color="gold">
                        <span style={{ fontSize: 14 }}>
                          {showValue.complaint}
                        </span>
                      </Tag>
                    )}
                    {showValue.deliveryStatus === 'Return' ||
                    showValue.deliveryStatus === 'Return3x' ? (
                      <span style={{ fontSize: 14 }}>
                        <b>Ke-{showValue.deliveryPosition}</b>
                      </span>
                    ) : (
                      ''
                    )}
                    {showValue.attempt === true ? (
                      <span style={{ color: 'red', fontWeight: 'bold' }}>
                        (Attempt)
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
              </Row>
            )}
            {(showValue.deliveryStatus !== 'OnProcces' ||
              showValue.deliveryStatus !== 'OnHold') &&
              getProcessTime && (
                <>
                  {renderData(
                    showValue.deliveryStatus === 'Success'
                      ? 'Nama Penerima'
                      : showValue.deliveryStatus === 'Return' ||
                        showValue.deliveryStatus === 'Return3x'
                      ? 'Alasan'
                      : '',
                    showValue.deliveryStatus === 'Success'
                      ? showValue.receiverName + '/' + showValue.receiverTitle
                      : showValue.deliveryStatus === 'Return' ||
                        showValue.deliveryStatus === 'Return3x'
                      ? showValue.returnedReason
                      : ''
                  )}
                  <Row style={{ paddingBottom: '6px', paddingTop: '1px' }}>
                    <Col span={7}>
                      <div>
                        <span>Lihat Lokasi</span>
                      </div>
                    </Col>
                    <Col span={1}>
                      <span>:</span>
                    </Col>
                    <Col span={16} style={{ paddingLeft: '10px' }}>
                      <div>
                        {showValue.receiverLocation === null ||
                        showValue.receiverLocation === '' ||
                        showValue.receiverLocation === undefined ? (
                          '-'
                        ) : (
                          <a
                            href={
                              'http://maps.google.com/maps?z=18&q=' +
                              showValue.receiverLocation
                            }
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <span>
                              <span>{showValue.receiverLocation}</span>
                            </span>
                          </a>
                        )}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            {renderData('Waybill', showValue.waybillCode)}
            {renderData('Cycle', showValue.cycle)}
            {renderData('Nama Pemilik', showValue.shipToName)}
            {renderData(
              'No Tlpn',
              showValue.itemProcessTime === null ||
                showValue.itemProcessTime === ''
                ? ''
                : showValue.shipToPhone === '' || showValue.shipToPhone === null
                ? '-'
                : showValue.shipToPhone
            )}
            {renderData(
              'Alamat',
              address +
                ' ' +
                (getCity === null ? '' : getCity) +
                ' ' +
                (getZipCode === null ? '' : getZipCode)
            )}

            {renderData('Barcode', getBarcode)}
            {getFilterTypeDS === 'Empty' || getFilterTypeDS === null ? (
              ''
            ) : (
              <React.Fragment>
                {renderData(
                  EnumLabelSearchType(getFilterTypeDS),
                  getValueTypeDS === null ? '-' : getValueTypeDS
                )}
              </React.Fragment>
            )}

            {getProcessTime === null ? (
              ''
            ) : getProcessTime === '' ? (
              ''
            ) : (
              <div>
                {showValue.deliveryStatus === 'OnProcces' ||
                showValue.deliveryStatus === 'OnHold' ? (
                  ''
                ) : (
                  <div>
                    <Row style={{ paddingBottom: '6px', paddingTop: '20px' }}>
                      <Col span={7}>
                        <div>
                          <span>Lama Pengiriman</span>
                        </div>
                      </Col>
                      <Col span={1}>
                        <span>:</span>
                      </Col>
                      <Col span={16} style={{ paddingLeft: '10px' }}>
                        {showValue.itemDeliveryTime === null ? (
                          ''
                        ) : (
                          <div>
                            <span>
                              <b>{showValue.estimatedDelivery} Hari Kerja</b>
                            </span>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            )}

            <Row style={{ paddingBottom: '6px', paddingTop: '1px' }}>
              <Col span={7}>
                <div>
                  <span>Kurir</span>
                </div>
              </Col>
              <Col span={1}>
                <span>:</span>
              </Col>
              <Col span={16} style={{ paddingLeft: '10px' }}>
                <div>
                  {getProcessTime === null || getProcessTime === '' ? (
                    ''
                  ) : showValue.courierCode === null ? (
                    '-'
                  ) : (
                    <span>
                      <b>
                        {showValue.courierCode} ({showValue.courierName})
                      </b>
                    </span>
                  )}
                </div>
              </Col>
            </Row>
            {renderData(
              'Tipe Pengiriman',
              getDeliveryType === '0' ? '-' : EnumDeliveryType(getDeliveryType)
            )}
            {showValue.brankasCode &&
              renderData('Kode Brankas', showValue.brankasCode)}
            {getProcessTime === null ? '' : getProcessTime === '' ? '' : <hr />}

            {renderData('Area', showValue.district)}
            {renderData(
              'No Resi',
              showValue.noResi === null || showValue.noResi === ''
                ? '-'
                : showValue.noResi
            )}
            {renderData(
              'Jenis Dokumen',
              getDocumentType === '0' ? '-' : getDocumentType
            )}
            {renderData('Total dok. Terkait', showValue.totalGroupDocument)}
            {renderData(
              'Dimensi',
              showValue.itemProcessTime === null
                ? ''
                : showValue.dimention === null
                ? '-'
                : showValue.dimention
            )}
            {renderData('Total Berat', showWeight)}
            {showValue.deliveryStatus === 'OnProcces' ||
            showValue.deliveryStatus === 'OnHold' ? (
              ''
            ) : (
              <React.Fragment>
                <Row style={{ paddingBottom: '6px', paddingTop: '1px' }}>
                  <Col span={7}>
                    <div>
                      <span>Data lainnya</span>
                    </div>
                  </Col>
                  <Col span={1}>
                    <span>:</span>
                  </Col>
                  {getAdditionalData === null ? (
                    ''
                  ) : (
                    <Col span={16}>
                      <div>
                        <Button type="link" onClick={handleModalAdditionalData}>
                          Lihat data
                        </Button>
                      </div>
                    </Col>
                  )}
                </Row>
              </React.Fragment>
            )}
            <div>
              <Row style={{ paddingTop: '35px' }}>
                <Col span={7}>
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button style={{ width: '160px' }}>
                      <span>{placeholderFormPdf}</span> <Icon type={'right'} />
                    </Button>
                  </Dropdown>
                </Col>
                <Col
                  span={17}
                  style={{ paddingTop: '1px', paddingLeft: '18px' }}
                >
                  <Button
                    onClick={handleExportPdf}
                    disabled={validateButtonPdf}
                    style={{
                      paddingBottom: '3px',
                      borderColor:
                        getExportDeliveryStatus === null ? '' : '#7956EE',
                      background:
                        getExportDeliveryStatus === null ? '' : '#7956EE',
                    }}
                    type="primary"
                    shape="circle"
                    icon="download"
                  ></Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
