import instance from 'config/interceptor';

export const downloadCustomerReport = async (data, onDownloadProgress: any) => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/Delivery/DownloadCustomReportCustomer`,
      data,
      { responseType: 'arraybuffer', onDownloadProgress }
    );
  } catch (error) {
    return error;
  }
};
