import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionPagination from '../../../action/Pagination/ActionPagination';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  changeLoadingFalse,
  changeLoadingTrue,
} from '../../../action/toggle/index';

import { ErrorMassage } from '../../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';
import { setCustomerAccount } from '../../../action/ManageAccount/Customer/ActionCustomerAccount';

export function* S_getCustomerAccountDataTable() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const IdCustomer = state.Customer.PropertiesCustomer.IdCustomer;
  const pagePagination =
    state.Pagination.PagePagination === 0
      ? 0
      : state.Pagination.PagePagination - 1;

  const sizeDataPagination = state.Pagination.SizeDataPagination;
  const valueSearch = state.Toggle.ValueSearch;
  const UrlSearchCondition =
    valueSearch === '' || valueSearch === null
      ? `?customerId=${IdCustomer}`
      : `?NameOrCode=${valueSearch}&customerId=${IdCustomer}`;
  try {
    yield put(changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        `/Customer/account/${pagePagination}/${sizeDataPagination}${UrlSearchCondition}`,
      { headers: config }
    );
    yield put(setCustomerAccount(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      yield put(changeLoadingFalse());
      const propsError = ErrorMassage('List data akun customer');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* SM_getCustomerAccountDataTable() {
  yield takeLatest('GET_CUSTOMER_ACCOUNT', S_getCustomerAccountDataTable);
  yield takeLatest('SUBMIT_SEARCH', S_getCustomerAccountDataTable);
}
