import React, { Component } from 'react';
import { Col, Form, Input, Row, Switch } from 'antd';
import ModalMaterial from '../../../../materials/ModalMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';
import ButtonMaterial from '../../../../materials/ButtonMaterial';

interface IProps {
  typeToggle: any;
  visible: any;
  isLoading: any;
  name: any;
  ipServer1: any;
  ipServer2: any;
  ipServer3: any;
  ipServer4: any;
  ipPort: any;
  kuota: any;
  position: any;
  toggleServer: any;
  handlePutName: any;
  handleIpServer1: any;
  handleIpServer2: any;
  handleIpServer3: any;
  handleIpServer4: any;
  handleIpPort: any;
  handlePutKuota: any;
  handlePutPosition: any;
  handleToggleServer: any;
  validateName: any;
  validateIpServer1: any;
  validateIpServer2: any;
  validateIpServer3: any;
  validateIpServer4: any;
  validateIpPort: any;
  validateKuota: any;
  validatePosition: any;
  helpValidateName: any;
  helpValidateKuota: any;
  helpValidatePosition: any;
  handleSubmitServerVoip: any;
  handleCloseModal: any;
}
export default class FormServerVoipComponent extends Component<IProps> {
  render() {
    const InputGroup = Input.Group;
    const validateServerIp =
      this.props.validateIpServer1 === 'error' ||
      this.props.validateIpServer2 === 'error' ||
      this.props.validateIpServer3 === 'error' ||
      this.props.validateIpServer4 === 'error' ||
      this.props.validateIpPort === 'error' ||
      this.props.validateIpServer1 === 'number' ||
      this.props.validateIpServer2 === 'number' ||
      this.props.validateIpServer3 === 'number' ||
      this.props.validateIpServer4 === 'number' ||
      this.props.validateIpPort === 'number' ||
      this.props.validateIpServer1 === 'regip' ||
      this.props.validateIpServer2 === 'regip' ||
      this.props.validateIpServer3 === 'regip' ||
      this.props.validateIpServer4 === 'regip'
        ? 'error'
        : '';
    const helpValidateServerIp =
      this.props.validateIpServer1 === 'error' ||
      this.props.validateIpServer2 === 'error' ||
      this.props.validateIpServer3 === 'error' ||
      this.props.validateIpServer4 === 'error' ||
      this.props.validateIpPort === 'error'
        ? 'Ip server wajib diisi'
        : this.props.validateIpServer1 === 'number' ||
          this.props.validateIpServer2 === 'number' ||
          this.props.validateIpServer3 === 'number' ||
          this.props.validateIpServer4 === 'number' ||
          this.props.validateIpPort === 'number'
        ? 'Tidak boleh selain angka'
        : this.props.validateIpServer1 === 'regip' ||
          this.props.validateIpServer2 === 'regip' ||
          this.props.validateIpServer3 === 'regip' ||
          this.props.validateIpServer4 === 'regip'
        ? 'Ip server tidak valid'
        : '';

    const ValidateButton =
      this.props.name === null ||
      this.props.ipServer1 === null ||
      this.props.ipServer2 === null ||
      this.props.ipServer3 === null ||
      this.props.ipServer4 === null ||
      this.props.ipPort === null ||
      validateServerIp === 'error' ||
      this.props.kuota === null ||
      this.props.position === null
        ? true
        : false;

    return (
      <div>
        <ModalMaterial
          className={''}
          width={'40%'}
          style={{}}
          teksTitle={
            this.props.typeToggle === 'AddServerVoip'
              ? 'TAMBAHKAN SERVER'
              : 'UBAH DATA SERVER'
          }
          visible={
            this.props.visible === true &&
            this.props.typeToggle === 'AddServerVoip'
              ? true
              : this.props.visible === true &&
                this.props.typeToggle === 'EditServerVoip'
              ? true
              : false
          }
          onCancel={this.props.handleCloseModal}
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={
                <div style={{ paddingTop: '5px' }}>
                  <div
                    style={{
                      paddingRight: '20px',
                      paddingLeft: '30px',
                      paddingTop: '20px',
                    }}
                  >
                    <div>
                      <span>
                        <b>Nama</b>
                      </span>
                      <FormInputTextMaterial
                        validateType={true}
                        onPressEnter={''}
                        className={''}
                        disabled={false}
                        addonBefore={''}
                        placeholder={'Contoh: Server Voip 1'}
                        prefix={null}
                        value={this.props.name}
                        maxLength={30}
                        style={{}}
                        size=""
                        onChange={this.props.handlePutName}
                        onBlur={this.props.handlePutName}
                        validatestatus={this.props.validateName}
                        type={''}
                        help={this.props.helpValidateName}
                      />
                    </div>

                    <div>
                      <span>
                        <b>IP Server</b>
                      </span>
                      <Form.Item
                        validateStatus={validateServerIp}
                        help={helpValidateServerIp}
                      >
                        <InputGroup style={{ marginTop: 3 }}>
                          <Row>
                            <Col span={3}>
                              <Input
                                value={this.props.ipServer1}
                                placeholder={'192'}
                                maxLength={3}
                                style={{ textAlign: 'center' }}
                                onChange={this.props.handleIpServer1}
                              />
                            </Col>
                            <Col span={1} style={{ marginTop: 10 }}>
                              <b style={{ marginLeft: 8, color: '#969696' }}>
                                .
                              </b>
                            </Col>
                            <Col span={3}>
                              <Input
                                value={this.props.ipServer2}
                                placeholder={'168'}
                                maxLength={3}
                                style={{ textAlign: 'center' }}
                                onChange={this.props.handleIpServer2}
                              />
                            </Col>
                            <Col span={1} style={{ marginTop: 10 }}>
                              <b style={{ marginLeft: 8, color: '#969696' }}>
                                .
                              </b>
                            </Col>
                            <Col span={3}>
                              <Input
                                value={this.props.ipServer3}
                                placeholder={'XXX'}
                                maxLength={3}
                                style={{ textAlign: 'center' }}
                                onChange={this.props.handleIpServer3}
                              />
                            </Col>
                            <Col span={1} style={{ marginTop: 10 }}>
                              <b style={{ marginLeft: 8, color: '#969696' }}>
                                .
                              </b>
                            </Col>
                            <Col span={3}>
                              <Input
                                value={this.props.ipServer4}
                                placeholder={'XXX'}
                                maxLength={3}
                                style={{ textAlign: 'center' }}
                                onChange={this.props.handleIpServer4}
                              />
                            </Col>
                            <Col span={1} style={{ marginTop: 5 }}>
                              <b style={{ marginLeft: 8, color: '#969696' }}>
                                :
                              </b>
                            </Col>
                            <Col span={4}>
                              <Input
                                value={this.props.ipPort}
                                placeholder={'80'}
                                maxLength={5}
                                style={{ textAlign: 'center' }}
                                onChange={this.props.handleIpPort}
                              />
                            </Col>
                          </Row>
                        </InputGroup>
                      </Form.Item>
                    </div>

                    <div>
                      <span>
                        <b>Kuota</b>
                      </span>
                      <FormInputTextMaterial
                        validateType={true}
                        onPressEnter={''}
                        className={''}
                        disabled={false}
                        addonBefore={''}
                        placeholder={'Contoh: 999'}
                        prefix={null}
                        value={this.props.kuota}
                        maxLength={3}
                        style={{}}
                        size=""
                        onChange={this.props.handlePutKuota}
                        onBlur={this.props.handlePutKuota}
                        validatestatus={this.props.validateKuota}
                        type={''}
                        help={this.props.helpValidateKuota}
                      />
                    </div>

                    <div>
                      <span>
                        <b>Urutan</b>
                      </span>
                      <FormInputTextMaterial
                        validateType={true}
                        onPressEnter={''}
                        className={''}
                        disabled={
                          this.props.typeToggle === 'AddServerVoip'
                            ? true
                            : false
                        }
                        addonBefore={''}
                        placeholder={''}
                        prefix={null}
                        value={this.props.position}
                        maxLength={''}
                        style={{}}
                        size=""
                        onChange={this.props.handlePutPosition}
                        onBlur={this.props.handlePutPosition}
                        validatestatus={this.props.validatePosition}
                        type={''}
                        help={this.props.helpValidatePosition}
                      />
                    </div>

                    <div>
                      <span>
                        <b>Aktifkan Server</b>
                      </span>
                      <Row>
                        <Col span={2} style={{ marginTop: 8 }}>
                          <Switch
                            checked={this.props.toggleServer}
                            onChange={this.props.handleToggleServer}
                          />
                        </Col>

                        <Col span={22} style={{ marginTop: 9 }}>
                          <span
                            style={{
                              paddingLeft: '18px',
                              color: '#969696',
                            }}
                          >
                            {this.props.toggleServer === true
                              ? 'Aktif'
                              : 'Non-aktif'}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div
                    style={{
                      textAlign: 'right',
                      marginRight: 30,
                      marginTop: 20,
                    }}
                  >
                    <ButtonMaterial
                      disabled={ValidateButton}
                      handleSubmit={this.props.handleSubmitServerVoip}
                      teksButton={'Simpan'}
                      size={''}
                      shape={'round'}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={{}}
                    />
                  </div>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
