import React, { useState, useEffect } from 'react';
import ModalConfirmation from 'components/ModalConfirmation';
import { postSetManifestSaveCode } from 'services/ManifestSaveCodeServices';
import ModalEditCodeReturComponent from '../Component';
import ModalStatus from 'materials/ModalStatus';
import { useMutation } from '@tanstack/react-query';
import { SelectOptionsInterface } from 'interface/general';

type ModalEditCodeReturProps = {
  onCancel: () => void;
  onClickAddRetur: () => void;
  refetchManifestRetur: () => void;
  handleGetDataManifest?: () => void;
  isShow: boolean;
  loadingManifestRetur: boolean;
  selectedData: any;
  dataManifestRetur: SelectOptionsInterface[];
};

export default function ModalEditCodeRetur({
  isShow,
  loadingManifestRetur,
  onClickAddRetur,
  onCancel,
  refetchManifestRetur,
  handleGetDataManifest = () => undefined,
  selectedData,
  dataManifestRetur,
}: ModalEditCodeReturProps) {
  const { mutate: submitManifestSafeCode, isLoading: isLoadingSubmit } =
    useMutation(postSetManifestSaveCode, {
      onSuccess: res => {
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Berhasil diubah',
            content: 'Kode brankas berhasil diubah.',
            handleOke: () => handleGetDataManifest(),
          });
          handleClickSave();
          onCancel();
          refetchManifestRetur();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Menjalankan Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [manifestSafeCode, setManifestSafeCode] = useState<any>(null);

  useEffect(() => {
    if (selectedData) {
      setManifestSafeCode(selectedData?.manifestSafeCodeId || null);
    }
  }, [selectedData]);

  const handleClickSave = () => {
    setShowConfirm(!showConfirm);
  };

  const handleSaveData = () => {
    const data = {
      itemScanManifestTime: selectedData.itemScanManifestTime,
      treatmentDocument: selectedData.treatmentDocument,
      userCode: selectedData.userCode,
      productId: selectedData.productId,
      manifestSafeCode,
    };
    submitManifestSafeCode(data);
  };

  return (
    <>
      <ModalConfirmation
        visibleModal={showConfirm}
        title="Apakah anda yakin?"
        description="Pastikan anda telah memeriksa kembali hasil input data."
        onCancel={handleClickSave}
        onConfirm={handleSaveData}
        isLoadingYa={isLoadingSubmit}
      />
      <ModalEditCodeReturComponent
        isShow={isShow}
        onCancel={onCancel}
        onSave={handleClickSave}
        onClickAddRetur={onClickAddRetur}
        dataManifestRetur={dataManifestRetur}
        manifestSafeCode={manifestSafeCode}
        setManifestSafeCode={value => setManifestSafeCode(value)}
        loadingManifestRetur={loadingManifestRetur}
      />
    </>
  );
}
