export function setLoadingBranch()
{
    return({
        type:"SET_LOADING_BRANCH"
    })
}
export function setTypeLoadingBranch(TypeLoadingBranch:any)
{
    return({
        type:"SET_TYPE_LOADING_BRANCH",
        TypeLoadingBranch
    })
}
export function clearTypeLoadingBranch()
{
    return({
        type:"CLEAR_TYPE_LOADING_BRANCH"
    })
}