import { Col, Icon, Row } from 'antd';
import { Form, Field } from 'react-final-form';

import CardMaterial from '../../../materials/CardMaterial';
import React from 'react';
import SpinMaterial from '../../../materials/SpinMaterial';
import moment from 'moment';
import InputDatePicker from 'components/InputDatePicker';
import SelectField from 'components/SelectField';
import { SingleCheckbox } from 'components/SingleCheckbox';
import Button from 'components/Button';
import { SelectOptionsInterface } from 'interface/general';
import { FilterDataDeliveryType } from './index';

export type DataDeliveryTypes = {
  showChangeFilter: boolean;
  filterDataDelivery: FilterDataDeliveryType;
  setShowChangeFilter: (value: boolean) => void;
  handleChangeFilter: (name: string, value: any) => void;
  handleResetFilter: () => void;
  handleSubmitFilter: () => void;
  dataCustomers: SelectOptionsInterface[];
  dataProducts: SelectOptionsInterface[];
  dataBranchs: any[];
  dataCourier: any[];
  isLoadingCustomers: boolean;
  isLoadingProducts: boolean;
  isLoadingBranch: boolean;
  isLoadingCourier: boolean;
  isLoading: boolean;
};

export default function FilterDataDeliveryComponent({
  showChangeFilter,
  filterDataDelivery,
  setShowChangeFilter,
  handleChangeFilter,
  handleResetFilter,
  handleSubmitFilter,
  dataCustomers,
  dataProducts,
  dataBranchs,
  dataCourier,
  isLoadingCustomers,
  isLoadingProducts,
  isLoadingBranch,
  isLoadingCourier,
  isLoading,
}: DataDeliveryTypes) {
  const disabledDatePickup = current => {
    const selectedDates = filterDataDelivery.periodeDelivery;
    if (!selectedDates || selectedDates.length === 0) {
      return (
        !current ||
        current.isAfter(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))
      );
    } else {
      const startDate = selectedDates[0];
      if (!startDate) {
        return current && current > moment().endOf('day');
      } else {
        return (
          !current ||
          current.isBefore(moment(selectedDates[0])) ||
          current.isAfter(
            moment(new Date()).add(1, 'day').format('YYYY-MM-DD')
          ) ||
          current.isAfter(
            moment(selectedDates[0]).add(31, 'day').format('YYYY-MM-DD')
          )
        );
      }
    }
  };
  return (
    <div>
      <SpinMaterial spinning={isLoading} size="large">
        <CardMaterial
          style={{ borderRadius: '8px' }}
          title={
            <div style={{ color: '#11BEFF' }}>
              <span style={{ textTransform: 'uppercase' }}>
                <b>DATA PENGIRIMAN</b>
              </span>
            </div>
          }
        >
          <Form
            initialValues={{ ...filterDataDelivery }}
            validate={values => {
              const errors = {} as any;

              if (
                !values.periodeDelivery ||
                values.periodeDelivery.length !== 2 ||
                !values.periodeDelivery[0] ||
                !values.periodeDelivery[1]
              ) {
                errors.periodeDelivery = 'Periode Pengirimam wajib dipilih';
              }

              return errors;
            }}
            onSubmit={handleSubmitFilter}
          >
            {({ handleSubmit, form, valid, values }) => (
              <Row gutter={[32, 24]}>
                <Col span={12}>
                  <Field name="periodeDelivery">
                    {({ input, meta }) => (
                      <InputDatePicker
                        label="Periode Pengiriman"
                        firstPlaceholder="Dari tanggal"
                        secondPlaceholder="Sampai tanggal"
                        format="DD-MM-YYYY"
                        type="range"
                        error={meta.error && meta.touched && meta.error}
                        onBlur={input.onBlur}
                        value={input.value}
                        onChange={value =>
                          handleChangeFilter('periodeDelivery', value)
                        }
                        onCalendarChange={value =>
                          handleChangeFilter('periodeDelivery', value)
                        }
                        disabledDate={disabledDatePickup}
                        addonBefore={
                          <Field name="typePeriode">
                            {({ input }) => (
                              <SelectField
                                value={input.value}
                                data={[
                                  { id: 'Cycle', name: 'Cycle' },
                                  { id: 'Delivery', name: 'Kirim' },
                                  { id: 'Finish', name: 'Selesai' },
                                  { id: 'Process', name: 'Upload' },
                                ]}
                                style={{ width: 100 }}
                                onChange={value =>
                                  handleChangeFilter('typePeriode', value)
                                }
                              />
                            )}
                          </Field>
                        }
                      />
                    )}
                  </Field>
                </Col>
                <Col span={12}></Col>
                <Col span={24}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                      color: '#11BEFF',
                      fontWeight: 500,
                      cursor: 'pointer',
                      width: 'fit-content',
                    }}
                    onClick={() => setShowChangeFilter(!showChangeFilter)}
                  >
                    <p style={{ margin: 0, fontSize: 14 }}>Ubah Filter</p>
                    <Icon type={!showChangeFilter ? 'right' : 'down'} />
                  </div>
                </Col>
                {showChangeFilter && (
                  <>
                    <Col
                      span={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 24,
                      }}
                    >
                      <Field name="statusDelivery">
                        {({ input, meta }) => (
                          <SelectField
                            label="Status Pengiriman"
                            placeholder="Status Pengiriman"
                            value={input.value}
                            mode="multiple"
                            disabled={!values.periodeDelivery[0]}
                            data={[
                              { id: 0, name: 'Pilih Semua Status' },
                              { id: 103, name: 'Diterima' },
                              { id: 104, name: 'Dikembalikan' },
                              { id: 102, name: 'Proses' },
                              { id: 101, name: 'OnHold' },
                            ]}
                            onBlur={input.onBlur}
                            onChange={value =>
                              handleChangeFilter('statusDelivery', value)
                            }
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            allowClear
                          />
                        )}
                      </Field>
                      <Field name="customer">
                        {({ input, meta }) => (
                          <SelectField
                            label="Pelanggan"
                            placeholder="Pilih Pelanggan"
                            data={dataCustomers}
                            value={input.value}
                            onBlur={input.onBlur}
                            onChange={value => {
                              handleChangeFilter('customer', value);
                              handleChangeFilter('customerProduct', null);
                            }}
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            disabled={!values.periodeDelivery[0]}
                            loading={isLoadingCustomers}
                          />
                        )}
                      </Field>
                      <Field name="customerProduct">
                        {({ input, meta }) => (
                          <SelectField
                            label="Produk Pelanggan"
                            placeholder="Pilih Produk Pelanggan"
                            data={dataProducts}
                            value={input.value}
                            onBlur={input.onBlur}
                            onChange={value =>
                              handleChangeFilter('customerProduct', value)
                            }
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            disabled={!values.customer}
                            loading={isLoadingProducts}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col
                      span={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 24,
                      }}
                    >
                      <Field name="branch">
                        {({ input, meta }) => (
                          <SelectField
                            label="Posko"
                            placeholder="Pilih Posko"
                            data={dataBranchs}
                            value={input.value}
                            onBlur={input.onBlur}
                            onChange={value => {
                              handleChangeFilter('branch', value);
                              handleChangeFilter('courier', null);
                            }}
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            disabled={!values.periodeDelivery[0]}
                            loading={isLoadingBranch}
                          />
                        )}
                      </Field>
                      <Field name="courier">
                        {({ input, meta }) => (
                          <SelectField
                            label="Kurir"
                            placeholder="Pilih Kurir"
                            labelKey="firstName"
                            valueKey="id"
                            data={dataCourier}
                            value={input.value}
                            onBlur={input.onBlur}
                            onChange={value =>
                              handleChangeFilter('courier', value)
                            }
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            disabled={!values.branch}
                            loading={isLoadingCourier}
                          />
                        )}
                      </Field>
                      <Field name="isPhysicalDocument">
                        {({ input, meta }) => (
                          <SingleCheckbox
                            labelRight="Fisik Dokumen"
                            formStyle={{ marginTop: 21 }}
                            input={input}
                            meta={meta}
                            onChange={e =>
                              handleChangeFilter(
                                'isPhysicalDocument',
                                !input.value
                              )
                            }
                          />
                        )}
                      </Field>
                    </Col>
                  </>
                )}
                <Col span={24} style={{ padding: '0 16px' }}>
                  <div
                    style={{
                      display: 'flex',
                      gap: 24,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      text="Reset"
                      variant="danger"
                      onClick={() => {
                        handleResetFilter();
                        form.restart();
                      }}
                      disabled={!valid}
                    />
                    <Button
                      text="Cari"
                      onClick={handleSubmit}
                      disabled={!valid}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Form>
        </CardMaterial>
      </SpinMaterial>
    </div>
  );
}
