import 'styles/FormInputStyle/index.css';

import { Col, Row } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from 'materials/ButtonMaterial';
import CourierComponent from 'containers/Content/ManageAccount/Courier/CourierBranchContainer';
import DetailComponentMatrial from 'materials/DetailComponentMatrial';
import FormBranchContainer from 'module/Branch/Container/FormBranchContainer';
import FormDetailBranchComponent from './FormDetailBranchComponent';
import Maps from 'module/Map/Container/MapContainer';

interface IProps {
  GroupNameBranch: any;
  NameBranch: any;
  AreaCode: any;
  Address: any;
  PhoneNumber: any;
  Province: any;
  City: any;
  SubDistrict: any;
  Village: any;
  AdminBranch: any;
  PostalCode: any;
  getLatitudeBranch: any;
  getLongitudeBranch: any;
  getPartner: any;
  isLoading: any;
  DetailBranch: any;
  handleBackDetail: any;
  handleEditDetail: any;
}
export default class BranchDetailComponent extends Component<IProps> {
  render() {
    return (
      <div>
        {this.props.DetailBranch === true ? (
          ''
        ) : (
          <div>
            <FormBranchContainer />
          </div>
        )}
        <div
          style={{
            paddingTop: '35px',
            paddingLeft: '35px',
            paddingRight: '35px',
          }}
        >
          <DetailComponentMatrial
            Deskripsi={this.props.NameBranch}
            isLoading={this.props.isLoading}
            ButtonHeader={
              <Row>
                <Col span={12}>
                  <div style={{ paddingRight: '10px', float: 'right' }}>
                    <ButtonMaterial
                      disabled={''}
                      handleSubmit={this.props.handleEditDetail}
                      teksButton={'Ubah'}
                      size={''}
                      shape={'round'}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={{
                        width: '80px',
                        color: '#fafafa',
                        background: '#FCC642',
                        borderColor: '#FCC642',
                      }}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div style={{ float: 'right' }}>
                    <ButtonMaterial
                      disabled={''}
                      handleSubmit={this.props.handleBackDetail}
                      teksButton={'Kembali'}
                      size={''}
                      shape={'round'}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={{
                        width: '80px',
                        color: '#fafafa',
                        background: '#F5487D',
                        borderColor: '#F5487D',
                      }}
                    />
                  </div>
                </Col>
              </Row>
            }
            Modul={'POSKO'}
            formText={
              <FormDetailBranchComponent
                GroupNameBranch={this.props.GroupNameBranch}
                NameBranch={this.props.NameBranch}
                Province={this.props.Province}
                City={this.props.City}
                SubDistrict={this.props.SubDistrict}
                AreaCode={this.props.AreaCode}
                PhoneNumber={this.props.PhoneNumber}
                Village={this.props.Village}
                PostalCode={this.props.PostalCode}
                Address={this.props.Address}
                AdminBranch={this.props.AdminBranch}
                Latitude={this.props.getLatitudeBranch}
                Longitude={this.props.getLongitudeBranch}
                Partner={this.props.getPartner}
              />
            }
          />
        </div>
        <div
          style={{
            paddingRight: '35px',
            paddingLeft: '35px',
            paddingTop: '20px',
          }}
        >
          <CourierComponent />
        </div>
        <div style={{ paddingRight: '35px', paddingLeft: '35px' }}>
          <Maps />
        </div>
      </div>
    );
  }
}
