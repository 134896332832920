import { Col, Row, Switch } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../materials/ButtonMaterial';
import FormInputTextMaterial from '../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../materials/ModalMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';

interface IProps {
  visible: any;
  handleClearFormCustomerStatus: any;
  jenisToggle: any;
  ValidateButton: any;

  GetStatusPosition: any;
  PutCustomerStatusPosition: any;
  ValidateCustomerStatusPosition: any;
  helpCustomerStatusPosition: any;

  GetStatusCode: any;
  PutCustomerStatusCode: any;
  ValidateCustomerStatusCode: any;
  helpCustomerStatusCode: any;

  GetStatusDescription: any;
  PutCustomerStatusDescription: any;
  ValidateCustomerStatusDescription: any;
  helpCustomerStatusDescription: any;

  GetStatusLabel: any;
  PutCustomerStatusLabel: any;
  ValidateCustomerStatusLabel: any;
  helpCustomerStatusLabel: any;

  GetStatusAttempt:any;
  handlePutCustomerStatusAttempt:any;

  GetStatusFreeText:any;
  handlePutCustomerStatusFreeText:any;

  GetStatusYBS:any;
  handlePutCustomerStatusYBS:any;
  ReasonDeliveryStatus: any;

  isLoading: any;
  handleSubmitCustomerStatus: any;
  handleEditCustomerStatus: any;
}

export default class FormCustomerStatusConfigComponent extends Component<IProps> {
  render() {
    
    return (
      <div>
        <ModalMaterial
          className={''}
          width={'40%'}
          style={{}}
          teksTitle={
            this.props.jenisToggle === 'EditStatus'
              ? `UBAH STATUS ${this.props.ReasonDeliveryStatus}`
              : this.props.jenisToggle === 'AddCustomStatus'? `TAMBAHKAN STATUS ${this.props.ReasonDeliveryStatus}`
              :''
          }
          visible={this.props.visible}
          onCancel={this.props.handleClearFormCustomerStatus}
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={
                <div style={{ paddingTop: '20px' }}>
                  <Row>
                    <Col>
                      <div style={{ marginRight: 20, marginLeft: 20 }}>
                        <div>
                          <span>
                            <b>Posisi</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetStatusPosition}
                            className={''}
                            disabled={false}
                            addonBefore={''}
                            placeholder={'Contoh : 5'}
                            prefix={''}
                            maxLength={4}
                            style={{ borderRadius: '10px' }}
                            size=""
                            type={''}
                            onChange={this.props.PutCustomerStatusPosition}
                            onBlur={this.props.PutCustomerStatusPosition}
                            validatestatus={
                              this.props.ValidateCustomerStatusPosition
                            }
                            help={this.props.helpCustomerStatusPosition}
                            onPressEnter={''}
                          />
                        </div>

                        <div>
                          <span>
                            <b>Kode</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetStatusCode}
                            className={''}
                            disabled={false}
                            addonBefore={''}
                            placeholder={'Contoh : R1'}
                            prefix={''}
                            maxLength={10}
                            style={{ borderRadius: '10px' }}
                            size=""
                            type={''}
                            onChange={this.props.PutCustomerStatusCode}
                            onBlur={this.props.PutCustomerStatusCode}
                            validatestatus={
                              this.props.ValidateCustomerStatusCode
                            }
                            help={this.props.helpCustomerStatusCode}
                            onPressEnter={''}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Label</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetStatusLabel}
                            className={''}
                            disabled={false}
                            addonBefore={''}
                            placeholder={
                              'Contoh : Label'
                            }
                            prefix={''}
                            maxLength={13}
                            style={{ borderRadius: '10px' }}
                            size=""
                            type={''}
                            onChange={this.props.PutCustomerStatusLabel}
                            onBlur={this.props.PutCustomerStatusLabel}
                            validatestatus={
                              this.props.ValidateCustomerStatusLabel
                            }
                            help={this.props.helpCustomerStatusLabel}
                            onPressEnter={''}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Deskripsi Status</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetStatusDescription}
                            className={''}
                            disabled={false}
                            addonBefore={''}
                            placeholder={
                              this.props.ReasonDeliveryStatus === 'DITERIMA'
                                ? 'Contoh : Yang Bersangkutan'
                                : 'Contoh : Rumah Kosong'
                            }
                            prefix={''}
                            maxLength={225}
                            style={{ borderRadius: '10px' }}
                            size=""
                            type={''}
                            onChange={this.props.PutCustomerStatusDescription}
                            onBlur={this.props.PutCustomerStatusDescription}
                            validatestatus={
                              this.props.ValidateCustomerStatusDescription
                            }
                            help={this.props.helpCustomerStatusDescription}
                            onPressEnter={''}
                          />
                        </div>
                        {this.props.ReasonDeliveryStatus==="DIKEMBALIKAN"?
                        <>
                          <div style={{ marginBottom: 20 }}>
                            <span>
                              <b>Status Attempt</b>
                            </span>
                            <Row>
                              <Col span={2} style={{ marginTop: 8 }}>
                                <Switch
                                  checked={this.props.GetStatusAttempt}
                                  onChange={this.props.handlePutCustomerStatusAttempt}
                                />
                              </Col>

                              <Col span={22} style={{ marginTop: 9 }}>
                                <span
                                  style={{
                                    paddingLeft: '18px',
                                    color: '#969696',
                                  }}
                                >
                                  {this.props.GetStatusAttempt === true
                                    ? 'Aktif'
                                    : 'Non-aktif'}
                                </span>
                              </Col>
                            </Row>
                          </div>
                          
                        </>
                        :
                        <>
                        <div style={{ marginBottom: 20 }}>
                            <span>
                              <b>Status YBS</b>
                            </span>
                            <Row>
                              <Col span={2} style={{ marginTop: 8 }}>
                                <Switch
                                  checked={this.props.GetStatusYBS}
                                  onChange={this.props.handlePutCustomerStatusYBS}
                                />
                              </Col>

                              <Col span={22} style={{ marginTop: 9 }}>
                                <span
                                  style={{
                                    paddingLeft: '18px',
                                    color: '#969696',
                                  }}
                                >
                                  {this.props.GetStatusYBS === true
                                    ? 'Aktif'
                                    : 'Non-aktif'}
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </>
                        }
                        <div style={{ marginBottom: 20 }}>
                          <span>
                              <b>Free Text</b>
                          </span>
                          <Row>
                              <Col span={2} style={{ marginTop: 8 }}>
                                <Switch
                                  disabled={this.props.GetStatusYBS === true ? true : false}
                                  checked={this.props.GetStatusFreeText}
                                  onChange={this.props.handlePutCustomerStatusFreeText}
                                />
                              </Col>

                              <Col span={22} style={{ marginTop: 9 }}>
                                <span
                                  style={{
                                    paddingLeft: '18px',
                                    color: '#969696',
                                  }}
                                >
                                  {this.props.GetStatusFreeText === true
                                    ? 'Aktif'
                                    : 'Non-aktif'}
                                </span>
                              </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div style={{ textAlign: 'right', marginRight: 30 }}>
                    <ButtonMaterial
                      disabled={this.props.ValidateButton}
                      handleSubmit={
                        this.props.jenisToggle === 'EditStatus'
                          ? this.props.handleEditCustomerStatus
                          : this.props.handleSubmitCustomerStatus
                      }
                      teksButton={'Simpan'}
                      size={''}
                      shape={'default'}
                      className={''}
                      type={'primary'}
                      style={{ borderRadius: '10px' }}
                      icon={''}
                    />
                  </div>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
