import React, { useState } from 'react';
import Updatelocation from './UpdateLocation';
import EditLocation from './EditLocation';
import SettingLocationComponent from '../components';
import { useQuery } from '@tanstack/react-query';
import ModalStatus from 'materials/ModalStatus';
import { getSettingLocations } from 'services/SettingLocationService';

export default function SettingLocation() {
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [isActiveType, setIsActiveType] = useState<string>('None');

  const [showModalUpdate, setShowModalUpdate] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<number | null>(null);

  const {
    data: dataTable,
    isFetching: isLoadingTable,
    refetch,
  } = useQuery(
    ['GetTablePerformanceCourier', page, isActiveType, search],
    () => getSettingLocations({ page, isActiveType, search }),
    {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          if (res.data) {
            const response = res.data;
            if (response?.data?.length < 0) {
              ModalStatus({
                status: 'error',
                title: 'Tidak Ditemukan',
                content: 'Data yang Anda cari tidak ditemukan Mohon ulangi',
              });
            }
          }
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi kesalahan',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <SettingLocationComponent
        handleModalUpdate={() => setShowModalUpdate(!showModalUpdate)}
        dataTable={dataTable?.data || null}
        isLoadingTable={isLoadingTable}
        handlePage={setPage}
        handleSearchValue={setSearch}
        isActiveType={isActiveType}
        handleIsActiveType={setIsActiveType}
        handleShowModalEdit={setShowModalEdit}
      />
      <Updatelocation
        show={showModalUpdate}
        handleModal={() => setShowModalUpdate(!showModalUpdate)}
        refetch={refetch}
      />
      <EditLocation
        show={showModalEdit}
        handleModal={() => setShowModalEdit(null)}
        refetch={refetch}
      />
    </>
  );
}
