import React, {Component} from 'react';
import {connect} from 'react-redux';
import TableDataStatusDefaultComponent from '../../../components/Content/Customer/TableDataStatusCustomComponent';
import * as toggle from '../../../core/action/toggle';
import * as ActionTableStatusCustom from '../../../core/action/Customer/ActionTableStatusDefault';
import { bindActionCreators, compose } from 'redux';

interface IProps
{
    data:any
    GetCustomerName:any
    GetReasonDeliveryStatus:any
    actionTableStatusCustom:any
    actionToggle:any
    isLoading:any
}

interface IState
{
    sRT:any
    dataSource:any
    page:any
}

class TableCustomDataStatusContainer extends Component <IProps, IState>
{
    constructor(props:any)
    {
        super(props)
        this.state={
            sRT:null,
            dataSource: this.props.data,
            page: 0
        }
        this.handlePage = this.handlePage.bind(this)
        this.handleModal = this.handleModal.bind(this)
    }
    handlePage(event:any)
    {
        this.setState({
            page:event,
        });
    }
    handleModal()
    {
        this.props.actionToggle.changeModal()
        this.props.actionToggle.changeJenisToggle("AddCustomStatus")
    }
    
    render(){
        return(
            <TableDataStatusDefaultComponent
                dataSource={this.state.dataSource}
                data={this.props.data}
                GetCustomerName={this.props.GetCustomerName}
                GetReasonDeliveryStatus={this.props.GetReasonDeliveryStatus}
                handlePage={this.handlePage}
                page={this.state.page}
                isLoading={this.props.isLoading}
                handleModal={this.handleModal}
            />
        )
    }
}
const mapStateToProps = (state:any)=>({
    data:state.Customer.CustomDataStatus,
    GetCustomerName : state.Customer.DetailCustomer.CustomerName,
    GetReasonDeliveryStatus : state.Customer.PropertiesCustomer.ReasonDeliveryStatus,
    isLoading:state.Toggle.isLoading,
})
const mapDispatchToProps = (dispatch:any) => ({
    actionTableStatusCustom : bindActionCreators(ActionTableStatusCustom, dispatch),
    actionToggle : bindActionCreators(toggle, dispatch)
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
    withConnect
)(TableCustomDataStatusContainer)