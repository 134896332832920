import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_downloadByWaybill(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const key = 'download';
  const Waybill = state.PrintWaybill.FormPrintWaybill.Waybill;
  const WaybillPrintType = state.PrintWaybill.FormPrintWaybill.WaybillPrintType;
  const SeparatorAddress = state.PrintWaybill.FormPrintWaybill.SeparatorAddress;
  const NoTelp = state.PrintWaybill.FormPrintWaybill.NoTelp;
  const CodeBrankas = state.PrintWaybill.FormPrintWaybill.CodeBrankas;
  // const Watermark = state.PrintWaybill.FormPrintWaybill.Watermark;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = `Waybill - ${Waybill}.pdf`;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }
  try {
    const { data } = yield call(
      axios.get,
      `${
        process.env.REACT_APP_URL
      }/Delivery/DownloadWaybillPdf/${Waybill}/${WaybillPrintType}/${true}/${CodeBrankas}?Separator=${SeparatorAddress}&NoTelp=${NoTelp}`,
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const percentProgress = Math.floor((loaded * 100) / total);
          if (percentProgress < 100) {
            message.loading({
              content: `Loading ${percentProgress}%`,
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
  } catch (error) {
    if (error.response.data.Message === 'Network Error') {
      message.error({
        content: 'Download Gagal',
        key,
        duration: 3,
      });
    } else if (error.response.data.Message === 'No delivery found!') {
      message.error({
        content: 'Waybill tidak tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* SM_downloadByWaybill() {
  yield takeLatest('DOWNLOAD_BY_WAYBILL', S_downloadByWaybill);
}
