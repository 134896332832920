import instance from 'config/interceptor';

export const uploadDelivery = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + '/Delivery/upload',
      data
    );
  } catch (error) {
    return error;
  }
};

export const uploadDeliveryContinueProcess = async data => {
  try {
    return await instance.put(
      process.env.REACT_APP_URL + '/Delivery/Upload/ContinueProcess',
      data
    );
  } catch (error) {
    return error;
  }
};

export const downloadResultUploadDelivery = async (
  data: {
    listResult: any;
  },
  onDownloadProgress: any
) => {
  try {
    const response = await instance.post(
      process.env.REACT_APP_URL + `/Delivery/upload/downloadResult`,
      data,
      {
        responseType: 'arraybuffer',
        onDownloadProgress,
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};
