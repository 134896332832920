import React, { useState } from 'react';
import RestoreCustomerComponent from '../Component/RestoreCustomerComponent';
import { renderActionComponent } from 'apps/assets/components/CButton';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import FormRestoreCustomerContainer from './FormRestoreCustomerContainer';
import FormDownloadDeleteContainer from './FormDownloadDeleteContainer';
import { getRestoreDataCustomer } from 'services/RestoreService';
import moment from 'moment';
import { archiveDateTypes } from 'lib/constants';

export default function RestoreCustomerContainer() {
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [searchGroup, setSearchGroup] = useState<string>('');
  const [statusGroup, setStatusGroup] = useState<any>(null);
  const [modalAdd, setModalAdd] = useState<boolean>(false);
  const [modalDownload, setModalDownload] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>(null);

  const { data, isLoading, isFetching, refetch } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: [
      'dataLevelGroup',
      { page, name: search, isActive: statusGroup?.key },
    ],
    queryFn: ({ queryKey }) => getRestoreDataCustomer(queryKey),
  });

  const looppage = (page > 1 ? page : page) * 10 + 1;

  const renderAction = record => {
    const statusDone = record?.status === 'Selesai';
    const handleDetail = () => {
      setModalDownload(!modalDownload);
      setSelectedData(record);
    };

    return renderActionComponent(
      false,
      record.id,
      {
        renderDownload: true,
        tooltipDownload: statusDone ? 'Download BAST Hapus Data' : '',
      },
      {
        handleDetail: handleDetail,
      },
      { validateDetail: !statusDone }
    );
  };

  const handlePageAddNewLevel = () => {
    setModalAdd(!modalAdd);
  };

  const handlePage = (event: any) => {
    setPage(event !== 0 ? event - 1 : event);
  };

  const handleFilterStatus = value => {
    setPage(0);
    setStatusGroup({ label: value.item.props.children, key: value.key });
    setSearch('');
    setSearchGroup('');
  };

  const handleFilterSearch = value => {
    setSearch(value);
    setPage(0);
  };

  const getStyleStatus = (status: string) => {
    let style = {
      fontSize: 14,
      fontWeight: 600,
    };
    switch (status) {
      case 'Selesai':
        style = {
          ...style,
          color: '#00AB44',
        };
        break;

      case 'Proses':
        style = {
          ...style,
          color: '#1890FF',
        };
        break;
      case 'Dalam Antrian':
        style = {
          ...style,
          color: '#ECA722',
        };
        break;
      case 'Gagal':
        style = {
          ...style,
          color: '#EA1A1A',
        };
        break;

      default:
        style = {
          ...style,
          color: '#00AB44',
        };
        break;
    }

    return style;
  };

  const columns = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <b>No</b>
        </div>
      ),
      width: 50,
      render: (text: any, record: any, index: any) => (
        <span>{index + looppage}</span>
      ),
      key: 'no',
    },
    {
      title: 'Pelanggan',
      dataIndex: 'customerName',
      key: 'customerName',
      width: 120,
    },

    {
      title: 'Periode',
      key: 'year',
      width: 200,
      render: data => {
        const selectedArchiveType = archiveDateTypes.find(
          value => value.id === data.archiveDateType
        );
        return data.year ? (
          <p style={{ marginBottom: 0 }}>
            {data.year} ({selectedArchiveType?.name})
          </p>
        ) : (
          <p style={{ marginBottom: 0 }}>
            {moment(data.startDate).format('MMM YYYY')} -{' '}
            {moment(data.endDate).format('MMM YYYY')} (
            {selectedArchiveType?.name})
          </p>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => <p style={getStyleStatus(text)}>{text}</p>,
      width: 120,
    },
    {
      title: 'Jumlah Data',
      dataIndex: 'countingArchive',
      key: 'countingArchive',
      width: 120,
    },
    {
      title: 'Aksi',
      render: (record: any) => renderAction(record),
      width: 120,
    },
  ];

  return (
    <>
      <RestoreCustomerComponent
        columns={columns}
        handlePageAddNewLevel={handlePageAddNewLevel}
        handlePage={handlePage}
        handleSearchLevel={handleFilterSearch}
        roles={data?.data?.data || []}
        pagePagination={data?.data?.page + 1 || 0}
        totalPagination={data?.data?.totalDatas || 0}
        isLoading={isLoading || isFetching}
        searchGroup={searchGroup}
        handleSearchGroup={value => setSearchGroup(value)}
        statusGroup={statusGroup}
        handleStatusGroup={handleFilterStatus}
      />

      <FormRestoreCustomerContainer
        modalAdd={modalAdd}
        handleHideModal={() => setModalAdd(!modalAdd)}
        refetch={refetch}
      />

      <FormDownloadDeleteContainer
        showModal={modalDownload}
        handleHideModal={() => {
          setModalDownload(!modalDownload);
          setSelectedData(null);
        }}
        refetch={refetch}
        selectedData={selectedData}
      />
    </>
  );
}
