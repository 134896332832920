export function addManualHoliday()
{
    return({
        type:"ADD_MANUAL_HOLIDAY"
    })
}

export function addUploadCsvHoliday()
{
    return({
        type:"ADD_UPLOAD_CSV_HOLIDAY"
    })
}