import React from 'react';
import { Button, Checkbox } from 'antd';
import ButtonMaterial from '../../../materials/ButtonMaterial';
import FormInputTextMaterial from '../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../materials/ModalMaterial';
import InputFile from 'components/InputFile';
import SpinMaterial from 'materials/SpinMaterial';

interface IProps {
  getWaybill: any;
  handlePutWaybill: any;
  ValidateWaybill: any;
  helpValidateWaybill: any;
  handleDelete: any;
  handleRestore: any;
  visible: any;
  jenisToggle: any;
  LastAccess: any;
  RadioButtonW: any;
  validateDeleteData: any;
  handleClearModal: any;
  isLoading: any;
  isWaybill: boolean;
  setIsWaybill: (value: boolean) => void;
  downloadTemplate: () => void;
  isLoadingDownload: boolean;
  setFileUpload: (value: File) => void;
  handleDownload: () => void;
  fileName: string;
  setFileName: (value: string) => void;
}

export default function FormRestoreDataByWaybillComponent(props: IProps) {
  const handleChangeFile = e => {
    if (e.file) {
      props.setFileUpload(e.file);
      props.setFileName(e.file.name);
    }
  };
  return (
    <ModalMaterial
      width={'40%'}
      teksTitle={
        props.RadioButtonW === 'Delete'
          ? 'HAPUS BERDASARKAN WAYBILL'
          : 'RESTORE BERDASARKAN WAYBILL'
      }
      visible={props.visible}
      onCancel={props.handleClearModal}
    >
      <SpinMaterial spinning={props.isLoadingDownload} size="large">
        <div
          style={{
            padding: 24,
            display: 'flex',
            flexDirection: 'column',
            gap: 40,
            alignItems: 'flex-end',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'flex-end',
              gap: 24,
            }}
          >
            <div style={{ flex: '1' }}>
              {props.isWaybill ? (
                <div>
                  <span>
                    <b>Waybill</b>
                  </span>
                  <FormInputTextMaterial
                    validateType={true}
                    value={props.getWaybill}
                    className={'borderRadiusDefault'}
                    disabled={false}
                    addonBefore={''}
                    placeholder={'Scan Waybill'}
                    prefix={''}
                    maxLength={100}
                    style={{}}
                    size=""
                    type={''}
                    onChange={props.handlePutWaybill}
                    onBlur={props.handlePutWaybill}
                    validatestatus={props.ValidateWaybill}
                    help={props.helpValidateWaybill}
                    onPressEnter={''}
                    styleForm={{ margin: 0 }}
                  />
                </div>
              ) : (
                <div>
                  <InputFile
                    label="File CSV"
                    required
                    handleChangeFile={handleChangeFile}
                    value={props.fileName}
                    accept=".csv"
                    placeholder="Pilih File"
                  />
                  <Button
                    type="link"
                    style={{
                      float: 'left',
                      marginLeft: '-10px',
                    }}
                    onClick={props.downloadTemplate}
                  >
                    <u style={{ fontStyle: 'italic' }}>Download sample file</u>
                  </Button>
                </div>
              )}
            </div>
            <Checkbox
              style={{
                paddingLeft: 5,
                marginBottom: props.isWaybill
                  ? props.ValidateWaybill
                    ? 27
                    : 10
                  : 41,
              }}
              checked={props.isWaybill}
              onChange={() => props.setIsWaybill(!props.isWaybill)}
            >
              Scan Waybill
            </Checkbox>
          </div>

          <ButtonMaterial
            disabled={
              props.isWaybill ? props.validateDeleteData : !props.fileName
            }
            handleSubmit={() => {
              props.isWaybill
                ? props.RadioButtonW === 'Delete'
                  ? props.handleDelete()
                  : props.handleRestore()
                : props.handleDownload();
            }}
            teksButton={<b>Simpan</b>}
            size={'middle'}
            shape={'round'}
            type={'primary'}
          />
        </div>
      </SpinMaterial>
    </ModalMaterial>
  );
}
