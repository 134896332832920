import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionCourierManifest from '../../../action/Manifest/CourierManifest/ActionCourierManifest';
import * as ActionLogin from '../../../../module/Login/Store';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import React from 'react';
import axios from 'axios';
import moment from 'moment';

export function* S_downloadCourierManifest(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const key = 'download';
  const DeliveryDate = moment(
    state.Manifest.CourierManifestForm.DeliveryDate
  ).format('YYYYMMDD');
  const BranchName = state.Manifest.CourierManifestForm.BranchName;
  const DataArray = state.Manifest.ListCourierManifest;

  const file_name2 =
    BranchName === null
      ? `Manifest ${DeliveryDate}.zip`
      : `Manifest ${BranchName} ${DeliveryDate}.zip`;

  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = file_name2;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }
  try {
    Modal.info({
      title: <p style={{ color: '#11beff' }}>{<b>Download Data</b>}</p>,
      content: (
        <div>
          <p>Sedang mendownload data, mohon tunggu</p>
        </div>
      ),
    });
    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Manifest/Courier/PDF',
      {
        data: DataArray,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);

          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(ActionCourierManifest.clearListCourierManifestForm());
  } catch (error) {
    if (error.response.data.Message === 'No delivery found!') {
      message.warning({
        content: 'Manifest Kurir Tidak Tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* SM_downloadCourierManifest() {
  yield takeLatest('DOWNLOAD_COURIER_MANIFEST_FORM', S_downloadCourierManifest);
}
