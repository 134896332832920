import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import '../../../../styles/TableStyle/index.css';

import ButtonSelectCustomerContainer from '../../../../containers/Content/Manifest/CustomerManifest/ButtonDownloadContainer';
import FormCustomerManifestContainer from '../../../../containers/Content/Manifest/CustomerManifest/FormCustomerManifestContainer';
import SpinMaterial from '../../../../materials/SpinMaterial';
import { Table } from 'antd';
import moment from 'moment';
import * as CustomerManifest from '../../../../core/action/Manifest/CustomerManifest/ActionCustomerManifest';

moment.locale('id');

interface IProps {
  isLoading: any;
  TableDeliveryData: any;
  ListCustomerManifest: any;
  Count: any;
  BranchId: any;
  handleReset: any;
  handlePage: any;
  page: any;
  handleNext: any;
  actionCustomerManifest: any;
}

const TableCustomerManifestComponent = (props: IProps) => {
  const [statusTreatmentDocument, setStatusTreatmentDocument] =
    useState<any>(null);

  const handleStatusTreatmentDocument = treatmentDocument => {
    setStatusTreatmentDocument(treatmentDocument);
  };

  let looppage = (props.page - 1) * 10 + 1;

  const columns = [
    {
      title: <b>No</b>,
      render: (text: any, record: any, index: any) => (
        <span>{props.page > 1 ? index + looppage : index + 1}</span>
      ),
      key: 'no',
      width: 40,
    },
    {
      title: <b>Tanggal</b>,
      dataIndex: 'itemScanManifestTime',
      key: 'itemScanManifestTime',
      render: (itemScanManifestTime: any) => (
        <span>{moment(itemScanManifestTime).format('YYYY-MM-DD')}</span>
      ),
      width: 120,
    },
    {
      title: <b>Discan Oleh</b>,
      dataIndex: 'userScan',
      key: 'userScan',
      render: (userScan: any) => <span>{userScan}</span>,
      width: 120,
    },
    {
      title: <b>Jumlah Dokumen</b>,
      dataIndex: 'countDocument',
      key: 'countDocument',
      render: (countDocument: any) => (
        <span style={{ color: 'red' }}>
          {countDocument.replace('Document', 'Dokumen')}
        </span>
      ),
      width: 120,
    },
    {
      title: <b>Perlakuan Dokumen</b>,
      dataIndex: 'treatmentDocument',
      key: 'treatmentDocument',
      render: (text: any) => <span>{text}</span>,
      width: 120,
    },
    {
      title: <b>Pilih</b>,
      key: 'pilih',
      width: 120,
      render: (record: any) => (
        <div
          style={{
            display: 'flex',
            gap: 12,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ButtonSelectCustomerContainer
            scanTime={record.itemScanManifestTime}
            userCode={record.userCode}
            handleStatusTreatmentDocument={() =>
              handleStatusTreatmentDocument(record.treatmentDocument)
            }
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <SpinMaterial
        spinning={props.isLoading}
        style={{}}
        size={'large'}
        content={
          <div>
            <Table
              pagination={{
                onChange: page => {
                  props.handlePage(page);
                },
                pageSize: 10,
              }}
              bordered={false}
              dataSource={props.TableDeliveryData}
              columns={columns}
            />
          </div>
        }
      ></SpinMaterial>
      <FormCustomerManifestContainer
        statusTreatmentDocument={statusTreatmentDocument}
        handleStatusTreatmentDocument={handleStatusTreatmentDocument}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  actionCustomerManifest: bindActionCreators(CustomerManifest, dispatch),
});
const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(TableCustomerManifestComponent);
