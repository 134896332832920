import { Col, Row, Table } from 'antd';
import React, { Component } from 'react';

import AddCourierContainer from '../../../../containers/Content/ManageAccount/Courier/AddCourierContainer';
import ButtonCourierContainer from '../../../../containers/Content/ManageAccount/Courier/ButtonCourierContainer';
import ButtonMaterial from '../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../materials/CardMaterial';
import DetailCourierContainer from '../../../../containers/Content/ManageAccount/Courier/DetailCourierContainer';
import EditCourierContainer from '../../../../containers/Content/ManageAccount/Courier/EditCourierContainer';
import ResetPasswordContainer from '../../../../containers/Content/ResetPassword/ResetPasswordContainer';
import SearchMaterial from '../../../../materials/SearchMatrial';
import SpinMaterial from '../../../../materials/SpinMaterial';

interface IProps {
  ValueSearch: any;
  dataSource: any;
  nameSearch: any;
  data: any;
  handlePage: any;
  handleSearch: any;
  handleSubmitSearch: any;
  isLoading: any;
  handleModal: any;
  EditBranch: any;
  PagePagination: any;
  TotalPagePagination: any;
}

export default class CourierComponent extends Component<IProps> {
  render() {
    let loopPage = (this.props.PagePagination - 1) * 10 + 1;
    const columns = [
      {
        title: <b>No</b>,
        width: 50,

        render: (text: any, record: any, index: any) => (
          <span>{this.props.PagePagination > 1 ? index + loopPage : index + 1}</span>
        ),
      },
      {
        title: <b>Nama Kurir</b>,
        dataIndex: 'firstName',
        key: 'name',
        width: 120,
      },
      {
        title: <b>Kode Kurir</b>,
        dataIndex: 'code',
        render: (code: any) => <span className={'redColor'}>{code}</span>,
        key: 'code',
        width: 200,
      },
      {
        title: <b>No Telp</b>,
        dataIndex: 'phone',
        render: (phone: any) => <span>+62{phone}</span>,
        key: 'phone',
        width: 120,
      },
      {
        title: <b>Posko</b>,
        dataIndex: 'branchName',
        render: (branchName: any) => (
          <span className={'redColor'}>{branchName}</span>
        ),
        key: 'branchName',
        width: 200,
      },
      {
        title: <b>Aksi</b>,
        key: 'action',
        width: 130,
        render: (record: any) => (
          <div>
            <Row>
              <Col span={8}>
                <div className={'btnResetForm'}>
                  <ButtonCourierContainer
                    typeButton={'reset'}
                    idCourier={record.id}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className={'paddingRight'}>
                  <ButtonCourierContainer
                    typeButton={'detail'}
                    idCourier={record.id}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className={'floatRight'}>
                  <ButtonCourierContainer
                    typeButton={'edit'}
                    idCourier={record.id}
                  />
                </div>
              </Col>
            </Row>
          </div>
        ),
      },
    ];
    return (
      <div>
        <AddCourierContainer />
        <CardMaterial
          title={''}
          extra={''}
          style={{}}
          content={
            <div>
              <Row>
                <Col span={12}>
                  <div>
                    <span className={'titleSearchCard'}>
                      <b>KURIR</b>
                    </span>
                    <SearchMaterial
                      value={this.props.ValueSearch}
                      style={{width:'70%'}}
                      placeholder={'Cari nama atau kode'}
                      onChange={this.props.handleSearch}
                      onPressEnter={this.props.handleSubmitSearch}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  {this.props.EditBranch === null ? (
                    <div className={'floatRight'}>
                      <ButtonMaterial
                        disabled={''}
                        handleSubmit={this.props.handleModal}
                        teksButton={<b>TAMBAHKAN KURIR</b>}
                        size={''}
                        shape={''}
                        className={'btnLink'}
                        type={'link'}
                        icon={''}
                        style={{}}
                      />

                      <ButtonMaterial
                        disabled={''}
                        handleSubmit={this.props.handleModal}
                        teksButton={''}
                        size={''}
                        shape={'circle'}
                        className={'btnAdd'}
                        type={''}
                        icon={'plus'}
                        style={{}}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
            </div>
          }
        />
        <SpinMaterial
          spinning={this.props.isLoading}
          style={{}}
          size={'large'}
          content={
            <Table
              pagination={{
                onChange: page => {
                  this.props.handlePage(page);
                },
                pageSize: 10,
                total: this.props.TotalPagePagination,
                current:
                    this.props.PagePagination === 0
                      ? 1
                      : this.props.PagePagination,
              }}
              rowKey={record => record.id}
              bordered={false}
              dataSource={this.props.data}
              columns={columns}
            />
          }
        />
        <DetailCourierContainer />
        <EditCourierContainer />
        <ResetPasswordContainer />
      </div>
    );
  }
}
