import React from 'react';
import '../../../styles/InputUploadStyle/index.css';

import { Button, Icon, Input } from 'antd';

import ButtonMaterial from '../../../materials/ButtonMaterial';

interface IProps {
  fileName: any;
  ValidasiButtonAdd: any;
  handleConvertCsvHoliday: any;
  handleDownloadTamplate: any;
  handleAddUploadCsvHoliday: any;
}

export default function FormCsvHolidayComponent(props: IProps) {
  return (
    <div>
      <div>
        <span className="spacingBottomH">
          <b>Unggah CSV</b>
        </span>
        <div className="spacingTopBottomH">
          <div className="fileUploadInput">
            <Input
              onChange={props.handleConvertCsvHoliday}
              size="large"
              value={props?.fileName || ''}
              suffix={<Icon type="upload" />}
              multiple={false}
              type="file"
              accept=".csv"
            />
          </div>
        </div>
      </div>
      <div>
        <span className="fontItalicaAndUnderline">
          <Button type="link" onClick={props.handleDownloadTamplate}>
            Download sample file
          </Button>
        </span>
      </div>
      <div className="textAlignRight">
        <ButtonMaterial
          disabled={props.ValidasiButtonAdd}
          handleSubmit={props.handleAddUploadCsvHoliday}
          teksButton={'Simpan'}
          size={''}
          shape={'round'}
          className={''}
          type={'primary'}
          style={{}}
          icon={''}
        />
      </div>
    </div>
  );
}
