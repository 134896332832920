export function getAdminTable()
{
    return({
        type:"GET_TABLE_ADMIN"
    })
}
export function SubmitSearchAdminBranch() {
    return({
        type: "SUBMIT_SEARCH_ADMIN_BRANCH"
    })
}
export function setAdminTable(TableAdmin:any)
{
    return({
        type:"SET_TABLE_ADMIN",
        TableAdmin
    })
}
export function clearAdminTable()
{
    return({
        type:"CLEAR_TABLE_ADMIN"
    })
}