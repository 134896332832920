import { Checkbox, Col, Form, Row, Select } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../../materials/ModalMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';

interface IProps {
  visible: any;
  handleClearDataAccount: any;
  jenisToggle: any;
  LoadingSelectCustomerProduct: any;

  GetAccountFirstName: any;
  GetAccountLastName: any;
  GetAccountEmail: any;
  GetAccountPhoneNumber: any;
  GetAccountAddress: any;
  GetAccountUsername: any;
  GetAccountStatus: any;
  GetCustomerProductId: any;
  GetProduct: any;
  listCustomerProduct: any;

  ValidateAccountFirstName: any;
  ValidateAccountLastName: any;
  ValidateAccountEmail: any;
  ValidateAccountPhoneNumber: any;
  ValidateAccountAddress: any;
  ValidateButton: any;

  helpAccountFirstName: any;
  helpAccountLastName: any;
  helpAccountEmail: any;
  helpAccountPhoneNumber: any;
  helpAccountAddress: any;

  PutAccountFirstName: any;
  PutAccountLastName: any;
  PutAccountEmail: any;
  PutAccountPhoneNumber: any;
  PutAccountAddress: any;
  PutProduct: any;
  ArrayValue: any;
  isLoading: any;
  handleSubmit: any;
  handleEditSubmit: any;
  PutAccountStatus: any;
}
const { Option } = Select;
export default class AddAdminComponent extends Component<IProps> {
  render() {
    return (
      <div>
        <ModalMaterial
          className={''}
          width={'65%'}
          style={{}}
          teksTitle={
            this.props.jenisToggle === 'AddCustomerAccount'
              ? 'TAMBAHKAN AKUN PELANGGAN'
              : this.props.jenisToggle === 'EditCustomerAccount'
              ? 'UBAH AKUN PELANGGAN'
              : ''
          }
          visible={
            this.props.visible === true &&
            this.props.jenisToggle === 'AddCustomerAccount'
              ? true
              : this.props.visible === true &&
                this.props.jenisToggle === 'EditCustomerAccount'
              ? true
              : false
          }
          onCancel={this.props.handleClearDataAccount}
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={
                <div className={'spacingTopDefault'}>
                  <Row>
                    <Col span={12}>
                      <div className={'spacingColForm'}>
                        <div>
                          <span>
                            <b>Nama Depan</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetAccountFirstName}
                            className={'borderRadiusDefault'}
                            disabled={false}
                            addonBefore={''}
                            placeholder={'Masukkan Nama Depan'}
                            prefix={''}
                            maxLength={'30'}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.PutAccountFirstName}
                            onBlur={this.props.PutAccountFirstName}
                            validatestatus={this.props.ValidateAccountFirstName}
                            help={this.props.helpAccountFirstName}
                            onPressEnter={''}
                          />
                        </div>

                        <div>
                          <span>
                            <b>Nama Belakang</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetAccountLastName}
                            className={'borderRadiusDefault'}
                            disabled={false}
                            addonBefore={''}
                            placeholder={'Masukkan Nama Belakang'}
                            prefix={''}
                            maxLength={30}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.PutAccountLastName}
                            onBlur={this.props.PutAccountLastName}
                            validatestatus={this.props.ValidateAccountLastName}
                            help={this.props.helpAccountLastName}
                            onPressEnter={''}
                          />
                        </div>

                        <div>
                          <span>
                            <b>Nama Pengguna</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetAccountUsername}
                            className={'borderRadiusDefault'}
                            disabled={true}
                            addonBefore={''}
                            placeholder={''}
                            prefix={''}
                            maxLength={''}
                            style={{}}
                            size=""
                            type={''}
                            onChange={''}
                            onBlur={''}
                            validatestatus={''}
                            help={''}
                            onPressEnter={''}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Alamat Lengkap</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetAccountAddress}
                            className={'borderRadiusDefault'}
                            disabled={false}
                            addonBefore={''}
                            placeholder={'Masukkan alamat lengkap'}
                            prefix={''}
                            maxLength={225}
                            style={{}}
                            size=""
                            type={'textArea'}
                            onChange={this.props.PutAccountAddress}
                            onBlur={this.props.PutAccountAddress}
                            validatestatus={this.props.ValidateAccountAddress}
                            help={this.props.helpAccountAddress}
                            onPressEnter={''}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col span={12}>
                      <div className={'spacingColForm'}>
                        <div>
                          <span>
                            <b>No Telp</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetAccountPhoneNumber}
                            className={'borderRadiusDefault'}
                            disabled={false}
                            addonBefore={'+62'}
                            placeholder={'Masukkan no telp'}
                            prefix={''}
                            maxLength={'15'}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.PutAccountPhoneNumber}
                            onBlur={this.props.PutAccountPhoneNumber}
                            validatestatus={
                              this.props.ValidateAccountPhoneNumber
                            }
                            help={this.props.helpAccountPhoneNumber}
                            onPressEnter={''}
                          />
                        </div>

                        <div>
                          <span>
                            <b>Alamat Email</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetAccountEmail}
                            className={'borderRadiusDefault'}
                            disabled={false}
                            addonBefore={''}
                            placeholder={'Masukkan Email'}
                            prefix={''}
                            maxLength={50}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.PutAccountEmail}
                            onBlur={this.props.PutAccountEmail}
                            validatestatus={this.props.ValidateAccountEmail}
                            help={this.props.helpAccountEmail}
                            onPressEnter={''}
                          />
                        </div>

                        <div>
                          <span>
                            <b>Tutup Hak Akses Produk</b>
                          </span>
                          <Form.Item validateStatus={''} help={''}>
                            <Select
                              loading={this.props.LoadingSelectCustomerProduct}
                              mode="multiple"
                              value={this.props.GetCustomerProductId}
                              placeholder="Pilih Produk Pelanggan"
                              showSearch
                              allowClear={true}
                              onFocus={this.props.GetProduct}
                              onChange={this.props.PutProduct}
                              style={{ width: '100%' }}
                            >
                              {this.props.GetCustomerProductId.length === 0 ? (
                                <Option key={''} value={'Pilih Semua Produk'}>
                                  {'Pilih Semua Produk'}
                                </Option>
                              ) : null}
                              {this.props.listCustomerProduct.map(
                                (data: any, index: any) => (
                                  <Option key={index} value={data.id}>
                                    {data.name}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </div>

                        <div className={'spacingBottomCustAccount'}>
                          <span>
                            <b>Status Default</b>
                          </span>
                          <br />
                          <Checkbox
                            className={'spacingCheckboxCustAccount'}
                            style={{}}
                            disabled={false}
                            checked={this.props.GetAccountStatus}
                            onChange={this.props.PutAccountStatus}
                          />
                          <span className={'checkboxAddCustAccount'}>
                            Ceklis untuk aktifkan status akun
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className={'btnSubmitModalForm'}>
                    <ButtonMaterial
                      disabled={this.props.ValidateButton}
                      handleSubmit={
                        this.props.jenisToggle === 'AddCustomerAccount'
                          ? this.props.handleSubmit
                          : this.props.handleEditSubmit
                      }
                      teksButton={'Simpan'}
                      size={''}
                      shape={'default'}
                      className={'borderRadiusDefault'}
                      type={'primary'}
                      style={{}}
                      icon={''}
                    />
                  </div>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
