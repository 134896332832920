export function GetTableCustomer()
{
    return({
        type:"GET_TABLE_CUSTOMER"
    })
}
export function SubmitSearchCustomer() {
    return({
        type: "SUBMIT_SEARCH_CUSTOMER"
    })
}
export function SetTableCustomer(TableCustomer:any)
{
    return({
        type:"SET_TABLE_CUSTOMER",
        TableCustomer
    })
}
export function ClearTableCustomer()
{
    return({
        type:"CLEAR_TABLE_CUSTOMER"
    })
}