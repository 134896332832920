import { Action } from 'redux';

const initialState = {
  Filter: {
    RadioButton: 'Cycle',
    PhysicalDocument: false,
    CycleDocument: true,
    DeliveryDocument: false,
    DeliveryStatus: [],
    CycleStart: null,
    CycleEnd: null,
    Customer: null,
    CustomerName: null,
    CustomerProductName: null,
    CustomerProduct: null,
    Branch: null,
    CourierName: null,
    Courier: null,
    DownloadType: null,
    TableDeliveryFilter: [],
    TotalOnProcess: null,
    TotalSuccess: null,
    TotalReturn: null,
    TotalDelivery: null,
  },
};

export interface IAuthAction extends Action<string> {}
export interface ISetRadioButton extends IAuthAction {
  RadioButton: any;
}
export interface ISetCycleStart extends IAuthAction {
  CycleStart: any;
}
export interface ISetCycleEnd extends IAuthAction {
  CycleEnd: any;
}
export interface ISetDeliveryStatus extends IAuthAction {
  DeliveryStatus: any;
}
export interface ISetCustomerName extends IAuthAction {
  CustomerName: any;
}
export interface ISetCustomer extends IAuthAction {
  Customer: any;
}
export interface ISetCustomerProductName extends IAuthAction {
  CustomerProductName: any;
}
export interface ISetCustomerProduct extends IAuthAction {
  CustomerProduct: any;
}
export interface ISetBranch extends IAuthAction {
  Branch: any;
}
export interface ISetCourierName extends IAuthAction {
  CourierName: any;
}
export interface ISetCourier extends IAuthAction {
  Courier: any;
}
export interface ISetDownloadType extends IAuthAction {
  DownloadType: any;
}
export interface ISetTableDeliveryFilter extends IAuthAction {
  TableDeliveryFilter: any;
}
export interface ISetTotalSuccess extends IAuthAction {
  TotalSuccess: any;
}
export interface ISetTotalOnProcess extends IAuthAction {
  TotalOnProcess: any;
}
export interface ISetTotalReturn extends IAuthAction {
  TotalReturn: any;
}
export interface ISetTotalDelivery extends IAuthAction {
  TotalDelivery: any;
}
export default function AuthReducerDetailId(
  state = initialState,
  action: IAuthAction
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'SET_RADIO_BUTTON':
      const setRadioButton = action as ISetRadioButton;
      newState.Filter.RadioButton = setRadioButton.RadioButton;
      return { ...newState };
    case 'SET_CYCLE_DOCUMENT':
      newState.Filter.CycleDocument = !newState.Filter.CycleDocument;
      return { ...newState };
    case 'SET_PHYSICAL_DOCUMENT':
      newState.Filter.PhysicalDocument = !newState.Filter.PhysicalDocument;
      return { ...newState };
    case 'SET_DELIVERY_DOCUMENT':
      newState.Filter.DeliveryDocument = !newState.Filter.DeliveryDocument;
      return { ...newState };
    case 'SET_CYCLE_START':
      const setCycleStart = action as ISetCycleStart;
      newState.Filter.CycleStart = setCycleStart.CycleStart;
      return { ...newState };
    case 'SET_CYCLE_END':
      const setCycleEnd = action as ISetCycleEnd;
      newState.Filter.CycleEnd = setCycleEnd.CycleEnd;
      return { ...newState };
    case 'SET_DELIVERY_STATUS':
      const setDeliveryStatus = action as ISetDeliveryStatus;
      newState.Filter.DeliveryStatus = setDeliveryStatus.DeliveryStatus;
      return { ...newState };
    case 'SET_CUSTOMER_NAME':
      const setCustomerName = action as ISetCustomerName;
      newState.Filter.CustomerName = setCustomerName.CustomerName;
      return { ...newState };
    case 'SET_CUSTOMER':
      const setCustomer = action as ISetCustomer;
      newState.Filter.Customer = setCustomer.Customer;
      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT_NAME':
      const setCustomerProductName = action as ISetCustomerProductName;
      newState.Filter.CustomerProductName =
        setCustomerProductName.CustomerProductName;
      return { ...newState };
    case 'SET_CUSTOMER_PRODUCT':
      const setCustomerProduct = action as ISetCustomerProduct;
      newState.Filter.CustomerProduct = setCustomerProduct.CustomerProduct;
      return { ...newState };
    case 'SET_BRANCH_FILTER':
      const setBranch = action as ISetBranch;
      newState.Filter.Branch = setBranch.Branch;
      return { ...newState };
    case 'SET_COURIER_NAME_FILTER':
      const setCourierName = action as ISetCourierName;
      newState.Filter.CourierName = setCourierName.CourierName;
      return { ...newState };
    case 'SET_COURIER_FILTER':
      const setCourier = action as ISetCourier;
      newState.Filter.Courier = setCourier.Courier;
      return { ...newState };
    case 'SET_DOWNLOAD_TYPE_DD':
      const setDownloadType = action as ISetDownloadType;
      newState.Filter.DownloadType = setDownloadType.DownloadType;
      return { ...newState };
    case 'SET_TABLE_DELIVERY_FILTER':
      const setTableDeliveryFilter = action as ISetTableDeliveryFilter;
      newState.Filter.TableDeliveryFilter =
        setTableDeliveryFilter.TableDeliveryFilter;
      return { ...newState };
    case 'SET_TOTAL_SUCCESS':
      const setTotalSuccess = action as ISetTotalSuccess;
      newState.Filter.TotalSuccess = setTotalSuccess.TotalSuccess;
      return { ...newState };
    case 'SET_TOTAL_ON_PROCESS':
      const setTotalOnProcess = action as ISetTotalOnProcess;
      newState.Filter.TotalOnProcess = setTotalOnProcess.TotalOnProcess;
      return { ...newState };
    case 'SET_TOTAL_RETURN':
      const setTotalReturn = action as ISetTotalReturn;
      newState.Filter.TotalReturn = setTotalReturn.TotalReturn;
      return { ...newState };
    case 'SET_TOTAL_DELIVERY':
      const setTotalDelivery = action as ISetTotalDelivery;
      newState.Filter.TotalDelivery = setTotalDelivery.TotalDelivery;
      return { ...newState };
    case 'CLEAR_CUSTOMER_PRODUCT':
      newState.Filter.CustomerProductName = null;
      newState.Filter.CustomerProduct = null;
      return { ...newState };
    case 'CLEAR_TABLE_DELIVERY_FILTER':
      newState.Filter.TableDeliveryFilter = [];
      return { ...newState };
    case 'CLEAR_COURIER':
      newState.Filter.Courier = null;
      newState.Filter.CustomerName = null;
      return { ...newState };
    case 'CLEAR_CUSTOMER_DD':
      newState.Filter.Customer = null;
      newState.Filter.CustomerName = null;
      return { ...newState };
    case 'CLEAR_BRANCH':
      newState.Filter.Branch = null;
      return { ...newState };
    case 'CLEAR_ALL_FILTER':
      newState.Filter.CycleDocument = true;
      newState.Filter.PhysicalDocument = false;
      newState.Filter.DeliveryStatus = [];
      newState.Filter.DeliveryDocument = false;
      newState.Filter.CycleStart = null;
      newState.Filter.CycleEnd = null;
      newState.Filter.CustomerName = null;
      newState.Filter.Customer = null;
      newState.Filter.CustomerProductName = null;
      newState.Filter.CustomerProduct = null;
      newState.Filter.Branch = null;
      newState.Filter.CourierName = null;
      newState.Filter.Courier = null;
      newState.Filter.DownloadType = null;
      newState.Filter.TableDeliveryFilter = [];
      newState.Filter.TotalSuccess = null;
      newState.Filter.TotalOnProcess = null;
      newState.Filter.TotalReturn = null;
      newState.Filter.TotalDelivery = null;
      return { ...newState };
    case 'RESET_FILTER_EXCEPT_RADIOBUTTON':
      newState.Filter.CycleStart = null;
      newState.Filter.CycleEnd = null;
      newState.Filter.CustomerName = null;
      newState.Filter.Customer = null;
      newState.Filter.CustomerProductName = null;
      newState.Filter.CustomerProduct = null;
      newState.Filter.CourierName = null;
      newState.Filter.Courier = null;
      newState.Filter.DownloadType = null;
      newState.Filter.TableDeliveryFilter = [];
      newState.Filter.TotalSuccess = null;
      newState.Filter.TotalOnProcess = null;
      newState.Filter.TotalReturn = null;
      newState.Filter.TotalDelivery = null;
      return { ...newState };

    case 'RESET_FILTER':
      newState.Filter.CycleDocument = true;
      newState.Filter.PhysicalDocument = false;
      newState.Filter.DeliveryDocument = false;
      newState.Filter.CycleStart = null;
      newState.Filter.CycleEnd = null;
      newState.Filter.CustomerName = null;
      newState.Filter.Customer = null;
      newState.Filter.CustomerProductName = null;
      newState.Filter.CustomerProduct = null;
      newState.Filter.Branch = null;
      newState.Filter.CourierName = null;
      newState.Filter.Courier = null;
      newState.Filter.DownloadType = null;
      newState.Filter.TableDeliveryFilter = [];
      newState.Filter.TotalSuccess = null;
      newState.Filter.TotalOnProcess = null;
      newState.Filter.TotalReturn = null;
      newState.Filter.TotalDelivery = null;
      return { ...newState };
    case 'RESET_FILTER_BRANCH':
      newState.Filter.CycleDocument = true;
      newState.Filter.PhysicalDocument = false;
      newState.Filter.DeliveryDocument = false;
      newState.Filter.CycleStart = null;
      newState.Filter.CycleEnd = null;
      newState.Filter.CustomerName = null;
      newState.Filter.Customer = null;
      newState.Filter.CustomerProductName = null;
      newState.Filter.CustomerProduct = null;
      newState.Filter.CourierName = null;
      newState.Filter.Courier = null;
      newState.Filter.DownloadType = null;
      newState.Filter.TableDeliveryFilter = [];
      newState.Filter.TotalSuccess = null;
      newState.Filter.TotalOnProcess = null;
      newState.Filter.TotalReturn = null;
      newState.Filter.TotalDelivery = null;
      return { ...newState };
  }
  return state;
}
