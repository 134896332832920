import {Action} from 'redux';

const initialState = 
{
    router : null,
    componentRouter : "Beranda",
}

export interface IAuthAction extends Action<string>
{

}

export interface IChangeRouter extends IAuthAction
{
    router : any
}
export interface IChangeComponentRouter extends IAuthAction
{
    componentRouter : any
}


export default function AuthReducerDetailId(state=initialState, action:IAuthAction){
    const newState = Object.assign({}, state)
    switch(action.type)
    {
        case "SET_ROUTER":
            const changeRouter = action as IChangeRouter
            newState.router = changeRouter.router
            
        return {...newState}
        
        case "SET_COMPONENT_ROUTER":
            const changeComponentRouter = action as IChangeComponentRouter
            newState.componentRouter = changeComponentRouter.componentRouter
            
        return {...newState}
    }
    return state
}