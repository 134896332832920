import * as ActionFocusDelivery from 'module/DeliveryStatus/Store/ActionGetFocusDeliveryWaybill';
import * as ActionToggle from '../../../core/action/toggle/index';

import { bindActionCreators, compose } from 'redux';

import OtherDataComponent from 'module/DeliveryStatus/Component/OtherDataComponent';
import React from 'react';
import { connect } from 'react-redux';

interface IProps {
  typeToggle: any;
  getOtherData: any;
  ValueSearch: any;
  actionToggle: any;
  actionFocusDelivery: any;
}
function OtherDataContainer({
  typeToggle,
  getOtherData,
  ValueSearch,
  actionToggle,
  actionFocusDelivery,
}: IProps) {
  function handleCloseModal() {
    actionToggle.changeModal();
    actionToggle.clearJenisToggle();
  }
  function handleSearch(event: any) {
    actionToggle.changeValueSearch(event.target.value.toLowerCase());
  }
  function handleGetFocus(idFocus: any, waybillFocus: any) {
    actionFocusDelivery.setIdFocusDelivery(idFocus);
    actionFocusDelivery.setWaybillFocusDelivery(waybillFocus);
    actionFocusDelivery.getFocusDeliveryStatusWaybill();
  }
  return (
    <div>
      <OtherDataComponent
        typeToggle={typeToggle}
        getOtherData={getOtherData}
        handleCloseModal={handleCloseModal}
        handleGetFocus={handleGetFocus}
        handleSearch={handleSearch}
        ValueSearch={ValueSearch}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  typeToggle: state.Toggle.jenisToggle,
  getOtherData: state.DeliveryStatus.OtherData,
  ValueSearch: state.Toggle.ValueSearch,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionFocusDelivery: bindActionCreators(ActionFocusDelivery, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OtherDataContainer);
