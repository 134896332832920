import React, { Component } from 'react';
import { Row, Col, Select, Form, DatePicker, Button } from 'antd';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';
import CardMaterial from '../../../../materials/CardMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';

const { Option } = Select;

interface IProps {
  roleAccount: any;
  valueDeliveryDate: any;
  deliveryDate: any;
  branch: any;
  IdBranch: any;
  validateDeliveryDate: any;
  validateBranch: any;
  helpValidateDeliveryDate: any;
  helpValidateBranch: any;
  handleValidateDeliveryDate: any;
  handlePutDeliveryDate: any;
  handleGetBranch: any;
  handleGetDataManifest: any;
  handlePutBranch: any;
  handleBlurBranch: any;
  handleResetBranch: any;
  listBranch: any;
  loadingSelectBranch: any;
}

export default class CourierManifestFormComponent extends Component<IProps> {
  render() {
    const minDate = (d: any) => !d || d.isBefore(moment('01-01-2020'));
    const validateButtonSearch =
      this.props.deliveryDate === null ||
      this.props.validateDeliveryDate === 'error'
        ? true
        : false;
    return (
      <div className="spacingBottomCard">
        <SpinMaterial
          spinning={false}
          style={{}}
          size={'large'}
          content={
            <CardMaterial
              extra={''}
              title={<b className='titleCard'>Manifest</b>}
              style={{ borderRadius: '10px' }}
              content={
                <div>
                  <Row>
                    <Col span={12} className='spacingRight'>
                      <div>
                        <span>
                          <b>Tanggal Pengiriman</b>
                        </span>
                        <Form.Item
                          validateStatus={this.props.validateDeliveryDate}
                          help={this.props.helpValidateDeliveryDate}
                        >
                          <DatePicker
                            className={'datePickerDefault'}
                            locale={locale}
                            format={'YYYY-MM-DD'}
                            placeholder={'Pilih Tanggal'}
                            onOpenChange={this.props.handleValidateDeliveryDate}
                            value={this.props.valueDeliveryDate}
                            onChange={this.props.handlePutDeliveryDate}
                            disabledDate={minDate}
                          />
                        </Form.Item>
                      </div>
                    </Col>

                    <Col span={12} className='spacingLeft'>
                      <div>
                        <Row>
                          <Col span={20}>
                            <span>
                              <b>Posko</b>
                            </span>
                            <Form.Item
                              validateStatus={this.props.validateBranch}
                              help={this.props.helpValidateBranch}
                            >
                              <Select
                                loading={
                                  this.props.roleAccount === 'Branch'
                                    ? null
                                    : this.props.loadingSelectBranch
                                }
                                style={{ width: '95%' }}
                                placeholder="Pilih Posko"
                                optionFilterProp="children"
                                disabled={
                                  this.props.roleAccount === 'Branch'
                                    ? true
                                    : false
                                }
                                showSearch
                                allowClear={true}
                                onSelect={this.props.handleResetBranch}
                                onChange={this.props.handlePutBranch}
                                onBlur={this.props.handleBlurBranch}
                                value={
                                  this.props.IdBranch !== null &&
                                  this.props.IdBranch !== 0 ? (
                                    this.props.IdBranch
                                  ) : this.props.branch === null ? (
                                    <span className='colorPlaceholderDropDown'>
                                      Pilih Posko
                                    </span>
                                  ) : (
                                    this.props.branch
                                  )
                                }
                                onFocus={this.props.handleGetBranch}
                              >
                                {this.props.listBranch.map(
                                  (data: any, index: any) => (
                                    <Option
                                      key={index}
                                      value={data.id}
                                      label={data.name}
                                    >
                                      {data.name}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4} style={{ paddingTop: 10 }}>
                            <Button
                              disabled={validateButtonSearch}
                              onClick={this.props.handleGetDataManifest}
                              className='buttonSearchManifestCourier'
                              type="primary"
                              shape="circle"
                              icon="search"
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
