import * as ActionAuth from '../../action/Auth';
import * as ActionCustomerLoading from '../../action/Customer/ActionCustomerLoading';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionSetCustomer from '../../action/Customer/ActionListCustomer';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_GetListCustomer(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(ActionCustomerLoading.setLoadingCustomer());
    yield put(ActionCustomerLoading.setTypeLoadingCustomer('selectCustomer'));
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/customers',
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionCustomerLoading.setLoadingCustomer());
    yield put(ActionCustomerLoading.clearTypeLoadingCustomer());
    yield put(ActionSetCustomer.setListCustomer(data.data));
  } catch (error) {
    if (error.response.status !== 401) {
      yield put(ActionCustomerLoading.setLoadingCustomer());
      yield put(ActionCustomerLoading.clearTypeLoadingCustomer());
    }
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_GetListCustomer() {
  yield takeLatest('GET_LIST_CUSTOMER', S_GetListCustomer);
}
