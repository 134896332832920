import React, { useState } from 'react';
import ModalDetailSyncDataComponent from '../Component/ModalDetailSyncDataComponent';
import { useQuery } from '@tanstack/react-query';
import { getDetailSyncDataDelivery } from 'services/SyncDataServices';

export default function ModalDetailSyncDataContainer(props: any) {
  const {
    modalDetailSyncData,
    setModalDetailSyncData,
    detailSyncData = null,
    setDetailSyncData,
  } = props;
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentSize] = useState<number>(10);
  const {
    data: detailSyncDataTable,
    isFetching: isFetchingDetailSyncDataTable,
  } = useQuery(
    ['detailSyncData', detailSyncData?.id, currentPage, currentSize],
    getDetailSyncDataDelivery,
    {
      enabled: detailSyncData !== null,
    }
  );
  console.log('detailSyncDataTable', detailSyncDataTable);
  const handleCancel = () => {
    setModalDetailSyncData(false);
    setDetailSyncData(null);
  };
  const columnData = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <b>No</b>
        </div>
      ),
      render: (_text, _record, index) => (
        <span>
          {currentPage > 0 ? index + (currentPage * 10 + 1) : index + 1}
        </span>
      ),
      key: 'no',
      width: 10,
    },
    {
      title: <b>Waybill</b>,
      dataIndex: 'waybillCode',
      key: 'waybillCode',
      width: 80,
    },
    {
      title: <b>Tanggal Selesai Kirim</b>,
      dataIndex: 'deliveryFinishedDate',
      key: 'deliveryFinishedDate',
      width: 30,
    },
    {
      title: <b>Status Detail</b>,
      render: row => {
        const { syncDataDeliveryStatus, messageError } = row;
        return (
          <span
            style={{
              color:
                syncDataDeliveryStatus === 'Error'
                  ? '#EA1A1A'
                  : syncDataDeliveryStatus === 'OnProcess'
                  ? '#ECA722'
                  : syncDataDeliveryStatus === 'Success'
                  ? '#00AB44'
                  : '',
            }}
          >
            {syncDataDeliveryStatus === 'Error'
              ? messageError
              : syncDataDeliveryStatus === 'OnProcess'
              ? 'Dalam Proses'
              : syncDataDeliveryStatus === 'Success'
              ? 'Successful'
              : ''}
          </span>
        );
      },
      width: 80,
    },
  ];

  return (
    <ModalDetailSyncDataComponent
      modalDetailSyncData={modalDetailSyncData}
      handleCancel={handleCancel}
      detailSyncData={detailSyncData}
      detailSyncDataTable={detailSyncDataTable?.data || []}
      isFetchingDetailSyncDataTable={isFetchingDetailSyncDataTable}
      currentPage={currentPage}
      totalDatas={detailSyncDataTable?.totalDatas || 0}
      totalPages={detailSyncDataTable?.totalPages || 0}
      setCurrentPage={setCurrentPage}
      columnData={columnData}
    />
  );
}
