import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../../materials/CardMaterial';
import FormInputTextMaterial from '../../../../../materials/FormInputTextMaterial';
import SpinMaterial from '../../../../../materials/SpinMaterial';
import locale from 'antd/es/date-picker/locale/id_ID';

interface IProps
{
    stateLocalDate:any
    handleGenerateWaybill:any
    handleDeliveryDate:any
    handlePutWaybill:any
    handleSearchWaybill:any
    getWaybill:any
    validateSearchButton:any
    valideWaybill:any
    helpValidateWaybill:any
    getName:any
    validateFormName:any
    handlePutName:any
    validateName:any
    helpValidateName:any
    getPhoneNumber:any
    validateFormPhoneNumber:any
    getDeliveryDate:any
    getaddress:any
    handlePutAddress:any
    handleReset:any
    validateResetButton:any
    validateDeliveryDate:any
    validateGenerateWaybill:any
    handlePutPhoneNumber:any
    validatePhoneNumber:any
    helpValidatePhoneNumber:any
    isLoading:any
}
export default class SingleDataComponent extends Component<IProps> {
    render() {
        const convertAddress = this.props.getaddress===null?[]:
        Object.keys(this.props.getaddress)
        
        const ButtonGroup = Button.Group;
        return (
            <div>
            <SpinMaterial
            spinning={this.props.isLoading}
            style={{}}
            size={'large'}
            content={
                <React.Fragment>
                <CardMaterial
                extra={''}
                title={<b style={{ color: '#11BEFF' }}>KIRIM ULANG SATUAN</b>}
                style={{ borderRadius: '10px' }}
                content={
                    <div>
                        <Row>
                            <Col span={12}>
                            <div>
                                <span>
                                <b>Waybill</b>
                                </span>
                                <FormInputTextMaterial
                                    validateType={true}
                                    value={this.props.getWaybill}
                                    className={''}
                                    disabled={false}
                                    addonBefore={''}
                                    onPressEnter={''}
                                    placeholder={'Masukan waybill'}
                                    prefix={''}
                                    maxLength={30}
                                    style={{ borderRadius: '10px' }}
                                    size=""
                                    type={''}
                                    onChange={this.props.handlePutWaybill}
                                    onBlur={this.props.handlePutWaybill}
                                    validatestatus={this.props.valideWaybill}
                                    help={this.props.helpValidateWaybill}
                                />
                            </div>
                            </Col>
                            <Col span={12}>
                                <Button
                                    onClick={this.props.handleSearchWaybill}
                                    style={{ marginLeft:25,marginTop:24,borderRadius:'10px'}}
                                    type="primary"
                                    disabled={this.props.validateSearchButton}
                                >
                                    Cari
                                </Button>
                            </Col>
                        </Row>
                    </div>
                }/>
                <CardMaterial
                extra={''}
                title={null}
                style={{ borderRadius: '10px',marginTop:20 }}
                content={
                    <div>
                        <Row>
                            <Col span={12}>
                            <div>
                                <span>
                                <b>Nama Pemilik</b>
                                </span>
                                <FormInputTextMaterial
                                    validateType={true}
                                    value={this.props.getName}
                                    className={''}
                                    disabled={this.props.validateFormName}
                                    addonBefore={''}
                                    onPressEnter={''}
                                    placeholder={'Masukan nama pemilik'}
                                    prefix={''}
                                    maxLength={''}
                                    style={{ borderRadius: '10px' }}
                                    size=""
                                    type={''}
                                    onChange={this.props.handlePutName}
                                    onBlur={this.props.handlePutName}
                                    validatestatus={this.props.validateName}
                                    help={this.props.helpValidateName}
                                />
                            </div>
                            <div>
                                <span>
                                    <b>No Telp</b>
                                </span>
                                <FormInputTextMaterial
                                    validateType={true}
                                    value={this.props.getPhoneNumber}
                                    className={''}
                                    disabled={this.props.validateFormPhoneNumber}
                                    addonBefore={''}
                                    placeholder={"Masukan nomor telepon"}
                                    prefix={''}
                                    maxLength={14}
                                    style={{borderRadius: "10px"}}
                                    size=''
                                    type={''}
                                    onChange={this.props.handlePutPhoneNumber}
                                    onBlur={''}
                                    validatestatus={this.props.validatePhoneNumber}
                                    help={this.props.helpValidatePhoneNumber}
                                    onPressEnter={''}
                                />
                            </div>
                            <div style={{ marginTop: 27 }}>
                                <span>
                                    <b>Tanggal Kirim</b>
                                </span>
                                <Form.Item
                                validateStatus={''}
                                help={''}
                                >
                                <DatePicker
                                    style={{
                                    width: '100%',
                                    backgroundColor: '#F5F5F5',
                                    fontWeight: 'bold',
                                    }}
                                    locale={locale}
                                    format={'YYYY-MM-DD'}
                                    placeholder={'Pilih Tanggal'}
                                    value={this.props.stateLocalDate}
                                    onChange={this.props.handleDeliveryDate}
                                    disabled={this.props.validateDeliveryDate}
                                />
                                </Form.Item>
                            </div>
                            </Col>
                            <Col span={12} style={{ paddingLeft: '20px' }}>
                                {this.props.getaddress===null?
                                <React.Fragment>
                                <span>
                                <b>Alamat</b>
                                </span>
                                <FormInputTextMaterial
                                validateType={true}
                                value={''}
                                className={''}
                                disabled={this.props.validateFormName}
                                addonBefore={''}
                                onPressEnter={''}
                                placeholder={'Masukan alamat'}
                                prefix={''}
                                maxLength={''}
                                style={{ borderRadius: '10px' }}
                                size=""
                                type={''}
                                onChange={''}
                                onBlur={''}
                                validatestatus={''}
                                help={''}
                            />
                            </React.Fragment>
                            :
                            <React.Fragment>
                                {convertAddress.map((data)=>{
                                return <div>
                                    <div>
                                <span>
                                <b>{data==='Alamat1'?'Alamat':''}</b>
                                </span>
                                <Form.Item>
                                <Input 
                                    placeholder='Masukan alamat'
                                    defaultValue={this.props.getaddress[data]}
                                    disabled={this.props.validateFormName}
                                    style={data!=='Alamat1'?
                                        {
                                        borderRadius: '10px',
                                        marginTop:26
                                        }
                                    :
                                        {
                                             borderRadius: '10px'
                                        }
                                    }
                                    onChange={
                                        (e: any) =>
                                        this.props.handlePutAddress(
                                            e,
                                            data
                                            )
                                    }
                                />
                                </Form.Item>
                            </div>
                                </div>
                            })}
                            </React.Fragment>
                            }
                            </Col>
                        </Row>
                    </div>
                }/>
                <ButtonGroup style={{float:'right',marginTop:20}}>
                    <div style={{float:'left', paddingRight:'15px'}}>
                    <ButtonMaterial
                    disabled={this.props.validateResetButton}
                    handleSubmit={this.props.handleReset}
                    teksButton={"Reset"}
                    size={""} 
                    shape={"round"}
                    className={""}
                    type={"primary"}
                    icon={""}
                    style={
                    this.props.validateResetButton===true?
                        {
                            width:130,
                            borderRadius:'20px'
                        }
                        :
                        {
                            borderRadius:'20px',
                            color: "#fafafa" ,
                            width:130,
                            background:"#FF5858",
                            borderColor:"#FF5858"
                        }
                    }
                        />
                    </div>
                    <div style={{float:'right'}}>
                        <ButtonMaterial
                            disabled={this.props.validateGenerateWaybill}
                            handleSubmit={this.props.handleGenerateWaybill}
                            teksButton={"Generate waybill"}
                            size={""}
                            shape={"round"}
                            className={""}
                            type={"primary"}
                            icon={""}
                            style={{borderRadius:'20px'}}
                        />
                    </div>
                </ButtonGroup>
                </React.Fragment>
            }/>
            </div>
        )
    }
}
