const PickupDataValidation = (values, props) => {
  const { startDate, finishDate } = values;
  const errors: any = {};
  if (!startDate) {
    errors.startDate = 'Tanggal wajib diisi';
  }
  if (!finishDate) {
    errors.finishDate = 'Tanggal wajib diisi';
  }
  const pickupDataStatusValueLength = values.pickupStatus
    ? values.pickupStatus.length
    : 0;
  if (pickupDataStatusValueLength === 0) {
    errors.pickupStatus = 'Status pengambilan wajib diisi';
  }
  return errors;
};
export default PickupDataValidation;
