import React from 'react';
import { Icon } from 'antd';
import styles from './ContentAlertWarning.module.css';

export default function ContentAlertWarning({
  title,
  children,
}: {
  title: string;
  children?: React.ReactNode;
}) {
  return (
    <div className={styles.wrapperWarning}>
      <Icon type="warning" className={styles.iconWarning} />
      <div className={styles.wrapperContentWarning}>
        <p className={styles.titleWarning}>{title}</p>
        {children}
      </div>
    </div>
  );
}
