import { Col, DatePicker, Form, Radio, Row, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';
import React, { Component } from 'react';
import SelectField from 'components/SelectField';
import ButtonMaterial from '../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../materials/CardMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';

const { Option } = Select;

interface IProps {
  radioButton: any;
  valueScanDate: any;
  scanDate: any;
  customer: any;
  treatmentDocument: any;
  customerProduct: any;
  validateScanDate: any;
  validateCustomer: any;
  validateCustomerProduct: any;
  helpValidateScanDate: any;
  helpValidateCustomer: any;
  helpValidateCustomerProduct: any;
  handleValidateScanDate: any;
  handlePutScanDate: any;
  handlePutCustomer: any;
  handlePutCustomerProduct: any;
  handleBlurCustomer: any;
  handleBlurCustomerProduct: any;
  handleGetDataManifest: any;
  handleGetCustomer: any;
  handleGetCustomerProduct: any;
  handleResetProduct: any;
  handleResetCustomer: any;
  handleChangeRadioButton: any;
  handleChangeTreatmentDocument: any;
  listCustomer: any;
  listCustomerProduct: any;
  loadingCustomer: any;
  loadingCustomerProduct: any;
}

export default class CustomerManifestFormComponent extends Component<IProps> {
  render() {
    const minDate = (d: any) => !d || d.isBefore(moment('01-01-2020'));
    const validateButtonSearch =
      this.props.customer === null
        ? this.props.scanDate === null ||
          this.props.customer === null ||
          this.props.validateScanDate === 'error' ||
          this.props.validateCustomer === 'error'
          ? true
          : false
        : this.props.customerProduct === null ||
          this.props.validateCustomerProduct === 'error' ||
          this.props.scanDate === null ||
          this.props.validateScanDate === 'error' ||
          this.props.validateCustomer === 'error'
        ? true
        : false;
    return (
      <div className="spacingBottomCard">
        <SpinMaterial
          spinning={false}
          style={{}}
          size={'large'}
          content={
            <CardMaterial
              extra={''}
              title={
                <b className="titleCard">
                  Tanda Terima Pengembalian Dokumen Pelanggan
                </b>
              }
              style={{ borderRadius: '10px' }}
              content={
                <div>
                  <Row style={{ marginBottom: '25px' }}>
                    <Col span={12} className="spacingRight">
                      <div>
                        <Radio.Group
                          onChange={this.props.handleChangeRadioButton}
                          value={this.props.radioButton}
                        >
                          <Radio value={'Success'}>Sukses</Radio>
                          <Radio value={'Return'}>Return</Radio>
                        </Radio.Group>
                      </div>
                    </Col>
                    <Col span={12} className="spacingLeft"></Col>
                  </Row>
                  <Row>
                    <Col span={12} className="spacingRight">
                      <div>
                        <Row>
                          <span>
                            <b>Pelanggan</b>
                          </span>
                          <Form.Item
                            validateStatus={this.props.validateCustomer}
                            help={this.props.helpValidateCustomer}
                          >
                            <Select
                              loading={this.props.loadingCustomer}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              value={
                                this.props.customer === null ? (
                                  <span className="colorPlaceholderDropDown">
                                    Pilih Pelanggan
                                  </span>
                                ) : (
                                  this.props.customer
                                )
                              }
                              showSearch
                              onSelect={this.props.handleResetCustomer}
                              onChange={this.props.handlePutCustomer}
                              onBlur={this.props.handleBlurCustomer}
                              onFocus={this.props.handleGetCustomer}
                            >
                              {this.props.listCustomer.map(
                                (data: any, index: any) => (
                                  <Option key={index} value={data.id}>
                                    {data.name}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                          <span>
                            <b>Produk</b>
                          </span>
                          <Form.Item
                            validateStatus={this.props.validateCustomerProduct}
                            help={this.props.helpValidateCustomerProduct}
                          >
                            <Select
                              loading={this.props.loadingCustomerProduct}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              placeholder="Pilih Produk Pelanggan"
                              disabled={
                                this.props.customer === null ? true : false
                              }
                              showSearch
                              value={
                                this.props.customerProduct === null ? (
                                  <span className="colorPlaceholderDropDown">
                                    Pilih Produk
                                  </span>
                                ) : (
                                  this.props.customerProduct
                                )
                              }
                              onFocus={this.props.handleGetCustomerProduct}
                              onSelect={this.props.handleResetProduct}
                              onBlur={this.props.handleBlurCustomerProduct}
                              onChange={this.props.handlePutCustomerProduct}
                            >
                              {this.props.listCustomerProduct.map(
                                (data: any, index: any) => (
                                  <Option key={index} value={data.id}>
                                    {data.name}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </Row>
                      </div>
                    </Col>
                    <Col span={12} className="spacingLeft">
                      <div>
                        <span>
                          <b>Tanggal Scan</b>
                        </span>
                        <Form.Item
                          validateStatus={this.props.validateScanDate}
                          help={this.props.helpValidateScanDate}
                        >
                          <DatePicker
                            className={'datePickerDefault'}
                            locale={locale}
                            format={'YYYY-MM-DD'}
                            placeholder={'Pilih Tanggal'}
                            onOpenChange={this.props.handleValidateScanDate}
                            value={this.props.valueScanDate}
                            onChange={this.props.handlePutScanDate}
                            disabledDate={minDate}
                          />
                        </Form.Item>
                        <SelectField
                          label="Perlakuan Dokumen"
                          placeholder="Pilih Perlakuan Dokumen"
                          value={this.props.treatmentDocument}
                          mode="multiple"
                          allowClear
                          data={[
                            { id: 0, name: 'Pilih Semua' },
                            {
                              id: 'ReturnCustomer',
                              name: 'Dikembalikan ke Pelanggan',
                            },
                            { id: 'Destroy', name: 'Dihancurkan' },
                          ]}
                          onChange={value =>
                            this.props.handleChangeTreatmentDocument(value)
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} className="spacingRight">
                      <div></div>
                    </Col>
                    <Col span={12} className="spacingLeft">
                      <div className={'spacingDownloadButton'}>
                        <ButtonMaterial
                          disabled={validateButtonSearch}
                          handleSubmit={this.props.handleGetDataManifest}
                          teksButton={<b>Cari</b>}
                          size={'middle'}
                          shape={'round'}
                          className={'buttonCariCustomerManifest'}
                          type={'primary'}
                          icon={''}
                          style={{}}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
