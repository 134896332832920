import React, { Component } from 'react';

import ButtonMaterial from '../../../materials/ButtonMaterial';

interface IProps {
  typeToggle: any;
  handleGetGroupingDetail: any;
}
export default class ButtonGroupingBranchComponent extends Component<IProps> {
    render() {
    const handleSubmit = this.props.handleGetGroupingDetail
    return (
      <React.Fragment>
        <ButtonMaterial
          disabled={''}
          handleSubmit={handleSubmit}
          teksButton={''}
          size={''}
          shape={''}
          className={'btnEdit'}
          type={''}
          icon={'edit'}
          style={{}}
        />
      </React.Fragment>
    );
  }
}
