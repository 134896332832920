import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';

import { put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';
import { message } from 'antd';
import moment from 'moment';

export function* S_DownloadWaybill(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const DeliveryTime = state.Delivery.PrintWaybill.DataDownload.DeliveryTime;
  const BranchName = state.Delivery.PrintWaybill.DataDownload.BranchName;
  const CourierCode = state.Delivery.PrintWaybill.DataDownload.CourierCode;
  const BranchId = state.Delivery.PrintWaybill.DataDownload.BranchId;
  const CourierId = state.Delivery.PrintWaybill.DataDownload.CourierId;
  const Cycle = state.Delivery.PrintWaybill.DataDownload.Cycle;
  const Position = state.Delivery.PrintWaybill.DataDownload.Position;
  // const Watermark = state.PrintWaybill.FormPrintWaybill.Watermark;
  const key = 'download';

  const params = {
    itemDeliveryTime: DeliveryTime,
    cycle: Cycle,
    position: Position,
    branchId: BranchId,
    branchName: BranchName,
    courierCode: CourierCode,
    courierId: CourierId,
    watermark: true,
  };
  try {
    Modal.info({
      title: <p style={{ color: '#11beff' }}>{<b>Download Data</b>}</p>,
      content: (
        <div>
          <p>Sedang mendownload data, mohon tunggu</p>
        </div>
      ),
    });
    axios({
      url: `${process.env.REACT_APP_URL}/Delivery/DownloadWaybillPdf`,
      method: 'POST',
      responseType: 'blob',
      data: params,
      headers: config,
      onDownloadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        let percentProgress = Math.floor((loaded * 100) / total);
        // let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        if (percentProgress < 100) {
          message.loading({
            content: 'Loading ' + percentProgress + '%',
            //   <Progress
            //   type="circle"
            //   strokeColor={{
            //     '0%': '#108ee9',
            //     '100%': '#87d068',
            //   }}
            //   percent={percentProgress}
            //   width={80}
            // />
            key,
          });
        } else if (percentProgress === 100) {
          Modal.destroyAll();
          message.success({
            content: 'Download Berhasil',
            key,
            duration: 1.5,
          });
        } else if (percentProgress === Infinity) {
          Modal.destroyAll();
          message.error({
            content: 'Download Gagal',
            key,
            duration: 3,
          });
        }
      },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `WAYBILL-KE(${Position})-${BranchName}-${CourierCode}-${moment(
          DeliveryTime
        ).format('YYYYMMDD')}.pdf`
      );
      document.body.appendChild(link);
      link.click();
    });
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
  } catch (error) {
    if (error.response.data.Message === 'No delivery found!') {
      message.warning({
        content: 'Waybill Tidak Tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* SM_DownloadWaybill() {
  yield takeLatest('DOWNLOAD_WAYBILL', S_DownloadWaybill);
}
