import * as FormConfigReport from '../../../core/action/Customer/ActionDetailConfigReport';
import * as toggle from '../../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import FormCustomerReportComponent from '../../../components/Content/Customer/FormCustomerReportComponent';
import { connect } from 'react-redux';

interface IProps {
  actionToggle: any;
  visible: any;
  fiture: any;
  jenisToggle: any;
  actionFormConfigReport: any;
  GetCustomerName: any;
  GetCustomerNameReport: any;
  GetFormatReport: any;
  GetPeriodeReport: any;
  GetDelimitedReport: any;
  GetWithoutHeaderReport: any;
  GetShowInPortalReport: any;
  GetSettingReport: any;
  GetCsvList: any;
  isLoading: any;
}

interface IState {
  ValidateCustomerName: any;
  ValidateFormatReport: any;
  ValidateSettingReport: any;
  ValidatePeriodeReport: any;
  ValidateDelimitedReport: any;
}

class FormCustomerReportContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateCustomerName: null,
      ValidateFormatReport: null,
      ValidateSettingReport: null,
      ValidatePeriodeReport: null,
      ValidateDelimitedReport: null,
    };
    this.handleClearConfigReport = this.handleClearConfigReport.bind(this);
    this.handlePutCustomerNameReport = this.handlePutCustomerNameReport.bind(
      this
    );
    this.handlePutFormatReport = this.handlePutFormatReport.bind(this);
    this.handleBlurFormatReport = this.handleBlurFormatReport.bind(this);
    this.handlePutSettingReport = this.handlePutSettingReport.bind(this);
    this.handleBlurSettingReport = this.handleBlurSettingReport.bind(this);
    this.handleResetFormatValidateReport = this.handleResetFormatValidateReport.bind(
      this
    );
    this.handlePutCheckbox = this.handlePutCheckbox.bind(this)
    this.handlePutCheckboxShow = this.handlePutCheckboxShow.bind(this)
    this.handlePutPeriodeReport = this.handlePutPeriodeReport.bind(this);
    this.handleBlurPeriodeReport = this.handleBlurPeriodeReport.bind(this);
    this.handleResetPeriodeValidateReport = this.handleResetPeriodeValidateReport.bind(
      this
    );
    this.handlePutDelimitedReport = this.handlePutDelimitedReport.bind(this);
    this.handleBlurDelimitedReport = this.handleBlurDelimitedReport.bind(this);
    this.handleResetDelimitedValidateReport = this.handleResetDelimitedValidateReport.bind(
      this
    );
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClearConfigReport() {
    if (this.props.fiture === 'DetailConfigReport') {
    } else {
      this.props.actionFormConfigReport.changeClearCustomerConfigReport();
    }
    this.props.actionToggle.changeModal();
    this.props.actionToggle.clearJenisToggle();
    this.setState({
      ValidateCustomerName: '',
      ValidateFormatReport: '',
      ValidatePeriodeReport: '',
      ValidateDelimitedReport: '',
      ValidateSettingReport: '',
    });
  }

  handlePutCustomerNameReport(event: any) {
    this.props.actionFormConfigReport.changeCustomerNameReport(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateCustomerName: 'error',
      });
    } else if (event.target.value.length < 4) {
      this.setState({
        ValidateCustomerName: 'notMinChar',
      });
    } else {
      this.setState({
        ValidateCustomerName: '',
      });
    }
  }
  handlePutFormatReport(event: any) {
    this.props.actionFormConfigReport.changeFormatReport(event);
    if (event === 'Excel') {
      this.props.actionFormConfigReport.changeDelimitedReport('Empty');
      this.setState({
        ValidateDelimitedReport: null,
      });
    } else {
      if (this.props.GetDelimitedReport !== 'Empty') {
      } else {
        this.props.actionFormConfigReport.clearDelimitedReport();
      }
    }
  }
  handleResetFormatValidateReport(event: any) {
    this.setState({
      ValidateFormatReport: null,
    });
  }
  handleBlurFormatReport(event: any) {
    if (this.props.GetFormatReport === null) {
      this.setState({
        ValidateFormatReport: 'error',
      });
    } else {
      this.setState({
        ValidateFormatReport: null,
      });
    }
  }
  handlePutSettingReport(event: any) {
    this.props.actionFormConfigReport.changeSettingReport(event);
  }
  handleBlurSettingReport(event: any) {
    if (this.props.GetSettingReport === null) {
      this.setState({
        ValidateSettingReport: 'error',
      });
    } else {
      this.setState({
        ValidateSettingReport: null,
      });
    }
  }
  handlePutPeriodeReport(event: any) {
    this.props.actionFormConfigReport.changePeriodeReport(event);
  }
  handleResetPeriodeValidateReport(event: any) {
    this.setState({
      ValidatePeriodeReport: null,
    });
  }
  handleBlurPeriodeReport(event: any) {
    if (this.props.GetPeriodeReport === null) {
      this.setState({
        ValidatePeriodeReport: 'error',
      });
    } else {
      this.setState({
        ValidatePeriodeReport: null,
      });
    }
  }
  handlePutDelimitedReport(event: any) {
    this.props.actionFormConfigReport.changeDelimitedReport(event);
  }
  handlePutCheckbox(event:any)
    {
        this.props.actionFormConfigReport.changeWithoutHeaderReport(event.target.checked)
    }
  handlePutCheckboxShow(event:any)
    {
        this.props.actionFormConfigReport.changeShowInPortalReport(event.target.checked)
    }
  handleResetDelimitedValidateReport(event: any) {
    this.setState({
      ValidateDelimitedReport: null,
    });
  }
  handleBlurDelimitedReport(event: any) {
    if (this.props.GetDelimitedReport === null) {
      this.setState({
        ValidateDelimitedReport: 'error',
      });
    } else {
      this.setState({
        ValidateDelimitedReport: null,
      });
    }
  }
  handleSubmit() {
    this.props.jenisToggle === 'AddFormatReport'
      ? this.props.actionFormConfigReport.submitAddConfigReport()
      : this.props.actionFormConfigReport.submitEditConfigReport();
  }
  
  render() {
    return (
      <div>
        <FormCustomerReportComponent
          visible={
            this.props.jenisToggle === 'AddFormatReport' ||
            this.props.jenisToggle === 'EditFormatReport'
              ? true
              : false
          }
          jenisToggle={this.props.jenisToggle}
          ValidateButton={
            this.props.GetCustomerName === '' ||
            this.props.GetCustomerName === null ||
            this.state.ValidateCustomerName === 'notMinChar' ||
            this.props.GetCustomerNameReport === '' ||
            this.props.GetCustomerNameReport === null ||
            this.props.GetFormatReport === '' ||
            this.props.GetFormatReport === null ||
            this.props.GetPeriodeReport === '' ||
            this.props.GetPeriodeReport === null ||
            this.props.GetDelimitedReport === '' ||
            this.props.GetDelimitedReport === null ||
            this.props.GetSettingReport === '' ||
            this.props.GetSettingReport === null
              ? true
              : false
          }
          GetCustomerName={this.props.GetCustomerName}
          PutCustomerNameReport={this.handlePutCustomerNameReport}
          ValidateCustomerNameReport={
            this.state.ValidateCustomerName === 'error' ||
            this.state.ValidateCustomerName === 'notMinChar'
              ? 'error'
              : ''
          }
          helpCustomerNameReport={
            this.state.ValidateCustomerName === 'error'
              ? 'Nama format wajib diisi'
              : this.state.ValidateCustomerName === 'notMinChar'
              ? 'Nama format harus lebih dari 3 karakter'
              : ''
          }
          GetCustomerNameReport={this.props.GetCustomerNameReport}
          PutFormatReport={this.handlePutFormatReport}
          GetFormatReport={this.props.GetFormatReport}
          ValueFormatReport={
            this.props.GetFormatReport === null ? (
              <span style={{ color: '#969696' }}>Pilih format file</span>
            ) : (
              this.props.GetFormatReport
            )
          }
          handleBlurFormatReport={this.handleBlurFormatReport}
          ValidateFormatReport={
            this.state.ValidateFormatReport === 'error' ? 'error' : ''
          }
          helpFormatReport={
            this.state.ValidateFormatReport === 'error'
              ? 'Format file wajib diisi'
              : ''
          }
          handleResetFormatValidateReport={this.handleResetFormatValidateReport}
          PutPeriodeReport={this.handlePutPeriodeReport}
          GetPeriodeReport={this.props.GetPeriodeReport}
          ValuePeriodeReport={
            this.props.GetPeriodeReport === null ? (
              <span style={{ color: '#969696' }}>Pilih periode</span>
            ) : (
              this.props.GetPeriodeReport
            )
          }
          handleBlurPeriodeReport={this.handleBlurPeriodeReport}
          ValidatePeriodeReport={
            this.state.ValidatePeriodeReport === 'error' ? 'error' : ''
          }
          helpPeriodeReport={
            this.state.ValidatePeriodeReport === 'error'
              ? 'Periode wajib diisi'
              : ''
          }
          handleResetPeriodeValidateReport={
            this.handleResetPeriodeValidateReport
          }
          PutDelimitedReport={this.handlePutDelimitedReport}
          GetDelimitedReport={this.props.GetDelimitedReport}
          ValueDelimitedReport={
            this.props.GetDelimitedReport === null ? (
              <span style={{ color: '#969696' }}>Pilih delimited</span>
            ) : (
              this.props.GetDelimitedReport
            )
          }
          handleBlurDelimitedReport={this.handleBlurDelimitedReport}
          ValidateDelimitedReport={
            this.state.ValidateDelimitedReport === 'error' ? 'error' : ''
          }
          helpDelimitedReport={
            this.state.ValidateDelimitedReport === 'error'
              ? 'Delimited wajib diisi'
              : ''
          }
          handleResetDelimitedValidateReport={
            this.handleResetDelimitedValidateReport
          }
          ValueSettingReport={
            this.props.GetSettingReport === null ? (
              <span style={{ color: '#969696' }}>Pilih format file csv</span>
            ) : (
              this.props.GetSettingReport
            )
          }
          PutSettingReport={this.handlePutSettingReport}
          GetSettingReport={this.props.GetSettingReport}
          handleBlurSettingReport={this.handleBlurSettingReport}
          ValidateSettingReport={
            this.state.ValidateSettingReport === 'error' ? 'error' : ''
          }
          helpSettingReport={
            this.state.ValidateSettingReport === 'error'
              ? 'Format file softcopy wajib diisi'
              : ''
          }
          handlePutCheckbox={this.handlePutCheckbox}
          GetWithoutHeader = {this.props.GetWithoutHeaderReport}
          handlePutCheckboxShow = {this.handlePutCheckboxShow}
          GetShowInPortalReport = {this.props.GetShowInPortalReport}
          GetCsvList={this.props.GetCsvList}
          handleSubmit={this.handleSubmit}
          handleClearConfigReport={this.handleClearConfigReport}
          isLoading={this.props.isLoading}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  jenisToggle: state.Toggle.jenisToggle,
  GetCustomerName: state.Customer.DetailCustomer.CustomerName,
  GetCustomerNameReport:
    state.Customer.FormCustomerConfigReport.CustomerNameReport,
  GetFormatReport: state.Customer.FormCustomerConfigReport.FormatReport,
  GetSettingReport: state.Customer.FormCustomerConfigReport.SettingReport,
  GetPeriodeReport: state.Customer.FormCustomerConfigReport.PeriodeReport,
  GetDelimitedReport: state.Customer.FormCustomerConfigReport.DelimitedReport,
  GetWithoutHeaderReport: state.Customer.FormCustomerConfigReport.WithoutHeader,
  GetShowInPortalReport: state.Customer.FormCustomerConfigReport.ShowInPortal,
  GetCsvList: state.Customer.ListCustomerCsvType,
  isLoading: state.Toggle.isLoading,
  fiture: state.LastAccess.Fiture,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(toggle, dispatch),
  actionFormConfigReport: bindActionCreators(FormConfigReport, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FormCustomerReportContainer);
