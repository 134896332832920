import { Col, Row, Table } from 'antd';
import ModalMaterial from 'materials/ModalMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import moment from 'moment-timezone';
import React from 'react';

export default function ModalDetailSyncDataComponent(props) {
  const {
    modalDetailSyncData,
    handleCancel,
    detailSyncData,
    isFetchingDetailSyncDataTable,
    detailSyncDataTable = [],
    setCurrentPage,
    totalDatas,
    columnData,
    currentPage,
  } = props;
  return (
    <ModalMaterial
      width={'70%'}
      teksTitle="DETAIL SYNC DATA API"
      visible={modalDetailSyncData}
      onCancel={() => handleCancel()}
    >
      <SpinMaterial spinning={isFetchingDetailSyncDataTable} size="large">
        <Row style={{ padding: '16px 24px' }} gutter={[0, 24]}>
          <Col span={12}>
            <b>Tanggal Sync Data</b>
            <p>
              {detailSyncData &&
                moment(detailSyncData.syncDeliveryDate).format(
                  'DD-MM-YYYY HH:mm:ss'
                )}
            </p>
          </Col>
          <Col span={12}>
            <b>Status Pengiriman</b>
            <p>Dikembalikan</p>
          </Col>
          <Col span={12}>
            <b>Tanggal Pengiriman</b>
            <p>{detailSyncData && detailSyncData.deliveryDate}</p>
          </Col>
          <Col span={12}>
            <b>Pelanggan</b>
            <p>{detailSyncData && detailSyncData.customer}</p>
          </Col>
          <Col span={12}></Col>
          <Col span={12}>
            <b>Produk</b>
            <p>{detailSyncData && detailSyncData.customerProduct}</p>
          </Col>
        </Row>

        <Table
          style={{ padding: '16px 24px' }}
          rowKey={(record: any) => record.id}
          scroll={{ x: 'auto' }}
          pagination={{
            onChange: page => setCurrentPage(page - 1),

            pageSize: 10,
            total: totalDatas,
            current: currentPage === 0 ? 1 : currentPage + 1,
          }}
          bordered={false}
          dataSource={detailSyncDataTable}
          columns={columnData}
          footer={() => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                gap: 8,
              }}
            >
              <p style={{ margin: 0, fontWeight: 500 }}>
                Total Entri ({totalDatas || 0})
              </p>
            </div>
          )}
        />
      </SpinMaterial>
    </ModalMaterial>
  );
}
