import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

export function* S_downloadDeliveryReport(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const key = 'download';

  const firstDate = moment(
    state.Report.DeliveryReportForm.CycleDateStart
  ).format('YYYYMMDD');
  const lastDate = moment(state.Report.DeliveryReportForm.CycleDateLast).format(
    'YYYYMMDD'
  );
  const customerId = state.Report.DeliveryReportForm.CustomerId;
  const branchId = state.Report.DeliveryReportForm.BranchId;
  const checkboxToday = state.Report.DeliveryReportForm.CheckBoxToday;

  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = `Laporan Pengiriman ${firstDate} - ${lastDate}.xlsx`;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }

  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Delivery/DownloadSummaryDelivery',
      {
        cycleStart: firstDate,
        cycleEnd: lastDate,
        customer: customerId,
        branch: branchId,
        toDay: checkboxToday,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);
          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    if (error.response.status === 500) {
      message.error({
        content: 'Download Gagal',
        key,
        duration: 3,
      });
    }
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_downloadDeliveryReport() {
  yield takeLatest('DOWNLOAD_DELIVERY_REPORT', S_downloadDeliveryReport);
}
