import { Alert, Col, Row } from 'antd';
import { Field, reduxForm } from 'redux-form';
import { InputFile, InputSelect } from '../../../apps/assets/components/CInput';

import CButton from '../../../apps/assets/components/CButton';
import CCard from '../../../apps/assets/components/CCard';
import CIcon from '../../../apps/assets/components/CIcon';
import CModal from '../../../apps/assets/components/CModal';
import CSpin from '../../../apps/assets/components/CSpin';
import { InputDatePicker } from '../../../apps/assets/components/CInputDate';
import React from 'react';
import moment from 'moment';
import { typeDocument } from '../../../apps/enums';

// import validate from '../Validation/CallCenterFormValidation';

function CallCenterFormComponent(props) {
  const {
    handleClearFormatCsvAndProduct,
    handleGetCourierList,
    handleConvertCsv,
    handleCloseModal,
    handleGetCustomerAndBranchList,
    handleGetFormatCsv,
    handleGetProduct,
    handleClearCourier,
    handleSubmit,
    handleDownload,
    isLoading,
    isLoadingBranchList,
    isLoadingCustomerList,
    isLoadingFormatCsvList,
    isLoadingCourierList,
    customerProductList,
    formatCsvList,
    branchList,
    courierList,
    customerList,
    totalSuccessKu,
    totalFailedKu,
    totalSuccessAttempt,
    totalFailedAttempt,
    notificationModalIsShow,
    // errorFormValue,
    formValue,
  } = props;
  const minDate = (d: any) =>
    !d ||
    d.isBefore('2020-01-01') ||
    d.isAfter(moment(new Date()).add(1, 'month').format('YYYY-MM-DD'));
  const valueCustomer = formValue ? formValue.customer : '';
  const valueTypeDocument = formValue ? formValue.documentType : '';
  const valueProduct = formValue ? formValue.product : '';
  const valueBranch = formValue ? formValue.branch : '';
  const valueCourier = formValue ? formValue.courier : '';
  const valueFormatCsv = formValue ? formValue.formatCsv : '';
  const valueDeliveryDate = formValue ? formValue.deliveryDate : '';
  const valueFileCsv = formValue ? formValue.fileCsv : '';
  // const validateBtnSubmit = Object.keys(errorFormValue).length > 0;
  const validateBtnSubmit =
    valueCustomer === '' ||
    valueCustomer === undefined ||
    valueCustomer === null ||
    valueTypeDocument === '' ||
    valueTypeDocument === undefined ||
    valueTypeDocument === null ||
    valueProduct === '' ||
    valueProduct === undefined ||
    valueProduct === null ||
    valueBranch === '' ||
    valueBranch === undefined ||
    valueBranch === null ||
    valueCourier === '' ||
    valueCourier === undefined ||
    valueCourier === null ||
    valueFormatCsv === '' ||
    valueFormatCsv === null ||
    valueFormatCsv === undefined ||
    valueDeliveryDate === '' ||
    valueDeliveryDate === undefined ||
    valueDeliveryDate === null ||
    valueFileCsv === '' ||
    valueFileCsv === undefined ||
    valueFileCsv === null
      ? true
      : false;

  const btnSubmit = [
    {
      type: 'primary',
      className: 'btnDefaultSubmit',
      onClick: handleSubmit,
      isLoading: isLoading,
      content: 'Simpan',
      id: 'btnSubmitCallCenter',
      disabled: validateBtnSubmit,
    },
  ];
  const btnModal = [
    {
      className: 'btnDownloadDefault',
      onClick: handleDownload,
      isLoading: isLoading,
      content: 'Download',
      id: 'btnDownloadCallCenter',
    },
    {
      type: 'primary',
      className: 'btnDefaultSubmit',
      onClick: handleCloseModal,
      isLoading: isLoading,
      content: 'Tutup',
      id: 'btnTutupCallCenter',
    },
  ];
  const isCustomer =
    valueCustomer === undefined || valueCustomer === '' ? true : false;
  const isBranch = !valueBranch ? true : false;
  const showModal = () => {
    const textAlertSuccess = () => {
      return (
        <React.Fragment>
          <Col span={6}>
            <CIcon className={'iconNotifSuccess'} type="check-square" />
          </Col>
          <Col span={18} className="greenText">
            <Row>
              Berhasil kirim ulang : <b>{totalSuccessKu}</b>
            </Row>
            <Row>
              Berhasil attempt : <b>{totalSuccessAttempt}</b>
            </Row>
          </Col>
        </React.Fragment>
      );
    };
    const textAlertFailed = () => {
      return (
        <React.Fragment>
          <Col span={6}>
            <CIcon className={'iconNotifFailed'} type="close-square" />
          </Col>
          <Col span={18} className="redText">
            <Row>
              Gagal kirim ulang : <b>{totalFailedKu}</b>
            </Row>
            <Row>
              Gagal attempt : <b>{totalFailedAttempt}</b>
            </Row>
          </Col>
        </React.Fragment>
      );
    };
    return (
      <CModal
        className={''}
        teksTitle={'STATUS UNGGAH DATA'}
        visible={notificationModalIsShow}
        onCancel={handleCloseModal}
        content={
          <div className="defaultPaddingModal">
            <Row>
              <Col span={12} className="spacingRightAlertCallCenter">
                <Alert
                  className="alertSuccess"
                  message={textAlertSuccess()}
                  type="success"
                />
              </Col>
              <Col span={12} className="spacingLeftAlertCallCenter">
                <Alert
                  className="alertSuccess"
                  message={textAlertFailed()}
                  type="error"
                />
              </Col>
            </Row>
            <Row className="rowButtonCallCenter">
              <CButton buttonData={btnModal} />
            </Row>
          </div>
        }
      />
    );
  };
  const contentCard = () => {
    return (
      <React.Fragment>
        <Row>
          <Col span={12} className="spacingRight">
            <Field
              name="customer"
              component={InputSelect}
              idComponent="inputCustomer"
              loading={isLoadingCustomerList}
              data={customerList}
              dafaultItemName="Pilih Pelanggan"
              additionalHandleOnFocusFunc={handleGetCustomerAndBranchList}
              additionalHandleChangeFunc={handleClearFormatCsvAndProduct}
              label={'Pelanggan'}
            />
            <Field
              name="documentType"
              component={InputSelect}
              idComponent="inputDocumentType"
              dafaultItemName="Pilih Jenis Dokumen"
              data={typeDocument}
              label={'Jenis Dokumen'}
            />
            <Field
              name="product"
              component={InputSelect}
              idComponent="inputProduct"
              dafaultItemName="Pilih Produk"
              additionalHandleOnFocusFunc={handleGetProduct}
              data={customerProductList}
              disabled={isCustomer}
              label={'Produk'}
            />
            <Field
              name="branch"
              component={InputSelect}
              loading={isLoadingBranchList}
              idComponent="inputBranch"
              dafaultItemName="Pilih Posko"
              additionalHandleChangeFunc={handleClearCourier}
              additionalHandleOnFocusFunc={handleGetCourierList}
              data={branchList}
              disabled={isCustomer}
              label={'Posko'}
            />
          </Col>
          <Col span={12}>
            <Field
              name="formatCsv"
              component={InputSelect}
              loading={isLoadingFormatCsvList}
              idComponent="inputFormatCsv"
              dafaultItemName="Pilih template unggah"
              additionalHandleOnFocusFunc={handleGetFormatCsv}
              data={formatCsvList}
              disabled={isCustomer}
              label={'Template Unggah'}
            />
            <Field
              additionalHandleChangeFunc={handleConvertCsv}
              name="fileCsv"
              className="fileUploadInput"
              component={InputFile}
              idComponent="inputFile"
              multiple={false}
              accept=".csv"
              label={'Unggah File'}
            />
            <Field
              name="deliveryDate"
              component={InputDatePicker}
              idComponent="inputDeliveryDate"
              label="Tanggal Kirim"
              typeDate="datePicker"
              disabledDate={minDate}
              placeholder={'Tanggal Kirim'}
            />
            <Field
              name="courier"
              component={InputSelect}
              loading={isLoadingCourierList}
              idComponent="inputCourier"
              customOption={'labelAndCode'}
              dafaultItemName="Pilih Kurir"
              data={courierList}
              disabled={isBranch}
              label={'Kurir'}
            />
          </Col>
        </Row>
        <Row className="floatRight">
          <CButton buttonData={btnSubmit} />
        </Row>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <CSpin
        spinning={isLoading}
        size={'large'}
        content={
          <div className="defaultSpace">
            <CCard
              cardClassName={'defaultCard'}
              cardTitle={<span className="lightBlueColor">CALL CENTER</span>}
              cardExtra={''}
              cardContent={<React.Fragment>{contentCard()}</React.Fragment>}
            />
            {showModal()}
          </div>
        }
      />
    </React.Fragment>
  );
}
const CallCenterFormMenu = reduxForm({
  form: 'callCenterForm',
  shouldError: () => {
    return true;
  },
  // validate,
})(CallCenterFormComponent);
export default CallCenterFormMenu;
