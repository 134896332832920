import * as ActionAuth from '../../../core/action//Auth';
import * as ActionComplaint from '../Store/ComplaintAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionDataGeneral from '../../App/Store/GeneralDataAction';
import * as ActionLogin from '../../Login/Store';
import * as SelectorApp from '../../App/Selector/AppSelector';
import * as SelectorComplaint from '../Selector/ComplaintSelector';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { change, getFormValues } from 'redux-form';
import { ref, remove } from 'firebase/database';

import React from 'react';
import axios from 'axios';
import { getDb } from '../../Config/firebaseConfig';
import moment from 'moment';

function* S_getComplaintListProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const pagePagination = yield select(SelectorApp.makePagePagination());
  const pagePaginationValue = pagePagination - 1;
  const sizeDataPagination = yield select(SelectorApp.makeSizeDataPagination());
  try {
    yield put(ActionComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Admin/GetDataTiketNoFilter/${pagePaginationValue}/${sizeDataPagination}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComponent.setTotalPagePagination(data.totalPages * 10));
    yield put(ActionComplaint.fetchComplaintNewList(data.data));
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}
function* S_getWaybillComplaintProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const formValue = yield select(getFormValues('complaintForm'));

  try {
    yield put(ActionComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Admin/SearchWaybill/${formValue.waybill}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComplaint.fetchComplaintDetailDoc(data));
    yield put(ActionDataGeneral.getBranchList());
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    // yield put(ActionComplaint.clearDetailDocComplaint());
    // yield put(ActionComplaint.clearComplaintNewForm());
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

function* S_submitDocComplaintProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const detailValue = yield select(
    SelectorComplaint.makeComplaintDetailDocSelector()
  );
  const formValue = yield select(getFormValues('complaintForm'));
  const authUsername = yield select(SelectorApp.makeIsAuthUsername());
  const DateNow = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSz');

  try {
    yield put(ActionComponent.processLoading(true));
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Admin/AddInvestigasi`,
      {
        waybill: detailValue.waybillCode,
        itemComplainProcess: DateNow,
        noTelp: formValue.phoneNumber,
        question: formValue.complaintSubject,
        questionDetail: formValue.detailComplaint,
        userCreate: authUsername,
        complaintType: 'Proses',
        courierId: formValue.courierInvestigate,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComplaint.submitSearchFilterComplaint());
    yield put(ActionComplaint.clearComplaintNewForm());
    yield put(ActionComplaint.clearDetailDocComplaint());
    Modal.success({
      title: (
        <p className={'titleModalNotifSuccess'}>
          Berhasil Membuat Keluhan Dengan Nomor Tiket #{data}
        </p>
      ),

      onOk() {},
    });
    yield put(ActionComponent.openModal('Complaint'));
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

function* S_submitSearchComplaintProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const pagePagination = yield select(SelectorApp.makePagePagination());
  const pagePaginationValue = pagePagination - 1;
  const sizeDataPagination = yield select(SelectorApp.makeSizeDataPagination());
  const formValue = yield select(getFormValues('complaint'));
  const complaintStatusValue = formValue ? formValue.complaintStatus : '';
  const filterValue = formValue ? formValue.filterValue : '';
  const searchValue = formValue ? formValue.searchValueFilter : '';
  const paramcomplaintStatusValue = `${Object.values(complaintStatusValue).join(
    '%2C'
  )}`;

  const linkApi = paramcomplaintStatusValue
    ? `/Admin/SelectDataForAddComplaintDeliveryAdmin/${pagePaginationValue}/${sizeDataPagination}/${paramcomplaintStatusValue}?FilterSelect=${filterValue}&Value=${searchValue}`
    : `/Admin/SelectDataForAddComplaintDeliveryAdmin/${pagePaginationValue}/${sizeDataPagination}/Proses%2CDalamAntrian?FilterSelect=Empty`;
  try {
    yield put(ActionComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}${linkApi}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComponent.setTotalPagePagination(data.totalPages * 10));
    yield put(ActionComplaint.fetchComplaintNewList(data.data));

    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionComplaint.clearListDocComplaint());
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

function* S_getDetailDataComplaintProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const detailValue = yield select(
    SelectorComplaint.makeComplaintDetailDocSelector()
  );
  try {
    yield put(ActionComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Admin/GetDataDetailComplaint/${detailValue.id}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComplaint.fetchComplaintDetailDoc(data));
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

function* S_submitInQueueToProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const username = state.Auth.UsernameAuth;
  const detailValue = yield select(
    SelectorComplaint.makeComplaintDetailDocSelector()
  );
  const formValue = yield select(getFormValues('detailFormComplaint'));
  const updateNotif = () => {
    const removeTarget = ref(
      getDb,
      `${process.env.REACT_APP_FIREBASE_PARENT}/${detailValue.waybillCode}`
    );
    return remove(removeTarget);
  };
  try {
    yield put(ActionComponent.processLoading(true));
    yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/Admin/ConfirmComplaintTicketRequestedByCustomer/${detailValue.id}/${username}/${formValue.courierInvestigate}`,
      {},
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(change('complaintForm', 'showType', null));
    yield put(change('complaintForm', 'modalType', null));
    yield put(ActionComplaint.clearDetailDocComplaint());
    updateNotif();
    Modal.success({
      title: (
        <p className={'titleModalNotifSuccess'}>
          Berhasil mengubah status menjadi proses
        </p>
      ),
      onOk() {},
    });
    yield put(ActionComplaint.submitSearchFilterComplaint());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}
function* S_submitProcessToCustomerConfirmProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const detailValue = yield select(
    SelectorComplaint.makeComplaintDetailDocSelector()
  );
  const investigationFile = yield select(
    SelectorComplaint.makeComplaintInvestigationFileSelector()
  );
  const authUsername = yield select(SelectorApp.makeIsAuthUsername());
  const formValue = yield select(getFormValues('detailFormComplaint'));

  try {
    yield put(ActionComponent.processLoading(true));
    yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/Admin/ConfirmFeedbackForTicketComplaintRequestedByCustomer/${detailValue.id}`,
      {
        mode: true,
        description: formValue.resultInvestigation,
        image: investigationFile,
        userActor: authUsername,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(change('complaintForm', 'showType', null));
    yield put(change('complaintForm', 'modalType', null));
    yield put(ActionComplaint.clearDetailDocComplaint());
    yield put(ActionComplaint.clearComplaintNewForm());
    yield put(ActionComplaint.clearInvestigationFile());
    Modal.success({
      title: (
        <p className={'titleModalNotifSuccess'}>
          Berhasil mengubah status menjadi konfirmasi pelanggan
        </p>
      ),
      onOk() {},
    });
    yield put(ActionComplaint.submitSearchFilterComplaint());
  } catch (error) {
    if (
      error.response.data.Message ===
      'Status keluhan gagal di update, karena status saat ini KonfirmasiPelanggan'
    ) {
      yield put(change('complaintForm', 'showType', null));
      yield put(change('complaintForm', 'modalType', null));
      yield put(ActionComplaint.clearDetailDocComplaint());
      yield put(ActionComplaint.clearComplaintNewForm());
      yield put(ActionComplaint.clearInvestigationFile());
      yield put(ActionComplaint.submitSearchFilterComplaint());
      yield put(ActionLogin.errorHandling(action, error));
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
    yield put(ActionComponent.processLoading(false));
  }
}

export function* S_downloadComplaintProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const key = 'download';
  const detailValue = yield select(
    SelectorComplaint.makeComplaintDetailDocSelector()
  );

  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = `INVESTIGASI(${detailValue.tiket})-${detailValue.waybillCode}.pdf`;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }
  try {
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Complaint/DownloadInvestigasiPdf`,
      {
        id: detailValue.id,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const percentProgress = Math.floor((loaded * 100) / total);

          if (percentProgress < 100) {
            message.loading({
              content: `Loading ${percentProgress}%`,
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
  } catch (error) {
    if (error.response.data.Message === 'Network Error') {
      message.error({
        content: 'Download Gagal',
        key,
        duration: 3,
      });
    } else if (error.response.data.Message === 'No delivery found!') {
      message.error({
        content: 'Tiket tidak tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

function* S_getCourierComplaintProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const formValue = yield select(getFormValues('complaintForm'));
  const formValueDetail = yield select(getFormValues('detailFormComplaint'));
  const detailValue = yield select(
    SelectorComplaint.makeComplaintDetailDocSelector()
  );
  let getBranchValue = '';
  const valueBranch = () => {
    if (
      formValue.formType === 'changeCourier' ||
      formValue.formType === 'inQueueAssign'
    ) {
      getBranchValue = formValueDetail.branchInvestigate;
    } else if (formValue.formType === 'assignCourier') {
      getBranchValue = formValue.branchInvestigate;
    } else {
      getBranchValue = detailValue.branch;
    }
  };
  //
  try {
    valueBranch();
    yield put(ActionDataGeneral.loadingGetData('Courier'));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Courier/branch/${getBranchValue}?isAll=false`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComplaint.fetchCourierComplaint(data.data));
    yield put(ActionDataGeneral.loadingGetData('Courier'));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionDataGeneral.loadingGetData('Courier'));
  }
}

function* S_submitChangeCourierInvestigateProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const username = state.Auth.UsernameAuth;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const detailValue = yield select(
    SelectorComplaint.makeComplaintDetailDocSelector()
  );

  const formValue = yield select(getFormValues('detailFormComplaint'));
  try {
    yield put(ActionComponent.processLoading(true));
    yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/Complaint/UpdateInvestigasi/${formValue.courierInvestigate}/${detailValue.id}/${username}`,
      {},
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(change('complaintForm', 'showType', null));
    yield put(change('complaintForm', 'formType', null));
    yield put(change('complaintForm', 'modalType', null));
    yield put(ActionComponent.openModal('AssignCourierComplaint'));
    yield put(ActionComplaint.clearDetailDocComplaint());
    Modal.success({
      title: (
        <p className={'titleModalNotifSuccess'}>Berhasil mengubah kurir</p>
      ),
      onOk() {},
    });
    yield put(ActionComplaint.getDetailDataComplaint());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

export function* SM_getWaybillComplaintAction() {
  yield takeLatest('GET_WAYBILL_COMPLAINT', S_getWaybillComplaintProcess);
}
export function* SM_getComplaintListAction() {
  yield takeLatest('GET_COMPLAINT_LIST_REQUESTED', S_getComplaintListProcess);
}
export function* SM_submitDocComplaintAction() {
  yield takeLatest('SUBMIT_DOCUMENT_COMPLAINT', S_submitDocComplaintProcess);
}
export function* SM_submitSearchComplaintAction() {
  yield takeLatest(
    'SUBMIT_SEARCH_FILTER_COMPLAINT',
    S_submitSearchComplaintProcess
  );
}
export function* SM_submitInQueueToProcessAction() {
  yield takeLatest('SUBMIT_IN_QUEUE_TO_PROCESS', S_submitInQueueToProcess);
}
export function* SM_submitProcessToCustomerConfirmAction() {
  yield takeLatest(
    'SUBMIT_PROCESS_TO_CUSTOMER_CONFIRM',
    S_submitProcessToCustomerConfirmProcess
  );
}
export function* SM_getDetailDataComplaintAction() {
  yield takeLatest(
    'GET_DETAIL_DATA_COMPLAINT',
    S_getDetailDataComplaintProcess
  );
}
export function* SM_downloadComplaintAction() {
  yield takeLatest('DOWNLOAD_COMPLAINT', S_downloadComplaintProcess);
}
export function* SM_getCourierComplaintAction() {
  yield takeLatest('GET_COURIER_COMPLAINT', S_getCourierComplaintProcess);
}
export function* SM_submitChangeCourierInvestigateAction() {
  yield takeLatest(
    'SUBMIT_CHANGE_COURIER_INVESTIGATE',
    S_submitChangeCourierInvestigateProcess
  );
}
