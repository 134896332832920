import { Button } from 'antd';
import React, { Component } from 'react';

interface IProps {
  handleCheckServerVoip: any;
}

export default class ButtonEditServerVoipComponent extends Component<IProps> {
  render() {
    return (
      <div>
        <Button
          type="link"
          onClick={this.props.handleCheckServerVoip}
          style={{ color: '#5358FF' }}
        >
          <span style={{ textDecorationLine: 'underline' }}>Test IP</span>
        </Button>
      </div>
    );
  }
}
