import * as ActionLogin from '../../../module/Login/Store';
import * as ActionManualHoliday from '../../action/Holiday/ActionFormManualHoliday';
import * as ActionTableHoliday from '../../action/Holiday/ActionTableHoliday';
import * as toggle from '../../action/toggle';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_addHolidaySynchroniz() {
  const state = yield select();
  const year = state.Holiday.FormSynchronizedWeekly.YearSynchronized;
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(toggle.changeLoadingTrue());
    yield call(
      axios.put,
      process.env.REACT_APP_URL + '/Holiday/syncronize/' + year,
      {},
      {
        headers: config,
      }
    );
    yield put(ActionTableHoliday.GetTableHoliday());
    yield put(ActionManualHoliday.clearAllFormManualHoliday());
    yield put(toggle.changeModal());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: <p style={{ color: '#31E116' }}>Berhasil sinkron hari libur</p>,

      onOk() {},
    });
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      if (error.response.status === 401) {
        yield put(ActionLogin.submitTokenRefrash());
      } else {
        const propsError = ErrorMassage('tambah sinkron hari libur');
        const code = propsError.filter((person: any) =>
          person.Code.toString().toLowerCase().includes(error.response.status)
        );
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              {code.map((data: any) => data.TitleMassage)}
            </p>
          ),
          content: (
            <div>
              <p style={{ color: '#C0C0C0' }}>
                {code.map((data: any) => data.Massage)}
              </p>
            </div>
          ),
          onOk() {},
        });
      }
    }
  }
}

export function* SM_addHolidaySynchroniz() {
  yield takeLatest('ADD_HOLIDAY_SYNCHRONIZ', S_addHolidaySynchroniz);
}
