import * as ActionDeliveryPriority from '../../../core/action/DeliveryPriority/ActionDeliveryPriority';
import * as ActionGetDeliveryStatus from 'module/DeliveryStatus/Store/ActionGetDeliveryStatus';
import * as ActionSetDeliveryStatus from 'module/DeliveryStatus/Store/ActionSetDeliveryStatus';
import * as ActionToggle from '../../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import DeliveryPriorityAddFormComponent from '../../../components/Content/DeliveryPriority/DeliveryPriorityAddFormComponent';
import { connect } from 'react-redux';

interface IProps {
  actionToggle: any;
  actionDeliveryPriority: any;
  actionGetDeliveryStatus: any;
  actionSetDeliveryStatus: any;
  getWaybillForm: any;
  getWaybillCode: any;
  getBarcode: any;
  getCustomerName: any;
  getCourierName: any;
  getCourierCode: any;
  getAddress: any;
  getTicketStatus: any;
  getCreatedTime: any;
  getDocumentStatus: any;
  PagePagination: any;
  TotalPagePagination: any;
  typeToggle: any;
  visible: any;
  isLoading: any;
}

interface IState {
  ValidateWaybillForm: any;
}

class DeliveryPriorityaddFormContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateWaybillForm: null,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handlePutWaybill = this.handlePutWaybill.bind(this);
    this.handleGetDetailDocument = this.handleGetDetailDocument.bind(this);
    this.handleSubmitPriority = this.handleSubmitPriority.bind(this);
    this.handleViewStatusDelivery = this.handleViewStatusDelivery.bind(this);
  }
  handleCloseModal() {
    this.setState({
      ValidateWaybillForm: null,
    });
    this.props.actionDeliveryPriority.clearDataForm();
    this.props.actionToggle.changeModal();
    this.props.actionToggle.clearJenisToggle();
  }
  handleGetDetailDocument() {
    this.props.actionSetDeliveryStatus.setSearchTypeSubmit('waybill');
    this.props.actionGetDeliveryStatus.getDeliveryStatus();
  }
  handleSubmitPriority() {
    this.props.actionDeliveryPriority.addTicketDeliveryPriority();
  }
  handleViewStatusDelivery(event: any) {
    const url = '/delivery/status?' + event;
    window.open(url, '_blank');
  }
  handlePutWaybill(event: any) {
    this.props.actionDeliveryPriority.setWaybillForm(event.target.value);
    if (event.target.value.length < 5) {
      this.setState({
        ValidateWaybillForm: 'notMinChar',
      });
    } else {
      this.setState({
        ValidateWaybillForm: '',
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <DeliveryPriorityAddFormComponent
          handleCloseModal={this.handleCloseModal}
          handlePutWaybill={this.handlePutWaybill}
          getWaybillForm={this.props.getWaybillForm}
          validateWaybillForm={
            this.state.ValidateWaybillForm === 'notMinChar' ? 'error' : ''
          }
          helpWaybillForm={
            this.state.ValidateWaybillForm === 'notMinChar'
              ? 'Waybill tidak boleh kurang dari 5'
              : ''
          }
          handleGetDetailDocument={this.handleGetDetailDocument}
          validateButton={
            this.state.ValidateWaybillForm === 'notMinChar' ||
            this.props.getWaybillForm === '' ||
            this.props.getWaybillForm === null
              ? true
              : false
          }
          validateButtonPriority={
            this.props.typeToggle === 'detailTicket' ? true : false
          }
          validateButtonSubmitPriority={
            this.props.getDocumentStatus === 'Return' ||
            this.props.getDocumentStatus === 'OnHold' ||
            this.props.getDocumentStatus === 'Success' ||
            this.props.getDocumentStatus === 'Return3x'
              ? true
              : false
          }
          getWaybillCode={this.props.getWaybillCode}
          getBarcode={this.props.getBarcode}
          getCustomerName={this.props.getCustomerName}
          getCourierName={this.props.getCourierName}
          getCourierCode={this.props.getCourierCode}
          getAddress={this.props.getAddress}
          getTicketStatus={this.props.getTicketStatus}
          getCreatedTime={this.props.getCreatedTime}
          handleViewStatusDelivery={this.handleViewStatusDelivery}
          handleSubmitPriority={this.handleSubmitPriority}
          typeToggle={this.props.typeToggle}
          visible={this.props.visible}
          isLoading={this.props.isLoading}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  getWaybillForm: state.DeliveryPriority.DeliveryPriorityForm.waybillForm,
  getWaybillCode: state.DeliveryPriority.DeliveryPriorityForm.waybillCode,
  getBarcode: state.DeliveryPriority.DeliveryPriorityForm.barcode,
  getCustomerName: state.DeliveryPriority.DeliveryPriorityForm.customerName,
  getCourierName: state.DeliveryPriority.DeliveryPriorityForm.courierName,
  getCourierCode: state.DeliveryPriority.DeliveryPriorityForm.courierCode,
  getAddress: state.DeliveryPriority.DeliveryPriorityForm.address,
  getTicketStatus: state.DeliveryPriority.DeliveryPriorityForm.ticketStatus,
  getCreatedTime: state.DeliveryPriority.DeliveryPriorityForm.createdTime,
  getDocumentStatus: state.DeliveryPriority.DeliveryPriorityForm.documentStatus,
  PagePagination: state.Pagination.PagePagination,
  TotalPagePagination: state.Pagination.TotalPagePagination,
  typeToggle: state.Toggle.jenisToggle,
  visible: state.Toggle.modal,
  isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionDeliveryPriority: bindActionCreators(ActionDeliveryPriority, dispatch),
  actionGetDeliveryStatus: bindActionCreators(
    ActionGetDeliveryStatus,
    dispatch
  ),
  actionSetDeliveryStatus: bindActionCreators(
    ActionSetDeliveryStatus,
    dispatch
  ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DeliveryPriorityaddFormContainer);
