import * as ActionFormPrintWaybill from '../../../../core/action/Delivery/PrintWaybill/ActionFormPrintWaybill';
import * as toggle from '../../../../core/action/toggle';

import React, { useState, useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import FormPrintByWaybillComponent from '../../../../components/Content/Delivery/PrintWaybill/FormPrintByWaybillComponent';
import { connect } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import {
  downloadWaybillPdfDelivery,
  downloadWaybillPdfDeliveryMultiple,
} from 'services/DeliveryService';
import { processProgressRequest, downloadBlob } from 'lib/util';
import { Modal } from 'antd';
import ModalDownloadProgress from 'materials/ModalDownloadProgress';
import ModalStatus from 'materials/ModalStatus';
import moment from 'moment-timezone';

interface IProps {
  actionToggle: any;
  actionFormPrintWaybill: any;
  visible: any;
  jenisToggle: any;
  getSwitchValue: any;
  getDeliveryDate: any;
  getWaybill: any;
  getFileData: any;
  getSeparatorAddress: any;
  getWatermark: any;
  getNotelp: any;
  getCodeBrankas: any;
  isLoading: any;
  waybillPrintType: any;
}

const AddAdminContainer = (props: IProps) => {
  const [ValidateWaybill, setValidateWaybill] = useState<any>(null);
  const [file, setfile] = useState<any>(null);
  const [fileName, setfileName] = useState<any>(null);

  const [loadingProgress, setLoadingProgress] = useState(0);
  const progressHandler = processProgressRequest(setLoadingProgress);

  const { mutate: mutateDownloadSingle, isLoading: isLoadingDownloadSingle } =
    useMutation(
      (data: any) => downloadWaybillPdfDelivery(data, progressHandler),
      {
        onSuccess: res => {
          if (res.status === 200) {
            downloadBlob(res.data, `Waybill - ${props.getWaybill}`, 'pdf');
            ModalStatus({
              status: 'success',
              title: 'Download Berhasil',
            });
          } else {
            setLoadingProgress(0);
            ModalStatus({
              status: 'error',
              title: 'Terjadi Kesalahan',
              content:
                res.response?.data?.Message ||
                'Terjadi kesalahan pada server, mohon hubungi admin untuk tindakan lebih lanjut',
            });
          }
        },
        onError: () => {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
          });
        },
      }
    );

  const {
    mutate: mutateDownloadMultiple,
    isLoading: isLoadingDownloadMultiple,
  } = useMutation(
    (data: any) => downloadWaybillPdfDeliveryMultiple(data, progressHandler),
    {
      onSuccess: res => {
        if (res.status === 200) {
          downloadBlob(
            res.data,
            `Waybill Bulk - ${moment().format('YYYY-MM-DD HH:mm:ss')}`,
            'pdf'
          );
          ModalStatus({
            status: 'success',
            title: 'Download Berhasil',
          });
        } else {
          setLoadingProgress(0);
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
            content:
              res.response?.data?.Message ||
              'Terjadi kesalahan pada server, mohon hubungi admin untuk tindakan lebih lanjut',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    }
  );

  useEffect(() => {
    if (loadingProgress === 100) {
      Modal.destroyAll();
      setLoadingProgress(0);
    }
  }, [loadingProgress]);

  const handlePutPrintType = (event: any) => {
    props.actionFormPrintWaybill.changeWaybillPrintType(event.target.value);
  };
  const handleClearModalForm = () => {
    props.actionToggle.changeModal();
    props.actionToggle.clearJenisToggle();
    props.actionFormPrintWaybill.ClearPrintWaybillFormPW();
    setValidateWaybill('');
    setfileName('');
  };

  const handleDownloadTemplate = () => {
    props.actionFormPrintWaybill.downloadTemplate();
  };
  const handleDownloadByWaybill = () => {
    mutateDownloadSingle({
      Waybill: props.getWaybill,
      WaybillPrintType: props.waybillPrintType,
      CodeBrankas: props.getCodeBrankas,
      SeparatorAddress: props.getSeparatorAddress,
      NoTelp: props.getNotelp,
    });
    setfileName('');
  };
  const handleDownloadMultipleWaybill = () => {
    mutateDownloadMultiple({
      fileString: props.getFileData,
      separator: props.getSeparatorAddress,
      NoTelp: props.getNotelp,
      CodeBrankas: props.getCodeBrankas,
      watermark: true,
    });
    setfileName('');
  };

  const handleSwitch = (event: any) => {
    props.actionFormPrintWaybill.changeSwitchValuePW(event);
    if (event === true) {
      props.actionFormPrintWaybill.ClearWaybill();
      setValidateWaybill('');
    } else {
      props.actionFormPrintWaybill.ClearUploadForm();
    }
  };

  const getBase64 = (file: any) => {
    return new Promise(resolve => {
      let baseURL;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleConvert = (e: any) => {
    setfileName(e.target.value);
    let fileState = file;
    fileState = e.target.files[0];

    getBase64(fileState)
      .then((result: any) => {
        fileState['base64'] = result.toString().split(',')[1];
        props.actionFormPrintWaybill.changeFileDataPW(result.split(',')[1]);
        setfile(fileState);
      })
      .catch(err => {
        console.log(err);
      });

    setfile(e.target.files[0]);
  };
  const handlePutWaybill = (event: any) => {
    props.actionFormPrintWaybill.changeWaybillPW(
      event.target.value.toUpperCase()
    );

    if (event.target.value === '') {
      setValidateWaybill('error');
    } else if (event.target.value.length < 7) {
      setValidateWaybill('notMinChar');
    } else {
      setValidateWaybill('');
    }
  };

  const handlePutSeparatorAddress = (event: any) => {
    props.actionFormPrintWaybill.changeSeparatorAddress(event.target.checked);
  };
  const handlePutWatermark = (event: any) => {
    props.actionFormPrintWaybill.changeWatermark(event.target.checked);
  };

  const handlePutNotelp = (event: any) => {
    props.actionFormPrintWaybill.changeNotelp(event.target.checked);
  };

  const handlePutCodeBrankas = (event: any) => {
    props.actionFormPrintWaybill.changeCodeBrankas(event.target.checked);
  };

  return (
    <div>
      <FormPrintByWaybillComponent
        visible={props.jenisToggle === 'PrintByWaybill' ? true : false}
        jenisToggle={props.jenisToggle}
        handleClearModalForm={handleClearModalForm}
        handlePutWaybill={handlePutWaybill}
        handleSwitch={handleSwitch}
        handleConvert={handleConvert}
        handleDownloadMultipleWaybill={handleDownloadMultipleWaybill}
        getSwitchValue={props.getSwitchValue}
        getDeliveryDate={props.getDeliveryDate}
        getWaybill={props.getWaybill}
        getFileData={props.getFileData}
        fileName={fileName}
        ValidateWaybill={
          ValidateWaybill === 'error' || ValidateWaybill === 'notMinChar'
            ? 'error'
            : ''
        }
        helpValidateWaybill={
          ValidateWaybill === 'error'
            ? 'Waybill wajib diisi'
            : ValidateWaybill === 'notMinChar'
            ? 'Waybill harus lebih dari 6 karakter'
            : ''
        }
        validateButtonDownload={
          props.getSwitchValue === false &&
          (props.getWaybill === null ||
            props.getWaybill === '' ||
            ValidateWaybill === 'error' ||
            ValidateWaybill === 'notMinChar')
            ? true
            : props.getSwitchValue === true && props.getFileData === null
            ? true
            : false
        }
        handleDownloadTemplate={handleDownloadTemplate}
        handleDownloadByWaybill={handleDownloadByWaybill}
        isLoading={props.isLoading}
        handlePutPrintType={handlePutPrintType}
        waybillPrintType={props.waybillPrintType}
        handlePutSeparatorAddress={handlePutSeparatorAddress}
        handlePutWatermark={handlePutWatermark}
        getWatermark={props.getWatermark}
        getSeparatorAddress={props.getSeparatorAddress}
        handlePutNotelp={handlePutNotelp}
        handlePutCodeBrankas={handlePutCodeBrankas}
        getNotelp={props.getNotelp}
        getCodeBrankas={props.getCodeBrankas}
      />
      <ModalDownloadProgress
        loading={isLoadingDownloadSingle || isLoadingDownloadMultiple}
        loadingProgress={loadingProgress}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  jenisToggle: state.Toggle.jenisToggle,
  getSwitchValue: state.PrintWaybill.FormPrintWaybill.SwitchValue,
  getDeliveryDate: state.PrintWaybill.FormPrintWaybill.DeliveryDate,
  getWaybill: state.PrintWaybill.FormPrintWaybill.Waybill,
  getFileData: state.PrintWaybill.FormPrintWaybill.FileData,
  getSeparatorAddress: state.PrintWaybill.FormPrintWaybill.SeparatorAddress,
  getWatermark: state.PrintWaybill.FormPrintWaybill.Watermark,
  getNotelp: state.PrintWaybill.FormPrintWaybill.NoTelp,
  getCodeBrankas: state.PrintWaybill.FormPrintWaybill.CodeBrankas,
  waybillPrintType: state.PrintWaybill.FormPrintWaybill.WaybillPrintType,
  isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionFormPrintWaybill: bindActionCreators(ActionFormPrintWaybill, dispatch),
  actionToggle: bindActionCreators(toggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AddAdminContainer);
