import * as ActionFieldBranch from 'core/action/Branch/ActionFieldBranch';
import * as ActionToggle from 'core/action/toggle/index';

import { bindActionCreators, compose } from 'redux';

import ButtonCustomerProductComponent from 'module/Branch/Component/ButtonGroupingBranchComponent';
import React from 'react';
import { connect } from 'react-redux';

interface IProps {
  idGroupProduct: any;
  groupingName: any;
  typeToggle: any;
  actionFieldBranch: any;
  actionToggle: any;
}
function ButtonGroupingBranchContainer({
  idGroupProduct,
  groupingName,
  typeToggle,
  actionFieldBranch,
  actionToggle,
}: IProps) {
  function handleGetGroupingDetail() {
    actionFieldBranch.changeGroupId(idGroupProduct);
    actionFieldBranch.changeGroupName(groupingName);
  }
  return (
    <React.Fragment>
      <ButtonCustomerProductComponent
        typeToggle={typeToggle}
        handleGetGroupingDetail={handleGetGroupingDetail}
      />
    </React.Fragment>
  );
}
const mapStateProps = (state: any) => ({
  typeToggle: state.Toggle.jenisToggle2,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionFieldBranch: bindActionCreators(ActionFieldBranch, dispatch),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(ButtonGroupingBranchContainer);
