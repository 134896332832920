import * as ActionAuth from '../../../../action/Auth';
import * as ActionLogin from '../../../../../module/Login/Store';
import * as toggle from '../../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';
import history from '../../../../../core/utils/History';
import moment from 'moment';

export function* S_UploadSoftcopy(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const FileCsv = state.Delivery.UnggahDocument.csv;
  const UploadDate = moment(state.Delivery.UnggahDocument.UploadDate).format(
    'YYYY-MM-DDTHH:mm:ss.SSSz'
  );
  const DeliveryDate = moment(
    state.Delivery.UnggahDocument.DeliveryDate
  ).format('YYYY-MM-DDTHH:mm:ss.SSSz');
  const CustomerProductId = state.Delivery.UnggahDocument.CustomerProductId;
  const FormatCsv = state.Delivery.UnggahDocument.CustomCsvCustomer;
  const FileName = state.Delivery.UnggahDocument.FileName;
  // let DateNow = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSz');
  
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;

  try {
    yield put(toggle.changeLoadingTrue());
    yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Delivery/upload/customer`,
      {
        fileCsv: FileCsv,
        deliveryTime: DeliveryDate === 'Invalid date' ? null : DeliveryDate,
        customerProductId: CustomerProductId,
        customerCsvId: FormatCsv,
        fileName: FileName,
        processTime: UploadDate === 'Invalid date' ? null : UploadDate,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.changeLoadingFalse());
    yield put(toggle.clearJenisToggle());

    // yield put(LastAccess.changeLastAccessFiture('deliveryDistribution'));
    Modal.success({
      title: <p style={{ color: '#31E116' }}>Berhasil Mengunggah</p>,
      content: (
        <div>
          <p style={{ color: '#C0C0C0' }}>Klik OK untuk langkah selanjutnya</p>
        </div>
      ),
      onOk: () => {
        history.push('/delivery/document?deliveryDistribution');
      },
    });
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_UploadSoftcopy() {
  yield takeLatest('SUBMIT_UPLOAD_SOFTCOPY', S_UploadSoftcopy);
}
