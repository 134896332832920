import * as ActionAuth from '../../action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionRestoreData from '../../action/RestoreData/ActionRestoreData';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_restoreRestoreData(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const LastAccess = state.LastAccess.Fiture;
  const ListFile = state.RestoreData.ListRestoreData.filter(
    item => item.selected === true
  );
  const DataFile = ListFile.map((data: any) => data.id);
  const ListWaybill = state.RestoreData.ListWaybillRestoreData.filter(
    item => item.selected === true
  );
  const DataWaybill = ListWaybill.map((data: any) => data.waybill);
  const body =
    LastAccess === null || LastAccess === 'restoreDataCsv'
      ? {
          idDeliveryFile: DataFile,
        }
      : {
          waybillCode: DataWaybill,
        };
  const apiLink = `${process.env.REACT_APP_URL}/Delivery/RestoreData/RestoreMultipleFile/`;
  const apiLink2 = `${process.env.REACT_APP_URL}/Delivery/RestoreData/RestoreMultipleWaybill/`;
  try {
    yield put(toggle.changeLoadingTrue());
    const { data } =
      LastAccess === null || LastAccess === 'restoreDataCsv'
        ? yield call(axios.delete, apiLink, { data: body, headers: config })
        : yield call(axios.delete, apiLink2, { data: body, headers: config });
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(ActionRestoreData.clearListRestoreData());
    yield put(ActionRestoreData.clearListWaybillRestoreData());
    yield put(ActionRestoreData.setListResultDeleteData(data));
    yield put(ActionRestoreData.submitGetDataRestoreData());
    if (LastAccess !== null || LastAccess !== 'restoreDataCsv') {
      Modal.success({
        title: (
          <p style={{ color: '#31E116' }}>
            <b>Berhasil restore data</b>
          </p>
        ),
        onOk() {},
      });
    } else {
      yield put(toggle.changeModal2());
      yield put(toggle.changeJenisToggle2('resultDelete'));
    }
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}

export function* SM_restoreRestoreData() {
  yield takeLatest('SUBMIT_RESTORE_RESTORE_DATA', S_restoreRestoreData);
}
