export function getFocusDeliveryStatusWaybill() {
  return {
    type: 'GET_FOCUS_DELIVERY_STATUS_WAYBILL',
  };
}
export function setIdFocusDelivery(IdFocusDelivery: any) {
  return {
    type: 'SET_ID_FOCUS_DELIVERY',
    IdFocusDelivery,
  };
}
export function setWaybillFocusDelivery(WaybillFocusDelivery: any) {
  return {
    type: 'SET_WAYBILL_FOCUS_DELIVERY',
    WaybillFocusDelivery,
  };
}
export function clearFocusDelivery() {
  return {
    type: 'CLEAR_FOCUS_DELIVERY',
  };
}
