export function changeRadioButtonDate(RadioButtonDate: any) {
  return {
    type: 'CHANGE_RADIO_BUTTON_DATE_DW',
    RadioButtonDate,
  };
}
export function changeFirstDate(FirstDate: any) {
  return {
    type: 'CHANGE_FIRST_DATE_DW',
    FirstDate,
  };
}
export function changeLastDate(LastDate: any) {
  return {
    type: 'CHANGE_LAST_DATE_DW',
    LastDate,
  };
}
export function changeCustomer(Customer: any) {
  return {
    type: 'CHANGE_CUSTOMER_DW',
    Customer,
  };
}
export function changeCustomerName(CustomerName: any) {
  return {
    type: 'CHANGE_CUSTOMER_NAME_DW',
    CustomerName,
  };
}
export function changeCustomerProduct(CustomerProduct: any) {
  return {
    type: 'CHANGE_CUSTOMER_PRODUCT_DW',
    CustomerProduct,
  };
}
export function changeDeliveryType(DeliveryType: any) {
  return {
    type: 'CHANGE_DELIVERY_TYPE_DW',
    DeliveryType,
  };
}
export function changeDeliveryStatus(DeliveryStatus: any) {
  return {
    type: 'CHANGE_DELIVERY_STATUS_DW',
    DeliveryStatus,
  };
}
export function changeFileCsv(FileCsvDw: any) {
  return {
    type: 'CHANGE_FILE_CSV_DW',
    FileCsvDw,
  };
}
export function changeCbBarcode() {
  return {
    type: 'CHANGE_CB_BARCODE_DW',
  };
}
export function changeCbBarcodeReverse() {
  return {
    type: 'CHANGE_CB_BARCODE_REVERSE_DW',
  };
}
export function changeFileName(FileName: any) {
  return {
    type: 'CHANGE_FILE_NAME_DW',
    FileName,
  };
}
export function changeFlagFileName(FlagFileName: any) {
  return {
    type: 'CHANGE_FLAG_FILE_NAME_DW',
    FlagFileName,
  };
}
export function changeFlagFileNameUpload(FlagFileNameUpload: any) {
  return {
    type: 'CHANGE_FLAG_FILE_NAME_UPLOAD_DW',
    FlagFileNameUpload,
  };
}
export function changeCountSuccess(CountSuccess: any) {
  return {
    type: 'CHANGE_COUNT_SUCCESS_DW',
    CountSuccess,
  };
}
export function changeCountFailed(CountFailed: any) {
  return {
    type: 'CHANGE_COUNT_FAILED_DW',
    CountFailed,
  };
}
export function clearForm() {
  return {
    type: 'CLEAR_FORM_DW',
  };
}
export function clearFormByUpload() {
  return {
    type: 'CLEAR_FORM_BY_UPLOAD_DW',
  };
}
export function clearCustomerProduct() {
  return {
    type: 'CLEAR_CUSTOMER_PRODUCT_DW',
  };
}
export function downloadDigitalWaybill() {
  return {
    type: 'DOWNLOAD_DIGITAL_WAYBILL',
  };
}
export function downloadDigitalWaybillTemplate() {
  return {
    type: 'DOWNLOAD_DIGITAL_WAYBILL_TEMPLATE',
  };
}
export function downloadDigitalWaybillByUpload() {
  return {
    type: 'DOWNLOAD_DIGITAL_WAYBILL_BY_UPLOAD',
  };
}
export function getCountDigitalWaybillByUpload() {
  return {
    type: 'GET_COUNT_DIGITAL_WAYBILL_BY_UPLOAD',
  };
}
export function getErrorDigitalWaybillByUpload() {
  return {
    type: 'GET_ERROR_DIGITAL_WAYBILL_BY_UPLOAD',
  };
}
export function getDeleteDigitalWaybillByUpload() {
  return {
    type: 'GET_DELETE_DIGITAL_WAYBILL_BY_UPLOAD',
  };
}
