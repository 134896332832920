import * as ActionComponent from '../../../App/Store/ComponentAction';
import * as ActionGeneralData from '../../../App/Store/GeneralDataAction';
import * as ActionPickupData from '../Store/PickupDataAction';
import * as SelectorsComponent from '../../../App/Selector/AppSelector';
import * as SelectorsGeneralData from '../../../App/Selector/GeneralDataSelector';
import * as SelectorsPickupData from '../Selector/PickupDataSelector';

import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { change, getFormSyncErrors, getFormValues } from 'redux-form';
import { connect, useDispatch } from 'react-redux';

import PickupDataComponent from '../Component/PickupDataComponent';
import { createStructuredSelector } from 'reselect';
import { renderActionComponent } from '../../../../apps/assets/components/CButton';

function PickupDataContainer(props) {
  const {
    pagePagination,
    actionGeneralData,
    actionPickupData,
    isLoading,
    componentAction,
    formValue,
  } = props;
  const dispatch = useDispatch();
  const startDateValue = formValue ? formValue.startDate : '';
  const finishDateValue = formValue ? formValue.finishDate : '';
  useEffect(() => {
    if (finishDateValue < startDateValue) {
      dispatch(change('pickupDataForm', 'finishDate', null));
    }
    dispatch(
      change('pickupDataForm', 'pickupStatus', [
        'AssignPickup',
        'SuccessPickup',
      ])
    );
    dispatch(change('pickupDataForm', 'dateType', true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderAction = record => {
    const handleDetail = () => {
      const url = `/pickupStatus?${record.waybill}`;
      window.open(url, '_blank');
    };

    return renderActionComponent(
      isLoading,
      record.id,
      {
        renderInfo: true,
      },
      {
        handleDetail: handleDetail,
      },
      { validate: false }
    );
  };
  const renderColor = record => {
    if (record === 'Dalam Antrian') {
      return <span className="lightBlueColor">{record}</span>;
    } else if (record === 'Proses') {
      return <span className="greenText">{record}</span>;
    } else {
      return <span className="redColor">{record}</span>;
    }
  };
  const [CollapseState, setCollapseState] = useState(false);
  const looppage = (pagePagination - 1) * 10 + 1;
  const columns = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <b>No</b>
        </div>
      ),
      width: 50,
      render: (text: any, record: any, index: any) => (
        <span>{pagePagination > 1 ? index + looppage : index + 1}</span>
      ),
      key: 'no',
    },
    {
      title: 'Waybill',
      dataIndex: 'waybill',
      key: 'waybill',
      render: (waybill: any) => <span style={{ color: 'red' }}>{waybill}</span>,
      width: 120,
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      width: 120,
    },
    {
      title: 'Alamat',
      dataIndex: 'address',
      render: (alamat: any) => (
        <span style={{ color: 'red' }}>{`${Object.values(JSON.parse(alamat))
          .join(' ')
          .substring(0, 30)}`}</span>
      ),
      key: 'address',
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (record: any) => renderColor(record),
      width: 100,
    },
    {
      title: 'Aksi',
      render: (record: any) => renderAction(record),
      width: 80,
    },
  ];
  const handlePage = page => {
    componentAction.setPagePagination(page);
    actionPickupData.getPickupData();
  };
  const handleGetPickupData = () => {
    actionPickupData.getPickupData();
  };
  const handleSearchDataPickupData = () => {
    dispatch(change('pickupDataForm', 'searchData', true));
    componentAction.setPagePagination(0);
    componentAction.setTotalPagePagination(1);
    actionPickupData.getPickupData();
  };
  const handleDetailFilter = () => {
    setCollapseState(!CollapseState);
  };
  const handleGetCustomerList = () => {
    actionGeneralData.getCustomerList();
  };
  const handleClearCustomerProduct = () => {
    dispatch(change('pickupDataForm', 'customerProduct', null));
  };
  const handleGetBranchList = () => {
    actionGeneralData.getBranchList();
  };
  const handleClearCourier = () => {
    dispatch(change('pickupDataForm', 'courierPickup', null));
  };
  const handleGetCustomerProduct = () => {
    actionPickupData.getCustomerProduct();
  };
  const handleResetPickupDataForm = () => {
    actionPickupData.resetPickupDataForm();
    dispatch(change('pickupDataForm', 'dateType', true));
    dispatch(
      change('pickupDataForm', 'pickupStatus', [
        'AssignPickup',
        'SuccessPickup',
      ])
    );
  };
  const handleGetCourier = () => {
    actionPickupData.getCourier();
  };
  return (
    <React.Fragment>
      <PickupDataComponent
        columns={columns}
        CollapseState={CollapseState}
        handleGetPickupData={handleGetPickupData}
        handleDetailFilter={handleDetailFilter}
        handleGetCustomerList={handleGetCustomerList}
        handleClearCustomerProduct={handleClearCustomerProduct}
        handleGetBranchList={handleGetBranchList}
        handleClearCourier={handleClearCourier}
        handleGetCustomerProduct={handleGetCustomerProduct}
        handleGetCourier={handleGetCourier}
        handleResetPickupDataForm={handleResetPickupDataForm}
        handleSearchDataPickupData={handleSearchDataPickupData}
        handlePage={handlePage}
        {...props}
      />
    </React.Fragment>
  );
}

const mapStateToProps = () =>
  createStructuredSelector({
    isLoading: SelectorsComponent.makeIsLoading(),
    pagePagination: SelectorsComponent.makePagePagination(),
    totalPagination: SelectorsComponent.makeTotalPagePagination(),
    branchList: SelectorsGeneralData.makeBranchListSelector(),
    customerList: SelectorsGeneralData.makeCustomerListSelector(),
    courierList: SelectorsPickupData.makeCourierPickUpDataListSelector(),
    customerProductList:
      SelectorsPickupData.makeCustomerProductPickUpDataListSelector(),
    countingDataStatus: SelectorsPickupData.makeCountingDataStatusSelector(),
    pickupDataList: SelectorsPickupData.makePickupDataListSelector(),
    formValue: getFormValues('pickupDataForm'),
    errorFormValue: getFormSyncErrors('pickupDataForm'),
  });
const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
  actionGeneralData: bindActionCreators(ActionGeneralData, dispatch),
  actionPickupData: bindActionCreators(ActionPickupData, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PickupDataContainer);
