import * as ActionExportDeliveryStatus from '../Store/ActionExportDeliveryStatus';
import * as ActionListResendSubmit from '../../../core/action/Attempt/ActionListResendSubmit';
import * as ActionSetDeliveryTime from 'module/DeliveryStatus/Store/ActionSetDeliveryStatus';
import * as ActionToggle from '../../../core/action/toggle';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import DocumentInfoBarcodeComponent from 'module/DeliveryStatus/Component/DocumentInfoBarcodeComponent';
import { connect } from 'react-redux';

interface IProps {
  getListResend: any;
  getExportDeliveryStatus: any;
  getSearchTypeSubmit: any;
  actionListResendSubmit: any;
  actionSetDeliveryTime: any;
  actionExportDeliveryStatus: any;
  actionToggle: any;
  typeToggle: any;
  visible: any;
}
function DocumentInfoBarcodeContainer({
  getListResend,
  getExportDeliveryStatus,
  getSearchTypeSubmit,
  actionListResendSubmit,
  actionSetDeliveryTime,
  actionExportDeliveryStatus,
  actionToggle,
  typeToggle,
  visible,
}: IProps) {
  const [CollapsListResend, setCollapsListResend] = useState(false);
  const [CollapsKeyListResend, setCollapsKeyListResend] = useState(null);

  function handleCloseModal() {
    actionToggle.changeModal();
    actionToggle.clearJenisToggle();
  }
  function handleModalImage() {
    actionToggle.changeModal();
    actionToggle.changeJenisToggle('imageBarcode');
  }
  function handleModalSignature() {
    actionToggle.changeModal();
    actionToggle.changeJenisToggle('signatureBarcode');
  }
  function handleCollapsListResend(
    event: any,
    itemProcessTime: any,
    itemDeliveryTime: any,
    itemFinishedTime: any,
    waybillCode: any
  ) {
    actionSetDeliveryTime.setProcessTime(itemProcessTime);
    actionSetDeliveryTime.setDeliveryTime(itemDeliveryTime);
    actionSetDeliveryTime.setFinishedTime(itemFinishedTime);
    actionSetDeliveryTime.setWaybillForm(waybillCode);
    setCollapsListResend(!CollapsListResend);
    setCollapsKeyListResend(event);
  }
  function handleViewStatusDelivery(event: any) {
    const url = '/delivery/status?' + event;
    window.open(url, '_blank');
  }
  function handleRemoveAllListResend() {
    actionListResendSubmit.clearListResendSubmit();
  }
  function handleSlectionExportPdf(event: any) {
    actionSetDeliveryTime.setExportDeliveryStatus(event.key);
  }
  function handleExportPdf() {
    if (getExportDeliveryStatus === 'waybillPelanggan') {
      actionExportDeliveryStatus.getExportDeliveryStatus();
    } else if (getExportDeliveryStatus === 'waybillKurir') {
      actionExportDeliveryStatus.getExportDeliveryStatusKurir();
    }
  }

  return (
    <div>
      <DocumentInfoBarcodeComponent
        handleCloseModal={handleCloseModal}
        handleCollapsListResend={handleCollapsListResend}
        handleRemoveAllListResend={handleRemoveAllListResend}
        handleViewStatusDelivery={handleViewStatusDelivery}
        handleModalImage={handleModalImage}
        handleModalSignature={handleModalSignature}
        handleSlectionExportPdf={handleSlectionExportPdf}
        handleExportPdf={handleExportPdf}
        CollapsListResend={CollapsListResend}
        CollapsKeyListResend={CollapsKeyListResend}
        getListResend={getListResend}
        getExportDeliveryStatus={getExportDeliveryStatus}
        getSearchTypeSubmit={getSearchTypeSubmit}
        typeToggle={typeToggle}
        visibleSignature={
          visible === false && typeToggle !== 'signatureBarcode' ? false : true
        }
        visibleImage={
          visible === false && typeToggle !== 'imageBarcode' ? false : true
        }
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  getListResend: state.DeliveryStatus.DeliveryStatusBarcode,
  address: state.Resend.ListSubmitResendDelivery,
  typeToggle: state.Toggle.jenisToggle,
  visible: state.Toggle.visible,
  getExportDeliveryStatus: state.DeliveryStatus.Properties.ExportDeliveryStatus,
  getSearchTypeSubmit: state.DeliveryStatus.Properties.SearchTypeSubmit,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionListResendSubmit: bindActionCreators(ActionListResendSubmit, dispatch),
  actionSetDeliveryTime: bindActionCreators(ActionSetDeliveryTime, dispatch),
  actionExportDeliveryStatus: bindActionCreators(
    ActionExportDeliveryStatus,
    dispatch
  ),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DocumentInfoBarcodeContainer);
