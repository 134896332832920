import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionToggle from '../../../action/toggle';

import { Modal, message } from 'antd';
import { put, select, takeLatest } from 'redux-saga/effects';

import React from 'react';
import axios from 'axios';
import moment from 'moment';

export function* S_downloadDataActive(action: any) {
  const state = yield select();
  const key = 'download';
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const currentDate = moment().format('YYYYMMDD');

  try {
    Modal.info({
      title: <p style={{ color: '#11beff' }}>{<b>Download Data</b>}</p>,
      content: (
        <div>
          <p>Sedang mendownload data, mohon tunggu</p>
        </div>
      ),
    });
    axios({
      url: `${process.env.REACT_APP_URL}/VoipSetting/Download/ResultVoipCsv`,
      method: 'POST',
      responseType: 'blob',
      data: '',
      headers: config,
      onDownloadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        let percentProgress = Math.floor((loaded * 100) / total);

        if (percentProgress < 100) {
          message.loading({
            content: 'Loading ' + percentProgress + '%',
            key,
          });
        } else if (percentProgress === 100) {
          Modal.destroyAll();
          message.success({
            content: 'Download Berhasil',
            key,
            duration: 3,
          });
        } else if (percentProgress === Infinity) {
          Modal.destroyAll();
          message.error({
            content: 'Download Gagal',
            key,
            duration: 3,
          });
        }
      },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Data_Active_Voip_${currentDate}.zip`);
      document.body.appendChild(link);
      link.click();
    });
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionToggle.clearJenisToggle());
  } catch (error) {
    yield put(ActionToggle.changeLoadingFalse());
    if (error.response.data.Message === 'No delivery found!') {
      message.warning({
        content: 'Data Aktif Tidak Tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* SM_downloadDataActive() {
  yield takeLatest('SUBMIT_DOWNLOAD_DATA_ACTIVE', S_downloadDataActive);
}
