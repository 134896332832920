import { UseBaseQueryOptions } from '@tanstack/react-query';
import instance from 'config/interceptor';

export const getListSortedDocument = async (params: UseBaseQueryOptions) => {
  const [
    ,
    page,
    size,
    customerProducts,
    typePeriode,
    periodeDelivery,
    username,
  ]: any = params.queryKey || [];
  let customerProductIds = '';
  for (const productId of customerProducts) {
    customerProductIds += `&CustomerProductIds=${productId}`;
  }

  const { data } = await instance.get(
    `/Delivery/SortDocument/${page}/${size}?FilterDateType=${typePeriode}${customerProductIds}&Date=${periodeDelivery}&UserScan=${username}`
  );
  return data;
};
export const getCountingSortedDocument = async (
  params: UseBaseQueryOptions
) => {
  const [, customerProducts, typePeriode, periodeDelivery, username]: any =
    params.queryKey || [];
  let customerProductIds = '';
  for (const productId of customerProducts) {
    customerProductIds += `&CustomerProductIds=${productId}`;
  }

  const { data } = await instance.get(
    `/Delivery/SortDocument/Count?FilterDateType=${typePeriode}${customerProductIds}&Date=${periodeDelivery}&UserScan=${username}`
  );
  return data;
};

export const getListUnscanDocument = async (params: UseBaseQueryOptions) => {
  const [, page, size, customerProducts, typePeriode, periodeDelivery]: any =
    params.queryKey || [];
  let customerProductIds = '';
  for (const productId of customerProducts) {
    customerProductIds += `&CustomerProductIds=${productId}`;
  }

  const { data } = await instance.get(
    `/Delivery/SortDocument/Unscan/${page}/${size}?FilterDateType=${typePeriode}${customerProductIds}&Date=${periodeDelivery}`
  );
  return data;
};

export const mutateDeleteSortDocument = async (params: any) => {
  const { waybillNumber } = params;
  await instance.put(`/Delivery/SortDocument/UpdateScan/${waybillNumber}`);
};

export const mutateScanSortDocument = async (params: any) => {
  return await instance.post(`/Delivery/SortDocument/AddScan`, params);
};
