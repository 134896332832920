import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { onValue, ref } from 'firebase/database';
import { getDb } from 'module/Config/firebaseConfig';

import SidebarComponent from 'components/Sidebar/SidebarComponent';

import * as ActionAuth from '../../core/action/Auth';
import * as ActionComponent from '../../module/App/Store/ComponentAction';
import * as ActionDeliverylBranch from '../../core/action/Branch/ActionDeliveryBranch';
import * as ActionFilter from '../../core/action/DataDelivery/ActionFormDataDelivery';
import * as ActionLogin from '../../module/Login/Store';
import * as ActionProgressCourier from '../../core/action/Beranda/ActionProgressCourier';
import * as ActionRouter from '../../core/action/Router/ActionRouter';
import * as ActionTableAdminBranch from '../../core/action/ManageAccount/AdminBranch/ActionTableAdminBranch';
import * as ActionTableBranch from '../../core/action/Branch/ActionTableBranch';
import * as ActionTableCustomer from '../../core/action/Customer/ActionTableCustomer';
import * as ActionTableHoliday from '../../core/action/Holiday/ActionTableHoliday';

type SidebarContainerProps = {
  actionLogin: any;
  actionAuth: any;
  actiondatatablebranch: any;
  actionDeliverylBranch: any;
  actionProgressCourier: any;
  actionDataTableAdmin: any;
  actionTableCustomer: any;
  actionTableHoliday: any;
  actionComponent: any;
  actionrouter: any;
  actionFilter: any;
  router: any;
  routerhistory: any;
  roleAccount: any;
  countingNotif: any;
  sideMenus: Array<any>;
};

function SidebarContainer(props: SidebarContainerProps) {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [requestComplaint, setRequestComplaint] = useState<number>(0);

  useEffect(() => {
    onValue(
      ref(getDb, `${process.env.REACT_APP_FIREBASE_PARENT}/`),
      snapshoot => {
        const data = snapshoot.size;
        props.actionComponent.setCountingNotif(data);
      }
    );
    onValue(
      ref(getDb, `${process.env.REACT_APP_FIREBASE_PARENT_MOBILE}/`),
      snapshoot => {
        const data = snapshoot.size;
        setRequestComplaint(data);
      }
    );
  });

  const handleActionSelect = (e: any) => {
    switch (e) {
      case '/': {
        if (props.roleAccount === 'Branch') {
          props.actionProgressCourier.getDataProgressCourier();
        } else {
          props.actionDeliverylBranch.getDeliveryBranch();
        }
        props.actionrouter.setComponentRouter('Beranda');
        props.actionrouter.setRouter('/');
        break;
      }
      case '/branch': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actiondatatablebranch.GetTableBranch();
        props.actionrouter.setComponentRouter('Branch');
        props.actionrouter.setRouter('/branch');
        break;
      }
      case '/manageAccount': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('AdminBranch');
        props.actionrouter.setRouter('/manageAccount');
        break;
      }
      case '/delivery/status': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('DeliveryStatus');
        props.actionrouter.setRouter('/delivery/status');
        break;
      }
      case '/dataDelivery': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('DataDelivery');
        props.actionrouter.setRouter('/dataDelivery');
        break;
      }
      case '/pickupStatus': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('PickupStatus');
        props.actionrouter.setRouter('/pickupStatus');
        break;
      }
      case '/pickupData': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('PickupData');
        props.actionrouter.setRouter('/pickupData');
        break;
      }
      case '/processPickUp': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('ProcessPickup');
        props.actionrouter.setRouter('/processPickUp');
        break;
      }
      case '/processPickUp/detailPickUp': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('DetailPickup');
        props.actionrouter.setRouter('/processPickUp/detailPickUp');
        break;
      }
      case '/delivery/document': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('DeliveryDocument');
        props.actionrouter.setRouter('/delivery/document');
        break;
      }
      case '/resend': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('Resend');
        props.actionrouter.setRouter('/resend');
        break;
      }
      case '/manualProcess': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('ManualProcess');
        props.actionrouter.setRouter('/manualProcess');
        break;
      }
      case '/deliveryPriority': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('DeliveryPriority');
        props.actionrouter.setRouter('/deliveryPriority');
        break;
      }
      case '/delivery/printWaybill': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('DeliveryPrintWaybill');
        props.actionrouter.setRouter('/delivery/printWaybill');
        break;
      }
      case '/laporan/download': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('Report');
        props.actionrouter.setRouter('/laporan/download');
        break;
      }
      case '/manifest': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setRouter('/manifest');
        break;
      }
      case '/customer': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionTableCustomer.GetTableCustomer();
        props.actionrouter.setComponentRouter('Customer');
        props.actionrouter.setRouter('/customer');
        break;
      }
      // case '/complain': {
      //   props.actionAuth.changeStatusTokenRefresh(false);
      //   props.actionrouter.setComponentRouter('Complain');
      //   props.actionrouter.setRouter('/complain');
      //   break;
      // }
      case '/complaintNew': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('complaintNew');
        props.actionrouter.setRouter('/complaintNew');
        break;
      }
      case '/customer/detail': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('CustomerDetail');
        props.actionrouter.setRouter('/customer');
        break;
      }
      case '/manageCustomerAccount/detail': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('CustomerAccountDetail');
        props.actionrouter.setRouter('/manageAccount');
        break;
      }
      case '/customer/config': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('IndexCustomerConfig');
        props.actionrouter.setRouter('/customer');
        break;
      }
      case '/branch/detail': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('BranchDetail');
        props.actionrouter.setRouter('/branch');
        break;
      }
      case '/holiday': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionTableHoliday.GetTableHoliday();
        props.actionrouter.setComponentRouter('Holiday');
        props.actionrouter.setRouter('/holiday');
        break;
      }
      case '/IndexManifest': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('IndexManifest');
        props.actionrouter.setRouter('/IndexManifest');
        break;
      }
      case '/assigningCourier': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('assigningCourier');
        props.actionrouter.setRouter('/assigningCourier');
        break;
      }
      case '/assigningCourierSA': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('assigningCourierSA');
        props.actionrouter.setRouter('/assigningCourierSA');
        break;
      }
      case '/delivery/uploadSoftcopy': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionrouter.setComponentRouter('/delivery/uploadSoftcopy');
        props.actionrouter.setRouter('/delivery/uploadSoftcopy');
        break;
      }
      case '/voip': {
        props.actionAuth.changeStatusTokenRefresh(false);
        props.actionLogin.submitTokenRefrash();
        props.actionrouter.setComponentRouter('/voip');
        props.actionrouter.setRouter('/voip');
        break;
      }
    }
  };

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <SidebarComponent
      collapsed={collapsed}
      router={props.router}
      routerhistory={props.routerhistory}
      handleCollapsed={handleCollapsed}
      handleActionSelect={handleActionSelect}
      roleAccount={props.roleAccount}
      countingNotif={props.countingNotif}
      countingRequestNotif={requestComplaint}
      sidebarDatas={props.sideMenus}
    />
  );
}

const mapStateToProps = (state: any) => ({
  countingNotif: state.ComponentState.countingNotif,
  router: state.Navigation.router,
  routerhistory: state.router.location.pathname,
  roleAccount: state.Auth.Role,
  sideMenus: state.Auth.SideMenus,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionComponent: bindActionCreators(ActionComponent, dispatch),
  actiondatatablebranch: bindActionCreators(ActionTableBranch, dispatch),
  actionDataTableAdmin: bindActionCreators(ActionTableAdminBranch, dispatch),
  actionTableCustomer: bindActionCreators(ActionTableCustomer, dispatch),
  actionrouter: bindActionCreators(ActionRouter, dispatch),
  actionDeliverylBranch: bindActionCreators(ActionDeliverylBranch, dispatch),
  actionProgressCourier: bindActionCreators(ActionProgressCourier, dispatch),
  actionTableHoliday: bindActionCreators(ActionTableHoliday, dispatch),
  actionFilter: bindActionCreators(ActionFilter, dispatch),
  actionLogin: bindActionCreators(ActionLogin, dispatch),
  actionAuth: bindActionCreators(ActionAuth, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SidebarContainer);
