import React, { Component } from 'react';
import { Card } from 'antd';

interface IProps {
  content?: any;
  style?: any;
  title?: any;
  extra?: any;
  children?: React.ReactNode;
  className?: any;
}
export default class CardMaterial extends Component<IProps> {
  render() {
    return (
      <Card
        {...this.props}
        style={this.props.style}
        title={this.props.title}
        extra={this.props.extra}
        headStyle={{
          padding: this.props.children ? '0 24px' : '10px 24px',
        }}
        bodyStyle={{
          padding: this.props.children || this.props.content ? '24px' : '0',
        }}
      >
        {this.props.children && this.props.children}
        {this.props.content && this.props.content}
      </Card>
    );
  }
}
