import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import moment from 'moment';
import CustomerManifestFormComponent from '../../../../components/Content/Manifest/CustomerManifest/CustomerManifestComponent';
import * as CustomerManifest from '../../../../core/action/Manifest/CustomerManifest/ActionCustomerManifest';
import * as ActionListCustomer from '../../../../core/action/Customer/ActionListCustomer';
import * as ActionListCustomerProduct from '../../../../core/action/CustomerProduct/ActionListCustomerProduct';
import * as toggle from '../../../../core/action/toggle';

interface IProps {
  actionListCustomer: any;
  actionListCustomerProduct: any;
  listCustomer: any;
  listCustomerProduct: any;
  LoadingCustomer: any;
  TypeLoadingCustomer: any;
  LoadingCustomerProduct: any;
  TypeLoadingCustomerProduct: any;
  actionCustomerManifest: any;
  RadioButton: any;
  ScanDate: any;
  Customer: any;
  TreatmentDocument: any;
  CustomerProduct: any;
  actionToggle: any;
}
interface IState {
  ValueScanDate: any;
  ValidateScanDate: any;
  ValidateCustomer: any;
  ValidateCustomerProduct: any;
}
class CustomerManifestFormContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValueScanDate: null,
      ValidateScanDate: null,
      ValidateCustomer: null,
      ValidateCustomerProduct: null,
    };
    this.handleChangeRadioButton = this.handleChangeRadioButton.bind(this);
    this.handleValidateScanDate = this.handleValidateScanDate.bind(this);
    this.handlePutScanDate = this.handlePutScanDate.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleResetProduct = this.handleResetProduct.bind(this);
    this.handleResetCustomer = this.handleResetCustomer.bind(this);
    this.handleGetDataManifest = this.handleGetDataManifest.bind(this);
    this.handleGetCustomer = this.handleGetCustomer.bind(this);
    this.handleGetCustomerProduct = this.handleGetCustomerProduct.bind(this);
    this.handlePutCustomer = this.handlePutCustomer.bind(this);
    this.handleChangeTreatmentDocument =
      this.handleChangeTreatmentDocument.bind(this);
    this.handleBlurCustomer = this.handleBlurCustomer.bind(this);
    this.handlePutCustomerProduct = this.handlePutCustomerProduct.bind(this);
    this.handleBlurCustomerProduct = this.handleBlurCustomerProduct.bind(this);
  }

  handleChangeRadioButton(event: any) {
    this.props.actionCustomerManifest.changeRadioButtonCustomerManifestForm(
      event.target.value
    );
    this.props.actionCustomerManifest.clearTableCustomerManifestForm();
  }

  handleValidateScanDate(event: any) {
    this.setState({
      ValidateScanDate: event,
    });
    if (this.state.ValidateScanDate === true) {
      if (
        this.props.ScanDate === null ||
        this.props.ScanDate === undefined ||
        this.props.ScanDate === 'Invalid date'
      ) {
        this.setState({
          ValidateScanDate: 'error',
        });
      } else {
        this.setState({
          ValidateScanDate: null,
        });
      }
    } else {
    }
  }

  handlePutScanDate(event: any) {
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      this.props.actionCustomerManifest.changeScanDateCustomerManifestForm(
        dateValue
      );
      this.props.actionCustomerManifest.clearTableCustomerManifestForm();
    } else {
      this.props.actionCustomerManifest.changeScanDateCustomerManifestForm(
        null
      );
    }
    this.setState({
      ValueScanDate: event,
    });
    if (event === '' || event === null) {
      this.setState({
        ValidateScanDate: 'error',
      });
    } else {
      this.setState({
        ValidateScanDate: null,
      });
    }
  }

  handleGetCustomer() {
    this.props.actionListCustomer.getListCustomer();
  }
  handleGetCustomerProduct() {
    this.props.actionListCustomerProduct.getListCustomerProductNoType();
  }
  handleResetProduct() {
    this.setState({
      ValidateCustomerProduct: null,
    });
  }
  handleResetCustomer() {
    this.props.actionListCustomerProduct.clearListCustomerProduct();
    this.props.actionCustomerManifest.changeProductCustomerManifestForm(null);
    this.setState({
      ValidateCustomerProduct: null,
    });
  }

  handlePutCustomer(event: any) {
    this.props.actionCustomerManifest.changeCustomerCustomerManifestForm(event);
    if (event === null) {
      this.setState({
        ValidateCustomer: 'error',
      });
    } else {
      this.setState({
        ValidateCustomer: null,
      });
    }
    this.props.actionCustomerManifest.clearTableCustomerManifestForm();
  }
  handleChangeTreatmentDocument(event: any) {
    this.props.actionCustomerManifest.changeTreatmentDocumentCustomerManifestForm(
      event
    );
    this.props.actionCustomerManifest.clearTableCustomerManifestForm();
  }

  handleBlurCustomer() {
    if (this.props.Customer === null) {
      this.setState({
        ValidateCustomer: 'error',
      });
    } else {
      this.setState({
        ValidateCustomer: null,
      });
    }
  }
  handleBlurCustomerProduct() {
    if (this.props.CustomerProduct === null) {
      this.setState({
        ValidateCustomerProduct: 'error',
      });
    } else {
      this.setState({
        ValidateCustomerProduct: null,
      });
    }
  }
  handlePutCustomerProduct(event: any) {
    this.props.actionCustomerManifest.changeProductCustomerManifestForm(event);
    if (this.props.CustomerProduct === null) {
      this.setState({
        ValidateCustomerProduct: 'error',
      });
    } else {
      this.setState({
        ValidateCustomerProduct: null,
      });
    }
    this.props.actionCustomerManifest.clearTableCustomerManifestForm();
  }

  handleReset() {
    this.props.actionCustomerManifest.clearCustomerManifestForm();
    this.props.actionCustomerManifest.clearTableCustomerManifestForm();
    this.props.actionCustomerManifest.clearListCustomerrManifestForm();
    this.setState({
      ValueScanDate: null,
      ValidateScanDate: null,
      ValidateCustomer: null,
      ValidateCustomerProduct: null,
    });
  }

  handleGetDataManifest() {
    this.props.actionCustomerManifest.changeCountCustomerManifestForm(null);
    this.props.actionCustomerManifest.clearTableCustomerManifestForm();
    this.props.actionCustomerManifest.clearListCustomerrManifestForm();
    this.props.actionCustomerManifest.changeGetDataCustomerManifest();
  }

  render() {
    return (
      <CustomerManifestFormComponent
        radioButton={this.props.RadioButton}
        valueScanDate={this.state.ValueScanDate}
        scanDate={this.props.ScanDate}
        customer={this.props.Customer}
        treatmentDocument={this.props.TreatmentDocument}
        customerProduct={this.props.CustomerProduct}
        listCustomer={this.props.listCustomer}
        listCustomerProduct={this.props.listCustomerProduct}
        loadingCustomer={this.props.LoadingCustomer}
        loadingCustomerProduct={this.props.LoadingCustomerProduct}
        validateScanDate={
          this.state.ValidateScanDate === 'error' ? 'error' : ''
        }
        validateCustomer={
          this.state.ValidateCustomer === 'error' ? 'error' : ''
        }
        validateCustomerProduct={
          this.state.ValidateCustomerProduct === 'error' ? 'error' : ''
        }
        helpValidateScanDate={
          this.state.ValidateScanDate === 'error' ? 'Tanggal wajib diisi' : ''
        }
        helpValidateCustomer={
          this.state.ValidateCustomer === 'error' ? 'Pelanggan wajib diisi' : ''
        }
        helpValidateCustomerProduct={
          this.state.ValidateCustomerProduct === 'error'
            ? 'Produk pelanggan wajib diisi'
            : ''
        }
        handleValidateScanDate={this.handleValidateScanDate}
        handleGetCustomer={this.handleGetCustomer}
        handlePutCustomer={this.handlePutCustomer}
        handleChangeTreatmentDocument={this.handleChangeTreatmentDocument}
        handleBlurCustomer={this.handleBlurCustomer}
        handleGetCustomerProduct={this.handleGetCustomerProduct}
        handlePutCustomerProduct={this.handlePutCustomerProduct}
        handleBlurCustomerProduct={this.handleBlurCustomerProduct}
        handleResetProduct={this.handleResetProduct}
        handleResetCustomer={this.handleResetCustomer}
        handleChangeRadioButton={this.handleChangeRadioButton}
        handleGetDataManifest={this.handleGetDataManifest}
        handlePutScanDate={this.handlePutScanDate}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  RadioButton: state.Manifest.CustomerManifestForm.RadioButton,
  ScanDate: state.Manifest.CustomerManifestForm.ScanDate,
  Customer: state.Manifest.CustomerManifestForm.Customer,
  TreatmentDocument: state.Manifest.CustomerManifestForm.TreatmentDocument,
  CustomerProduct: state.Manifest.CustomerManifestForm.Product,
  listCustomer: state.Customer.ListCustomer,
  listCustomerProduct: state.CustomerProduct.ListCustomerProduct,
  LoadingCustomer: state.Customer.PropertiesCustomer.LoadingCustomer,
  TypeLoadingCustomer: state.Customer.PropertiesCustomer.TypeLoadingCustomer,
  LoadingCustomerProduct:
    state.CustomerProduct.PropertiesCustomerProduct.LoadingCustomerProduct,
  TypeLoadingCustomerProduct:
    state.CustomerProduct.PropertiesCustomerProduct.TypeLoadingCustomerProduct,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionCustomerManifest: bindActionCreators(CustomerManifest, dispatch),
  actionListCustomer: bindActionCreators(ActionListCustomer, dispatch),
  actionListCustomerProduct: bindActionCreators(
    ActionListCustomerProduct,
    dispatch
  ),
  actionToggle: bindActionCreators(toggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CustomerManifestFormContainer);
