import { Action } from 'redux';

const initialState = {
  CourierManifestForm: {
    DeliveryDate: null,
    BranchId: null,
    BranchName: null,
    Count: null,
  },
  TableCourierManifest: [],
  ListCourierManifest: [],
  CustomerManifestForm: {
    RadioButton: 'Success',
    ScanDate: null,
    Customer: null,
    Product: null,
    Count: null,
    ReturnName: null,
    Address: null,
    City: null,
    ReturnDate: null,
    TreatmentDocument: ['ReturnCustomer', 'Destroy'],
  },
  TableCustomerManifest: [],
  ListCustomerManifest: [],
};

export interface IAuthAction extends Action<string> {}

export interface IChangeDeliveryDateCourierManifestForm extends IAuthAction {
  DeliveryDate: any;
}
export interface IChangeBranchIdCourierManifestForm extends IAuthAction {
  BranchId: any;
}
export interface IChangeBranchNameCourierManifestForm extends IAuthAction {
  BranchName: any;
}
export interface IChangeTableCourierManifestForm extends IAuthAction {
  TableCourierManifest: any;
}
export interface IChangeListCourierManifestForm extends IAuthAction {
  ListCourierManifest: never;
}
export interface IChangeRemoveListCourierManifestForm extends IAuthAction {
  ListCourierManifest: never;
}
export interface IChangeCountCourierManifestForm extends IAuthAction {
  Count: any;
}
export interface IChangeRemoveCountListCourierManifestForm extends IAuthAction {
  Count: any;
}

// --------------------------------------------------------------------------//
export interface IChangeRadioButtonCustomerManifestForm extends IAuthAction {
  RadioButton: any;
}
export interface IChangeScanDateCustomerManifestForm extends IAuthAction {
  ScanDate: any;
}
export interface IChangeCustomerCustomerManifestForm extends IAuthAction {
  Customer: any;
}
export interface IChangeTreatmentDocumentCustomerManifestForm
  extends IAuthAction {
  TreatmentDocument: any;
}
export interface IChangeProductCustomerManifestForm extends IAuthAction {
  Product: any;
}
export interface IChangeCountCustomerManifestForm extends IAuthAction {
  Count: any;
}
export interface IChangeRemoveCountCustomerManifestForm extends IAuthAction {
  Count: any;
}
export interface IChangeReturnNameCustomerManifestForm extends IAuthAction {
  ReturnName: any;
}
export interface IChangeReturnAddressCustomerManifestForm extends IAuthAction {
  Address: any;
}
export interface IChangeReturnCityCustomerManifestForm extends IAuthAction {
  City: any;
}
export interface IChangeReturnDateCustomerManifestForm extends IAuthAction {
  ReturnDate: any;
}
export interface IChangeTableCustomerManifestForm extends IAuthAction {
  TableCustomerManifest: any;
}
export interface IChangeListCustomerManifestForm extends IAuthAction {
  ListCustomerManifest: never;
}
export interface IChangeRemoveListCustomerManifestForm extends IAuthAction {
  ListCustomerManifest: never;
}

export default function AuthReducer(state = initialState, action: IAuthAction) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_DELIVERY_DATE_COURIER_MANIFEST_FORM':
      const changeDeliveryDateCourierManifestForm =
        action as IChangeDeliveryDateCourierManifestForm;
      newState.CourierManifestForm.DeliveryDate =
        changeDeliveryDateCourierManifestForm.DeliveryDate;
      return { ...newState };

    case 'CHANGE_BRANCH_ID_COURIER_MANIFEST_FORM':
      const changeBranchIdCourierManifestForm =
        action as IChangeBranchIdCourierManifestForm;
      newState.CourierManifestForm.BranchId =
        changeBranchIdCourierManifestForm.BranchId;
      return { ...newState };

    case 'CHANGE_BRANCH_NAME_COURIER_MANIFEST_FORM':
      const changeBranchNameCourierManifestForm =
        action as IChangeBranchNameCourierManifestForm;
      newState.CourierManifestForm.BranchName =
        changeBranchNameCourierManifestForm.BranchName;
      return { ...newState };

    case 'CHANGE_TABLE_COURIER_MANIFEST_FORM':
      const changeTableCourierManifestForm =
        action as IChangeTableCourierManifestForm;
      newState.TableCourierManifest =
        changeTableCourierManifestForm.TableCourierManifest;
      return { ...newState };

    case 'CHANGE_LIST_COURIER_MANIFEST_FORM':
      const changeListCourierManifestForm =
        action as IChangeListCourierManifestForm;
      newState.ListCourierManifest = [
        ...state.ListCourierManifest,
        changeListCourierManifestForm.ListCourierManifest,
      ];
      return { ...newState };

    case 'CHANGE_COUNT_COURIER_MANIFEST_FORM':
      const changeCountCourierManifestForm =
        action as IChangeCountCourierManifestForm;
      newState.CourierManifestForm.Count = changeCountCourierManifestForm.Count;
      return { ...newState };

    case 'REMOVE_LIST_COURIER_MANIFEST_FORM':
      const changeRemovedCheckbox =
        action as IChangeRemoveListCourierManifestForm;
      const indexCheckbox = newState.ListCourierManifest.indexOf(
        changeRemovedCheckbox.ListCourierManifest
      );
      const arrayCheckbox = newState.ListCourierManifest;
      const resultArrayCheckbox = arrayCheckbox.splice(indexCheckbox, 1);
      console.log(resultArrayCheckbox);
      return { ...newState };

    case 'CLEAR_COURIER_MANIFEST_FORM':
      newState.CourierManifestForm.DeliveryDate = null;
      newState.CourierManifestForm.BranchId = null;
      newState.CourierManifestForm.BranchName = null;
      return { ...newState };

    case 'CLEAR_TABLE_COURIER_MANIFEST_FORM':
      newState.TableCourierManifest = [];
      return { ...newState };

    case 'CLEAR_LIST_COURIER_MANIFEST_FORM':
      newState.ListCourierManifest = [];
      newState.CourierManifestForm.Count = null;
      return { ...newState };

    // -------------------------------------------------------------------------//
    case 'CHANGE_RADIO_BUTTON_CUSTOMER_MANIFEST_FORM':
      const changeRadioButtonCustomerManifestForm =
        action as IChangeRadioButtonCustomerManifestForm;
      newState.CustomerManifestForm.RadioButton =
        changeRadioButtonCustomerManifestForm.RadioButton;
      return { ...newState };

    case 'CHANGE_SCAN_DATE_CUSTOMER_MANIFEST_FORM':
      const changeScanDateCustomerManifestForm =
        action as IChangeScanDateCustomerManifestForm;
      newState.CustomerManifestForm.ScanDate =
        changeScanDateCustomerManifestForm.ScanDate;
      return { ...newState };

    case 'CHANGE_CUSTOMER_CUSTOMER_MANIFEST_FORM':
      const changeCustomerCustomerManifestForm =
        action as IChangeCustomerCustomerManifestForm;
      newState.CustomerManifestForm.Customer =
        changeCustomerCustomerManifestForm.Customer;
      return { ...newState };

    case 'CHANGE_TREATMENT_DOCUMENT_MANIFEST_FORM':
      const changeTreatmentDocument =
        action as IChangeTreatmentDocumentCustomerManifestForm;
      newState.CustomerManifestForm.TreatmentDocument =
        changeTreatmentDocument.TreatmentDocument;
      return { ...newState };

    case 'CHANGE_PRODUCT_CUSTOMER_MANIFEST_FORM':
      const changeProductCustomerManifestForm =
        action as IChangeProductCustomerManifestForm;
      newState.CustomerManifestForm.Product =
        changeProductCustomerManifestForm.Product;
      return { ...newState };

    case 'CHANGE_COUNT_CUSTOMER_MANIFEST_FORM':
      const changeCountCustomerManifestForm =
        action as IChangeCountCustomerManifestForm;
      newState.CustomerManifestForm.Count =
        changeCountCustomerManifestForm.Count;
      return { ...newState };

    case 'CHANGE_RETURN_NAME_CUSTOMER_MANIFEST_FORM':
      const changeReturnNameCustomerManifestForm =
        action as IChangeReturnNameCustomerManifestForm;
      newState.CustomerManifestForm.ReturnName =
        changeReturnNameCustomerManifestForm.ReturnName;
      return { ...newState };

    case 'CHANGE_RETURN_ADDRESS_CUSTOMER_MANIFEST_FORM':
      const changeReturnAddressCustomerManifestForm =
        action as IChangeReturnAddressCustomerManifestForm;
      newState.CustomerManifestForm.Address =
        changeReturnAddressCustomerManifestForm.Address;
      return { ...newState };

    case 'CHANGE_RETURN_CITY_CUSTOMER_MANIFEST_FORM':
      const changeReturnCityCustomerManifestForm =
        action as IChangeReturnCityCustomerManifestForm;
      newState.CustomerManifestForm.City =
        changeReturnCityCustomerManifestForm.City;
      return { ...newState };

    case 'CHANGE_RETURN_DATE_CUSTOMER_MANIFEST_FORM':
      const changeReturnDateCustomerManifestForm =
        action as IChangeReturnDateCustomerManifestForm;
      newState.CustomerManifestForm.ReturnDate =
        changeReturnDateCustomerManifestForm.ReturnDate;
      return { ...newState };

    case 'CHANGE_TABLE_CUSTOMER_MANIFEST_FORM':
      const changeTableCustomerManifestForm =
        action as IChangeTableCustomerManifestForm;
      newState.TableCustomerManifest =
        changeTableCustomerManifestForm.TableCustomerManifest;
      return { ...newState };

    case 'CHANGE_LIST_CUSTOMER_MANIFEST_FORM':
      const changeCheckboxId = action as IChangeListCustomerManifestForm;
      newState.ListCustomerManifest = changeCheckboxId.ListCustomerManifest;
      return { ...newState };

    case 'REMOVE_LIST_CUSTOMER_MANIFEST_FORM':
      const changeRemovedCheckboxId =
        action as IChangeRemoveListCustomerManifestForm;
      const indexCheckboxId = newState.ListCustomerManifest.indexOf(
        changeRemovedCheckboxId.ListCustomerManifest
      );
      const arrayCheckboxId = newState.ListCustomerManifest;
      const resultArrayCheckboxId = arrayCheckboxId.splice(indexCheckboxId, 1);
      console.log(resultArrayCheckboxId);
      return { ...newState };

    case 'CLEAR_CUSTOMER_MANIFEST_FORM':
      newState.CustomerManifestForm.RadioButton = 'Success';
      newState.CustomerManifestForm.ScanDate = null;
      newState.CustomerManifestForm.Customer = null;
      newState.CustomerManifestForm.Product = null;
      newState.CustomerManifestForm.ReturnName = null;
      newState.CustomerManifestForm.ReturnDate = null;
      newState.CustomerManifestForm.Address = null;
      newState.CustomerManifestForm.City = null;
      newState.CustomerManifestForm.TreatmentDocument = [
        'ReturnCustomer',
        'Destroy',
      ];
      return { ...newState };
    case 'CLEAR_CUSTOMER_MANIFEST_POPUP_FORM':
      newState.CustomerManifestForm.ReturnName = null;
      newState.CustomerManifestForm.ReturnDate = null;
      newState.CustomerManifestForm.Address = null;
      newState.CustomerManifestForm.City = null;
      return { ...newState };

    case 'CLEAR_TABLE_CUSTOMER_MANIFEST_FORM':
      newState.TableCustomerManifest = [];
      return { ...newState };

    case 'CLEAR_LIST_CUSTOMER_MANIFEST_FORM':
      newState.ListCustomerManifest = [];
      newState.CustomerManifestForm.Count = null;
      return { ...newState };
  }
  return { ...newState };
}
