export function setDeliveryBranch(DeliveryBranch:any)
{
    return({
        type:"SET_DELIVERY_BRANCH",
        DeliveryBranch
    })
}
export function getDeliveryBranch()
{
    return({
        type:"GET_DELIVERY_BRANCH"
    })
}
export function clearDeliveryBranch()
{
    return({
        type:"CLEAR_DELIVERY_BRANCH"
    })
}