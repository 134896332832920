export function setComplaintList(complaintList:any)
{
    return({
        type:"SET_COMPLAINT_LIST",
        complaintList
    })
}
export function getComplaintList()
{
    return({
        type:"GET_COMPLAINT_LIST"
    })
}