import * as ActionAuth from '../../action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionRestoreData from '../../action/RestoreData/ActionRestoreData';
import * as toggle from '../../action/toggle/index';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import React from 'react';
import axios from 'axios';
import moment from 'moment';

export function* S_deleteRestoreData(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const LastAccess = state.LastAccess.Fiture;

  const ListFile = state.RestoreData.ListRestoreData.filter(
    item => item.selected === true
  );
  const DataFile = ListFile.map((data: any) => data.id);

  const ListWaybill = state.RestoreData.ListWaybillRestoreData.filter(
    item => item.selected === true
  );
  const DataWaybill = ListWaybill.map((data: any) => data.waybill);

  const body =
    LastAccess === null || LastAccess === 'restoreDataCsv'
      ? {
          idDeliveryFile: DataFile,
        }
      : {
          waybillCode: DataWaybill,
        };
  const apiLink = `${process.env.REACT_APP_URL}/Delivery/BackupData/DeleteMultipleFile/`;
  const apiLink2 = `${process.env.REACT_APP_URL}/Delivery/BackupData/DeleteMultipleWaybill/`;
  try {
    yield put(toggle.changeLoadingTrue());
    const { data } =
      LastAccess === null || LastAccess === 'restoreDataCsv'
        ? yield call(axios.delete, apiLink, { data: body, headers: config })
        : yield call(axios.delete, apiLink2, { data: body, headers: config });
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(
      LastAccess === null || LastAccess === 'restoreDataCsv'
        ? ActionRestoreData.clearListRestoreData()
        : ActionRestoreData.clearListWaybillRestoreData()
    );
    yield put(ActionRestoreData.setListResultDeleteData(data));
    yield put(ActionRestoreData.submitGetDataRestoreData());
    if (LastAccess === null || LastAccess === 'restoreDataCsv') {
      yield put(toggle.changeModal2());
      yield put(toggle.changeJenisToggle2('resultDelete'));
    } else if (data.substring(0, 6) === 'Ticket') {
      yield put(toggle.changeModal2());
      yield put(toggle.changeJenisToggle2('failedDeleteWaybill'));
    } else {
      Modal.success({
        title: (
          <p style={{ color: '#31E116' }}>
            <b>Berhasil dihapus</b>
          </p>
        ),
        onOk() {},
      });
    }
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}

export function* S_DownloadResultDeleteFileProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const listResultDeleteData = state.RestoreData.ListResultDeleteData;
  const DateNow = moment(new Date()).format('YYYYMMDDHHmmss');
  const key = 'download';

  const params = {
    listResult: listResultDeleteData.listResultCsv,
  };
  try {
    Modal.info({
      title: <p style={{ color: '#11beff' }}>{<b>Download Data</b>}</p>,
      content: (
        <div>
          <p>Sedang mendownload data, mohon tunggu</p>
        </div>
      ),
    });
    axios({
      url: `${process.env.REACT_APP_URL}/Delivery/upload/downloadResult`,
      method: 'POST',
      responseType: 'blob',
      data: params,
      headers: config,
      onDownloadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        let percentProgress = Math.floor((loaded * 100) / total);

        if (percentProgress < 100) {
          message.loading({
            content: 'Loading ' + percentProgress + '%',
            key,
          });
        } else if (percentProgress === 100) {
          Modal.destroyAll();
          message.success({
            content: 'Download Berhasil',
            key,
            duration: 1.5,
          });
        } else if (percentProgress === Infinity) {
          Modal.destroyAll();
          message.error({
            content: 'Download Gagal',
            key,
            duration: 3,
          });
        }
      },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `LaporanHapusRestore_${DateNow}.txt`);
      document.body.appendChild(link);
      link.click();
    });
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_deleteRestoreData() {
  yield takeLatest('SUBMIT_DELETE_RESTORE_DATA', S_deleteRestoreData);
}
export function* SM_DownloadResultDeleteFileAction() {
  yield takeLatest(
    'DOWNLOAD_RESULT_DELETE_FILE',
    S_DownloadResultDeleteFileProcess
  );
}
