export function setTicketId(ticketId:any)
{
    return({
        type:"SET_TICKET_ID_DP",
        ticketId
    })
}
export function setDeliveryId(deliveryId:any)
{
    return({
        type:"SET_DELIVERY_ID_DP",
        deliveryId
    })
}
export function setWaybillForm(waybillForm:any)
{
    return({
        type:"CHANGE_WAYBILL_FORM_DP",
        waybillForm
    })
}
export function setWaybillCode(waybillCode:any)
{
    return({
        type:"SET_WAYBILL_CODE_DP",
        waybillCode
    })
}
export function setBarcode(barcode:any)
{
    return({
        type:"SET_BARCODE_DP",
        barcode
    })
}
export function setCustomerName(customerName:any)
{
    return({
        type:"SET_CUSTOMER_NAME_DP",
        customerName
    })
}
export function setCourierName(courierName:any)
{
    return({
        type:"SET_COURIER_NAME_DP",
        courierName
    })
}
export function setCourierCode(courierCode:any)
{
    return({
        type:"SET_COURIER_CODE_DP",
        courierCode
    })
}
export function setAddress(address:any)
{
    return({
        type:"SET_ADDRESS_DP",
        address
    })
}
export function setTicketStatus(ticketStatus:any)
{
    return({
        type:"SET_TICKET_STATUS_DP",
        ticketStatus
    })
}
export function setDocumentStatus(documentStatus:any)
{
    return({
        type:"SET_DOCUMENT_STATUS_DP",
        documentStatus
    })
}
export function setCreatedTime(createdTime:any)
{
    return({
        type:"SET_CREATED_TIME_DP",
        createdTime
    })
}
export function setListDeliveryPriority(ListDeliveryPriority:any)
{
    return({
        type:"SET_LIST_DELIVERY_PRIORITY_DP",
        ListDeliveryPriority
    })
}
export function changeFilterDP(filterType:any)
{
    return({
        type:"CHANGE_FILTER_DP",
        filterType
    })
}
export function getDataTableDP()
{
    return({
        type:"GET_DATA_TABLE_DP"
    })
}
export function getDetailDeliveryPriority()
{
    return({
        type:"GET_DETAIL_TICKET_DELIVERY_PRIORITY"
    })
}
export function addTicketDeliveryPriority()
{
    return({
        type:"ADD_TICKET_DELIVERY_PRIORITY"
    })
}
export function filterDataTableDP()
{
    return({
        type:"FILTER_DATA_TABLE_DP"
    })
}
export function clearDataForm()
{
    return({
        type:"CLEAR_DATA_FORM_DP"
    })
}
export function clearWaybillForm()
{
    return({
        type:"CLEAR_WAYBILL_FORM_DP"
    })
}
export function clearDataTable()
{
    return({
        type:"CLEAR_DATA_TABLE_DP"
    })
}