export function setPagePagination(PagePagination:any)
{
    return({
        type:"SET_PAGE_PAGINATION",
        PagePagination
    })
}
export function setTotalPagePagination(TotalPagePagination:any)
{
    return({
        type:"SET_TOTAL_PAGE_PAGINATION",
        TotalPagePagination
    })
}
export function setSizeDataPagination(SizeDataPagination:any)
{
    return({
        type:"SET_SIZE_DATA_PAGINATION",
        SizeDataPagination
    })
}
export function clearAllPagination()
{
    return({
        type:"CLEAR_ALL_PAGINATION"
    })
}