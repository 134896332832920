import { getDatabase } from 'firebase/database';
import { initializeApp } from 'firebase/app';

// SMART COURIER FIREBASE

const firebaseConfig = {
  apiKey: 'AIzaSyD_jYSuTPqZ2h91tNxXCSE3sSn_hSSI74E',
  authDomain: 'smartcourier-516a3.firebaseapp.com',
  databaseURL:
    'https://smartcourier-516a3-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'smartcourier-516a3',
  storageBucket: 'smartcourier-516a3.appspot.com',
  messagingSenderId: '219442646148',
  appId: '1:219442646148:web:c3185182c4d9dc4f5dc6d1',
  measurementId: 'G-V29SF0MTYZ',
};

// OBIE FIREBASE

// const firebaseConfig = {
//     apiKey: "AIzaSyCYp7ZL_sqXaeOxYOEBpJlp3EIqx2hhYMM",
//     authDomain: "test-conting.firebaseapp.com",
//     databaseURL: "https://test-conting-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "test-conting",
//     storageBucket: "test-conting.appspot.com",
//     messagingSenderId: "564413579465",
//     appId: "1:564413579465:web:2125a32629062b293fcd18",
//     measurementId: "G-KGCY8X1VW4"
//   };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const getDb = getDatabase(app);
