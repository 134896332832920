import { SM_AddAccountAdmin, SM_AddBranch } from './Branch/SagaAddBranch';
import {
  SM_CourierTable,
  SM_SearchTableCourier,
} from './ManageAccount/Courier/SagaTableCourier';
import {
  SM_DetailEditBranch,
  SM_EditSubmitBranch,
} from './Branch/SagaEditBranch';
import { SM_DetailVillage, SM_GetVillage } from './Address/SagaVillage';
import {
  SM_DownloadResultDeleteFileAction,
  SM_deleteRestoreData,
} from './RestoreData/SagaDeleteRestoreData';
import {
  SM_DownloadResultEmergency,
  SM_GetCountEmergency,
  SM_GetDeleteEmergency,
  SM_SubmiteEmergencyScan,
  SM_SubmiteEmergencyUpload,
} from './ManualProcess/Emergency/SagaSubmitEmergency';
import {
  SM_DownloadResultPickupFileAction,
  SM_DownloadTemplatePickupFileAction,
  SM_downloadPickupDataAction,
  SM_getCourierBranchListAction,
  SM_getDetailPickUpListAction,
  SM_getPickUpListAction,
  SM_submitAssignCourierAction,
  SM_uploadCsvPickupAction,
} from '../../module/PickUpProcess/Saga/SagaPickUpProcess';
import {
  SM_DownloadResultUploadCsv,
  SM_UploadCsv,
  SM_changeNextUploadCsv,
  SM_getDataStatusUpload,
  SM_getDetailDocumentStatusUpload,
} from './Delivery/Document/SagaUploadCsv';
import {
  SM_FilterDataTableDeliveryPriority,
  SM_GetDataTableDeliveryPriority,
} from './DeliveryPriority/SagaTableDeliveryPriority';
import {
  SM_GenerateNewWaybill,
  SM_GetDataWaybill,
} from './Delivery/Resend/SingleData/SagaSingleData';
import {
  SM_GetAllAdminBranch,
  SM_GetDetailBranch,
  SM_GetDetailVillage,
  SM_GetNameAdminBranch,
} from './Branch/SagaDetailBranch';
import {
  SM_GetCourierCodeAndName,
  SM_GetListScan,
  SM_GetListUnScan,
  SM_GetTotalSacn,
  SM_ScanByBarcode,
} from './Delivery/Document/SagaDistribution';
import {
  SM_GetCustomDataStatus,
  SM_GetDefaultDataStatus,
} from './Customer/SagaDefaultStatus';
import {
  SM_GetDataTableBranch,
  SM_SearchTableBranch,
} from './Branch/TableBranchSaga';
import {
  SM_GetDataTableCustomer,
  SM_SearchTableCustomer,
} from './Customer/TableCustomerSaga';
import {
  SM_GetDuplicateBarcodeScanManifest,
  SM_GetListScanManifest,
  SM_SearchDataScanManifest,
} from './Manifest/Scan/SagaGetTableManifest';
import {
  SM_GetListColumnHistory,
  SM_GetListColumnMasterData,
} from './Customer/SagaListColumnCustomReport';
import {
  SM_GetListColumnSecHistory,
  SM_GetListColumnSecMasterData,
} from './Customer/SagaListColumnSecCustomReport';
import {
  SM_GetListCustomerProduct,
  SM_GetListCustomerProductNoType,
} from './CustomerProduct/SagaListCustomerProduct';
import {
  SM_GetListCustomerProductUpdateAddress,
  SM_GetListTemplateUpload,
  SM_SubmitUpdateAddressUpload,
  SM_downloadResultUploadAddress,
  SM_getWaybillUpdateAddress,
  SM_submitUpdateAddress,
} from './ManualProcess/UpdateAddress/SagaUpdateAddress';
import {
  SM_GetStatusDeliveryReturnCustomerReport,
  SM_GetStatusDeliverySuccessCustomerReport,
} from './Report/Customer/SagaGetStatusDeliveryCustomerReport';
import {
  SM_ScanManifest,
  SM_ScanManifestMultipleBarcode,
} from './Manifest/Scan/SagaScanManifest';
import {
  SM_SearchTableAdminBranch,
  SM_TableAdmin,
} from './ManageAccount/AdminBranch/SagaTableAdminBranch';
import {
  SM_SubmitWaybill,
  SM_SubmitWorBSA,
} from './AssigningCourier/SagaSubmit';
import {
  SM_downloadComplaintAction,
  SM_getComplaintListAction,
  SM_getCourierComplaintAction,
  SM_getDetailDataComplaintAction,
  SM_getWaybillComplaintAction,
  SM_submitChangeCourierInvestigateAction,
  SM_submitDocComplaintAction,
  SM_submitInQueueToProcessAction,
  SM_submitProcessToCustomerConfirmAction,
  SM_submitSearchComplaintAction,
} from '../../module/ComplaintNew/Saga/SagaComplaint';
import {
  SM_downloadComplaintReportAction,
  SM_getCustProductListComplaintReportAction,
} from './../../module/Report/ComplaintReport/Saga/SagaComplaintReport';
import {
  SM_downloadCourierReport,
  SM_getCourierMultipleBranch,
} from './Report/Courier/SagaDownloadCourierReport';
import {
  SM_downloadDigitalWaybillTemplate,
  SM_getCountDigitalWaybill,
  SM_getDeleteDigitalWaybill,
  SM_getDownloadDigitalWaybillByUpload,
  SM_getErrorDigitalWaybill,
} from './Report/DigitalWaybill/SagaDownloadDigitalWaybillByUpload';
import {
  SM_downloadLogActivityDataAction,
  SM_getLogActivityListAction,
  SM_logActivityLogoutAction,
} from '../../module/LogActivity/Saga/SagaLogActivity';
import {
  SM_downloadResultUploadCallCenterAction,
  SM_getCourierListAction,
  SM_getCustomerProductListAction,
  SM_getFormatCsvListAction,
  SM_submitUploadCallCenterAction,
} from '../../module/CallCenter/Saga/SagaCallCenter';
import { SM_getAllBranch, SM_getBranchGroupList } from './Branch/SagaAllBranch';
import {
  SM_getAllBranchAction,
  SM_getAllCustomerAction,
  SM_getInternalBranch,
  SM_getLogActivityAction,
  SM_getUserAction,
} from '../../module/App/Saga/SagaGetAllGeneral';
import {
  SM_getAllLocationCourier,
  SM_getIndividuLocationCourier,
} from './Maps/SagaCourierLocation';
import {
  SM_getCountingStatusPickupAction,
  SM_getCourierPickupDataAction,
  SM_getCustProductListAction,
  SM_getPickupDataAction,
} from '../../module/Pickup/PickupData/Saga/SagaPickupData';
import {
  SM_getDataTableDataActive,
  SM_searchDataTableDataActive,
} from './Voip/DataActive/SagaTableDataActive';
import {
  SM_getDataTableServerVoip,
  SM_searchDataTableServerVoip,
} from './Voip/ServerVoip/SagaTableServerVoip';
import {
  SM_getDeliveryStatus,
  SM_getFocusDeliveryStatus,
} from './DeliveryStatus/SagaGetDeliveryStatus';
import {
  SM_getDeliveryStatusBarcode,
  SM_getDeliveryStatusFilter,
} from './DeliveryStatus/SagaGetDeliveryStatusBarcode';
import {
  SM_getGroupNameBranchList,
  SM_submitEditGroupNameBranch,
  SM_submitGroupNameBranch,
} from './Branch/SagaGroupBranch';
import {
  SM_getGroupNameList,
  SM_submitEditGroupName,
  SM_submitGroupNameProduct,
} from './CustomerProduct/SagaGroupName';
import {
  SM_getLevelListAction,
  SM_getModuleListAction,
  SM_submitLevelAction,
} from './../../module/ManageAccountNew/Level/Saga/SagaLevel';
import {
  SM_getTemplateHoliday,
  SM_uploadCsvHoliday,
} from './Holiday/SagaUploadCsvHoliday';
import {
  SM_login,
  SM_loginTokenRefrash,
  SM_loginTokenRefreshTemporary,
} from '../../module/Login/Saga/LoginSaga';
import { all, fork } from 'redux-saga/effects';

import { SM_AddCustomCsvCustomer } from './Customer/SagaAddCustomCsvCustomer';
import { SM_AddCustomReportDataCustomer } from './Customer/SagaAddCustomReportDataCustomer';
import { SM_AddCustomStatus } from './Customer/SagaAddCustomStatus';
import { SM_AddCustomeProduct } from './CustomerProduct/SagaAddCustomerProduct';
import { SM_AddCustomer } from './Customer/SagaAddCustomer';
import { SM_AddGroupName } from './Manifest/Scan/SagaAddGroup';
import { SM_CourierTableBranch } from './ManageAccount/Courier/SagaTableCourier';
import { SM_DeleteScanManifest } from './Manifest/Scan/SagaDeleteScanManifest';
import { SM_DetailCsvList } from './Customer/SagaDetailCsvList';
import { SM_DetailCustomCsvCustomer } from './Customer/SagaDetailCustomCsvCustomer';
import { SM_DetailCustomReportDataCustomer } from './Customer/SagaDetailCustomReportDataCustomer';
import { SM_DownloadResultCountManifest } from './Manifest/Scan/SagaDownloadResultCountManifest';
import { SM_DownloadWaybill } from './Delivery/Document/SagaDownloadWaybill';
import { SM_EditCustomCsvCustomer } from './Customer/SagaEditCustomCsvCustomer';
import { SM_EditCustomReportDataCustomer } from './Customer/SagaEditCustomReportDataCustomer';
import { SM_EditCustomerProduct } from './CustomerProduct/SagaEditCustomerProduct';
import { SM_EditGrupList } from './Manifest/Scan/SagaEditGroup';
import { SM_EditSubmitCustomer } from './Customer/SagaEditCustomer';
import { SM_ExportAirWaybillPdf } from './DeliveryStatus/SagaExportDeliveryStatusPdf';
import { SM_GetCity } from './Address/SagaCity';
import { SM_GetCountScanned } from './Manifest/Scan/SagaCountScanManifest';
import { SM_GetDataDeliveryFilter } from './DeliveryFilter/SagaTableDeliveryFilter';
import { SM_GetDataDeliveryFilterCount } from './DeliveryFilter/SagaCountDelivery';
import { SM_GetDataTableComplaint } from './Complaint/SagaTableComplaint';
import { SM_GetDataTableHoliday } from './Holiday/SagaTableHoliday';
import { SM_GetDetailCustomer } from './Customer/SagaDetailCustomer';
import { SM_GetDetailCustomerActivation } from './Customer/Voip/SagaGetDetailCustomerActivation';
import { SM_GetDetailCustomerProduct } from './CustomerProduct/SagaDetailCustomerProduct';
import { SM_GetGroup } from './Manifest/Scan/SagaGetGroup';
import { SM_GetListActivationServerVoip } from './Customer/Voip/SagaGetListActivationServer';
import { SM_GetListCustomCsvCustomer } from './Customer/SagaListCustomCsvCustomer';
import { SM_GetListCustomer } from './Customer/SagaListCustomer';
import { SM_GetListCustomerCustomerFormat } from './Customer/SagaListCustomerReportFormat';
import { SM_GetProvince } from './Address/SagaProvince';
import { SM_GetSubDistrict } from './Address/SagaSubDistrict';
import { SM_GetTableCustomReportDataCustomer } from './Customer/SagaTableCustomReportDataCustomer';
import { SM_GetTableCustomerProduct } from './CustomerProduct/SagaTableCustomerProduct';
import { SM_UploadSoftcopy } from './Delivery/Resend/UploadSoftcopy/SagaUploadSoftcopy';
import { SM_addAdminPosko } from './ManageAccount/AdminBranch/SagaAddAdminBranch';
import { SM_addCourier } from './ManageAccount/Courier/SagaAddCourier';
import { SM_addCustomerAccount } from './ManageAccount/Customer/SagaAddCustomerAccount';
import { SM_addCustomerConfigReport } from './Customer/SagaAddConfigReport';
import { SM_addCustomerFiture } from './Customer/SagaAddCustomerFiture';
import { SM_addHolidaySynchroniz } from './Holiday/SagaAddHolidaySynchroniz';
import { SM_addManualHoliday } from './Holiday/SagaAddHoliday';
import { SM_addResendDelivery } from './Attempt/SagaAddResendDelivery';
import { SM_addServerVoip } from './Voip/ServerVoip/SagaAddServerVoip';
import { SM_addTicket } from './Complaint/SagaAddComplaint';
import { SM_addTicketDP } from './DeliveryPriority/SagaAddTicket';
import { SM_checkServerVoip } from './Voip/ServerVoip/SagaCheckServerVoip';
import { SM_customerConfigReport } from './Customer/SagaDetailConfigReport';
import { SM_customerFiture } from './Customer/SagaCustomerFiture';
import { SM_deleteCustomCsv } from './Customer/SagaDeleteCustomCsv';
import { SM_deleteCustomReportDataCustomer } from './Customer/SagaDeleteCustomReportDataCustomer';
import { SM_deleteStatus } from './Customer/SagaDeleteCustomStatus';
import { SM_deleteWaybillRestoreData } from './RestoreData/SagaDeleteWaybillRestoreData';
import { SM_detailAdminBranch } from './ManageAccount/AdminBranch/SagaDetailAdminBranch';
import { SM_detailCourier } from './ManageAccount/Courier/SagaDetailCourier';
import { SM_detailCustomStatus } from './Customer/SagaDetailCustomStatus';
import { SM_detailEditConfigReport } from './Customer/SagaDetailEditConfigReport';
import { SM_detailProfile } from './Profile/SagaDetailProfile';
import { SM_downloadBastReport } from './Report/Bast/SagaDownloadBast';
import { SM_downloadByWaybill } from './Delivery/PrintWaybill/SagaDownloadByWaybill';
import { SM_downloadCourierBonusReport } from './Report/CourierBonus/SagaDownloadCourierBonusReport';
import { SM_downloadCourierManifest } from './Manifest/Courier/SagaDownloadCourierManifest';
import { SM_downloadCustomCsvCustomer } from './Customer/SagaDownloadSampleCustomCsv';
import { SM_downloadCustomerManifest } from './Manifest/Customer/SagaDownloadCustomerManifest';
import { SM_downloadCustomerReport } from './Report/Customer/SagaDownloadCustomerReport';
import { SM_downloadDataActive } from './Voip/DataActive/SagaDownloadDataActive';
import { SM_downloadDeliveryData } from './DeliveryFilter/SagaDownloadDeliveryData';
import { SM_downloadDeliveryReport } from './Report/DeliveryReport/SagaDeliveryReport';
import { SM_downloadMisReport } from './Report/Mis/SagaDownloadMisReport';
import { SM_downloadMultipleWaybill } from './Delivery/PrintWaybill/SagaDownloadMultipleWaybill';
import { SM_downloadRestoreData } from './RestoreData/SagaDownloadRestoreData';
import { SM_downloadSampleManualProcessVendor } from './ManualProcess/Vendor/SagaDownloadSampleManualProcessVendor';
import { SM_downloadSelectWaybill } from './Delivery/PrintWaybill/SagaDownloadSelectWaybill';
import { SM_downloadTemplate } from './Delivery/PrintWaybill/SagaDownloadTemplate';
import { SM_downloadTemplateEmergencyUpload } from './ManualProcess/Emergency/SagaTemplateEmergencyUpload';
import { SM_downloadTicket } from './Complaint/SagaDownloadComplaintTicket';
import { SM_downloadValidateReport } from './Report/Validate/SagaDownloadValidateReport';
import { SM_editCustomerAccount } from './ManageAccount/Customer/SagaEditCustomerAccount';
import { SM_editCustomerFiture } from './Customer/SagaEditCustomerFiture';
import { SM_editHoliday } from './Holiday/SagaEditHoliday';
import { SM_editProfile } from './Profile/SagaEditProfile';
import { SM_editResetAccountPassword } from './ResetPassword/SagaResetAccountPassword';
import { SM_editResetPassword } from './ResetPassword/SagaResetPassword';
import { SM_editServerVoip } from './Voip/ServerVoip/SagaEditServerVoip';
import { SM_errorHandling } from './ErrorHandling/SagaErrorHandling';
import { SM_generateAdminCode } from './ManageAccount/AdminBranch/SagaGenerateAdminCode';
import { SM_generateCourierCode } from './ManageAccount/Courier/SagaGenerateCourierCode';
import { SM_generateTicketCode } from './Complaint/SagaGenerateTicketCode';
import { SM_generateUsernameCustomer } from './ManageAccount/Customer/SagaGenerateCustomerAccount';
import { SM_getBranchManualProcessVendor } from './ManualProcess/Vendor/SagaGetBranchManualProcessVendor';
import { SM_getCustomerAccountDataTable } from './ManageAccount/Customer/SagaTableCustomerAccount';
import { SM_getDataCourierManifest } from './Manifest/Courier/SagaGetDataCourierManifest';
import { SM_getDataCustomerrManifest } from './Manifest/Customer/SagaGetDataCustomerManifest';
import { SM_getDataPrintWaybill } from './Delivery/PrintWaybill/SagaGetDataPrintWaybill';
import { SM_getDataScan } from './Delivery/Document/SagaGetDataScan';
import { SM_getDataTableRestoreData } from './RestoreData/SagaTableRestoreData';
import { SM_getDeliveryBranch } from './Branch/SagaDeliveryBranch';
import { SM_getDetailCustomerAccount } from './ManageAccount/Customer/SagaDetailAccountCustomer';
import { SM_getDetailHoliday } from './Holiday/SagaDetailHoliday';
import { SM_getDetailServerVoip } from './Voip/ServerVoip/SagaDetailServerVoip';
import { SM_getDetailTicket } from './Complaint/SagaGetDetailTicket';
import { SM_getDetailTicketDP } from './DeliveryPriority/SagaDetailTicket';
import { SM_getDownloadDigitalWaybill } from './Report/DigitalWaybill/SagaDownloaDigitalWaybill';
import { SM_getDownloadInvoice } from './Report/Invoice/SagaDownloadInvoice';
import { SM_getHolidaySynchroniz } from './Holiday/SagaGetHolidaySynchroniz';
import { SM_getPickupStatusAction } from '../../module/Pickup/PickupStatus/Saga/SagaPickupStatus';
import { SM_getProgressCourier } from './Beranda/SagaGetProgressCourier';
import { SM_getSearchDataDeliveryAction } from './../../module/DeliveryData/Saga/SagaDataDelivery';
import { SM_restoreRestoreData } from './RestoreData/SagaRestoreRestoreData';
import { SM_role } from './Role';
import { SM_statusCustomerAccount } from './ManageAccount/Customer/SagaStatusAccountCustomer';
import { SM_submitAddActivation } from './Customer/Voip/SagaAddActivationVoip';
import { SM_submitChangeCustomToDefault } from './Customer/SagaChangeStatusToDefault';
import { SM_submitDeliveryDate } from './Delivery/PrintWaybill/SagaSubmitDeliveryDate';
import { SM_submitEditAdmin } from './ManageAccount/AdminBranch/SagaEditAdminBranch';
import { SM_submitEditComplaint } from './Complaint/SagaSubmitEditComplaint';
import { SM_submitEditConfigReport } from './Customer/SagaEditConfigReport';
import { SM_submitEditCourier } from './ManageAccount/Courier/SagaEditCourier';
import { SM_submitEditCustomStatus } from './Customer/SagaEditCustomStatus';
import { SM_submitManualProcess } from './ManualProcess/SagaSubmitManualProcess';
import { SM_submitManualProcessVendor } from './ManualProcess/Vendor/SagaSubmitManualProcessVendor';
import { SM_synchronizeDataActive } from './Voip/DataActive/SagaSynchronizeDataActive';
import { SM_updateTicket } from './Complaint/SagaUpdateTicket';

export default function* () {
  yield all([
    fork(SM_login),
    fork(SM_loginTokenRefrash),
    fork(SM_loginTokenRefreshTemporary),
    fork(SM_GetDataTableBranch),
    fork(SM_TableAdmin),
    fork(SM_GetDetailBranch),
    fork(SM_GetNameAdminBranch),
    fork(SM_getGroupNameBranchList),
    fork(SM_submitGroupNameBranch),
    fork(SM_submitEditGroupNameBranch),
    fork(SM_GetDetailVillage),
    fork(SM_addAdminPosko),
    fork(SM_GetProvince),
    fork(SM_GetCity),
    fork(SM_GetSubDistrict),
    fork(SM_GetVillage),
    fork(SM_DetailVillage),
    fork(SM_GetAllAdminBranch),
    fork(SM_AddBranch),
    fork(SM_AddAccountAdmin),
    fork(SM_EditSubmitBranch),
    fork(SM_DetailEditBranch),
    fork(SM_detailAdminBranch),
    fork(SM_submitEditAdmin),
    fork(SM_GetDataTableCustomer),
    fork(SM_GetDetailCustomer),
    fork(SM_AddCustomer),
    fork(SM_EditSubmitCustomer),
    fork(SM_GetTableCustomerProduct),
    fork(SM_AddCustomeProduct),
    fork(SM_EditCustomerProduct),
    fork(SM_GetDetailCustomerProduct),
    fork(SM_CourierTable),
    fork(SM_generateCourierCode),
    fork(SM_getAllBranch),
    fork(SM_getBranchGroupList),
    fork(SM_addCourier),
    fork(SM_detailCourier),
    fork(SM_CourierTableBranch),
    fork(SM_submitEditCourier),
    fork(SM_GetListCustomer),
    fork(SM_GetListCustomerProduct),
    fork(SM_GetListCustomerProductNoType),
    fork(SM_UploadCsv),
    fork(SM_getDataStatusUpload),
    fork(SM_getDetailDocumentStatusUpload),
    fork(SM_changeNextUploadCsv),
    fork(SM_DownloadResultUploadCsv),
    fork(SM_GetTotalSacn),
    fork(SM_getDataScan),
    fork(SM_DownloadWaybill),
    fork(SM_ScanByBarcode),
    fork(SM_GetCourierCodeAndName),
    fork(SM_GetListScan),
    fork(SM_GetListUnScan),
    fork(SM_GetTotalSacn),
    fork(SM_generateAdminCode),
    fork(SM_getDeliveryStatus),
    fork(SM_GetDefaultDataStatus),
    fork(SM_GetCustomDataStatus),
    fork(SM_AddCustomStatus),
    fork(SM_submitEditCustomStatus),
    fork(SM_detailCustomStatus),
    fork(SM_deleteStatus),
    fork(SM_customerFiture),
    fork(SM_addCustomerFiture),
    fork(SM_editCustomerFiture),
    fork(SM_AddCustomCsvCustomer),
    fork(SM_DetailCustomCsvCustomer),
    fork(SM_EditCustomCsvCustomer),
    fork(SM_customerConfigReport),
    fork(SM_addCustomerConfigReport),
    fork(SM_detailEditConfigReport),
    fork(SM_submitEditConfigReport),
    fork(SM_GetTableCustomReportDataCustomer),
    fork(SM_GetListColumnMasterData),
    fork(SM_GetListColumnHistory),
    fork(SM_AddCustomReportDataCustomer),
    fork(SM_DetailCustomReportDataCustomer),
    fork(SM_EditCustomReportDataCustomer),
    fork(SM_addResendDelivery),
    fork(SM_submitChangeCustomToDefault),
    fork(SM_submitManualProcess),
    fork(SM_getIndividuLocationCourier),
    fork(SM_getAllLocationCourier),
    fork(SM_downloadCourierReport),
    fork(SM_getCourierMultipleBranch),
    fork(SM_getDeliveryBranch),
    fork(SM_GetDataTableHoliday),
    fork(SM_addManualHoliday),
    fork(SM_getDetailHoliday),
    fork(SM_editHoliday),
    fork(SM_getTemplateHoliday),
    fork(SM_uploadCsvHoliday),
    fork(SM_getHolidaySynchroniz),
    fork(SM_addHolidaySynchroniz),
    fork(SM_getDownloadInvoice),
    fork(SM_DetailCsvList),
    fork(SM_getCustomerAccountDataTable),
    fork(SM_generateUsernameCustomer),
    fork(SM_getDetailCustomerAccount),
    fork(SM_addCustomerAccount),
    fork(SM_editCustomerAccount),
    fork(SM_statusCustomerAccount),
    fork(SM_GetListCustomCsvCustomer),
    fork(SM_GetDataDeliveryFilter),
    fork(SM_GetDataDeliveryFilterCount),
    fork(SM_getSearchDataDeliveryAction),
    fork(SM_generateTicketCode),
    fork(SM_addTicket),
    fork(SM_GetDataTableComplaint),
    fork(SM_getDetailTicket),
    fork(SM_updateTicket),
    fork(SM_downloadCustomerReport),
    fork(SM_GetListCustomerCustomerFormat),
    fork(SM_getFocusDeliveryStatus),
    fork(SM_ExportAirWaybillPdf),
    fork(SM_downloadTicket),
    fork(SM_getDeliveryStatusBarcode),
    fork(SM_getDeliveryStatusFilter),
    fork(SM_detailProfile),
    fork(SM_downloadDeliveryData),
    fork(SM_editProfile),
    fork(SM_editResetPassword),
    fork(SM_editResetAccountPassword),
    fork(SM_downloadCourierBonusReport),
    fork(SM_getDataPrintWaybill),
    fork(SM_downloadTemplate),
    fork(SM_downloadByWaybill),
    fork(SM_downloadMultipleWaybill),
    fork(SM_downloadMisReport),
    fork(SM_downloadSelectWaybill),
    fork(SM_GetGroup),
    fork(SM_AddGroupName),
    fork(SM_EditGrupList),
    fork(SM_getDataCourierManifest),
    fork(SM_GetListScanManifest),
    fork(SM_SearchDataScanManifest),
    fork(SM_GetDuplicateBarcodeScanManifest),
    fork(SM_ScanManifestMultipleBarcode),
    fork(SM_ScanManifest),
    fork(SM_GetCountScanned),
    fork(SM_DeleteScanManifest),
    fork(SM_downloadCourierManifest),
    fork(SM_getDataCustomerrManifest),
    fork(SM_downloadCustomerManifest),
    fork(SM_role),
    fork(SM_getProgressCourier),
    fork(SM_getBranchManualProcessVendor),
    fork(SM_downloadSampleManualProcessVendor),
    fork(SM_submitManualProcessVendor),
    fork(SM_SubmitWaybill),
    fork(SM_SubmitWorBSA),
    fork(SM_downloadValidateReport),
    fork(SM_getDownloadDigitalWaybill),
    fork(SM_UploadSoftcopy),
    fork(SM_GetDataTableDeliveryPriority),
    fork(SM_FilterDataTableDeliveryPriority),
    fork(SM_getDetailTicketDP),
    fork(SM_addTicketDP),
    fork(SM_deleteCustomReportDataCustomer),
    fork(SM_downloadCustomCsvCustomer),
    fork(SM_submitEditComplaint),
    fork(SM_addServerVoip),
    fork(SM_getDataTableServerVoip),
    fork(SM_searchDataTableServerVoip),
    fork(SM_checkServerVoip),
    fork(SM_editServerVoip),
    fork(SM_getDetailServerVoip),
    fork(SM_getDataTableDataActive),
    fork(SM_searchDataTableDataActive),
    fork(SM_downloadDataActive),
    fork(SM_synchronizeDataActive),
    fork(SM_deleteRestoreData),
    fork(SM_DownloadResultDeleteFileAction),
    fork(SM_downloadRestoreData),
    fork(SM_restoreRestoreData),
    fork(SM_getDataTableRestoreData),
    fork(SM_GetListActivationServerVoip),
    fork(SM_GetDetailCustomerActivation),
    fork(SM_submitAddActivation),
    fork(SM_errorHandling),
    fork(SM_submitDeliveryDate),
    fork(SM_GetDataWaybill),
    fork(SM_GenerateNewWaybill),
    fork(SM_GetStatusDeliveryReturnCustomerReport),
    fork(SM_GetStatusDeliverySuccessCustomerReport),
    fork(SM_deleteCustomCsv),
    fork(SM_downloadTemplateEmergencyUpload),
    fork(SM_SubmiteEmergencyScan),
    fork(SM_SubmiteEmergencyUpload),
    fork(SM_GetListColumnSecHistory),
    fork(SM_GetListColumnSecMasterData),
    fork(SM_deleteWaybillRestoreData),
    fork(SM_DownloadResultCountManifest),
    fork(SM_getPickUpListAction),
    fork(SM_getComplaintListAction),
    fork(SM_submitSearchComplaintAction),
    fork(SM_getFormatCsvListAction),
    fork(SM_getCustomerProductListAction),
    fork(SM_submitUploadCallCenterAction),
    fork(SM_downloadResultUploadCallCenterAction),
    fork(SM_getLevelListAction),
    fork(SM_getModuleListAction),
    fork(SM_submitLevelAction),
    fork(SM_getCourierListAction),
    fork(SM_getAllCustomerAction),
    fork(SM_getAllBranchAction),
    fork(SM_getLogActivityAction),
    fork(SM_getUserAction),
    fork(SM_getLogActivityListAction),
    fork(SM_logActivityLogoutAction),
    fork(SM_downloadLogActivityDataAction),
    fork(SM_getInternalBranch),
    fork(SM_submitChangeCourierInvestigateAction),
    fork(SM_getCourierComplaintAction),
    fork(SM_downloadComplaintAction),
    fork(SM_submitProcessToCustomerConfirmAction),
    fork(SM_getDetailDataComplaintAction),
    fork(SM_submitInQueueToProcessAction),
    fork(SM_getWaybillComplaintAction),
    fork(SM_getDetailPickUpListAction),
    fork(SM_submitDocComplaintAction),
    fork(SM_submitAssignCourierAction),
    fork(SM_uploadCsvPickupAction),
    fork(SM_getCustProductListAction),
    fork(SM_getPickupDataAction),
    fork(SM_getPickupStatusAction),
    fork(SM_getCountingStatusPickupAction),
    fork(SM_getCourierPickupDataAction),
    fork(SM_downloadPickupDataAction),
    fork(SM_DownloadTemplatePickupFileAction),
    fork(SM_DownloadResultPickupFileAction),
    fork(SM_getCourierBranchListAction),
    fork(SM_getWaybillUpdateAddress),
    fork(SM_SubmitUpdateAddressUpload),
    fork(SM_GetListCustomerProductUpdateAddress),
    fork(SM_downloadResultUploadAddress),
    fork(SM_GetListTemplateUpload),
    fork(SM_submitUpdateAddress),
    fork(SM_getDownloadDigitalWaybillByUpload),
    fork(SM_downloadDigitalWaybillTemplate),
    fork(SM_getCustProductListComplaintReportAction),
    fork(SM_downloadComplaintReportAction),
    fork(SM_getCountDigitalWaybill),
    fork(SM_getErrorDigitalWaybill),
    fork(SM_downloadBastReport),
    fork(SM_submitGroupNameProduct),
    fork(SM_getGroupNameList),
    fork(SM_submitEditGroupName),
    fork(SM_downloadDeliveryReport),
    fork(SM_getDeleteDigitalWaybill),
    fork(SM_GetCountEmergency),
    fork(SM_GetDeleteEmergency),
    fork(SM_DownloadResultEmergency),
    fork(SM_SearchTableCustomer),
    fork(SM_SearchTableBranch),
    fork(SM_SearchTableAdminBranch),
    fork(SM_SearchTableCourier),
  ]);
}
