import * as ActionAuth from '../../../core/action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import axios from 'axios';
import { message } from 'antd';

export function* S_ExportAirWaybillPdf(action: any) {
  const state = yield select();
  const Waybill = state.DeliveryStatus.WaybillForm;

  const key = 'download';
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = `${Waybill}.pdf`;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }
  const token = state.Auth.Token;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_URL +
        `/Delivery/DownloadAirWaybillPdf?waybill=${Waybill}`,
      {},
      {
        responseType: 'arraybuffer',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);
          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 500) {
      message.error({
        content: 'Download Gagal',
        key,
        duration: 3,
      });
    }
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_ExportAirWaybillPdf() {
  yield takeLatest('GET_EXPORT_DELIVERY_STATUS', S_ExportAirWaybillPdf);
}
