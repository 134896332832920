import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';

interface IProps {
  handleDetailCourier: any;
  handleEditCourier: any;
  handleResetPassword: any;
  typeButton: any;
}
export default class ButtonDetailBranchComponent extends Component<IProps> {
  render() {
    return this.props.typeButton === 'reset' ? (
      <ButtonMaterial
        disabled={''}
        handleSubmit={this.props.handleResetPassword}
        teksButton={''}
        size={''}
        shape={''}
        className={'btnResetPassword'}
        type={''}
        icon={'lock'}
        style={{}}
      />
    ) : this.props.typeButton === 'detail' ? (
      <ButtonMaterial
        disabled={''}
        handleSubmit={this.props.handleDetailCourier}
        teksButton={''}
        size={''}
        shape={''}
        className={'btnDetail'}
        type={''}
        icon={'eye'}
        style={{}}
      />
    ) : (
      <ButtonMaterial
        disabled={''}
        handleSubmit={this.props.handleEditCourier}
        teksButton={''}
        size={''}
        shape={''}
        className={'btnEdit'}
        type={''}
        icon={'edit'}
        style={{}}
      />
    );
  }
}
