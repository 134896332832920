import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as FormCourier from '../../../action/ManageAccount/Courier/ActionFormDetailCourier';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_generateCourierCode(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const AreaCodeId = state.Courier.FormCourier.AreaCodeId;
  try {
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Courier/generate/' + AreaCodeId,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(FormCourier.changeCourierCode(data.code));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_generateCourierCode() {
  yield takeLatest('GENERATE_COURIER_CODE', S_generateCourierCode);
}
