import instance from 'config/interceptor';

export const getDataTableBagging = async params => {
  try {
    const {
      page,
      BaggingTypes,
      CreatedBaggingTime,
      BaggingStatusDocument,
      OriginalBranch,
      DestinationBranch,
      BarcodeBagging,
    } = params;
    let link = `/BaggingDocument/${page}/10?`;

    if (BaggingTypes?.length > 0) {
      const queryParam = `BaggingTypes=${BaggingTypes.join('&BaggingTypes=')}`;
      link = link.concat(`&${queryParam}`);
    }

    if (CreatedBaggingTime)
      link = link.concat(
        `&CreatedBaggingTime=${CreatedBaggingTime.format('DDMMYYYY')}`
      );

    if (BaggingStatusDocument?.length > 0) {
      const queryParam = `BaggingStatusDocument=${BaggingStatusDocument.join(
        '&BaggingStatusDocument='
      )}`;
      link = link.concat(`&${queryParam}`);
    }

    if (OriginalBranch) link = link.concat(`&OriginalBranch=${OriginalBranch}`);
    if (DestinationBranch)
      link = link.concat(`&DestinationBranch=${DestinationBranch}`);
    if (BarcodeBagging) link = link.concat(`&BarcodeBagging=${BarcodeBagging}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const getCountingTableBagging = async params => {
  try {
    const {
      BaggingTypes,
      CreatedBaggingTime,
      BaggingStatusDocument,
      OriginalBranch,
      DestinationBranch,
      BarcodeBagging,
    } = params;

    let link = `/BaggingDocument/Counting?`;

    if (BaggingTypes?.length > 0) {
      const queryParam = `BaggingTypes=${BaggingTypes.join('&BaggingTypes=')}`;
      link = link.concat(`&${queryParam}`);
    }
    if (CreatedBaggingTime)
      link = link.concat(
        `&CreatedBaggingTime=${CreatedBaggingTime.format('DDMMYYYY')}`
      );
    if (BaggingStatusDocument?.length > 0) {
      const queryParam = `BaggingStatusDocument=${BaggingStatusDocument.join(
        '&BaggingStatusDocument='
      )}`;
      link = link.concat(`&${queryParam}`);
    }
    if (OriginalBranch) link = link.concat(`&OriginalBranch=${OriginalBranch}`);
    if (DestinationBranch)
      link = link.concat(`&DestinationBranch=${DestinationBranch}`);
    if (BarcodeBagging) link = link.concat(`&BarcodeBagging=${BarcodeBagging}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const getBarcodeBag = async branchForm => {
  try {
    let link = `/BaggingDocument/${branchForm}`;

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const getSingleBaggingDocument = async id => {
  try {
    let link = `/BaggingDocument/Detail/${id}`;

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const deleteBaggingDocument = async id => {
  try {
    let link = `/BaggingDocument/${id}`;

    return await instance.delete(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const postBaggingDocument = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/BaggingDocument`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const putBaggingDocument = async data => {
  try {
    return await instance.put(
      process.env.REACT_APP_URL + `/BaggingDocument`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const getScanTableBagging = async params => {
  try {
    const { page, idBagging } = params;
    let link = `/BaggingDocument/Scan/${idBagging}/${page}/5`;

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const putScanBagging = async data => {
  try {
    return await instance.put(
      process.env.REACT_APP_URL + `/BaggingDocument/Scan/Waybill`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const deleteScanBagging = async data => {
  const { waybill, idBagging } = data;
  try {
    return await instance.delete(
      process.env.REACT_APP_URL +
        `/BaggingDocument/Scan/delete/${waybill}/${idBagging}`
    );
  } catch (error) {
    return error;
  }
};

export const downloadBaggingDocument = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/BaggingDocument/Download/Barcode`,
      data,
      { responseType: 'arraybuffer' }
    );
  } catch (error) {
    return error;
  }
};

export const getDataTableReceiveBagging = async params => {
  try {
    const { page, destinationBranch, barcode } = params;
    let link = `/BaggingDocument/Received/Scan/${destinationBranch}/${page}/10`;
    if (barcode) link = link.concat(`?barcodeBagging=${barcode}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const getDataCountingReceiveBagging = async destinationBranch => {
  try {
    let link = `/BaggingDocument/Received/Scan/Counting/${destinationBranch}`;

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const postReceiveBaggingDocument = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/BaggingDocument/Received/Scan`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const downloadBaggingDocumentList = async (
  data: { id: number; barcodeBag: string },
  onDownloadProgress: any
) => {
  try {
    const response = await instance.get(
      process.env.REACT_APP_URL + `/BaggingDocument/Download/${data.id}`,

      {
        responseType: 'arraybuffer',
        onDownloadProgress,
      }
    );

    return {
      ...response,
      barcodeBag: data.barcodeBag,
    };
  } catch (error) {
    return error;
  }
};
