import * as ActionConfigReport from '../../action/Customer/ActionDetailConfigReport';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_submitEditConfigReport() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const CustomerNameReport =
    state.Customer.FormCustomerConfigReport.CustomerNameReport;
  const FormatReport = state.Customer.FormCustomerConfigReport.FormatReport;
  const DelimitedReport =
    state.Customer.FormCustomerConfigReport.DelimitedReport;
  const PeriodeReportelimitedReport =
    state.Customer.FormCustomerConfigReport.PeriodeReport;
  const WithoutHeader = state.Customer.FormCustomerConfigReport.WithoutHeader;
  const ShowInPortal = state.Customer.FormCustomerConfigReport.ShowInPortal;
  const IdCustomerReport =
    state.Customer.FormCustomerConfigReport.IdCustomerReport;
  const IdCustomCsv = state.Customer.FormCustomerConfigReport.SettingReport;
  const lastAccess = state.LastAccess.Fiture;
  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.put,
      process.env.REACT_APP_URL +
        '/Setting/CustomReportFile/' +
        IdCustomerReport,
      {
        id: IdCustomerReport,
        name: CustomerNameReport,
        format: FormatReport,
        delimited: DelimitedReport,
        periode: PeriodeReportelimitedReport,
        customCsvId: IdCustomCsv,
        headerHide: WithoutHeader,
        reportPortal: ShowInPortal,
      },
      { headers: config }
    );

    yield put(ActionConfigReport.clearListCustomerReport());
    yield put(toggle.clearJenisToggle());
    if (lastAccess === 'DetailConfigReport') {
      yield put(ActionConfigReport.changeDetailEditCustomerConfigReport());
    } else {
      yield put(ActionConfigReport.changeClearCustomerConfigReport());
    }
    yield put(ActionConfigReport.changeDetailCustomerConfigReport());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>Berhasil mengubah format laporan</p>
      ),

      onOk() {},
    });
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      const propsError = ErrorMassage('Ubah format laporan');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}
export function* SM_submitEditConfigReport() {
  yield takeLatest('SUBMIT_EDIT_CONFIG_REPORT', S_submitEditConfigReport);
}
