import React from 'react';
import moment from 'moment';
import ButtonMaterial from '../../../materials/ButtonMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';
import { Form, DatePicker, Switch, Checkbox, Button } from 'antd';
import ModalMaterial from '../../../materials/ModalMaterial';
import FormCsvHoliday from '../Container/FormCsvHolidayContainer';
import FormSynchronizedWeeklyContainer from '../Container/FormSynchronizedWeeklyContainer';
import { Form as FormFinal, Field } from 'react-final-form';
import FinalFormInput from '../../../materials/FinalFormInput';

interface IProps {
  visible: any;
  isLoading: any;
  isLoadingUpdate: any;
  typeToggle: any;
  DateHoliday: any;
  HolidayEvent: any;
  IdHoliday: any;
  ValueSwitch: any;
  SyncronizeWeekly: any;
  ActiveHoliday: any;
  handlePutHolidayDate: any;
  handleCloseModal: any;
  handlePutHolidayEvent: any;
  handleSwitch: any;
  handleAddManualHoliday: any;
  handleEditHoliday: any;
  handleValidateHolidayDate: any;
  handlePutActivateHoliday: any;
  handleSynchronizeWeekly: any;
  ValidateHolidayDate: any;
  ValidateHolidayEvent: any;
  ValidateButtonSave: any;
  fileNameCsv?: any;
  fileCsv?: any;
  handleSetNameCsv?: any;
  handleSetFileCsv?: any;
  submitHolidayCsv?: any;
  isLoadingUploadCsv?: any;
}

export default function FormManualHolidayComponent(props: IProps) {
  const handleSubmit = async value => {
    props.typeToggle === 'AddHoliday'
      ? props.handleAddManualHoliday(value)
      : props.handleEditHoliday({
          ...value,
          id: props.IdHoliday,
          isDisabled: props.ActiveHoliday === true ? false : true,
          date: moment(value.date)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .add(7, 'hours'),
        });
  };
  return (
    <div>
      <ModalMaterial
        className={''}
        width={'40%'}
        style={{ paddingTop: '20px' }}
        teksTitle={
          props.typeToggle === 'EditHoliday'
            ? 'UBAH HARI LIBUR'
            : 'TAMBAHKAN HARI LIBUR'
        }
        visible={props.visible}
        onCancel={props.handleCloseModal}
        content={
          <SpinMaterial
            spinning={props.isLoading}
            style={{}}
            size={'large'}
            content={
              <div>
                <div
                  style={{
                    paddingRight: '35px',
                    paddingLeft: '35px',
                    paddingTop: '15px',
                  }}
                >
                  {props.SyncronizeWeekly === true ? null : props.typeToggle ===
                    'AddHoliday' ? (
                    <div style={{ paddingBottom: '20px' }}>
                      <Switch
                        checked={props.ValueSwitch}
                        onChange={props.handleSwitch}
                      />
                      <span
                        style={{ paddingLeft: '15px', fontStyle: 'italic' }}
                      >
                        Tambahkan dengan template
                      </span>
                    </div>
                  ) : null}
                  <div>
                    {props.SyncronizeWeekly === true ? (
                      <FormSynchronizedWeeklyContainer />
                    ) : props.ValueSwitch === true ? (
                      <FormCsvHoliday
                        fileNameCsv={props.fileNameCsv}
                        fileCsv={props.fileCsv}
                        handleSetNameCsv={props.handleSetNameCsv}
                        handleSetFileCsv={props.handleSetFileCsv}
                        submitHolidayCsv={props.submitHolidayCsv}
                      />
                    ) : (
                      <FormFinal
                        onSubmit={handleSubmit}
                        initialValues={{
                          date: props.DateHoliday || null,
                          eventName: props.HolidayEvent || '',
                        }}
                        validate={values => {
                          const errors = {} as any;
                          if (!values.date) {
                            errors.date = 'Tanggal tidak boleh kosong!!';
                          }
                          if (!values.eventName) {
                            errors.eventName =
                              'Event libur tidak boleh kosong!!';
                          }
                          return errors;
                        }}
                        render={({ handleSubmit, submitting, valid, form }) => (
                          <form>
                            <div>
                              <span>
                                <b>Tanggal</b>
                              </span>
                              <div>
                                <Field
                                  name="date"
                                  render={({ input, meta }) => (
                                    <Form.Item
                                      validateStatus={
                                        meta.error && meta.touched
                                          ? 'error'
                                          : 'validating'
                                      }
                                      help={
                                        meta.error && meta.touched && meta.error
                                      }
                                    >
                                      <DatePicker
                                        value={
                                          !input.value
                                            ? input.value
                                            : moment(input.value)
                                        }
                                        onChange={input.onChange}
                                        format={'DD-MM-YYYY'}
                                        style={{
                                          width: '100%',
                                          backgroundColor: '#F5F5F5',
                                          fontWeight: 'bold',
                                        }}
                                        placeholder={'Masukan tanggal'}
                                      />
                                    </Form.Item>
                                  )}
                                />
                              </div>
                            </div>
                            <div>
                              <span>
                                <b>Event Libur</b>
                              </span>
                              <FinalFormInput
                                name={'eventName'}
                                placeholder={'Masukan event libur'}
                                style={{ borderRadius: '10px' }}
                                validateType
                              />
                            </div>
                            {props.typeToggle === 'EditHoliday' ? (
                              <div
                                style={{
                                  paddingTop: '9px',
                                  paddingBottom: '20px',
                                }}
                              >
                                <Checkbox
                                  checked={props.ActiveHoliday}
                                  onChange={props.handlePutActivateHoliday}
                                />
                                <span style={{ paddingLeft: '10px' }}>
                                  Ceklis untuk aktifkan status libur
                                </span>
                              </div>
                            ) : null}
                            {props.typeToggle === 'EditHoliday' ? null : (
                              <div>
                                <span
                                  style={{
                                    fontStyle: 'italic',
                                    textDecoration: 'underline',
                                  }}
                                >
                                  <Button
                                    type="link"
                                    onClick={props.handleSynchronizeWeekly}
                                  >
                                    Sinkron akhir pekan tahunan
                                  </Button>
                                </span>
                              </div>
                            )}
                            <div style={{ textAlign: 'right' }}>
                              <ButtonMaterial
                                disabled={submitting || !valid}
                                teksButton={'Simpan'}
                                size={''}
                                shape={'round'}
                                className={''}
                                type={'primary'}
                                style={
                                  props.ValidateButtonSave === true
                                    ? { height: '35px' }
                                    : {
                                        colorFont: '#11BEFF',
                                        height: '35px',
                                        background: '#11BEFF',
                                        borderColor: '#11BEFF',
                                      }
                                }
                                icon={''}
                                htmlType="button"
                                handleSubmit={() => {
                                  handleSubmit();
                                  form.reset();
                                }}
                              />
                            </div>
                          </form>
                        )}
                      ></FormFinal>
                    )}
                  </div>
                </div>
              </div>
            }
          />
        }
      />
    </div>
  );
}
