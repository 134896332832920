import * as ActionDeliveryPriority from '../../action/DeliveryPriority/ActionDeliveryPriority';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionPagination from '../../action/Pagination/ActionPagination';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../action/toggle';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_GetDataTableDeliveryPriority() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const PagePagination = state.Pagination.PagePagination;
  const SizeDataPagination = state.Pagination.SizeDataPagination;
  const waybillForm = state.DeliveryPriority.DeliveryPriorityForm.waybillForm;
  const filterType =
    state.DeliveryPriority.Properties.filterType === 'process'
      ? true
      : state.DeliveryPriority.Properties.filterType === 'finished'
      ? false
      : '';

  let apiLinkValue = '';
  const apiLink = () => {
    if (filterType !== '' && !waybillForm === false) {
      apiLinkValue = `?waybill=${waybillForm}&stats=${filterType}`;
    } else if (filterType !== '' && !waybillForm === true) {
      apiLinkValue = `?stats=${filterType}`;
    } else if (filterType === '' && !waybillForm === false) {
      apiLinkValue = `?waybill=${waybillForm}`;
    } else {
      apiLinkValue = '';
    }
  };
  try {
    apiLink();
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/DeliveryUrgent/${PagePagination}/${SizeDataPagination}${apiLinkValue}`,
      { headers: config }
    );

    yield put(ActionDeliveryPriority.setListDeliveryPriority(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      const propsError = ErrorMassage('List Pengiriman Prioritas');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
    yield put(changeLoadingFalse());
  }
}

export function* S_FilterDataTableDeliveryPriority() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const waybillForm = state.DeliveryPriority.DeliveryPriorityForm.waybillForm;
  const PagePagination = state.Pagination.PagePagination;
  const SizeDataPagination = state.Pagination.SizeDataPagination;

  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      waybillForm !== ''
        ? `${process.env.REACT_APP_URL}/DeliveryUrgent/waybill/${waybillForm}`
        : `${process.env.REACT_APP_URL}/DeliveryUrgent/${PagePagination}/${SizeDataPagination}`,
      { headers: config }
    );

    yield put(ActionDeliveryPriority.setListDeliveryPriority(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      const propsError = ErrorMassage('List Pengiriman Prioritas');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
    yield put(changeLoadingFalse());
  }
}

export function* SM_GetDataTableDeliveryPriority() {
  yield takeLatest('GET_DATA_TABLE_DP', S_GetDataTableDeliveryPriority);
}

export function* SM_FilterDataTableDeliveryPriority() {
  yield takeLatest('FILTER_DATA_TABLE_DP', S_FilterDataTableDeliveryPriority);
}
