import instance from 'config/interceptor';

export const getDataTableHoliday = async params => {
  try {
    const { page } = params[1];
    return await instance.get(
      process.env.REACT_APP_URL + `/Holiday/${page}/10`
    );
  } catch (error) {
    return error;
  }
};

export const postHoliday = async data => {
  try {
    return await instance.post(process.env.REACT_APP_URL + `/Holiday`, data);
  } catch (error) {
    return error;
  }
};

export const updateHoliday = async data => {
  try {
    return await instance.put(
      process.env.REACT_APP_URL + `/Holiday/Update`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const uploadHolidayCsv = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/Holiday/upload`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const downloadResultResponse = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/Delivery/upload/downloadResult`,
      data
    );
  } catch (error) {
    return error;
  }
};
