import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionFormPrintWaybill from '../../../action/Delivery/PrintWaybill/ActionFormPrintWaybill';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionToogle from '../../../action/toggle/index';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import React from 'react';
import axios from 'axios';
import moment from 'moment';

export function* S_downloadSelectWaybill(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const key = 'download';
  const FileData = state.PrintWaybill.CheckboxId;
  const CourierId = state.PrintWaybill.FormPrintWaybill.CourierId;
  const NoTelp = state.PrintWaybill.FormPrintWaybill.NoTelp;
  const CodeBrankas = state.PrintWaybill.FormPrintWaybill.CodeBrankas;
  const RadioButton = state.PrintWaybill.FormPrintWaybill.RadioButton;
  const Cycle = moment(state.PrintWaybill.FormPrintWaybill.UploadDate).format(
    'YYYYMMDD'
  );
  const printType = state.PrintWaybill.FormPrintWaybill.WaybillPrintType;
  const SeparatorAddress = state.PrintWaybill.FormPrintWaybill.SeparatorAddress;
  let DateNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  const file_name2 = `Waybill - ${DateNow}.pdf`;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  // const Watermark = state.PrintWaybill.FormPrintWaybill.Watermark;
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = file_name2;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }

  try {
    Modal.info({
      title: <p style={{ color: '#11beff' }}>{<b>Download Data</b>}</p>,
      content: (
        <div>
          <p>Sedang mendownload data, mohon tunggu</p>
        </div>
      ),
    });
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Delivery/DownloadWaybillPdf/Checklist`,
      {
        deliveryFile: FileData,
        printType: printType,
        courierId: CourierId === null ? 0 : CourierId,
        cycle: RadioButton === 'Upload' ? null : Cycle,
        separator: SeparatorAddress,
        NoTelp,
        CodeBrankas,
        watermark: true,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);

          // let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionFormPrintWaybill.ClearAllCheckbox());
    yield put(ActionToogle.changeModal());
    yield put(ActionToogle.clearJenisToggle());
  } catch (error) {
    if (error.response.data.Message === 'Network Error') {
      message.error({
        content: 'Download Gagal',
        key,
        duration: 3,
      });
    } else if (error.response.data.Message === 'No delivery found!') {
      message.error({
        content: 'Waybill tidak tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* SM_downloadSelectWaybill() {
  yield takeLatest('DOWNLOAD_SELECT_WAYBILL', S_downloadSelectWaybill);
}
