import * as ActionListCustomCsvCustomer from '../../../../../core/action/Customer/ActionListCustomCsvCustomer';
import * as ActionListCustomer from '../../../../../core/action/Customer/ActionListCustomer';
import * as ActionListCustomerProduct from '../../../../../core/action/CustomerProduct/ActionListCustomerProduct';
import * as ActionUnggahDocument from '../../../../../core/action/Delivery/Document/ActionFormUnggahDocument';
import * as ActionUploadSoftcopy from '../../../../../core/action/Delivery/UploadSoftcopy/ActionUploadSoftcopy';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import UploadSoftcopyComponent from '../../../../../components/Content/Delivery/Resend/UploadSoftcopy/UploadSoftcopyComponent';
import { connect } from 'react-redux';
import moment from 'moment';

interface IProps {
  isLoading: any;
  actionUploadSoftcopy: any;
  actionUnggahDocument: any;
  actionListCustomer: any;
  actionListCustomerProduct: any;
  actionListFormatCsv: any;
  UploadDate: any;
  DeliveryDate: any;
  Customer: any;
  DocumentType: any;
  CustomerProduct: any;
  FormatCsv: any;
  FileCsv: any;
  ListCustomer: any;
  ListCustomerProduct: any;
  ListFormatCsv: any;
  LoadingSelectCustomer: any;
  LoadingSelectCustomerProduct: any;
  LoadingTypeSelectCustomerProduct: any;
}
interface IState {
  ListCustomCsvState: any;
  IdCustomCsv: any;
  file: any;
  fileName: any;
  base64URL: any;
  ValidateUploadDate: any;
  ValidateDeliveryDate: any;
  ValidateConditionUploadDate: any;
  ValidateCustomer: any;
  ValidateDocumentType: any;
  ValidateCustomerProduct: any;
  ValidateFormatCsv: any;
  defaultCsv: any;
  valueUploadDate: any;
  valueDeliveryDate: any;
  formatFile: any;
}
class UploadSoftcopyContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ListCustomCsvState: [],
      IdCustomCsv: null,
      file: null,
      fileName: '',
      base64URL: null,
      ValidateUploadDate: null,
      ValidateDeliveryDate: null,
      ValidateConditionUploadDate: null,
      ValidateCustomer: null,
      ValidateDocumentType: null,
      ValidateCustomerProduct: null,
      ValidateFormatCsv: null,
      defaultCsv: false,
      valueUploadDate: null,
      valueDeliveryDate: null,
      formatFile: '.csv',
    };
    this.handleValidateUploadDate = this.handleValidateUploadDate.bind(this);
    this.handlePutUploadDate = this.handlePutUploadDate.bind(this);
    this.handlePutCustomer = this.handlePutCustomer.bind(this);
    this.handleResetCustomer = this.handleResetCustomer.bind(this);
    this.handlePutCustomerProduct = this.handlePutCustomerProduct.bind(this);
    this.handleBlurCustomer = this.handleBlurCustomer.bind(this);
    this.handleBlurDocumentType = this.handleBlurDocumentType.bind(this);
    this.handleBlurCustomerProduct = this.handleBlurCustomerProduct.bind(this);
    this.handleGetCustomer = this.handleGetCustomer.bind(this);
    this.handleGetCustomerProduct = this.handleGetCustomerProduct.bind(this);
    this.handleGetFormatCsv = this.handleGetFormatCsv.bind(this);
    this.handlePutDocumentType = this.handlePutDocumentType.bind(this);
    this.handlePutFormatCsv = this.handlePutFormatCsv.bind(this);
    this.handleBlurFormatCsv = this.handleBlurFormatCsv.bind(this);
    this.handleResetFormatCsv = this.handleResetFormatCsv.bind(this);
    this.handleConvert = this.handleConvert.bind(this);
    this.handleResetDocumentType = this.handleResetDocumentType.bind(this);
    this.handleValidateDeliveryDate =
      this.handleValidateDeliveryDate.bind(this);
    this.handleResetCustomerProduct =
      this.handleResetCustomerProduct.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handlePutDeliveryDate = this.handlePutDeliveryDate.bind(this);
    this.handleSubmitUploadSoftcopy =
      this.handleSubmitUploadSoftcopy.bind(this);
  }

  handleValidateUploadDate(event: any) {
    this.setState({
      ValidateConditionUploadDate: event,
    });
    if (this.state.ValidateConditionUploadDate === true) {
      if (
        this.props.UploadDate === null ||
        this.props.UploadDate === undefined ||
        this.props.UploadDate === 'Invalid date'
      ) {
        this.setState({
          ValidateUploadDate: 'error',
        });
      } else {
        this.setState({
          ValidateUploadDate: null,
        });
      }
    }
  }

  handlePutUploadDate(event: any) {
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      this.props.actionUnggahDocument.changeCustomerUploadDate(dateValue);
    } else {
      this.props.actionUnggahDocument.changeCustomerUploadDate(null);
    }
    this.setState({
      valueUploadDate: event,
    });

    if (event === '' || event === null) {
      this.setState({
        ValidateUploadDate: 'error',
      });
    } else {
      this.setState({
        ValidateUploadDate: null,
      });
    }
  }

  handlePutDeliveryDate(event: any) {
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      this.props.actionUnggahDocument.changeCustomerDeliveryDate(dateValue);
    } else {
      this.props.actionUnggahDocument.changeCustomerDeliveryDate(null);
    }
    this.setState({
      valueDeliveryDate: event,
    });
  }

  handleValidateDeliveryDate(event: any) {
    this.setState({
      ValidateConditionUploadDate: event,
    });
    // if (this.state.ValidateConditionUploadDate === true) {
    //   if (
    //     this.props.DeliveryDate === null ||
    //     this.props.DeliveryDate === undefined ||
    //     this.props.DeliveryDate === 'Invalid date'
    //   ) {
    //     this.setState({
    //       ValidateUploadDate: 'error',
    //     });
    //   } else {
    //     this.setState({
    //       ValidateUploadDate: null,
    //     });
    //   }
    // }
  }

  handleGetCustomer() {
    this.props.actionListCustomer.getListCustomer();
  }

  handleResetCustomer() {
    this.props.actionListCustomerProduct.clearListCustomerProduct();
    this.props.actionUnggahDocument.clearCustomerProduct();
    this.props.actionListCustomerProduct.clearListCustomerProduct();
    this.setState({
      ValidateCustomer: null,
      ValidateDocumentType: null,
      ValidateCustomerProduct: null,
      ValidateFormatCsv: null,
      defaultCsv: true,
    });
  }

  handlePutCustomer(event: any) {
    this.props.actionUnggahDocument.clearAllFormUnggahCsv();
    this.props.actionUnggahDocument.changeCustomCsvCustomer(null);
    this.props.actionUnggahDocument.changeCustomerId(event);
    this.props.actionUnggahDocument.clearProductType();
    this.props.actionListFormatCsv.getListCustomCsvCustomer();
    if (event === null) {
      this.setState({
        ValidateCustomer: 'error',
      });
    } else {
      this.setState({
        ValidateCustomer: null,
      });
    }
  }

  handleBlurCustomer() {
    if (this.props.Customer === null) {
      this.setState({
        ValidateCustomer: 'error',
      });
    } else {
      this.setState({
        ValidateCustomer: null,
      });
    }
  }

  handlePutDocumentType(event: any) {
    this.props.actionUnggahDocument.changeProductType(event);
    if (event === null) {
      this.setState({
        ValidateDocumentType: 'error',
      });
    } else {
      this.setState({
        ValidateDocumentType: null,
      });
    }
  }

  handleBlurDocumentType() {
    if (this.props.DocumentType === null) {
      this.setState({
        ValidateDocumentType: 'error',
      });
    } else {
      this.setState({
        ValidateDocumentType: null,
      });
    }
  }
  handleResetDocumentType() {
    this.props.actionUnggahDocument.clearCustomerProduct();
    this.props.actionListCustomerProduct.clearListCustomerProduct();
    this.setState({
      ValidateDocumentType: null,
      ValidateCustomerProduct: null,
    });
  }

  handleGetCustomerProduct() {
    this.props.actionListCustomerProduct.getListCustomerProduct();
  }

  handlePutCustomerProduct(event: any) {
    this.props.actionUnggahDocument.changeCustomerProductId(event);
    if (this.props.CustomerProduct === null) {
      this.setState({
        ValidateCustomerProduct: 'error',
      });
    } else {
      this.setState({
        ValidateCustomerProduct: null,
      });
    }
  }

  handleBlurCustomerProduct() {
    if (this.props.CustomerProduct === null) {
      this.setState({
        ValidateCustomerProduct: 'error',
      });
    } else {
      this.setState({
        ValidateCustomerProduct: null,
      });
    }
  }

  handleResetCustomerProduct() {
    this.setState({
      ValidateCustomerProduct: null,
    });
  }

  handleGetFormatCsv() {
    this.props.actionListFormatCsv.getListCustomCsvCustomer();
  }

  setDefaultCsv = (list: any) =>
    list.find((item: any) => item.defaultStatus === true);

  handlePutFormatCsv(event: any, label: any) {
    const lengthFormatFile = label.props.children.length;
    let result = label.props.children.substring(
      lengthFormatFile - 2,
      lengthFormatFile
    );
    if (result === 'x)') {
      this.setState({
        formatFile: '.xlsx',
      });
    } else if (result === 't)') {
      this.setState({
        formatFile: '.txt',
      });
    } else if (result === 's)') {
      this.setState({
        formatFile: '.xls',
      });
    } else {
      this.setState({
        formatFile: '.csv',
      });
    }

    this.props.actionUnggahDocument.changeCustomCsvCustomer(event);
    if (event === null) {
      this.setState({
        ValidateFormatCsv: 'error',
      });
    } else {
      this.setState({
        ValidateFormatCsv: null,
      });
    }
  }

  handleBlurFormatCsv() {
    if (this.props.FormatCsv === null) {
      this.setState({
        ValidateFormatCsv: 'error',
      });
    } else {
      this.setState({
        ValidateFormatCsv: null,
      });
    }
  }

  handleResetFormatCsv() {
    this.setState({
      defaultCsv: false,
    });
  }

  handleConvert(e: any) {
    this.setState({
      fileName: e.target.value,
    });
    let { file } = this.state;
    file = e.target.files[0];
    this.getBase64(file)
      .then((result: any) => {
        file['base64'] = result.toString().split(',')[1];
        this.props.actionUnggahDocument.changeFileCsv(result.split(',')[1]);
        this.setState({
          base64URL: result.split(',')[1],
          file,
        });
      })
      .catch(err => {
        console.log(err);
      });

    this.setState({
      file: e.target.files[0],
    });
    this.props.actionUnggahDocument.changeCustomerFileName(
      file.name.split('.')[0]
    );
  }

  getBase64 = (file: any) => {
    return new Promise(resolve => {
      let baseURL;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  handleReset() {
    const DateNow = moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss');
    this.props.actionUploadSoftcopy.clearUploadSoftcopy();
    this.props.actionUnggahDocument.clearAllFormUnggahCsv();
    this.props.actionListCustomer.clearListCustomer();
    this.props.actionListCustomerProduct.clearListCustomerProduct();
    this.setState({
      ValidateUploadDate: null,
      ValidateConditionUploadDate: null,
      ValidateCustomer: null,
      ValidateDocumentType: null,
      ValidateCustomerProduct: null,
      ValidateFormatCsv: null,
      file: null,
      fileName: '',
      base64URL: null,
      valueUploadDate: moment(Date.now()),
    });
    this.props.actionUnggahDocument.changeCustomerUploadDate(DateNow);
  }

  handleSubmitUploadSoftcopy() {
    this.props.actionUploadSoftcopy.submitUploadSoftcopy();
  }
  componentWillMount() {
    const DateNow = moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss');
    this.setState({
      valueUploadDate: moment(Date.now()),
    });
    this.props.actionUnggahDocument.changeCustomerUploadDate(DateNow);
  }
  render() {
    return (
      <div>
        <UploadSoftcopyComponent
          isLoading={this.props.isLoading}
          formatFile={this.state.formatFile}
          defaultCsv={this.state.defaultCsv}
          fileName={this.state.fileName}
          uploadDate={this.props.UploadDate}
          customer={this.props.Customer}
          documentType={this.props.DocumentType}
          customerProduct={this.props.CustomerProduct}
          formatCsv={this.props.FormatCsv}
          fileCsv={this.state.file}
          valueUploadDate={this.state.valueUploadDate}
          listCustomer={this.props.ListCustomer}
          listCustomerProduct={this.props.ListCustomerProduct}
          listFormatCsv={this.props.ListFormatCsv}
          loadingSelectCustomer={this.props.LoadingSelectCustomer}
          loadingSelectCustomerProduct={this.props.LoadingSelectCustomerProduct}
          loadingTypeSelectCustomerProduct={
            this.props.LoadingTypeSelectCustomerProduct
          }
          validateUploadDate={
            this.state.ValidateUploadDate === 'error' ? 'error' : ''
          }
          validateCustomer={
            this.state.ValidateCustomer === 'error' ? 'error' : ''
          }
          validateDocumentType={
            this.state.ValidateDocumentType === 'error' ? 'error' : ''
          }
          validateCustomerProduct={
            this.state.ValidateCustomerProduct === 'error' ? 'error' : ''
          }
          validateFormatCsv={
            this.state.ValidateFormatCsv === 'error' ? 'error' : ''
          }
          helpValidateUploadDate={
            this.state.ValidateUploadDate === 'error'
              ? 'Tanggal wajib diisi'
              : ''
          }
          helpValidateCustomer={
            this.state.ValidateCustomer === 'error'
              ? 'Pelanggan wajib diisi'
              : ''
          }
          helpValidateDocumentType={
            this.state.ValidateDocumentType === 'error'
              ? 'Jenis dokumen wajib diisi'
              : ''
          }
          helpValidateCustomerProduct={
            this.state.ValidateCustomerProduct === 'error'
              ? 'Produk wajib diisi'
              : ''
          }
          helpValidateFormatCsv={
            this.state.ValidateFormatCsv === 'error'
              ? 'Template unggah wajib diisi'
              : ''
          }
          valueDeliveryDate={this.state.valueDeliveryDate}
          handleConvert={this.handleConvert}
          handleValidateUploadDate={this.handleValidateUploadDate}
          handlePutUploadDate={this.handlePutUploadDate}
          handlePutCustomer={this.handlePutCustomer}
          handlePutDocumentType={this.handlePutDocumentType}
          handlePutCustomerProduct={this.handlePutCustomerProduct}
          handlePutFormatCsv={this.handlePutFormatCsv}
          handleBlurCustomer={this.handleBlurCustomer}
          handleBlurDocumentType={this.handleBlurDocumentType}
          handleBlurCustomerProduct={this.handleBlurCustomerProduct}
          handleBlurFormatCsv={this.handleBlurFormatCsv}
          handleGetCustomer={this.handleGetCustomer}
          handleGetFormatCsv={this.handleGetFormatCsv}
          handleGetCustomerProduct={this.handleGetCustomerProduct}
          handleResetCustomer={this.handleResetCustomer}
          handleResetDocumentType={this.handleResetDocumentType}
          handleResetCustomerProduct={this.handleResetCustomerProduct}
          handleResetFormatCsv={this.handleResetFormatCsv}
          handleReset={this.handleReset}
          handleSubmitUploadSoftcopy={this.handleSubmitUploadSoftcopy}
          handlePutDeliveryDate={this.handlePutDeliveryDate}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  isLoading: state.Toggle.isLoading,
  ListCustomer: state.Customer.ListCustomer,
  ListCustomerProduct: state.CustomerProduct.ListCustomerProduct,
  ListFormatCsv: state.Customer.ListCustomCsvCustomer,

  UploadDate: state.UploadSoftcopy.UploadDate,
  DeliveryDate: state.Delivery.UnggahDocument.DeliveryDate,
  Customer: state.Delivery.UnggahDocument.CustomerId,
  CustomerProduct: state.Delivery.UnggahDocument.CustomerProductId,
  FormatCsv: state.Delivery.UnggahDocument.CustomCsvCustomer,
  DocumentType: state.Delivery.UnggahDocument.ProductType,
  Csv: state.Delivery.UnggahDocument.csv,
  FileCsv: state.Delivery.UnggahDocument.csv,
  LoadingSelectCustomer: state.Customer.PropertiesCustomer.LoadingCustomer,
  LoadingSelectCustomerProduct:
    state.CustomerProduct.PropertiesCustomerProduct.LoadingCustomerProduct,
  LoadingTypeSelectCustomerProduct:
    state.CustomerProduct.PropertiesCustomerProduct.TypeLoadingCustomerProduct,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionUploadSoftcopy: bindActionCreators(ActionUploadSoftcopy, dispatch),
  actionUnggahDocument: bindActionCreators(ActionUnggahDocument, dispatch),
  actionListCustomer: bindActionCreators(ActionListCustomer, dispatch),
  actionListCustomerProduct: bindActionCreators(
    ActionListCustomerProduct,
    dispatch
  ),
  actionListFormatCsv: bindActionCreators(
    ActionListCustomCsvCustomer,
    dispatch
  ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(UploadSoftcopyContainer);
