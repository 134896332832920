import * as ActionDeliveryPriority from '../../action/DeliveryPriority/ActionDeliveryPriority';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_getDetailTicketDP() {
  const state = yield select();
  const TicketId = state.DeliveryPriority.Properties.ticketId;
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/DeliveryUrgent/' + TicketId,
      { headers: config }
    );

    yield put(ActionDeliveryPriority.setWaybillCode(data.waybillCode));
    yield put(ActionDeliveryPriority.setBarcode(data.barcode));
    yield put(ActionDeliveryPriority.setCustomerName(data.shipToName));
    yield put(ActionDeliveryPriority.setCourierName(data.courierName));
    yield put(ActionDeliveryPriority.setCourierCode(data.courierCode));
    yield put(
      ActionDeliveryPriority.setAddress(JSON.parse(data.shipToAddress))
    );
    yield put(ActionDeliveryPriority.setTicketStatus(data.statusTicket));
    yield put(ActionDeliveryPriority.setCreatedTime(data.createdTime));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      const propsError = ErrorMassage('Detail tiket');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* SM_getDetailTicketDP() {
  yield takeLatest('GET_DETAIL_TICKET_DELIVERY_PRIORITY', S_getDetailTicketDP);
}
