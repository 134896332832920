import React, { useState } from 'react';
import { Row, Col, Icon, Checkbox } from 'antd';
import { BagTypes } from 'lib/constants';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import ModalStatus from 'materials/ModalStatus';
import ModalSelectBarcode from '../../../../../../materials/ModalSelectBarcode';
import styles from './ModalScanWaybil.module.css';
import {
  putScanBagging,
  getScanTableBagging,
  deleteScanBagging,
} from 'services/BaggingDocumentServices';
import { SectionInfo } from '../../../../../../materials/SectionInfo';
import ModalMaterial from 'materials/ModalMaterial';
import CollapseListWaybil from '../../../../../../materials/CollapseListWaybil';
import { InputText } from 'components/InputField';
import SpinMaterial from 'materials/SpinMaterial';
import Button from 'components/Button';

type ModalScanWaybilProps = {
  isShowModal: boolean;
  handleCloseModal: () => void;
  refetchTable?: () => void;
  selectedBagId: number | null;
  barcodeBag: string;
  typeBag: string;
};

export type ScanBarcodeMultiple = {
  waybill: string;
  barcode: string;
  cycle: string;
  name: string;
  customerProduct?: string;
  courier?: string;
};

export default function ModalScanWaybil({
  isShowModal,
  handleCloseModal,
  selectedBagId,
  barcodeBag,
  typeBag,
  refetchTable = () => undefined,
}: ModalScanWaybilProps) {
  const [waybill, setWaybill] = useState<string>('');
  const [successWaybill, setSuccessWaybill] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isBarcode, setIsBarcode] = useState<boolean>(false);
  const [successMultipleBarcode, setSuccessMultipleBarcode] =
    useState<boolean>(false);
  const [dataScanBarcode, setDataScanBarcode] = useState<ScanBarcodeMultiple[]>(
    []
  );
  const [page, setPage] = useState<number>(0);
  const [selectedWaybil, setSelectedWaybiil] = useState<string | null>(null);

  const {
    data: dataTableScanBag,
    isFetching: isLoadingTableScanBag,
    refetch,
  } = useQuery<AxiosResponse>(
    ['TableScanBarcodeBag', page],
    () => getScanTableBagging({ page, idBagging: selectedBagId }),
    {
      enabled: !!selectedBagId,
    }
  );

  const { mutate: mutateScanBagging, isLoading } = useMutation(putScanBagging, {
    onSuccess: (res: any) => {
      if (res.status === 200 || res.status === 204) {
        if (res.data) {
          const response = res.data;
          if (response.isMultiple) {
            setSuccessMultipleBarcode(response.isMultiple);
            setDataScanBarcode(response.data);
            return;
          }

          if (
            (!isBarcode && !response.isMultiple) ||
            (isBarcode && !response.isMultiple)
          ) {
            setSuccessWaybill(waybill);
            setPage(0);
            refetch();
            refetchTable();
            setIsSuccess(true);
            setSuccessMultipleBarcode(false);
            setWaybill('');
          }
        }
      } else {
        ModalStatus({
          status: 'error',
          title: 'Gagal Proses',
          content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
        });
      }
    },
    onError: () => {
      ModalStatus({
        status: 'error',
        title: 'Internal Server Error',
        content: 'Silahkan Hubungi Admin',
      });
    },
  });

  const { mutate: mutateDeleteWaybil, isLoading: isLoadingDelete } =
    useMutation(deleteScanBagging, {
      onSuccess: (res: any) => {
        if (res.status === 200 || res.status === 204) {
          ModalStatus({
            status: 'success',
            title: 'Berhasil Dihapus',
            content: `Waybill ${selectedWaybil} telah di hapus`,
          });
          setSelectedWaybiil(null);
          setPage(0);
          refetch();
          refetchTable();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const handleEnterWaybil = value => {
    const barcodeValue = isBarcode && !successMultipleBarcode ? value : '';
    const waybillValue =
      (!isBarcode && !successMultipleBarcode) ||
      (isBarcode && successMultipleBarcode)
        ? value
        : '';
    const data = {
      baggingType: typeBag,
      barcodeBagging: barcodeBag,
      waybill: waybillValue,
      barcode: barcodeValue,
    };
    mutateScanBagging(data);
  };

  const handleCloseModalScan = () => {
    handleCloseModal();
    setWaybill('');
    setSuccessWaybill('');
    setIsSuccess(false);
  };

  return (
    <div>
      <ModalMaterial
        teksTitle="Scan Waybill"
        width="70%"
        visible={isShowModal}
        onCancel={handleCloseModalScan}
        content={
          <SpinMaterial
            spinning={isLoading}
            size={'large'}
            content={
              <>
                <div style={{ marginTop: 24, padding: '0 24px' }}>
                  <div className={styles.wrapperFilter}>
                    <Row gutter={24}>
                      <Col md={12} className={styles.filterColumn}>
                        <SectionInfo
                          label="Jenis Bag"
                          value={
                            BagTypes.find(value => value.id === typeBag)?.name
                          }
                        />
                        <div className={styles.wrapperInputScan}>
                          <InputText
                            label={isBarcode ? 'Scan Barcode' : 'Scan Waybill'}
                            placeholder={
                              isBarcode
                                ? 'Masukan barcode disini...'
                                : 'Masukan waybill disini...'
                            }
                            formStyle={{ margin: 0, width: '100%' }}
                            onChange={value => setWaybill(value)}
                            value={waybill}
                            additionalHandleEnterFunc={e =>
                              handleEnterWaybil(e.target.value)
                            }
                            maxlength={isBarcode ? 100 : 16}
                          />
                          <Checkbox
                            className={styles.checkboxBarcode}
                            value={isBarcode}
                            onChange={() => {
                              setIsBarcode(!isBarcode);
                              setIsSuccess(false);
                            }}
                          >
                            Barcode
                          </Checkbox>
                        </div>

                        {isSuccess && successWaybill && (
                          <p className={styles.successWaybil}>
                            <Icon type="check-circle" theme="filled" />
                            <span>
                              {isBarcode ? 'Barcode' : 'Waybill'}{' '}
                              <b>{successWaybill}</b> berhasil di-scan
                            </span>
                          </p>
                        )}
                      </Col>
                      <Col md={12} className={styles.filterColumn}>
                        <SectionInfo label="Barcode Bag" value={barcodeBag} />
                      </Col>
                    </Row>
                    <CollapseListWaybil
                      selectedBagId={selectedBagId}
                      dataTableScanBag={dataTableScanBag?.data}
                      mutateDeleteWaybil={mutateDeleteWaybil}
                      isLoadingTableScanBag={isLoadingTableScanBag}
                      isLoadingDelete={isLoadingDelete}
                      selectedWaybil={selectedWaybil}
                      setSelectedWaybiil={setSelectedWaybiil}
                      setPage={setPage}
                    />
                    <div className={styles.actionFilter}>
                      <Button
                        text="Tutup"
                        variant="danger"
                        onClick={handleCloseModalScan}
                      />
                    </div>
                  </div>
                </div>
                <ModalSelectBarcode
                  dataScanBarcode={dataScanBarcode}
                  isShowModal={successMultipleBarcode}
                  handleCloseModal={() => setSuccessMultipleBarcode(false)}
                  handleEnterWaybil={handleEnterWaybil}
                />
              </>
            }
          />
        }
      />
    </div>
  );
}
