export function changeFirstDate(FirstDate: any) {
  return {
    type: 'CHANGE_FIRST_DATE_BAST',
    FirstDate,
  };
}
export function changeLastDate(LastDate: any) {
  return {
    type: 'CHANGE_LAST_DATE_BAST',
    LastDate,
  };
}
export function changeCustomerId(CustomerId: any) {
  return {
    type: 'CHANGE_CUSTOMER_ID_BAST',
    CustomerId,
  };
}
export function changeProductId(ProductId: any) {
  return {
    type: 'CHANGE_PRODUCT_ID_BAST',
    ProductId,
  };
}
export function clearCustomerProduct() {
  return {
    type: 'CLEAR_PRODUCT_ID_BAST',
  };
}
export function clearForm() {
  return {
    type: 'CLEAR_FORM_BAST',
  };
}
export function downloadBast() {
  return {
    type: 'DOWNLOAD_BAST',
  };
}
