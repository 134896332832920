import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import FormManualHolidayComponent from '../Component/FormManualHolidayComponent';
import * as ActionFormManualHoliday from '../../../core/action/Holiday/ActionFormManualHoliday';
import * as ActionFormSynchroniz from '../../../core/action/Holiday/ActionFormSynchronizedWeekly';
import * as ActionToggle from '../../../core/action/toggle/index';
import * as ActionPropertiesHoliday from '../../../core/action/Holiday/ActionPropertiesHoliday';
import * as ActionAddHoliday from '../../../core/action/Holiday/ActionAddHoliday';
import * as ActionEditHoliday from '../../../core/action/Holiday/ActionEditHoliday';
import * as ActionGetHolidaySynchroniz from '../../../core/action/Holiday/ActionGetHolidaySynchroniz';
import * as ActionUploadCsvHoliday from '../../../core/action/Holiday/ActionUploadCsvHoliday';
import { useMutation } from '@tanstack/react-query';
import {
  postHoliday,
  updateHoliday,
  uploadHolidayCsv,
  downloadResultResponse,
} from '../../../services/HolidayServices';
import ModalStatus from '../../../materials/ModalStatus';
import ModalMaterial from 'materials/ModalMaterial';
import CountResult from 'components/CountResult';
import { downloadBlob } from 'lib/util';
import { ErrorMassage } from 'core/ErrorMassage';

interface ManualHolidayProps {
  isLoading: any;
  visible: any;
  typeToggle: any;
  DateHoliday: any;
  HolidayEvent: any;
  IdHoliday: any;
  ActiveHoliday: any;
  ValueSwitch: any;
  SyncronizeWeekly: any;
  actionFormManualHoliday: any;
  actionToggle: any;
  actionPropertiesHoliday: any;
  actionAddHoliday: any;
  actionEditHoliday: any;
  actionUploadCsvHoliday: any;
  actionGetHolidaySynchroniz: any;
  actionFormSynchroniz: any;
  refetch: any;
}

const FormManualHolidayContainer = (props: ManualHolidayProps) => {
  const [ValidateHolidayDate, setValidateHolidayDate] = useState<any>(null);
  const [ValidateHolidayEvent, setValidateHolidayEvent] = useState<any>(null);
  const [ValidateConditionHolidayDate, setValidateConditionHolidayDate] =
    useState<any>(null);

  const [fileNameCsv, setFileNameCsv] = useState<string>('');
  const [fileCsv, setFileCsv] = useState<any>(null);
  const [successUploadCsv, setSuccessUploadCsv] = useState<boolean>(false);
  const [resultUploadCsv, setResultUploadCsv] = useState<{
    success: number;
    error: number;
  }>({ success: 0, error: 0 });
  const [resultListCsv, setResultListCsv] = useState<Array<any>>([]);

  const { mutate: submitHoliday, isLoading } = useMutation(postHoliday, {
    onSuccess: res => {
      handleCloseModal();
      props.refetch();
      if (res.status === 200) {
        ModalStatus({
          status: 'success',
          title: 'Berhasil menambahkan hari libur',
        });
      } else {
        ModalStatus({
          status: 'error',
          title: 'Tanggal sudah ada di hari libur!!!',
          content: 'Silakan pilih tanggal lainnya',
        });
      }
    },
    onError: () => {
      ModalStatus({
        status: 'error',
        title: 'Tanggal sudah ada di hari libur!!!',
        content: 'Silakan pilih tanggal lainnya',
      });
    },
  });

  const { mutate: submitUpdateHoliday, isLoading: isLoadingUpdate } =
    useMutation(updateHoliday, {
      onSuccess: res => {
        handleCloseModal();
        props.refetch();
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Berhasil menambahkan hari libur',
          });
        } else {
          ModalStatus({
            status: 'error',
            title: 'Tanggal sudah ada di hari libur!!!',
            content: 'Silakan pilih tanggal lainnya',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Tanggal sudah ada di hari libur!!!',
          content: 'Silakan pilih tanggal lainnya',
        });
      },
    });

  const { mutate: mutateDownloadHoliday, isLoading: isLoadingDownload } =
    useMutation(downloadResultResponse, {
      onSuccess: res => {
        handleCloseModal();
        props.refetch();
        if (res.status === 200) {
          downloadBlob(res.data, 'Gagal upload', 'txt');
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    });

  const { mutate: submitHolidayCsv, isLoading: isLoadingUpload } = useMutation(
    uploadHolidayCsv,
    {
      onSuccess: res => {
        handleCloseModal();
        props.refetch();
        if (res.status === 200) {
          setSuccessUploadCsv(!successUploadCsv);
          setResultUploadCsv({
            success: res.data?.success,
            error: res.data?.failed,
          });
          setResultListCsv(res.data?.listResultCsv);
        } else {
          const errorMessage = res.data.Message;
          const searchMessageError = errorMessage.search('File not supported!');
          if (res.status === 500 && searchMessageError >= 0) {
            ModalStatus({
              status: 'error',
              title: 'Isi file hari libur tidak sesuai!!!',
              content: 'Silakan cek isi file csv hari libur',
            });
          } else {
            const propsError = ErrorMassage('download template hari libur');
            const code = propsError.filter((person: any) =>
              person.Code.toString().toLowerCase().includes(res.status)
            );
            ModalStatus({
              status: 'error',
              title: code.map((data: any) => data.TitleMassage).toString(),
              content: code.map((data: any) => data.Massage).toString(),
            });
          }
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Tanggal sudah ada di hari libur!!!',
          content: 'Silakan pilih tanggal lainnya',
        });
      },
    }
  );

  const handleSynchronizeWeekly = () => {
    var now = new Date();
    var currentYear = now.getFullYear();
    props.actionFormSynchroniz.setYearSynchronized(currentYear);
    props.actionPropertiesHoliday.setSynchronizeWeekly();
    setValidateHolidayDate(null);
    setValidateHolidayEvent(null);
    props.actionGetHolidaySynchroniz.getHolidaySynchroniz();
  };
  const handlePutActivateHoliday = () => {
    props.actionFormManualHoliday.setActiveHoliday();
  };
  const handlePutHolidayDate = (event: any) => {
    props.actionFormManualHoliday.setDateHoliday(event);
    if (event === '') {
      setValidateHolidayDate('error');
    } else {
      setValidateHolidayDate(null);
    }
  };
  const handlePutHolidayEvent = (event: any) => {
    props.actionFormManualHoliday.setEventHoliday(event.target.value);
    if (event.target.value === '') {
      setValidateHolidayEvent('error');
    } else {
      setValidateHolidayEvent(null);
    }
  };
  const handleValidateHolidayDate = (event: any) => {
    setValidateConditionHolidayDate(event);
    if (ValidateConditionHolidayDate === true) {
      if (
        props.DateHoliday === null ||
        props.DateHoliday === undefined ||
        props.DateHoliday === 'Invalid date'
      ) {
        setValidateHolidayDate('error');
      } else {
        setValidateHolidayDate(null);
      }
    } else {
    }
  };
  const handleCloseModal = () => {
    props.actionFormManualHoliday.clearAllFormManualHoliday();
    props.actionToggle.changeModal();
    props.actionToggle.clearJenisToggle();
    props.actionUploadCsvHoliday.clearCsvHoliday();
    setValidateHolidayDate(null);
    setValidateHolidayEvent(null);
    setFileNameCsv('');
    setFileCsv(null);
  };
  const handleSwitch = () => {
    props.actionPropertiesHoliday.setSwitchFormHoliday();
    setValidateHolidayDate(null);
    setValidateHolidayEvent(null);

    props.actionFormManualHoliday.clearDateHoliday();
    props.actionFormManualHoliday.clearEventHoliday();
    props.actionUploadCsvHoliday.clearCsvHoliday();
  };

  const handleDownloadResultCsv = () => {
    mutateDownloadHoliday({ listResult: resultListCsv });
  };

  const handleClickCloseCsv = () => {
    setSuccessUploadCsv(!successUploadCsv);
    setResultUploadCsv({
      success: 0,
      error: 0,
    });
    setResultListCsv([]);
  };

  return (
    <div>
      <ModalMaterial
        teksTitle={'Unggah Hari Libur'}
        width="50%"
        visible={successUploadCsv}
        onCancel={handleClickCloseCsv}
        content={
          <CountResult
            success={resultUploadCsv.success}
            error={resultUploadCsv.error}
            onClickDownload={handleDownloadResultCsv}
            onClickOke={handleClickCloseCsv}
            disabledButtonDownload={
              isLoadingDownload || resultUploadCsv?.error === 0
            }
          />
        }
      />
      <FormManualHolidayComponent
        DateHoliday={props.DateHoliday}
        HolidayEvent={props.HolidayEvent}
        IdHoliday={props.IdHoliday}
        visible={props.visible}
        isLoading={isLoading}
        isLoadingUpdate={isLoadingUpdate}
        ValueSwitch={props.ValueSwitch}
        SyncronizeWeekly={props.SyncronizeWeekly}
        typeToggle={props.typeToggle}
        ActiveHoliday={props.ActiveHoliday}
        handlePutHolidayDate={handlePutHolidayDate}
        handlePutHolidayEvent={handlePutHolidayEvent}
        handleValidateHolidayDate={handleValidateHolidayDate}
        handleCloseModal={handleCloseModal}
        handleSwitch={handleSwitch}
        handleAddManualHoliday={submitHoliday}
        handleEditHoliday={submitUpdateHoliday}
        handlePutActivateHoliday={handlePutActivateHoliday}
        handleSynchronizeWeekly={handleSynchronizeWeekly}
        ValidateHolidayDate={ValidateHolidayDate}
        ValidateHolidayEvent={ValidateHolidayEvent}
        ValidateButtonSave={
          props.DateHoliday === null ||
          props.DateHoliday === 'Invalid date' ||
          props.HolidayEvent === null ||
          props.HolidayEvent === ''
            ? true
            : false
        }
        fileNameCsv={fileNameCsv}
        fileCsv={fileCsv}
        handleSetNameCsv={value => setFileNameCsv(value)}
        handleSetFileCsv={value => setFileCsv(value)}
        submitHolidayCsv={submitHolidayCsv}
        isLoadingUploadCsv={isLoadingUpload}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isLoading: state.Toggle.isLoading,
  visible: state.Toggle.modal,
  typeToggle: state.Toggle.jenisToggle,
  ValueSwitch: state.Holiday.PropertiesHoliday.SwitchHoliday,
  SyncronizeWeekly: state.Holiday.PropertiesHoliday.SyncronizeWeekly,
  DateHoliday: state.Holiday.FormManualHoliday.DateHoliday,
  HolidayEvent: state.Holiday.FormManualHoliday.EventHoliday,
  IdHoliday: state.Holiday.PropertiesHoliday.IdHoliday,
  ActiveHoliday: state.Holiday.FormManualHoliday.ActiveHoliday,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionFormManualHoliday: bindActionCreators(
    ActionFormManualHoliday,
    dispatch
  ),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionPropertiesHoliday: bindActionCreators(
    ActionPropertiesHoliday,
    dispatch
  ),
  actionAddHoliday: bindActionCreators(ActionAddHoliday, dispatch),
  actionEditHoliday: bindActionCreators(ActionEditHoliday, dispatch),
  actionUploadCsvHoliday: bindActionCreators(ActionUploadCsvHoliday, dispatch),
  actionGetHolidaySynchroniz: bindActionCreators(
    ActionGetHolidaySynchroniz,
    dispatch
  ),
  actionFormSynchroniz: bindActionCreators(ActionFormSynchroniz, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FormManualHolidayContainer);
