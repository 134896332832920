// DY: DEV CHANGES 4 JUL 24

import React from 'react';
import { Row, Col } from 'antd';
import ModalMaterial from 'materials/ModalMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import Button from 'components/Button';
import { Form, Field } from 'react-final-form';
import { InputSwitch, InputText } from 'components/InputField';
import SelectField from 'components/SelectField';
import { InitialValueLocation } from 'module/SettingLocation/container/EditLocation';
import ContentAlertWarning from 'components/ContentAlertWarning';

type ModalEditLocationProps = {
  handleModal: () => void;
  show: number | null;
  handleSubmit: () => void;
  handleOnChange?: (name: string, value: any) => void;
  initialValue: InitialValueLocation;
  isLoading: boolean;
  listBranch: {
    id: any;
    name: any;
  }[];
};

export default function ModalEditLocation({
  show,
  handleModal,
  handleSubmit,
  handleOnChange = () => undefined,
  initialValue,
  isLoading = false,
  listBranch,
}: ModalEditLocationProps) {
  return (
    <ModalMaterial
      width={'70%'}
      teksTitle={'EDIT WILAYAH'}
      visible={!!show}
      onCancel={handleModal}
    >
      <SpinMaterial spinning={isLoading} size="large">
        <Form
          initialValues={initialValue}
          onSubmit={handleSubmit}
          validate={values => {
            const error = {} as any;
            if (!values.branch) {
              error.branch = 'Posko Wajib diisi';
            }
            if (!values.sortCode) {
              error.sortCode = 'SortCode Wajib diisi';
            }
            if (values?.sortCode && values?.sortCode.toString().length > 7) {
              error.sortCode = 'SortCode tidak boleh lebih dari 7 karakter';
            }
            return error;
          }}
        >
          {({ handleSubmit, invalid, values }) => (
            <form
              style={{
                padding: '20px 24px 0 24px',
                display: 'flex',
                flexDirection: 'column',
                gap: 24,
              }}
              onSubmit={handleSubmit}
            >
              <ContentAlertWarning title="Setiap kode pos adalah unik dan hanya dapat dikaitkan dengan satu sort code. Pastikan sort code yang Anda perbarui hanya dipakai untuk satu kode pos." />
              <Field name="isActive">
                {({ input, meta }) => (
                  <InputSwitch
                    label="Status Wilayah"
                    input={input}
                    meta={meta}
                    isLabelActive
                    onChange={value => handleOnChange('isActive', value)}
                    formStyle={{ margin: 0 }}
                  />
                )}
              </Field>
              <Row gutter={[32, 32]}>
                <Col
                  span={12}
                  style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
                >
                  <Field name="province">
                    {({ input, meta }) => (
                      <InputText
                        label="Provinsi"
                        placeholder="Masukkan Provinsi"
                        formStyle={{ margin: 0 }}
                        meta={meta}
                        input={input}
                        disabled
                      />
                    )}
                  </Field>
                  <Field name="subDistric">
                    {({ input, meta }) => (
                      <InputText
                        label="Kecamatan"
                        placeholder="Masukkan Kecamatan"
                        formStyle={{ margin: 0 }}
                        meta={meta}
                        input={input}
                        disabled
                      />
                    )}
                  </Field>
                  <Field name="postalCode">
                    {({ input, meta }) => (
                      <InputText
                        label="Kode Pos"
                        placeholder="Masukan Kode Pos"
                        formStyle={{ margin: 0 }}
                        meta={meta}
                        input={input}
                        onChange={value => handleOnChange('postalCode', value)}
                        min={0}
                        isOnlyNumber
                        disabled
                      />
                    )}
                  </Field>
                  <Field name="branch">
                    {({ input, meta }) => (
                      <SelectField
                        label="Posko"
                        placeholder="Masukkan Posko"
                        data={listBranch}
                        value={input.value}
                        valueKey="id"
                        labelKey="name"
                        onChange={value => handleOnChange('branch', value)}
                      />
                    )}
                  </Field>
                </Col>
                <Col
                  span={12}
                  style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
                >
                  <Field name="city">
                    {({ input, meta }) => (
                      <InputText
                        label="Kota/Kabupaten"
                        placeholder="Masukkan Kota/Kabupaten"
                        formStyle={{ margin: 0 }}
                        meta={meta}
                        input={input}
                        disabled
                      />
                    )}
                  </Field>
                  <Field name="villages">
                    {({ input, meta }) => (
                      <SelectField
                        label="Desa/Kelurahan"
                        placeholder="Masukkan Desa/Kelurahan"
                        data={[]}
                        value={input.value}
                        mode="multiple"
                        disabled
                      />
                    )}
                  </Field>
                  <Field name="sortCode">
                    {({ input, meta }) => (
                      <InputText
                        label="Short Code"
                        placeholder="Masukan Short Code"
                        formStyle={{ margin: 0 }}
                        meta={meta}
                        input={input}
                        onChange={value => handleOnChange('sortCode', value)}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <Button text="Simpan" htmlType="submit" disabled={invalid} />
              </div>
            </form>
          )}
        </Form>
      </SpinMaterial>
    </ModalMaterial>
  );
}
