import { Modal } from 'antd';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import FormRestoreDataByWaybillComponent from '../../../components/Content/RestoreData/FormRestoreDataByWaybillComponent';
import * as ActionRestoreData from '../../../core/action/RestoreData/ActionRestoreData';
import * as toggle from '../../../core/action/toggle';
import {
  downloadTemplateDeleteOrRestore,
  restoreDataByFile,
  deleteDataByFile,
} from 'services/RestoreService';
import { downloadBlob, fileReader } from 'lib/util';
import ModalStatus from 'materials/ModalStatus';

const { confirm } = Modal;

interface IProps {
  actionToggle: any;
  actionRestoreData: any;
  visible: any;
  jenisToggle: any;
  getWaybill: any;
  isLoading: any;
  LastAccess: any;
  RadioButtonW: any;
  showModal: boolean;
  handleShowHideModal: () => void;
}

const AddAdminContainer = (props: IProps) => {
  const [ValidateWaybill, setValidateWaybill] = useState<any>(null);
  const [isWaybill, setIsWaybill] = useState<boolean>(false);
  const [fileUpload, setFileUpload] = useState<any | null>(null);
  const [fileName, setFileName] = useState<string>('');

  const { mutate: downloadTemplate, isLoading: isLoadingTemplate } =
    useMutation(downloadTemplateDeleteOrRestore, {
      onSuccess: res => {
        if (res.status === 200) {
          downloadBlob(res.data, 'template_hapus_waybill', 'csv');
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Menjalankan Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const { mutate: mutateRestoreData, isLoading: isLoadingRestore } =
    useMutation(restoreDataByFile, {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Restore berhasil',
          });
          props.handleShowHideModal();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
        setFileName('');
        setFileUpload(null);
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const { mutate: mutateDeleteData, isLoading: isLoadingDelete } = useMutation(
    deleteDataByFile,
    {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Hapus berhasil',
          });
          props.handleShowHideModal();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
        setFileName('');
        setFileUpload(null);
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    }
  );

  const handleDownload = async () => {
    const fileRead = await fileReader(fileUpload?.originFileObj || null);

    if (fileRead.filePreview) {
      if (props.RadioButtonW === 'Delete') {
        mutateDeleteData({ file: fileRead.filePreview });
      } else {
        mutateRestoreData({ file: fileRead.filePreview });
      }
    }
  };

  const handleClearModalForm = () => {
    props.actionRestoreData.clearWaybillRestoreData();
    setValidateWaybill(null);
    props.handleShowHideModal();
  };

  const handlePutWaybill = (event: any) => {
    props.actionRestoreData.setWaybillRestoreData(
      event.target.value.toUpperCase()
    );

    if (event.target.value === '') {
      setValidateWaybill('error');
    } else if (event.target.value.length < 7) {
      setValidateWaybill('notMinChar');
    } else {
      setValidateWaybill('');
    }
  };

  const handleDelete = () => {
    confirm({
      title: 'Apakah anda yakin ingin menghapus waybill ini ?',
      okText: 'Ya',
      okType: 'danger',
      cancelText: 'Tidak',
      onOk: () => {
        props.actionRestoreData.submitDeleteRestoreDataByWaybill();
        props.handleShowHideModal();
      },
      onCancel: () => {},
    });
  };

  const handleRestore = () => {
    confirm({
      title: 'Apakah anda yakin ingin merestore waybill ini ?',
      okText: 'Ya',
      okType: 'primary',
      cancelText: 'Tidak',
      onOk: () => {
        props.actionRestoreData.submitDeleteRestoreDataByWaybill();
        props.handleShowHideModal();
      },
      onCancel: () => {},
    });
  };

  return (
    <FormRestoreDataByWaybillComponent
      visible={props.showModal}
      handleClearModal={handleClearModalForm}
      LastAccess={props.LastAccess}
      RadioButtonW={props.RadioButtonW}
      jenisToggle={props.jenisToggle}
      handleDelete={handleDelete}
      handleRestore={handleRestore}
      handlePutWaybill={handlePutWaybill}
      getWaybill={props.getWaybill}
      ValidateWaybill={
        ValidateWaybill === 'error' || ValidateWaybill === 'notMinChar'
          ? 'error'
          : ''
      }
      helpValidateWaybill={
        ValidateWaybill === 'error'
          ? 'Waybill wajib diisi'
          : ValidateWaybill === 'notMinChar'
          ? 'Waybill harus lebih dari 6 karakter'
          : ''
      }
      validateDeleteData={
        props.getWaybill === null ||
        props.getWaybill === '' ||
        ValidateWaybill === 'error' ||
        ValidateWaybill === 'notMinChar'
          ? true
          : false
      }
      isLoading={props.isLoading}
      setIsWaybill={setIsWaybill}
      isWaybill={isWaybill}
      downloadTemplate={() => downloadTemplate()}
      isLoadingDownload={
        isLoadingTemplate || isLoadingRestore || isLoadingDelete
      }
      setFileUpload={setFileUpload}
      handleDownload={handleDownload}
      fileName={fileName}
      setFileName={setFileName}
    />
  );
};
const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  jenisToggle: state.Toggle.jenisToggle,
  LastAccess: state.LastAccess.Fiture,
  isLoading: state.Toggle.isLoading,
  getWaybill: state.RestoreData.FormRestoreData.Waybill,
  RadioButtonW: state.RestoreData.FormRestoreData.RadioButtonW,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionRestoreData: bindActionCreators(ActionRestoreData, dispatch),
  actionToggle: bindActionCreators(toggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AddAdminContainer);
