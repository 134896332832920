import React, { Component } from 'react';
import { Tabs } from 'antd';
import { getTabMenus } from 'lib/util';

import IndexCourierManifest from '../../../components/Content/Manifest/CourierManifest/index';
import IndexCustomerManifest from '../../../components/Content/Manifest/CustomerManifest/index';
import ScanManifest from 'module/Manifest/ScanManifest/container';
import ManifestBrankas from '../../../module/Manifest/ManifestBrankas/container';
const { TabPane } = Tabs;

interface IProps {
  roleAccount: any;
  lastAccessFiture: any;
  handleChangeTabs: any;
  sideMenus: any;
}

export default class IndexManifestComponent extends Component<IProps> {
  render() {
    let a = '1';
    const isBranch = this.props.roleAccount === 'Branch';
    const tabMenus = getTabMenus(this.props.sideMenus, 'Manifest') || [];

    const componentTabMenus = {
      'Manifest Kurir': <IndexCourierManifest />,
      'Scan Manifest': <ScanManifest />,
      'Manifest Pelanggan': <IndexCustomerManifest />,
      'Manifest Brankas': <ManifestBrankas />,
    };

    if (this.props.lastAccessFiture === 'customerManifest') {
      a = '3';
    } else if (this.props.lastAccessFiture === 'scanManifest') {
      a = '2';
    } else {
      a = '1';
    }

    return (
      <div>
        <Tabs
          className="tabDefault"
          defaultActiveKey={a}
          onChange={this.props.handleChangeTabs}
        >
          {tabMenus
            .filter(value => {
              if (
                (isBranch && value.label === 'Manifest Kurir') ||
                value.label === 'Insentif Kurir'
              ) {
                return value;
              }

              if (!isBranch) {
                return value;
              }

              return null;
            })
            .map((item, index) => (
              <TabPane
                tab={<b>{item.label}</b>}
                key={
                  isBranch && item.label === 'Insentif Kurir' ? 6 : index + 1
                }
              >
                {componentTabMenus[item.label]}
              </TabPane>
            ))}
        </Tabs>
      </div>
    );
  }
}
