import React from 'react';
import { Collapse, Icon, Table } from 'antd';
import ButtonMaterial from 'materials/ButtonMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import ModalConfirmation from 'components/ModalConfirmation';
import { DataTableResProps } from 'interface/general';
const { Panel } = Collapse;

type CollapseListWaybilProps = {
  isDetail?: boolean;
  selectedBagId?: number | null;
  dataTableScanBag: DataTableResProps;
  mutateDeleteWaybil?: (value: any) => void;
  isLoadingTableScanBag?: boolean;
  isLoadingDelete?: boolean;
  selectedWaybil?: string | null;
  setSelectedWaybiil?: (value: string | null) => void;
  setPage?: (value: number) => void;
};

export default function CollapseListWaybil({
  isDetail = false,
  selectedBagId,
  dataTableScanBag,
  mutateDeleteWaybil = () => undefined,
  isLoadingTableScanBag = false,
  isLoadingDelete = false,
  selectedWaybil,
  setSelectedWaybiil = () => undefined,
  setPage = () => undefined,
}: CollapseListWaybilProps) {
  const pagePagination = dataTableScanBag?.page + 1 || 0;
  const totalPagination = dataTableScanBag?.totalPages * 5 || 0;
  const looppage = (pagePagination - 1) * 5 + 1;

  const columnsTable = [
    {
      title: 'No',
      render: (text: any, record: any, index: any) => (
        <span>
          <span>{pagePagination > 1 ? index + looppage : index + 1}</span>
        </span>
      ),
    },
    {
      title: 'Waybill',
      dataIndex: 'waybill',
    },
    {
      title: 'Nama Nasabah',
      dataIndex: 'shipToName',
    },
    {
      title: 'Pelanggan',
      dataIndex: 'customerName',
    },
    {
      title: 'Produk',
      dataIndex: 'productName',
    },
    ...(!isDetail
      ? [
          {
            title: 'Aksi',
            render: record => (
              <div style={{ display: 'flex', gap: 8 }}>
                <ButtonMaterial
                  icon="delete"
                  type="danger"
                  handleSubmit={() => setSelectedWaybiil(record?.waybill)}
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  const handleDeleteWaybil = () => {
    mutateDeleteWaybil({
      waybill: selectedWaybil,
      idBagging: selectedBagId,
    });
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <Icon type="caret-right" rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel
        header={
          <p
            style={{
              margin: 0,
              color: '#31E116',
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            {`Total Waybill (${dataTableScanBag?.totalDatas || 0})`}
          </p>
        }
        key="1"
        style={{
          borderRadius: 0,
          padding: 0,
          border: 0,
        }}
      >
        <SpinMaterial
          spinning={isLoadingTableScanBag || isLoadingDelete}
          size={'large'}
          content={
            <Table
              columns={columnsTable}
              bordered
              dataSource={dataTableScanBag?.data || []}
              pagination={{
                onChange: page => {
                  setPage(page - 1);
                },
                pageSize: 5,
                total: totalPagination,
                current: pagePagination === 0 ? 1 : pagePagination,
              }}
            />
          }
        />
      </Panel>
      <ModalConfirmation
        title="Apakah anda yakin?"
        description="Waybill yang telah diinput akan dihapus dari Bag ini"
        visibleModal={!!selectedWaybil}
        onCancel={() => setSelectedWaybiil(null)}
        onConfirm={() => handleDeleteWaybil()}
        centered
      />
    </Collapse>
  );
}
