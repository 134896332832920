import { CheckLogin, CheckToken } from '../core/middleware/AuthMiddleware';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import AddLevelAccount from '../module/ManageAccountNew/Level/Container/AddLevelFromContainer';
import AddRole from '../module/Role/Container/AddRoleFormContainer';
import EditRole from '../module/Role/Container/EditRoleFormContainer';
import ViewRole from '../module/Role/Container/ViewRoleFormContainer';
import DetailLevelAccount from '../module/ManageAccountNew/Level/Container/DetailLevelFormContainer';
import EditLevelAccount from '../module/ManageAccountNew/Level/Container/EditLevelFormContainer';
import AdminBranch from '../containers/Content/ManageAccount/IndexContainer';
import AssigningCourier from 'module/AssigningCourier/Container/AssigningCourierContainer';
import AssigningCourierSA from 'module/AssigningCourier/Container/AssigningCourierSAContainer';
import Branch from '../module/Branch/Container/BranchContainer';
import BranchDetail from 'module/Branch/Container/BranchDetailContainer';
import CallCenter from '../module/CallCenter/Container/CallCenterFormContainer';
import Complain from 'module/Complaint/Container/IndexComplaintContainer';
import ComplaintNew from '../module/ComplaintNew/Container/IndexComplaintContainer';
import Customer from '../containers/Content/Customer/CustomerContainer';
import CustomerAccountDetail from '../containers/Content/ManageAccount/Customer/DetailCustomerAccountContainer';
import CustomerDetail from '../containers/Content/Customer/CustomerDetailContainer';
import Dashboard from '../module/Dashboard/Container';
import DashboardBranch from 'module/Dashboard/Container/Pengiriman/ProgressCourierContainer';
import DataDelivery from 'module/DeliveryData/Component';
import DeliveryPrintWaybill from '../containers/Content/Delivery/Document/IndexPrintWaybillContainer';
import DeliveryPriority from '../containers/Content/DeliveryPriority/DeliveryPriorityContainer';
import DeliveryStatus from 'module/DeliveryStatus/Container/IndexDeliveryStatusContainer';
import DetailPickUp from '../module/PickUpProcess/Container/DetailPickUpContainer';
import Holiday from 'module/Holiday/Container/HolidayContainer';
import IndexCustomerConfig from '../containers/Content/Customer/IndexCustomerConfigContainer';
import IndexManifest from '../containers/Content/Manifest/IndexManifestContainer';
import LogActivity from '../module/LogActivity/Container/LogActivityContainer';
import LoginContainer from 'module/Login/Component/ParentHocLoginComponent';
import ManageCustomerAccount from '../containers/Content/ManageAccount/Customer/TableManageCustomerContainer';
import ManageLevelAccount from '../module/ManageAccountNew/Level/Container/LevelContainer';
import RoleContainer from 'module/Role/Container/RoleContainer';
import ManualProcess from '../containers/Content/ManualProcess/IndexManualProcessContainer';
import NotFound from '../components/NotFound';
import PickupData from '../module/Pickup/PickupData/Container/PickupDataContainer';
import PickupStatus from '../module/Pickup/PickupStatus/Container/PickupStatusContainer';
import PrivacyPolicy from '../containers/Content/PrivacyPolicy/PrivacyPolicyContainer';
import ProcessPickUp from '../module/PickUpProcess/Container/PickUpProcessContainer';
import Profile from '../containers/Content/Profile/ProfileContainer';
import Report from 'module/Report/Container/IndexReportContainer';
import Ringkasan from 'module/Report/Ringkasan/container';
import Resend from 'module/Attempt/Container/ResendContainer';
import RestoreData from '../containers/Content/RestoreData/IndexRestoreDataContainer';
import ServerVoip from '../containers/Content/Voip/IndexContainer';
import { TemplateBerandaComponent } from '../core/template/TemplateComposed';
import UploadSoftcopy from '../containers/Content/Delivery/Resend/UploadSoftcopy/IndexContainer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import history from '../core/utils/History';
import DeliveryBetweenPosts from 'module/DeliveryBetweenPosts/Container';
import indexDelivery from '../containers/Content/Delivery/Document/IndexDeliveryContainer';
import SmsBlast from 'module/SmsBlast/container';
import SettingLocation from 'module/SettingLocation/container';

interface IProps {
  Token: any;
  Role: any;
}
class Navigation extends Component<IProps> {
  protected login: any;
  protected profile: any;
  protected dashboard: any;
  protected dashboardBranch: any;
  protected adminBranch: any;
  protected branch: any;
  protected branchdetail: any;
  protected customer: any;
  protected customerdetail: any;
  protected indexCustomerConfig: any;
  protected indexDelivery: any;
  protected deliveryPrintWaybill: any;
  protected deliverystatus: any;
  protected datadelivery: any;
  protected resend: any;
  protected manualProcess: any;
  protected report: any;
  protected ringkasan: any;
  protected holiday: any;
  protected logActivity: any;
  protected manageCustomerAccount: any;
  protected manageLevelAccount: any;
  protected role: any;
  protected addRole: any;
  protected editRole: any;
  protected viewRole: any;
  protected addLevelAccount: any;
  protected detailLevelAccount: any;
  protected editLevelAccount: any;
  protected customerAccountDetail: any;
  protected complain: any;
  protected callCenter: any;
  protected deliveryBetweenPosts: any;
  protected IndexManifest: any;
  protected assigningCourier: any;
  protected assigningCourierSA: any;
  protected uploadSoftcopy: any;
  protected processPickUp: any;
  protected pickupData: any;
  protected pickupStatus: any;
  protected complaintNew: any;
  protected detailPickUp: any;
  protected privacyPolicy: any;
  protected deliveryPriority: any;
  protected serverVoip: any;
  protected restoreData: any;
  protected smsBlast: any;
  protected settingLocation: any;
  protected notFound: any;

  render() {
    this.login = CheckLogin(LoginContainer);
    this.dashboard = CheckToken(TemplateBerandaComponent(Dashboard));
    this.dashboardBranch = CheckToken(
      TemplateBerandaComponent(DashboardBranch)
    );
    this.profile = CheckToken(TemplateBerandaComponent(Profile));
    this.adminBranch = CheckToken(TemplateBerandaComponent(AdminBranch));
    this.branch = CheckToken(TemplateBerandaComponent(Branch));
    this.branchdetail =
      this.props.Token == null
        ? history.push('/login')
        : TemplateBerandaComponent(BranchDetail);
    this.customer = CheckToken(TemplateBerandaComponent(Customer));
    this.customerdetail =
      this.props.Token == null
        ? history.push('/login')
        : TemplateBerandaComponent(CustomerDetail);
    this.indexCustomerConfig = CheckToken(
      TemplateBerandaComponent(IndexCustomerConfig)
    );
    this.indexDelivery = CheckToken(TemplateBerandaComponent(indexDelivery));
    this.deliveryPrintWaybill = CheckToken(
      TemplateBerandaComponent(DeliveryPrintWaybill)
    );
    this.processPickUp = CheckToken(TemplateBerandaComponent(ProcessPickUp));
    this.pickupData = CheckToken(TemplateBerandaComponent(PickupData));
    this.pickupStatus = CheckToken(TemplateBerandaComponent(PickupStatus));
    this.complaintNew = CheckToken(TemplateBerandaComponent(ComplaintNew));
    this.callCenter = CheckToken(TemplateBerandaComponent(CallCenter));
    this.deliveryBetweenPosts = CheckToken(
      TemplateBerandaComponent(DeliveryBetweenPosts)
    );
    this.detailPickUp = CheckToken(TemplateBerandaComponent(DetailPickUp));
    this.deliverystatus = CheckToken(TemplateBerandaComponent(DeliveryStatus));
    this.resend = CheckToken(TemplateBerandaComponent(Resend));
    this.manualProcess = CheckToken(TemplateBerandaComponent(ManualProcess));
    this.report = CheckToken(TemplateBerandaComponent(Report));
    this.ringkasan = CheckToken(TemplateBerandaComponent(Ringkasan));
    this.holiday = CheckToken(TemplateBerandaComponent(Holiday));
    this.datadelivery = CheckToken(TemplateBerandaComponent(DataDelivery));
    this.logActivity = CheckToken(TemplateBerandaComponent(LogActivity));
    this.manageCustomerAccount = CheckToken(
      TemplateBerandaComponent(ManageCustomerAccount)
    );
    this.manageLevelAccount = CheckToken(
      TemplateBerandaComponent(ManageLevelAccount)
    );
    this.role = CheckToken(TemplateBerandaComponent(RoleContainer));
    this.addRole = CheckToken(TemplateBerandaComponent(AddRole));
    this.editRole = CheckToken(TemplateBerandaComponent(EditRole));
    this.viewRole = CheckToken(TemplateBerandaComponent(ViewRole));
    this.addLevelAccount = CheckToken(
      TemplateBerandaComponent(AddLevelAccount)
    );
    this.detailLevelAccount = CheckToken(
      TemplateBerandaComponent(DetailLevelAccount)
    );
    this.editLevelAccount = CheckToken(
      TemplateBerandaComponent(EditLevelAccount)
    );
    this.customerAccountDetail = CheckToken(
      TemplateBerandaComponent(CustomerAccountDetail)
    );
    this.complain = CheckToken(TemplateBerandaComponent(Complain));
    this.IndexManifest = CheckToken(TemplateBerandaComponent(IndexManifest));
    this.assigningCourier = CheckToken(
      TemplateBerandaComponent(AssigningCourier)
    );
    this.assigningCourierSA = CheckToken(
      TemplateBerandaComponent(AssigningCourierSA)
    );
    this.deliveryPriority = CheckToken(
      TemplateBerandaComponent(DeliveryPriority)
    );
    this.serverVoip = CheckToken(TemplateBerandaComponent(ServerVoip));
    this.restoreData = CheckToken(TemplateBerandaComponent(RestoreData));
    this.uploadSoftcopy = CheckToken(TemplateBerandaComponent(UploadSoftcopy));
    this.smsBlast = CheckToken(TemplateBerandaComponent(SmsBlast));
    this.settingLocation = CheckToken(
      TemplateBerandaComponent(SettingLocation)
    );
    this.notFound = NotFound;
    this.privacyPolicy = PrivacyPolicy;

    return (
      <Switch>
        <Route exact={true} path="/login" component={this.login} />
        <Route exact={true} path="/" component={this.dashboard} />
        <Route exact={true} path="/profile" component={this.profile} />
        <Route
          exact={true}
          path="/manageAccount"
          component={this.adminBranch}
        />
        <Route exact={true} path="/branch" component={this.branch} />
        <Route
          exact={true}
          path="/branch/detail"
          component={this.branchdetail}
        />
        <Route exact={true} path="/customer" component={this.customer} />
        <Route
          exact={true}
          path="/customer/detail"
          component={this.customerdetail}
        />
        <Route
          exact={true}
          path="/customer/config"
          component={this.indexCustomerConfig}
        />
        <Route
          exact={true}
          path="/delivery/uploadSoftcopy"
          component={this.uploadSoftcopy}
        />
        <Route
          exact={true}
          path="/delivery/document"
          component={this.indexDelivery}
        />
        <Route
          exact={true}
          path="/delivery/printWaybill"
          component={this.deliveryPrintWaybill}
        />
        <Route
          exact={true}
          path="/delivery/status"
          component={this.deliverystatus}
        />
        <Route exact={true} path="/resend" component={this.resend} />
        <Route
          exact={true}
          path="/manualProcess"
          component={this.manualProcess}
        />
        <Route exact={true} path="/laporan/download" component={this.report} />
        <Route
          exact={true}
          path="/laporan/ringkasan"
          component={this.ringkasan}
        />
        <Route exact={true} path="/holiday" component={this.holiday} />
        <Route
          exact={true}
          path="/dataDelivery"
          component={this.datadelivery}
        />
        <Route exact={true} path="/logActivity" component={this.logActivity} />
        <Route
          exact={true}
          path="/manageLevelAccount"
          component={this.manageLevelAccount}
        />
        <Route
          exact={true}
          path="/manageLevelAccount/addLevelAccount"
          component={this.addLevelAccount}
        />
        <Route
          exact={true}
          path="/manageLevelAccount/detailLevelAccount/:id"
          component={this.detailLevelAccount}
        />
        <Route
          exact={true}
          path="/manageLevelAccount/editLevelAccount/:id"
          component={this.editLevelAccount}
        />
        <Route exact={true} path="/role" component={this.role} />
        <Route exact={true} path="/role/addRole" component={this.addRole} />
        <Route
          exact={true}
          path="/role/editRole/:id"
          component={this.editRole}
        />
        <Route
          exact={true}
          path="/role/detailRole/:id"
          component={this.viewRole}
        />
        <Route
          exact={true}
          path="/manageCustomerAccount"
          component={this.manageCustomerAccount}
        />
        <Route
          exact={true}
          path="/manageCustomerAccount/detail"
          component={this.customerAccountDetail}
        />
        <Route exact={true} path="/complain" component={this.complain} />
        <Route exact={true} path="/callCenter" component={this.callCenter} />
        <Route
          exact={true}
          path="/deliveryBetweenPosts"
          component={this.deliveryBetweenPosts}
        />
        <Route
          exact={true}
          path="/IndexManifest"
          component={this.IndexManifest}
        />
        <Route
          exact={true}
          path="/assigningCourier"
          component={
            this.props.Role === 'Branch' ? this.assigningCourier : this.notFound
          }
        />
        <Route
          exact={true}
          path="/assigningCourierSA"
          component={
            this.props.Role === 'Admin'
              ? this.assigningCourierSA
              : this.notFound
          }
        />
        <Route
          exact={true}
          path="/PrivacyPolicy"
          component={this.privacyPolicy}
        />
        <Route
          exact={true}
          path="/complaintNew"
          component={this.complaintNew}
        />
        <Route exact={true} path="/pickupData" component={this.pickupData} />
        <Route
          exact={true}
          path="/pickupStatus"
          component={this.pickupStatus}
        />
        <Route
          exact={true}
          path="/processPickUp"
          component={this.processPickUp}
        />
        <Route
          exact={true}
          path="/processPickUp/detailPickUp"
          component={this.detailPickUp}
        />
        <Route
          exact={true}
          path="/deliveryPriority"
          component={this.deliveryPriority}
        />
        <Route exact={true} path="/voip" component={this.serverVoip} />
        <Route exact={true} path="/restore" component={this.restoreData} />
        <Route exact={true} path="/sms-blast" component={this.smsBlast} />
        <Route
          exact={true}
          path="/setting-location"
          component={this.settingLocation}
        />
        <Route exact={false} component={this.notFound} />
      </Switch>
    );
  }
}
const mapStateToProps = (state: any) => ({
  Token: state.Auth.Token,
  Role: state.Auth.Role,
});
const withConnect = connect(mapStateToProps);

export default compose(withConnect)(Navigation);
