import { Button, Tooltip } from 'antd';

import React from 'react';

interface IProps {
  buttonData?: any;
  buttonFloat?: any;
  isLoading?: any;
  containerStyle?: any;
  handleRefresh?: any;
  tooltipPlace?: any;
  tooltipTitle?: any;
  shape?: any;
  type?: any;
}

const renderRefresh = (handleRefresh: any, style: any) => {
  if (handleRefresh) {
    return (
      <Button icon={'reload'} onClick={handleRefresh} style={style}>
        Refresh
      </Button>
    );
  }
  return null;
};

export default function CButton(props: IProps) {
  const { buttonData, buttonFloat, containerStyle, handleRefresh } = props;

  let dataBtn;
  const style = {
    marginLeft: buttonFloat === 'right' ? '10px' : 0,
    marginRight: buttonFloat === 'right' ? 0 : '10px',
  };
  if (buttonData && Object.keys(buttonData).length) {
    dataBtn = buttonData.map((value: any, index: any) => {
      if (value) {
        const {
          content,
          icon,
          className,
          isLoading,
          tooltipPlace,
          tooltipTitle,
          shape,
          type,
          disabled,
        } = value;
        const propsBtn = {
          ...value,
          icon: icon,
          key: index,
          shape: shape,
          loading: isLoading,
          className: className,
          type: type,
          disabled,
        };
        if (tooltipTitle) {
          return (
            <Tooltip
              placement={tooltipPlace ? tooltipPlace : 'top'}
              title={tooltipTitle}
            >
              <Button {...propsBtn}>{content}</Button>
            </Tooltip>
          );
        } else {
          return <Button {...propsBtn}>{content}</Button>;
        }
      }
      return null;
    });
  }
  return (
    <React.Fragment>
      <span style={buttonFloat ? { float: buttonFloat } : {}}>
        {dataBtn}
        {renderRefresh(handleRefresh, style)}
      </span>
      <span className="clearfix" />
      <div style={containerStyle ? containerStyle : {}} />
    </React.Fragment>
  );
}
export const renderActionComponent = (
  isLoading,
  id,
  renderData,
  handleData,
  validate
) => {
  const buttonDataAction = [
    renderData.renderPassword === true
      ? {
          // type: 'primary',
          icon: 'lock',
          onClick: handleData.handlePassword,
          id: `btnPassword${id}`,
          className: 'btnResetPassword',
        }
      : null,
    renderData.renderInfo === true || renderData.renderDownload
      ? {
          // type: 'primary',
          icon: renderData.renderDownload ? 'download' : 'eye',
          onClick: handleData.handleDetail,
          id: `btnDetail${id}`,
          className: 'btnDetailTbl',
          disabled: validate.validateDetail,
          tooltipTitle: renderData.tooltipDownload,
        }
      : null,
    renderData.renderUpdate === true
      ? {
          // type: 'primary',
          icon: 'edit',
          onClick: handleData.handleUpdate,
          id: `btnEdit${id}`,
          className: 'btnEditTbl',
          disabled: validate.validateEdit,
        }
      : null,
    renderData.renderConfig === true
      ? {
          // type: 'danger',
          icon: 'setting',
          onClick: handleData.handleConfig,
          id: `btnConfig${id}`,
          className: 'btnConfig',
        }
      : null,
  ];
  return (
    <div>
      <CButton
        buttonData={buttonDataAction}
        isLoading={isLoading}
        containerStyle={{ marginBottom: '0px' }}
      />
    </div>
  );
};
