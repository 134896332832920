import React from 'react';
import styles from './PopoverElement.module.css';
import { Popover } from 'antd';

type PopoverElementProps = {
  content: React.ReactNode;
  children: React.ReactNode;
  onShow: (value: boolean) => void;
  show?: boolean;
  disabled?: boolean;
  placement?: any;
  labelCancel?: string;
  onClickOke?: () => void;
  onClickCancel?: () => void;
};

export default function PopoverElement({
  content,
  children,
  onShow,
  show = false,
  disabled = false,
  placement,
  onClickOke = () => undefined,
  onClickCancel = () => undefined,
  labelCancel = 'Tutup',
}: PopoverElementProps) {
  return (
    <Popover
      placement={placement}
      content={
        <div className={styles.wrapperContent}>
          <div className={styles.wrapperBody}>{content}</div>
          <div className={styles.wrapperFooter}>
            <p
              onClick={() => {
                onClickCancel();
                onShow(!show);
              }}
            >
              {labelCancel}
            </p>
            <p
              onClick={() => {
                onClickOke();
                onShow(!show);
              }}
            >
              Ok
            </p>
          </div>
        </div>
      }
      trigger="click"
      visible={show}
      onVisibleChange={() => !disabled && onShow(!show)}
      overlayClassName="custom-popover"
    >
      {children}
    </Popover>
  );
}
