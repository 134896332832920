import { createSelector } from 'reselect';

const selectorComplaintNew = (state: any) => state.ComplaintNew;

export const makeComplaintListSelector = () =>
  createSelector(selectorComplaintNew, state => state.list);
export const makeComplaintDetailDocSelector = () =>
  createSelector(selectorComplaintNew, state => state.complaintDetailDoc);
export const makeComplaintInvestigationFileSelector = () =>
  createSelector(selectorComplaintNew, state => state.investigationFile);
export const makeCourierInvestigationSelector = () =>
  createSelector(selectorComplaintNew, state => state.courierComplaint);