import { Action } from 'redux';

const initialState = {
  FormPrintWaybill: {
    SwitchValue: false,
    RadioButton: 'Upload',
    UploadDate: null,
    BranchId: null,
    CourierId: null,
    CustomerId: null,
    CustomerProductId: [],
    Waybill: null,
    FileData: null,
    DataListPrintWaybill: [],
    WaybillPrintType: '3Waybill',
    DeliveryDate: null,
    CountCheckbox: null,
    SeparatorAddress: false,
    Watermark: false,
    NoTelp: false,
    CodeBrankas: false,
  },
  CheckboxId: [],
};

export interface IAuthAction extends Action<string> {}

export interface IChangeSwitchValue extends IAuthAction {
  SwitchValue: any;
}
export interface IChangeSeparatorAddress extends IAuthAction {
  SeparatorAddress: any;
}
export interface IChangeWatermark extends IAuthAction {
  Watermark: any;
}
export interface IChangeNoTelp extends IAuthAction {
  NoTelp: any;
}
export interface IChangeCodeBrankas extends IAuthAction {
  CodeBrankas: any;
}
export interface IChangeRadioButton extends IAuthAction {
  RadioButton: any;
}
export interface IChangeUploadDate extends IAuthAction {
  UploadDate: any;
}
export interface IChangeBranchId extends IAuthAction {
  BranchId: any;
}
export interface IChangeCourierId extends IAuthAction {
  CourierId: any;
}
export interface IChangeCustomerId extends IAuthAction {
  CustomerId: any;
}
export interface IChangeCustomerProductId extends IAuthAction {
  CustomerProductId: any;
}

export interface IChangeWaybill extends IAuthAction {
  Waybill: any;
}
export interface IChangeFileData extends IAuthAction {
  FileData: any;
}
export interface ISetDataListPrintWaybill extends IAuthAction {
  DataListPrintWaybill: any;
}
export interface ISetWaybillPrintType extends IAuthAction {
  WaybillPrintType: any;
}
export interface ISetDeliveryDate extends IAuthAction {
  DeliveryDate: any;
}
export interface ISetCountCheckbox extends IAuthAction {
  CountCheckbox: any;
}
export interface IChangeCheckboxId extends IAuthAction {
  CheckboxId: never;
}
export interface IChangeRemoveCheckboxId extends IAuthAction {
  CheckboxId: never;
}

export default function AuthReducer(state = initialState, action: IAuthAction) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_SWITCH_VALUE_PW':
      const changeSwitchValue = action as IChangeSwitchValue;
      newState.FormPrintWaybill.SwitchValue = changeSwitchValue.SwitchValue;

      return { ...newState };

    case 'CHANGE_SEPARATOR_ADDRESS_PW':
      const changeSeparatorAddress = action as IChangeSeparatorAddress;
      newState.FormPrintWaybill.SeparatorAddress =
        changeSeparatorAddress.SeparatorAddress;

      return { ...newState };
    case 'CHANGE_WATERMARK_PW':
      const changeWatermark = action as IChangeWatermark;
      newState.FormPrintWaybill.Watermark = changeWatermark.Watermark;

      return { ...newState };

    case 'CHANGE_NOTELP_PW':
      const changeNotelp = action as IChangeNoTelp;
      newState.FormPrintWaybill.NoTelp = changeNotelp.NoTelp;

      return { ...newState };

    case 'CHANGE_CODEBRANKAS_PW':
      const changeCodeBrankas = action as IChangeCodeBrankas;
      newState.FormPrintWaybill.CodeBrankas = changeCodeBrankas.CodeBrankas;

      return { ...newState };

    case 'CHANGE_RADIO_BUTTON_PW':
      const changeRadioButton = action as IChangeRadioButton;
      newState.FormPrintWaybill.RadioButton = changeRadioButton.RadioButton;

      return { ...newState };

    case 'CHANGE_UPLOAD_DATE_PW':
      const changeUploadDate = action as IChangeUploadDate;
      newState.FormPrintWaybill.UploadDate = changeUploadDate.UploadDate;

      return { ...newState };

    case 'CHANGE_BRANCH_ID_PW':
      const changeBranchId = action as IChangeBranchId;
      newState.FormPrintWaybill.BranchId = changeBranchId.BranchId;

      return { ...newState };

    case 'CHANGE_COURIER_ID_PW':
      const changeCourierId = action as IChangeCourierId;
      newState.FormPrintWaybill.CourierId = changeCourierId.CourierId;

      return { ...newState };

    case 'CHANGE_CUSTOMER_ID_PW':
      const changeCustomerId = action as IChangeCustomerId;
      newState.FormPrintWaybill.CustomerId = changeCustomerId.CustomerId;

      return { ...newState };

    case 'CHANGE_CUSTOMER_PRODUCT_ID_PW':
      const changeCustomerProductId = action as IChangeCustomerProductId;
      newState.FormPrintWaybill.CustomerProductId =
        changeCustomerProductId.CustomerProductId;

      return { ...newState };

    case 'CHANGE_WAYBILL_PW':
      const changeWaybill = action as IChangeWaybill;
      newState.FormPrintWaybill.Waybill = changeWaybill.Waybill;

      return { ...newState };

    case 'CHANGE_FILE_DATA_PW':
      const changeFileData = action as IChangeFileData;
      newState.FormPrintWaybill.FileData = changeFileData.FileData;

      return { ...newState };

    case 'CHANGE_COUNT_CHECKBOX_PW':
      const changeCountCheckbox = action as ISetCountCheckbox;
      newState.FormPrintWaybill.CountCheckbox =
        changeCountCheckbox.CountCheckbox;

      return { ...newState };

    case 'CHANGE_WAYBILL_PRINT_TYPE_PW':
      const changeWaybillPrintType = action as ISetWaybillPrintType;
      newState.FormPrintWaybill.WaybillPrintType =
        changeWaybillPrintType.WaybillPrintType;

      return { ...newState };

    case 'CHANGE_DELIVERY_DATE_PW':
      const changeDeliveryDate = action as ISetDeliveryDate;
      newState.FormPrintWaybill.DeliveryDate = changeDeliveryDate.DeliveryDate;

      return { ...newState };

    case 'CHANGE_SELECTED_CHECKBOX_ID_PW':
      const changeCheckboxId = action as IChangeCheckboxId;
      newState.CheckboxId = [...state.CheckboxId, changeCheckboxId.CheckboxId];
      return { ...newState };

    case 'CHANGE_REMOVED_CHECKBOX_ID_PW':
      const changeRemovedCheckboxId = action as IChangeRemoveCheckboxId;
      const indexCheckboxId = newState.CheckboxId.indexOf(
        changeRemovedCheckboxId.CheckboxId
      );
      const arrayCheckboxId = newState.CheckboxId;
      const resultArrayCheckboxId = arrayCheckboxId.splice(indexCheckboxId, 1);
      console.log(resultArrayCheckboxId);

      return { ...newState };

    case 'SET_LIST_DATA_PW':
      const setDataPrintWaybill = action as ISetDataListPrintWaybill;
      newState.FormPrintWaybill.DataListPrintWaybill =
        setDataPrintWaybill.DataListPrintWaybill;

      return { ...newState };

    case 'CLEAR_LIST_DATA_PW':
      newState.FormPrintWaybill.DataListPrintWaybill = [];

      return { ...newState };

    case 'CLEAR_COUNT_CHECKBOX_PW':
      newState.FormPrintWaybill.CountCheckbox = null;

      return { ...newState };

    case 'CLEAR_WAYBILL_PRINT_TYPE_PW':
      newState.FormPrintWaybill.WaybillPrintType = '3Waybill';

      return { ...newState };
    case 'CLEAR_WATERMARK_PW':
      newState.FormPrintWaybill.Watermark = false;

      return { ...newState };

    case 'CLEAR_NOTELP_PW':
      newState.FormPrintWaybill.NoTelp = false;

      return { ...newState };

    case 'CLEAR_DELIVERY_DATE_PW':
      newState.FormPrintWaybill.DeliveryDate = null;

      return { ...newState };

    case 'CLEAR_LIST_AND_DATE_PW':
      newState.FormPrintWaybill.DataListPrintWaybill = [];
      newState.FormPrintWaybill.UploadDate = null;

      return { ...newState };

    case 'CLEAR_ALL_CHECKBOX_PW':
      newState.CheckboxId = [];

      return { ...newState };

    case 'CLEAR_WAYBILL_FORM_PW':
      newState.FormPrintWaybill.Waybill = null;
      return { ...newState };

    case 'CLEAR_UPLOAD_FORM_PW':
      newState.FormPrintWaybill.FileData = null;
      return { ...newState };

    case 'CLEAR_CUSTOMER_PRODUCT_PW':
      newState.FormPrintWaybill.CustomerProductId = [];
      return { ...newState };

    case 'CLEAR_PRINT_WAYBILL_FORM':
      newState.FormPrintWaybill.SwitchValue = false;
      newState.FormPrintWaybill.SeparatorAddress = false;
      newState.FormPrintWaybill.Watermark = false;
      newState.FormPrintWaybill.NoTelp = false;
      newState.FormPrintWaybill.RadioButton = 'Upload';
      newState.FormPrintWaybill.UploadDate = null;
      newState.FormPrintWaybill.BranchId = null;
      newState.FormPrintWaybill.CustomerId = null;
      newState.FormPrintWaybill.CustomerProductId = [];
      newState.FormPrintWaybill.CourierId = null;
      newState.FormPrintWaybill.Waybill = null;
      newState.FormPrintWaybill.FileData = null;

      return { ...newState };
  }
  return { ...newState };
}
