import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as ActionGetDataProfile from '../../../core/action/Profile/ActionProfile';
import * as Toggle from '../../../core/action/toggle';
import ProfileComponent from '../../../components/Content/Profile/ProfileComponent';

interface IProps {
  actionGetDataProfile: any;
  actionToggle: any;
  Photo: any;
  Initials: any;
  FirstName: any;
  LastName: any;
  UserName: any;
  NoTelp: any;
  Email: any;
  Address: any;
  isLoading: any;
}
class ProfileContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleModalEdit = this.handleModalEdit.bind(this);
    this.handleModalChangePassword = this.handleModalChangePassword.bind(this);
  }

  componentDidMount() {
    if (this.props.FirstName === null) {
      this.props.actionGetDataProfile.getDataProfile();
    }
  }

  handleModalEdit() {
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle('EditProfile');
  }

  handleModalChangePassword() {
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle('ChangePasswordProfile');
  }

  render() {
    return (
      <div>
        <ProfileComponent
          isLoading={this.props.isLoading}
          photo={this.props.Photo}
          firstName={this.props.FirstName}
          lastName={this.props.LastName}
          userName={this.props.UserName}
          initials={this.props.Initials}
          noTelp={this.props.NoTelp}
          email={this.props.Email}
          address={this.props.Address}
          handleModalEdit={this.handleModalEdit}
          handleModalChangePassword={this.handleModalChangePassword}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  Photo: state.Profile.Photo,
  Initials: state.Profile.Initials,
  FirstName: state.Profile.FirstName,
  LastName: state.Profile.LastName,
  UserName: state.Profile.UserName,
  NoTelp: state.Profile.NoTelp,
  Email: state.Profile.Email,
  Address: state.Profile.Address,
  isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionGetDataProfile: bindActionCreators(ActionGetDataProfile, dispatch),
  actionToggle: bindActionCreators(Toggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ProfileContainer);
