const FilterComplaintValidation = (values, props) => {
  const errors: any = {};
  const valueLength = values.complaintStatus
    ? values.complaintStatus.length
    : 0;
  if (valueLength === 0) {
    errors.complaintStatus = 'Status keluhan wajib diisi';
  }
  return errors;
};
export default FilterComplaintValidation;
