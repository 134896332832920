import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionPagination from '../../../action/Pagination/ActionPagination';
import * as LoadingCourier from '../../../action/ManageAccount/Courier/ActionLoadingCourier';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  changeLoadingFalse,
  changeLoadingTrue,
} from '../../../action/toggle/index';

import axios from 'axios';
import { setCourierTable } from '../../../action/ManageAccount/Courier/ActionTableCourier';

export function* S_getCourierDataTable(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const pagePagination = state.Pagination.PagePagination - 1;
  const sizeDataPagination = state.Pagination.SizeDataPagination;
  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        `/Courier/${pagePagination}/${sizeDataPagination}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(setCourierTable(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_getCourierDataTableBranch(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const router = state.router.location.pathname;
  const role = state.Auth.Role;

  const idBranch =
    router === '/delivery/document'
      ? state.Delivery.DistributionDocument.BranchId
      : router === '/resend'
      ? state.Resend.FormResendDelivery.ResendBranch
      : router === '/assigningCourier' || router === '/assigningCourierSA'
      ? state.AssigningCourier.branchId
      : router === '/manualProcess'
      ? state.ManualProcess.ManualProcessForm.AreaCodeIdMprocess !== null
        ? state.ManualProcess.ManualProcessForm.AreaCodeIdMprocess
        : state.ManualProcess.ManualProcessVendorForm.BranchId !== null
        ? state.ManualProcess.ManualProcessVendorForm.BranchId
        : state.ManualProcess.EmergencyProcess.Branch
      : router === '/laporan/download'
      ? state.Report.CourierReportForm.BranchCourierR === null ||
        state.Report.CourierReportForm.BranchCourierR.length === 0
        ? state.Report.CourierBonusReportForm.Branch
        : state.Report.CourierReportForm.BranchCourierR
      : router === '/dataDelivery'
      ? state.DataDelivery.Filter.Branch
      : role === 'Branch'
      ? state.Auth.BranchAuth
      : state.Branch.PropertiesBranch.idBranch;

  const isAllCourier =
    router === '/laporan/download' || router === '/dataDelivery' ? true : false;

  try {
    yield put(LoadingCourier.setLoadingCourier());
    yield put(LoadingCourier.setTypeLoadingCourier('selectCourier'));

    if (router === '') {
      yield put(changeLoadingTrue());
    }
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Courier/branch/${idBranch}?isAll=${isAllCourier}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(setCourierTable(data.data));
    yield put(LoadingCourier.clearLoadingCourier());
    if (router === '') {
      yield put(changeLoadingFalse());
    }
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_searchTableCourier(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const branchId = state.Auth.BranchAuth;
  const searchValue = state.Toggle.ValueSearch;
  const pagePagination = state.Pagination.PagePagination;
  const sizeDataPagination = state.Pagination.SizeDataPagination;
  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/SearchDataCourier/${pagePagination}/${sizeDataPagination}?Branch=${branchId}&NameOrCode=${searchValue}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(setCourierTable(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(changeLoadingFalse());
  }
}

export function* SM_CourierTable() {
  yield takeLatest('GET_TABLE_COURIER', S_getCourierDataTable);
}

export function* SM_CourierTableBranch() {
  yield takeLatest('GET_TABLE_COURIER_BRANCH', S_getCourierDataTableBranch);
}

export function* SM_SearchTableCourier() {
  yield takeLatest('SUBMIT_SEARCH_COURIER', S_searchTableCourier);
}
