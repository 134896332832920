import React, { Component } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import TablePrintWaybillComponent from '../../../../components/Content/Delivery/Document/TablePrintWaybillComponent';
import * as ActionDataScan from '../../../../core/action/Delivery/Document/ActionFormPrintWaybill';
import * as Toggle from '../../../../core/action/toggle';
import * as ActionPagination from '../../../../core/action/Pagination/ActionPagination';
import * as ActionFormPrintWaybill from '../../../../core/action/Delivery/PrintWaybill/ActionFormPrintWaybill';

interface IProps
{
  actionDataScan:any
  actionToggle:any
  actionFormPrintWaybill:any
  isLoading:any
  dataScan:any
  //BATAS CODE LAMA
  DataListPrintWaybill:any
  TotalPagePagination:any
  PagePagination:any
  actionPagination:any
}

interface IState
{
    page:any
}

class TablePrintWaybillContainer extends Component<IProps,IState> {
    constructor(props:any)
    {
        super(props)
        this.state=
        {
            page:null
        }
        this.handlePage = this.handlePage.bind(this)
        
    }
    handlePage(event:any)
    {
        this.props.actionPagination.setPagePagination(event)
        this.setState({
            page:event,
        });
        this.props.actionFormPrintWaybill.getListDataPrintWaybill()
    }
    render() {
        return (
            <TablePrintWaybillComponent
                data={this.props.dataScan}
                isLoading={this.props.isLoading}
            //BATAS CODE LAMA
                TotalPagePagination={this.props.TotalPagePagination}
                PagePagination={this.props.PagePagination}
                handlePage={this.handlePage}
                DataListPrintWaybill={this.props.DataListPrintWaybill}
                page={this.state.page}
            />
        )
    }
}
const mapStateProps = (state:any) => ({
    dataScan:state.Delivery.PrintWaybill.ListData,
    isLoading:state.Toggle.isLoading,
    //BATAS CODE LAMA
    TotalPagePagination:state.Pagination.TotalPagePagination,
    PagePagination:state.Pagination.PagePagination,
    DataListPrintWaybill:state.PrintWaybill.FormPrintWaybill.DataListPrintWaybill,
})
const mapDispatchToProps = (dispatch:any) => ({
    actionDataScan : bindActionCreators(ActionDataScan, dispatch),
    actionToggle : bindActionCreators(Toggle, dispatch),
    //BATAS CODE LAMA
    actionPagination : bindActionCreators(ActionPagination, dispatch),
    actionFormPrintWaybill : bindActionCreators(ActionFormPrintWaybill, dispatch)
})

const withConnect = connect(mapStateProps, mapDispatchToProps)

export default compose(
    withConnect
)(TablePrintWaybillContainer)
