import { Button, Col, Row, Table } from 'antd';
import ModalMaterial from 'materials/ModalMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import React from 'react';

export interface IProps {
  modalWaybillOption: boolean;
  handleCancel: () => void;
  dataWaybillOption: any[];
  columnData: any[];
  selectedWaybill: string | null;
  handleScanWaybill: () => void;
  isLoadingMutation: boolean;
}
export default function ModalWaybillOptionComponent(props: IProps) {
  const {
    modalWaybillOption,
    handleCancel,
    dataWaybillOption,
    columnData,
    selectedWaybill,
    handleScanWaybill,
    isLoadingMutation,
  } = props;
  const footer = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '16px 24px',
        }}
      >
        <Button
          type="primary"
          onClick={() => {
            handleScanWaybill();
          }}
          disabled={!selectedWaybill}
          style={{ borderRadius: '20px', width: '100px' }}
        >
          Pilih
        </Button>
      </div>
    );
  };
  return (
    <ModalMaterial
      width={'70%'}
      teksTitle="Pilih Barcode untuk di-Scan"
      visible={modalWaybillOption}
      onCancel={() => handleCancel()}
      footer={footer()}
    >
      <SpinMaterial spinning={isLoadingMutation} size="large">
        <Row style={{ padding: '0 36px', marginTop: '20px' }} gutter={[0, 24]}>
          <Col span={24}>
            <p
              style={{
                margin: 0,
                color: '#EA1A1A',
              }}
            >
              Karena Barcode duplikat, pilih salah satu data
            </p>
          </Col>
        </Row>

        <Table
          style={{ padding: '16px 24px' }}
          rowKey={(record: any) => record.id}
          scroll={{ x: 'auto' }}
          bordered={false}
          dataSource={dataWaybillOption || []}
          columns={columnData}
        />
      </SpinMaterial>
    </ModalMaterial>
  );
}
