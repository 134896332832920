export function setDateHoliday(DateHoliday:any)
{
    return({
        type:"SET_DATE_HOLIDAY",
        DateHoliday
    })
}
export function setEventHoliday(EventHoliday:any)
{
    return({
        type:"SET_EVENT_HOLIDAY",
        EventHoliday
    })
}
export function setActiveHoliday()
{
    return({
        type:"SET_ACTIVE_HOLIDAY"
    })
}
export function clearDateHoliday()
{
    return({
        type:"CLEAR_DATE_HOLIDAY"
    })
}
export function clearEventHoliday()
{
    return({
        type:"CLEAR_EVENT_HOLIDAY"
    })
}
export function clearAllFormManualHoliday()
{
    return({
        type:"CLEAR_ALL_FORM_MANUAL_HOLIDAY"
    })
}