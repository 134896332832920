import { Action } from 'redux';

const initialState = {
  PropertiesManualProcess: {
    RadiobuttonTypeMprocess: null,
  },
  ManualProcessForm: {
    RadiobuttonStatusMprocess: 'Success',
    AreaCodeIdMprocess: null,
    CourierCodeMprocess: null,
    CustomerMprocess: null,
    ReasonMprocess: [],
    ReasonOthersMprocess: null,
    DateMprocess: null,
    ReceiveNameMprocess: null,
    WaybillMprocess: null,
    InformationMprocess: null,
    InformationOthersMprocess: null,
    BarcodeCheckBox: false,
  },
  ListSubmitManualProcess: [],
  ManualProcessVendorForm: {
    BranchId: null,
    CourierId: null,
    FileName: null,
    FileData: null,
    FormatDate: null,
    TypeUpload: 'Internal',
    CheckBox: false,
    isChangeCourier: false,
  },
  EmergencyProcess: {
    ModeEmergency: null,
    DocumentType: null,
    District: null,
    Branch: null,
    Courier: null,
    Weight: 0,
    Koli: 0,
    ListKoli: [0],
    DeliveryTime: null,
    FinishTime: null,
    NumberResi: null,
    Waybill: null,
    Csv: null,
    BarcodeColumn: null,
    TypeDelivery: null,
    ReceiverName: null,
    DeliveryNote: null,
    FileName: null,
    CountSuccess: null,
    CountFailed: null,
  },
  updateAddress: {
    radioButton: 'single',
    waybill: null,
    waybillForm: null,
    name: null,
    phoneNumber: null,
    customer: null,
    customerProduct: null,
    templateUpload: null,
    cycleDate: null,
    fileUpload: null,
    fileName: null,
    address: null,
    resultUpload: null,
    customerProductList: [],
    templateUploadList: [],
  },
};

export interface IAuthAction extends Action<string> {}

export interface ISetListSubmitMprocess extends IAuthAction {
  ListSubmitManualProcess: any;
}
export interface IChangeRadiobuttonTypeMprocess extends IAuthAction {
  RadiobuttonTypeMprocess: any;
}
export interface IChangeRadiobuttonStatusIdMprocess extends IAuthAction {
  RadiobuttonStatusMprocess: any;
}
export interface IChangeAreaCodeIdMprocess extends IAuthAction {
  AreaCodeIdMprocess: any;
}
export interface IChangeCourierCodeMprocess extends IAuthAction {
  CourierCodeMprocess: any;
}
export interface IChangeCustomerMprocess extends IAuthAction {
  CustomerMprocess: any;
}
export interface IChangeReasonMprocess extends IAuthAction {
  ReasonMprocess: any;
}
export interface IChangeReasonOthersMprocess extends IAuthAction {
  ReasonOthersMprocess: any;
}
export interface IChangeDateMprocess extends IAuthAction {
  DateMprocess: any;
}
export interface IChangeReceiveNameMprocess extends IAuthAction {
  ReceiveNameMprocess: any;
}
export interface IChangeWaybillMprocess extends IAuthAction {
  WaybillMprocess: any;
}
export interface IChangeInformationMprocess extends IAuthAction {
  InformationMprocess: any;
}
export interface IChangeInformationOthersMprocess extends IAuthAction {
  InformationOthersMprocess: any;
}

export interface IChangeBranchIdManualProcessVendor extends IAuthAction {
  BranchId: any;
}

export interface IChangeCourierIdManualProcessVendor extends IAuthAction {
  CourierId: any;
}

export interface IChangeFileDataManualProcessVendor extends IAuthAction {
  FileData: any;
}
export interface IChangeFileNameManualProcessVendor extends IAuthAction {
  FileName: any;
}

export interface IChangeFormatDateManualProcessVendor extends IAuthAction {
  FormatDate: any;
}
export interface IChangeTypeUploadManualProcessVendor extends IAuthAction {
  TypeUpload: any;
}

export interface IChangeModeEmergency extends IAuthAction {
  ModeEmergency: any;
}

export interface IChangeDocumentType extends IAuthAction {
  DocumentType: any;
}

export interface IChangeDistrict extends IAuthAction {
  District: any;
}

export interface IChangeBranch extends IAuthAction {
  Branch: any;
}
export interface IChangeCourier extends IAuthAction {
  Courier: any;
}

export interface IChangeWeight extends IAuthAction {
  Weight: any;
}

export interface IChangeKoli extends IAuthAction {
  Koli: any;
}

export interface IChangeDeliveryTime extends IAuthAction {
  DeliveryTime: any;
}

export interface IChangeFinishTime extends IAuthAction {
  FinishTime: any;
}

export interface IChangeNumberResi extends IAuthAction {
  NumberResi: any;
}

export interface IChangeWaybill extends IAuthAction {
  Waybill: any;
}

export interface IChangeCsv extends IAuthAction {
  Csv: any;
}
export interface IChangeBarcodeColumn extends IAuthAction {
  BarcodeColumn: any;
}
export interface IChangeTypeDelivery extends IAuthAction {
  TypeDelivery: any;
}
export interface IChangeReceiverName extends IAuthAction {
  ReceiverName: any;
}
export interface IChangeDeliveryNote extends IAuthAction {
  DeliveryNote: any;
}
export interface IChangeFileName extends IAuthAction {
  FileName: any;
}
export interface IChangeCountSuccess extends IAuthAction {
  CountSuccess: any;
}
export interface IChangeCountFailed extends IAuthAction {
  CountFailed: any;
}
export interface IChangeListKoli extends IAuthAction {
  ListKoli: any;
}
export interface IChangeRadioButtonUA extends IAuthAction {
  radioButton: any;
}
export interface IChangeWaybillUA extends IAuthAction {
  waybill: any;
}
export interface IChangeWaybillFormUA extends IAuthAction {
  waybillForm: any;
}
export interface IChangeCustomerUA extends IAuthAction {
  customer: any;
}
export interface IChangeCustomerProductUA extends IAuthAction {
  customerProduct: any;
}
export interface IChangeTemplateUploadUA extends IAuthAction {
  templateUpload: any;
}
export interface IChangeCycleDateUA extends IAuthAction {
  cycleDate: any;
}
export interface IChangeFileUploadUA extends IAuthAction {
  fileUpload: any;
}
export interface IChangeFileNameUA extends IAuthAction {
  fileName: any;
}
export interface IChangeNameUA extends IAuthAction {
  name: any;
}
export interface IChangePhoneNumberUA extends IAuthAction {
  phoneNumber: any;
}
export interface IChangeAddressUA extends IAuthAction {
  address: any;
}
export interface IFetchCustomerProductUA extends IAuthAction {
  customerProductList: any;
}
export interface IFetchResultUploadUA extends IAuthAction {
  resultUpload: any;
}
export interface IFetchTemplateUploadUA extends IAuthAction {
  templateUploadList: any;
}
export default function AuthReducer(state = initialState, action: IAuthAction) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'SET_LIST_SUBMIT_MANUAL_PROCESS':
      const setListSubmitMprocess = action as ISetListSubmitMprocess;
      newState.ListSubmitManualProcess =
        setListSubmitMprocess.ListSubmitManualProcess;
      return { ...newState };

    case 'CHANGE_RADIO_BUTTON_TYPE_MANUAL_PROCESS':
      const changeRadiobuttonTypeMprocess =
        action as IChangeRadiobuttonTypeMprocess;
      newState.PropertiesManualProcess.RadiobuttonTypeMprocess =
        changeRadiobuttonTypeMprocess.RadiobuttonTypeMprocess;
      return { ...newState };

    case 'CHANGE_RADIO_BUTTON_STATUS_MANUAL_PROCESS':
      const changeRadiobuttonStatusMprocess =
        action as IChangeRadiobuttonStatusIdMprocess;
      newState.ManualProcessForm.RadiobuttonStatusMprocess =
        changeRadiobuttonStatusMprocess.RadiobuttonStatusMprocess;
      return { ...newState };

    case 'CHANGE_AREA_CODE_ID_MANUAL_PROCESS':
      const changeAreaCodeIdMprocess = action as IChangeAreaCodeIdMprocess;
      newState.ManualProcessForm.AreaCodeIdMprocess =
        changeAreaCodeIdMprocess.AreaCodeIdMprocess;
      return { ...newState };

    case 'CHANGE_COURIER_CODE_MANUAL_PROCESS':
      const changeCourierCodeMprocess = action as IChangeCourierCodeMprocess;
      newState.ManualProcessForm.CourierCodeMprocess =
        changeCourierCodeMprocess.CourierCodeMprocess;
      return { ...newState };

    case 'CHANGE_CUSTOMER_MANUAL_PROCESS':
      const changeCustomerMprocess = action as IChangeCustomerMprocess;
      newState.ManualProcessForm.CustomerMprocess =
        changeCustomerMprocess.CustomerMprocess;
      return { ...newState };

    case 'CHANGE_REASON_MANUAL_PROCESS':
      const changeReasonMprocess = action as IChangeReasonMprocess;
      newState.ManualProcessForm.ReasonMprocess =
        changeReasonMprocess.ReasonMprocess;
      return { ...newState };

    case 'CHANGE_REASON_OTHERS_MANUAL_PROCESS':
      const changeReasonOthersMprocess = action as IChangeReasonOthersMprocess;
      newState.ManualProcessForm.ReasonOthersMprocess =
        changeReasonOthersMprocess.ReasonOthersMprocess;
      return { ...newState };

    case 'CHANGE_DATE_MANUAL_PROCESS':
      const changeDateMprocess = action as IChangeDateMprocess;
      newState.ManualProcessForm.DateMprocess = changeDateMprocess.DateMprocess;
      return { ...newState };

    case 'CHANGE_RECEIVE_NAME_MANUAL_PROCESS':
      const changeReceiveNameMprocess = action as IChangeReceiveNameMprocess;
      newState.ManualProcessForm.ReceiveNameMprocess =
        changeReceiveNameMprocess.ReceiveNameMprocess;
      return { ...newState };

    case 'CHANGE_WAYBILL_MANUAL_PROCESS':
      const changeWaybillMprocess = action as IChangeWaybillMprocess;
      newState.ManualProcessForm.WaybillMprocess =
        changeWaybillMprocess.WaybillMprocess;
      return { ...newState };

    case 'CHANGE_INFORMATION_MANUAL_PROCESS':
      const changeInformationMprocess = action as IChangeInformationMprocess;
      newState.ManualProcessForm.InformationMprocess =
        changeInformationMprocess.InformationMprocess;
      return { ...newState };

    case 'CHANGE_INFORMATION_OTHERS_MANUAL_PROCESS':
      const changeInformationOthersMprocess =
        action as IChangeInformationOthersMprocess;
      newState.ManualProcessForm.InformationOthersMprocess =
        changeInformationOthersMprocess.InformationOthersMprocess;
      return { ...newState };

    case 'CHANGE_CHECKBOX_WAYBILL_OR_BARCODE':
      newState.ManualProcessForm.BarcodeCheckBox =
        !newState.ManualProcessForm.BarcodeCheckBox;
      return { ...newState };

    case 'CLEAR_RADIO_BUTTON_MANUAL_PROCESS':
      newState.PropertiesManualProcess.RadiobuttonTypeMprocess = null;
      return { ...newState };

    case 'CLEAR_COURIER_CODE_MANUAL_PROCESS':
      newState.ManualProcessForm.CourierCodeMprocess = null;
      return { ...newState };

    case 'CLEAR_REASON_MANUAL_PROCESS':
      newState.ManualProcessForm.ReasonMprocess = [];
      return { ...newState };

    case 'CLEAR_REASON_OTHERS_MANUAL_PROCESS':
      newState.ManualProcessForm.ReasonOthersMprocess = null;
      return { ...newState };

    case 'CLEAR_INFORMATION_OTHERS_MANUAL_PROCESS':
      newState.ManualProcessForm.InformationOthersMprocess = null;
      return { ...newState };

    case 'CLEAR_WAYBILL_MANUAL_PROCESS':
      newState.ManualProcessForm.WaybillMprocess = null;
      return { ...newState };

    case 'CLEAR_RECEIVE_NAME_MANUAL_PROCESS_FORM':
      newState.ManualProcessForm.ReceiveNameMprocess = null;
      return { ...newState };

    case 'CLEAR_RADIO_BUTTON_STATUS':
      newState.ManualProcessForm.RadiobuttonStatusMprocess = 'Success';
      return { ...newState };

    case 'CLEAR_RADIO_BUTTON_TYPE':
      newState.PropertiesManualProcess.RadiobuttonTypeMprocess = null;
      return { ...newState };

    case 'CLEAR_MANUAL_PROCESS_FORM':
      newState.ManualProcessForm.RadiobuttonStatusMprocess = 'Success';
      newState.ManualProcessForm.AreaCodeIdMprocess = null;
      newState.ManualProcessForm.WaybillMprocess = null;
      newState.ManualProcessForm.CourierCodeMprocess = null;
      newState.ManualProcessForm.CustomerMprocess = null;
      newState.ManualProcessForm.ReasonMprocess = [];
      newState.ManualProcessForm.ReasonOthersMprocess = null;
      newState.ManualProcessForm.DateMprocess = null;
      newState.ManualProcessForm.ReceiveNameMprocess = null;
      newState.ManualProcessForm.WaybillMprocess = null;
      newState.ManualProcessForm.InformationMprocess = null;
      newState.ManualProcessForm.InformationOthersMprocess = null;
      newState.ManualProcessForm.BarcodeCheckBox = false;
      newState.PropertiesManualProcess.RadiobuttonTypeMprocess = null;
      newState.ListSubmitManualProcess = [];
      return { ...newState };

    case 'CLEAR_FORM_WHEN_CHANGE_RADIO_BUTTON':
      newState.ManualProcessForm.AreaCodeIdMprocess = null;
      newState.ManualProcessForm.WaybillMprocess = null;
      newState.ManualProcessForm.CourierCodeMprocess = null;
      newState.ManualProcessForm.CustomerMprocess = null;
      newState.ManualProcessForm.ReasonMprocess = [];
      newState.ManualProcessForm.ReasonOthersMprocess = null;
      newState.ManualProcessForm.DateMprocess = null;
      newState.ManualProcessForm.ReceiveNameMprocess = null;
      newState.ManualProcessForm.WaybillMprocess = null;
      newState.ManualProcessForm.InformationMprocess = null;
      newState.ManualProcessForm.InformationOthersMprocess = null;
      newState.ManualProcessForm.BarcodeCheckBox = false;
      return { ...newState };

    case 'CLEAR_LIST_MANUAL_PROCESS':
      newState.ListSubmitManualProcess = [];
      return { ...newState };

    case 'CLEAR_BRANCH_AND_COURIER_MANUAL_PROCESS':
      newState.ManualProcessForm.AreaCodeIdMprocess = null;
      newState.ManualProcessForm.CourierCodeMprocess = null;
      return { ...newState };

    case 'CHANGE_BRANCH_MANUAL_PROCESS_VENDOR_FORM':
      const changeBranchManualProcessVendor =
        action as IChangeBranchIdManualProcessVendor;
      newState.ManualProcessVendorForm.BranchId =
        changeBranchManualProcessVendor.BranchId;
      return { ...newState };

    case 'CHANGE_COURIER_MANUAL_PROCESS_VENDOR_FORM':
      const changeCourierManualProcessVendor =
        action as IChangeCourierIdManualProcessVendor;
      newState.ManualProcessVendorForm.CourierId =
        changeCourierManualProcessVendor.CourierId;
      return { ...newState };

    case 'CHANGE_FILE_DATA_MANUAL_PROCESS_VENDOR_FORM':
      const changeFileDataManualProcessVendor =
        action as IChangeFileDataManualProcessVendor;
      newState.ManualProcessVendorForm.FileData =
        changeFileDataManualProcessVendor.FileData;
      return { ...newState };

    case 'CHANGE_FILE_NAME_MANUAL_PROCESS_VENDOR_FORM':
      const changeFileNameManualProcessVendor =
        action as IChangeFileNameManualProcessVendor;
      newState.ManualProcessVendorForm.FileName =
        changeFileNameManualProcessVendor.FileName;
      return { ...newState };

    case 'CHANGE_FORMAT_DATE_MANUAL_PROCESS_VENDOR_FORM':
      const changeFormatDateManualProcessVendor =
        action as IChangeFormatDateManualProcessVendor;
      newState.ManualProcessVendorForm.FormatDate =
        changeFormatDateManualProcessVendor.FormatDate;
      return { ...newState };

    case 'CHANGE_TYPE_UPLOAD_MANUAL_PROCESS_VENDOR_FORM':
      const changeTypeUploadManualProcessVendor =
        action as IChangeTypeUploadManualProcessVendor;
      newState.ManualProcessVendorForm.TypeUpload =
        changeTypeUploadManualProcessVendor.TypeUpload;
      return { ...newState };

    case 'CHANGE_CHECKBOX_MANUAL_PROCESS_VENDOR_FORM':
      newState.ManualProcessVendorForm.CheckBox =
        !newState.ManualProcessVendorForm.CheckBox;
      return { ...newState };

    case 'CHANGE_CHECKBOX_COURIER_MANUAL_PROCESS_VENDOR_FORM':
      newState.ManualProcessVendorForm.isChangeCourier =
        !newState.ManualProcessVendorForm.isChangeCourier;
      if (!newState.ManualProcessVendorForm.isChangeCourier) {
        newState.ManualProcessVendorForm.BranchId = null;
        newState.ManualProcessVendorForm.CourierId = null;
      }
      return { ...newState };

    case 'CLEAR_COURIER_MANUAL_PROCESS_VENDOR_FROM':
      newState.ManualProcessVendorForm.CourierId = null;

      return { ...newState };

    case 'CLEAR_BRANCH_AND_COURIER_VENDOR_FROM':
      newState.ManualProcessVendorForm.BranchId = null;
      newState.ManualProcessVendorForm.CourierId = null;
      return { ...newState };

    case 'CLEAR_MANUAL_PROCESS_VENDOR_FROM':
      newState.ManualProcessVendorForm.BranchId = null;
      newState.ManualProcessVendorForm.CourierId = null;
      newState.ManualProcessVendorForm.FileName = null;
      newState.ManualProcessVendorForm.FileData = null;
      newState.ManualProcessVendorForm.FormatDate = null;
      newState.ManualProcessVendorForm.TypeUpload = 'Internal';
      newState.ManualProcessVendorForm.CheckBox = false;
      newState.ManualProcessVendorForm.isChangeCourier = false;

      return { ...newState };

    case 'CHANGE_MODE_EMERGENCY':
      const changeModeEmergency = action as IChangeModeEmergency;
      newState.EmergencyProcess.ModeEmergency =
        changeModeEmergency.ModeEmergency;
      return { ...newState };

    case 'CHANGE_DOCUMENT_TYPE_EMERGENCY':
      const changeDocumentType = action as IChangeDocumentType;
      newState.EmergencyProcess.DocumentType = changeDocumentType.DocumentType;
      return { ...newState };

    case 'CLEAR_DOCUMENT_TYPE_EMERGENCY':
      newState.EmergencyProcess.DocumentType = null;
      return { ...newState };

    case 'CHANGE_DISTRICT_EMERGENCY':
      const changeDistrict = action as IChangeDistrict;
      newState.EmergencyProcess.District = changeDistrict.District;
      return { ...newState };

    case 'CLEAR_DISTRICT_EMERGENCY':
      newState.EmergencyProcess.District = null;
      return { ...newState };

    case 'CHANGE_BRANCH_EMERGENCY':
      const changeBranch = action as IChangeBranch;
      newState.EmergencyProcess.Branch = changeBranch.Branch;
      return { ...newState };

    case 'CLEAR_BRANCH_EMERGENCY':
      newState.EmergencyProcess.Branch = null;
      return { ...newState };

    case 'CHANGE_COURIER_EMERGENCY':
      const changeCourier = action as IChangeCourier;
      newState.EmergencyProcess.Courier = changeCourier.Courier;
      return { ...newState };

    case 'CLEAR_COURIER_EMERGENCY':
      newState.EmergencyProcess.Courier = null;
      return { ...newState };

    case 'CHANGE_WEIGHT_EMERGENCY':
      const changeWeight = action as IChangeWeight;
      newState.EmergencyProcess.Weight = changeWeight.Weight;
      return { ...newState };

    case 'CLEAR_WEIGHT_EMERGENCY':
      newState.EmergencyProcess.Weight = 0;
      return { ...newState };

    case 'CHANGE_KOLI_EMERGENCY':
      const changeKoli = action as IChangeKoli;
      newState.EmergencyProcess.Koli = changeKoli.Koli;
      return { ...newState };

    case 'CLEAR_KOLI_EMERGENCY':
      newState.EmergencyProcess.Koli = 0;
      return { ...newState };

    case 'CHANGE_LIST_KOLI_EMERGENCY':
      const changeListKoli = action as IChangeListKoli;
      newState.EmergencyProcess.ListKoli = changeListKoli.ListKoli;
      return { ...newState };

    case 'CLEAR_LIST_KOLI_EMERGENCY':
      newState.EmergencyProcess.ListKoli = [0];
      return { ...newState };

    case 'CHANGE_DELIVERY_TIME_EMERGENCY':
      const changeDeliveryTime = action as IChangeDeliveryTime;
      newState.EmergencyProcess.DeliveryTime = changeDeliveryTime.DeliveryTime;
      return { ...newState };

    case 'CLEAR_DELIVERY_TIME_EMERGENCY':
      newState.EmergencyProcess.DeliveryTime = null;
      return { ...newState };

    case 'CHANGE_FINISH_TIME_EMERGENCY':
      const changeFinishTime = action as IChangeFinishTime;
      newState.EmergencyProcess.FinishTime = changeFinishTime.FinishTime;
      return { ...newState };

    case 'CLEAR_FINISH_TIME_EMERGENCY':
      newState.EmergencyProcess.FinishTime = null;
      return { ...newState };

    case 'CHANGE_NUMBER_RESI_EMERGENCY':
      const changeNumberResi = action as IChangeNumberResi;
      newState.EmergencyProcess.NumberResi = changeNumberResi.NumberResi;
      return { ...newState };

    case 'CLEAR_NUMBER_RESI_EMERGENCY':
      newState.EmergencyProcess.NumberResi = null;
      return { ...newState };

    case 'CHANGE_WAYBILL_EMERGENCY':
      const changeWaybill = action as IChangeWaybill;
      newState.EmergencyProcess.Waybill = changeWaybill.Waybill;
      return { ...newState };

    case 'CLEAR_WAYBILL_EMERGENCY':
      newState.EmergencyProcess.Waybill = null;
      return { ...newState };

    case 'CHANGE_CSV_EMERGENCY':
      const changeCsv = action as IChangeCsv;
      newState.EmergencyProcess.Csv = changeCsv.Csv;
      return { ...newState };

    case 'CHANGE_BARCODE_COLUMN_EMERGENCY':
      const changeBarcodeColumn = action as IChangeBarcodeColumn;
      newState.EmergencyProcess.BarcodeColumn =
        changeBarcodeColumn.BarcodeColumn;
      return { ...newState };

    case 'CHANGE_TYPE_DELIVERY_EMERGENCY':
      const changeTypeDelivery = action as IChangeTypeDelivery;
      newState.EmergencyProcess.TypeDelivery = changeTypeDelivery.TypeDelivery;
      return { ...newState };

    case 'CHANGE_RECEIVER_NAME_EMERGENCY':
      const changeReceiverName = action as IChangeReceiverName;
      newState.EmergencyProcess.ReceiverName = changeReceiverName.ReceiverName;
      return { ...newState };

    case 'CHANGE_DELIVERY_NOTE_EMERGENCY':
      const changeDeliveryNote = action as IChangeDeliveryNote;
      newState.EmergencyProcess.DeliveryNote = changeDeliveryNote.DeliveryNote;
      return { ...newState };

    case 'CHANGE_FILE_NAME_EMERGENCY':
      const changeFileName = action as IChangeFileName;
      newState.EmergencyProcess.FileName = changeFileName.FileName;
      return { ...newState };

    case 'CHANGE_COUNT_SUCCESS_EMERGENCY':
      const changeCountSuccess = action as IChangeCountSuccess;
      newState.EmergencyProcess.CountSuccess = changeCountSuccess.CountSuccess;
      return { ...newState };

    case 'CHANGE_COUNT_FAILED_EMERGENCY':
      const changeCountFailed = action as IChangeCountFailed;
      newState.EmergencyProcess.CountFailed = changeCountFailed.CountFailed;
      return { ...newState };

    case 'CLEAR_CSV_EMERGENCY':
      newState.EmergencyProcess.Csv = null;
      return { ...newState };

    case 'CLEAR_BRANCH_AND_COURIER_EMERGENCY':
      newState.EmergencyProcess.Branch = null;
      newState.EmergencyProcess.Courier = null;
      return { ...newState };

    case 'CLEAR_ALL_EMERGENCY_FORM':
      newState.EmergencyProcess.Branch = null;
      newState.EmergencyProcess.Courier = null;
      newState.EmergencyProcess.Csv = null;
      newState.EmergencyProcess.BarcodeColumn = null;
      newState.EmergencyProcess.TypeDelivery = null;
      newState.EmergencyProcess.ReceiverName = null;
      newState.EmergencyProcess.DeliveryNote = null;
      newState.EmergencyProcess.DeliveryTime = null;
      newState.EmergencyProcess.District = null;
      newState.EmergencyProcess.DocumentType = null;
      newState.EmergencyProcess.FinishTime = null;
      newState.EmergencyProcess.Koli = 0;
      newState.EmergencyProcess.ListKoli = [0];
      newState.EmergencyProcess.ModeEmergency = null;
      newState.EmergencyProcess.NumberResi = null;
      newState.EmergencyProcess.Waybill = null;
      newState.EmergencyProcess.Weight = 0;
      return { ...newState };

    case 'CLEAR_CONDITION_MODE':
      newState.EmergencyProcess.Branch = null;
      newState.EmergencyProcess.Courier = null;
      newState.EmergencyProcess.DeliveryTime = null;
      newState.EmergencyProcess.District = null;
      newState.EmergencyProcess.DocumentType = null;
      newState.EmergencyProcess.FinishTime = null;
      newState.EmergencyProcess.NumberResi = null;
      newState.EmergencyProcess.BarcodeColumn = null;
      newState.EmergencyProcess.TypeDelivery = null;
      newState.EmergencyProcess.ReceiverName = null;
      newState.EmergencyProcess.DeliveryNote = null;
      return { ...newState };

    case 'CLEAR_MODAL_EMERGENCY':
      newState.EmergencyProcess.FileName = null;
      newState.EmergencyProcess.CountSuccess = null;
      newState.EmergencyProcess.CountFailed = null;
      return { ...newState };

    case 'CHANGE_RADIO_BUTTON_UA':
      const changeRadioButtonUA = action as IChangeRadioButtonUA;
      newState.updateAddress.radioButton = changeRadioButtonUA.radioButton;

      return { ...newState };
    case 'CHANGE_WAYBILL_UA':
      const changeWaybillUA = action as IChangeWaybillUA;
      newState.updateAddress.waybill = changeWaybillUA.waybill;

      return { ...newState };

    case 'CHANGE_WAYBILL_FORM_UA':
      const changeWaybillFormUA = action as IChangeWaybillFormUA;
      newState.updateAddress.waybillForm = changeWaybillFormUA.waybillForm;

      return { ...newState };

    case 'CHANGE_NAME_UA':
      const changeNameUA = action as IChangeNameUA;
      newState.updateAddress.name = changeNameUA.name;

      return { ...newState };
    case 'CHANGE_CUSTOMER_UA':
      const changeCustomerUA = action as IChangeCustomerUA;
      newState.updateAddress.customer = changeCustomerUA.customer;

      return { ...newState };
    case 'CHANGE_CUSTOMER_PRODUCT_UA':
      const changeCustomerProductUA = action as IChangeCustomerProductUA;
      newState.updateAddress.customerProduct =
        changeCustomerProductUA.customerProduct;

      return { ...newState };
    case 'CHANGE_TEMPLATE_UPLOAD_UA':
      const changeTemplateUploadUA = action as IChangeTemplateUploadUA;
      newState.updateAddress.templateUpload =
        changeTemplateUploadUA.templateUpload;

      return { ...newState };
    case 'CHANGE_CYCLE_DATE_UA':
      const changeCycleDateUA = action as IChangeCycleDateUA;
      newState.updateAddress.cycleDate = changeCycleDateUA.cycleDate;

      return { ...newState };
    case 'CHANGE_FILE_UPLOAD_UA':
      const changeFileUploadUA = action as IChangeFileUploadUA;
      newState.updateAddress.fileUpload = changeFileUploadUA.fileUpload;

      return { ...newState };
    case 'CHANGE_FILE_NAME_UA':
      const changeFileNameUA = action as IChangeFileNameUA;
      newState.updateAddress.fileName = changeFileNameUA.fileName;

      return { ...newState };

    case 'CHANGE_PHONE_NUMBER_UA':
      const changePhoneNumberUA = action as IChangePhoneNumberUA;
      newState.updateAddress.phoneNumber = changePhoneNumberUA.phoneNumber;

      return { ...newState };

    case 'CHANGE_ADDRESS_UA':
      const changeAddressUA = action as IChangeAddressUA;
      newState.updateAddress.address = changeAddressUA.address;

      return { ...newState };
    case 'FETCH_CUSTOMER_PRODUCT_LIST_UA':
      const fetchCustomerProduct = action as IFetchCustomerProductUA;
      newState.updateAddress.customerProductList =
        fetchCustomerProduct.customerProductList;

      return { ...newState };
    case 'FETCH_RESULT_UPLOAD_UA':
      const fetchResultUpload = action as IFetchResultUploadUA;
      newState.updateAddress.resultUpload = fetchResultUpload.resultUpload;

      return { ...newState };
    case 'FETCH_TEMPLATE_UPLOAD_LIST_UA':
      const fetchTemplateUploadList = action as IFetchTemplateUploadUA;
      newState.updateAddress.templateUploadList =
        fetchTemplateUploadList.templateUploadList;

      return { ...newState };

    case 'CLEAR_MULTIPLE_DATA_FORM_UA':
      newState.updateAddress.customer = null;
      newState.updateAddress.customerProduct = null;
      newState.updateAddress.templateUpload = null;
      newState.updateAddress.cycleDate = null;
      newState.updateAddress.fileUpload = null;
      newState.updateAddress.fileName = null;
      newState.updateAddress.resultUpload = null;
      return { ...newState };
    case 'CLEAR_ALL_FORM_UA':
      newState.updateAddress.name = null;
      newState.updateAddress.phoneNumber = null;
      newState.updateAddress.address = null;
      newState.updateAddress.waybill = null;
      newState.updateAddress.waybillForm = null;
      newState.updateAddress.customer = null;
      newState.updateAddress.customerProduct = null;
      newState.updateAddress.templateUpload = null;
      newState.updateAddress.cycleDate = null;
      newState.updateAddress.fileUpload = null;
      newState.updateAddress.fileName = null;
      return { ...newState };

    case 'CLEAR_FORM_EXCEPT_WAYBILL_UA':
      newState.updateAddress.name = null;
      newState.updateAddress.phoneNumber = null;
      newState.updateAddress.address = null;
      newState.updateAddress.waybillForm = null;
      return { ...newState };
  }
  return { ...newState };
}
