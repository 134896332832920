export function changeCType(ChangeType:any)
{
    return({
        type:"CHANGE_CTYPE",
        ChangeType
    })
}
export function clearCType()
{
    return({
        type:"CLEAR_CHANGE_CTYPE"
    })
}