import React from 'react';
import FormRole from 'components/FormRole';

export default function AddRoleFormComponent(props) {
  return (
    <FormRole
      handleBtnBack={props.handleBtnBack}
      handleSubmit={props.handleSubmit}
      isLoadingMenus={props.isLoadingMenus}
      isLoadingSubmit={props.isLoadingSubmit}
      dataAllMenus={props.dataAllMenus}
      dataLevel={props.dataLevel}
      handleSetDataMenu={props.handleSetDataMenu}
      handleSelectedlevel={props.handleSelectedlevel}
      handleChangeForm={props.handleChangeForm}
    />
  );
}
