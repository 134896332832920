export function getListCustomerProduct()
{
    return({
        type:"GET_LIST_CUSTOMER_PRODUCT"
    })
}
export function getListCustomerProductNoType()
{
    return({
        type:"GET_LIST_CUSTOMER_PRODUCT_NO_TYPE"
    })
}

export function setListCustomerProduct(ListCustomerProduct:any)
{
    return({
        type:"SET_LIST_CUSTOMER_PRODUCT",
        ListCustomerProduct
    })
}
export function clearListCustomerProduct()
{
    return({
        type:"CLEAR_LIST_CUSTOMER_PRODUCT"
    })
}