import * as ActionFormComplaint from 'core/action/Complaint/ActionFormComplaint';
import * as ActionToggle from 'core/action/toggle';

import { bindActionCreators, compose } from 'redux';

import ButtonComplainComponent from '../Component/ButtonComplaintComponent';
import React from 'react';
import { connect } from 'react-redux';

interface IProps {
  idComplain: any;
  actionToggle: any;
  actionFormComplaint: any;
  ticketStatus: any;
  typeButton: any;
}
function ButtonComplainContainer({
  idComplain,
  actionToggle,
  actionFormComplaint,
  ticketStatus,
  typeButton,
}: IProps) {
  function handleDetailComplain() {
    actionFormComplaint.changeComplaintId(idComplain);
    actionFormComplaint.getDetailComplaintTicket();
    if (ticketStatus === 0) {
      actionFormComplaint.changeComplaintStatus('process');
    } else if (ticketStatus === 1) {
      actionFormComplaint.changeComplaintStatus('finished');
    } else {
      actionFormComplaint.changeComplaintStatus('new');
    }
    actionToggle.changeJenisToggle('EditTicket');
  }
  function handleEditFormComplaint() {
    actionFormComplaint.changeComplaintId(idComplain);
    actionFormComplaint.getDetailComplaintTicket();
    if (ticketStatus === 0) {
      actionFormComplaint.changeComplaintStatus('process');
    } else if (ticketStatus === 1) {
      actionFormComplaint.changeComplaintStatus('finished');
    } else {
      actionFormComplaint.changeComplaintStatus('new');
    }
    actionToggle.changeJenisToggle('EditFormTicket');
  }
  return (
    <div>
      <ButtonComplainComponent
        handleDetailComplain={handleDetailComplain}
        handleEditFormComplaint={handleEditFormComplaint}
        typeButton={typeButton}
        ticketStatus={ticketStatus}
      />
    </div>
  );
}
const mapStateProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  actionFormComplaint: bindActionCreators(ActionFormComplaint, dispatch),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(ButtonComplainContainer);
