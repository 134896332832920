import {Action} from 'redux'

const initialState = 
{
    PropertiesAssignCourier:{
        AssignStatus:null,
        DetailWorB:null,
        DetailCourierCode:null,
    },
    
    deliveryType:null,
    envelopeType:'standart',
    weight:0,
    koli:0,
    area:null,
    branchId:null,
    courierId:null,
    courierCode:null,
    WorB:null,
    CheckBox:false,
    CheckBoxKoli:false,
    ListKoli:[0],
    
}

export interface IAuthAction extends Action<string>
{

}
export interface IChangeDeliveryType_AC extends IAuthAction
{
    deliveryType : any
}
export interface IChangeEnvelopeType_AC extends IAuthAction
{
    envelopeType : any
}
export interface IChangeWeight_AC extends IAuthAction
{
    weight : any
}
export interface IChangeListKoli_AC extends IAuthAction
{
    ListKoli : any
}
export interface IChangeKoli_AC extends IAuthAction
{
    koli : any
}
export interface IChangeArea_AC extends IAuthAction
{
    area : any
}
export interface IChangeBranchId_AC extends IAuthAction
{
    branchId : any
    
}
export interface IChangeCourierId_AC extends IAuthAction
{
    courierId : any
    
}
export interface IChangeCourierCode_AC extends IAuthAction
{
    courierCode : any
}
export interface IChangeDetailCourierCode_AC extends IAuthAction
{
    DetailCourierCode : any
}
export interface IChangeWorB_AC extends IAuthAction
{
    WorB : any   
}
export interface IChangeDetailWorB_AC extends IAuthAction
{
    DetailWorB : any
    
}
export interface IChangeAssignStatus_AC extends IAuthAction
{
    AssignStatus : any
    
}
export interface IChangeCheckBox_AC extends IAuthAction
{
    CheckBox : any
    
}
export interface IChangeCheckBoxKoli_AC extends IAuthAction
{
    CheckBoxKoli : any
    
}

export default function AuthReducer(state=initialState, action:IAuthAction){
    const newState = Object.assign({}, state)
    switch(action.type)
    {
        case "CHANGE_DELIVERY_TYPE_AC":
            const changeDeliveryType = action as IChangeDeliveryType_AC
            newState.deliveryType = changeDeliveryType.deliveryType
        return {...newState}
        
        case "CHANGE_ENVELOPE_TYPE_AC":
            const changeEnvelopeType = action as IChangeEnvelopeType_AC
            newState.envelopeType = changeEnvelopeType.envelopeType
        return {...newState}
        
        case "CHANGE_WEIGHT_AC":
            const changeWeight = action as IChangeWeight_AC
            newState.weight = changeWeight.weight
        return {...newState}
        
        case "CHANGE_LIST_KOLI_AC":
            const changeListKoli = action as IChangeListKoli_AC
            newState.ListKoli = changeListKoli.ListKoli
        return {...newState}

        case "CHANGE_KOLI_AC":
            const changeKoli = action as IChangeKoli_AC
            newState.koli = changeKoli.koli
        return {...newState}
        
        case "CHANGE_AREA_AC":
            const changeArea = action as IChangeArea_AC
            newState.area = changeArea.area
        return {...newState}

        case "CHANGE_BRANCH_ID_AC":
            const changeBranchId = action as IChangeBranchId_AC
            newState.branchId = changeBranchId.branchId
        return {...newState}
        
        case "CHANGE_COURIER_ID_AC":
            const changeCourierId = action as IChangeCourierId_AC
            newState.courierId = changeCourierId.courierId
        return {...newState}
        
        case "CHANGE_COURIER_CODE_AC":
            const changeCourierCode = action as IChangeCourierCode_AC
            newState.courierCode = changeCourierCode.courierCode
        return {...newState}
        
        case "CHANGE_DETAIL_COURIER_CODE_AC":
            const changeDetailCourierCode = action as IChangeDetailCourierCode_AC
            newState.PropertiesAssignCourier.DetailCourierCode = changeDetailCourierCode.DetailCourierCode
        return {...newState}
        
        case "CHANGE_W_OR_B_AC":
            const changeWorB = action as IChangeWorB_AC
            newState.WorB = changeWorB.WorB
        return {...newState}
        
        case "CHANGE_DETAIL_W_OR_B_AC":
            const changeDetailWorB = action as IChangeDetailWorB_AC
            newState.PropertiesAssignCourier.DetailWorB = changeDetailWorB.DetailWorB
        return {...newState}
        
        case "CHANGE_CHECKBOX_AC":
            const changeCheckbox = action as IChangeCheckBox_AC
            newState.CheckBox = changeCheckbox.CheckBox
        return {...newState}
        
        case "CHANGE_CHECKBOX_KOLI_AC":
            const changeCheckboxKoli = action as IChangeCheckBoxKoli_AC
            newState.CheckBoxKoli = changeCheckboxKoli.CheckBoxKoli
        return {...newState}
        
        case "CHANGE_ASSIGN_STATUS_AC":
            const changeAssignStatus = action as IChangeAssignStatus_AC
            newState.PropertiesAssignCourier.AssignStatus = changeAssignStatus.AssignStatus
        return {...newState}

        case "CLEAR_LIST_KOLI_AC":
            newState.ListKoli = [0]
        return {...newState}
        
        case "CLEAR_CHECKBOX_KOLI_AC":
            newState.CheckBoxKoli = false
        return {...newState}
        
        case "CLEAR_KOLI_AC":
            newState.koli = 0
        return {...newState}

        case "CLEAR_FORM_AC":
            newState.deliveryType = null
            newState.envelopeType = 'standart'
            newState.weight = 0
            newState.ListKoli = [0]
            newState.koli = 0
            newState.area = null
            newState.branchId = null
            newState.courierId = null
            newState.WorB = null
            newState.CheckBox = false
            newState.CheckBoxKoli = false
            newState.courierCode = null
            newState.PropertiesAssignCourier.AssignStatus = null
            newState.PropertiesAssignCourier.DetailCourierCode = null
            newState.PropertiesAssignCourier.DetailWorB = null
        return {...newState}
        
        case "CLEAR_W_OR_B_AC":
            newState.WorB = null
        return {...newState}
        
        case "CLEAR_KOLI_OR_WEIGHT_AC":
            newState.weight = 0
            newState.ListKoli = [0]
            newState.koli = 0
        return {...newState}
        
        case "RESET_FORM_AC":
            newState.deliveryType = null
            newState.envelopeType = 'standart'
            newState.weight = 0
            newState.area = null
            newState.branchId = null
            newState.courierId = null
            newState.WorB = null
            newState.CheckBox = false
            newState.CheckBoxKoli = false
            newState.courierCode = null
            newState.ListKoli = [0]
            newState.koli = 0
            newState.PropertiesAssignCourier.AssignStatus = null
            newState.PropertiesAssignCourier.DetailCourierCode = null
            newState.PropertiesAssignCourier.DetailWorB = null
        return {...newState}

        case "RESET_FORM_AC_BRANCH":
            newState.deliveryType = null
            newState.envelopeType = 'standart'
            newState.weight = 0
            newState.area = null
            // newState.branchId = null
            newState.courierId = null
            newState.WorB = null
            newState.CheckBox = false
            newState.CheckBoxKoli = false
            newState.courierCode = null
            newState.ListKoli = [0]
            newState.koli = 0
            newState.PropertiesAssignCourier.AssignStatus = null
            newState.PropertiesAssignCourier.DetailCourierCode = null
            newState.PropertiesAssignCourier.DetailWorB = null
        return {...newState}

    }
    return {...newState}
}