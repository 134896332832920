import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

export function* S_downloadMisReport(action: any) {
  const state = yield select();
  const key = 'download';
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const CustomerId = state.Report.MisReportForm.CustomerId;
  const RadioButton = state.Report.MisReportForm.RadioButton;
  const District = state.Report.MisReportForm.District;
  const CustomerProductId = state.Report.MisReportForm.CustomerProductId;
  const CustomerName = state.Report.MisReportForm.CustomerName;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const StartDate = moment(state.Report.MisReportForm.StartDate).format(
    'YYYYMMDD'
  );
  const FinishDate = moment(state.Report.MisReportForm.FinishDate).format(
    'YYYYMMDD'
  );

  const file_name2 = `Laporan ${RadioButton.toUpperCase()} ${CustomerName} ${StartDate} ${FinishDate}.xls`;

  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = file_name2;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }

  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Delivery/DeliveryCreateMIS`,
      {
        itemDeliveryTimeFrom: StartDate,
        itemDeliveryTimeTo: FinishDate,
        customer: CustomerId,
        district: District,
        customerProducts: CustomerProductId,
        typeReport: RadioButton,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);

          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.statusText === 'Internal Server Error') {
      message.warning({
        content: 'Laporan Tidak Tersedia',
        key,
        duration: 3,
      });
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* SM_downloadMisReport() {
  yield takeLatest('DOWNLOAD_MIS_REPORT_FORM', S_downloadMisReport);
}
