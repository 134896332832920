import * as ActionDetailListCsvType from '../../action/Customer/ActionDetailListCsvType';
import * as ActionLogin from '../../../module/Login/Store';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../action/toggle';

import axios from 'axios';
export function* S_DetailCsvList() {
  const state = yield select();
  const IdCustomer = state.Customer.PropertiesCustomer.IdCustomer;
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Setting/CustomCsv/Customer/' + IdCustomer,
      { headers: config }
    );

    if (data.data.length === 0) {
      yield put(ActionDetailListCsvType.setCsvTemplate('add'));
    } else {
      yield put(ActionDetailListCsvType.setCsvTemplate('detail'));
    }
    yield put(ActionDetailListCsvType.setListCustomerCsvType(data.data));
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      yield put(changeLoadingFalse());
    }
  }
}

export function* SM_DetailCsvList() {
  yield takeLatest('GET_LIST_CUSTOMER_CSV_TYPE', S_DetailCsvList);
}
