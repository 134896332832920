import '../../../styles/ResendStyle/index.css';
import '../../../styles/TableStyle/index.css';

import { Alert, Checkbox, Col, Row, Table } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../materials/ButtonMaterial';
import ButtonRestoreDataContainer from '../../../containers/Content/RestoreData/ButtonRestoreDataContainer';
import CIcon from '../../../apps/assets/components/CIcon';
import CModal from '../../../apps/assets/components/CModal';
import CardMaterial from '../../../materials/CardMaterial';
import SearchMatrial from '../../../materials/SearchMatrial';
import SpinMaterial from '../../../materials/SpinMaterial';

// import moment from 'moment';

interface IProps {
  isLoading: any;
  typeToggle: any;
  visibleModal: any;
  valueSearch: any;
  radioButton: any;
  radioButtonW: any;
  selectAll: any;
  selectAllW: any;
  modal: any;
  listResultDeleteData: any;
  LastAccess: any;
  ListRestoreData: any;
  ListWaybillRestoreData: any;
  PagePagination: any;
  TotalPagePagination: any;
  handleCloseModal: any;
  handlePage: any;
  handleSearch: any;
  handleCheckbox: any;
  handleSelectAll: any;
  handleDelete: any;
  handleRestore: any;
  handleDownloadDeleteResult: any;
  page: any;
}

export default class TableRestoreDataComponent extends Component<IProps> {
  render() {
    const successResult =
      this.props.listResultDeleteData === null
        ? this.props.listResultDeleteData
        : this.props.listResultDeleteData.success;
    const failedResult =
      this.props.listResultDeleteData === null
        ? this.props.listResultDeleteData
        : this.props.listResultDeleteData.failed;
    const validateDownloadResult = failedResult === 0 ? true : false;
    const showPlaceholder = 'Cari jenis produk / admin ';
    const showModalWaybillError = () => {
      const showModalFailed =
        this.props.typeToggle === 'failedDeleteWaybill' &&
        this.props.visibleModal === true
          ? true
          : false;
      return (
        <React.Fragment>
          <CModal
            className={''}
            titleClassName={'titleModalNotifFailed'}
            teksTitle={'Gagal menghapus data'}
            visible={showModalFailed}
            onCancel={this.props.handleCloseModal}
            content={
              <div className="defaultPaddingModal textAlignCenter">
                <p className="textResultDeleteWaybill">
                  {this.props.listResultDeleteData}
                </p>
                <ButtonMaterial
                  className="btnCloseResultDelete"
                  handleSubmit={this.props.handleCloseModal}
                  teksButton={'Tutup'}
                  shape={'round'}
                  type={'primary'}
                />
              </div>
            }
          />
        </React.Fragment>
      );
    };
    const showModal = () => {
      const visible =
        this.props.typeToggle === 'resultDelete' &&
        this.props.visibleModal === true
          ? true
          : false;

      return (
        <React.Fragment>
          <CModal
            className={''}
            teksTitle={'STATUS UNGGAH DATA'}
            visible={visible}
            onCancel={this.props.handleCloseModal}
            content={
              <div className="defaultPaddingModal">
                <Row>
                  <Col span={12} className="spacingRightAlertCallCenter">
                    <Alert
                      className="alertSuccess"
                      message={
                        <React.Fragment>
                          <Col span={6}>
                            <CIcon
                              className={'iconNotifSuccess'}
                              type="check-square"
                            />
                          </Col>
                          <Col span={18} className="greenText spacingTop">
                            <Row>
                              Berhasil : <b>{successResult}</b>
                            </Row>
                          </Col>
                        </React.Fragment>
                      }
                      type="success"
                    />
                  </Col>
                  <Col span={12} className="spacingLeftAlertCallCenter">
                    <Alert
                      className="alertSuccess"
                      message={
                        <React.Fragment>
                          <Col span={6}>
                            <CIcon
                              className={'iconNotifFailed'}
                              type="close-square"
                            />
                          </Col>
                          <Col span={18} className="redText spacingTop">
                            <Row>
                              Gagal : <b>{failedResult}</b>
                            </Row>
                          </Col>
                        </React.Fragment>
                      }
                      type="error"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12} className="spacingRight">
                    <ButtonMaterial
                      disabled={validateDownloadResult}
                      className="btnDownloadResultDelete"
                      handleSubmit={this.props.handleDownloadDeleteResult}
                      teksButton={'Download'}
                      shape={'round'}
                      type={'primary'}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonMaterial
                      disabled={false}
                      className="btnCloseResultDelete marginLeft"
                      handleSubmit={this.props.handleCloseModal}
                      teksButton={'Tutup'}
                      shape={'round'}
                      type={'primary'}
                    />
                  </Col>
                </Row>
              </div>
            }
          />
        </React.Fragment>
      );
    };
    const source =
      this.props.LastAccess === null ||
      this.props.LastAccess === 'restoreDataCsv'
        ? this.props.ListRestoreData
        : this.props.ListWaybillRestoreData;

    const list = source.filter(item => item.selected === true);
    const validateButton = list.length === 0 ? true : false;
    let looppage = (this.props.PagePagination - 1) * 10 + 1;
    const columns = [
      {
        title: 'No',
        render: (text: any, record: any, index: any) => (
          <span>
            {this.props.PagePagination > 1 ? index + looppage : index + 1}
          </span>
        ),
        key: 'no',
        width: 30,
      },
      this.props.LastAccess !== 'restoreDataWaybill'
        ? {
            title: 'Nama File',
            dataIndex: 'fileName',
            key: 'fileName',
            width: 90,
          }
        : { width: 0 },
      {
        title: 'Jenis Produk',
        dataIndex: 'jenisProduk',
        render: (jenisProduk: any, record: any) => <span>{jenisProduk}</span>,
        key: 'jenisProduk',
        width: 90,
      },
      {
        title: 'Admin',
        dataIndex: 'admin',
        render: (admin: any, record: any) => (
          <span style={{ color: 'red' }}>
            {admin === null || admin === '' ? '-' : admin}
          </span>
        ),
        key: 'admin',
        width: 80,
      },
      this.props.LastAccess !== 'restoreDataWaybill'
        ? {
            title: 'Jumlah Dokumen',
            dataIndex: 'totalProcess',
            render: (totalProcess: any, record: any) => (
              <span className={'wordColorGreenRestoreData'}>
                {totalProcess + '/' + record.totalData + ' Dokumen'}
              </span>
            ),
            key: 'totalProcess',
            width: 90,
          }
        : {
            title: 'Waybill',
            dataIndex: 'waybillCode',
            render: (waybillcode: any) => (
              <span className={'wordColorGreenRestoreData'}>{waybillcode}</span>
            ),
            key: 'totalProcess',
            width: 100,
          },
      {
        title: 'Aksi',
        width: 40,
        render: (record: any) => (
          <React.Fragment>
            <Row>
              <ButtonRestoreDataContainer
                idRestoreData={record.id}
                productType={record.jenisProduk}
                waybillCode={record.waybillCode}
                typeButton={'DownloadRestoreData'}
              />
            </Row>
          </React.Fragment>
        ),
      },
      {
        title: (
          <div>
            <Checkbox
              style={{ marginRight: 8 }}
              checked={
                this.props.LastAccess === null ||
                this.props.LastAccess === 'restoreDataCsv'
                  ? this.props.selectAll
                  : this.props.selectAllW
              }
              onChange={this.props.handleSelectAll}
            />
            <span>Select All</span>
          </div>
        ),
        key: 'aksi',
        width: 60,
        render: (value: any, record: any, rowIndex: any) => (
          <div>
            <Checkbox
              checked={record.selected}
              onChange={this.props.handleCheckbox(record.id)}
            />
          </div>
        ),
      },
    ];
    return (
      <div className={'layoutTableRestoreData'}>
        {showModal()}
        {showModalWaybillError()}
        <SpinMaterial
          spinning={this.props.isLoading}
          style={{}}
          size={'large'}
          content={
            <div>
              <CardMaterial
                extra={''}
                title={''}
                style={{}}
                content={
                  <div>
                    <Row>
                      <Col span={16}>
                        <div>
                          <span className="titleSearchCard">SEMUA DATA</span>
                          <SearchMatrial
                            value={this.props.valueSearch}
                            style={{ borderRadius: '10px', width: '50%' }}
                            placeholder={
                              this.props.LastAccess === null ||
                              this.props.LastAccess === 'restoreDataCsv'
                                ? `${showPlaceholder}/ total dokumen`
                                : `${showPlaceholder}`
                            }
                            onChange={this.props.handleSearch}
                          />
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="floatRight">
                          <ButtonMaterial
                            disabled={validateButton}
                            handleSubmit={
                              this.props.LastAccess === null ||
                              this.props.LastAccess === 'restoreDataCsv'
                                ? this.props.radioButton === 'Delete'
                                  ? this.props.handleDelete
                                  : this.props.handleRestore
                                : this.props.radioButtonW === 'Delete'
                                ? this.props.handleDelete
                                : this.props.handleRestore
                            }
                            teksButton={
                              this.props.LastAccess === null ||
                              this.props.LastAccess === 'restoreDataCsv'
                                ? this.props.radioButton
                                : this.props.radioButtonW
                            }
                            size={''}
                            shape={'round'}
                            className={
                              this.props.LastAccess === null ||
                              this.props.LastAccess === 'restoreDataCsv'
                                ? this.props.radioButton === 'Delete'
                                  ? validateButton
                                    ? ''
                                    : 'btnDeleteTableRestoreData'
                                  : validateButton
                                  ? ''
                                  : 'btnRestoreTableRestoreData'
                                : this.props.radioButtonW === 'Delete'
                                ? validateButton
                                  ? ''
                                  : 'btnDeleteTableRestoreData'
                                : validateButton
                                ? ''
                                : 'btnRestoreTableRestoreData'
                            }
                            type={'primary'}
                            icon={''}
                            style={{}}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                }
              />
              <Table
                pagination={{
                  onChange: page => {
                    this.props.handlePage(page);
                  },
                  pageSize: 10,
                  current: this.props.PagePagination,
                }}
                bordered={false}
                dataSource={source.filter((data: any) =>
                  this.props.LastAccess === null ||
                  this.props.LastAccess === 'restoreDataCsv'
                    ? data.admin
                        .toString()
                        .toLowerCase()
                        .includes(this.props.valueSearch) ||
                      data.jenisProduk
                        .toString()
                        .toLowerCase()
                        .includes(this.props.valueSearch) ||
                      data.totalData
                        .toString()
                        .toLowerCase()
                        .includes(this.props.valueSearch)
                    : data.admin
                        .toString()
                        .toLowerCase()
                        .includes(this.props.valueSearch) ||
                      data.jenisProduk
                        .toString()
                        .toLowerCase()
                        .includes(this.props.valueSearch) ||
                      data.waybillCode
                        .toString()
                        .toLowerCase()
                        .includes(this.props.valueSearch)
                )}
                columns={columns}
              />
            </div>
          }
        ></SpinMaterial>
      </div>
    );
  }
}
