import React from 'react';
import ModalWaybillOptionComponent from '../Component/ModalWaybillOptionComponent';
import { Radio } from 'antd';
interface IProps {
  dataWaybillOption: any[];
  handleSubmit: (values: any) => void;
  isLoadingMutation: boolean;
  modalWaybillOption: boolean;
  scanPayload: any;
  selectedWaybill: string | null;
  setDataWaybillOption: (value: any[] | null) => void;
  setModalWaybillOption: (value: boolean) => void;
  setSelectedWaybill: (value: string | null) => void;
}

export default function ModalWaybillOptionContainer(props: IProps) {
  const {
    dataWaybillOption,
    handleSubmit,
    isLoadingMutation,
    modalWaybillOption,
    scanPayload,
    selectedWaybill,
    setDataWaybillOption,
    setModalWaybillOption,
    setSelectedWaybill,
  } = props;

  const handleCancel = () => {
    setModalWaybillOption(false);
    setDataWaybillOption(null);
    setSelectedWaybill(null);
  };

  const columnData = [
    {
      title: <b>Waybill</b>,
      dataIndex: 'waybill',
      key: 'waybill',
      width: 30,
    },
    {
      title: <b>Barcode</b>,
      dataIndex: 'barcode',
      key: 'barcode',
      width: 30,
    },
    {
      title: <b>Tanggal Cycle</b>,
      dataIndex: 'cycle',
      key: 'cycle',
      width: 30,
    },
    {
      title: <b>Nama</b>,
      dataIndex: 'name',
      key: 'name',
      width: 30,
    },
    {
      key: 'action',
      width: 30,
      render: record => {
        return (
          <Radio.Group>
            <Radio
              key={record.waybill}
              value={selectedWaybill}
              onClick={() => setSelectedWaybill(record.waybill)}
              checked={
                selectedWaybill ? record.waybill === selectedWaybill : false
              }
            ></Radio>
          </Radio.Group>
        );
      },
    },
  ];

  const handleScanWaybill = () => {
    const payload = {
      ...scanPayload,
      waybillOrBarcode: selectedWaybill,
      typeScan: 'waybill',
    };
    handleSubmit(payload);
  };

  return (
    <ModalWaybillOptionComponent
      modalWaybillOption={modalWaybillOption}
      handleCancel={handleCancel}
      dataWaybillOption={dataWaybillOption}
      columnData={columnData}
      handleScanWaybill={handleScanWaybill}
      selectedWaybill={selectedWaybill}
      isLoadingMutation={isLoadingMutation}
    />
  );
}
