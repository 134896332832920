import * as ActionAuth from '../../action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as formPassword from '../../action/ResetPassword/ActionResetPassword';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_editResetAccountPassword(action: any) {
  try {
    yield put(toggle.changeLoadingTrue());
    const state = yield select();

    const token = state.Auth.Token;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-type': 'application/json',
    };
    const statusTokenRefresh = state.Auth.StatusTokenRefresh;

    const NewPassword = state.ResetPassword.NewPassword;
    const Username =
      state.Toggle.jenisToggle === 'ResetPasswordAdmin'
        ? state.AdminBranch.FormAdmin.Username
        : state.Toggle.jenisToggle === 'ResetPasswordCourier'
        ? state.Courier.FormCourier.CourierCode
        : state.Toggle.jenisToggle === 'ResetPasswordCustomer'
        ? state.Customer.CustomerAccount.Username
        : '';

    yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/Profile/statusUserPassword/${Username}`,
      {},
      { headers: config }
    );
    yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/User/password/initial/${Username}`,
      `'${NewPassword}'`,
      { headers: config }
    );

    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(toggle.changeModal());
    yield put(toggle.clearJenisToggle());
    yield put(formPassword.changeClearDataResetPassword());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Kata Sandi Berhasil Di Ubah</b>
        </p>
      ),

      onOk() {},
    });
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* SM_editResetAccountPassword() {
  yield takeLatest(
    'SUBMIT_EDIT_RESET_ACCOUNT_PASSWORD',
    S_editResetAccountPassword
  );
}
