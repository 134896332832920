export function setLoadingCustomer()
{
    return({
        type:"SET_LOADING_CUSTOMER"
    })
}
export function setTypeLoadingCustomer(TypeLoadingCustomer:any)
{
    return({
        type:"SET_TYPE_LOADING_CUSTOMER",
        TypeLoadingCustomer
    })
}
export function clearTypeLoadingCustomer()
{
    return({
        type:"CLEAR_TYPE_LOADING_CUSTOMER"
    })
}
export function clearLoadingCustomer()
{
    return({
        type:"CLEAR_LOADING_CUSTOMER"
    })
}