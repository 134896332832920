import React, { Component } from 'react';

import { Spin } from 'antd';

interface IProps {
  spinning: any;
  style?: any;
  size: any;
  content?: any;
  children?: React.ReactNode;
}

export default class SpinMaterial extends Component<IProps> {
  render() {
    return (
      <div>
        <Spin spinning={this.props.spinning} size={this.props.size}>
          {this.props.children}
          {this.props.content}
        </Spin>
      </div>
    );
  }
}
