import { Col, Input, Row, Table } from 'antd';
import React, { Component } from 'react';
import ButtonCheckServerVoipContainer from '../../../../containers/Content/Voip/ServerVoip/ButtonCheckServerVoipContainer';
import ButtonEditServerVoipContainer from '../../../../containers/Content/Voip/ServerVoip/ButtonEditServerVoipContainer';
import FormServerVoipContainer from '../../../../containers/Content/Voip/ServerVoip/FormServerVoipContainer';
import ButtonMaterial from '../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../materials/CardMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';
import '../../../../styles/TableStyle/index.css';

interface IProps {
  isLoading: any;
  ListServerVoip: any;
  SearchValue: any;
  PagePagination: any;
  TotalPagePagination: any;
  handleSearch: any;
  handlePutSearch: any;
  handlePage: any;
  handleModal: any;
  handleCheckServerVoip: any;
  page: any;
}

export default class ServerVoipComponent extends Component<IProps> {
  render() {
    const columns = [
      {
        title: 'Nama',
        dataIndex: 'nameServer',
        render: (nameServer: any) => <span>{nameServer}</span>,
        key: 'nameServer',
        width: 90,
      },
      {
        title: 'IP Address',
        dataIndex: 'ipAddress',
        render: (ipAddress: any) => <span>{ipAddress}</span>,
        key: 'ipAddress',
        width: 120,
      },
      {
        title: 'Detail IP',
        dataIndex: 'ipAddress',
        render: (ipAddress: any) => (
          <ButtonCheckServerVoipContainer ipAddress={ipAddress} />
        ),
        key: 'ipAddress',
        width: 40,
      },
      {
        title: 'Kuota',
        dataIndex: 'kuota',
        render: (kuota: any) => <span>{kuota}</span>,
        key: 'kuota',
        width: 60,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status: any) => (
          <span>
            {status === true ? (
              <span style={{ color: '#4FACFB' }}>Aktif</span>
            ) : (
              'Tidak Aktif'
            )}
          </span>
        ),
        key: 'status',
        width: 60,
      },
      {
        title: 'Aksi',
        key: 'aksi',
        width: 60,
        render: (record: any) => (
          <div>
            <ButtonEditServerVoipContainer id={record.id} />
          </div>
        ),
      },
    ];
    return (
      <div style={{ paddingBottom: '35px' }}>
        <FormServerVoipContainer />
        <SpinMaterial
          spinning={this.props.isLoading}
          style={{}}
          size={'large'}
          content={
            <div>
              <CardMaterial
                extra={''}
                title={''}
                style={{}}
                content={
                  <div>
                    <Row>
                      <Col span={16}>
                        <div>
                          <span
                            style={{ color: '#11BEFF', paddingRight: '20px' }}
                          >
                            <b>MODUL VOIP SERVER</b>
                          </span>
                          <Input.Search
                            style={{ borderRadius: '10px', width: '50%' }}
                            placeholder={'Cari berdasarkan ip address'}
                            onChange={this.props.handlePutSearch}
                            onPressEnter={this.props.handleSearch}
                            value={this.props.SearchValue}
                          />
                        </div>
                      </Col>
                      <Col span={8}>
                        <div style={{ float: 'right' }}>
                          <ButtonMaterial
                            disabled={''}
                            handleSubmit={this.props.handleModal}
                            teksButton={<b>TAMBAHKAN SERVER</b>}
                            size={''}
                            shape={''}
                            className={''}
                            type={'link'}
                            icon={''}
                            style={{
                              color: '#11BEFF',
                              paddingRight: '15px',
                            }}
                          />

                          <ButtonMaterial
                            disabled={''}
                            handleSubmit={this.props.handleModal}
                            teksButton={''}
                            size={''}
                            shape={'circle'}
                            className={''}
                            type={'primary'}
                            icon={'plus'}
                            style={{
                              color: '#fafafa',
                              paddingBottom: '3px',
                              background: '#31E116',
                              borderColor: '#31E116',
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                }
              ></CardMaterial>
              <Table
                pagination={{
                  onChange: page => {
                    this.props.handlePage(page);
                  },
                  pageSize: 10,
                  total: this.props.TotalPagePagination,
                  current:
                    this.props.PagePagination === 0
                      ? 1
                      : this.props.PagePagination,
                }}
                bordered={false}
                dataSource={this.props.ListServerVoip}
                columns={columns}
              />
            </div>
          }
        ></SpinMaterial>
      </div>
    );
  }
}
