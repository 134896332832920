import 'styles/LoginStyle/index.css';

import { Field, reduxForm } from 'redux-form';

import ButtonMaterial from 'materials/ButtonMaterial';
import FormInputTextMaterial from 'components/CInput';
import { Icon } from 'antd';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';
import logo from 'image/iconCCC.png';
import validate from 'module/Login/Validation/LoginValidation';

interface IProps {
  handleSubmit?: any;
  isLoading?: boolean;
  invalid: boolean;
}

function LoginComponent(props) {
  const { isLoading, handleSubmit, invalid }: IProps = props;

  return (
    <div>
      <SpinMaterial
        spinning={isLoading}
        style={{}}
        size={'large'}
        content={
          <div className="FormLogin-Component">
            <div className="center">
              <img src={logo} className="logo" alt="logo" />
            </div>
            <div className="font-ccc">
              <p>
                <b>Courier </b>Command <b>Center</b>
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <Field
                name="username"
                component={FormInputTextMaterial}
                className="example-input"
                validateType={true}
                placeholder="Nama Pengguna"
                leftPrefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                size="large"
              />
              <Field
                name="password"
                component={FormInputTextMaterial}
                className="example-input"
                validateType={true}
                placeholder="Kata Sandi"
                size="large"
                leftPrefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type={'password'}
              />

              {/* <div>
              <Checkbox className="CheckBox-IngatKataSandi">
                Ingat Kata Sandi
              </Checkbox>
              <a className="LupaPassword" href="#">
                Lupa Kata Sandi
              </a>
              <br />
            </div> */}

              <div className="center">
                <ButtonMaterial
                  disabled={invalid}
                  teksButton="MASUK"
                  size="default"
                  shape="round"
                  className="button-login"
                  type="primary"
                  htmlType="submit"
                />
              </div>
            </form>
          </div>
        }
      />
    </div>
  );
}

const LoginFormComponent = reduxForm({
  form: 'loginForm',
  shouldError: () => {
    return true;
  },
  validate,
})(LoginComponent);
export default LoginFormComponent;
