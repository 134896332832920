import * as ActionAuth from '../../action/Auth';
import * as ActionComplaint from '../../action/Complaint/ActionFormComplaint';
import * as ActionDeliveryPriority from '../../action/DeliveryPriority/ActionDeliveryPriority';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionSetDeliveryStatus from 'module/DeliveryStatus/Store/ActionSetDeliveryStatus';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../action/toggle';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';
import moment from 'moment';

export function* S_getDeliveryStatus(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const route = state.router.location.pathname;
  const waybill =
    route === '/complain'
      ? state.Complaint.complaintWaybill
      : route === '/deliveryPriority'
      ? state.DeliveryPriority.DeliveryPriorityForm.waybillForm
      : route === '/delivery/status'
      ? state.DeliveryStatus.Waybill
      : '';
  try {
    yield put(changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Delivery/waybill/' + waybill,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    if (route === '/delivery/status') {
      yield put(ActionSetDeliveryStatus.setDataDocument(data));
      yield put(ActionSetDeliveryStatus.setProcessTime(data.itemProcessTime));
      yield put(ActionSetDeliveryStatus.setDeliveryTime(data.itemDeliveryTime));
      yield put(ActionSetDeliveryStatus.setFinishedTime(data.itemFinishedTime));
      yield put(ActionSetDeliveryStatus.setFilterTypeDS(data.filterType));
      yield put(ActionSetDeliveryStatus.setValueTypeDS(data.valueFilter));
      yield put(ActionSetDeliveryStatus.setDistrictDS(data.district));
      yield put(ActionSetDeliveryStatus.setDimention(data.dimention));
      yield put(ActionSetDeliveryStatus.setAttempt(data.attempt));
      yield put(ActionSetDeliveryStatus.setReceiptNumber(data.noResi));
      yield put(
        ActionSetDeliveryStatus.setTotalGroupDocument(data.totalGroupDocument)
      );
      yield put(ActionSetDeliveryStatus.setTotalWeight(data.totalWeight));
      yield put(ActionSetDeliveryStatus.setKoli(data.koli));
      yield put(ActionSetDeliveryStatus.setBarcode(data.barcode));
      yield put(ActionSetDeliveryStatus.setWaybillForm(data.waybillCode));
      yield put(
        ActionSetDeliveryStatus.setDocumentType(
          data.itemType === 'Document' ? 'Dokumen' : data.itemType
        )
      );
      yield put(ActionSetDeliveryStatus.setDeliveryType(data.deliveryType));
      yield put(
        ActionSetDeliveryStatus.setDeliveryPosition(data.deliveryPosition)
      );
      yield put(ActionSetDeliveryStatus.setCourierCode(data.courierCode));
      yield put(ActionSetDeliveryStatus.setCourierName(data.courierName));
      yield put(ActionSetDeliveryStatus.setOwnerName(data.shipToName));
      yield put(ActionSetDeliveryStatus.setNoTelp(data.shipToPhone));
      yield put(ActionSetDeliveryStatus.setCity(data.shipToCity));
      yield put(ActionSetDeliveryStatus.setZipCode(data.shipToPostCode));
      yield put(
        ActionSetDeliveryStatus.setAddress(JSON.parse(data.shipToAddress))
      );
      yield put(
        ActionSetDeliveryStatus.setAdditionalData(
          JSON.parse(data.additionalData)
        )
      );
      yield put(ActionSetDeliveryStatus.setOtherData(data.details));
      yield put(ActionSetDeliveryStatus.setImage(data.image));
      yield put(ActionSetDeliveryStatus.setSignature(data.signature));
      yield put(
        ActionSetDeliveryStatus.setProductImage(data.customerProductImage)
      );
      yield put(ActionSetDeliveryStatus.setStatusDelivery(data.deliveryStatus));
      yield put(ActionSetDeliveryStatus.setReceiverName(data.receiverName));
      yield put(ActionSetDeliveryStatus.setReceiverTitle(data.receiverTitle));
      yield put(ActionSetDeliveryStatus.setReturnedReason(data.returnedReason));
      yield put(
        ActionSetDeliveryStatus.setReceiverLocation(data.receiverLocation)
      );
      yield put(ActionSetDeliveryStatus.setHistory(data.history));
      yield put(
        ActionSetDeliveryStatus.setDateEstimation(data.estimatedDelivery)
      );
      yield put(ActionSetDeliveryStatus.setComplaint(data.complaint));
    } else if (route === '/complain') {
      yield put(ActionComplaint.changeComplaintOwnerName(data.shipToName));
      yield put(ActionComplaint.changeComplaintDetailNoTelp(data.shipToPhone));
      yield put(ActionComplaint.changeComplaintBarcodeDetail(data.barcode));
      yield put(
        ActionComplaint.changeComplaintAddress(JSON.parse(data.shipToAddress))
      );
      yield put(ActionComplaint.changeComplaintPostCode(data.shipToPostCode));
      yield put(ActionComplaint.changeComplaintCity(data.shipToCity));
    } else if (route === '/deliveryPriority') {
      yield put(ActionDeliveryPriority.setDeliveryId(data.id));
      yield put(ActionDeliveryPriority.setWaybillCode(data.waybillCode));
      yield put(ActionDeliveryPriority.setCustomerName(data.shipToName));
      yield put(ActionDeliveryPriority.setCourierName(data.courierName));
      yield put(ActionDeliveryPriority.setCourierCode(data.courierCode));
      yield put(ActionDeliveryPriority.setBarcode(data.barcode));
      yield put(ActionDeliveryPriority.setDocumentStatus(data.deliveryStatus));
      yield put(
        ActionDeliveryPriority.setAddress(JSON.parse(data.shipToAddress))
      );
    } else {
    }

    yield put(changeLoadingFalse());
  } catch (error) {
    if (
      error.response.data.Message ===
        `No delivery with waybill ${waybill} found` &&
      route === '/delivery/status'
    ) {
      yield put(ActionSetDeliveryStatus.clearDeliveryStatusEcveptWaybill());
      yield put(changeLoadingFalse());
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>Waybill yang anda cari tidak ada</p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>Silahkan coba lagi</p>
          </div>
        ),
        onOk() {},
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}
export function* S_getFocusDeliveryStatus(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const waybillFocus = state.DeliveryStatus.Properties.WaybillFocusDelivery;
  const idFocus = state.DeliveryStatus.Properties.IdFocusDelivery;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Delivery/waybillDetail/' +
        idFocus +
        '/' +
        waybillFocus,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionSetDeliveryStatus.setDataDocument(data));
    yield put(ActionSetDeliveryStatus.setProcessTime(data.itemProcessTime));
    yield put(ActionSetDeliveryStatus.setDeliveryTime(data.itemDeliveryTime));
    yield put(ActionSetDeliveryStatus.setFinishedTime(data.itemFinishedTime));
    yield put(ActionSetDeliveryStatus.setFilterTypeDS(data.filterType));
    yield put(ActionSetDeliveryStatus.setValueTypeDS(data.valueFilter));
    yield put(ActionSetDeliveryStatus.setBarcode(data.barcode));
    yield put(
      ActionSetDeliveryStatus.setDocumentType(
        data.itemType === 'Document' ? 'Dokumen' : data.itemType
      )
    );
    yield put(ActionSetDeliveryStatus.setDeliveryType(data.deliveryType));
    yield put(
      ActionSetDeliveryStatus.setDeliveryPosition(data.deliveryPosition)
    );
    yield put(ActionSetDeliveryStatus.setCourierCode(data.courierCode));
    yield put(ActionSetDeliveryStatus.setCourierName(data.courierName));
    yield put(ActionSetDeliveryStatus.setOwnerName(data.shipToName));
    yield put(ActionSetDeliveryStatus.setNoTelp(data.shipToPhone));
    yield put(
      ActionSetDeliveryStatus.setAddress(JSON.parse(data.shipToAddress))
    );
    yield put(
      ActionSetDeliveryStatus.setAdditionalData(JSON.parse(data.additionalData))
    );
    yield put(ActionSetDeliveryStatus.setOtherData(data.details));
    // yield put(ActionSetDeliveryStatus.setDeliveryDuration(data.shipToAddress))
    yield put(ActionSetDeliveryStatus.setImage(data.image));
    yield put(ActionSetDeliveryStatus.setSignature(data.signature));
    yield put(ActionSetDeliveryStatus.setStatusDelivery(data.deliveryStatus));
    yield put(ActionSetDeliveryStatus.setReceiverName(data.receiverName));
    yield put(ActionSetDeliveryStatus.setReceiverTitle(data.receiverTitle));
    yield put(ActionSetDeliveryStatus.setReturnedReason(data.returnedReason));
    yield put(
      ActionSetDeliveryStatus.setReceiverLocation(data.receiverLocation)
    );
    yield put(ActionSetDeliveryStatus.setComplaint(data.complaint));
    yield put(ActionSetDeliveryStatus.setHistory(data.history));
    let DeliveryTime = moment(data.itemDeliveryTime).format('YYYY/MM/DD');
    let FinishedTime = moment(data.itemFinishedTime).format('YYYY/MM/DD');
    let DateNow = moment(new Date()).format('YYYY-MM-DD');
    let DateEstimation =
      data.itemFinishedTime === null
        ? moment.duration(moment(DateNow).diff(moment(DeliveryTime)))
        : moment.duration(moment(FinishedTime).diff(moment(DeliveryTime)));
    yield put(
      ActionSetDeliveryStatus.setDateEstimation(
        DateEstimation.asDays() + 1 <= 0 && data.itemFinishedTime === null
          ? 0
          : DateEstimation.asDays() + 1 <= 0 && data.itemFinishedTime !== null
          ? 0
          : DateEstimation.asDays() + 1
      )
    );
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* SM_getFocusDeliveryStatus() {
  yield takeLatest(
    'GET_FOCUS_DELIVERY_STATUS_WAYBILL',
    S_getFocusDeliveryStatus
  );
}
export function* SM_getDeliveryStatus() {
  yield takeLatest('GET_DELIVERY_STATUS', S_getDeliveryStatus);
}
