import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionGeneralData from '../../App/Store/GeneralDataAction';
import * as ActionLogActivity from '../../LogActivity/Store/LogActivityAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsGeneralData from '../../App/Selector/GeneralDataSelector';
import * as SelectorsLogActivity from '../../LogActivity/Selector/LogActivitySelector';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { change, getFormSyncErrors, getFormValues } from 'redux-form';
import { connect, useDispatch } from 'react-redux';

import LogActivityComponent from '../Component/LogActivityComponent';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

function LogActivityContainer(props) {
  const {
    formValue,
    componentAction,
    pagePagination,
    actionGeneralData,
    actionLogActivity,
  } = props;
  const valueData = formValue ? formValue : '';
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(change('logActivityForm', 'logType', []));
    dispatch(change('logActivityForm', 'user', []));
    if (valueData.startDateLogActivity) {
      dispatch(change('logActivityForm', 'finishDateLogActivity', null));
    }
    // eslint-disable-next-line
  }, [actionLogActivity]);
  const handleGetLogActivity = () => {
    actionGeneralData.getLogActivityList();
  };
  const handleGetUser = () => {
    actionGeneralData.getUserList();
  };
  const handlePage = page => {
    componentAction.setPagePagination(page);
    actionLogActivity.getLogActivityDataList();
  };
  const handleSearchLogActivity = () => {
    actionLogActivity.getLogActivityDataList();
  };
  const handleDownload = () => {
    actionLogActivity.downloadLogActivityData();
  };
  const handleReset = () => {
    actionLogActivity.resetFormLogActivity();
    dispatch(change('logActivityForm', 'logType', []));
    dispatch(change('logActivityForm', 'user', []));
  };
  const looppage = (pagePagination - 1) * 10 + 1;
  const columns = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <b>No</b>
        </div>
      ),
      width: 50,
      render: (text: any, record: any, index: any) => (
        <span>{pagePagination > 1 ? index + looppage : index + 1}</span>
      ),
      key: 'no',
    },
    {
      title: 'Tanggal Aktivitas',
      dataIndex: 'timestamp',
      render: (timestamp: any) =>
        timestamp === null ? (
          '-'
        ) : (
          <span>{moment(timestamp).format('DD MMM YYYY HH:mm:ss')}</span>
        ),
      key: 'timestamp',
      width: 80,
    },
    {
      title: 'Nama Pengguna',
      dataIndex: 'actor',
      key: 'actor',
      width: 80,
    },
    {
      title: 'Aktivitas',
      dataIndex: 'action',
      render: action => <span>{action}</span>,
      key: 'action',
      width: 90,
    },
    {
      title: 'Deskripsi',
      dataIndex: 'description',
      render: (description: any) =>
        description === null ? '-' : <span>{description}</span>,
      key: 'description',
      width: 110,
    },
  ];
  return (
    <React.Fragment>
      <LogActivityComponent
        handlePage={handlePage}
        columns={columns}
        handleGetLogActivity={handleGetLogActivity}
        handleGetUser={handleGetUser}
        handleSearchLogActivity={handleSearchLogActivity}
        handleReset={handleReset}
        handleDownload={handleDownload}
        {...props}
      />
    </React.Fragment>
  );
}

const mapStateToProps = () =>
  createStructuredSelector({
    logActivityDataList: SelectorsLogActivity.makeLogActivityDataListSelector(),
    logActivityList: SelectorsGeneralData.makeLogActivityListSelector(),
    userList: SelectorsGeneralData.makeUserListSelector(),
    pagePagination: SelectorsComponent.makePagePagination(),
    totalPagination: SelectorsComponent.makeTotalPagePagination(),
    isLoading: SelectorsComponent.makeIsLoading(),
    formValue: getFormValues('logActivityForm'),
    errorFormValue: getFormSyncErrors('logActivityForm'),
  });
const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
  actionGeneralData: bindActionCreators(ActionGeneralData, dispatch),
  actionLogActivity: bindActionCreators(ActionLogActivity, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(LogActivityContainer);
