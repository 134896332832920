import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import {
  getAllCustomers,
  getProductByCustomers,
} from 'services/GeneralService';
import { postRestoreDataCustomer } from 'services/RestoreService';
import ModalStatus from 'materials/ModalStatus';
import ModalConfirmation from 'components/ModalConfirmation';
import ModalMaterial from 'materials/ModalMaterial';
import FormRestoreCustomerComponent from '../Component/FormRestoreCustomerComponent';

type FormRestoreCustomerContainerType = {
  handleHideModal: () => void;
  refetch: () => void;
  modalAdd: boolean;
};

export default function FormRestoreCustomerContainer({
  handleHideModal,
  refetch,
  modalAdd,
}: FormRestoreCustomerContainerType) {
  const [optionProducts, setOptionsProducts] = useState<Array<any>>([]);

  const [selectedCustomer, setSelectedCustomer] = useState<number | undefined>(
    undefined
  );
  const [selectedProduct, setSelectedProduct] = useState<Array<any>>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<Array<any>>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(
    undefined
  );
  const [selectedArchiveType, setSelectedArchiveType] =
    useState<string>('Cycle');
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);

  const { data: dataAllCustomers, isLoading: isLoadingCustomers } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataCustomers'],
    queryFn: () => getAllCustomers(),
  });

  const { data: dataAllProducs } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataProductByCustomers', { id: selectedCustomer }],
    queryFn: ({ queryKey }) => getProductByCustomers(queryKey),
    enabled: !!selectedCustomer,
  });

  const { mutate: submitRestoreCustomre, isLoading } = useMutation(
    postRestoreDataCustomer,
    {
      onSuccess: res => {
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Data berhasil disimpan',
          });
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
        setShowModalConfirm(false);
        refetch();
        handleClearAndHide();
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    }
  );

  useEffect(() => {
    if (dataAllProducs && dataAllProducs?.data?.data) {
      const arrProduct = [{ id: 0, name: 'Pilih Semua' }] as Array<any>;
      dataAllProducs?.data?.data.forEach(item => {
        arrProduct.push({ id: item.id, name: item.name });
      });
      setOptionsProducts(arrProduct);
    }
  }, [dataAllProducs]);

  const handleClearData = () => {
    setSelectedCustomer(undefined);
    setSelectedProduct([]);
    setSelectedYear(undefined);
    setSelectedPeriod([]);
  };

  const handleClearAndHide = () => {
    handleClearData();
    handleHideModal();
  };

  const handleSubmit = () => {
    const data = {
      customerProductId: selectedProduct,
      startDate:
        selectedPeriod[0].startOf('month').format('YYYY-MM-DD') || null,
      endDate: selectedPeriod[1].endOf('month').format('YYYY-MM-DD') || null,
      archiveDateType: selectedArchiveType,
    };
    submitRestoreCustomre(data);
  };

  return (
    <>
      <ModalMaterial
        width={'40%'}
        teksTitle={'PERMINTAAN HAPUS DATA PELANGGAN'}
        visible={modalAdd}
        onCancel={handleClearAndHide}
        content={
          <>
            <FormRestoreCustomerComponent
              isLoading={isLoadingCustomers || isLoading}
              dataAllCustomers={dataAllCustomers?.data?.data || []}
              dataAllProducts={optionProducts}
              handleSelectCustomer={value => setSelectedCustomer(value)}
              handleSelectProduct={value => setSelectedProduct(value)}
              handleSelectYear={value => setSelectedYear(value)}
              setSelectedPeriod={value => setSelectedPeriod(value)}
              setSelectedArchiveType={value => setSelectedArchiveType(value)}
              selectedCustomer={selectedCustomer}
              selectedProduct={selectedProduct}
              selectedYear={selectedYear}
              selectedPeriod={selectedPeriod}
              selectedArchiveType={selectedArchiveType}
              handleSubmit={() => setShowModalConfirm(!showModalConfirm)}
            />
            <ModalConfirmation
              title="Simpan Permintaan?"
              description="Data pelanggan akan terhapus sesuai detail permintaan dan tidak dapat dibatalkan"
              visibleModal={showModalConfirm}
              cancelText="YA"
              okeText="TIDAK"
              onConfirm={() => setShowModalConfirm(!showModalConfirm)}
              isLoadingYa={isLoading}
              onCancel={handleSubmit}
            />
          </>
        }
        className="modalProcessPickupAssignCourier"
      />
    </>
  );
}
