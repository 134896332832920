import { Tabs } from 'antd';
import { getTabMenus } from 'lib/util';
import React, { Component } from 'react';
import UploadSoftcopyContainer from '../../../../../containers/Content/Delivery/Resend/UploadSoftcopy/UploadSoftcopyContainer';
import SingleDataContainer from '../../../../../containers/Content/Delivery/Resend/SingleData/SingleDataContainer';
const { TabPane } = Tabs;

interface IProps {
  lastAccessFiture: any;
  handleChangeTabs: any;
  sideMenus: any;
}

export default class IndexComponent extends Component<IProps> {
  render() {
    let a = '1';
    const tabMenus = getTabMenus(
      this.props.sideMenus,
      'Pengiriman',
      'Kirim Ulang'
    );

    if (this.props.lastAccessFiture === 'resendOneDelivery') {
      a = '2';
    } else {
      a = '1';
    }

    return (
      <div>
        <Tabs
          style={{ padding: '35px' }}
          defaultActiveKey={a}
          onChange={this.props.handleChangeTabs}
        >
          {tabMenus.map((item, index) => (
            <TabPane tab={<b>{item.label}</b>} key={index + 1}>
              {item.label === 'Unggah Data KU' ? (
                <UploadSoftcopyContainer />
              ) : item.label === 'Data Satuan KU' ? (
                <SingleDataContainer />
              ) : null}
            </TabPane>
          ))}
        </Tabs>
      </div>
    );
  }
}
