import instance from 'config/interceptor';

export const getRestoreDataCustomer = async params => {
  try {
    const { page = 0, isActive = 'All', size = 10, name } = params[1];
    let link = `/ArchiveData/${isActive}/${page}/${size}`;
    if (name) link = link.concat(`?name=${name}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const postRestoreDataCustomer = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/ArchiveData`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const getSingleBastArchive = async params => {
  try {
    const { id } = params[1];
    return await instance.get(
      process.env.REACT_APP_URL + `/ArchiveData/FormInput/${id}`
    );
  } catch (error) {
    return error;
  }
};

export const downloadBastArchive = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/ArchiveData/DownloadBastArchiveData`,
      data,
      { responseType: 'arraybuffer' }
    );
  } catch (error) {
    return error;
  }
};

export const downloadTemplateDeleteOrRestore = async () => {
  try {
    return await instance.get(
      process.env.REACT_APP_URL + `/Delivery/TemplateBackupAndRestore`
    );
  } catch (error) {
    return error;
  }
};

export const deleteDataByFile = async data => {
  try {
    return await instance.delete(
      process.env.REACT_APP_URL + `/Delivery/BackupData/Upload`,
      { data }
    );
  } catch (error) {
    return error;
  }
};

export const restoreDataByFile = async data => {
  try {
    return await instance.delete(
      process.env.REACT_APP_URL + `/Delivery/RestoreData/Upload`,
      { data }
    );
  } catch (error) {
    return error;
  }
};
