import { Action } from 'redux';

const initialState = {
  PropertiesScanManifest: {
    LoadingGroupName: false,
    LoadingTypeGroupName: null,
    ShowTable: false,
    ScanStatus: null,
    BorWScanned: null,
    ScannedByUser: null,
    CountScanned: null,
    CountUnScanned: null,
    TotalScanned: null,
    ScannedId: null,
    ValidateGroupName: null,
  },
  ScanManifestForm: {
    ManifestRadioButtonForm: 'Success',
    ManifestCustomerForm: null,
    ManifestProductForm: null,
    ManifestBorWForm: null,
    ManifestGroupForm: null,
    ManifestCheckboxForm: false,
    ManifestMonthly: false,
    ManifestCycleDateForm: null,
    Condition: null,
  },
  SearchValue: null,
  ResponseScan: null,
  GroupNameForm: null,
  GroupId: null,
  MultipleBarcodeSelected: null,
  ListGroup: [],
  ListMultipleBarcode: [],
  TableScanManifest: [],
};

export interface IAuthAction extends Action<string> {}

export interface IChangeLoadingGroupName extends IAuthAction {
  LoadingGroupName: any;
}
export interface IChangeLoadingTypeGroupName extends IAuthAction {
  LoadingTypeGroupName: any;
}
export interface IChangeShowTable extends IAuthAction {
  ShowTable: any;
}
export interface IChangeScanStatus extends IAuthAction {
  ScanStatus: any;
}
export interface IChangeBorWScanned extends IAuthAction {
  BorWScanned: any;
}
export interface IChangeScannedByUser extends IAuthAction {
  ScannedByUser: any;
}
export interface IChangeCountScanned extends IAuthAction {
  CountScanned: any;
}

export interface IChangeCountUnScanned extends IAuthAction {
  CountUnScanned: any;
}
export interface IChangeTotalScanned extends IAuthAction {
  TotalScanned: any;
}
export interface IChangeScannedId extends IAuthAction {
  ScannedId: any;
}
export interface IChangeValidateGroupName extends IAuthAction {
  ValidateGroupName: any;
}
export interface IChangeManifestCustomerForm extends IAuthAction {
  ManifestCustomerForm: any;
}
export interface IChangeManifestProductForm extends IAuthAction {
  ManifestProductForm: any;
}
export interface IChangeManifestBorWForm extends IAuthAction {
  ManifestBorWForm: any;
}
export interface IChangeManifestGroupForm extends IAuthAction {
  ManifestGroupForm: any;
}
export interface IChangeManifestCheckboxForm extends IAuthAction {
  ManifestCheckboxForm: any;
}
export interface IChangeManifestRadioButtonForm extends IAuthAction {
  ManifestRadioButtonForm: any;
}
export interface IChangeManifestCycleDateForm extends IAuthAction {
  ManifestCycleDateForm: any;
}
export interface IChangeListGroup extends IAuthAction {
  ListGroup: any;
}
export interface IFetchListMultipleBarcode extends IAuthAction {
  ListMultipleBarcode: any;
}
export interface IChangeTableScanManifest extends IAuthAction {
  TableScanManifest: any;
}
export interface IChangeSearchValue extends IAuthAction {
  SearchValue: any;
}
export interface IFetchResponseScan extends IAuthAction {
  ResponseScan: any;
}
export interface IChangeGroupNameForm extends IAuthAction {
  GroupNameForm: any;
}
export interface IChangeMultipleBarcodeSelected extends IAuthAction {
  MultipleBarcodeSelected: any;
}
export interface IChangeGroupId extends IAuthAction {
  GroupId: any;
}
export interface IDownloadResultCountManifest extends IAuthAction {
  Condition: any;
}

export default function AuthReducer(state = initialState, action: IAuthAction) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_LOADING_GROUP_NAME':
      newState.PropertiesScanManifest.LoadingGroupName =
        !newState.PropertiesScanManifest.LoadingGroupName;
      return { ...newState };

    case 'CHANGE_SHOW_TABLE_SCAN_MANIFEST':
      const changeShowTable = action as IChangeShowTable;
      newState.PropertiesScanManifest.ShowTable =
      changeShowTable.ShowTable;
      return { ...newState };

    case 'CHANGE_SCAN_STATUS':
      const changeScanStatus = action as IChangeScanStatus;
      newState.PropertiesScanManifest.ScanStatus = changeScanStatus.ScanStatus;
      return { ...newState };

    case 'CHANGE_SCANNED_BY_USER':
      const changeScannedByUser = action as IChangeScannedByUser;
      newState.PropertiesScanManifest.ScannedByUser =
        changeScannedByUser.ScannedByUser;
      return { ...newState };
    case 'CHANGE_SCANNED_STATUS':
      const changeBorWScanned = action as IChangeBorWScanned;
      newState.PropertiesScanManifest.BorWScanned =
        changeBorWScanned.BorWScanned;
      return { ...newState };

    case 'CHANGE_COUNT_SCANNED':
      const changeCountScanned = action as IChangeCountScanned;
      newState.PropertiesScanManifest.CountScanned =
        changeCountScanned.CountScanned;
      return { ...newState };

    case 'CHANGE_COUNT_UN_SCANNED':
      const changeCountUnScanned = action as IChangeCountUnScanned;
      newState.PropertiesScanManifest.CountUnScanned =
        changeCountUnScanned.CountUnScanned;
      return { ...newState };

    case 'CHANGE_TOTAL_SCANNED':
      const changeTotalScanned = action as IChangeTotalScanned;
      newState.PropertiesScanManifest.TotalScanned =
        changeTotalScanned.TotalScanned;
      return { ...newState };

    case 'CHANGE_SCANNED_ID':
      const changeScannedId = action as IChangeScannedId;
      newState.PropertiesScanManifest.ScannedId = changeScannedId.ScannedId;
      return { ...newState };

    case 'CHANGE_VALIDATE_GROUP_NAME':
      const changeValidateGroupName = action as IChangeValidateGroupName;
      newState.PropertiesScanManifest.ValidateGroupName =
        changeValidateGroupName.ValidateGroupName;
      return { ...newState };

    case 'CHANGE_MANIFEST_CUSTOMER_FORM':
      const changeManifestCustomerForm = action as IChangeManifestCustomerForm;
      newState.ScanManifestForm.ManifestCustomerForm =
        changeManifestCustomerForm.ManifestCustomerForm;
      return { ...newState };

    case 'CHANGE_MANIFEST_PRODUCT_FORM':
      const changeProductManifestForm = action as IChangeManifestProductForm;
      newState.ScanManifestForm.ManifestProductForm =
        changeProductManifestForm.ManifestProductForm;
      return { ...newState };

    case 'CHANGE_MANIFEST_B_OR_W_FORM':
      const changeManifestBorWForm = action as IChangeManifestBorWForm;
      newState.ScanManifestForm.ManifestBorWForm =
        changeManifestBorWForm.ManifestBorWForm;
      return { ...newState };

    case 'CHANGE_MANIFEST_GROUP_FORM':
      const changeManifestGroupForm = action as IChangeManifestGroupForm;
      newState.ScanManifestForm.ManifestGroupForm =
        changeManifestGroupForm.ManifestGroupForm;
      return { ...newState };

    case 'CHANGE_MANIFEST_CHECKBOX_FORM':
      const changeManifestCheckboxForm = action as IChangeManifestCheckboxForm;
      newState.ScanManifestForm.ManifestCheckboxForm =
        changeManifestCheckboxForm.ManifestCheckboxForm;
      return { ...newState };

    case 'CHANGE_MANIFEST_IS_MONTHLY':
      newState.ScanManifestForm.ManifestMonthly =
        !newState.ScanManifestForm.ManifestMonthly;
      return { ...newState };

    case 'CHANGE_MANIFEST_RADIO_BUTTON_FORM':
      const changeManifestRadioButtonForm =
        action as IChangeManifestRadioButtonForm;
      newState.ScanManifestForm.ManifestRadioButtonForm =
        changeManifestRadioButtonForm.ManifestRadioButtonForm;
      return { ...newState };

    case 'CHANGE_MANIFEST_CYCLE_DATE_FORM':
      const changeManifestCycleDateForm =
        action as IChangeManifestCycleDateForm;
      newState.ScanManifestForm.ManifestCycleDateForm =
        changeManifestCycleDateForm.ManifestCycleDateForm;
      return { ...newState };

    case 'CHANGE_LIST_GROUP':
      const changeListGroup = action as IChangeListGroup;
      newState.ListGroup = changeListGroup.ListGroup;
      return { ...newState };
    case 'FETCH_LIST_MULTIPLE_BARCODE':
      const fetchListMultipleBarcode = action as IFetchListMultipleBarcode;
      newState.ListMultipleBarcode = fetchListMultipleBarcode.ListMultipleBarcode;
      return { ...newState };

    case 'CHANGE_TABLE_SCAN_MANIFEST':
      const changeTableScanManifest = action as IChangeTableScanManifest;
      newState.TableScanManifest = changeTableScanManifest.TableScanManifest;
      return { ...newState };

    case 'CHANGE_GROUP_NAME_MANIFEST_FORM':
      const changeGroupNameForm = action as IChangeGroupNameForm;
      newState.GroupNameForm = changeGroupNameForm.GroupNameForm;
      return { ...newState };
    case 'CHANGE_MULTIPLE_BARCODE_SELECTED':
      const changeMultipleBarcodeSelected = action as IChangeMultipleBarcodeSelected;
      newState.MultipleBarcodeSelected = changeMultipleBarcodeSelected.MultipleBarcodeSelected;
      return { ...newState };
    
    case 'CHANGE_SEARCH_VALUE_MANIFEST_FORM':
      const changeSearchValue = action as IChangeSearchValue;
      newState.SearchValue = changeSearchValue.SearchValue;
      return { ...newState };
    case 'FETCH_RESPONSE_SCAN_MANIFEST':
      const fetchResponse = action as IFetchResponseScan;
      newState.ResponseScan = fetchResponse.ResponseScan;
      return { ...newState };

    case 'CHANGE_GROUP_ID':
      const changeGroupId = action as IChangeGroupId;
      newState.GroupId = changeGroupId.GroupId;
      return { ...newState };

    case 'DOWNLOAD_RESULT_COUNT_MANIFEST':
      const DownloadResultCountManifest =
        action as IDownloadResultCountManifest;
      newState.ScanManifestForm.Condition =
        DownloadResultCountManifest.Condition;
      return { ...newState };

    case 'CLEAR_MANIFEST_FORM':
      newState.ScanManifestForm.ManifestRadioButtonForm = 'Success';
      newState.ScanManifestForm.ManifestCustomerForm = null;
      newState.ScanManifestForm.ManifestProductForm = null;
      newState.ScanManifestForm.ManifestGroupForm = null;
      newState.ScanManifestForm.ManifestBorWForm = null;
      newState.ScanManifestForm.ManifestCheckboxForm = false;
      newState.ScanManifestForm.ManifestMonthly = false;
      newState.ScanManifestForm.Condition = null;
      newState.ScanManifestForm.ManifestCycleDateForm = null;
      return { ...newState };

    case 'CLEAR_PROPERTIES_SCAN_MANIFEST':
      newState.PropertiesScanManifest.LoadingGroupName = false;
      newState.PropertiesScanManifest.LoadingTypeGroupName = null;
      newState.PropertiesScanManifest.ShowTable = false;
      newState.PropertiesScanManifest.ScanStatus = null;
      newState.PropertiesScanManifest.BorWScanned = null;
      newState.PropertiesScanManifest.ScannedByUser = null;
      newState.PropertiesScanManifest.CountScanned = null;
      newState.PropertiesScanManifest.CountUnScanned = null;
      newState.PropertiesScanManifest.TotalScanned = null;
      newState.PropertiesScanManifest.ScannedId = null;
      newState.PropertiesScanManifest.ValidateGroupName = null;
      return { ...newState };

    case 'CLEAR_VALIDATE_GROUP_NAME':
      newState.PropertiesScanManifest.ValidateGroupName = null;
      return { ...newState };

    case 'CLEAR_LOADING_TYPE_GROUP_NAME':
      newState.PropertiesScanManifest.LoadingTypeGroupName = null;
      return { ...newState };

    case 'CLEAR_SCAN_STATUS_AND_B_OR_W':
      newState.PropertiesScanManifest.ScanStatus = null;
      newState.PropertiesScanManifest.BorWScanned = null;
      return { ...newState };

    case 'CLEAR_MANIFEST_B_OR_W_FORM':
      newState.ScanManifestForm.ManifestBorWForm = null;
      return { ...newState };

    case 'CLEAR_B_OR_W':
      newState.PropertiesScanManifest.BorWScanned = null;
      return { ...newState };

    case 'CLEAR_MANIFEST_GROUP_ID':
      newState.ScanManifestForm.ManifestGroupForm = null;
      return { ...newState };

    case 'CLEAR_MANIFEST_PRODUCT_FORM':
      newState.ScanManifestForm.ManifestProductForm = null;
      return { ...newState };

    case 'CLEAR_COLLAPSE':
      newState.ScanManifestForm.ManifestCustomerForm = null;
      newState.ScanManifestForm.ManifestProductForm = null;
      newState.ScanManifestForm.ManifestCycleDateForm = null;
      return { ...newState };

    case 'CLEAR_GROUP_LIST':
      newState.ListGroup = [];
      return { ...newState };
    case 'CLEAR_LIST_MULTIPLE_BARCODE':
      newState.ListMultipleBarcode = [];
      return { ...newState };

    case 'CLEAR_GROUP_NAME_FORM':
      newState.GroupNameForm = null;
      return { ...newState };
    case 'CLEAR_MULTIPLE_BARCODE_SELECTED':
      newState.MultipleBarcodeSelected = null;
      return { ...newState };
    case 'CLEAR_SEARCH_VALUE_MANIFEST_FORM':
      newState.SearchValue = null;
      return { ...newState };
    case 'CLEAR_RESPONSE_SCAN_MANIFEST':
      newState.ResponseScan = null;
      return { ...newState };

    case 'CLEAR_GROUP_ID':
      newState.GroupId = null;
      return { ...newState };

    case 'CLEAR_TABLE_MANIFEST':
      newState.TableScanManifest = [];
      return { ...newState };
  }
  return { ...newState };
}
