import * as ActionComplaint from 'core/action/Complaint/ActionFormComplaint';
import * as ActionComplaintList from 'core/action/Complaint/ActionComplaintList';
import * as ActionPagination from 'core/action/Pagination/ActionPagination';
import * as ActionToggle from 'core/action/toggle/index';

import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import IndexComplainComponent from 'module/Complaint/Component/IndexComplaintComponent';
import { connect } from 'react-redux';

interface IProps {
  isLoading: any;
  actionPagination: any;
  ValueSearch: any;
  getKey: any;
  filterStatus: any;
  ComplaintListData: any;
  PagePagination: any;
  TotalPagePagination: any;
  actionToggle: any;
  actionFormHoliday: any;
  actionTableHoliday: any;
  actionComplaint: any;
  actionComplaintList: any;
}

function IndexComplainContainer({
  isLoading,
  actionPagination,
  ValueSearch,
  getKey,
  filterStatus,
  ComplaintListData,
  PagePagination,
  TotalPagePagination,
  actionToggle,
  actionComplaint,
  actionComplaintList,
}: IProps) {
  const [page, setPage]: any = useState(0);

  useEffect(() => {
    actionComplaintList.getComplaintList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleModal() {
    actionToggle.changeModal();
    actionComplaint.changeComplaintStatus('new');
    actionToggle.changeJenisToggle('AddNewTicket');
  }
  function handlePage(event: any) {
    actionPagination.setPagePagination(event);
    setPage(event);
  }
  function handleSearch(event: any) {
    actionToggle.changeValueSearch(event.target.value.toLowerCase());
  }
  function handleFilter(event: any) {
    actionComplaint.changeKey(event.key);
    if (event.key === 'process') {
      actionComplaint.changeFilterStatus('0');
    } else if (event.key === 'finished') {
      actionComplaint.changeFilterStatus('1');
    } else {
      actionComplaint.changeFilterStatus('0%2C1');
    }
    actionComplaintList.getComplaintList();
  }

  return (
    <div>
      <IndexComplainComponent
        ValueSearch={ValueSearch}
        isLoading={isLoading}
        page={page}
        ComplaintListData={ComplaintListData}
        PagePagination={PagePagination}
        TotalPagePagination={TotalPagePagination}
        getKey={getKey}
        filterStatus={filterStatus}
        handlePage={handlePage}
        handleModal={handleModal}
        handleSearch={handleSearch}
        handleFilter={handleFilter}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  ValueSearch: state.Toggle.ValueSearch,
  isLoading: state.Toggle.isLoading,
  ValueSwitch: state.Holiday.PropertiesHoliday.SwitchHoliday,
  ComplaintListData: state.Complaint.complaintList,
  getKey: state.Complaint.complaintProperties.key,
  filterStatus: state.Complaint.complaintProperties.filterStatus,
  PagePagination: state.Pagination.PagePagination,
  TotalPagePagination: state.Pagination.TotalPagePagination,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionComplaint: bindActionCreators(ActionComplaint, dispatch),
  actionComplaintList: bindActionCreators(ActionComplaintList, dispatch),
  actionPagination: bindActionCreators(ActionPagination, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(IndexComplainContainer);
