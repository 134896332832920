import { Action } from 'redux';

export interface IFetchComplaintReportAction extends Action {
  data: object;
}
export function fetchCustomerProductList(
  data: object
): IFetchComplaintReportAction {
  return {
    type: 'FETCH_COMPLAINT_REPORT_CUSTOMER_PRODUCT_LIST',
    data,
  };
}
export function changeCustomerName(data: object): IFetchComplaintReportAction {
  return {
    type: 'CHANGE_CUSTOMER_NAME_COMPLAINT_REPORT',
    data,
  };
}
export function getCustomerProductList() {
  return {
    type: 'COMPLAINT_REPORT_GET_CUSTOMER_PRODUCT_LIST_REQUESTED',
  };
}
export function downloadComplaintReport() {
  return {
    type: 'DOWNLOAD_COMPLAINT_REPORT_REQUESTED',
  };
}
export function clearComplaintReportForm() {
  return {
    type: 'RESET_COMPLAINT_REPORT_FORM',
  };
}
