import '../../../../styles/InputUploadStyle/index.css';

import { Col, Icon, Row, Table } from 'antd';
import React from 'react';
import { Form, Field } from 'react-final-form';

import ButtonMaterial from '../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../materials/CardMaterial';
import ModalMaterial from '../../../../materials/ModalMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';
import SelectField from 'components/SelectField';
import InputFile from 'components/InputFile';
import InputDatePicker from 'components/InputDatePicker';
import Button from 'components/Button';
import moment from 'moment';
import { ColumnAlignTable, SelectOptionsInterface } from 'interface/general';
import { DataUploadDocumentType } from '../../../../containers/Content/Delivery/Document/UploadData/FormUnggahDocumentContainer';
import { productTypes } from 'lib/constants';

interface IProps {
  handlePage: any;
  handleConvert: any;
  handleGetCustomer: any;
  handleGetCustomerProduct: any;
  handleGetCustomCsvCustomer: any;
  handlePutCustomer: any;
  handlePutProductType: any;
  handlePutCustomerProduct: any;
  handlePutCustomCsvCustomer: any;
  handleUploadCsv: any;
  handleBlurCustomCsvCustomer: any;
  handleBlurCustomer: any;
  handleResetCustomer: any;
  handleResetUploadCsv: any;
  handleBlurProductType: any;
  handleResetProductType: any;
  handleBlurCustomerProduct: any;
  handleResetCustomerProduct: any;
  handleResetCustomCsv: any;
  handleModalDetailStatus: any;
  handleCloseModalDetailStatus: any;
  handleRefresh: any;
  visible2: any;
  jenisToggle2: any;
  detailStatusUploadDocument: any;
  listStatusUploadDocument: any;
  Csv: any;
  resultUploadData: any;
  countSuccess: any;
  countFailed: any;
  typeLoading: any;
  validateCustomerForm: any;
  validateProductTypeForm: any;
  valueGetCustomer: any;
  valueGetCustomerProduct: any;
  valueGetCustomCsvCustomer: any;
  validateCustomerProductForm: any;
  validateCustomCsvCustomer: any;
  Customer: any;
  ProductType: any;
  CustomerProduct: any;
  listCustomer: any;
  listCustomerProduct: any;
  listCustomCsvCustomer: any;
  ListCustomCsvState: any;
  isLoading: any;
  file: any;
  fileName: any;
  defaultCsv: any;
  customCsvCustomer: any;
  PagePagination: any;
  TotalPagePagination: any;
  LoadingSelectCustomer: any;
  LoadingTypeSelectCustomer: any;
  LoadingSelectCustomerProduct: any;
  LoadingTypeSelectCustomerProduct: any;
  handlePutDeliveryDate: any;
  handlePutUploadDate: any;
  customDeliveryDate: any;
  customUploadDate: any;
  validateResetButton: any;
  handleModal: any;
  handleNext: any;
  handleDownload: any;
  valueUploadDateForm: any;
  valueDeliveryDateForm: any;
  formatFile: any;
  dataUploadDocument: DataUploadDocumentType;
  handleChangeForm: (name: string, value: any) => void;
  handleResetForm: () => void;
  dataCustomers: SelectOptionsInterface[];
  isLoadingCustomers: boolean;
  dataProducts: any[];
  isLoadingProducts: boolean;
  dataCsvList: any[];
  dataBranchs: any[];
  isLoadingCsvList: boolean;
  isLoadingBranch: boolean;
  isLoadingUpload: boolean;
  isLoadingDeliveryContinue: boolean;
  handleSubmitUpload: () => void;
  handleSubmitContinue: (value: boolean) => void;
}
export default function FormUnggahDataComponent(props: IProps) {
  const statusUpload = (props: any) => {
    const status = {
      Error: <span className="redText">Gagal</span>,
      Upload: <span style={{ color: '#ECA722' }}>Dalam Proses</span>,
      Continue: <span style={{ color: '#ECA722' }}>Dalam Proses</span>,
      Duplicate: <span style={{ color: '#11BEFF' }}>Duplikasi</span>,
    };

    return status[props];
  };

  const modalDetailStatusUpload = () => {
    const valyeDetailStatusDocument = props.detailStatusUploadDocument || null;
    const isError = valyeDetailStatusDocument?.status === 'Error';
    const isDuplicate = valyeDetailStatusDocument?.status === 'Duplicate';

    const renderData = (label: any, content: any) => {
      return (
        <Row>
          <Col span={6}>
            <div>
              <span>
                <b>{label}</b>
              </span>
            </div>
          </Col>
          <Col span={18}>
            <div>
              <span>{`: ${content}`}</span>
            </div>
          </Col>
        </Row>
      );
    };

    return (
      <ModalMaterial
        className={''}
        width={'50%'}
        style={{}}
        bodyStyle={{ padding: 0 }}
        teksTitle={'DETAIL FILE'}
        visible={
          props.visible2 === true && props.jenisToggle2 === 'DetailStatusUpload'
            ? true
            : false
        }
        onCancel={props.handleCloseModalDetailStatus}
        content={
          <SpinMaterial spinning={props.isLoadingDeliveryContinue} size="large">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                padding: '24px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 12,
                }}
              >
                {renderData('Nama File', valyeDetailStatusDocument?.fileName)}
                {renderData(
                  'Tanggal Upload',
                  moment(valyeDetailStatusDocument?.dateUpload).format(
                    'DD-MM-YYYY HH:mm:ss'
                  )
                )}
                {renderData(
                  'User Upload',
                  valyeDetailStatusDocument?.actorUpload
                )}
              </div>

              <div
                style={{
                  display: 'flex',
                  padding: 16,
                  borderRadius: 10,
                  border: `1px solid ${
                    isError || isDuplicate ? '#EA1A1A' : '#ECA722'
                  }`,
                  gap: 12,
                  background:
                    isError || isDuplicate ? '#EA1A1A29' : '#FCC64229',
                }}
              >
                <Icon
                  type={
                    isError || isDuplicate
                      ? 'close-circle'
                      : 'exclamation-circle'
                  }
                  style={{
                    color: isError || isDuplicate ? '#EA1A1A' : '#ECA722',
                    fontSize: 16,
                  }}
                />
                {isDuplicate ? (
                  <div
                    style={{ display: 'flex', flexDirection: 'column', gap: 6 }}
                  >
                    {valyeDetailStatusDocument?.message
                      .split(';')
                      .map(message => (
                        <p
                          style={{ fontSize: 14, margin: 0, lineHeight: '1.2' }}
                        >
                          {message}
                        </p>
                      ))}
                  </div>
                ) : (
                  <p style={{ fontSize: 14, margin: 0, lineHeight: '1.2' }}>
                    {isError
                      ? valyeDetailStatusDocument?.message
                      : 'File sedang dalam proses unggah. Silakan periksa secara berkala melalui tabel Riwayat Unggah'}
                  </p>
                )}
              </div>

              {isDuplicate && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 16,
                    justifyContent: 'flex-end',
                  }}
                >
                  <ButtonMaterial
                    teksButton="Batalkan Proses"
                    type="danger"
                    style={{
                      colorFont: '#11BEFF',
                      height: '35px',
                      width: 150,
                      borderRadius: '18px',
                      background: '#ea1a1a',
                      borderColor: '#ea1a1a',
                      fontWeight: 600,
                    }}
                    handleSubmit={() => props.handleSubmitContinue(false)}
                  />
                  <ButtonMaterial
                    teksButton="Lanjutkan"
                    type="primary"
                    style={{
                      colorFont: '#11BEFF',
                      height: '35px',
                      width: 100,
                      borderRadius: '18px',
                      background: '#11beff',
                      borderColor: '#11beff',
                      fontWeight: 600,
                    }}
                    handleSubmit={() => props.handleSubmitContinue(true)}
                  />
                </div>
              )}
            </div>
          </SpinMaterial>
        }
      />
    );
  };
  const looppage = (props.PagePagination - 1) * 10 + 1;
  const columns = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <b>No</b>
        </div>
      ),
      width: 50,
      render: (text: any, record: any, index: any) => (
        <span>{props.PagePagination > 1 ? index + looppage : index + 1}</span>
      ),
      key: 'no',
    },
    {
      title: <b>Nama File</b>,
      dataIndex: 'fileName',
      key: 'fileName',
      width: 120,
    },
    {
      title: <b>Pelanggan</b>,
      dataIndex: 'customer',
      key: 'customer',
      width: 120,
    },
    {
      title: <b>Produk</b>,
      dataIndex: 'customerProduct',
      key: 'customerProduct',
      width: 120,
    },
    {
      title: <b>Format CSV</b>,
      dataIndex: 'formatCsv',
      key: 'formatCsv',
      width: 120,
    },
    {
      title: <b>Status</b>,
      dataIndex: 'status',
      key: 'status',
      render: (status: any) => <span>{statusUpload(status)}</span>,
      width: 80,
    },
    {
      title: <b>Tanggal Upload</b>,
      dataIndex: 'dateUpload',
      key: 'dateUpload',
      render: (dateUpload: any) =>
        dateUpload === null ? (
          '-'
        ) : (
          <span>{moment(dateUpload).format('DD-MM-YYYY HH:mm:ss')}</span>
        ),
      width: 120,
    },
    {
      title: <b>Aksi</b>,
      key: 'pilih',
      width: 50,
      align: 'center' as ColumnAlignTable,
      render: (record: any) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {(record.status === 'Error' || record.status === 'Duplicate') && (
            <div
              style={{
                borderRadius: 8,
                padding: 6,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#1890FF',
                cursor: 'pointer',
              }}
              onClick={() => props.handleModalDetailStatus(record.id)}
            >
              <img src="/icon-info.svg" alt="" />
            </div>
          )}
        </div>
      ),
    },
  ];
  return (
    <div>
      <SpinMaterial spinning={props.isLoadingUpload} size={'large'}>
        {modalDetailStatusUpload()}
        <CardMaterial
          title={<b style={{ color: '#11BEFF' }}>UNGGAH DATA</b>}
          style={{ borderRadius: '10px' }}
        >
          <Form
            initialValues={{ ...props.dataUploadDocument }}
            validate={values => {
              const errors = {} as any;

              if (!values.customer) {
                errors.customer = 'Pelanggan wajib dipilih';
              }
              if (!values.product) {
                errors.product = 'Produk wajib dipilih';
              }
              if (!values.branch) {
                errors.branch = 'Posko wajib dipilih';
              }
              if (!values.typeTemplate) {
                errors.typeTemplate = 'Template unggah wajib dipilih';
              }
              if (!values.uploadFileName) {
                errors.uploadFileName = 'File Unggah tidak boleh kosong';
              }

              return errors;
            }}
            onSubmit={props.handleSubmitUpload}
          >
            {({ valid, handleSubmit, form, values, submitSucceeded }) => (
              <Row gutter={[32, 32]}>
                <Col
                  span={12}
                  style={{ display: 'flex', flexDirection: 'column', gap: 24 }}
                >
                  <Field name="customer">
                    {({ input, meta }) => (
                      <SelectField
                        label="Pelanggan"
                        placeholder="Pilih Pelanggan"
                        value={input.value}
                        data={props.dataCustomers}
                        loading={props.isLoadingCustomers}
                        onChange={value => {
                          props.handleChangeForm('customer', value);
                          props.handleChangeForm('typeTemplate', null);
                          props.handleChangeForm('product', null);
                        }}
                        onBlur={input.onBlur}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                        validate
                      />
                    )}
                  </Field>
                  <Field name="product">
                    {({ input, meta }) => (
                      <SelectField
                        label="Produk"
                        placeholder="Pilih Produk"
                        value={input.value}
                        data={props.dataProducts}
                        loading={props.isLoadingProducts}
                        onChange={value => {
                          const selectedProduct = props.dataProducts.find(
                            item => item.id === value
                          );
                          props.handleChangeForm('product', value);
                          props.handleChangeForm(
                            'typeDocument',
                            selectedProduct?.productType
                          );
                        }}
                        onBlur={input.onBlur}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                        validate
                        disabled={!values.customer}
                      />
                    )}
                  </Field>
                  <Field name="typeDocument">
                    {({ input, meta }) => (
                      <SelectField
                        label="Jenis Dokumen"
                        placeholder="Pilih Jenis Dokumen"
                        onChange={value =>
                          props.handleChangeForm('typeDocument', value)
                        }
                        onBlur={input.onBlur}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                        valueKey="id"
                        labelKey="name"
                        data={productTypes}
                        value={input.value}
                        validate
                        disabled
                      />
                    )}
                  </Field>
                  <Field name="branch">
                    {({ input, meta }) => (
                      <SelectField
                        label="Asal Posko (Origin)"
                        placeholder="Pilih Posko"
                        data={props.dataBranchs}
                        value={input.value}
                        labelKey="name"
                        valueKey="id"
                        onChange={value =>
                          props.handleChangeForm('branch', value)
                        }
                        onBlur={input.onBlur}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                        loading={props.isLoadingBranch}
                        validate
                      />
                    )}
                  </Field>
                </Col>
                <Col
                  span={12}
                  style={{ display: 'flex', flexDirection: 'column', gap: 24 }}
                >
                  <Field name="typeTemplate">
                    {({ input, meta }) => (
                      <SelectField
                        label="Template Unggah"
                        placeholder="Pilih Template"
                        valueKey="id"
                        labelKey="nameCustomCsv"
                        value={input.value}
                        data={props.dataCsvList}
                        loading={props.isLoadingCsvList}
                        onChange={value =>
                          props.handleChangeForm('typeTemplate', value)
                        }
                        onBlur={input.onBlur}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                        validate
                        disabled={!values.customer}
                      />
                    )}
                  </Field>
                  <Field name="uploadFileName">
                    {({ input, meta }) => {
                      const dataTemplateUpload = props.dataCsvList;
                      const selectedTemplateUpload =
                        dataTemplateUpload.find(
                          value => value.id === values.typeTemplate
                        )?.nameCustomCsv || null;
                      const lengthFormatFile =
                        selectedTemplateUpload?.length || 0;
                      let accceptFormat = '.csv';

                      if (selectedTemplateUpload) {
                        const result = selectedTemplateUpload.substring(
                          lengthFormatFile - 2,
                          lengthFormatFile
                        );

                        if (result === 'x)') {
                          accceptFormat = '.xlsx';
                        } else if (result === 't)') {
                          accceptFormat = '.txt';
                        } else if (result === 's)') {
                          accceptFormat = '.xls';
                        } else {
                          accceptFormat = '.csv';
                        }
                      }
                      return (
                        <InputFile
                          label="Unggah File"
                          required
                          accept={accceptFormat}
                          validateFile={meta.touched && meta.error && 'error'}
                          error={meta.error && meta.touched && meta.error}
                          handleChangeFile={value =>
                            props.handleChangeForm('uploadFileName', value)
                          }
                          value={input.value}
                        />
                      );
                    }}
                  </Field>
                  <Field name="dateSend">
                    {({ input, meta }) => (
                      <InputDatePicker
                        label="Tanggal Kirim"
                        placeholder="Pilih Tanggal"
                        error={meta.touched && meta.error && meta.error}
                        value={input.value}
                        onChange={value =>
                          props.handleChangeForm('dateSend', value)
                        }
                        disabledDate={currentDate => {
                          return (
                            !currentDate ||
                            currentDate.isBefore('2020-01-01') ||
                            currentDate.isAfter(
                              moment(new Date())
                                .add(1, 'month')
                                .format('YYYY-MM-DD')
                            )
                          );
                        }}
                      />
                    )}
                  </Field>
                  <Field name="dateUpload">
                    {({ input, meta }) => (
                      <InputDatePicker
                        label="Tanggal Upload"
                        placeholder="Pilih Tanggal"
                        onChange={value =>
                          props.handleChangeForm('dateUpload', value)
                        }
                        error={meta.touched && meta.error && meta.error}
                        value={input.value}
                      />
                    )}
                  </Field>
                </Col>
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                    gap: 24,
                    justifyContent: 'flex-end',
                    padding: '0 16px',
                  }}
                >
                  <Button
                    text="Reset"
                    variant="danger"
                    disabled={!valid}
                    onClick={() => {
                      props.handleResetForm();
                      form.restart();
                    }}
                  />
                  <Button
                    text="Lanjut"
                    disabled={!valid}
                    onClick={() => {
                      handleSubmit();
                      form.restart();
                    }}
                  />
                </Col>
              </Row>
            )}
          </Form>
        </CardMaterial>
      </SpinMaterial>

      <SpinMaterial
        spinning={props.isLoading}
        style={{}}
        size={'large'}
        content={
          <React.Fragment>
            <div className="spacingTop">
              <CardMaterial
                extra={''}
                title={''}
                style={{}}
                content={
                  <Row>
                    <Col span={12}>
                      <span className="defaultCardTableAdd">
                        RIWAYAT UNGGAH
                      </span>
                    </Col>
                    <Col span={12}>
                      <ButtonMaterial
                        handleSubmit={props.handleRefresh}
                        teksButton={'Refresh Table'}
                        className={'btnRefresh'}
                        style={{ fontWeight: 600, borderRadius: 50 }}
                      />
                    </Col>
                  </Row>
                }
              />
              <Table
                bordered={false}
                pagination={{
                  onChange: page => {
                    props.handlePage(page);
                  },
                  pageSize: 10,
                  total: props.TotalPagePagination,
                  current:
                    props.PagePagination === 0 ? 1 : props.PagePagination,
                }}
                dataSource={props.listStatusUploadDocument}
                columns={columns}
              />
            </div>
          </React.Fragment>
        }
      />
      <ModalMaterial
        className={''}
        width={'30%'}
        style={{}}
        teksTitle={
          <span
            style={
              props.countSuccess === 0
                ? { color: '#FF5858' }
                : { color: '#31E116' }
            }
          >
            {props.resultUploadData}
          </span>
        }
        visible={props.typeLoading === 'resultUploadCsv' ? true : false}
        onCancel={props.handleModal}
        content={
          <Row>
            <div>
              <div
                style={{
                  marginTop: '30px',
                  marginLeft: '20px',
                  textAlign: 'start',
                }}
              >
                <p>
                  <span style={{ color: '#000000' }}>
                    Berhasil : {props.countSuccess}
                  </span>
                </p>
                <p>
                  <span style={{ color: '#000000' }}>
                    Gagal : {props.countFailed}
                  </span>
                </p>
              </div>
              <Col>
                <div
                  style={{
                    marginTop: '20px',
                    float: 'right',
                    paddingRight: '20px',
                  }}
                >
                  <ButtonMaterial
                    disabled={props.countSuccess === 0 ? true : false}
                    handleSubmit={props.handleNext}
                    teksButton={<b>Lanjut</b>}
                    size={'small'}
                    shape={''}
                    className={''}
                    type={'primary'}
                    icon={''}
                    style={
                      props.countSuccess === 0
                        ? {
                            height: '30px',
                            width: '70px',
                            borderRadius: '12px',
                          }
                        : {
                            colorFont: '#11BEFF',
                            height: '30px',
                            width: '70px',
                            borderRadius: '12px',
                          }
                    }
                  />
                </div>
                <div
                  style={{
                    marginTop: '20px',
                    paddingRight: '20px',
                    float: 'right',
                  }}
                >
                  <ButtonMaterial
                    disabled={''}
                    handleSubmit={props.handleDownload}
                    teksButton={<b>Download</b>}
                    size={'small'}
                    shape={''}
                    className={''}
                    type={'primary'}
                    icon={''}
                    style={{
                      colorFont: '#11BEFF',
                      height: '30px',
                      width: '100px',
                      borderRadius: '12px',
                      background: '#7956EE',
                      borderColor: '#7956EE',
                    }}
                  />
                </div>
              </Col>
            </div>
          </Row>
        }
      />
    </div>
  );
}
