import * as ActionDetailBranch from 'core/action/Branch/ActionDetailBranch';
import * as ActionEditBranch from 'core/action/Branch/ActionEditBranch';
import * as ActionFieldBranch from 'core/action/Branch/ActionFieldBranch';
import * as ActionToggle from 'core/action/toggle/index';

import { bindActionCreators, compose } from 'redux';

import ButtonBranchComponent from 'module/Branch/Component/ButtonBranchComponent';
import React from 'react';
import { connect } from 'react-redux';
import history from 'core/utils/History';

interface IProps {
  idBranch: any;
  actionDetailBranch: any;
  actionEditBranch: any;
  actionFieldBranch: any;
  actionToggle: any;
  typeButton: any;
}
function ButtonBranchContainer({
  idBranch,
  actionDetailBranch,
  actionEditBranch,
  actionFieldBranch,
  actionToggle,
  typeButton,
}: IProps) {
  function handleBranchDetail() {
    actionDetailBranch.setIdBranch(idBranch);
    actionDetailBranch.changeEditBranch(true);
    history.push('/branch/detail');
  }
  function handleBranchEdit() {
    actionDetailBranch.setIdBranch(idBranch);
    actionFieldBranch.getGroupNameList();
    actionToggle.changeModal();
    actionToggle.changeJenisToggle('EditBranch');
    actionEditBranch.changeDetailEditBranch();
  }
  return (
    <div>
      <ButtonBranchComponent
        handleBranchDetail={handleBranchDetail}
        handleBranchEdit={handleBranchEdit}
        typeButton={typeButton}
      />
    </div>
  );
}
const mapStateProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  actionDetailBranch: bindActionCreators(ActionDetailBranch, dispatch),
  actionEditBranch: bindActionCreators(ActionEditBranch, dispatch),
  actionFieldBranch: bindActionCreators(ActionFieldBranch, dispatch),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(ButtonBranchContainer);
