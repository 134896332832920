import * as ActionAuth from '../../action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionToogle from '../../action/toggle/index';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

export function* S_downloadDeliveryData(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const key = 'download';
  const DateFrom = moment(state.DataDelivery.Filter.CycleStart).format(
    'YYYYMMDD'
  );
  const DateTo = moment(state.DataDelivery.Filter.CycleEnd).format('YYYYMMDD');
  const RadioButton = state.DataDelivery.Filter.RadioButton;
  const CustomerProductParam = state.DataDelivery.Filter.CustomerProduct;
  const CustomerProductName = state.DataDelivery.Filter.CustomerProductName;
  const BranchId = state.DataDelivery.Filter.Branch;
  const CourierParam = state.DataDelivery.Filter.Courier;
  const CourierName = state.DataDelivery.Filter.CourierName;
  const DownloadType = state.DataDelivery.Filter.DownloadType;
  const DeliveStat = state.DataDelivery.Filter.DeliveryStatus;
  const CustomerName = state.DataDelivery.Filter.CustomerName;
  const CustomerId = state.DataDelivery.Filter.Customer;
  const PhysicalDocument = state.DataDelivery.Filter.PhysicalDocument;
  let DateNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  // DownloadType+" "+CustomerName+" "+CustomerProduct+" "+Courier+" "+DateNow+".xls"
  const custName = CustomerName === null ? '' : ` ${CustomerName}`;
  const custProd =
    CustomerProductName === null ? '' : ` ${CustomerProductName}`;
  const couName = CourierParam === null ? '' : ` ${CourierName}`;
  const file_name2 = `${DownloadType} ${custName} ${custProd} ${couName} ${DateNow}`;
  const conditionRB = RadioButton.toLowerCase();
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = file_name2 + '.xls';
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }
  try {
    yield put(ActionToogle.changeLoadingTrue());
    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Delivery/DownloadDeliveryVendor',
      {
        timeStart: DateFrom,
        timeEnd: DateTo,
        customer: CustomerId === null ? 0 : CustomerId,
        product: CustomerProductParam === null ? 0 : CustomerProductParam,
        branch: BranchId === null ? 0 : BranchId,
        courier: CourierParam === null ? 0 : CourierParam,
        deliveryTime: conditionRB,
        deliveryStatus: `${Object.values(DeliveStat).join(',')}`,
        typeReportVendor: DownloadType,
        physicalDocument: PhysicalDocument || false,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);

          // let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionToogle.changeLoadingFalse());
  } catch (error) {
    if (error.response.data.Message === 'Network Error') {
      message.error({
        content: 'Download Gagal',
        key,
        duration: 3,
      });
      yield put(ActionToogle.changeLoadingFalse());
    } else if (error.response.data.Message === 'No delivery found!') {
      message.error({
        content: 'Data Pengiriman tidak tersedia',
        key,
        duration: 3,
      });
      yield put(ActionToogle.changeLoadingFalse());
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* SM_downloadDeliveryData() {
  yield takeLatest('DOWNLOAD_DELIVERY_DATA', S_downloadDeliveryData);
}
