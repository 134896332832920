import * as ActionCourierManifest from '../../../../core/action/Manifest/CourierManifest/ActionCourierManifest';
import * as toggle from '../../../../core/action/toggle';

import React, { Component } from 'react'
import {bindActionCreators, compose} from 'redux';

import ButtonDownloadComponent from '../../../../components/Content/Manifest/CourierManifest/ButtonDownloadComponent';
import {connect} from 'react-redux';
import moment from 'moment';

interface IProps
{
    deliveryDate: any;
    id: any;
    idBranch: any;
    idCourier: any;
    BranchId: any;
    actionToggle:any
    actionCourierManifest:any
}

interface IState
{

}

class ButtonDownloadContainer extends Component <IProps, IState> {
    constructor(props:any)
    {
        super (props)
        this.state=
        {

        }
        
        this.handleChangeAndDownload = this.handleChangeAndDownload.bind(this)
    }
    async handleChangeAndDownload()
    {
        const data = {
            itemDeliveryTime: moment(this.props.deliveryDate).format('YYYYMMDD'),
            idBranch: this.props.idBranch,
            idCourier: this.props.BranchId !== null ? this.props.idCourier : 0,
          };
        await this.props.actionCourierManifest.changeListCourierManifestForm(data);
        this.props.actionCourierManifest.downloadCourierManifestForm();
    }
    render() {
        return (
            <React.Fragment>
                <ButtonDownloadComponent
                handleChangeAndDownload={this.handleChangeAndDownload}
                />
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state:any)=> ({
    TypeToggle:state.Toggle.jenisToggle,
    BranchId: state.Manifest.CourierManifestForm.BranchId,
})

const mapDispatchToProps = (dispatch:any) => ({
    actionToggle : bindActionCreators(toggle, dispatch),
    actionCourierManifest: bindActionCreators(ActionCourierManifest, dispatch),
})

const withConnect = connect (mapStateToProps, mapDispatchToProps)

export default compose(
    withConnect
)(ButtonDownloadContainer)