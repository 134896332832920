import React from 'react';
import * as ActionUploadCsvHoliday from '../../../core/action/Holiday/ActionUploadCsvHoliday';
import { bindActionCreators, compose } from 'redux';
import FormCsvHolidayComponent from '../Component/FormCsvHolidayComponent';
import { connect } from 'react-redux';

interface IProps {
  actionUploadCsvHoliday: any;
  fileNameCsv?: any;
  fileCsv?: any;
  handleSetNameCsv?: any;
  handleSetFileCsv?: any;
  submitHolidayCsv?: any;
  isLoadingUploadCsv?: any;
}

const FormCsvHolidayContainer = (props: IProps) => {
  const handleAddUploadCsvHoliday = () => {
    // props.actionUploadCsvHoliday.addCsvHoliday();
    const data = {
      fileString: props.fileCsv,
    };
    props.submitHolidayCsv(data);
  };
  const handleDownloadTamplate = () => {
    props.actionUploadCsvHoliday.getTemplateCsvHoliday();
  };
  const getBase64 = (file: any) => {
    return new Promise(resolve => {
      let baseURL;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const handleConvertCsvHoliday = (event: any) => {
    props.actionUploadCsvHoliday.setFileNameCsv(event.target.value);
    props.handleSetNameCsv(event.target.value);
    const file = event.target.files[0];
    getBase64(file)
      .then((result: any) => {
        file['base64'] = result.toString().split(',')[1];
        props.actionUploadCsvHoliday.setCsvHoliday(result.split(',')[1]);
        props.handleSetFileCsv(result.split(',')[1]);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div>
      <FormCsvHolidayComponent
        fileName={props.fileNameCsv}
        ValidasiButtonAdd={props.fileCsv === null ? true : false}
        handleConvertCsvHoliday={handleConvertCsvHoliday}
        handleDownloadTamplate={handleDownloadTamplate}
        handleAddUploadCsvHoliday={handleAddUploadCsvHoliday}
      />
    </div>
  );
};

const mapStateProps = (state: any) => ({
  fileName: state.Holiday.FormUploadHoliday.FileNameCsv,
  fileCsv: state.Holiday.FormUploadHoliday.CsvHoliday,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionUploadCsvHoliday: bindActionCreators(ActionUploadCsvHoliday, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(FormCsvHolidayContainer);
