import * as ActionPagination from 'core/action/Pagination/ActionPagination';
import * as ActionTableBranch from 'core/action/Branch/ActionTableBranch';
import * as ActionToggle from 'core/action/toggle/index';

import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import BranchComponent from 'module/Branch/Component/BranchComponent';
import { connect } from 'react-redux';
import history from 'core/utils/History';

interface IProps {
  data: any;
  actiontablebranch: any;
  actionPagination: any;
  actionToggle: any;
  isLoading: any;
  PagePagination: any;
  TotalPagePagination: any;
  ValueSearch: any;
}
function BranchContainer({
  data,
  actiontablebranch,
  actionPagination,
  actionToggle,
  isLoading,
  PagePagination,
  TotalPagePagination,
  ValueSearch,
}: IProps) {
  const [sRT, setsRT] = useState(null);
  const [dataSource, setdataSource] = useState(data);
  const [nameSearch, setnameSearch] = useState(false);
  const [page, setpage] = useState(0);

  useEffect(() => {
    actiontablebranch.GetTableBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(sRT, setdataSource, setnameSearch);

  function handlePage(event: any) {
    actionPagination.setPagePagination(event);
    setpage(event);
  }
  function handleBranchDetail(event: any) {
    history.push('/branch/detail');
  }
  function handleSearch(event: any) {
    if (event.target.value.length === 0) {
      actionToggle.changeValueSearch(null);
      actiontablebranch.GetTableBranch();
    } else {
      actionToggle.changeValueSearch(event.target.value.replace(/^\s/, ''));
    }
  }
  function handleSubmitSearch() {
    if (ValueSearch === null || ValueSearch === '') {
    } else {
      actionPagination.setPagePagination(0);
      actiontablebranch.SubmitSearchBranch();
    }
  }
  function handleResetSearch(clearFilters: any) {
    clearFilters();
    setsRT(null);
  }
  function handleModal() {
    actionToggle.changeModal();
    actionToggle.changeJenisToggle('AddBranch');
  }
  return (
    <div>
      <BranchComponent
        data={data}
        nameSearch={nameSearch}
        dataSource={dataSource}
        isLoading={isLoading}
        PagePagination={PagePagination}
        TotalPagePagination={TotalPagePagination}
        page={page}
        ValueSearch={ValueSearch}
        handlePage={handlePage}
        handleSearch={handleSearch}
        handleModal={handleModal}
        handleResetSearch={handleResetSearch}
        handleSubmitSearch={handleSubmitSearch}
        handleBranchDetail={handleBranchDetail}
      />
    </div>
  );
}
const mapStateProps = (state: any) => ({
  data: state.Table.TableBranch,
  isLoading: state.Toggle.isLoading,
  ValueSearch: state.Toggle.ValueSearch,
  PagePagination: state.Pagination.PagePagination,
  TotalPagePagination: state.Pagination.TotalPagePagination,
});
const mapDispatchToProps = (dispatch: any) => ({
  actiontablebranch: bindActionCreators(ActionTableBranch, dispatch),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionPagination: bindActionCreators(ActionPagination, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(BranchContainer);
