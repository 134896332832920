import { Button, Tooltip } from 'antd';
import React from 'react';

interface IProps {
  handleSubmit?: any;
  teksButton?: any;
  style?: any;
  size?: any;
  shape?: any;
  className?: any;
  type?: any;
  disabled?: any;
  icon?: any;
  htmlType?: any;
  tooltip?: any;
  ghost?: boolean;
}
export default function ButtonMaterial({
  disabled,
  handleSubmit,
  className,
  type,
  shape,
  size,
  icon,
  style,
  teksButton,
  htmlType,
  tooltip,
  ghost = false,
}: IProps) {
  const RenderEmpty = ({
    title,
    children,
  }: {
    title?: string;
    children: React.ReactNode;
  }) => <>{children}</>;

  const IsTooltip = tooltip ? Tooltip : RenderEmpty;

  return (
    <IsTooltip title={tooltip ? tooltip : ''}>
      <Button
        disabled={disabled}
        onClick={handleSubmit}
        className={className}
        type={type}
        shape={shape}
        size={size}
        icon={icon}
        style={style}
        htmlType={htmlType}
        ghost={ghost}
      >
        {teksButton}
      </Button>
    </IsTooltip>
  );
}
