import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as formAdmin from '../../../action/ManageAccount/AdminBranch/ActionFormDetailAdminBranch';
import * as table from '../../../action/ManageAccount/AdminBranch/ActionTableAdminBranch';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_submitAddAdminBranch(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const AdminName = state.AdminBranch.FormAdmin.AdminName;
  const AdminLastName = state.AdminBranch.FormAdmin.AdminLastName;
  const AdminEmail = state.AdminBranch.FormAdmin.AdminEmail;
  const AdminPhoneNumber = state.AdminBranch.FormAdmin.AdminPhoneNumber;
  const AdminDob = state.AdminBranch.FormAdmin.AdminDob;
  const AdminAddress = state.AdminBranch.FormAdmin.AdminAddress;
  const AdminPhoto = state.AdminBranch.FormAdmin.AdminPhoto;
  const AdminCode = state.AdminBranch.FormAdmin.Code;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Profile/Branch',
      {
        firstName: AdminName,
        lastName: AdminLastName,
        photo: AdminPhoto,
        email: AdminEmail,
        phone: AdminPhoneNumber,
        dob: AdminDob,
        branchId: 0,
        code: AdminCode,
        address: AdminAddress,
        username: AdminCode,
        password: AdminCode,
      },
      { headers: config }
    );
    yield put(formAdmin.changeClearDataAdminForm());
    yield put(toggle.clearJenisToggle());
    yield put(table.clearAdminTable());
    yield put(toggle.changeLoadingFalse());
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil di simpan</b>
        </p>
      ),

      onOk() {},
    });
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}

export function* SM_addAdminPosko() {
  yield takeLatest('SUBMIT_ADD_ADMIN_BRANCH', S_submitAddAdminBranch);
}
