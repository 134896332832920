import * as ActionBastReport from 'core/action/Report/BastReport/ActionBastReport';
import * as ActionCourierBonus from 'core/action/Report/CourierBonusReport/ActionCourierBonusReport';
import * as ActionCourierReport from 'core/action/Report/CourierReport/ActionCourierReportForm';
import * as ActionCustomerReportForm from 'core/action/Report/CustomerReport/ActionCustomerReportForm';
import * as ActionDeliveryReport from 'core/action/Report/DeliveryReport/ActionDeliveryReport';
import * as ActionInvoice from 'core/action/Report/InvoiceReport/ActionSetFormInvoice';
import * as ActionListCustomer from 'core/action/Customer/ActionListCustomer';
import * as ActionListCustomerProduct from 'core/action/CustomerProduct/ActionListCustomerProduct';
import * as ActionMisReportForm from 'core/action/Report/MisReport/ActionMisReportForm';
import * as ActionValidateReport from 'core/action/Report/ValidateReport/ActionValidateForm';
import * as ActionWaybillDigital from 'core/action/Report/DigitalWaybillReport/ActionDigitalWaybill';
import * as LastAccess from 'core/action/LastAccess/index';

import { bindActionCreators, compose } from 'redux';

import IndexReportComponent from 'module/Report/Component/IndexReportComponent';
import React from 'react';
import { connect } from 'react-redux';

interface IProps {
  actionInvoice: any;
  actionCourierReport: any;
  actionListCustomer: any;
  actionListCustomerProduct: any;
  actionCourierBonus: any;
  roleAccount: any;
  actionLastAccess: any;
  actionValidateReport: any;
  actionMisReportForm: any;
  actionCustomerReportForm: any;
  actionDeliveryReport: any;
  actionWaybillDigital: any;
  actionBastReport: any;
  Fiture: any;
  sideMenus: any;
}

function IndexReportContainer({
  actionInvoice,
  actionCourierReport,
  actionListCustomer,
  actionListCustomerProduct,
  actionCourierBonus,
  roleAccount,
  actionLastAccess,
  actionValidateReport,
  actionMisReportForm,
  actionCustomerReportForm,
  actionDeliveryReport,
  actionWaybillDigital,
  actionBastReport,
  Fiture,
  sideMenus,
}: IProps) {
  function handleChangeTabsReport(event: any) {
    if (roleAccount === 'Branch') {
      actionCourierReport.clearCourierBranchRForm();
      actionCourierBonus.clearCourierBonusBranchForm();
    } else {
      actionInvoice.clearAllFormInvoice();
      actionCourierReport.clearCourierRForm();
      actionListCustomer.clearListCustomer();
      actionListCustomerProduct.clearListCustomerProduct();
      actionCourierBonus.clearCourierBonusForm();
      actionValidateReport.clearValidateReportForm();
      actionCustomerReportForm.clearCustomerReportForm();
      actionMisReportForm.clearMisReportForm();
      actionDeliveryReport.clearAllFormDeliveryReport();
      actionWaybillDigital.clearForm();
      actionWaybillDigital.clearFormByUpload();
      actionBastReport.clearForm();
    }
    function handleChangeLastAccess(action) {
      actionLastAccess.changeLastAccessFiture(action);
    }
    switch (event) {
      case '1':
        handleChangeLastAccess('courierReport');
        break;
      case '2':
        handleChangeLastAccess('deliveryReport');
        break;
      case '3':
        handleChangeLastAccess('invoiceReport');
        break;
      case '4':
        handleChangeLastAccess('customerReport');
        break;
      case '5':
        handleChangeLastAccess('misReport');
        break;
      case '6':
        handleChangeLastAccess('courierBonusReport');
        break;
      case '7':
        handleChangeLastAccess('validateReport');
        break;
      case '8':
        handleChangeLastAccess('complaintReport');
        break;
      case '9':
        handleChangeLastAccess('waybillDigital');
        break;
      case '10':
        handleChangeLastAccess('bastSms');
        break;
      default:
        handleChangeLastAccess('courierReport');
    }
  }

  return (
    <IndexReportComponent
      roleAccount={roleAccount}
      lastAccessFiture={Fiture}
      handleChangeTabsReport={handleChangeTabsReport}
      sideMenus={sideMenus}
    />
  );
}

const mapStateToProps = (state: any) => ({
  roleAccount: state.Auth.Role,
  Fiture: state.LastAccess.Fiture,
  sideMenus: state.Auth.SideMenus,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionInvoice: bindActionCreators(ActionInvoice, dispatch),
  actionCourierReport: bindActionCreators(ActionCourierReport, dispatch),
  actionListCustomer: bindActionCreators(ActionListCustomer, dispatch),
  actionValidateReport: bindActionCreators(ActionValidateReport, dispatch),
  actionMisReportForm: bindActionCreators(ActionMisReportForm, dispatch),
  actionCustomerReportForm: bindActionCreators(
    ActionCustomerReportForm,
    dispatch
  ),
  actionListCustomerProduct: bindActionCreators(
    ActionListCustomerProduct,
    dispatch
  ),
  actionCourierBonus: bindActionCreators(ActionCourierBonus, dispatch),
  actionDeliveryReport: bindActionCreators(ActionDeliveryReport, dispatch),
  actionWaybillDigital: bindActionCreators(ActionWaybillDigital, dispatch),
  actionBastReport: bindActionCreators(ActionBastReport, dispatch),
  actionLastAccess: bindActionCreators(LastAccess, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(IndexReportContainer);
