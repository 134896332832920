import * as ActionCustomerAccount from '../../../../core/action/ManageAccount/Customer/ActionCustomerAccount';
import * as ActionPagination from '../../../../core/action/Pagination/ActionPagination';
import * as ActionToggle from '../../../../core/action/toggle/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import TableCustomerAccountComponent from '../../../../components/Content/ManageAccount/Customer/TableCustomerAccountComponent';
import { connect } from 'react-redux';

interface IProps {
  data: any;
  isLoading: any;
  actionCustomerAccount: any;
  actionToggle: any;
  actionPagination: any;
  PagePagination: any;
  TotalPagePagination: any;
  ValueSearch: any;
}

interface IState {}

class TableCustomerAccountContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {};
    this.handlePutSearch = this.handlePutSearch.bind(this);
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handlePage = this.handlePage.bind(this);
  }

  handlePage(event: any) {
    this.props.actionPagination.setPagePagination(event);
    this.props.actionCustomerAccount.getCustomerAccount();
  }
  handlePutSearch(event: any) {
    this.props.actionToggle.changeValueSearch(
      event.target.value.replace(/^\s/, '')
    );
  }
  handleSubmitSearch() {
    this.props.actionToggle.submitSearch();
  }
  handleModal() {
    this.props.actionCustomerAccount.generateUsernameCustomerAccount();
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle('AddCustomerAccount');
  }

  render() {
    return (
      <React.Fragment>
        <TableCustomerAccountComponent
          data={this.props.data}
          PagePagination={this.props.PagePagination}
          TotalPagePagination={this.props.TotalPagePagination}
          isLoading={this.props.isLoading}
          ValueSearch={this.props.ValueSearch}
          handlePage={this.handlePage}
          handlePutSearch={this.handlePutSearch}
          handleSubmitSearch={this.handleSubmitSearch}
          handleModal={this.handleModal}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  ValueSearch: state.Toggle.ValueSearch,
  data: state.Customer.CustomerAccount.CustomerListAccount,
  PagePagination: state.Pagination.PagePagination,
  TotalPagePagination: state.Pagination.TotalPagePagination,
  isLoading: state.Toggle.isLoading,
  jenisToggle: state.Toggle.jenisToggle,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionCustomerAccount: bindActionCreators(ActionCustomerAccount, dispatch),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionPagination: bindActionCreators(ActionPagination, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TableCustomerAccountContainer);
