import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';

interface IProps {
  handleCustomerDetail: any;
  handleCustomerEditAccount: any;
  handleResetPassword: any;
  typeButton: any;
  route: any;
}
export default class ButtonCustomerComponent extends Component<IProps> {
  render() {
    return (
      <React.Fragment>
        {this.props.route === '/manageAccount' ? (
          <ButtonMaterial
            disabled={''}
            handleSubmit={this.props.handleCustomerDetail}
            teksButton={''}
            size={''}
            shape={''}
            className={'btnDetail'}
            type={''}
            icon={'eye'}
            style={{}}
          />
        ) : this.props.typeButton === 'ResetPasswordCustomer' ? (
          <ButtonMaterial
            disabled={''}
            handleSubmit={this.props.handleResetPassword}
            teksButton={''}
            size={''}
            shape={''}
            className={'btnResetPassword'}
            type={''}
            icon={'lock'}
            style={{}}
          />
        ) : (
          <ButtonMaterial
            disabled={''}
            handleSubmit={this.props.handleCustomerEditAccount}
            teksButton={''}
            size={''}
            shape={''}
            className={'btnEdit'}
            type={''}
            icon={'edit'}
            style={{}}
          />
        )}
      </React.Fragment>
    );
  }
}
