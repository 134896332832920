import * as ActioanAllBranch from 'core/action/Branch/ActionAllBranch';
import * as ActionCourierMaps from 'core/action/Maps/CourierTracing/ActionCourierTracking';
import * as ActionCourierTable from 'core/action/ManageAccount/Courier/ActionTableCourier';
import * as ActionDetailBranch from 'core/action/Branch/ActionDetailBranch';
import * as ActionDetailCourier from 'core/action/Maps/CourierTracing/ActionCourierTracking';
import * as ActionTogleMaps from 'core/action/Maps/ActionToggleMaps';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import MarkerComponent from 'module/Map/Component/MapComponent';
import { connect } from 'react-redux';

interface IProps {
  actionDetailCourier: any;
  actionCourierTable: any;
  actionTogleMaps: any;
  actionDetailBranch: any;
  actionCourierMaps: any;
  actioanAllBranch: any;
  detailCourier: any;
  selectCourier: any;
  NameBranch: any;
  listCourier: any;
  listBranch: any;
  typeRealTime: any;
  courierCode: any;
  courierPhone: any;
  courierBranch: any;
  courierLastSeen: any;
  deliverySuccess: any;
  deliveryReturn: any;
  deliveryPending: any;
  deliveryTotal: any;
  isLoading: any;
  toggleMaps: any;
  router: any;
  idBranch: any;
  roleAccount: any;
  IdBranch: any;
  errorConnectionMaps: any;
  getLatitudeBranch: any;
  getLongitudeBranch: any;
}
// interface IState {
//   collapssFormTracking: any;
//   stateIdCourier: any;
// }
function MarkerContainer({
  actionDetailBranch,
  actionCourierMaps,
  actionTogleMaps,
  actionDetailCourier,
  actioanAllBranch,
  actionCourierTable,
  typeRealTime,
  idBranch,
  roleAccount,
  IdBranch,
  isLoading,
  toggleMaps,
  router,
  errorConnectionMaps,
  courierCode,
  courierPhone,
  courierBranch,
  courierLastSeen,
  deliverySuccess,
  deliveryReturn,
  deliveryPending,
  deliveryTotal,
  detailCourier,
  selectCourier,
  NameBranch,
  getLatitudeBranch,
  getLongitudeBranch,
  listCourier,
  listBranch,
}: IProps) {
  // intervalID: any;
  // constructor(props: any) {
  //   super(props);
  //   this.state = {
  //     collapssFormTracking: true,
  //     stateIdCourier: null,
  //   };
  //   this.handleCollapssFormTracking =
  //     this.handleCollapssFormTracking.bind(this);
  //   this.handleGetCourier = this.handleGetCourier.bind(this);
  //   this.handleGetBranch = this.handleGetBranch.bind(this);
  //   this.handlePutSelectCourier = this.handlePutSelectCourier.bind(this);
  //   this.handleSearchLocationCourier =
  //     this.handleSearchLocationCourier.bind(this);
  //   this.handlePutAllBranch = this.handlePutAllBranch.bind(this);
  // }

  const [stateIdCourier, setStateIdCourier] = useState(null);
  const [collapssFormTracking, setCollapssFormTracking] = useState(true);

  function handlePutAllBranch(event: any) {
    actionDetailBranch.setIdBranch(event);
    actionDetailBranch.changeDetailBranch();
    actionCourierMaps.clearSelectCourier();
    actionCourierMaps.clearDetailCourier();
    actionCourierMaps.clearCourierDeliveryData();
    actionTogleMaps.setTypeRealTime('getAllCourier');
  }
  function handleSearchLocationCourier() {
    if (stateIdCourier === 'empty') {
      actionDetailCourier.clearSelectCourier();
      actionTogleMaps.setTypeRealTime('getAllCourier');
      actionDetailCourier.getAllCourierLocation();
      setStateIdCourier(null);
      actionDetailCourier.clearCourierDeliveryData();
    } else {
      actionTogleMaps.setToggleMaps('Maps');
      actionTogleMaps.setTypeRealTime('getCourier');
      actionDetailCourier.selectCourier(stateIdCourier);
      actionDetailCourier.getCourierPerson();
    }
  }
  function handlePutSelectCourier(event: any) {
    setStateIdCourier(event);
  }
  function handleGetBranch() {
    actioanAllBranch.getAllBranch();
  }
  function handleGetCourier() {
    actionCourierTable.getCourierTableBranch();
  }
  function handleCollapssFormTracking() {
    actionTogleMaps.setTypeRealTime('getAllCourier');
    setCollapssFormTracking(!collapssFormTracking);
    setStateIdCourier(null);
    actionDetailCourier.clearSelectCourier();
    actionDetailCourier.clearCourierDeliveryData();
    actionDetailCourier.getAllCourierLocation();
  }
  // async componentDidMount() {
  //   if (roleAccount === 'Branch') {
  //     await actioanAllBranch.getAllBranch();
  //     await actionDetailBranch.setIdBranch(IdBranch);
  //     await actionDetailBranch.changeDetailBranch();
  //   }
  //   this.intervalID = setInterval(this.getData.bind(this), 5000);
  // }
  // componentWillUnmount() {
  //   clearInterval(this.intervalID);
  //   actionTogleMaps.clearToggleMaps();
  // }
  // const getData = () => {
  //   if (typeRealTime === 'getAllCourier' || typeRealTime === null) {
  //     actionDetailCourier.getAllCourierLocation();
  //   } else if (typeRealTime === 'getCourier') {
  //     actionDetailCourier.getCourierPerson();
  //   }
  //   if (idBranch === null) {
  //     // this.setState({
  //     //   stateIdCourier: null,
  //     // });
  //   }
  // };
  return (
    <div>
      <MarkerComponent
        roleAccount={roleAccount}
        IdBranch={IdBranch}
        isLoading={isLoading}
        idBranch={idBranch}
        toggleMaps={toggleMaps}
        router={router}
        errorConnectionMaps={errorConnectionMaps}
        courierCode={courierCode}
        courierPhone={courierPhone}
        courierBranch={courierBranch}
        courierLastSeen={courierLastSeen}
        deliverySuccess={deliverySuccess}
        deliveryReturn={deliveryReturn}
        deliveryPending={deliveryPending}
        deliveryTotal={deliveryTotal}
        detailCourier={detailCourier}
        stateIdCourier={stateIdCourier}
        selectCourier={selectCourier}
        NameBranch={NameBranch}
        typeRealTime={typeRealTime}
        getLatitudeBranch={getLatitudeBranch}
        getLongitudeBranch={getLongitudeBranch}
        listCourier={listCourier}
        listBranch={listBranch}
        collapssFormTracking={collapssFormTracking}
        handleCollapssFormTracking={handleCollapssFormTracking}
        handleGetCourier={handleGetCourier}
        handleGetBranch={handleGetBranch}
        handlePutSelectCourier={handlePutSelectCourier}
        handleSearchLocationCourier={handleSearchLocationCourier}
        handlePutAllBranch={handlePutAllBranch}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  roleAccount: state.Auth.Role,
  IdBranch: state.Auth.BranchAuth,
  isLoading: state.Toggle.isLoading,
  toggleMaps: state.Maps.ToggleMaps,
  router: state.router.location.pathname,
  idBranch: state.Branch.PropertiesBranch.idBranch,
  errorConnectionMaps: state.Maps.ErrorConnectionMaps,
  detailCourier: state.Maps.CourierTracking.DetailCourier,
  selectCourier: state.Maps.CourierTracking.SelectCourier,
  typeRealTime: state.Maps.TypeRealTime,
  NameBranch: state.Branch.DetailBranch.NameBranch,
  listCourier: state.Table.TableCourier,
  listBranch: state.Branch.AllBranch,
  courierCode: state.Maps.CourierTracking.CourierDeliveryData.CourierCode,
  courierPhone: state.Maps.CourierTracking.CourierDeliveryData.CourierPhone,
  courierBranch: state.Maps.CourierTracking.CourierDeliveryData.CourierBranch,
  courierLastSeen:
    state.Maps.CourierTracking.CourierDeliveryData.CourierLastSeen,
  deliverySuccess:
    state.Maps.CourierTracking.CourierDeliveryData.DeliverySuccess,
  deliveryReturn: state.Maps.CourierTracking.CourierDeliveryData.DeliveryReturn,
  deliveryPending:
    state.Maps.CourierTracking.CourierDeliveryData.DeliveryPending,
  deliveryTotal: state.Maps.CourierTracking.CourierDeliveryData.DeliveryTotal,
  getLatitudeBranch: state.Branch.DetailBranch.LatitudeBranch,
  getLongitudeBranch: state.Branch.DetailBranch.LongitudeBranch,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionDetailCourier: bindActionCreators(ActionDetailCourier, dispatch),
  actionCourierTable: bindActionCreators(ActionCourierTable, dispatch),
  actionTogleMaps: bindActionCreators(ActionTogleMaps, dispatch),
  actionDetailBranch: bindActionCreators(ActionDetailBranch, dispatch),
  actionCourierMaps: bindActionCreators(ActionCourierMaps, dispatch),
  actioanAllBranch: bindActionCreators(ActioanAllBranch, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MarkerContainer);
