import { Col, Row, Table } from 'antd';
import { Field, reduxForm } from 'redux-form';

import CButton from '../../../apps/assets/components/CButton';
import CCard from '../../../apps/assets/components/CCard';
import CSpin from '../../../apps/assets/components/CSpin';
import { InputDatePicker } from '../../../apps/assets/components/CInputDate';
import { InputSelect } from '../../../apps/assets/components/CInput';
import React from 'react';
import moment from 'moment';
import validate from '../Validation/LogActivityValidation';

function LogActivityComponent(props) {
  const {
    pagePagination,
    totalPagination,
    isLoading,
    handlePage,
    handleGetLogActivity,
    handleGetUser,
    handleSearchLogActivity,
    handleReset,
    handleDownload,
    logActivityDataList,
    columns,
    logActivityList,
    userList,
    errorFormValue,
    formValue,
  } = props;
  const dataValue = formValue ? formValue : '';
  const startDate = dataValue.startDateLogActivity
    ? dataValue.startDateLogActivity
    : '';
  const lengthLogActivity = dataValue.logType ? dataValue.logType.length : 0;
  const lengthUserActivity = dataValue.user ? dataValue.user.length : 0;
  const disableDate = (d: any) =>
    !d ||
    d.isBefore(moment(startDate).add(0, 'day').format('YYYY-MM-DD')) ||
    d.isAfter(moment(startDate).add(31, 'day').format('YYYY-MM-DD'));
  const dateValidation = (d: any) => !d || d.isAfter(moment(new Date()));
  const validateBtnSearch = dataValue.finishDateLogActivity ? false : true;
  const validateBtnReset = () => {
    if (
      lengthLogActivity === 0 &&
      lengthUserActivity === 0 &&
      Object.keys(errorFormValue).length === 2
    ) {
      return true;
    } else {
      return false;
    }
  };

  const btnDownloadAndSearch = [
    {
      type: 'primary',
      className: 'btnDownloadDefault',
      onClick: handleDownload,
      isLoading: isLoading,
      content: 'Download',
      disabled: validateBtnSearch,
      id: 'btnDownload',
    },
    {
      type: 'primary',
      className: 'btnDefaultSubmit',
      onClick: handleSearchLogActivity,
      isLoading: isLoading,
      content: 'Cari',
      disabled: validateBtnSearch,
      id: 'btnSearchLogActivity',
    },
  ];
  const btnReset = [
    {
      type: 'primary',
      className: 'btnResetLogActivity',
      onClick: handleReset,
      isLoading: isLoading,
      content: 'Reset',
      disabled: validateBtnReset(),
      id: 'btnResetLogActivity',
    },
  ];

  const contentCard = () => {
    return (
      <React.Fragment>
        <Row>
          <Col span={12} className="spacingRight">
            <Field
              name="startDateLogActivity"
              component={InputDatePicker}
              disabledDate={dateValidation}
              idComponent="inputStartDate"
              label="Dari Tanggal"
              typeDate="datePicker"
              placeholder={'Masukan tanggal'}
            />
            <Field
              name="logType"
              component={InputSelect}
              mode="multiple"
              additionalHandleOnFocusFunc={handleGetLogActivity}
              idComponent="inputLogType"
              placeholder="Semua"
              data={logActivityList}
              label={'Log Activity'}
            />
            <CButton buttonData={btnReset} />
          </Col>
          <Col span={12}>
            <Field
              name="finishDateLogActivity"
              component={InputDatePicker}
              idComponent="inputFinishDate"
              label="Sampai Tanggal"
              typeDate="datePicker"
              disabledDate={disableDate}
              placeholder={'Masukan tanggal'}
              disabled={startDate ? false : true}
            />
            <Field
              name="user"
              component={InputSelect}
              mode="multiple"
              additionalHandleOnFocusFunc={handleGetUser}
              idComponent="inputUserType"
              data={userList}
              placeholder="Semua"
              label={'User'}
            />
            <div className="floatRight">
              <CButton buttonData={btnDownloadAndSearch} />
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <CSpin
        spinning={isLoading}
        size={'large'}
        content={
          <div className="defaultSpace">
            <CCard
              cardClassName={'defaultCard'}
              cardTitle={<span className="lightBlueColor">LOG ACTIVITY</span>}
              cardContent={<React.Fragment>{contentCard()}</React.Fragment>}
            />
            <Table
              pagination={{
                onChange: page => {
                  handlePage(page);
                },
                pageSize: 10,
                total: totalPagination,
                current: pagePagination === 0 ? 1 : pagePagination,
              }}
              bordered={false}
              dataSource={logActivityDataList}
              columns={columns}
              className="spacingTop"
            />
          </div>
        }
      />
    </React.Fragment>
  );
}

const LogActivityFormComponent = reduxForm({
  form: 'logActivityForm',
  shouldError: () => {
    return true;
  },
  validate,
})(LogActivityComponent);
export default LogActivityFormComponent;
