import { Col, DatePicker, Form, Row, Select, Checkbox } from 'antd';
import ButtonMaterial from 'materials/ButtonMaterial';
import CardMaterial from 'materials/CardMaterial';
import FormInputTextMaterial from 'materials/FormInputTextMaterial';
import HistoryListResendContainer from '../Container/HistoryListResendContainer';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';
import ModalMaterial from 'materials/ModalMaterial';
import CountResult from 'components/CountResult';
import InputFile from 'components/InputFile';

const { Option } = Select;

interface IProps {
  loadingSelectBranch: any;
  isLoading: any;
  roleAccount: any;
  getResendBranch: any;
  getResendCourier: any;
  getResendDeliveryDate: any;
  getResendWaybill: any;
  getResendVendor: any;
  listAllBranch: any;
  listCourier: any;
  validateResendBranch: any;
  validateResendCourier: any;
  validateResendDeliveryDate: any;
  validateResendWaybill: any;
  helpValidateResendBranch: any;
  helpValidateResendCourier: any;
  helpValidateResendDeliveryDate: any;
  helpValidateResendWaybill: any;
  handleChangeResendBranch: any;
  handleChangeResendCourier: any;
  handleChangeResendDateDelivery: any;
  handleChangeResendWaybill: any;
  handleChangeResendVendor: any;
  handleBlurResendBranch: any;
  handleBlurResendCourier: any;
  handleValidateResendDateDelivery: any;
  handleBlurResendWaybill: any;
  handleGetAllBranch: any;
  handleGetCourier: any;
  handleResetResendBranch: any;
  handleResendSubmit: any;
  loadingSelectCourier: any;
  valueResendDate: any;
  isChangeCourier: any;
  handleCheckBoxCourier: any;
  handleScan: any;
  isScan: any;
  getIsSuccess: any;
  handleSetStatusSuccess: any;
  handleChangeFile: any;
  validateResendFile: any;
  mutateDownloadFile: any;
  isLoadingDownload: any;
  getExcelName: any;
  getResendCounting: any;
  isLoadingDownloadResult: any;
  handleDownloadResultCsv: any;
}
export default function ResendComponent({
  loadingSelectBranch,
  isLoading,
  roleAccount,
  getResendBranch,
  getResendCourier,
  getResendDeliveryDate,
  getResendWaybill,
  listAllBranch,
  listCourier,
  validateResendBranch,
  validateResendCourier,
  validateResendDeliveryDate,
  validateResendWaybill,
  helpValidateResendBranch,
  helpValidateResendCourier,
  helpValidateResendDeliveryDate,
  helpValidateResendWaybill,
  handleChangeResendBranch,
  handleChangeResendCourier,
  handleChangeResendDateDelivery,
  handleChangeResendWaybill,
  handleBlurResendBranch,
  handleBlurResendCourier,
  handleValidateResendDateDelivery,
  handleBlurResendWaybill,
  handleGetAllBranch,
  handleGetCourier,
  handleResetResendBranch,
  handleResendSubmit,
  loadingSelectCourier,
  valueResendDate,
  isChangeCourier,
  handleCheckBoxCourier,
  handleScan,
  isScan,
  getIsSuccess,
  handleSetStatusSuccess,
  handleChangeFile,
  validateResendFile,
  mutateDownloadFile,
  isLoadingDownload,
  getExcelName,
  getResendCounting,
  isLoadingDownloadResult,
  handleDownloadResultCsv,
}: IProps) {
  const disableCourier = getResendBranch === null ? true : false;
  const Branch =
    getResendBranch === null ? (
      <span style={{ color: '#969696' }}>Pilih posko</span>
    ) : (
      getResendBranch
    );
  const Courier =
    getResendCourier === null ? (
      <span style={{ color: '#969696' }}>Pilih kurir</span>
    ) : (
      getResendCourier
    );
  const disableButtonSave =
    (isChangeCourier &&
      (validateResendBranch === 'error' ||
        getResendBranch === null ||
        validateResendCourier === 'error' ||
        getResendCourier === null)) ||
    validateResendDeliveryDate === 'error' ||
    getResendDeliveryDate === null ||
    getResendDeliveryDate === 'Invalid date' ||
    ((validateResendWaybill === 'error' || !getResendWaybill) &&
      (validateResendFile === 'error' || !getExcelName))
      ? true
      : false;

  return (
    <div
      style={{
        paddingLeft: '35px',
        paddingRight: '35px',
        paddingTop: '35px',
      }}
    >
      <CardMaterial
        extra={''}
        style={{ borderRadius: '10px' }}
        title={
          <span style={{ color: '#11BEFF' }}>
            <b>ATTEMPT</b>
          </span>
        }
        content={
          <SpinMaterial
            spinning={isLoading}
            style={{}}
            size={'large'}
            content={
              <div>
                <Row style={{ margin: 0 }}>
                  <Col span={12} style={{ paddingRight: '20px' }}>
                    <div>
                      <span>
                        <b>Posko</b>
                      </span>
                      <Form.Item
                        validateStatus={validateResendBranch}
                        help={helpValidateResendBranch}
                      >
                        <Select
                          loading={loadingSelectBranch}
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          disabled={
                            roleAccount === 'Branch' || !isChangeCourier
                              ? true
                              : false
                          }
                          showSearch
                          onSelect={handleResetResendBranch}
                          onChange={handleChangeResendBranch}
                          onBlur={handleBlurResendBranch}
                          value={Branch}
                          onFocus={handleGetAllBranch}
                        >
                          {listAllBranch.map((data: any, index: any) => (
                            <Option key={index} value={data.id}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Kurir</b>
                      </span>
                      <Form.Item
                        validateStatus={validateResendCourier}
                        help={helpValidateResendCourier}
                        style={{ marginBottom: 8 }}
                      >
                        <Select
                          loading={loadingSelectCourier}
                          style={{ width: '100%', borderRadius: '10px' }}
                          // onSelect={handleChangeSource}
                          optionFilterProp="children"
                          showSearch
                          value={Courier}
                          disabled={disableCourier}
                          onChange={handleChangeResendCourier}
                          onBlur={handleBlurResendCourier}
                          onFocus={handleGetCourier}
                        >
                          {listCourier.map((data: any, index: any) => (
                            <Option key={index} value={data.id}>
                              {data.firstName}
                              {`( ${data.code} )`}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <div>
                        <Checkbox
                          style={{
                            paddingLeft: 5,
                            marginBottom: 20,
                          }}
                          checked={isChangeCourier}
                          onChange={handleCheckBoxCourier}
                        />
                        <span className={'checkboxSpanSeparatorAddress'}>
                          Ubah Kurir
                        </span>
                      </div>
                    </div>
                  </Col>

                  <Col span={12} style={{ paddingLeft: '20px' }}>
                    <div>
                      <span>
                        <b>Tanggal Kirim</b>
                      </span>
                      <Form.Item
                        validateStatus={validateResendDeliveryDate}
                        help={helpValidateResendDeliveryDate}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          locale={locale}
                          format={'DD/MM/YYYY'}
                          placeholder={'Masukan tanggal kirim'}
                          onOpenChange={handleValidateResendDateDelivery}
                          value={valueResendDate}
                          onChange={handleChangeResendDateDelivery}
                          disabledDate={(current: any) => {
                            return (
                              moment().add(8, 'days') <= current ||
                              moment().add(-8, 'days') >= current
                            );
                          }}
                        />
                      </Form.Item>
                    </div>
                    {isScan ? (
                      <div>
                        <span>
                          <b>Waybill</b>
                        </span>
                        <div>
                          <FormInputTextMaterial
                            validateType={true}
                            value={getResendWaybill}
                            className={''}
                            disabled={false}
                            addonBefore={''}
                            onPressEnter={''}
                            placeholder={'Masukan waybill'}
                            prefix={''}
                            maxLength={''}
                            style={{
                              borderRadius: '10px',
                            }}
                            styleForm={{ marginBottom: 0 }}
                            size=""
                            type={''}
                            onChange={handleChangeResendWaybill}
                            onBlur={handleBlurResendWaybill}
                            validatestatus={validateResendWaybill}
                            help={helpValidateResendWaybill}
                          />
                        </div>
                      </div>
                    ) : (
                      <InputFile
                        label="Unggah File"
                        required
                        validateFile={validateResendFile}
                        handleChangeFile={handleChangeFile}
                        value={getExcelName?.name}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      />
                    )}
                    <div style={{ margin: '5px 0 10px' }}>
                      <span className="fontItalicaAndUnderline">
                        {/* eslint-disable-next-line */}
                        <a
                          style={{ padding: 0 }}
                          onClick={mutateDownloadFile}
                          className="fontItalicaAndUnderline"
                        >
                          {isLoadingDownload
                            ? 'Loading...'
                            : 'Download sample file'}
                        </a>
                      </span>
                    </div>
                    <Checkbox
                      style={{
                        paddingLeft: 5,
                        marginBottom: 20,
                      }}
                      checked={isScan}
                      onChange={handleScan}
                    />
                    <span className={'checkboxSpanSeparatorAddress'}>Scan</span>
                  </Col>
                </Row>
                <div style={{ textAlign: 'right' }}>
                  <ButtonMaterial
                    disabled={disableButtonSave}
                    handleSubmit={handleResendSubmit}
                    teksButton={'Simpan'}
                    size={''}
                    shape={'round'}
                    className={''}
                    type={'primary'}
                    style={
                      disableButtonSave === true
                        ? { height: '35px' }
                        : {
                            colorFont: '#11BEFF',
                            height: '35px',
                            background: '#11BEFF',
                            borderColor: '#11BEFF',
                          }
                    }
                    icon={''}
                  />
                </div>
              </div>
            }
          />
        }
      />
      <ModalMaterial
        teksTitle={'PROSES ATTEMPT'}
        width="50%"
        visible={getIsSuccess}
        onCancel={handleSetStatusSuccess}
        content={
          <CountResult
            onClickOke={handleSetStatusSuccess}
            success={getResendCounting?.success}
            error={getResendCounting?.error}
            disabledButtonDownload={
              isLoadingDownloadResult || getResendCounting?.error === 0
            }
            onClickDownload={handleDownloadResultCsv}
          />
        }
      />
      <HistoryListResendContainer />
    </div>
  );
}
