import { Action } from 'redux';

export interface IFetchPickupStatusAction extends Action {
  data: object;
}

export function fetchPickupStatusDoc(data: object): IFetchPickupStatusAction {
  return {
    type: 'FETCH_PICKUP_STATUS_DOC',
    data,
  };
}

export function getPickupStatus() {
  return {
    type: 'GET_PICKUP_STATUS_REQUESTED',
  };
}
export function clearPickupStatusDoc() {
  return {
    type: 'CLEAR_PICKUP_STATUS_DOC',
  };
}
