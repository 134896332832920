import React, { Component } from 'react';
import { Row, Col} from 'antd';
import CardMaterial from '../materials/CardMaterial';
import SpinMaterial from "../materials/SpinMaterial";

interface IProps
{
    Deskripsi : any
    isLoading :any
    formText:any
    Modul:any
    ButtonHeader:any
}
export default class DetailComponentMatrial extends Component<IProps> {
    render() {
        return (
            <div>
                <div>
                    <CardMaterial
                        extra={""
                            
                        }
                        style={{}}
                        title={
                            <div>
                                <Row>
                                    <Col span={12}>
                                        <div style={{color:'#11BEFF'}}>
                                            <span style={{textTransform:'uppercase'}}>
                                                <b>DETAIL {this.props.Modul} {this.props.Deskripsi}</b>
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div style={{float:'right'}}>
                                            {this.props.ButtonHeader}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            }
                        content={
                            <div>
                                <SpinMaterial
                                    spinning={this.props.isLoading}
                                    style={{}}
                                    size={"large"}
                                    content={
                        
                                <div>
                                    {this.props.formText}
                                </div>
                                }/>

                            <div >
                        </div>

                    </div>
                        }>
                    </CardMaterial>
                </div>
            </div>
        )
    }
}
