import * as ActionActivationVoip from '../action/Customer/Voip/ActionActivationVoip';
import * as ActionAdminTable from '../action/ManageAccount/AdminBranch/ActionTableAdminBranch';
import * as ActionAssigningCourier from '../action/AssigningCourier/ActionAssigningCourier';
import * as ActionAuth from '../action/Auth';
import * as ActionCallCenter from '../../module/CallCenter/Store/CallCenterAction';
import * as ActionClearLastAccess from '../action/LastAccess/index';
import * as ActionComplaint from '../action/Complaint/ActionFormComplaint';
import * as ActionComplaintNew from '../../module/ComplaintNew/Store/ComplaintAction';
import * as ActionComponent from '../../module/App/Store/ComponentAction';
import * as ActionCourierTable from '../action/ManageAccount/Courier/ActionTableCourier';
import * as ActionCourierTracking from '../action/Maps/CourierTracing/ActionCourierTracking';
import * as ActionCustomCsvCustomer from '../action/Customer/ActionSetCustomCsvCustomer';
import * as ActionCustomerAccount from '../action/ManageAccount/Customer/ActionCustomerAccount';
import * as ActionCustomerConfigReport from '../action/Customer/ActionDetailConfigReport';
import * as ActionCustomerFiture from '../action/Customer/ActionCustomerFiture';
import * as ActionDataActive from '../action/Voip/DataActive/ActionDataActive';
import * as ActionDataDownload from '../action/Delivery/Document/ActionDataDownload';
import * as ActionDeliveryPrintWaybill from '../action/Delivery/Document/ActionFormPrintWaybill';
import * as ActionDeliveryPriority from '../action/DeliveryPriority/ActionDeliveryPriority';
import * as ActionDetailBranch from '../action/Branch/ActionDetailBranch';
import * as ActionDetailListCsvType from '../action/Customer/ActionDetailListCsvType';
import * as ActionDigitalWaybillReport from '../action/Report/DigitalWaybillReport/ActionDigitalWaybill';
import * as ActionDistribution from '../action/Delivery/Document/ActionFormDistribusi';
import * as ActionDownloadCustomerReport from '../action/Report/CustomerReport/ActionCustomerReportForm';
import * as ActionEmergency from '../action/ManualProcess/ActionEmergencyProcess';
import * as ActionFieldCustomer from '../action/Customer/ActionFieldCustomer';
import * as ActionFilterDelivery from '../action/DataDelivery/ActionFormDataDelivery';
import * as ActionFormPrintWaybill from '../action/Delivery/PrintWaybill/ActionFormPrintWaybill';
import * as ActionFormResend from '../action/Attempt/ActionFormResend';
import * as ActionInvoice from '../action/Report/InvoiceReport/ActionSetFormInvoice';
import * as ActionLastAccess from '../action/LastAccess/index';
import * as ActionListCustomCsvCustomer from '../action/Customer/ActionListCustomCsvCustomer';
import * as ActionListCustomer from '../action/Customer/ActionListCustomer';
import * as ActionListCustomerProduct from '../action/CustomerProduct/ActionListCustomerProduct';
import * as ActionListResendSubmit from '../action/Attempt/ActionListResendSubmit';
import * as ActionLogActivity from '../../module/LogActivity/Store/LogActivityAction';
import * as ActionManifestCourier from '../action/Manifest/CourierManifest/ActionCourierManifest';
import * as ActionManifestCustomer from '../action/Manifest/CustomerManifest/ActionCustomerManifest';
import * as ActionManualProcess from '../action/ManualProcess/ActionManualProcess';
import * as ActionManualProcessVendor from '../action/ManualProcess/ActionManualProcessVendor';
import * as ActionNavigation from '../action/Router/ActionRouter';
import * as ActionPagination from '../action/Pagination/ActionPagination';
import * as ActionPickupData from '../../module/Pickup/PickupData/Store/PickupDataAction';
import * as ActionPickupProcess from '../../module/PickUpProcess/Store/PickUpProcessAction';
import * as ActionPickupStatus from '../../module/Pickup/PickupStatus/Store/PickupStatusAction';
import * as ActionProfile from '../action/Profile/ActionProfile';
import * as ActionRadiobutton from '../action/Customer/ActionRadiobuttonStatus';
import * as ActionReasonDelivery from '../action/Customer/ActionReasonStatusCustomer';
import * as ActionReport from '../action/Report/CourierReport/ActionCourierReportForm';
import * as ActionResendSingleData from '../action/Delivery/Resend/ActionResendSingleData';
import * as ActionResetPassword from '../action/ResetPassword/ActionResetPassword';
import * as ActionRestoreData from '../action/RestoreData/ActionRestoreData';
import * as ActionScanManifest from '../action/Manifest/ScanManifest/ActionScanManifest';
import * as ActionServerVoip from '../action/Voip/Server/ActionServer';
import * as ActionSetDeliveryStatus from 'module/DeliveryStatus/Store/ActionSetDeliveryStatus';
import * as ActionStatusData from '../action/Customer/ActionTableStatusDefault';
import * as ActionTable from '../action/Table/index';
import * as ActionTableBranch from '../action/Branch/ActionTableBranch';
import * as ActionToggle from '../action/toggle/index';
import * as ActionToggleMaps from '../action/Maps/ActionToggleMaps';
import * as ActionUnggahCsv from '../action/Delivery/Document/ActionFormUnggahDocument';
import * as ActionUpdateAddress from '../action/ManualProcess/ActionUpdateAddress';
import * as ActionUploadSoftcopy from '../action/Delivery/UploadSoftcopy/ActionUploadSoftcopy';
import * as ActionValidateReport from '../action/Report/ValidateReport/ActionValidateForm';

import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import history from '../utils/History';

interface IProps {
  token: any;
  router: any;
  navigation: any;
  actionComponent: any;
  statusTokenRefresh: any;
  actiontable: any;
  actiondetailbranch: any;
  actiondetailcustomer: any;
  actionToggle: any;
  actionFilterDelivery: any;
  actionTableBranch: any;
  actionDetailBranch: any;
  actionCallCenter: any;
  actionClearLastAccessFiture: any;
  actionTableCourier: any;
  actionTableAdmin: any;
  actionDistribution: any;
  actionUnggahCsv: any;
  actionLastAccess: any;
  actionNavigation: any;
  actionSetDeliveryStatus: any;
  actionStatusData: any;
  actionReasonDelivery: any;
  actionCustomCsvCustomer: any;
  actionCustomerFiture: any;
  actionCustomerConfigReport: any;
  actionFormResend: any;
  actionListResendSubmit: any;
  actionLogActivity: any;
  actionRadiobutton: any;
  actionManualProcess: any;
  actionManualProcessVendor: any;
  actionCourierTracking: any;
  actionToggleMaps: any;
  actionReport: any;
  actionPagination: any;
  actionListCustomer: any;
  actionListCustomerProduct: any;
  actionListCustomCsvCustomer: any;
  actionInvoice: any;
  actionDetailListCsvType: any;
  actionDownloadCustomerReport: any;
  actionComplaint: any;
  actionComplaintNew: any;
  actionProfile: any;
  actionPickupProcess: any;
  actionPickupStatus: any;
  actionPickupData: any;
  actionResetPassword: any;
  actionFormPrintWaybill: any;
  actionScanManifest: any;
  actionManifestCourier: any;
  actionManifestCustomer: any;
  actionCustomerAccount: any;
  actionAssigningCourier: any;
  actionValidateReport: any;
  actionUploadSoftcopy: any;
  actionDeliveryPrintWaybill: any;
  actionDataDownload: any;
  actionDeliveryPriority: any;
  actionServerVoip: any;
  actionRestoreData: any;
  actionDataActive: any;
  actionAuth: any;
  actionActivationVoip: any;
  actionResendSingleData: any;
  actionEmergency: any;
  actionDigitalWaybillReport: any;
  actionUpdateAddress: any;
}

function redirectLogin() {
  history.push('/login');
}
function redirectDashboard() {
  history.push('/');
}
export function CheckToken(ComposeComponent: any) {
  class ComposedCheckToken extends Component<IProps> {
    public componentDidMount() {
      if (this.props.statusTokenRefresh === false) {
        this._checkAndRedirect();
      }
    }

    public componentDidUpdate() {}

    public _checkAndRedirect() {
      const { token } = this.props;
      if (token === null) {
        redirectLogin();
      }

      this.props.actiondetailbranch.clearAllBranch();
      this.props.actiontable.clearAllTable();
      if (
        this.props.router === '/customer/config' ||
        this.props.router === '/manageCustomerAccount/detail'
      ) {
      } else {
        this.props.actiondetailcustomer.changeClearAllDataCustomer();
        this.props.actionReasonDelivery.changeClearReasonStat();
      }

      this.props.actionCallCenter.clearAllFormCallCenter();
      this.props.actionDetailBranch.clearAllBranch();
      this.props.actionToggle.clearJenisToggle();
      this.props.actionToggle.clearValueSearch();
      this.props.actionDistribution.ClearAllFormDistribution();
      this.props.actionUnggahCsv.clearAllFormUnggahCsv();
      this.props.actionLogActivity.clearLogActivityListData();
      this.props.actionLastAccess.changeClearStep();
      this.props.actionSetDeliveryStatus.clearDeliveryStatus();
      this.props.actionSetDeliveryStatus.clearDeliveryStatusBarcode();
      this.props.actionStatusData.clearDefaultDataStatus();
      this.props.actionStatusData.clearCustomDataStatus();
      this.props.actionRadiobutton.clearRadiobutton();
      this.props.actionCustomCsvCustomer.clearAllDataCustomCsvCustomer();
      this.props.actionCustomerFiture.changeClearCustomerFiture();
      this.props.actionCustomerConfigReport.changeClearCustomerConfigReport();
      this.props.actionCustomerConfigReport.changeClearCustomerTypeFormConfigReport();
      this.props.actionCustomerConfigReport.clearListCustomerReport();
      this.props.actionFormResend.clearAllFormResend();
      this.props.actionListResendSubmit.clearListResendSubmit();
      this.props.actionManualProcess.clearManualProcessForm();
      this.props.actionManualProcessVendor.clearManualProcessVendorForm();
      this.props.actionCourierTracking.clearDetailCourier();
      this.props.actionCourierTracking.clearSelectCourier();
      this.props.actionToggleMaps.clearTypeRealTime();
      this.props.actionCourierTracking.clearCourierDeliveryData();
      this.props.actionReport.clearCourierRForm();
      this.props.actionPagination.clearAllPagination();
      this.props.actionListCustomer.clearListCustomer();
      this.props.actionListCustomerProduct.clearListCustomerProduct();
      this.props.actionInvoice.clearAllFormInvoice();
      this.props.actionListCustomCsvCustomer.clearListCustomCsvCustomer();
      this.props.actionDetailListCsvType.clearListCustomerCsvType();
      this.props.actionFilterDelivery.resetFilter();
      this.props.actionDownloadCustomerReport.clearCustomerReportForm();
      this.props.actionComplaint.clearPropertiesComplaint();
      this.props.actionComplaint.clearComplaintForm();
      this.props.actionComplaintNew.clearComplaintNewForm();
      this.props.actionComplaintNew.clearComplaintDetailForm();
      this.props.actionFormPrintWaybill.clearListAndDate();
      this.props.actionScanManifest.clearManifestForm();
      this.props.actionScanManifest.clearTableManifest();
      this.props.actionComponent.clearAllPagination();
      this.props.actionManifestCourier.clearCourierManifestForm();
      this.props.actionManifestCourier.clearTableCourierManifestForm();
      this.props.actionManifestCourier.clearListCourierManifestForm();
      this.props.actionManifestCustomer.clearCustomerManifestForm();
      this.props.actionManifestCustomer.clearTableCustomerManifestForm();
      this.props.actionManifestCustomer.clearListCustomerrManifestForm();
      this.props.actionScanManifest.clearScanStatusAndBorW();
      this.props.actionDataDownload.changeClearDataDownload();
      this.props.actionScanManifest.clearPropertiesScanManifest();
      this.props.actionCustomerAccount.clearDataCustomerAccount();
      this.props.actionValidateReport.clearValidateReportForm();
      this.props.actionDeliveryPrintWaybill.clearListData();
      this.props.actionAssigningCourier.ClearFormAc();
      this.props.actionUploadSoftcopy.clearUploadSoftcopy();
      this.props.actionDeliveryPriority.clearDataForm();
      this.props.actionDeliveryPriority.clearDataTable();
      this.props.actionServerVoip.changeClearAllFormServerVoip();
      this.props.actionServerVoip.changeClearListServerVoip();
      this.props.actionActivationVoip.clearActivationVoip();
      this.props.actionRestoreData.clearFormRestoreData();
      this.props.actionRestoreData.clearListRestoreData();
      this.props.actionRestoreData.clearListWaybillRestoreData();
      this.props.actionDataActive.clearListDataActive();
      this.props.actionDigitalWaybillReport.clearForm();
      this.props.actionUpdateAddress.clearAllForm();
      this.props.actionResendSingleData.clearClearAllForm();
      this.props.actionPickupStatus.clearPickupStatusDoc();
      this.props.actionPickupData.clearDataTablePickup();
      if (this.props.router !== '/profile') {
        this.props.actionProfile.changeClearDataProfile();
      }
      if (
        this.props.router === '/processPickUp/detailPickUp' ||
        this.props.router === '/processPickUp'
      ) {
      } else {
        this.props.actionPickupProcess.clearPickupProcessData();
      }
      this.props.actionResetPassword.changeClearDataResetPassword();

      this.props.actionTableBranch.ClearTableBranch();
      if (
        this.props.router === '/manageAccount' ||
        this.props.router === '/delivery/document' ||
        this.props.router === '/laporan/download' ||
        this.props.router === '/delivery/uploadSoftcopy' ||
        this.props.router === '/manualProcess' ||
        this.props.router === '/IndexManifest'
      ) {
      } else {
        this.props.actionClearLastAccessFiture.changeClearLastAccessFiture();
        this.props.actionTableCourier.clearCourierTable();
        this.props.actionTableAdmin.clearAdminTable();
        this.props.actionEmergency.ClearAllEmergancyForm();
      }
    }
    render() {
      return <ComposeComponent />;
    }
  }
  const mapStateToProps = (state: any) => ({
    token: state.Auth.Token,
    statusTokenRefresh: state.Auth.StatusTokenRefresh,
    router: state.router.location.pathname,
    navigation: state.Navigation.router,
  });

  const mapDispatchToProps = (dispatch: any) => ({
    actiontable: bindActionCreators(ActionTable, dispatch),
    actionUpdateAddress: bindActionCreators(ActionUpdateAddress, dispatch),
    actionComponent: bindActionCreators(ActionComponent, dispatch),
    actionUploadSoftcopy: bindActionCreators(ActionUploadSoftcopy, dispatch),
    actionValidateReport: bindActionCreators(ActionValidateReport, dispatch),
    actiondetailbranch: bindActionCreators(ActionDetailBranch, dispatch),
    actiondetailcustomer: bindActionCreators(ActionFieldCustomer, dispatch),
    actionDataDownload: bindActionCreators(ActionDataDownload, dispatch),
    actionToggle: bindActionCreators(ActionToggle, dispatch),
    actionDeliveryPrintWaybill: bindActionCreators(
      ActionDeliveryPrintWaybill,
      dispatch
    ),
    actionTableBranch: bindActionCreators(ActionTableBranch, dispatch),
    actionDetailBranch: bindActionCreators(ActionDetailBranch, dispatch),
    actionAuth: bindActionCreators(ActionAuth, dispatch),
    actionAssigningCourier: bindActionCreators(
      ActionAssigningCourier,
      dispatch
    ),
    actionClearLastAccessFiture: bindActionCreators(
      ActionClearLastAccess,
      dispatch
    ),
    actionCallCenter: bindActionCreators(ActionCallCenter, dispatch),
    actionTableCourier: bindActionCreators(ActionCourierTable, dispatch),
    actionTableAdmin: bindActionCreators(ActionAdminTable, dispatch),
    actionDistribution: bindActionCreators(ActionDistribution, dispatch),
    actionNavigation: bindActionCreators(ActionNavigation, dispatch),
    actionUnggahCsv: bindActionCreators(ActionUnggahCsv, dispatch),
    actionLastAccess: bindActionCreators(ActionLastAccess, dispatch),
    actionLogActivity: bindActionCreators(ActionLogActivity, dispatch),
    actionSetDeliveryStatus: bindActionCreators(
      ActionSetDeliveryStatus,
      dispatch
    ),
    actionStatusData: bindActionCreators(ActionStatusData, dispatch),
    actionReasonDelivery: bindActionCreators(ActionReasonDelivery, dispatch),
    actionCustomCsvCustomer: bindActionCreators(
      ActionCustomCsvCustomer,
      dispatch
    ),
    actionCustomerFiture: bindActionCreators(ActionCustomerFiture, dispatch),
    actionCustomerConfigReport: bindActionCreators(
      ActionCustomerConfigReport,
      dispatch
    ),
    actionFormResend: bindActionCreators(ActionFormResend, dispatch),
    actionListResendSubmit: bindActionCreators(
      ActionListResendSubmit,
      dispatch
    ),
    actionRadiobutton: bindActionCreators(ActionRadiobutton, dispatch),
    actionManualProcess: bindActionCreators(ActionManualProcess, dispatch),
    actionManualProcessVendor: bindActionCreators(
      ActionManualProcessVendor,
      dispatch
    ),
    actionCourierTracking: bindActionCreators(ActionCourierTracking, dispatch),
    actionToggleMaps: bindActionCreators(ActionToggleMaps, dispatch),
    actionReport: bindActionCreators(ActionReport, dispatch),
    actionPagination: bindActionCreators(ActionPagination, dispatch),
    actionListCustomer: bindActionCreators(ActionListCustomer, dispatch),
    actionListCustomerProduct: bindActionCreators(
      ActionListCustomerProduct,
      dispatch
    ),
    actionListCustomCsvCustomer: bindActionCreators(
      ActionListCustomCsvCustomer,
      dispatch
    ),
    actionInvoice: bindActionCreators(ActionInvoice, dispatch),
    actionDetailListCsvType: bindActionCreators(
      ActionDetailListCsvType,
      dispatch
    ),
    actionFilterDelivery: bindActionCreators(ActionFilterDelivery, dispatch),
    actionDownloadCustomerReport: bindActionCreators(
      ActionDownloadCustomerReport,
      dispatch
    ),
    actionComplaint: bindActionCreators(ActionComplaint, dispatch),
    actionComplaintNew: bindActionCreators(ActionComplaintNew, dispatch),
    actionProfile: bindActionCreators(ActionProfile, dispatch),
    actionPickupProcess: bindActionCreators(ActionPickupProcess, dispatch),
    actionPickupStatus: bindActionCreators(ActionPickupStatus, dispatch),
    actionPickupData: bindActionCreators(ActionPickupData, dispatch),
    actionResetPassword: bindActionCreators(ActionResetPassword, dispatch),
    actionFormPrintWaybill: bindActionCreators(
      ActionFormPrintWaybill,
      dispatch
    ),
    actionScanManifest: bindActionCreators(ActionScanManifest, dispatch),
    actionCustomerAccount: bindActionCreators(ActionCustomerAccount, dispatch),
    actionManifestCourier: bindActionCreators(ActionManifestCourier, dispatch),
    actionDeliveryPriority: bindActionCreators(
      ActionDeliveryPriority,
      dispatch
    ),
    actionServerVoip: bindActionCreators(ActionServerVoip, dispatch),
    actionRestoreData: bindActionCreators(ActionRestoreData, dispatch),
    actionDataActive: bindActionCreators(ActionDataActive, dispatch),
    actionActivationVoip: bindActionCreators(ActionActivationVoip, dispatch),
    actionResendSingleData: bindActionCreators(
      ActionResendSingleData,
      dispatch
    ),
    actionManifestCustomer: bindActionCreators(
      ActionManifestCustomer,
      dispatch
    ),
    actionEmergency: bindActionCreators(ActionEmergency, dispatch),
    actionDigitalWaybillReport: bindActionCreators(
      ActionDigitalWaybillReport,
      dispatch
    ),
  });

  return connect(mapStateToProps, mapDispatchToProps)(ComposedCheckToken);
}

export function CheckLogin(ComposeComponent: any) {
  class ComposedCheckLogin extends Component<IProps> {
    public componentDidMount() {
      this._checkAndRedirect();
    }

    public componentDidUpdate() {
      this._checkAndRedirect();
    }

    public _checkAndRedirect() {
      const { token } = this.props;
      if (token === null) {
        this.props.actiontable.clearAllTable();
        this.props.actionTableBranch.ClearTableBranch();
        this.props.actionDistribution.ClearAllFormDistribution();
        this.props.actionUnggahCsv.clearAllFormUnggahCsv();
        this.props.actionLastAccess.changeClearStep();
        this.props.actionNavigation.setRouter('/');
        this.props.actionSetDeliveryStatus.clearDeliveryStatus();
      } else {
        redirectDashboard();
      }
    }
    render() {
      return <ComposeComponent />;
    }
  }
  const mapStateToProps = (state: any) => ({
    token: state.Auth.Token,
    router: state.router.location.pathname,
    navigation: state.Navigation.router,
  });

  const mapDispatchToProps = (dispatch: any) => ({
    actiontable: bindActionCreators(ActionTable, dispatch),
    actiontablebranch: bindActionCreators(ActionTable, dispatch),
    actiondetailbranch: bindActionCreators(ActionDetailBranch, dispatch),
    actionToggle: bindActionCreators(ActionToggle, dispatch),
    actionTableBranch: bindActionCreators(ActionTableBranch, dispatch),
    actionDetailBranch: bindActionCreators(ActionDetailBranch, dispatch),
    actionDistribution: bindActionCreators(ActionDistribution, dispatch),
    actionUnggahCsv: bindActionCreators(ActionUnggahCsv, dispatch),
    actionLastAccess: bindActionCreators(ActionLastAccess, dispatch),
    actionNavigation: bindActionCreators(ActionNavigation, dispatch),
    actionSetDeliveryStatus: bindActionCreators(
      ActionSetDeliveryStatus,
      dispatch
    ),
    actionFormResend: bindActionCreators(ActionFormResend, dispatch),
    actionListResendSubmit: bindActionCreators(
      ActionListResendSubmit,
      dispatch
    ),
  });
  return connect(mapStateToProps, mapDispatchToProps)(ComposedCheckLogin);
}
