import * as ActionAssigningCourier from '../../action/AssigningCourier/ActionAssigningCourier';
import * as ActionLogin from '../../../module/Login/Store';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../action/toggle';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_SubmitWaybill(action) {
  try {
    yield put(changeLoadingTrue());
    const state = yield select();
    const branchId = state.AssigningCourier.branchId;
    const courierId = state.AssigningCourier.courierId;
    const WorB = state.AssigningCourier.WorB;
    const CourierCode = state.AssigningCourier.courierCode;
    const CheckBox = state.AssigningCourier.CheckBox;
    const token = state.Auth.Token;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-type': 'application/json',
    };

    yield call(
      axios.put,
      CheckBox === true
        ? process.env.REACT_APP_URL + '/Delivery/HeadBranchScanByBarcode'
        : process.env.REACT_APP_URL + '/Delivery/HeadBranchScanByWaybill',
      {
        branchId: branchId,
        courierId: courierId,
        waybillOrBarcode: WorB,
      },
      { headers: config }
    );
    yield put(ActionAssigningCourier.changeAssignStatus('Success'));
    yield put(ActionAssigningCourier.changeDetailWorB(WorB));
    yield put(ActionAssigningCourier.changeDetailCourierCode(CourierCode));
    yield put(ActionAssigningCourier.ClearWorBAc());
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      const state = yield select();
      const WorB = state.AssigningCourier.WorB;
      yield put(ActionAssigningCourier.changeDetailWorB(WorB));

      if (
        error.response.status === 500 &&
        (error.response.data.Message ===
          `Can not assign with No Waybill because status Success` ||
          error.response.data.Message ===
            `Can not assign with No Barcode because status Success`)
      ) {
        yield put(ActionAssigningCourier.changeAssignStatus('Failed'));
        yield put(ActionAssigningCourier.ClearWorBAc());
        yield put(changeLoadingFalse());
      } else if (
        error.response.status === 500 &&
        (error.response.data.Message ===
          `Can not assign with No Waybill because status Return` ||
          error.response.data.Message ===
            `Can not assign with No Barcode because status Return`)
      ) {
        yield put(ActionAssigningCourier.changeAssignStatus('Failed'));
        yield put(ActionAssigningCourier.ClearWorBAc());
        yield put(changeLoadingFalse());
      } else if (
        error.response.status === 500 &&
        (error.response.data.Message ===
          `Can not assign with No Waybill because status OnHold` ||
          error.response.data.Message ===
            `Can not assign with No Barcode because status OnHold`)
      ) {
        yield put(ActionAssigningCourier.changeAssignStatus('Failed'));
        yield put(ActionAssigningCourier.ClearWorBAc());
        yield put(changeLoadingFalse());
      } else if (
        error.response.status === 500 &&
        (error.response.data.Message === `No Waybill found!` ||
          error.response.data.Message === `No Barcode found!`)
      ) {
        yield put(ActionAssigningCourier.changeAssignStatus('FailedNotFound'));
        yield put(ActionAssigningCourier.ClearWorBAc());
        yield put(changeLoadingFalse());
      } else {
        yield put(ActionLogin.errorHandling(action, error));
        yield put(ActionAssigningCourier.ClearWorBAc());
        yield put(changeLoadingFalse());
      }
    }
  }
}

export function* S_SubmitWorBSA() {
  try {
    yield put(changeLoadingTrue());
    const state = yield select();
    const envelopeType = state.AssigningCourier.envelopeType;
    const deliveryType = state.AssigningCourier.deliveryType;
    const area = state.AssigningCourier.area;
    const branchId = state.AssigningCourier.branchId;
    const courierId = state.AssigningCourier.courierId;
    const koli = state.AssigningCourier.koli;
    const weight = state.AssigningCourier.weight;
    const WorB = state.AssigningCourier.WorB;
    const CourierCode = state.AssigningCourier.courierCode;
    const CheckBox = state.AssigningCourier.CheckBox;
    const CheckBoxKoli = state.AssigningCourier.CheckBoxKoli;
    const token = state.Auth.Token;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-type': 'application/json',
    };

    yield call(
      axios.put,
      CheckBox === true
        ? process.env.REACT_APP_URL + '/Delivery/SuperAdminScanByBarcode'
        : process.env.REACT_APP_URL + '/Delivery/SuperAdminScanByWaybill',
      {
        dimention: envelopeType,
        deliveryType: deliveryType,
        deliveryDistrictType: area,
        branchId: branchId,
        courierId: courierId,
        trueOrFalseKoli: CheckBoxKoli,
        totalWeight: weight === '' || weight === null ? 0 : weight,
        koli: koli,
        barcode: CheckBox === true ? WorB : 'string',
        waybill: CheckBox === true ? 'string' : WorB,
      },
      { headers: config }
    );
    yield put(ActionAssigningCourier.changeAssignStatus('Success'));
    yield put(ActionAssigningCourier.changeDetailWorB(WorB));
    yield put(ActionAssigningCourier.changeDetailCourierCode(CourierCode));
    yield put(ActionAssigningCourier.ClearWorBAc());
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      const state = yield select();
      const WorB = state.AssigningCourier.WorB;
      yield put(ActionAssigningCourier.changeDetailWorB(WorB));
      const propsError = ErrorMassage('Assign Courier');

      if (
        error.response.status === 500 &&
        (error.response.data.Message ===
          'Can not assign with No Waybill ' +
            WorB +
            ' because status Success' ||
          error.response.data.Message ===
            'Can not assign with No Barcode ' +
              WorB +
              ' because status Success')
      ) {
        yield put(ActionAssigningCourier.changeAssignStatus('Failed'));
        yield put(ActionAssigningCourier.ClearWorBAc());
        yield put(changeLoadingFalse());
      } else if (
        error.response.status === 500 &&
        (error.response.data.Message ===
          'Can not assign with No Waybill ' + WorB + ' because status Return' ||
          error.response.data.Message ===
            'Can not assign with No Barcode ' + WorB + ' because status Return')
      ) {
        yield put(ActionAssigningCourier.changeAssignStatus('Failed'));
        yield put(ActionAssigningCourier.ClearWorBAc());
        yield put(changeLoadingFalse());
      } else if (
        error.response.status === 500 &&
        (error.response.data.Message ===
          'Can not assign with No Waybill ' + WorB + ' because status OnHold' ||
          error.response.data.Message ===
            'Can not assign with No Barcode ' + WorB + ' because status OnHold')
      ) {
        yield put(ActionAssigningCourier.changeAssignStatus('Failed'));
        yield put(ActionAssigningCourier.ClearWorBAc());
        yield put(changeLoadingFalse());
      } else if (
        error.response.status === 500 &&
        (error.response.data.Message ===
          'No Waybill with ' + WorB + ' found!' ||
          error.response.data.Message === 'No Barcode with ' + WorB + ' found!')
      ) {
        yield put(ActionAssigningCourier.changeAssignStatus('FailedNotFound'));
        yield put(ActionAssigningCourier.ClearWorBAc());
        yield put(changeLoadingFalse());
      } else if (
        error.response.status === 500 &&
        error.response.data.Message ===
          'Data cannot be scanned because the branches do not match'
      ) {
        yield put(ActionAssigningCourier.changeAssignStatus('FailedNotFound'));
        yield put(ActionAssigningCourier.ClearWorBAc());
        yield put(changeLoadingFalse());
      } else {
        const code = propsError.filter((data: any) =>
          data.Code.toString().toLowerCase().includes(error.response.status)
        );
        yield put(ActionAssigningCourier.ClearWorBAc());
        yield put(changeLoadingFalse());
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              {code.map((data: any) => data.TitleMassage)}
            </p>
          ),
          content: (
            <div>
              <p style={{ color: '#C0C0C0' }}>
                {code.map((data: any) => data.Massage)}
              </p>
            </div>
          ),
          onOk() {},
        });
      }
    }
  }
}

export function* SM_SubmitWaybill() {
  yield takeLatest('SUBMIT_ASSIGNING_COURIER_W_OR_B', S_SubmitWaybill);
}

export function* SM_SubmitWorBSA() {
  yield takeLatest('SUBMIT_ASSIGNING_COURIER_W_OR_B_SA', S_SubmitWorBSA);
}
