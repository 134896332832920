import * as ActionDeliveryPriority from '../../../core/action/DeliveryPriority/ActionDeliveryPriority';
import * as ActionPagination from '../../../core/action/Pagination/ActionPagination';
import * as ActionToggle from '../../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import DeliveryPriorityComponent from '../../../components/Content/DeliveryPriority/DeliveryPriorityComponent';
import { connect } from 'react-redux';

interface IProps {
  ListDeliveryPriority: any;
  isLoading: any;
  PagePagination: any;
  TotalPagePagination: any;
  actionPagination: any;
  actionDeliveryPriority: any;
  actionToggle: any;
  getFilterType: any;
  getWaybillForm: any;
}

interface IState {}

class DeliveryPriorityContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {};

    this.handleModal = this.handleModal.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handlePutWaybillSearch = this.handlePutWaybillSearch.bind(this);
  }
  handleModal() {
    this.props.actionDeliveryPriority.clearWaybillForm();
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle('AddPriority');
  }
  handleFilter(event: any) {
    this.props.actionDeliveryPriority.changeFilterDP(event.key);
    this.props.actionPagination.setPagePagination(1);
    this.props.actionDeliveryPriority.getDataTableDP();
  }
  handleSearch() {
    this.props.actionPagination.setPagePagination(1);
    this.props.actionDeliveryPriority.getDataTableDP();
  }
  handlePutWaybillSearch(event: any) {
    this.props.actionDeliveryPriority.setWaybillForm(
      event.target.value.replace(/^\s/, '')
    );
  }
  handlePage(event: any) {
    this.props.actionPagination.setPagePagination(event);
    this.props.actionDeliveryPriority.getDataTableDP();
  }
  componentWillMount() {
    this.props.actionDeliveryPriority.getDataTableDP();
  }
  render() {
    return (
      <React.Fragment>
        <DeliveryPriorityComponent
          isLoading={this.props.isLoading}
          handleModal={this.handleModal}
          ListDeliveryPriority={this.props.ListDeliveryPriority}
          PagePagination={this.props.PagePagination}
          TotalPagePagination={this.props.TotalPagePagination}
          handlePage={this.handlePage}
          handleFilter={this.handleFilter}
          handleSearch={this.handleSearch}
          handlePutWaybillSearch={this.handlePutWaybillSearch}
          getWaybillForm={this.props.getWaybillForm}
          getFilterType={this.props.getFilterType}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  ListDeliveryPriority: state.DeliveryPriority.ListDeliveryPriority,
  getWaybillForm: state.DeliveryPriority.DeliveryPriorityForm.waybillForm,
  getFilterType: state.DeliveryPriority.Properties.filterType,
  PagePagination: state.Pagination.PagePagination,
  TotalPagePagination: state.Pagination.TotalPagePagination,
  TypeToggle: state.Toggle.jenisToggle,
  isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionDeliveryPriority: bindActionCreators(ActionDeliveryPriority, dispatch),
  actionPagination: bindActionCreators(ActionPagination, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DeliveryPriorityContainer);
