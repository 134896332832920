import 'styles/FormInputStyle/index.css';

import { Card, Col, List, Row, Skeleton } from 'antd';

import ButtonDetailBranchComponent from 'module/Dashboard/Container/Pengiriman/ButtonDetailBranchContainer';
import CardMaterial from 'materials/CardMaterial';
import MapsContainer from 'module/Map/Container/MapContainer';
import React from 'react';
import SearchMatrial from 'materials/SearchMatrial';

interface IProps {
  deliveryBranch: any;
  isloading: any;
  toggleType: any;
  ValueSearch: any;
  searchDeliveryBranch: any;
  nameSearch: any;
  handleSearch: any;
  handleResetSearch: any;
  handleDetailBranch: any;
}
export default function DashboardComponent({
  ValueSearch,
  handleSearch,
  isloading,
  toggleType,
  deliveryBranch,
}: IProps) {
  const listData: any = [];
  for (let i = 0; i < 6; i++) {
    listData.push({ name: '', onProccess: '', success: '', return: '' });
  }
  return (
    <div>
      <div>
        <CardMaterial
          extra={''}
          style={{ borderRadius: '10px' }}
          title={''}
          content={
            <div>
              <Row>
                <Col
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                  style={{ color: '#11BEFF', paddingTop: '6px' }}
                >
                  <span>
                    <b>SEMUA POSKO</b>
                  </span>
                </Col>
                <Col xs={21} sm={21} md={21} lg={21} xl={21}>
                  <SearchMatrial
                    value={ValueSearch}
                    style={{ borderRadius: '10px', width: '40%' }}
                    placeholder={'Cari nama atau kode'}
                    onChange={handleSearch}
                  />
                </Col>
              </Row>
            </div>
          }
        />
      </div>
      <div style={{ paddingTop: '25px' }}>
        {isloading === true && toggleType !== 'locationCourier' ? (
          <List
            grid={{ gutter: 15, column: 3 }}
            dataSource={listData}
            renderItem={item => (
              <List.Item>
                <Card
                  style={{
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                  }}
                >
                  <Skeleton loading={true} active />
                </Card>
              </List.Item>
            )}
          />
        ) : (
          <List
            grid={{ gutter: 15, column: 3 }}
            pagination={{
              onChange: page => {},
              pageSize: 6,
            }}
            dataSource={deliveryBranch
              .sort((a: any, b: any) => b.total - a.total)
              .filter(
                (state: any) =>
                  state.name.toString().toLowerCase().includes(ValueSearch) ||
                  state.uniqueCode
                    .toString()
                    .toLowerCase()
                    .includes(ValueSearch)
              )}
            renderItem={(item: any) => (
              <List.Item>
                <CardMaterial
                  extra={''}
                  title={''}
                  style={{
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                    height: '180px',
                  }}
                  content={
                    <div>
                      <div
                        style={{
                          fontWeight: 'bold',
                          color: '#11BEFF',
                          fontSize: '20px',
                          textAlign: 'center',
                          marginBottom: 15,
                        }}
                      >
                        {item.name}
                      </div>
                      <div>
                        <div
                          style={{
                            fontSize: '30px',
                            textAlign: 'center',
                            background: '#f5f5f5',
                          }}
                        >
                          <span style={{ color: 'black' }}>
                            {item.onProccess}
                          </span>
                          <span>/</span>
                          <span style={{ color: 'black' }}>
                            <b>{item.total}</b>
                          </span>
                        </div>
                        <div
                          style={{
                            fontSize: '16px',
                            textAlign: 'center',
                            paddingTop: '10px',
                          }}
                        >
                          <div>
                            Diterima{' '}
                            <b style={{ color: '#31E116' }}>{item.success}</b>
                          </div>
                          <div>
                            Dikembalikan{' '}
                            <b style={{ color: 'red' }}>{item.return}</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
                <ButtonDetailBranchComponent idBranch={item.id} />
              </List.Item>
            )}
          ></List>
        )}
      </div>
      <div style={{ paddingTop: '35px' }}>
        <MapsContainer />
      </div>
    </div>
  );
}
