import React, { useState, useEffect } from 'react';
import ModalFormBagging from './Components/ModalFormBagging';
import ModalScanWaybil from './Components/ModalScanWaybil';
import ModalConfirmation from 'components/ModalConfirmation';
import ModalDetailBagging from '../ModalDetailBagging';
import BaggingDocumentFilter from './Components/BaggingDocumentFilter';
import BaggingDocumentTable from './Components/BaggingDocumentTable';
import { DownloadBagTypes } from 'lib/constants';
import { message, Modal } from 'antd';
import { useQuery, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { getCourierWithBranch } from 'services/GeneralService';
import ModalStatus from 'materials/ModalStatus';
import { downloadBlob, processProgressRequest } from 'lib/util';
import {
  getBarcodeBag,
  postBaggingDocument,
  getDataTableBagging,
  getCountingTableBagging,
  getSingleBaggingDocument,
  putBaggingDocument,
  downloadBaggingDocument,
  deleteBaggingDocument,
  downloadBaggingDocumentList,
} from 'services/BaggingDocumentServices';
import { BaggingDocumentFormType, DataFilterBaggingType } from '..';
import moment from 'moment-timezone';
import ModalDownloadProgress from 'materials/ModalDownloadProgress';

type BaggingDocumentProps = {
  dataAllBranch: Array<any>;
  isLoadingBranch?: boolean;
  IdBranch?: number;
  roleAccount: string;
};

const defaultFilter = {
  BaggingTypes: ['Delivery', 'Return'],
  CreatedBaggingTime: null,
  BaggingStatusDocument: ['Process', 'Success'],
  OriginalBranch: null,
  DestinationBranch: null,
  BarcodeBagging: '',
};

export default function BaggingDocument({
  dataAllBranch,
  isLoadingBranch,
  IdBranch,
  roleAccount,
}: BaggingDocumentProps) {
  const [page, setPage] = useState<number>(0);
  const [dataFilterBagging, setDataFilterBagging] =
    useState<DataFilterBaggingType>(defaultFilter);

  const [selectedDownloadBag, setSelectedDownloadBag] = useState<string>('');
  const [selectedBagId, setSelectedBagId] = useState<number | null>(null);
  const [courierItems, setCourierItems] = useState<Array<any>>([]);

  const [isShowModalForm, setIsShowModalForm] = useState<boolean>(false);
  const [isShowModalScan, setIsShowModalScan] = useState<boolean>(false);
  const [isEditForm, setIsEditForm] = useState<boolean>(false);
  const [isDetailForm, setIsDetailForm] = useState<boolean>(false);
  const [isDeletedBag, setIsDeletedBag] = useState<any>(null);

  const [loadingProgress, setLoadingProgress] = useState(0);
  const progressHandler = processProgressRequest(setLoadingProgress);

  const [dataFormBagging, setDataFormBagging] =
    useState<BaggingDocumentFormType>({
      typeBag: 'Delivery',
      dateSend: null,
      courier: null,
      branchFrom: null,
      branchTo: null,
      barcodeBag: null,
      finishBaggingTime: null,
      couriercode: null,
      branchFromName: null,
      branchToName: null,
    });

  const { data: dataAllCourier, isLoading: isLoadingCourier } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: [
      'dataProductByCustomersReport',
      {
        branchItems:
          dataFormBagging.typeBag === 'Delivery'
            ? dataFormBagging.branchTo
            : dataFormBagging.branchFrom,
        isAll: false,
      },
    ],
    queryFn: ({ queryKey }) => getCourierWithBranch(queryKey),
    enabled:
      !!(dataFormBagging.typeBag === 'Delivery'
        ? dataFormBagging.branchFrom
        : dataFormBagging.branchTo) && !isShowModalScan,
  });

  const {
    data: dataTableBag,
    isFetching: isLoadingTableBag,
    refetch,
  } = useQuery(
    ['TableBarcodeBag', page, dataFilterBagging],
    () => getDataTableBagging({ page, ...dataFilterBagging }),
    {
      enabled: !!dataFilterBagging.CreatedBaggingTime,
    }
  );

  const { isFetching: isLoadingSingleBagging, data: dataSingleBag } = useQuery<
    AxiosResponse,
    Error
  >(
    ['SingleDataBarcodeBag', selectedBagId],
    () => getSingleBaggingDocument(selectedBagId),
    {
      onSuccess: res => {
        const data = res.data || null;
        setDataFormBagging({
          typeBag: data?.baggingType,
          barcodeBag: data?.barcodeBagging,
          courier: data?.courierId,
          dateSend: moment(data?.deliveryBaggingTime) || null,
          branchFrom: data?.originalBranchId,
          branchTo: data?.destinationBranchId,
          finishBaggingTime: data?.finishBaggingTime
            ? moment(data?.finishBaggingTime)
            : null,
          couriercode: data?.courier,
          branchFromName: data?.originalBranchName,
          branchToName: data?.destinationBranchName,
          baggingStatusDocument: data?.baggingStatusDocument,
        });
      },
      enabled: !!selectedBagId,
    }
  );

  const { data: dataCountingBag, isFetching: isLoadingCountingBag } = useQuery(
    ['CountBarcodeBag', dataFilterBagging],
    () => getCountingTableBagging({ ...dataFilterBagging }),
    {
      enabled: !!dataFilterBagging.CreatedBaggingTime,
    }
  );

  const { refetch: refetchBarcode } = useQuery<AxiosResponse, Error>(
    ['GetBarcodeBag', dataFormBagging.branchFrom],
    () => getBarcodeBag(dataFormBagging.branchFrom),
    {
      onSuccess: res => {
        handleChangeFormBagging('barcodeBag', res.data);
      },
      enabled:
        !!dataFormBagging.branchFrom &&
        !isDetailForm &&
        !isEditForm &&
        !isShowModalScan,
    }
  );

  const { mutate: deleteBagging, isLoading: isLoadingDelete } = useMutation(
    deleteBaggingDocument,
    {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          // ModalStatus({
          //   status: 'success',
          //   title: 'Data berhasil dihapus',
          // });
          message.success({
            content: 'Data berhasil dihapus',
            duration: 1.5,
          });
          refetch();
        } else {
          // ModalStatus({
          //   status: 'error',
          //   title: 'Gagal Proses',
          //   content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          // });
          message.error({
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
            duration: 1.5,
          });
        }
        setIsDeletedBag(null);
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
        setIsDeletedBag(null);
      },
    }
  );

  const { mutate: submitBaggingForm, isLoading: isLoadingSubmitForm } =
    useMutation(postBaggingDocument, {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          handleCloseModalForm();
          ModalStatus({
            status: 'success',
            title: 'Data berhasil disimpan',
            handleOke: () => {
              setSelectedBagId(res?.data || null);
              setIsShowModalScan(!isShowModalScan);
            },
          });
          refetch();
        } else {
          if (res.response?.data?.Message === 'Barcode sudah pernah diinput.') {
            refetchBarcode();
          }
          ModalStatus({
            status: 'error',
            title: 'Gagal Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const { mutate: putBaggingForm, isLoading: isLoadingPutForm } = useMutation(
    putBaggingDocument,
    {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          refetch();
          handleCloseModalForm();
          ModalStatus({
            status: 'success',
            title: 'Data berhasil diubah',
          });
        } else {
          if (res.response?.data?.Message === 'Barcode sudah pernah diinput.') {
            refetchBarcode();
          }
          ModalStatus({
            status: 'error',
            title: 'Gagal Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    }
  );

  const { mutate: mutateDownloadFile, isLoading: isLoadingDownload } =
    useMutation(downloadBaggingDocument, {
      onSuccess: res => {
        if (res.status === 200) {
          const kodePosko =
            (dataFilterBagging.OriginalBranch &&
              dataAllBranch.find(
                value => value.id === dataFilterBagging.OriginalBranch
              ).uniqueCode) ||
            '';
          const tanggalInput =
            (dataFilterBagging.CreatedBaggingTime &&
              dataFilterBagging.CreatedBaggingTime.format('YYYYMMDD')) ||
            '';
          downloadBlob(
            res.data,
            `BarcodeBag_${kodePosko}${tanggalInput}`,
            'pdf'
          );
          ModalStatus({
            status: 'success',
            title: 'Download berhasil',
          });
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    });

  const { mutate: mutateDownloadList, isLoading: isLoadingDownloadList } =
    useMutation(
      (data: { id: number; barcodeBag: string }) =>
        downloadBaggingDocumentList(data, progressHandler),
      {
        onSuccess: res => {
          if (res.status === 200) {
            downloadBlob(res.data, `ListWaybillBag_${res.barcodeBag}`, 'xlsx');
          } else {
            ModalStatus({
              status: 'error',
              title: 'Terjadi Kesalahan',
              content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
            });
          }
        },
        onError: () => {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
          });
        },
      }
    );

  useEffect(() => {
    if (IdBranch) {
      setDataFormBagging(dataFormBagging => ({
        ...dataFormBagging,
        branchFrom: IdBranch,
      }));
      setDataFilterBagging(dataFilterBagging => ({
        ...dataFilterBagging,
        OriginalBranch: IdBranch,
      }));
    }
  }, [IdBranch]);

  useEffect(() => {
    if (dataAllCourier && dataAllCourier?.data?.length > 0) {
      const courierArr = [] as any;
      dataAllCourier?.data?.forEach(item => {
        courierArr.push({
          id: item.id,
          name: `${item.firstName} (${item.code})`,
        });
      });
      setCourierItems(courierArr);
    } else {
      setCourierItems([]);
    }
  }, [dataAllCourier]);

  useEffect(() => {
    if (
      (dataFormBagging.typeBag === 'Delivery'
        ? dataFormBagging.branchFrom
        : dataFormBagging.branchTo) &&
      !!dataFormBagging.courier
    ) {
      handleChangeFormBagging('courier', null);
    }

    if (
      isEditForm &&
      dataFormBagging.branchFrom !== dataSingleBag?.data.originalBranchId
    ) {
      refetchBarcode();
    }

    if (
      isEditForm &&
      dataFormBagging.branchFrom === dataSingleBag?.data.originalBranchId &&
      dataFormBagging.barcodeBag !== dataSingleBag?.data.barcodeBagging
    ) {
      handleChangeFormBagging('barcodeBag', dataSingleBag?.data.barcodeBagging);
    }

    // eslint-disable-next-line
  }, [
    dataFormBagging.branchFrom,
    dataFormBagging.branchTo,
    dataFormBagging.typeBag,
  ]);

  useEffect(() => {
    if ((isEditForm || isDetailForm) && dataSingleBag?.data) {
      handleChangeFormBagging('courier', dataSingleBag?.data?.courierId);
    }

    // eslint-disable-next-line
  }, [dataSingleBag]);

  useEffect(() => {
    if (loadingProgress === 100) {
      Modal.destroyAll();
      setLoadingProgress(0);
    }
  }, [loadingProgress]);

  const handleChangeFormBagging = (key: string, value: any) => {
    setDataFormBagging(dataFormBagging => ({
      ...dataFormBagging,
      [key]: value,
    }));
  };

  const handleCloseModalForm = () => {
    const isBranch = roleAccount === 'Branch';
    const branchFrom = (isBranch && IdBranch) || null;

    setDataFormBagging(prev => ({
      typeBag: isEditForm ? null : prev.typeBag,
      barcodeBag: isEditForm ? null : prev.barcodeBag,
      dateSend: null,
      courier: null,
      branchFrom,
      branchTo: null,
    }));
    setIsShowModalForm(false);
    setSelectedBagId(null);
    setIsEditForm(false);
    setIsDetailForm(false);
    setIsShowModalScan(false);
  };

  const handleCloseModalScan = () => {
    setDataFormBagging(prev => ({
      typeBag: null,
      barcodeBag: null,
      dateSend: null,
      courier: null,
      branchFrom: prev.branchFrom,
      branchTo: null,
    }));
    setSelectedBagId(null);
    setIsShowModalScan(false);
  };

  const handleSubmitForm = (value: BaggingDocumentFormType) => {
    const data = {
      ...(isEditForm && { id: selectedBagId }),
      baggingType: value.typeBag,
      barcodeBagging: value.barcodeBag,
      courier: value.courier,
      deliveryBaggingTime: value.dateSend,
      originalBranch: value.branchFrom,
      destinationBranch: value.branchTo,
    };
    if (isEditForm) {
      putBaggingForm(data);
      return;
    }
    submitBaggingForm(data);
  };

  const handleSubmitFilter = (value: any) => {
    setDataFilterBagging({
      BaggingTypes: value.typeBag,
      CreatedBaggingTime: value.dateInput,
      BaggingStatusDocument: value.status,
      OriginalBranch: value.branchFrom,
      DestinationBranch: value.branchTo,
    });
  };
  const handleResetFilter = () => {
    setDataFilterBagging({ ...defaultFilter, OriginalBranch: IdBranch });
  };

  const handleDownloadFile = () => {
    const data = {
      baggingTypes: dataFilterBagging.BaggingTypes,
      createdBaggingTime:
        dataFilterBagging.CreatedBaggingTime.format('DDMMYYYY'),
      baggingStatusDocument: dataFilterBagging.BaggingStatusDocument,
      originalBranch: dataFilterBagging.OriginalBranch || 0,
      destinationBranch: dataFilterBagging.DestinationBranch || 0,
      barcodeBagging: dataFilterBagging.BarcodeBagging,
    };
    mutateDownloadFile(data);
  };

  const handleSearchBarcode = (barcode: string) => {
    setDataFilterBagging(dataFilter => ({
      ...dataFilter,
      BarcodeBagging: barcode,
    }));
  };

  const handleEditBagging = (id: number) => {
    setIsShowModalForm(!isShowModalForm);
    setSelectedBagId(id);
    setIsEditForm(true);
  };

  const handleDetailBagging = (id: number) => {
    setSelectedBagId(id);
    setIsDetailForm(true);
  };

  const handleDeleteBagging = (id: number) => {
    setIsDeletedBag(id);
  };

  const handleScanBagging = (id: number) => {
    setIsShowModalScan(!isShowModalScan);
    setSelectedBagId(id);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <BaggingDocumentFilter
        dataAllBranch={dataAllBranch}
        isLoadingBranch={isLoadingBranch}
        roleAccount={roleAccount}
        handleSubmitFilter={handleSubmitFilter}
        handleResetFilter={handleResetFilter}
        dataFilterBagging={dataFilterBagging}
        selectedBranchId={IdBranch}
      />
      <BaggingDocumentTable
        handleShowModalForm={() => {
          setIsShowModalForm(!isShowModalForm);
          refetchBarcode();
        }}
        handleShowModalScan={handleScanBagging}
        handleEditBagging={handleEditBagging}
        handleDetailBagging={handleDetailBagging}
        handleDeleteBagging={handleDeleteBagging}
        DownloadBagTypes={DownloadBagTypes}
        selectedDownloadBag={selectedDownloadBag}
        handleSelectedDownloadBag={value => setSelectedDownloadBag(value)}
        isLoadingTableBag={
          isLoadingTableBag ||
          isLoadingCountingBag ||
          isLoadingDownload ||
          isLoadingDelete ||
          isLoadingDownloadList
        }
        handlePage={value => setPage(value)}
        dataTableBag={dataTableBag?.data}
        dataCountingBag={dataCountingBag?.data}
        handleSearchBarcode={handleSearchBarcode}
        handleDownloadFile={handleDownloadFile}
        dataFilterBagging={dataFilterBagging}
        handleDownloadList={mutateDownloadList}
      />
      <ModalFormBagging
        dataAllBranch={dataAllBranch}
        dataAllCourier={courierItems || []}
        isLoadingBranch={isLoadingBranch}
        isLoadingCourier={isLoadingCourier}
        roleAccount={roleAccount}
        isShowModal={isShowModalForm}
        handleCloseModal={handleCloseModalForm}
        dataFormBagging={dataFormBagging}
        handleChangeFormBagging={handleChangeFormBagging}
        handleSubmitForm={handleSubmitForm}
        isEdit={isEditForm}
        isLoadingSubmitForm={
          isLoadingSubmitForm || isLoadingSingleBagging || isLoadingPutForm
        }
      />
      <ModalDetailBagging
        isShowModal={isDetailForm}
        handleCloseModal={handleCloseModalForm}
        dataFormBagging={dataFormBagging}
        isLoading={isLoadingSingleBagging}
        selectedBagId={selectedBagId}
      />
      <ModalScanWaybil
        isShowModal={isShowModalScan}
        handleCloseModal={handleCloseModalScan}
        selectedBagId={selectedBagId}
        barcodeBag={dataFormBagging?.barcodeBag || ''}
        typeBag={dataFormBagging?.typeBag || ''}
        refetchTable={refetch}
      />
      <ModalConfirmation
        title="Apakah anda yakin?"
        description="Bag yang telah diinput akan dihapus"
        visibleModal={!!isDeletedBag}
        onCancel={() => setIsDeletedBag(null)}
        onConfirm={() => deleteBagging(isDeletedBag)}
        centered
      />
      <ModalDownloadProgress
        loading={isLoadingDownloadList}
        loadingProgress={loadingProgress}
      />
    </div>
  );
}
