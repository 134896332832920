export function setIdCustomReportCustomerCdrc(IdCustomReportCustomer: any) {
  return {
    type: 'SET_ID_CUSTOM_REPORT_CUSTOMER_CDRC',
    IdCustomReportCustomer,
  };
}

export function setHeaderCdrc(Header: any) {
  return {
    type: 'SET_HEADER_CDRC',
    Header,
  };
}
export function setPositionCdrc(Position: any) {
  return {
    type: 'SET_POSITION_CDRC',
    Position,
  };
}
export function setFormatCdrc(Format: any) {
  return {
    type: 'SET_FORMAT_CDRC',
    Format,
  };
}
export function setValueCdrc() {
  return {
    type: 'SET_VALUE_CDRC',
  };
}
export function setValueValueCdrc(Value: any) {
  return {
    type: 'SET_VALUE_VALUE_CDRC',
    Value,
  };
}
export function setValueDefaultCdrc(ValueDefault: any) {
  return {
    type: 'SET_VALUE_DEFAULT_CDRC',
    ValueDefault,
  };
}
export function setSourceTableCdrc(SourceTable: any) {
  return {
    type: 'SET_SOURCE_TABLE_CDRC',
    SourceTable,
  };
}
export function setFormatTypeCdrc(FormatType: any) {
  return {
    type: 'SET_FORMAT_TYPE_CDRC',
    FormatType,
  };
}
export function setHistoryTypeCdrc(HistoryType: any) {
  return {
    type: 'SET_HISTORY_TYPE_CDRC',
    HistoryType,
  };
}
export function setFormatColumnCdrc(FormatColumn: any) {
  return {
    type: 'SET_FORMAT_COLUMN_CDRC',
    FormatColumn,
  };
}
export function changeCharLengthCdrc(CharacterLength: any) {
  return {
    type: 'CHANGE_SETTING_CHARACTER_LENGTH',
    CharacterLength,
  };
}
export function setSourceColumnCdrc(SourceColumn: any) {
  return {
    type: 'SET_SOURCE_COLUMN_CDRC',
    SourceColumn,
  };
}
export function setSourceColumnSecCdrc(SourceColumnSec: any) {
  return {
    type: 'SET_SOURCE_COLUMN_SEC_CDRC',
    SourceColumnSec,
  };
}
export function setDeliveryToCdrc(DeliveryTo: any) {
  return {
    type: 'SET_DELIVERY_TO_CDRC',
    DeliveryTo,
  };
}
export function setSubColumnCdrc(SubColumn: any) {
  return {
    type: 'SET_SUB_COLUMN_CDRC',
    SubColumn,
  };
}
export function setSubColumnSecCdrc(SubColumnSec: any) {
  return {
    type: 'SET_SUB_COLUMN_SEC_CDRC',
    SubColumnSec,
  };
}
export function setReplaceSuccessCdrc(ReplaceSuccess: any) {
  return {
    type: 'SET_REPLACE_SUCCESS_CDRC',
    ReplaceSuccess,
  };
}
export function setReplaceReturnCdrc(ReplaceReturn: any) {
  return {
    type: 'SET_REPLACE_RETURN_CDRC',
    ReplaceReturn,
  };
}
export function setReplaceOnProcessCdrc(ReplaceOnProcess: any) {
  return {
    type: 'SET_REPLACE_ON_PROCESS_CDRC',
    ReplaceOnProcess,
  };
}
export function setReplaceOnHoldCdrc(ReplaceOnHold: any) {
  return {
    type: 'SET_REPLACE_ON_HOLD_CDRC',
    ReplaceOnHold,
  };
}
export function setCheckBoxPetikCdrc(CheckBoxPetik: any) {
  return {
    type: 'SET_CHECKBOX_PETIK_CDRC',
    CheckBoxPetik,
  };
}

export function setCheckBoxOthersCdrc(CheckBoxOthers: any) {
  return {
    type: 'SET_CHECKBOX_OTHERS_CDRC',
    CheckBoxOthers,
  };
}

export function setListSourceColumnCdrc(ListSourceColumn: any) {
  return {
    type: 'SET_LIST_COLUMN_CDRC',
    ListSourceColumn,
  };
}

export function setListSourceColumnSecCdrc(ListSourceColumnSec: any) {
  return {
    type: 'SET_LIST_COLUMN_SEC_CDRC',
    ListSourceColumnSec,
  };
}

export function setListSubColumnCdrc(ListSubColumn: any) {
  return {
    type: 'SET_LIST_SUB_COLUMN_CDRC',
    ListSubColumn,
  };
}

export function setListSubColumnSecCdrc(ListSubColumnSec: any) {
  return {
    type: 'SET_LIST_SUB_COLUMN_SEC_CDRC',
    ListSubColumnSec,
  };
}

export function getListColumnMasterCustomReportCustomer() {
  return {
    type: 'GET_LIST_COLUMN_MASTER_CUSTOM_REPORT_CUSTOMER',
  };
}
export function getListColumnHistoryCustomReportCustomer() {
  return {
    type: 'GET_LIST_COLUMN_HISTORY_CUSTOM_REPORT_CUSTOMER',
  };
}
export function getListColumnSecMasterCustomReportCustomer() {
  return {
    type: 'GET_LIST_COLUMN_SEC_MASTER_CUSTOM_REPORT_CUSTOMER',
  };
}
export function getListColumnSecHistoryCustomReportCustomer() {
  return {
    type: 'GET_LIST_COLUMN_SEC_HISTORY_CUSTOM_REPORT_CUSTOMER',
  };
}
export function clearAllCdrc() {
  return {
    type: 'CLEAR_ALL_CDRC',
  };
}
export function clearOnClickValueCdrc() {
  return {
    type: 'CLEAR_ON_CLICK_VALUE_CDRC',
  };
}
export function clearSourceTableCdrc() {
  return {
    type: 'CLEAR_SOURCE_TABLE_CDRC',
  };
}
export function clearListColumnCustomReportCustomer() {
  return {
    type: 'CLEAR_LIST_COLUMN_CUSTOM_REPORT_CUSTOMER',
  };
}
export function clearFieldAllCustomReportCustomer() {
  return {
    type: 'CLEAR_FIELD_ALL_CUSTOM_REPORT_CUSTOMER',
  };
}
export function clearSubColumnCdrc() {
  return {
    type: 'CLEAR_SUB_COLUMN_CDRC',
  };
}
export function clearSubColumnSecCdrc() {
  return {
    type: 'CLEAR_SUB_COLUMN_SEC_CDRC',
  };
}
export function clearFormatColumnCdrc() {
  return {
    type: 'CLEAR_FORMAT_COLUMN_CDRC',
  };
}
export function getDetailCdrc() {
  return {
    type: 'GET_DETAIL_CDRC',
  };
}
export function submitAddCdrc() {
  return {
    type: 'SUBMIT_ADD_CDRC',
  };
}
export function submitEditCdrc() {
  return {
    type: 'SUBMIT_EDIT_CDRC',
  };
}
