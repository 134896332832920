export function changeDetailCustomerProduct() {
  return {
    type: 'CHANGE_SET_DETAIL_CUSTOMER_PRODUCT',
  };
}
export function clearAllCustomerProduct() {
  return {
    type: 'CLEAR_ALL_CUSTOMER_PRODUCT',
  };
}
export function clearDetailCustomerProduct() {
  return {
    type: 'CLEAR_DETAIL_CUSTOMER_PRODUCT',
  };
}
