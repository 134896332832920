import * as ActionComplaint from '../Store/ComplaintAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionGeneralData from '../../App/Store/GeneralDataAction';
import * as SelectorsComplaint from '../Selector/ComplaintSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsGeneralData from '../../App/Selector/GeneralDataSelector';
import { removeNotificationMobile } from 'lib/util';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { change, getFormSyncErrors, getFormValues } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { updateReviewComplaint } from 'services/ComplaintServices';
import ModalStatus from 'materials/ModalStatus';

import DetailComplaintComponent from '../Component/DetailComplaintComponent';
import { createStructuredSelector } from 'reselect';
import { complaintStatusObj } from 'apps/enums';
import { getObjectKey } from 'lib/util';

// import { ref,remove } from 'firebase/database';

// import { getDb } from '../../Config/firebaseConfig';

function DetailComplaintContainer(props) {
  const {
    complaintAction,
    componentAction,
    complaintDetailDoc,
    generalDataAction,
    formValueDetailForm,
    // countNotifValue,
    formValue,
  } = props;
  const dispatch = useDispatch();

  const [descValueState, setDescValue] = useState(null);
  const [imgValueState, setImgValue] = useState(null);
  const [signatureValueState, setSignatureValue] = useState(null);
  const [showModalReview, setShowModalReview] = useState<boolean>(false);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [answer, setAnswer] = useState('');

  const { mutate: updateReview, isLoading: isLoadingUpdateReview } =
    useMutation(updateReviewComplaint, {
      onSuccess: res => {
        setShowModalReview(!showModalReview);
        setShowModalConfirm(!showModalConfirm);
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Berhasil menyimpan review',
          });
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        setShowModalReview(!showModalReview);
        setShowModalConfirm(!showModalConfirm);
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    });

  /**
   =Tipe form redux form=

    => showType : untuk menampilkan detail tiket investigasi yang sudah dibuat (trigger di table button kolom aksi)
      - edit = tampilan detail tiket investigasi (bisa untuk mengubah status)
      - detail = tampilan detail tiket investigasi (hanya untuk view)

    => modalType : tipe modal yang ingin ditampilkan
      - assign = tampilan untuk menugaskan kurir (status Proses & Dalam Antrian)
      - edit = untuk membedakan tampilan popup pada detail investigasi 
              status Proses (button Proses) & status Dalam Antrian (button Ubah)
      - showImg = menampilkan image pada riwayat
      - showSignature = menampilkan ttd pada riwayat

    => formType : untuk trigger tampilan "Create Ticket"
      - inputData = tampilan pengisian form ketika ingin membuat tiket investigasi
      - assignCourier = tampilan penugasan kurir setelah mengisi form investigasi
      - changeCourier = tampilan penugasan kurir ketika ingin mengubah kurir investigasi
      - inQueueAssign = tampilan penugasan kurir ketika ingin menugaskan kurir investigasi dengan status "Dalam Antrian"
   */
  const handleConvertBase65 = (file: any) => {
    return new Promise(resolve => {
      let baseURL;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const handleConvert = (e: any) => {
    handleConvertBase65(e)
      .then((result: any) => {
        e['base64'] = result.toString().split(',')[1];
        complaintAction.changeInvestigationFile(result.split(',')[1]);
      })
      .catch(error => {
        console.log(error);
      });
  };
  const handleBack = () => {
    complaintAction.clearDetailDocComplaint();
    componentAction.setPagePagination(0);
    componentAction.setTotalPagePagination(1);
    dispatch(change('complaintForm', 'showType', null));
    dispatch(change('complaintForm', 'formType', null));
  };

  const handleEdit = () => {
    if (
      complaintDetailDoc.complaintType ===
      getObjectKey(complaintStatusObj, complaintStatusObj.DalamAntrian)
    ) {
      generalDataAction.getBranchList();
      complaintAction.getCourierComplaint();
      dispatch(
        change(
          'detailFormComplaint',
          'branchInvestigate',
          complaintDetailDoc.branch
        )
      );
      dispatch(
        change(
          'detailFormComplaint',
          'courierInvestigate',
          complaintDetailDoc.courier
        )
      );
      dispatch(change('complaintForm', 'modalType', 'assign'));
      dispatch(change('complaintForm', 'formType', 'inQueueAssign'));
      componentAction.openModal('AssignCourierComplaint');
    } else {
      dispatch(change('complaintForm', 'modalType', 'edit'));
      componentAction.openModal('ComplaintEdit');
    }
  };
  const handleDownload = () => {
    complaintAction.downloadComplaint();
  };
  const handleCloseModal = () => {
    componentAction.openModal('ComplaintEdit');
    complaintAction.clearComplaintDetailForm();
    dispatch(change('complaintForm', 'modalType', null));
  };
  const handleCloseModalAssignCourier = () => {
    // componentAction.openModal('ComplaintEdit');
    // complaintAction.clearComplaintDetailForm();
    complaintAction.clearComplaintDetailForm();
    componentAction.openModal('AssignCourierComplaint');
    dispatch(change('complaintForm', 'modalType', null));
    dispatch(change('complaintForm', 'formType', null));
  };
  const handleDetailDesc = desc => {
    dispatch(change('complaintForm', 'modalType', 'showDesc'));
    componentAction.openModal('ComplaintEdit');
    setDescValue(desc);
  };
  const handleLinkImage = img => {
    dispatch(change('complaintForm', 'modalType', 'showImg'));
    componentAction.openModal('ComplaintEdit');
    setImgValue(img);
  };
  const handleLinkSignature = signature => {
    dispatch(change('complaintForm', 'modalType', 'showSignature'));
    componentAction.openModal('ComplaintEdit');
    setSignatureValue(signature);
  };
  const handleSubmit = () => {
    if (
      complaintDetailDoc.complaintType ===
      getObjectKey(complaintStatusObj, complaintStatusObj.DalamAntrian)
    ) {
      complaintAction.submitComplaintInQueueToProcess();
    } else {
      if (formValue.formType === 'inputData') {
        dispatch(change('complaintForm', 'formType', 'assignCourier'));
      } else if (formValue.formType === 'changeCourier') {
        complaintAction.submitChangeCourierInvestigate();
      } else {
        complaintAction.submitProcessToCustomerConfirm();
      }
    }
  };
  const handleClearCourier = () => {
    if (!formValueDetailForm.branchInvestigate === false) {
      complaintAction.getCourierComplaint();
    }
    dispatch(change('detailFormComplaint', 'courierInvestigate', null));
  };
  const handleChangeCourier = () => {
    generalDataAction.getBranchList();
    complaintAction.getCourierComplaint();
    dispatch(
      change(
        'detailFormComplaint',
        'branchInvestigate',
        complaintDetailDoc.branch
      )
    );
    dispatch(
      change(
        'detailFormComplaint',
        'courierInvestigate',
        complaintDetailDoc.courier
      )
    );
    dispatch(change('complaintForm', 'modalType', 'assign'));
    dispatch(change('complaintForm', 'formType', 'changeCourier'));
    componentAction.openModal('AssignCourierComplaint');
  };

  const handleSubmitReview = async (values: any) => {
    const { resultInvestigationAdmin, image, signature } = values;
    const data = {
      id: complaintDetailDoc.id,
      answer: resultInvestigationAdmin,
      image,
      signature,
    };
    removeNotificationMobile(complaintDetailDoc.waybil);
    updateReview(data);
  };

  const handleLinkLocation = (latitude: string, longitude: string) => {
    const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(googleMapsLink, '_blank');
  };
  return (
    <React.Fragment>
      <DetailComplaintComponent
        handleBack={handleBack}
        handleDownload={handleDownload}
        handleEdit={handleEdit}
        handleChangeCourier={handleChangeCourier}
        handleLinkImage={handleLinkImage}
        handleLinkSignature={handleLinkSignature}
        handleCloseModal={handleCloseModal}
        handleCloseModalAssignCourier={handleCloseModalAssignCourier}
        handleConvert={handleConvert}
        handleSubmit={handleSubmit}
        handleClearCourier={handleClearCourier}
        imgValueState={imgValueState}
        signatureValueState={signatureValueState}
        handleDetailDesc={handleDetailDesc}
        descValueState={descValueState}
        showModalReview={showModalReview}
        showModalConfirm={showModalConfirm}
        handleShowModalReview={() => {
          setShowModalReview(!showModalReview);
          setAnswer('');
        }}
        handleShowModalConfirm={() => setShowModalConfirm(!showModalConfirm)}
        handleSubmitReview={handleSubmitReview}
        isLoadingUpdateReview={isLoadingUpdateReview}
        answer={answer}
        handleChangeAnswer={value => setAnswer(value)}
        handleLinkLocation={handleLinkLocation}
        {...props}
      />
    </React.Fragment>
  );
}
const mapStateToProps = () =>
  createStructuredSelector({
    complaintDetailDoc: SelectorsComplaint.makeComplaintDetailDocSelector(),
    editComplaintModalIsShow: SelectorsComponent.editComplaintModalIsShow(),
    assignCourierComplaintModalIsShow:
      SelectorsComponent.assignCourierComplaintModalIsShow(),
    courierInvestigationList:
      SelectorsComplaint.makeCourierInvestigationSelector(),
    branchList: SelectorsGeneralData.makeBranchListSelector(),
    isLoading: SelectorsComponent.makeIsLoading(),
    formValue: getFormValues('complaintForm'),
    formValueDetailForm: getFormValues('detailFormComplaint'),
    errorFormValue: getFormSyncErrors('detailFormComplaint'),
  });
const mapDispatchToProps = dispatch => ({
  complaintAction: bindActionCreators(ActionComplaint, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
  generalDataAction: bindActionCreators(ActionGeneralData, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(DetailComplaintContainer);
