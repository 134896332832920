import React, { useEffect } from 'react';
import { getTabMenus } from 'lib/util';

import EmergencyProcessContainer from '../../../containers/Content/ManualProcess/Emergency/EmergencyProcessContainer';
import ManualProcessContainer from '../../../containers/Content/ManualProcess/Internal/ManualProcessContainer';
import ManualProcessVendorContainer from '../../../containers/Content/ManualProcess/Vendor/ManualProcessVendorContainer';
import { Tabs } from 'antd';
import UpdateAddressContainer from '../../../containers/Content/ManualProcess/UpdateAddress/UpdateAddressContainer';
import SyncDataContainer from 'containers/Content/ManualProcess/SyncData/Container/SyncDataContainer';

const { TabPane } = Tabs;

interface IProps {
  handleChangeTabs: any;
  lastAccessFiture: any;
  sideMenus: any;
}

const IndexManualProcessComponent = ({
  handleChangeTabs,
  lastAccessFiture,
  sideMenus,
}: IProps) => {
  useEffect(() => {
    const handleChange = () => {
      if (lastAccessFiture === 'deliveryManual') {
        handleChangeTabs('1');
      } else if (lastAccessFiture === 'deliveryVendor') {
        handleChangeTabs('2');
      } else if (lastAccessFiture === 'deliveryEmergency') {
        handleChangeTabs('3');
      } else if (lastAccessFiture === 'deliveryUpdateAddress') {
        handleChangeTabs('4');
      } else if (lastAccessFiture === 'deliverySyncData') {
        handleChangeTabs('5');
      }
    };
    if (lastAccessFiture !== null) {
      handleChange();
    }
  }, [lastAccessFiture, handleChangeTabs]);

  const tabMenus = getTabMenus(sideMenus, 'Pengiriman', 'Proses Manual');
  // const tabMenus = [
  //   {
  //     label: 'Scan Selesai',
  //     link: null,
  //     position: 1,
  //   },
  //   {
  //     label: 'Unggah Selesai',
  //     link: null,
  //     position: 2,
  //   },
  //   {
  //     label: 'Emergency',
  //     link: null,
  //     position: 3,
  //   },
  //   {
  //     label: 'Perbaharui Alamat',
  //     link: null,
  //     position: 4,
  //   },
  //   {
  //     label: 'Sync Data API',
  //     link: null,
  //     position: 5,
  //   },
  // ];

  let a = '1';
  if (lastAccessFiture === 'deliverySyncData') {
    a = '5';
  } else if (lastAccessFiture === 'deliveryUpdateAddress') {
    a = '4';
  } else if (lastAccessFiture === 'deliveryEmergency') {
    a = '3';
  } else if (lastAccessFiture === 'deliveryVendor') {
    a = '2';
  } else {
    a = '1';
  }

  return (
    <div>
      <Tabs
        style={{
          paddingRight: '35px',
          paddingLeft: '35px',
          paddingTop: '35px',
        }}
        defaultActiveKey={a}
        activeKey={a}
        onChange={handleChangeTabs}
      >
        {tabMenus.map((item: any, index: any) => (
          <TabPane tab={<b>{item.label}</b>} key={index + 1}>
            {item.label === 'Scan Selesai' ? (
              <ManualProcessContainer />
            ) : item.label === 'Unggah Selesai' ? (
              <ManualProcessVendorContainer />
            ) : item.label === 'Emergency' ? (
              <EmergencyProcessContainer />
            ) : item.label === 'Perbaharui Alamat' ? (
              <UpdateAddressContainer />
            ) : item.label === 'Sync Data API' ? (
              <SyncDataContainer />
            ) : null}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default IndexManualProcessComponent;
