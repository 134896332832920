import { Form, Input, InputNumber, Select, Switch } from 'antd';
import React, { useState } from 'react';

import CIcon from './CIcon';

interface IProps {
  input: any;
  idComponent: any;
  placeholder?: any;
  meta: any;
  label?: any;
  disabled?: any;
  formClassName?: any;
  formStyle?: any;
  addtionalLabel?: any;
}

interface IPropsText extends IProps {
  icon?: any;
  className?: any;
  disableOnChange?: any;
  textArea?: any;
  classNameLabel?: any;
  maxlength?: any;
  additionalValue?: any;
  addonBefore?: any;
  additionalHandleEnterFunc?: any;
  regex?: any;
  optional?: boolean;
}
interface IPropsFile extends IProps {
  icon?: any;
  className?: any;
  disableOnChange?: any;
  textArea?: any;
  classNameLabel?: any;
  maxlength?: any;
  additionalValue?: any;
  multiple?: any;
  accept?: any;
  additionalHandleChangeFunc?: any;
}

interface IPropsTextNumber extends IProps {
  icon?: any;
  min?: any;
  max?: any;
  defaultValue?: any;
}

interface IPropsSelect extends IProps {
  icon?: any;
  data: any;
  dafaultItemName: any;
  loading?: any;
  mode?: any;
  additionalHandleChangeFunc?: any;
  additionalHandleOnFocusFunc?: any;
  combineValue?: any;
  disabledByKey?: any;
  disabledByKeyNegation?: any;
  className?: any;
  customOption?: any;
  optional?: boolean;
}

const defaultStyle = { color: 'rgba(0,0,0,.25)' };
const defaultStyleInput = { width: '100%' };
const { Search } = Input;

export const InputText = ({
  input,
  meta: { touched, error },
  placeholder,
  idComponent,
  icon,
  label,
  disabled,
  disableOnChange,
  classNameLabel,
  className,
  textArea,
  formClassName,
  maxlength,
  additionalValue,
  addtionalLabel,
  addonBefore,
  additionalHandleEnterFunc,
  optional = false,
}: IPropsText) => {
  const handleOnChange = (e: any) => input.onChange(e.target.value);
  if (additionalValue !== undefined) {
    input.onChange(additionalValue);
  }
  const labelTitle = label ? (
    <span>
      <b className={`capital ${classNameLabel}`}>{label}</b>
      <span style={{ color: '#BFBFBF' }}>
        {addtionalLabel ? ` ${addtionalLabel}` : null}
      </span>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={
        !optional && touched && error !== undefined ? 'error' : ''
      }
      help={!optional && touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
      className={formClassName}
    >
      {textArea ? (
        <Input.TextArea
          placeholder={placeholder}
          onChange={handleOnChange}
          onBlur={input.onBlur}
          id={idComponent}
          value={input.value}
          autoSize={{ minRows: 4 }}
          disabled={disabled}
          className={className}
          maxLength={maxlength}
        />
      ) : (
        <Input
          placeholder={placeholder}
          prefix={icon ? <CIcon type={icon} style={defaultStyle} /> : null}
          addonBefore={addonBefore}
          onChange={disableOnChange ? undefined : handleOnChange}
          onBlur={input.onBlur}
          id={idComponent}
          value={input.value}
          disabled={disabled}
          className={className}
          maxLength={maxlength}
          onPressEnter={additionalHandleEnterFunc}
        />
      )}
    </Form.Item>
  );
};

export const InputFile = ({
  input,
  meta: { dirty, error },
  label,
  addtionalLabel,
  idComponent,
  disabled,
  className,
  formClassName,
  multiple,
  accept,
  additionalHandleChangeFunc,
}: IPropsFile) => {
  const handleOnChange = (e: any) => {
    input.onChange(e.target.value);
    additionalHandleChangeFunc(e.target.files[0]);
  };
  const labelTitle = label ? (
    <span>
      <b>{label}</b>
      <span style={{ color: '#BFBFBF' }}>
        {addtionalLabel ? ` ${addtionalLabel}` : null}
      </span>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={dirty && error !== undefined ? 'error' : ''}
      help={dirty && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
      className={formClassName}
    >
      <Input
        suffix={<CIcon type={'upload'} />}
        onChange={handleOnChange}
        id={idComponent}
        value={input.value}
        disabled={disabled}
        className={className}
        multiple={multiple}
        type="file"
        accept={accept}
      />
    </Form.Item>
  );
};

export const TextInputSearch = ({
  input,
  placeholder,
  idComponent,
  disabled,
  className,
  label,
  additionalHandleEnterFunc,
  regex,
}: IPropsText) => {
  const labelTitle = label ? (
    <span>
      <b>{label}</b>
    </span>
  ) : null;
  const handleOnChange = (e: any) =>
    input.onChange(
      regex === 'noSpaceInFirstChar'
        ? e.target.value.replace(/^\s/, '')
        : e.target.value
    );
  return (
    <div>
      <span className="titleSearchCard">{labelTitle}</span>
      <Input.Search
        placeholder={placeholder}
        onChange={handleOnChange}
        onPressEnter={additionalHandleEnterFunc}
        onBlur={input.onBlur}
        id={idComponent}
        value={input.value}
        disabled={disabled}
        // style={{ borderRadius: '10px', width: '50%' }}
        className={className}
      />
    </div>
  );
};

export const TextNumberInput = ({
  input,
  placeholder,
  idComponent,
  icon,
  min,
  max,
  defaultValue,
  disabled,
}: IPropsTextNumber) => {
  return (
    <InputNumber
      style={{ width: '100%' }}
      placeholder={placeholder}
      prefix={icon ? <CIcon type={icon} style={defaultStyle} /> : null}
      id={idComponent}
      min={min}
      max={max}
      defaultValue={defaultValue}
      disabled={disabled}
      {...input}
    />
  );
};
export const InputTextNumber = ({
  input,
  placeholder,
  meta: { touched, error },
  idComponent,
  icon,
  label,
  min,
  max,
  defaultValue,
  disabled,
}: IPropsTextNumber) => {
  const labelTitle = label ? (
    <span>
      <b className="capital">{label}</b>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <InputNumber
        style={defaultStyleInput}
        placeholder={placeholder}
        prefix={icon ? <CIcon type={icon} style={defaultStyle} /> : null}
        id={idComponent}
        min={min}
        max={max}
        defaultValue={defaultValue}
        disabled={disabled}
        {...input}
      />
    </Form.Item>
  );
};
export const InputSwitch = ({
  input,
  meta: { touched, error },
  idComponent,
  label,
  disabled,
}: IProps) => {
  const labelTitle = label ? (
    <span>
      <b className="capital">{label}</b>
    </span>
  ) : null;
  const handleOnChange = (e: any) => {
    input.onChange(e);
  };
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <Switch
        defaultChecked={false}
        disabled={disabled}
        checked={input.value === '' ? false : input.value}
        onChange={handleOnChange}
      />
    </Form.Item>
  );
};
export const InputSelect = ({
  input,
  meta: { touched, error },
  data,
  mode,
  placeholder,
  dafaultItemName,
  idComponent,
  label,
  additionalHandleChangeFunc,
  additionalHandleOnFocusFunc,
  disabled,
  combineValue,
  disabledByKey,
  disabledByKeyNegation,
  className,
  formClassName,
  formStyle,
  customOption,
  loading,
  addtionalLabel,
}: IPropsSelect) => {
  const items = data.map((item: any, key: any) => {
    return (
      <Select.Option
        key={`${item.id}${key}`}
        value={item.id}
        optionfilterprop="children"
        disabled={
          disabledByKey
            ? disabledByKeyNegation === false
              ? item[disabledByKey]
              : !item[disabledByKey]
            : false
        }
      >
        {combineValue
          ? `${item[combineValue[1]]} ${combineValue[0]} ${
              item[combineValue[2]]
            }`
          : item.name}
      </Select.Option>
    );
  });

  const optionLabelAndCode = data.map((item: any, key: any) => {
    return (
      <Select.Option optionfilterprop="children" value={item.id}>
        {item.firstName}
        {` (${item.code})`}
      </Select.Option>
    );
  });
  const [count, setcount] = useState(0);

  const handleOnChange = (e, label) => {
    input.onChange(e);
    setcount(count + 1);
  };
  const labelTitle = (
    <span>
      <b>{label}</b>
      <span style={{ color: '#BFBFBF' }}>
        {addtionalLabel ? ` ${addtionalLabel}` : null}
      </span>
    </span>
  );

  return (
    <Form.Item
      validateStatus={
        (count > 0 || input.value) && error !== undefined ? 'error' : ''
      }
      help={(count > 0 || input.value) && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
      id={idComponent}
      className={formClassName}
      style={formStyle}
    >
      <Select
        placeholder={placeholder}
        onChange={handleOnChange}
        // onBlur={input.onBlur}
        loading={loading}
        mode={mode}
        onSelect={additionalHandleChangeFunc}
        onFocus={additionalHandleOnFocusFunc}
        id={idComponent}
        value={input.value}
        style={defaultStyleInput}
        disabled={disabled}
        className={className}
        showSearch={true}
        optionFilterProp="children"
      >
        {dafaultItemName ? (
          <Select.Option value="">{dafaultItemName}</Select.Option>
        ) : (
          ''
        )}
        {customOption === 'labelAndCode' ? optionLabelAndCode : items}
      </Select>
    </Form.Item>
  );
};
export const InputSelectPrefix = ({
  input,
  data,
  dafaultItemName,
  idComponent,
  additionalHandleChangeFunc,
  disabled,
  combineValue,
  disabledByKey,
  disabledByKeyNegation,
  className,
  customOption,
}: IPropsSelect) => {
  const items = data.map((item: any, key: any) => {
    return (
      <Select.Option
        key={`${item.id}${key}`}
        value={item.id}
        optionfilterprop="children"
        disabled={
          disabledByKey
            ? disabledByKeyNegation === false
              ? item[disabledByKey]
              : !item[disabledByKey]
            : false
        }
      >
        {combineValue
          ? `${item[combineValue[1]]} ${combineValue[0]} ${
              item[combineValue[2]]
            }`
          : item.name}
      </Select.Option>
    );
  });

  const optionLabelAndCode = data.map((item: any, key: any) => {
    return (
      <Select.Option optionfilterprop="children" value={item.id}>
        {item.firstName}
        {` (${item.code})`}
      </Select.Option>
    );
  });

  const handleOnChange = (e: any) => {
    input.onChange(e);
  };

  return (
    <Select
      onChange={handleOnChange}
      // onBlur={input.onBlur}
      optionfilterprop="children"
      onSelect={additionalHandleChangeFunc}
      id={idComponent}
      value={input.value}
      style={defaultStyleInput}
      disabled={disabled}
      className={className}
    >
      <Select.Option value="">{dafaultItemName}</Select.Option>
      {customOption === 'labelAndCode' ? optionLabelAndCode : items}
    </Select>
  );
};

export const InputPassword = ({
  input,
  meta: { touched, error },
  idComponent,
  icon,
  label,
  placeholder,
  disabled,
}: IPropsText) => {
  const handleOnChange = (e: any) => input.onChange(e.target.value);
  const labelTitle = label ? (
    <span>
      <b className="capital">{label}</b>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <Input.Password
        prefix={icon ? <CIcon type={icon} style={defaultStyle} /> : null}
        onChange={handleOnChange}
        onBlur={input.onBlur}
        id={idComponent}
        value={input.value}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Form.Item>
  );
};
export const InputSearch = ({
  input,
  meta: { touched, error },
  placeholder,
  idComponent,
  icon,
  label,
  disabled,
  disableOnChange,
  classNameLabel,
  className,
  formClassName,
  maxlength,
  additionalValue,
  addtionalLabel,
  addonBefore,
}: IPropsText) => {
  const handleOnChange = (e: any) => input.onChange(e.target.value);
  if (additionalValue !== undefined) {
    input.onChange(additionalValue);
  }
  const labelTitle = label ? (
    <span>
      <b className={classNameLabel}>{label}</b>
      <span style={{ color: '#BFBFBF' }}>
        {addtionalLabel ? ` ${addtionalLabel}` : null}
      </span>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
      className={formClassName}
    >
      <Search
        placeholder={placeholder}
        prefix={icon ? <CIcon type={icon} style={defaultStyle} /> : null}
        addonBefore={addonBefore}
        onChange={disableOnChange ? undefined : handleOnChange}
        onBlur={input.onBlur}
        id={idComponent}
        value={input.value}
        disabled={disabled}
        className={className}
        maxLength={maxlength}
      />
    </Form.Item>
  );
};
