import React from 'react';
import { Row, Col, Icon, Checkbox, Popconfirm, Tooltip } from 'antd';
import { DataTableResProps } from 'interface/general';

import styles from './ModalScanManifestBrankas.module.css';
import ModalMaterial from 'materials/ModalMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import { InputText } from 'components/InputField';
import { SectionInfo } from 'materials/SectionInfo';
import CollapseListWaybilManifest from '../CollapseWaybillManifest';
import {
  DetailManifestBrankasProps,
  CountingManifestBrankasProps,
} from 'interface/general';
import { MANIFEST_BRANKAS_DOWNLOAD_TYPE } from 'lib/constants';

type ModalScanManifestBrankasComponentProps = {
  isShowModalScan: number | null;
  setIsShowModalScan: () => void;
  waybill: string;
  setWaybill: (value: string) => void;
  successWaybill: string;
  isSuccess: boolean;
  isBarcode: boolean;
  setIsSuccess: (value: boolean) => void;
  setIsBarcode: (value: boolean) => void;
  setPage: (value: number) => void;
  handleEnterWaybil: (value: string) => void;
  selectedWaybill: string | null;
  setSelectedWaybill: (value: string) => void;
  handleDeleteWaybil: (id: number) => void;
  dataTableScan: DataTableResProps;
  isLoadingTableScan: boolean;
  isLoadingDetail: boolean;
  dataManifest: DetailManifestBrankasProps | undefined;
  dataCounting: CountingManifestBrankasProps | undefined;
  handleDownloadManifestScan: (type: string) => void;
};

export const PillScanDownload = ({
  background,
  label,
  tooltip,
  subTotal = 0,
  total = 0,
  onOkee = () => undefined,
}: {
  background: string;
  label: string;
  tooltip: string;
  subTotal?: number;
  total?: number;
  onOkee?: () => void;
}) => {
  return (
    <Popconfirm
      title={
        <div className={styles.wrapperPopPill}>
          <img alt="" src="/sms_failed.svg" />
          <p>Download daftar {label === 'Total' ? 'Total Manifest' : label}?</p>
        </div>
      }
      icon={null}
      okText="Ya"
      cancelText="Tidak"
      overlayClassName="manifest-brankas-overlay"
      onConfirm={onOkee}
    >
      <Tooltip title={tooltip}>
        <div className={styles.wrapperPillScan} style={{ background }}>
          <span className={styles.labelPill}>{label}:</span>
          <div>
            <b>{subTotal}</b>
            {total ? `/${total}` : null}
          </div>
        </div>
      </Tooltip>
    </Popconfirm>
  );
};

export default function ModalScanManifestBrankasComponent({
  setIsShowModalScan,
  waybill,
  setWaybill,
  successWaybill,
  isSuccess,
  isBarcode,
  setIsBarcode,
  setIsSuccess,
  setPage,
  handleEnterWaybil,
  selectedWaybill,
  setSelectedWaybill,
  isShowModalScan,
  dataTableScan,
  isLoadingTableScan = false,
  isLoadingDetail = false,
  dataManifest,
  dataCounting,
  handleDeleteWaybil,
  handleDownloadManifestScan,
}: ModalScanManifestBrankasComponentProps) {
  const isFull =
    (dataCounting &&
      dataCounting?.totalAlreadyScannedBundle >=
        dataCounting?.maxBundleScanned) ||
    false;
  return (
    <ModalMaterial
      teksTitle="Scan Waybill"
      width="70%"
      visible={!!isShowModalScan}
      onCancel={setIsShowModalScan}
    >
      <SpinMaterial spinning={isLoadingDetail} size={'large'}>
        <div style={{ marginTop: 24, padding: '0 24px' }}>
          <div className={styles.wrapperFilter}>
            <Row gutter={24}>
              <Col md={12} className={styles.filterColumn}>
                <SectionInfo
                  label="Pelanggan"
                  value={dataManifest?.customerName}
                />
                <div className={styles.wrapperInputScan}>
                  <InputText
                    label={isBarcode ? 'Barcode' : 'Waybill'}
                    placeholder={isBarcode ? 'Scan Barcode' : 'Scan Waybill'}
                    formStyle={{ margin: 0, width: '100%' }}
                    onChange={value => setWaybill(value)}
                    value={waybill}
                    additionalHandleEnterFunc={e =>
                      handleEnterWaybil(e.target.value)
                    }
                    maxlength={isBarcode ? 100 : 16}
                    disabled={isFull}
                  />
                  <Checkbox
                    className={styles.checkboxBarcode}
                    value={isBarcode}
                    onChange={() => {
                      setIsBarcode(!isBarcode);
                      setIsSuccess(false);
                    }}
                    disabled={isFull}
                  >
                    Barcode
                  </Checkbox>
                </div>
              </Col>
              <Col md={12} className={styles.filterColumn}>
                <SectionInfo
                  label="Kode Bundel"
                  value={dataManifest?.bundleCode}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={12}>
                <PillScanDownload
                  label="Di Scan User"
                  tooltip="Download Daftar Di Scan User"
                  total={dataCounting?.maxBundleScanned}
                  background="#11BEFF"
                  subTotal={dataCounting?.totalAlreadyScannedBundle}
                  onOkee={() =>
                    handleDownloadManifestScan(
                      MANIFEST_BRANKAS_DOWNLOAD_TYPE.ScanUser
                    )
                  }
                />
              </Col>
              <Col md={12} className={styles.wrapperPill}>
                <PillScanDownload
                  label="Sudah diScan"
                  tooltip="Download Daftar Sudah Di Scan"
                  background="#00AB44"
                  subTotal={dataCounting?.totalAlreadyScannedCycle}
                  onOkee={() =>
                    handleDownloadManifestScan(
                      MANIFEST_BRANKAS_DOWNLOAD_TYPE.Scan
                    )
                  }
                />
                <PillScanDownload
                  label="Belum diScan"
                  tooltip="Download Daftar Belum Di Scan"
                  background="#ECA722"
                  subTotal={dataCounting?.totalNotScannedCycle}
                  onOkee={() =>
                    handleDownloadManifestScan(
                      MANIFEST_BRANKAS_DOWNLOAD_TYPE.UnScan
                    )
                  }
                />
                <PillScanDownload
                  label="Total"
                  tooltip="Download Total Semua Manifest"
                  background="#A52A2A"
                  subTotal={dataCounting?.totalDataCycle}
                  onOkee={() =>
                    handleDownloadManifestScan(
                      MANIFEST_BRANKAS_DOWNLOAD_TYPE.Total
                    )
                  }
                />
              </Col>
            </Row>

            <div className={styles.alertInfo}>
              {isFull && (
                <p className={styles.errorWaybil}>
                  <Icon type="info-circle" />
                  <span>Bundel sudah penuh</span>
                </p>
              )}
              {isSuccess && successWaybill && (
                <p className={styles.successWaybil}>
                  <Icon type="check-circle" theme="filled" />
                  <span style={{ width: '-webkit-fill-available' }}>
                    {isBarcode ? 'Barcode' : 'Waybill'} <b>{successWaybill}</b>{' '}
                    berhasil di-scan
                  </span>
                </p>
              )}
            </div>

            <CollapseListWaybilManifest
              selectedId={isShowModalScan}
              dataTableScan={dataTableScan}
              mutateDeleteWaybil={handleDeleteWaybil}
              isLoadingTableScan={isLoadingTableScan}
              isLoadingDelete={false}
              selectedWaybil={selectedWaybill}
              setSelectedWaybiil={setSelectedWaybill}
              setPage={setPage}
            />
          </div>
        </div>
      </SpinMaterial>
    </ModalMaterial>
  );
}
