import * as ActionLogin from '../../../module/Login/Store';
import * as ActionManualHoliday from '../../action/Holiday/ActionFormManualHoliday';
import * as toggle from '../../action/toggle';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_getDetailHoliday() {
  const state = yield select();
  const IdHoliday = state.Holiday.PropertiesHoliday.IdHoliday;
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Holiday/' + IdHoliday,
      {
        headers: config,
      }
    );
    yield put(ActionManualHoliday.setDateHoliday(data.date));
    yield put(ActionManualHoliday.setEventHoliday(data.eventName));
    if (data.isDisabled === false) {
      yield put(ActionManualHoliday.setActiveHoliday());
    }
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      if (error.response.status === 401) {
        yield put(ActionLogin.submitTokenRefrash());
      } else {
        const propsError = ErrorMassage('detail hari libur');
        const code = propsError.filter((person: any) =>
          person.Code.toString().toLowerCase().includes(error.response.status)
        );
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              {code.map((data: any) => data.TitleMassage)}
            </p>
          ),
          content: (
            <div>
              <p style={{ color: '#C0C0C0' }}>
                {code.map((data: any) => data.Massage)}
              </p>
            </div>
          ),
          onOk() {},
        });
      }
    }
  }
}

export function* SM_getDetailHoliday() {
  yield takeLatest('GET_DETAIL_HOLIDAY', S_getDetailHoliday);
}
