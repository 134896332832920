import 'leaflet/dist/leaflet.css';
import 'styles/MapsStyle/CardStyleMaps.css';

import { Button, Card, Col, Row, Select } from 'antd';
import { Map, Marker, TileLayer, ZoomControl } from 'react-leaflet';

import ButtonMaterial from 'materials/ButtonMaterial';
import Control from 'react-leaflet-control';
import FormInputTextMaterial from 'materials/FormInputTextMaterial';
import IconMarkerBranch from 'image/IconMarkerBranch.png';
import IconMarkerCourier from 'image/IconMarkerCourier.png';
import L from 'leaflet';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';

const { Option } = Select;

interface IProps {
  isLoading: any;
  toggleMaps: any;
  router: any;
  roleAccount: any;
  IdBranch: any;
  idBranch: any;
  errorConnectionMaps: any;
  courierCode: any;
  courierPhone: any;
  courierBranch: any;
  courierLastSeen: any;
  deliverySuccess: any;
  deliveryReturn: any;
  deliveryPending: any;
  deliveryTotal: any;
  detailCourier: any;
  stateIdCourier: any;
  selectCourier: any;
  NameBranch: any;
  typeRealTime: any;
  getLatitudeBranch: any;
  getLongitudeBranch: any;
  listCourier: any;
  listBranch: any;
  collapssFormTracking: any;
  handleCollapssFormTracking: any;
  handleGetCourier: any;
  handlePutSelectCourier: any;
  handleSearchLocationCourier: any;
  handlePutAllBranch: any;
  handleGetBranch: any;
}
export const pointerIconCourier = new L.Icon({
  iconUrl: IconMarkerCourier,
  iconSize: [34, 42],
});
export const pointerIconBranch = new L.Icon({
  iconUrl: IconMarkerBranch,
  iconSize: [34, 42],
});
var periods = {
  year: 12 * 30 * 24 * 60 * 60 * 1000,
  month: 30 * 24 * 60 * 60 * 1000,
  week: 7 * 24 * 60 * 60 * 1000,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
};
export default function MapComponent({
  stateIdCourier,
  IdBranch,
  idBranch,
  router,
  detailCourier,
  getLatitudeBranch,
  getLongitudeBranch,
  errorConnectionMaps,
  selectCourier,
  collapssFormTracking,
  isLoading,
  toggleMaps,
  handleGetBranch,
  roleAccount,
  handlePutAllBranch,
  listBranch,
  NameBranch,
  handlePutSelectCourier,
  handleGetCourier,
  listCourier,
  handleSearchLocationCourier,
  typeRealTime,
  courierCode,
  courierPhone,
  courierBranch,
  deliverySuccess,
  deliveryReturn,
  deliveryPending,
  deliveryTotal,
  courierLastSeen,
  handleCollapssFormTracking,
}: IProps) {
  function LastSeenCourier(LastSeen: any) {
    var diff = Date.now() - LastSeen;

    if (diff > periods.year) {
      return Math.floor(diff / periods.year) + ' Tahun yang lalu';
    } else if (diff > periods.month) {
      return Math.floor(diff / periods.month) + ' Bulan yang lalu';
    } else if (diff > periods.week) {
      return Math.floor(diff / periods.week) + ' Minggu yang lalu';
    } else if (diff > periods.day) {
      return Math.floor(diff / periods.day) + ' Hari yang lalu';
    } else if (diff > periods.hour) {
      return Math.floor(diff / periods.hour) + ' Jam yang lalu';
    } else if (diff > periods.minute) {
      return Math.floor(diff / periods.minute) + ' Menit yang lalu';
    }
    return 'Beberapa saat yang lalu';
  }
  let Courier =
    stateIdCourier === null ? (
      <span style={{ color: '#969696' }}>Pilih kurir</span>
    ) : (
      stateIdCourier
    );
  let Branch =
    IdBranch !== null && IdBranch !== 0 ? (
      IdBranch
    ) : idBranch === null ? (
      <span style={{ color: '#969696' }}>Pilih Posko</span>
    ) : (
      idBranch
    );
  const disableSearch =
    router === '/' && idBranch === null
      ? true
      : stateIdCourier === null
      ? true
      : false;
  const mapLatLng = detailCourier.map((data: any, index: any) => data);
  const mapGeoLocation = detailCourier.map(
    (data: any, index: any) => data.geoLocation
  );
  const centerLat =
    detailCourier.length > 1
      ? getLatitudeBranch
      : idBranch !== null && mapLatLng.toString() === ''
      ? getLatitudeBranch
      : idBranch === null
      ? -6.229448431431136
      : parseFloat(mapGeoLocation.toString().split(',')[-0]);
  const centerLng =
    detailCourier.length > 1
      ? getLongitudeBranch
      : idBranch !== null && mapLatLng.toString() === ''
      ? getLongitudeBranch
      : idBranch === null
      ? 106.90916048049928
      : parseFloat(mapGeoLocation.toString().split(',')[1]);

  return (
    <div className="card" style={{ marginBottom: '35px' }}>
      <Row style={{ padding: '20px' }}>
        <Col span={12}>
          <div>
            <b style={{ color: '#11BEFF' }}>PELACAKAN KURIR</b>
          </div>
        </Col>
        <Col span={12}>
          <div style={{ float: 'right' }}>
            <span style={{ color: '#969696' }}>
              {'Status Pelacakan Kurir: '}
            </span>
            <b>
              {errorConnectionMaps === null ? (
                <span style={{ color: '#31E116' }}>Terhubung</span>
              ) : (
                <span style={{ color: '#FF5858' }}>Gagal Terhubung</span>
              )}
            </b>
          </div>
        </Col>
      </Row>
      <Map
        useFlyTo={true}
        zoomControl={false}
        center={{ lat: centerLat, lng: centerLng }}
        zoom={selectCourier === null ? 12 : 16}
        style={{ width: '100%', height: '550px' }}
      >
        <TileLayer
          attribution="©RDS-Express/QSI"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Control position="topleft">
          <div style={{ paddingTop: '1px' }}>
            <Row style={{ width: 335 }}>
              {collapssFormTracking === false ? (
                <Col span={22}>
                  <Card bordered={false}>
                    <SpinMaterial
                      spinning={
                        isLoading === true && toggleMaps === 'Maps'
                          ? true
                          : false
                      }
                      style={{}}
                      size={'large'}
                      content={
                        <div>
                          <div style={{ paddingBottom: '13px' }}>
                            <div style={{ paddingBottom: '8px' }}>
                              <b>Posko</b>
                            </div>
                            {router === '/' ? (
                              <Select
                                style={{
                                  width: '100%',
                                  borderRadius: '10px',
                                }}
                                optionFilterProp="children"
                                showSearch
                                value={Branch}
                                onFocus={handleGetBranch}
                                disabled={
                                  roleAccount === 'Branch' ? true : false
                                }
                                onChange={handlePutAllBranch}
                              >
                                {listBranch.map((data: any, index: any) => (
                                  <Option key={index} value={data.id}>
                                    {data.name}
                                  </Option>
                                ))}
                              </Select>
                            ) : (
                              <FormInputTextMaterial
                                onPressEnter={''}
                                validateType={false}
                                value={NameBranch}
                                className={''}
                                disabled={false}
                                addonBefore={''}
                                placeholder={''}
                                prefix={''}
                                maxLength={''}
                                style={{
                                  background: '#F5F5F5',
                                  fontWeight: 'bold',
                                }}
                                size=""
                                type={''}
                                onChange={''}
                                onBlur={''}
                                validatestatus={''}
                                help={''}
                              />
                            )}
                          </div>
                          <div style={{ paddingBottom: '25px' }}>
                            <div style={{ paddingBottom: '8px' }}>
                              <b>Kurir</b>
                            </div>
                            <Select
                              style={{ width: '100%', borderRadius: '10px' }}
                              optionFilterProp="children"
                              showSearch
                              value={Courier}
                              disabled={false}
                              onChange={handlePutSelectCourier}
                              onFocus={handleGetCourier}
                            >
                              <Option value={'empty'}>Pilih semua kurir</Option>
                              {listCourier.map((data: any, index: any) => (
                                <Option key={index} value={data.id}>
                                  {data.firstName}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div>
                            <ButtonMaterial
                              disabled={disableSearch}
                              handleSubmit={handleSearchLocationCourier}
                              teksButton={'Cari'}
                              size={''}
                              shape={'round'}
                              className={''}
                              type={'primary'}
                              style={
                                disableSearch === true
                                  ? {
                                      float: 'right',
                                      height: '30px',
                                      width: '75px',
                                    }
                                  : {
                                      colorFont: '#11BEFF',
                                      float: 'right',
                                      height: '30px',
                                      width: '75px',
                                      background: '#11BEFF',
                                      borderColor: '#11BEFF',
                                    }
                              }
                              icon={''}
                            />
                          </div>
                        </div>
                      }
                    />
                    {typeRealTime === 'getCourier' &&
                    toggleMaps === null &&
                    courierCode !== undefined &&
                    errorConnectionMaps !== 'Error' ? (
                      <div style={{ paddingTop: '35px' }}>
                        <Row style={{ paddingBottom: '15px' }}>
                          <Col span={24}>
                            <b>Detail Kurir</b>
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: '9px' }}>
                          <Col span={15}>
                            <span
                              style={{ fontSize: '9.5pt', color: '#969696' }}
                            >
                              Kode Kurir
                            </span>
                          </Col>
                          <Col span={8}>
                            <span style={{ color: '#000000' }}>
                              {courierCode}
                            </span>
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: '9px' }}>
                          <Col span={15}>
                            <span
                              style={{ fontSize: '9.5pt', color: '#969696' }}
                            >
                              No Telp
                            </span>
                          </Col>
                          <Col span={8}>
                            <span style={{ color: '#000000' }}>
                              {courierPhone === null || undefined
                                ? ''
                                : '+62' + courierPhone}
                            </span>
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: '9px' }}>
                          <Col span={15}>
                            <span
                              style={{ fontSize: '9.5pt', color: '#969696' }}
                            >
                              Posko Kurir
                            </span>
                          </Col>
                          <Col span={8}>
                            <span style={{ color: '#000000' }}>
                              {courierBranch}
                            </span>
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: '9px' }}>
                          <Col span={15}>
                            <span
                              style={{ fontSize: '9.5pt', color: '#969696' }}
                            >
                              Diterima/Dikembalikan
                            </span>
                          </Col>
                          <Col span={8}>
                            <div>
                              <span style={{ color: '#31E116' }}>
                                {deliverySuccess + ' '}
                              </span>
                              /
                              <span style={{ color: '#FF5858' }}>
                                {' ' + deliveryReturn}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: '9px' }}>
                          <Col span={15}>
                            <span
                              style={{ fontSize: '9.5pt', color: '#969696' }}
                            >
                              Tertunda Ke Server
                            </span>
                          </Col>
                          <Col span={8}>
                            <span style={{ color: '#11BEFF' }}>
                              {deliveryPending}
                            </span>
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: '9px' }}>
                          <Col span={15}>
                            <span
                              style={{ fontSize: '9.5pt', color: '#969696' }}
                            >
                              Total Dokumen
                            </span>
                          </Col>
                          <Col span={8}>
                            <div>
                              <span style={{ color: '#000000' }}>
                                {deliverySuccess + deliveryReturn + ' '}
                              </span>
                              /<span>{' ' + deliveryTotal}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={15}>
                            <span
                              style={{ fontSize: '9.5pt', color: '#969696' }}
                            >
                              Terakhir Terhubung
                            </span>
                          </Col>
                          <Col span={8}>
                            <span style={{ color: '#000000' }}>
                              {courierCode === undefined
                                ? ''
                                : LastSeenCourier(courierLastSeen)}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    ) : typeRealTime === 'getCourier' &&
                      toggleMaps === 'Maps' ? null : typeRealTime ===
                        'getCourier' && courierCode === undefined ? (
                      <span style={{ fontStyle: 'italic' }}>
                        *Kurir Belum Login
                      </span>
                    ) : null}
                  </Card>
                </Col>
              ) : null}
              <Col span={2}>
                <Button
                  onClick={handleCollapssFormTracking}
                  style={{
                    borderRadius: 0,
                    float: 'left',
                    marginTop: '20px',
                    height: '45px',
                    width: '25px',
                    background: '#FFFFFF',
                    borderColor: '#FFFFFF',
                    color: '#000000',
                  }}
                  type="primary"
                  size="small"
                  icon={collapssFormTracking === false ? 'left' : 'right'}
                />
              </Col>
            </Row>
          </div>
        </Control>
        <Marker
          position={{
            lat:
              getLatitudeBranch === null
                ? -6.229448431431136
                : getLatitudeBranch,
            lng:
              getLongitudeBranch === null
                ? 106.90916048049928
                : getLongitudeBranch,
          }}
          icon={pointerIconBranch}
        >
          {/* <Popup>
              <div>
                {data.courierName}
                {data.courierCode}
              </div>
            </Popup> */}
        </Marker>
        {detailCourier.map((data: any, index: any) => (
          <Marker
            position={
              data === ''
                ? {
                    lat: getLatitudeBranch,
                    lng: getLongitudeBranch,
                  }
                : {
                    lat: parseFloat(data.geoLocation.toString().split(',')[-0]),
                    lng: parseFloat(data.geoLocation.toString().split(',')[1]),
                  }
            }
            icon={pointerIconCourier}
          >
            {/* <Popup>
                <div>
                  {data.courierName}
                  {data.courierCode}
                </div>
              </Popup> */}
          </Marker>
        ))}
        <ZoomControl position="bottomright" />
      </Map>
    </div>
  );
}
