import * as LoginAction from '../../../module/Login/Store/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import PrivacyPolicyComponent from '../../../components/Content/PrivacyPolicy/PrivacyPolicyComponent';
import { connect } from 'react-redux';

interface IProps {
  authLoginAction: any;
  isLoading: any;
}
interface IState {
  ValidateUsername: any;
  ValidatePassword: any;
}
class PrivacyPolicyContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateUsername: null,
      ValidatePassword: null,
    };
  }

  render() {
    return (
      <div>
        <PrivacyPolicyComponent isLoading={this.props.isLoading} />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  authLoginAction: bindActionCreators(LoginAction, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PrivacyPolicyContainer);
