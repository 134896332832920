import { Col, Row } from 'antd';

import DeliveryStatusContainer from 'module/DeliveryStatus/Container/DeliveryStatusContainer';
import DocumentInfoBarcodeContainer from 'module/DeliveryStatus/Container/DocumentInfoBarcodeContainer';
import DocumentInfoContainer from 'module/DeliveryStatus/Container/DocumentInfoContainer';
import OtherDataContainer from 'module/DeliveryStatus/Container/OtherDataContainer';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';
import TrackingCardContainer from 'module/DeliveryStatus/Container/TrackingCardContainer';

interface IProps {
  isLoading: any;
  getDeliveryStatusBarcode: any;
  getOwnerName: any;
}

export default function IndexDeliveryStatusComponent({
  isLoading,
  getDeliveryStatusBarcode,
  getOwnerName,
}: IProps) {
  const barcode = getDeliveryStatusBarcode;

  return (
    <div style={{ padding: '35px' }}>
      <SpinMaterial
        spinning={isLoading}
        style={{}}
        size={'large'}
        content={
          <React.Fragment>
            <Row>
              <Col>
                <DeliveryStatusContainer />
              </Col>
            </Row>
            {getOwnerName === null && barcode.length === 0 ? (
              <Row>
                <Col span={14} style={{ paddingTop: '20px' }}>
                  <DocumentInfoContainer />
                </Col>
                <Col
                  span={10}
                  style={{ paddingTop: '20px', paddingLeft: '20px' }}
                >
                  <TrackingCardContainer />
                </Col>
              </Row>
            ) : getOwnerName === null && barcode.length > 0 ? (
              <Row>
                <Col span={24} style={{ paddingTop: '20px' }}>
                  <DocumentInfoBarcodeContainer />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={14} style={{ paddingTop: '20px' }}>
                  <DocumentInfoContainer />
                </Col>
                <Col
                  span={10}
                  style={{ paddingTop: '20px', paddingLeft: '20px' }}
                >
                  <TrackingCardContainer />
                </Col>
              </Row>
            )}
            {getOwnerName !== null && barcode.length === 0 ? (
              <Row>
                <Col style={{ paddingTop: '20px' }}>
                  <OtherDataContainer />
                </Col>
              </Row>
            ) : (
              ''
            )}
          </React.Fragment>
        }
      />
    </div>
  );
}
