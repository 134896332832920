import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionPagination from '../../../action/Pagination/ActionPagination';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../../action/toggle';

import { ErrorMassage } from '../../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';
import { changeTableCustomerManifestForm } from '../../../action/Manifest/CustomerManifest/ActionCustomerManifest';
import moment from 'moment';

export function* S_getDataCustomerManifest() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const ScanDate = moment(state.Manifest.CustomerManifestForm.ScanDate).format(
    'YYYYMMDD'
  );
  const RadioButton =
    state.Manifest.CustomerManifestForm.RadioButton === 'Success'
      ? true
      : false;
  const Customer =
    state.Manifest.CustomerManifestForm.Customer === null
      ? 0
      : state.Manifest.CustomerManifestForm.Customer;
  const Product =
    state.Manifest.CustomerManifestForm.Product === null
      ? 0
      : state.Manifest.CustomerManifestForm.Product;
  const TreatmentDocument =
    state.Manifest.CustomerManifestForm.TreatmentDocument;
  const PagePagination = state.Pagination.PagePagination + 1;
  const SizeDataPagination = state.Pagination.SizeDataPagination;
  let link =
    process.env.REACT_APP_URL +
    '/Manifest/Customer/' +
    ScanDate +
    '/' +
    Customer +
    '/' +
    Product +
    '/' +
    RadioButton +
    '/' +
    PagePagination +
    '/' +
    SizeDataPagination;

  if (TreatmentDocument) {
    TreatmentDocument.forEach((item, index) => {
      link = link.concat(
        `${index === 0 ? '?' : '&'}treatmentDocuments=${item}`
      );
    });
  }
  try {
    yield put(changeLoadingTrue());

    const { data } = yield call(axios.get, link, { headers: config });

    if (data.data.length === 0) {
      Modal.error({
        title: <p style={{ color: '#FF0000' }}>Pencarian Tidak Ditemukan</p>,
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>Data yang dicari ( 0 )</p>
          </div>
        ),
        onOk() {},
      });
    } else {
      Modal.success({
        title: <p style={{ color: '#31E116' }}>Pencarian Berhasil</p>,
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              Total Dokumen ( {data.data.length} )
            </p>
          </div>
        ),
        onOk() {},
      });
    }

    yield put(changeTableCustomerManifestForm(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      yield put(changeLoadingFalse());
      const propsError = ErrorMassage('List data customer manifest');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}
export function* SM_getDataCustomerrManifest() {
  yield takeLatest(
    'CHANGE_GET_DATA_CUSTOMER_MANIFEST',
    S_getDataCustomerManifest
  );
}
