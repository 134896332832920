export function changeEditCourier(CourierId:any)
{
    return({
        type:"CHANGE_EDIT_COURIER",
        CourierId
    })
}
export function submitEditCourier()
{
    return({
        type:"SUBMIT_EDIT_COURIER"
    })
}
export function clearEditCourier()
{
    return({
        type:"CLEAR_EDIT_COURIER"
    })
}