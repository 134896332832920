import * as ActionAddress from '../../action/Address/index';
import * as ActionAuth from '../../action/Auth';
import * as ActionDetailBranch from '../../action/Branch/ActionDetailBranch';
import * as ActionFieldBranch from '../../action/Branch/ActionFieldBranch';
import * as ActionLocationBranch from '../../action/Maps/BranchTracking/ActionBranchTracking';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionTable from '../../action/Branch/ActionTableBranch';
import * as ActionToggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../action/toggle';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';
import history from '../../utils/History';

export function* S_DetailEditBranch(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const idBranch = state.Branch.PropertiesBranch.idBranch;
  const Partner = state.Branch.DetailBranch.Partner;
  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Branch/${idBranch}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionFieldBranch.changeGroupNameBranch(data.groupingBranch));
    yield put(ActionFieldBranch.setNameBranch(data.name));
    yield put(ActionFieldBranch.setAreaCode(data.uniqueCode));
    yield put(ActionFieldBranch.setAddress(data.address));
    yield put(ActionFieldBranch.setPhoneNumber(data.phoneNumber));
    yield put(ActionFieldBranch.setPostalCode(data.postalCode));
    yield put(ActionDetailBranch.setIdAdminBranch(data.headIdBranch));
    yield put(ActionDetailBranch.setIdVillage(data.branchVillage));
    yield put(ActionFieldBranch.setBranchProvince(data.branchProvince));
    yield put(ActionFieldBranch.setBranchCity(data.branchCity));
    yield put(ActionFieldBranch.setBranchSubDistrict(data.branchDistrict));
    yield put(ActionFieldBranch.setBranchVillage(data.branchVillage));
    yield put(ActionFieldBranch.setAdminBranch(data.firstName));
    if (data.isPartner === true && Partner === false) {
      yield put(ActionFieldBranch.setPartner());
    }
    yield put(ActionAddress.GetProvince());
    yield put(ActionAddress.GetCity());
    yield put(ActionAddress.GetSubDistrict());
    yield put(ActionAddress.GetVillage());
    yield put(ActionFieldBranch.getSelectAdminBranch());

    yield put(
      ActionFieldBranch.setLatitudeBranch(data.geoLocation.split(',')[-0])
    );
    yield put(
      ActionFieldBranch.setLongitudeBranch(data.geoLocation.split(',')[1])
    );
    yield put(
      ActionLocationBranch.setBranchLat(data.geoLocation.split(',')[-0])
    );
    yield put(
      ActionLocationBranch.setBranchLng(data.geoLocation.split(',')[1])
    );

    yield put(ActionToggle.changeJenisToggle('EditBranch'));
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(changeLoadingFalse());
  }
}
export function* S_EditBranch(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const idBranch = state.Branch.PropertiesBranch.idBranch;
  const GroupNameBranch = state.Branch.DetailBranch.GroupNameBranch;
  const NameBranch = state.Branch.DetailBranch.NameBranch;
  const AreaCode = state.Branch.DetailBranch.AreaCode;
  const Address = state.Branch.DetailBranch.Address;
  const PhoneNumber = state.Branch.DetailBranch.PhoneNumber;
  const PostalCode = state.Branch.DetailBranch.PostalCode;
  const Province = state.Branch.DetailBranch.Province;
  const City = state.Branch.DetailBranch.City;
  const SubDistrict = state.Branch.DetailBranch.SubDistrict;
  const Village = state.Branch.DetailBranch.Village;
  const AdminBranch = state.Branch.DetailBranch.AdminBranch;
  const LatitudeBranch = state.Branch.DetailBranch.LatitudeBranch;
  const LongitudeBranch = state.Branch.DetailBranch.LongitudeBranch;
  const Partner = state.Branch.DetailBranch.Partner;
  const IdAdminBranch = state.Branch.PropertiesBranch.idAdminBranch;
  const ChangeAdminBranch =
    typeof AdminBranch === 'string' ? IdAdminBranch : AdminBranch;
  const detaileditbranch = state.Branch.PropertiesBranch.EditBranch;
  try {
    yield put(changeLoadingTrue());

    yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/Branch/update`,
      {
        id: idBranch,
        groupingBranch: GroupNameBranch,
        name: NameBranch,
        uniqueCode: AreaCode,
        address: Address,
        phoneNumber: PhoneNumber,
        branchVillage: Village,
        branchDistrict: SubDistrict,
        branchProvince: Province,
        branchCity: City,
        headIdBranch: ChangeAdminBranch === null ? 0 : ChangeAdminBranch,
        postalCode: PostalCode,
        latitude: LatitudeBranch,
        longitude: LongitudeBranch,
        isPartner: Partner,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    if (detaileditbranch === true) {
      yield put(ActionDetailBranch.clearAllDetailBranch());
      yield put(ActionDetailBranch.changeDetailBranch());
      history.push('/branch/detail');
    } else {
      yield put(ActionDetailBranch.clearAllBranch());
    }
    yield put(ActionTable.ClearTableBranch());
    yield put(ActionToggle.clearJenisToggle());
    yield put(changeLoadingFalse());
    yield put(ActionTable.GetTableBranch());

    Modal.success({
      title: <p className={'titleModalNotifSuccess'}>Berhasil di simpan</p>,
      onOk() {},
    });
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(changeLoadingFalse());
  }
}

export function* SM_DetailEditBranch() {
  yield takeLatest('CHANGE_DETAIL_EDIT_BRANCH', S_DetailEditBranch);
}
export function* SM_EditSubmitBranch() {
  yield takeLatest('EDIT_BRANCH_SUBMIT', S_EditBranch);
}
