import React from 'react';
import FilterTable from './FilterTable';
import { FilterDataSlaProps } from '../../container/SlaPengiriman';
import SlaDataTable from './SlaDataTable';
import { DataTableResProps } from 'interface/general';

type SlaPengirimanComponentProps = {
  dataAllBranch: Array<any>;
  isLoadingBranch?: boolean;
  IdBranch?: number;
  roleAccount: string;
  dataFilterSla: FilterDataSlaProps;
  handleResetFilter: () => void;
  handleChangeFilter: (name: string, value: any) => void;
  handlePage: (value: number) => void;
  handleSubmitFilter: (value: FilterDataSlaProps) => void;
  dataTableSla: DataTableResProps;
  isLoadingTable: boolean;
  handleSearchValue: (value: string) => void;
  selectedDownloadSla: string;
  setSelectedDownloadSla: (value: string) => void;
  handleDownloadFile: () => void;
};

export default function SlaPengirimanComponent({
  dataAllBranch,
  isLoadingBranch,
  IdBranch,
  roleAccount,
  dataFilterSla,
  handleResetFilter,
  handleChangeFilter,
  handlePage,
  handleSubmitFilter,
  dataTableSla,
  isLoadingTable,
  handleSearchValue,
  selectedDownloadSla,
  setSelectedDownloadSla,
  handleDownloadFile,
}: SlaPengirimanComponentProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <FilterTable
        dataAllBranch={dataAllBranch}
        isLoadingBranch={isLoadingBranch}
        selectedBranchId={IdBranch}
        roleAccount={roleAccount}
        dataFilterSla={dataFilterSla}
        handleResetFilter={handleResetFilter}
        handleChangeFilter={handleChangeFilter}
        handleSubmitFilter={handleSubmitFilter}
      />
      <SlaDataTable
        dataFilterSla={dataFilterSla}
        dataTableSla={dataTableSla}
        isLoadingTable={isLoadingTable}
        handlePage={handlePage}
        handleSearchValue={value => handleSearchValue(value)}
        selectedDownloadSla={selectedDownloadSla}
        setSelectedDownloadSla={setSelectedDownloadSla}
        handleDownloadFile={handleDownloadFile}
      />
    </div>
  );
}
