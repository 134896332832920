import { Form, Radio } from 'antd';
import React, { useState } from 'react';

interface IPropRadio {
  input;
  idComponent;
  meta;
  classNameLabel?;
  label?;
  disabled?;
  data;
  layout?;
  formClassName?;
  addtionalFunction?;
  addtionalLabel?;
  radioType?;
  className?;
  formStyle?;
  labelIsRequired?;
  formName;
  initialValues?;
}

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
  marginBottom: '7px',
};

export const RadioButton = (props: IPropRadio) => {
  const {
    input,
    meta: { error },
    idComponent,
    classNameLabel,
    label,
    disabled,
    data,
    layout,
    formClassName,
    addtionalFunction,
    addtionalLabel,
    radioType,
    className,
    labelIsRequired,
  } = props;
  const [count, setcount] = useState(0);
  const handleOnChange = e => {
    input.onChange(e);
    if (addtionalFunction) {
      addtionalFunction(e);
    }
    setcount(count + 1);
  };

  const additionalLabel = () => {
    if (addtionalLabel) {
      return ` ${addtionalLabel}`;
    } else {
      return null;
    }
  };

  const lebelRequired = () => {
    if (labelIsRequired) {
      return <span className="labelRequired">*</span>;
    }
    return null;
  };
  const labelTitle = label ? (
    <span>
      <b className={`capital ${classNameLabel}`}>{label}</b>
      <span style={{ color: '#BFBFBF' }}>{additionalLabel()}</span>
      {lebelRequired()}
    </span>
  ) : null;
  const items = data.map(data => {
    if (radioType === 'button') {
      return (
        <Radio.Button
          value={data.id}
          key={`radioValue${data.id}`}
          id={`radioValue${data.id}`}
          style={layout === 'vertical' ? radioStyle : undefined}
        >
          {data.icon ? data.icon : null}
          {data.component === undefined ? data.name : data.component}
        </Radio.Button>
      );
    }
    return (
      <Radio
        value={data.id}
        key={`radioValue${data.id}`}
        id={`radioValue${data.id}`}
        style={layout === 'vertical' ? radioStyle : undefined}
      >
        {data.component === undefined ? data.name : data.component}
      </Radio>
    );
  });
  return (
    <Form.Item
      validateStatus={count > 0 && error !== undefined ? 'error' : ''}
      help={count > 0 && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
      className={formClassName}
    >
      <Radio.Group
        id={idComponent}
        onChange={e => handleOnChange(e)}
        disabled={disabled}
        value={input.value}
        onBlur={input.onBlur}
        className={className}
        // {...input}
      >
        {items}
      </Radio.Group>
    </Form.Item>
  );
};
