import * as ActionFilter from '../../action/DataDelivery/ActionFormDataDelivery';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionPagination from '../../action/Pagination/ActionPagination';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  changeLoadingFalse,
  changeLoadingTrue,
} from '../../action/toggle/index';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';
import moment from 'moment';

export function* S_GetDataDeliveryFilter() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const IdBranchFormAuth = state.Auth.BranchAuth;
  const RoleFormAuth = state.Auth.Role;
  const PagePagination = state.Pagination.PagePagination - 1;
  const SizeDataPagination = state.Pagination.SizeDataPagination;
  const RadioButton = state.DataDelivery.Filter.RadioButton;
  const DeliveStat = state.DataDelivery.Filter.DeliveryStatus;
  const PhysicalDocument = state.DataDelivery.Filter.PhysicalDocument;
  const DateFrom = moment(state.DataDelivery.Filter.CycleStart).format(
    'YYYYMMDD'
  );
  const DateTo = moment(state.DataDelivery.Filter.CycleEnd).format('YYYYMMDD');
  const Customer =
    state.DataDelivery.Filter.Customer === null
      ? 0
      : state.DataDelivery.Filter.Customer;
  const CustomerProduct =
    state.DataDelivery.Filter.CustomerProduct === null
      ? 0
      : state.DataDelivery.Filter.CustomerProduct;
  const Branch =
    state.DataDelivery.Filter.Branch === null
      ? 0
      : state.DataDelivery.Filter.Branch;
  const Courier =
    state.DataDelivery.Filter.Courier === null
      ? 0
      : state.DataDelivery.Filter.Courier;
  const paramDeliveStat = `${Object.values(DeliveStat).join('%2C')}`;
  const ConditionBranch = RoleFormAuth === 'Branch' ? IdBranchFormAuth : Branch;

  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Delivery/DeliveryData/${paramDeliveStat}/${PagePagination}/${SizeDataPagination}?TimeStart=${DateFrom}&TimeEnd=${DateTo}&Customer=${Customer}&Product=${CustomerProduct}&Branch=${ConditionBranch}&Courier=${Courier}&DeliveryTime=${RadioButton}&PhysicalDocument=${PhysicalDocument}`,
      { headers: config }
    );
    yield put(ActionFilter.setTableDeliveryFilter(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      yield put(changeLoadingFalse());
      const propsError = ErrorMassage('table posko');
      const code = propsError.filter((data: any) =>
        data.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}
export function* SM_GetDataDeliveryFilter() {
  yield takeLatest('SEARCH_DELIVERY', S_GetDataDeliveryFilter);
}
