export function changeUsernameAuth(UsernameAuth: any) {
  return {
    type: 'CHANGE_USERNAME_AUTH',
    UsernameAuth,
  };
}
export function changePhotoAuth(PhotoAuth: any) {
  return {
    type: 'CHANGE_PHOTO_AUTH',
    PhotoAuth,
  };
}
export function changeInitialsAuth(InitialsAuth: any) {
  return {
    type: 'CHANGE_INITIALS_AUTH',
    InitialsAuth,
  };
}
export function changeToken(Token: any) {
  return {
    type: 'CHANGE_TOKEN',
    Token,
  };
}

export function changeSideMenus(SideMenus: any) {
  return {
    type: 'CHANGE_SIDEMENUS',
    SideMenus,
  };
}

export function changeTokenRefresh(TokenRefresh: any) {
  return {
    type: 'CHANGE_TOKEN_REFRESH',
    TokenRefresh,
  };
}
export function changeStatusTokenRefresh(StatusTokenRefresh: any) {
  return {
    type: 'CHANGE_STATUS_TOKEN_REFRESH',
    StatusTokenRefresh,
  };
}
export function ChangeRole(Role: any) {
  return {
    type: 'CHANGE_ROLE',
    Role,
  };
}
export function GetRole(username, token) {
  return {
    type: 'GET_ROLE_REQUESTED',
    username,
    token,
  };
}
export function clearStatusTokenRefresh() {
  return {
    type: 'CLEAR_STATUS_TOKEN_REFRESH',
  };
}
export function changeBranchAuth(BranchAuth: any) {
  return {
    type: 'CHANGE_BRANCH_AUTH',
    BranchAuth,
  };
}
