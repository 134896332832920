import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionWaybillDigital from '../../../action/Report/DigitalWaybillReport/ActionDigitalWaybill';
import * as toggle from '../../../action/toggle';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

export function* S_getDownloadDigitalWaybillByUpload(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
    'Access-Control-Expose-Headers': 'Content-Disposition',
  };
  const key = 'download';

  const FileCsv = state.Report.DigitalWaybillReportForm.FileCsvDw;
  const CheckboxBarcode = state.Report.DigitalWaybillReportForm.CheckboxBarcode;
  const CheckboxBarcodeReverse =
    state.Report.DigitalWaybillReportForm.CheckboxBarcodeReverse;
  const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
  const flagFileName = state.Report.DigitalWaybillReportForm.FlagFileNameUpload;
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data.data]));
    const link = document.createElement('a');
    const file_name = `Waybill Digital ${currentDate}.zip`;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }

  try {
    yield put(toggle.changeLoadingTrue());
    const response = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Delivery/DownloadWaybillDigital',
      {
        customerId: 0,
        filterDateType: 'Cycle',
        dateStart: 'string',
        dateEnd: 'string',
        customerProducts: [0],
        deliveryStats: ['OnHold'],
        deliveryType: ['reguler'],
        file: FileCsv,
        barcode: CheckboxBarcode,
        revertBarcode: CheckboxBarcodeReverse,
        fileNameWaybill: flagFileName,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);
          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(response);
    const filename =
      response.headers['content-disposition'].split('filename')[1];

    const rmQoute = filename.split('"')[1];

    yield put(ActionWaybillDigital.clearFormByUpload());
    yield put(ActionWaybillDigital.changeFileName(rmQoute));
    yield put(ActionWaybillDigital.getCountDigitalWaybillByUpload());
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.changeLoadingFalse());
    yield put(toggle.clearJenisToggle());
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(toggle.clearJenisToggle());
    if (error.response.status === 500) {
      message.error({
        content: 'Download Gagal',
        key,
        duration: 3,
      });
    }
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_downloadDigitalWaybillTemplate(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const CheckboxBarcode = state.Report.DigitalWaybillReportForm.CheckboxBarcode;

  const key = 'download';
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = `Sample File ${
      CheckboxBarcode === true ? 'Barcode' : 'Waybill'
    }.xlsx`;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }

  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_URL +
        '/Delivery/Template/waybillDigital?barcode=' +
        CheckboxBarcode,
      {},
      {
        responseType: 'arraybuffer',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);
          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    if (error.response.status === 500) {
      message.error({
        content: 'Download Gagal',
        key,
        duration: 3,
      });
    }
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_getCountDigitalWaybill(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const FileName = state.Report.DigitalWaybillReportForm.FileName;

  try {
    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Delivery/Validation/waybillDigital/',
      {
        fileName: FileName,
      },
      { headers: config }
    );

    yield put(toggle.changeJenisToggle('WaybillDigitalByUploadResult'));
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionWaybillDigital.changeCountSuccess(data.success));
    yield put(ActionWaybillDigital.changeCountFailed(data.failed));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_getErrorDigitalWaybill(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const FileName = state.Report.DigitalWaybillReportForm.FileName;

  const key = 'download';
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = `Result Waybill Digital (${moment().format(
      'YYYY-MM-DD'
    )}).txt`;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }

  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Delivery/DownloadwaybillDigital/Wrong/',
      {
        fileName: FileName,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);
          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    if (error.response.status === 500) {
      message.error({
        content: 'Download Gagal',
        key,
        duration: 3,
      });
    }
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_getDeleteDigitalWaybill() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const FileName = state.Report.DigitalWaybillReportForm.FileName;
  const body = {
    fileName: FileName,
  };
  if (statusTokenRefresh === true) {
    yield put(ActionAuth.changeStatusTokenRefresh(false));
  }
  try {
    yield call(
      axios.delete,
      process.env.REACT_APP_URL + '/Delivery/DeleteWbillDigital/Wrong',
      { data: body, headers: config }
    );
  } catch (error) {}
}

export function* SM_getDeleteDigitalWaybill() {
  yield takeLatest(
    'GET_DELETE_DIGITAL_WAYBILL_BY_UPLOAD',
    S_getDeleteDigitalWaybill
  );
}

export function* SM_getErrorDigitalWaybill() {
  yield takeLatest(
    'GET_ERROR_DIGITAL_WAYBILL_BY_UPLOAD',
    S_getErrorDigitalWaybill
  );
}

export function* SM_getCountDigitalWaybill() {
  yield takeLatest(
    'GET_COUNT_DIGITAL_WAYBILL_BY_UPLOAD',
    S_getCountDigitalWaybill
  );
}

export function* SM_downloadDigitalWaybillTemplate() {
  yield takeLatest(
    'DOWNLOAD_DIGITAL_WAYBILL_TEMPLATE',
    S_downloadDigitalWaybillTemplate
  );
}

export function* SM_getDownloadDigitalWaybillByUpload() {
  yield takeLatest(
    'DOWNLOAD_DIGITAL_WAYBILL_BY_UPLOAD',
    S_getDownloadDigitalWaybillByUpload
  );
}
