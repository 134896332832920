import { Col, Form, Input, Row, Select } from 'antd';
import React, { Component } from 'react';
import ButtonMaterial from '../../../materials/ButtonMaterial';
import FormInputTextMaterial from '../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../materials/ModalMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';
import { EnumCustomerAccess } from '../../../core/enumerable/General';

const { Option } = Select;

interface IProps {
  typeToggle: any;
  visible: any;
  isLoading: any;
  CustomerName: any;
  CustomerCode: any;
  CustomerType: any;
  CustomerTelephone: any;
  CustomerEmail: any;
  CustomerTypeOther: any;
  CustomerAddress: any;
  CustomerAccess: any;
  ValidateCustomerName: any;
  ValidateCustomerCode: any;
  ValidateCustomerType: any;
  ValidateCustomerTelephone: any;
  ValidateCustomerEmail: any;
  ValidateCustomerTypeOther: any;
  ValidateCustomerAddress: any;
  ValidateCustomerAccess: any;
  helpValidateCustomerName: any;
  helpValidateCustomerCode: any;
  helpValidateCustomerType: any;
  helpValidateCustomerTelephone: any;
  helpValidateCustomerEmail: any;
  helpValidateCustomerTypeOther: any;
  helpValidateCustomerAddress: any;
  helpValidateCustomerAccess: any;
  handleCloseModal: any;
  handleCustomerName: any;
  handleCustomerCode: any;
  handleCustomerType: any;
  handleCustomerTelephone: any;
  handleCustomerEmail: any;
  handleCustomerTypeOther: any;
  handleCustomerAddress: any;
  handleCustomerAccess: any;
  handleBlurCustomerType: any;
  handleBlurCustomerAccess: any;
  handleAddCustomer: any;
  handleEditCustomer: any;
  ValidateButton: any;
}
export default class FormCustomerComponent extends Component<IProps> {
  render() {
    const valuegetcustomertype =
      this.props.CustomerType === null ? (
        <span style={{ color: '#969696' }}>Pilih jenis pelanggan</span>
      ) : (
        this.props.CustomerType
      );

    const disablecustomertypeother =
      this.props.CustomerType === 'Lainnya' ? false : true;
    const disableAreaCode =
      this.props.typeToggle === 'EditCustomer' ? true : false;

    return (
      <div>
        <ModalMaterial
          className={''}
          width={'50%'}
          style={{}}
          teksTitle={
            this.props.typeToggle === 'EditCustomer'
              ? 'UBAH PELANGGAN'
              : 'TAMBAHKAN PELANGGAN'
          }
          visible={this.props.visible}
          onCancel={this.props.handleCloseModal}
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={
                <div style={{ paddingTop: '20px' }}>
                  <Row>
                    <Col span={12}>
                      <div style={{ marginRight: 20, marginLeft: 30 }}>
                        <div>
                          <span>
                            <b>Nama</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            disabled={false}
                            onPressEnter={''}
                            addonBefore={''}
                            placeholder={'Contoh : RDS Life'}
                            prefix={''}
                            value={this.props.CustomerName}
                            maxLength={30}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleCustomerName}
                            onBlur={this.props.handleCustomerName}
                            validatestatus={this.props.ValidateCustomerName}
                            help={this.props.helpValidateCustomerName}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Kode</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            disabled={disableAreaCode}
                            addonBefore={''}
                            onPressEnter={''}
                            placeholder={'Contoh : AB'}
                            prefix={''}
                            value={this.props.CustomerCode}
                            maxLength={2}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleCustomerCode}
                            onBlur={this.props.handleCustomerCode}
                            validatestatus={this.props.ValidateCustomerCode}
                            help={this.props.helpValidateCustomerCode}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Jenis</b>
                          </span>
                          <Form.Item
                            validateStatus={this.props.ValidateCustomerType}
                            help={this.props.helpValidateCustomerType}
                          >
                            <Select
                              style={{ width: '100%' }}
                              onSelect={this.props.handleCustomerType}
                              optionFilterProp="children"
                              showSearch
                              value={valuegetcustomertype}
                              onChange={this.props.handleCustomerType}
                              onBlur={this.props.handleBlurCustomerType}
                            >
                              <Option value="Asuransi">Asuransi</Option>
                              <Option value="Bank">Bank</Option>
                              <Option value="Lainnya">Lainnya</Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div>
                          <span>
                            <b>Alamat lengkap</b>
                          </span>
                          <Form.Item
                            validateStatus={this.props.ValidateCustomerAddress}
                            help={this.props.helpValidateCustomerAddress}
                          >
                            <Input.TextArea
                              name="alamat"
                              style={{ height: '100px' }}
                              placeholder={
                                'Contoh : Pakarti Centre Building, 6th Floor Jl. Tanah Abang 3 No. 23, 25, 27, Petojo Selatan, Kec. Gambir Jakarta Pusat '
                              }
                              value={this.props.CustomerAddress}
                              onBlur={this.props.handleCustomerAddress}
                              onChange={this.props.handleCustomerAddress}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div style={{ marginLeft: 20, marginRight: 30 }}>
                        <div>
                          <span>
                            <b>No Telp</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            onPressEnter={''}
                            disabled={false}
                            addonBefore={'+62'}
                            placeholder={'Contoh : 5922134'}
                            prefix={''}
                            value={this.props.CustomerTelephone}
                            maxLength={19}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleCustomerTelephone}
                            onBlur={this.props.handleCustomerTelephone}
                            validatestatus={
                              this.props.ValidateCustomerTelephone
                            }
                            help={this.props.helpValidateCustomerTelephone}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Email</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            disabled={false}
                            addonBefore={''}
                            onPressEnter={''}
                            placeholder={'Contoh : sample@rds.co.id'}
                            prefix={''}
                            value={this.props.CustomerEmail}
                            maxLength={30}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleCustomerEmail}
                            onBlur={this.props.handleCustomerEmail}
                            validatestatus={this.props.ValidateCustomerEmail}
                            help={this.props.helpValidateCustomerEmail}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Lainnya</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            onPressEnter={''}
                            disabled={disablecustomertypeother}
                            addonBefore={''}
                            placeholder={'Contoh : Leasing'}
                            prefix={''}
                            value={this.props.CustomerTypeOther}
                            maxLength={30}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleCustomerTypeOther}
                            onBlur={this.props.handleCustomerTypeOther}
                            validatestatus={
                              this.props.ValidateCustomerTypeOther
                            }
                            help={this.props.helpValidateCustomerTypeOther}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Akses Portal Pelanggan</b>
                          </span>
                          <Form.Item
                            validateStatus={this.props.ValidateCustomerAccess}
                            help={this.props.helpValidateCustomerAccess}
                          >
                            <Select
                              style={{ width: '100%' }}
                              showSearch
                              mode="multiple"
                              placeholder="Pilih akses portal pelanggan"
                              value={this.props.CustomerAccess}
                              allowClear={true}
                              onDeselect={this.props.handleBlurCustomerAccess}
                              onSelect={this.props.handleBlurCustomerAccess}
                              onBlur={this.props.handleBlurCustomerAccess}
                              onChange={this.props.handleCustomerAccess}
                            >
                              {EnumCustomerAccess.map(
                                (data: any, index: any) => (
                                  <Option key={index} value={data.value}>
                                    {data.label}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div style={{ textAlign: 'right', marginRight: 30 }}>
                    <ButtonMaterial
                      disabled={this.props.ValidateButton}
                      handleSubmit={
                        this.props.typeToggle === 'EditCustomer'
                          ? this.props.handleEditCustomer
                          : this.props.handleAddCustomer
                      }
                      teksButton={'Simpan'}
                      size={''}
                      shape={'round'}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={{}}
                    />
                  </div>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
