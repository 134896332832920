import { Form, Input } from 'antd';

import React from 'react';

interface IProps {
  style?: any;
  disabled?: any;
  className?: any;
  placeholder?: any;
  maxLength?: any;
  value?: any;
  onChange?: any;
  onBlur?: any;
  addonBefore?: any;
  prefix?: any;
  size?: any;
  type?: any;
  validatestatus?: any;
  help?: any;
  validateType?: any;
  onPressEnter?: any;
  leftPrefix?: any;
  input?: any;
  meta?: any;
}

const { TextArea } = Input;

export default function FormInputTextMaterial({
  validateType,
  meta,
  help,
  type,
  className,
  disabled,
  addonBefore,
  prefix,
  style,
  placeholder,
  maxLength,
  size,
  leftPrefix,
  input,
}: IProps) {
  const { error, touched } = meta || {};
  return validateType === true ? (
    <Form.Item
      validateStatus={error && touched ? 'error' : ''}
      help={error && touched ? error : ''}
    >
      {type === 'password' ? (
        <Input.Password
          className={className}
          disabled={disabled}
          addonBefore={addonBefore}
          prefix={leftPrefix || prefix}
          style={style}
          placeholder={placeholder}
          maxLength={maxLength}
          size={size}
          type={type}
          {...input}
        />
      ) : type === 'textArea' ? (
        <TextArea
          className={className}
          disabled={disabled}
          prefix={prefix}
          style={style}
          placeholder={placeholder}
          maxLength={maxLength}
          {...input}
        />
      ) : (
        <Input
          className={className}
          disabled={disabled}
          addonBefore={addonBefore}
          prefix={leftPrefix || prefix}
          style={style}
          placeholder={placeholder}
          maxLength={maxLength}
          size={size}
          type={type}
          {...input}
        />
      )}
    </Form.Item>
  ) : type === 'allowClear' ? (
    <Input
      className={className}
      disabled={disabled}
      allowClear
      addonBefore={addonBefore}
      prefix={prefix}
      style={style}
      placeholder={placeholder}
      maxLength={maxLength}
      size={size}
      type={type}
      {...input}
    />
  ) : (
    <Input
      className={className}
      disabled={disabled}
      addonBefore={addonBefore}
      prefix={prefix}
      style={style}
      placeholder={placeholder}
      maxLength={maxLength}
      size={size}
      type={type}
      {...input}
    />
  );
}
