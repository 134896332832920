import * as ActionAuth from '../../../../action/Auth';
import * as ActionLogin from '../../../../../module/Login/Store';
import * as ActionResendSingleData from '../../../../action/Delivery/Resend/ActionResendSingleData';
import * as toggle from '../../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_GetDataWaybill(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const waybill = state.ResendSingleData.propertiesResendSingleData.waybill;
  try {
    yield put(toggle.changeLoadingTrue());
    yield put(ActionResendSingleData.clearFormExceptProperties());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + `/Delivery/ResendSingle?waybill=${waybill}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(ActionResendSingleData.changeName(data.shipToName));
    yield put(ActionResendSingleData.changePhoneNumber(data.shipToPhone));
    yield put(
      ActionResendSingleData.changeAddress(JSON.parse(data.shipToAddress))
    );
    yield put(ActionResendSingleData.changeDisableForm(false));
    yield put(toggle.changeLoadingFalse());
    Modal.success({
      title: <p style={{ color: '#31E116' }}>Data berhasil ditemukan</p>,
      onOk() {},
    });
  } catch (error) {
    yield put(ActionResendSingleData.changeDisableForm(true));
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_GenerateNewWaybill(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const waybill = state.ResendSingleData.propertiesResendSingleData.waybill;
  const deliveryDate =
    state.ResendSingleData.propertiesResendSingleData.deliveryDate;
  const name = state.ResendSingleData.name;
  const phone = state.ResendSingleData.phoneNumber;
  const address = state.ResendSingleData.address;
  const addressPost = JSON.stringify(address);
  try {
    yield put(toggle.changeLoadingTrue());
    yield call(
      axios.post,
      process.env.REACT_APP_URL +
        `/Delivery/Update/ResendSingle?waybill=${waybill}`,
      {
        shipToName: name,
        shipToPhone: phone,
        shipToAddress: addressPost,
        itemDeliveryTime: deliveryDate,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionResendSingleData.changeDisableForm(true));
    yield put(ActionResendSingleData.clearClearAllForm());
    yield put(toggle.changeLoadingFalse());
    Modal.success({
      title: <p style={{ color: '#31E116' }}>Berhasil Tersimpan</p>,
      content: (
        <div>
          <p>Silahkan download waybill pada menu</p>
          <p>{'Pengiriman > Dokumen > Cetak Waybill'}</p>
        </div>
      ),
      onOk() {},
    });
  } catch (error) {
    yield put(ActionResendSingleData.changeDisableForm(true));
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_GetDataWaybill() {
  yield takeLatest('SEARCH_WAYBILL_RS', S_GetDataWaybill);
}
export function* SM_GenerateNewWaybill() {
  yield takeLatest('GENERATE_NEW_WAYBILL_RS', S_GenerateNewWaybill);
}
