export function changeCourierDetail(idCourier:any)
{
    return({
        type:"CHANGE_COURIER_DETAIL",
        idCourier
    })
}
export function typeViewCourier(TypeViewCourier:any)
{
    return({
        type:"TYPE_VIEW_COURIER",
        TypeViewCourier
    })
}
export function clearDetailCourier()
{
    return({
        type:"CLEAR_DETAIL_COURIER"
    })
}