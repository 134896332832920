import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as ActionTableCustomerProduct from '../../../../core/action/CustomerProduct/ActionTableCustomerProduct';
import * as ActionFieldCustomerProduct from '../../../../core/action/CustomerProduct/ActionFieldCustomerProduct';
import * as ActionToggle from '../../../../core/action/toggle/index';
import CustomerProductComponent from '../../../../components/Content/Customer/Product/CustomerProductComponent';
import FormCustomerContainer from '../FormCustomerContainer';
import FormCustomerProductContainer from './FormCustomerProductContainer';

interface IProps {
  data: any;
  isLoading: any;
  typeToggle: any;
  actionTableCustomerProduct: any;
  actionFieldCustomerProduct: any;
  actionToggle: any;
  ValueSearch: any;
}

const CustomerProductContainer = (props: IProps) => {
  const [dataSource, setDataSource] = useState([]);
  const [page, setPage] = useState(0);
  const [isActiveType, setIsActiveType] = useState('Active');

  useEffect(() => {
    props.actionTableCustomerProduct.GetTableCustomerProduct(isActiveType);
    props.actionToggle.clearValueSearch();
    // eslint-disable-next-line
  }, [isActiveType]);

  useEffect(() => {
    if (props?.data.length > 0) {
      setDataSource(props.data);
    } else {
      setDataSource([]);
    }
    // eslint-disable-next-line
  }, [props.data]);

  const handlePage = (event: any) => {
    setPage(event);
  };
  const handleSearch = (event: any) => {
    props.actionToggle.changeValueSearch(event.target.value.toLowerCase());
  };

  const handleModal = () => {
    props.actionToggle.changeModal();
    props.actionToggle.changeJenisToggle('AddCustomerProduct');
    props.actionFieldCustomerProduct.setCustomerProductDistrict1(3);
    props.actionFieldCustomerProduct.setCustomerProductDistrict2(5);
    props.actionFieldCustomerProduct.setCustomerProductDistrict3(8);
    props.actionFieldCustomerProduct.setCustomerProductDistrict4(3);
    props.actionFieldCustomerProduct.setCustomerProductDistrict5(3);
    props.actionFieldCustomerProduct.setCustomerProductDistrict6(3);
    props.actionFieldCustomerProduct.setCustomerProductDistrict7(3);
    props.actionFieldCustomerProduct.setCustomerProductDistrict8(3);
    props.actionFieldCustomerProduct.setCustomerProductDistrict9(3);
    props.actionFieldCustomerProduct.setCustomerProductDistrict10(3);
    props.actionFieldCustomerProduct.setCustomerProductDistrict11(3);
    props.actionFieldCustomerProduct.setCustomerProductDistrict12(3);
  };

  return (
    <>
      {props.typeToggle === 'EditCustomer' ? (
        <FormCustomerContainer />
      ) : (
        <FormCustomerProductContainer setIsActiveType={setIsActiveType} />
      )}
      <CustomerProductComponent
        data={props.data}
        isLoading={props.isLoading}
        page={page}
        typeToggle={props.actionToggle}
        dataSource={dataSource}
        ValueSearch={props.ValueSearch}
        handlePage={handlePage}
        handleSearch={handleSearch}
        handleModal={handleModal}
        setIsActiveType={setIsActiveType}
        isActiveType={isActiveType}
      />
    </>
  );
};
const mapStateProps = (state: any) => ({
  data: state.Table.TableCustomerProduct,
  isLoading: state.Toggle.isLoading,
  typeToggle: state.Toggle.jenisToggle,
  ValueSearch: state.Toggle.ValueSearch,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionTableCustomerProduct: bindActionCreators(
    ActionTableCustomerProduct,
    dispatch
  ),
  actionFieldCustomerProduct: bindActionCreators(
    ActionFieldCustomerProduct,
    dispatch
  ),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(CustomerProductContainer);
