import * as ActionBranchTracking from 'core/action/Maps/BranchTracking/ActionBranchTracking';
import * as ActionFieldBranch from 'core/action/Branch/ActionFieldBranch';
import * as ActionToggleMaps from 'core/action/Maps/ActionToggleMaps';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

// import { Marker } from 'react-leaflet';
import SetMapsBranchComponent from 'module/Branch/Component/SetMapsBranchComponent';
import { connect } from 'react-redux';

interface IProps {
  getBranchLat: any;
  getBranchLng: any;
  getZoomMap: any;
  modalLocationBranch: any;
  actionBranchTracking: any;
  actionToggleMaps: any;
  actionFieldBranch: any;
  handleModalLocationBranch: any;
}

function SetMapsBranchContainer({
  getBranchLat,
  getBranchLng,
  getZoomMap,
  modalLocationBranch,
  actionBranchTracking,
  actionToggleMaps,
  actionFieldBranch,
  handleModalLocationBranch,
}: IProps) {
  useEffect(() => {
    if (getBranchLat === null || getBranchLng === null) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(getCoordinates);
      } else {
        alert('Geolocation tidak bekerja di browser ini.');
      }
    }
    actionToggleMaps.setZoomMap(15);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // getCoordinates.bind(this);
    actionToggleMaps.setZoomMap(15);
  });

  // const refmarker = createRef<Marker>();

  function handleSaveLocationBranch() {
    actionFieldBranch.setLatitudeBranch(getBranchLat);
    actionFieldBranch.setLongitudeBranch(getBranchLng);
    handleModalLocationBranch();
  }
  function handleMarkerUpdatePosition(event: any) {
    const latlng = event.target.getLatLng();
    actionBranchTracking.setBranchLat(latlng.lat);
    actionBranchTracking.setBranchLng(latlng.lng);
  }
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getCoordinates);
    } else {
      alert('Geolocation tidak bekerja di browser ini.');
    }
  };
  const getCoordinates = (position: any) => {
    actionBranchTracking.setBranchLat(position.coords.latitude);
    actionBranchTracking.setBranchLng(position.coords.longitude);
  };

  return (
    <div>
      <SetMapsBranchComponent
        modalLocationBranch={modalLocationBranch}
        getBranchLat={getBranchLat}
        getBranchLng={getBranchLng}
        getZoomMap={getZoomMap}
        getLocation={getLocation}
        handleModalLocationBranch={handleModalLocationBranch}
        handleMarkerUpdatePosition={handleMarkerUpdatePosition}
        handleSaveLocationBranch={handleSaveLocationBranch}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  getBranchLat: state.Maps.BranchTracking.BranchLat,
  getBranchLng: state.Maps.BranchTracking.BranchLng,
  getZoomMap: state.Maps.ZoomMap,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionBranchTracking: bindActionCreators(ActionBranchTracking, dispatch),
  actionToggleMaps: bindActionCreators(ActionToggleMaps, dispatch),
  actionFieldBranch: bindActionCreators(ActionFieldBranch, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SetMapsBranchContainer);
