import * as ActionLogin from '../../../module/Login/Store';
import * as ActionTableHoliday from '../../action/Holiday/ActionTableHoliday';
import * as ActionUploadHoliday from '../../action/Holiday/ActionUploadCsvHoliday';
import * as toggle from '../../action/toggle';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_getTemplateHoliday() {
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = `${'Sampel Csv Holiday'}.csv`;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }

  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Holiday/template',
      {
        responseType: 'blob',
        headers: config,
      }
    );
    ResponseDownload(data);
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      const propsError = ErrorMassage('download template hari libur');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}
export function* S_uploadCsvHoliday() {
  const state = yield select();
  const Csv = state.Holiday.FormUploadHoliday.CsvHoliday;
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Holiday/upload',
      {
        fileString: Csv,
      },
      {
        headers: config,
      }
    );
    yield put(ActionUploadHoliday.clearCsvHoliday());
    yield put(ActionTableHoliday.GetTableHoliday());
    yield put(toggle.changeModal());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: <p style={{ color: '#31E116' }}>Upload Csv hari libur berhasil</p>,

      onOk() {},
    });
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
    } else {
      yield put(toggle.changeLoadingFalse());
      const errorMessage = error.response.data.Message;
      const searchMessageError = errorMessage.search('File not supported!');
      if (error.response.status === 500 && searchMessageError >= 0) {
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              {'Isi file hari libur tidak sesuai!!!'}
            </p>
          ),
          content: <div>Silakan cek isi file csv hari libur</div>,
          onOk() {},
        });
      } else if (error.response.status === 401) {
        yield put(ActionLogin.submitTokenRefrash());
      } else {
        const propsError = ErrorMassage('upload Csv hari libur');
        const code = propsError.filter((person: any) =>
          person.Code.toString().toLowerCase().includes(error.response.status)
        );
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              {code.map((data: any) => data.TitleMassage)}
            </p>
          ),
          content: (
            <div>
              <p style={{ color: '#C0C0C0' }}>
                {code.map((data: any) => data.Massage)}
              </p>
            </div>
          ),
          onOk() {},
        });
      }
    }
  }
}

export function* SM_getTemplateHoliday() {
  yield takeLatest('GET_TEMPLATE_CSV_HOLIDAY', S_getTemplateHoliday);
}
export function* SM_uploadCsvHoliday() {
  yield takeLatest('ADD_CSV_HOLIDAY', S_uploadCsvHoliday);
}
