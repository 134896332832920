import * as ActionCustomerAccount from '../../../action/ManageAccount/Customer/ActionCustomerAccount';
import * as ActionEditCustomerAccount from '../../../action/ManageAccount/Customer/ActionEditCustomerAccount';
import * as ActionListCustomerProduct from '../../../../core/action/CustomerProduct/ActionListCustomerProduct';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_editCustomerAccount() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const IdCustomerAccount = state.Customer.CustomerAccount.IdCustomerAccount;
  const FirstName = state.Customer.CustomerAccount.FirstName;
  const LastName = state.Customer.CustomerAccount.LastName;
  const PhoneNumber = state.Customer.CustomerAccount.PhoneNumber;
  const Email = state.Customer.CustomerAccount.Email;
  const Address = state.Customer.CustomerAccount.Address;
  const CustomerProductId = state.Customer.CustomerAccount.CustomerProductId;
  const ListCustomerProduct = state.CustomerProduct.ListCustomerProduct;
  try {
    yield put(toggle.changeLoadingTrue());

    const AllProduct: any[] = [];
    if (CustomerProductId[0] === 'Pilih Semua Produk') {
      for (var i = 0; i < ListCustomerProduct.length; i++) {
        AllProduct.push(ListCustomerProduct[i].id);
      }
    } else {
    }
    const ProductPost =
      CustomerProductId[0] === 'Pilih Semua Produk'
        ? AllProduct
        : CustomerProductId;

    yield call(
      axios.put,
      process.env.REACT_APP_URL +
        '/Profile/account/customer/' +
        IdCustomerAccount,
      {
        id: IdCustomerAccount,
        firstName: FirstName,
        lastName: LastName,
        email: Email,
        productBlock: ProductPost,
        phone: PhoneNumber,
        address: Address,
      },
      { headers: config }
    );
    yield put(ActionEditCustomerAccount.statusCustomerAccount());
    yield put(ActionCustomerAccount.clearDataCustomerAccount());
    yield put(ActionCustomerAccount.getCustomerAccount());
    yield put(ActionListCustomerProduct.clearListCustomerProduct());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil di simpan</b>
        </p>
      ),

      onOk() {},
    });
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      const propsError = ErrorMassage('Edit akun pelanggan');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* SM_editCustomerAccount() {
  yield takeLatest('EDIT_CUSTOMER_ACCOUNT', S_editCustomerAccount);
}
