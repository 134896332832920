import '../../../../styles/ResendStyle/index.css';

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Icon,
  Radio,
  Row,
  Select,
  Tag,
  Tooltip,
} from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../materials/CardMaterial';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../../materials/ModalMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';
import icon_resend_failed from '../../../../image/icon_resend_failed.png';
import icon_resend_success from '../../../../image/icon_resend_success.png';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';

interface IProps {
  handleViewStatusDelivery: any;
  handleModal: any;
  handleSubmit: any;
  handleRemoveAllListMprocess: any;
  RegexReplaceOthers: any;
  RegexReplaceInfoOthers: any;
  ValidateButtonSubmit: any;
  ValidateBranchMprocess: any;
  handleBlurBranchMprocess: any;
  ValidateReasonForm: any;
  ValidateCourierMprocess: any;
  handleBlurCourierMprocess: any;
  ValidateCustomerMprocess: any;
  handleBlurCustomerMprocess: any;
  ValidateReasonMprocess: any;
  handleBlurCustomerStatus: any;
  ValidateWaybillMprocess: any;
  handleBlurWaybill: any;
  handleBlurReceiveName: any;
  ValidateReceiveNameMprocess: any;
  handleBlurInfoMprocess: any;
  ValidateInfoMprocess: any;
  GetReason: any;
  valueMprocessDate: any;
  GetBranch: any;
  GetReceiveName: any;
  GetWaybill: any;
  ListCourier: any;
  ValueGetBranch: any;
  ValueGetCourier: any;
  ListCustomer: any;
  ListStatus: any;
  ValueGetCustomer: any;
  GetInfoMprocess: any;
  ValueGetInfoMprocess: any;
  ValidateMprocessDate: any;
  handleGetCustomerStatus: any;
  handleValidateManualProcessDate: any;
  handleResetCourier: any;
  handlePutBranchMprocess: any;
  handleGetBranch: any;
  handleGetCourier: any;
  handlePutCourierMprocess: any;
  handleGetCustomer: any;
  handlePutCustomerMprocess: any;
  handlePutManualProcessDate: any;
  handlePutReceiveName: any;
  handlePutWaybill: any;
  handlePutInfoMprocess: any;
  GetInfoOthersMprocess: any;
  handlePutInfoOthersMprocess: any;
  handleRadiobutton: any;
  handlePutCustomerStatus: any;
  handleResetReason: any;
  GetRadiobuttonStatus: any;
  ValidateReceiveName: any;
  GetListSubmit: any;
  disableCourierForm: any;
  handleCollapsListMprocess: any;
  CollapsListMprocess: any;
  CollapsKeyListMprocess: any;
  handleBlurInfoOthersMprocess: any;
  handlePutCustomerStatusOthers: any;
  handleBlurCustomerStatusOthers: any;
  GetReasonOthers: any;
  ValidateReasonOthersMprocess: any;
  ValidateInfoOthersMprocess: any;
  helpInfoOthersMprocess: any;
  handleClearInfoOthersForm: any;
  handleClearCustomerStatusOthers: any;
  handleChangeRadioButtonType: any;
  handleModalRadiobutton: any;
  GetRadiobuttonType: any;
  BranchList: any;
  isLoading: any;
  modal: any;
  loadingSelectBranch: any;
  loadingSelectCourier: any;
  loadingSelectCustomer: any;
  loadingSelectStatus: any;
  GetBarcodeCheckBox: any;
  handleChangeBarcodeCheckBox: any;
  isFreeText: any;
}

export default class ManualProcessComponent extends Component<IProps> {
  render() {
    const { Option } = Select;
    const BranchListValue = this.props.BranchList;
    console.log(this.props.GetListSubmit);

    const titleFieldStatus = () => {
      if (
        this.props.GetRadiobuttonStatus === 'ReturnToSuccess' ||
        this.props.GetRadiobuttonStatus === 'Success'
      ) {
        return 'Status Penerima';
      } else {
        return 'Alasan Dikembalikan';
      }
    };
    const valueReason =
      this.props.GetReason === null ? (
        <span
          style={{ color: '#969696' }}
        >{`Masukan ${titleFieldStatus()}`}</span>
      ) : (
        this.props.GetReason
      );

    const titleFieldOtherStatus = () => {
      if (
        this.props.GetRadiobuttonStatus === 'ReturnToSuccess' ||
        this.props.GetRadiobuttonStatus === 'Success'
      ) {
        return 'Status Tambahan';
      } else {
        return 'Alasan Tambahan';
      }
    };
    const notifValidateOtherStatus =
      this.props.ValidateReasonOthersMprocess === 'error'
        ? `${titleFieldOtherStatus()} tidak boleh kosong!`
        : '';
    return (
      <div>
        <SpinMaterial
          spinning={this.props.isLoading}
          style={{}}
          size={'large'}
          content={
            <React.Fragment>
              <CardMaterial
                extra={''}
                title={
                  <b style={{ color: '#11BEFF' }}>
                    PROSES MANUAL - SCAN SELESAI
                  </b>
                }
                style={{ borderRadius: '10px' }}
                content={
                  <React.Fragment>
                    {this.props.GetRadiobuttonType === 'forAdmin' ||
                    this.props.GetRadiobuttonType === null ? (
                      <Radio.Group
                        onChange={this.props.handleRadiobutton}
                        value={
                          (this.props.GetRadiobuttonStatus === 'Success' &&
                            this.props.GetRadiobuttonType === null) ||
                          (this.props.GetRadiobuttonStatus === 'Success' &&
                            this.props.GetRadiobuttonType === 'forAdmin')
                            ? 1
                            : (this.props.GetRadiobuttonStatus === 'Return' &&
                                this.props.GetRadiobuttonType === null) ||
                              (this.props.GetRadiobuttonStatus === 'Return' &&
                                this.props.GetRadiobuttonType === 'forAdmin')
                            ? 2
                            : null
                        }
                        // class="marginTopDefault"
                        style={{ marginTop: '15px' }}
                      >
                        <Radio
                          style={{ marginRight: 40 }}
                          value={1}
                          disabled={false}
                        >
                          Diterima
                        </Radio>
                        <Radio value={2} disabled={false}>
                          Dikembalikan
                        </Radio>
                      </Radio.Group>
                    ) : (
                      <Radio.Group
                        onChange={this.props.handleRadiobutton}
                        value={
                          this.props.GetRadiobuttonStatus ===
                            'ReturnToSuccess' &&
                          this.props.GetRadiobuttonType === 'forSuperAdmin'
                            ? 3
                            : this.props.GetRadiobuttonStatus ===
                                'SuccessToReturn' &&
                              this.props.GetRadiobuttonType === 'forSuperAdmin'
                            ? 4
                            : null
                        }
                        style={{ borderRadius: '10px', marginTop: '15px' }}
                      >
                        <Radio
                          style={{ marginRight: 40 }}
                          value={3}
                          disabled={false}
                        >
                          Dikembalikan Ke Diterima
                        </Radio>
                        <Radio value={4} disabled={false}>
                          Diterima Ke Dikembalikan
                        </Radio>
                      </Radio.Group>
                    )}
                    <ButtonMaterial
                      disabled={''}
                      className={'btnEditDefault floatRight'}
                      handleSubmit={this.props.handleModal}
                      teksButton={<b>Ubah</b>}
                      size={'middle'}
                      shape={'round'}
                      type={'primary'}
                    />
                    <ModalMaterial
                      className={''}
                      width={'40%'}
                      style={{}}
                      teksTitle={
                        <span style={{ textAlign: 'center', color: '#FCC642' }}>
                          Apakah anda yakin Ingin merubah radio button ?
                        </span>
                      }
                      visible={this.props.modal}
                      onCancel={this.props.handleModalRadiobutton}
                      content={
                        <Row>
                          <div>
                            <Col>
                              <div
                                style={{
                                  marginTop: '40px',
                                  float: 'right',
                                  paddingRight: '20px',
                                }}
                              >
                                <ButtonMaterial
                                  disabled={''}
                                  handleSubmit={
                                    this.props.handleChangeRadioButtonType
                                  }
                                  teksButton={<b>Ya</b>}
                                  size={'small'}
                                  type={'primary'}
                                  style={{
                                    colorFont: '#11BEFF',
                                    height: '30px',
                                    width: '60px',
                                    borderRadius: '12px',
                                  }}
                                />
                              </div>

                              <div
                                style={{
                                  marginTop: '40px',
                                  paddingRight: '20px',
                                  float: 'right',
                                }}
                              >
                                <ButtonMaterial
                                  disabled={''}
                                  handleSubmit={
                                    this.props.handleModalRadiobutton
                                  }
                                  teksButton={<b>Tidak</b>}
                                  size={'small'}
                                  type={'primary'}
                                  style={{
                                    colorFont: '#11BEFF',
                                    height: '30px',
                                    width: '60px',
                                    borderRadius: '12px',
                                    background: 'red',
                                    borderColor: 'red',
                                  }}
                                />
                              </div>
                            </Col>
                          </div>
                        </Row>
                      }
                    />
                  </React.Fragment>
                }
              />
              <div
                style={{
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  marginTop: '20px',
                }}
              >
                <CardMaterial
                  extra={''}
                  title={''}
                  style={{ borderRadius: '10px' }}
                  content={
                    <React.Fragment>
                      <Row>
                        <Col span={12} style={{ paddingRight: '20px' }}>
                          <div>
                            <span>
                              <b>Posko</b>
                            </span>
                            <Form.Item
                              validateStatus={
                                this.props.ValidateBranchMprocess === 'error'
                                  ? 'error'
                                  : ''
                              }
                              help={
                                this.props.ValidateBranchMprocess === 'error'
                                  ? 'Posko tidak boleh kosong!!!'
                                  : ''
                              }
                            >
                              <Select
                                loading={this.props.loadingSelectBranch}
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                disabled={false}
                                showSearch
                                onSelect={this.props.handleResetCourier}
                                onChange={this.props.handlePutBranchMprocess}
                                onBlur={this.props.handleBlurBranchMprocess}
                                value={this.props.ValueGetBranch}
                                onFocus={this.props.handleGetBranch}
                              >
                                {BranchListValue.map(
                                  (data: any, index: any) => (
                                    <Option key={index} value={data.id}>
                                      {data.name}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </Form.Item>
                          </div>
                          <div>
                            <span>
                              <b>Kurir</b>
                            </span>
                            <Form.Item
                              validateStatus={
                                this.props.ValidateCourierMprocess === 'error'
                                  ? 'error'
                                  : ''
                              }
                              help={
                                this.props.ValidateCourierMprocess === 'error'
                                  ? 'Kurir tidak boleh kosong!!!'
                                  : ''
                              }
                            >
                              <Select
                                loading={this.props.loadingSelectCourier}
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                disabled={this.props.disableCourierForm}
                                showSearch
                                onChange={this.props.handlePutCourierMprocess}
                                onBlur={this.props.handleBlurCourierMprocess}
                                value={this.props.ValueGetCourier}
                                onFocus={this.props.handleGetCourier}
                              >
                                {this.props.ListCourier.map(
                                  (data: any, index: any) => (
                                    <Option key={index} value={data.id}>
                                      {data.firstName}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </Form.Item>
                          </div>
                          <div>
                            <span>
                              <b>Pelanggan</b>
                            </span>
                            <Form.Item
                              validateStatus={
                                this.props.ValidateCustomerMprocess === 'error'
                                  ? 'error'
                                  : ''
                              }
                              help={
                                this.props.ValidateCustomerMprocess === 'error'
                                  ? 'Pelanggan tidak boleh kosong!!!'
                                  : ''
                              }
                            >
                              <Select
                                loading={this.props.loadingSelectCustomer}
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                disabled={false}
                                showSearch
                                onSelect={this.props.handleResetReason}
                                onChange={this.props.handlePutCustomerMprocess}
                                onBlur={this.props.handleBlurCustomerMprocess}
                                value={this.props.ValueGetCustomer}
                                onFocus={this.props.handleGetCustomer}
                              >
                                {this.props.ListCustomer.map(
                                  (data: any, index: any) => (
                                    <Option key={index} value={data.id}>
                                      {data.name}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </Form.Item>
                          </div>
                          <div>
                            <span>
                              <b>{titleFieldStatus()}</b>
                            </span>
                            <Form.Item
                              validateStatus={
                                this.props.ValidateReasonMprocess === 'error'
                                  ? 'error'
                                  : ''
                              }
                              help={
                                this.props.ValidateReasonMprocess === 'error'
                                  ? `${titleFieldStatus()} tidak boleh kosong!`
                                  : ''
                              }
                            >
                              <Select
                                loading={this.props.loadingSelectStatus}
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                disabled={this.props.ValidateReasonForm}
                                showSearch
                                onSelect={
                                  this.props.handleClearCustomerStatusOthers
                                }
                                onChange={this.props.handlePutCustomerStatus}
                                onBlur={this.props.handleBlurCustomerStatus}
                                value={valueReason}
                                onFocus={this.props.handleGetCustomerStatus}
                              >
                                {this.props.ListStatus.map(
                                  (data: any, index: any) => (
                                    <Option
                                      key={data.id}
                                      label={data.additionalText}
                                      value={data.description}
                                    >
                                      {data.description}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </Form.Item>
                          </div>
                          {(this.props.RegexReplaceOthers === 'lain-lain' &&
                            this.props.GetReason !== null) ||
                          this.props.isFreeText === true ? (
                            <div>
                              <span>
                                <b>{titleFieldOtherStatus()}</b>
                              </span>
                              <FormInputTextMaterial
                                validateType={true}
                                value={this.props.GetReasonOthers}
                                disabled={false}
                                placeholder={`Masukan ${titleFieldOtherStatus()}`}
                                style={{ borderRadius: '10px' }}
                                onChange={
                                  this.props.handlePutCustomerStatusOthers
                                }
                                onBlur={
                                  this.props.handleBlurCustomerStatusOthers
                                }
                                validatestatus={
                                  this.props.ValidateReasonOthersMprocess
                                }
                                help={notifValidateOtherStatus}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </Col>
                        <Col span={12} style={{ paddingLeft: '20px' }}>
                          <div>
                            <span>
                              <b>Tanggal</b>
                            </span>
                            <Form.Item
                              validateStatus={
                                this.props.ValidateMprocessDate === 'error'
                                  ? 'error'
                                  : ''
                              }
                              help={
                                this.props.ValidateMprocessDate === 'error'
                                  ? 'Tanggal proses manual tidak boleh kosong!!!'
                                  : ''
                              }
                            >
                              <DatePicker
                                style={{ width: '100%' }}
                                locale={locale}
                                format={'DD/MM/YYYY'}
                                placeholder={'Pilih tanggal'}
                                onOpenChange={
                                  this.props.handleValidateManualProcessDate
                                }
                                value={this.props.valueMprocessDate}
                                onChange={this.props.handlePutManualProcessDate}
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <span>
                              <b>Nama Penerima</b>
                            </span>
                            <FormInputTextMaterial
                              validateType={true}
                              value={this.props.GetReceiveName}
                              disabled={this.props.ValidateReceiveName}
                              placeholder={'Masukan nama penerima'}
                              style={{ borderRadius: '10px' }}
                              onChange={this.props.handlePutReceiveName}
                              onBlur={this.props.handleBlurReceiveName}
                              validatestatus={
                                this.props.ValidateReceiveNameMprocess ===
                                'error'
                                  ? 'error'
                                  : ''
                              }
                              help={
                                this.props.ValidateReceiveNameMprocess ===
                                'error'
                                  ? 'Nama penerima tidak boleh kosong!!!'
                                  : ''
                              }
                            />
                          </div>
                          <div>
                            <Row>
                              <Col span={18}>
                                <span>
                                  {this.props.GetBarcodeCheckBox ? (
                                    <b>Barcode</b>
                                  ) : (
                                    <b>Waybill</b>
                                  )}
                                </span>
                                <FormInputTextMaterial
                                  validateType={true}
                                  value={this.props.GetWaybill}
                                  disabled={false}
                                  placeholder={
                                    this.props.GetBarcodeCheckBox
                                      ? 'Masukan Barcode'
                                      : 'Masukan Waybill'
                                  }
                                  style={{ borderRadius: '10px' }}
                                  onChange={this.props.handlePutWaybill}
                                  onBlur={this.props.handleBlurWaybill}
                                  validatestatus={
                                    this.props.ValidateWaybillMprocess ===
                                    'error'
                                      ? 'error'
                                      : ''
                                  }
                                  help={
                                    this.props.ValidateWaybillMprocess ===
                                    'error'
                                      ? this.props.GetBarcodeCheckBox
                                        ? 'Barcode tidak boleh kosong!!!'
                                        : 'Waybill tidak boleh kosong!!!'
                                      : ''
                                  }
                                />
                              </Col>
                              <Col span={6}>
                                <Tooltip
                                  placement="top"
                                  title={'Ceklist untuk barcode'}
                                >
                                  <Checkbox
                                    style={{
                                      paddingTop: 30,
                                      paddingLeft: '24px',
                                    }}
                                    checked={this.props.GetBarcodeCheckBox}
                                    onChange={
                                      this.props.handleChangeBarcodeCheckBox
                                    }
                                  />
                                </Tooltip>
                                <span
                                  style={{
                                    paddingLeft: '18px',
                                    fontStyle: 'italic',
                                    color: '#969696',
                                  }}
                                >
                                  Barcode
                                </span>
                              </Col>
                            </Row>
                          </div>
                          <div>
                            <span>
                              <b>Keterangan</b>
                            </span>
                            <Form.Item
                              validateStatus={
                                this.props.ValidateInfoMprocess === 'error'
                                  ? 'error'
                                  : ''
                              }
                              help={
                                this.props.ValidateInfoMprocess === 'error'
                                  ? 'Keterangan tidak boleh kosong!!!'
                                  : ''
                              }
                            >
                              <Select
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                disabled={false}
                                showSearch
                                onSelect={this.props.handleClearInfoOthersForm}
                                onChange={this.props.handlePutInfoMprocess}
                                onBlur={this.props.handleBlurInfoMprocess}
                                value={this.props.ValueGetInfoMprocess}
                              >
                                <Option key={''} value={'Jaringan error'}>
                                  {'Jaringan error'}
                                </Option>
                                <Option
                                  key={''}
                                  value={'Paket data kurir habis'}
                                >
                                  {'Paket data kurir habis'}
                                </Option>
                                <Option key={''} value={'Melebihi SLA'}>
                                  {'Melebihi SLA'}
                                </Option>
                                <Option key={''} value={'lain-lain'}>
                                  {'Lain-lain'}
                                </Option>
                              </Select>
                            </Form.Item>
                          </div>
                          {this.props.RegexReplaceInfoOthers === 'lain-lain' &&
                          this.props.GetInfoMprocess !== null ? (
                            <div>
                              <span>
                                <b>Masukan Keterangan</b>
                              </span>
                              <FormInputTextMaterial
                                validateType={true}
                                value={this.props.GetInfoOthersMprocess}
                                disabled={false}
                                placeholder={'Masukan Keterangan lain-lain'}
                                style={{ borderRadius: '10px' }}
                                onChange={
                                  this.props.handlePutInfoOthersMprocess
                                }
                                onBlur={this.props.handleBlurInfoOthersMprocess}
                                validatestatus={
                                  this.props.ValidateInfoOthersMprocess
                                }
                                help={this.props.helpInfoOthersMprocess}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ButtonMaterial
                            disabled={this.props.ValidateButtonSubmit}
                            handleSubmit={this.props.handleSubmit}
                            teksButton={<b>Simpan</b>}
                            size={'middle'}
                            shape={'round'}
                            type={'primary'}
                            className={'btnDefaultSubmit floatRight'}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  }
                />
              </div>
            </React.Fragment>
          }
        />
        {this.props.GetListSubmit.map((data: any, index: any) => (
          <div style={{ paddingTop: '2px' }}>
            <div className="header">
              <div onClick={() => this.props.handleCollapsListMprocess(index)}>
                <Row style={{ margin: '15px' }}>
                  <Col span={1}>
                    <div style={{ paddingTop: '4px' }}>
                      <Icon
                        type={
                          this.props.CollapsKeyListMprocess === index &&
                          this.props.CollapsListMprocess === true
                            ? 'down'
                            : 'right'
                        }
                      />
                    </div>
                  </Col>
                  <Col span={21}>
                    <div
                      style={{
                        fontSize: '1.2em',
                        color:
                          data.data.message === null ? '#31E116' : '#FF5858',
                      }}
                    >
                      <b>
                        {data.Status === 'dataNotFound' ? (
                          <div>
                            <span style={{ fontStyle: 'italic' }}>
                              "{data.data.Waybill}"
                            </span>
                            <span>-Tidak Ditemukan</span>
                          </div>
                        ) : (
                          data.data.waybillCode +
                          (data.data.message ===
                          `Failed update, reason return or status receiver not compatible with waybill (${data.data.waybillCode})`
                            ? '-' +
                              (data.data.type === 1
                                ? 'Diterima-(No Waybill tidak sesuai dengan Pelanggan)'
                                : data.data.type === 2
                                ? 'Dikembalikan-(No Waybill tidak sesuai dengan Pelanggan)'
                                : data.data.type === 3
                                ? 'Dikembalikan Ke Diterima-(No Waybill tidak sesuai dengan Pelanggan)'
                                : data.data.type === 4
                                ? 'Diterima Ke Dikembalikan-(No Waybill tidak sesuai dengan Pelanggan)'
                                : '')
                            : '-' +
                              (data.data.type === 1
                                ? 'Diterima'
                                : data.data.type === 2
                                ? 'Dikembalikan'
                                : data.data.type === 3
                                ? 'Dikembalikan Ke Diterima'
                                : data.data.type === 4
                                ? 'Diterima Ke Dikembalikan'
                                : ''))
                        )}
                      </b>
                    </div>
                  </Col>
                  <Col span={2}>
                    <img
                      src={
                        data.data.message === null
                          ? icon_resend_success
                          : icon_resend_failed
                      }
                      alt="status icon"
                      style={{ float: 'right', width: '30px' }}
                    />
                  </Col>
                </Row>
              </div>

              {this.props.CollapsKeyListMprocess === index &&
              this.props.CollapsListMprocess === true ? (
                <div>
                  <hr
                    style={{
                      display: 'block',
                      height: '1px',
                      width: '100%',
                      border: '0',
                      borderTop: '1px solid #ccc',
                      margin: '0',
                      padding: '0',
                    }}
                  />
                  <div style={{ width: '100%', padding: '15px' }}>
                    {data.Status === 'dataNotFound' ? (
                      <div
                        style={{
                          color: '#969696',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        Tidak ditemukan
                      </div>
                    ) : (
                      <div style={{ fontSize: '1.0em' }}>
                        <Row>
                          <Col
                            span={12}
                            style={{
                              paddingLeft: '42px',
                              paddingRight: '15px',
                            }}
                          >
                            <Row>
                              <Col span={9}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#969696',
                                  }}
                                >
                                  <span>Waybill</span>
                                </div>
                              </Col>
                              <Col span={15}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#000000',
                                  }}
                                >
                                  <span>{data.data.waybillCode}</span>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={9}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#969696',
                                  }}
                                >
                                  <span>Nama Pemilik</span>
                                </div>
                              </Col>
                              <Col span={15}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#000000',
                                  }}
                                >
                                  <span>{data.data.shipToName}</span>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={9}>
                                <div>
                                  <span style={{ color: '#969696' }}>
                                    Alamat
                                  </span>
                                </div>
                              </Col>
                              <Col span={15}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#000000',
                                  }}
                                >
                                  <span>{`${Object.values(
                                    data.AddressArray
                                  ).join(' ')}`}</span>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={9}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#969696',
                                  }}
                                >
                                  <span>Status Pengiriman Terakhir</span>
                                </div>
                              </Col>
                              <Col span={15}>
                                <div style={{ color: '#000000' }}>
                                  <Tag
                                    color={
                                      data.data.deliveryStatus === 'Success'
                                        ? 'green'
                                        : data.data.deliveryStatus === 'Return'
                                        ? 'red'
                                        : 'gray'
                                    }
                                  >
                                    {data.data.deliveryStatus === 'Return' ? (
                                      <span style={{ fontSize: '14px' }}>
                                        Dikembalikan
                                      </span>
                                    ) : data.data.deliveryStatus ===
                                      'Success' ? (
                                      <span style={{ fontSize: '14px' }}>
                                        Diterima
                                      </span>
                                    ) : (
                                      <span style={{ fontSize: '14px' }}>
                                        Diproses
                                      </span>
                                    )}
                                  </Tag>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={9}>
                                {data.data.deliveryStatus === 'OnProcces' ? (
                                  ''
                                ) : (
                                  <div
                                    style={{
                                      paddingBottom: '15px',
                                      color: '#969696',
                                    }}
                                  >
                                    <span>
                                      {data.data.deliveryStatus === 'Success'
                                        ? 'Tanggal Diterima'
                                        : 'Tanggal Dikembalikan'}
                                    </span>
                                  </div>
                                )}
                              </Col>
                              <Col span={15}>
                                {data.data.deliveryStatus === 'OnProcces' ? (
                                  ''
                                ) : (
                                  <div
                                    style={{
                                      paddingBottom: '15px',
                                      color: '#000000',
                                    }}
                                  >
                                    {`(  ${moment(
                                      data.data.itemFinishedTime
                                    ).format('DD/MM/YYYY')} )`}
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col span={9}>
                                {data.data.deliveryStatus === 'OnProcces' ? (
                                  ''
                                ) : (
                                  <div
                                    style={{
                                      paddingBottom: '15px',
                                      color: '#969696',
                                    }}
                                  >
                                    {data.data.deliveryStatus === 'Success' ? (
                                      <span>Penerima Terakhir</span>
                                    ) : (
                                      <span>Alasan Dikembalikan</span>
                                    )}
                                  </div>
                                )}
                              </Col>
                              <Col span={15}>
                                {data.data.deliveryStatus === 'OnProcces' ? (
                                  ''
                                ) : (
                                  <div style={{ color: '#000000' }}>
                                    <span>
                                      {data.data.deliveryStatus === 'Return'
                                        ? data.data.returnedReason
                                        : (data.data.receiverName === ''
                                            ? ''
                                            : data.data.receiverName) +
                                          `( ${data.data.receiverTitle} )`}
                                    </span>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col span={12} style={{ paddingLeft: '15px' }}>
                            <Row>
                              <Col>
                                <div
                                  style={{
                                    padding: 5 % 0,
                                    backgroundColor: '#B0E0E6',
                                    textAlign: 'center',
                                    verticalAlign: 'middle',
                                  }}
                                >
                                  <div
                                    style={{
                                      color: '#007FAF',
                                      padding: 10 % 0,
                                    }}
                                  >
                                    <b>Pembaharuan Data</b>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#969696',
                                  }}
                                ></div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={9}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#969696',
                                  }}
                                >
                                  <span>Status Pengiriman</span>
                                </div>
                              </Col>
                              <Col span={15}>
                                <div style={{ color: '#000000' }}>
                                  {data.data.deliveryStatusUpdate === null ? (
                                    '-'
                                  ) : (
                                    <Tag
                                      color={
                                        data.data.deliveryStatusUpdate ===
                                        'Success'
                                          ? 'green'
                                          : 'red'
                                      }
                                    >
                                      {data.data.deliveryStatusUpdate ===
                                      null ? (
                                        '-'
                                      ) : data.data.deliveryStatusUpdate ===
                                        'Success' ? (
                                        <span style={{ fontSize: '14px' }}>
                                          Diterima
                                        </span>
                                      ) : (
                                        <span style={{ fontSize: '14px' }}>
                                          Dikembalikan
                                        </span>
                                      )}
                                    </Tag>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={9}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#969696',
                                  }}
                                >
                                  {data.data.deliveryStatusUpdate ===
                                  'Success' ? (
                                    <span>Penerima</span>
                                  ) : (
                                    <span>Alasan Dikembalikan</span>
                                  )}
                                </div>
                              </Col>
                              <Col span={15}>
                                <div style={{ color: '#000000' }}>
                                  <span>
                                    {data.data.deliveryStatusUpdate === null
                                      ? '-'
                                      : data.data.deliveryStatusUpdate ===
                                        'Return'
                                      ? data.data.returnedReasonUpdate
                                      : (data.data.receiverNameUpdate === ''
                                          ? ''
                                          : `${data.data.receiverNameUpdate} `) +
                                        `( ${data.data.receiverTitleUpdate} )`}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={9}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#969696',
                                  }}
                                >
                                  <span>
                                    {data.data.deliveryStatusUpdate ===
                                    'Success'
                                      ? 'Tanggal Diterima'
                                      : 'Tanggal Dikembalikan'}
                                  </span>
                                </div>
                              </Col>
                              <Col span={15}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#000000',
                                  }}
                                >
                                  {data.data.itemFinishedTimeUpdate === null
                                    ? '-'
                                    : `( 
                                      ${moment(
                                        data.data.itemFinishedTimeUpdate
                                      ).format('DD/MM/YYYY')} )`}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={9}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#969696',
                                  }}
                                >
                                  <span>Keterangan Proses Manual</span>
                                </div>
                              </Col>
                              <Col span={15}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#000000',
                                  }}
                                >
                                  {data.data.note}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <div
                          onClick={() =>
                            this.props.handleViewStatusDelivery(
                              data.data.waybillCode
                            )
                          }
                          style={{
                            paddingLeft: '42px',
                            paddingTop: '40px',
                            fontStyle: 'italic',
                            textDecoration: 'underline',
                            color: '#11BEFF',
                          }}
                        >
                          Lihat Status Pengiriman
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ))}
        {this.props.GetListSubmit.length !== 0 ? (
          <div style={{ width: '100%', paddingTop: '10px' }}>
            <Button
              style={{
                left: '50%',
                transform: 'translate(-50%)',
                borderRadius: '8px',
                background: 'red',
                borderColor: 'red',
                color: '#FFF',
              }}
              onClick={this.props.handleRemoveAllListMprocess}
            >
              <span>
                <b>Hapus semua list</b>
              </span>
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}
