import React from 'react';
import { Icon } from 'antd';
import { Form as FormFinal, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { InputText } from 'components/InputField';
import SpinMaterial from 'materials/SpinMaterial';
import Button from 'components/Button';
import RadioField from 'components/RadioField';

type FormDownloadDeleteComponentProps = {
  handleSubmit: (value: any) => void;
  isLoadingDownload?: boolean;
  isLoadingModal?: boolean;
  // VALUE FORM
  numberDocument: any;
  namePic: any;
  divitionPic: any;
  customerName: any;
  address: Array<string>;
  typeDownload: string;
  // FUNC FORM
  setNumberDocument: (value: any) => void;
  setNamePic: (value: any) => void;
  setDivitionPic: (value: any) => void;
  setCustomerName: (value: any) => void;
  setAddress: (value: Array<string>) => void;
  setTypeDownload: (value: string) => void;
};

export default function FormDownloadDeleteComponent({
  handleSubmit,
  isLoadingDownload = false,
  isLoadingModal = false,
  numberDocument,
  namePic,
  divitionPic,
  customerName,
  address,
  typeDownload,
  // FUNC FORM
  setNumberDocument,
  setNamePic,
  setDivitionPic,
  setCustomerName,
  setAddress,
  setTypeDownload,
}: FormDownloadDeleteComponentProps) {
  const requiredAddress = (value: any, index: number) => {
    if (index === 0 && !value) {
      return 'Alamat 1 wajib diisi';
    }

    if (value?.length > 70) {
      return 'Maksimal ketik 70 karakter';
    }

    return undefined;
  };

  const requiredArray = value =>
    value && value.length > 0 ? undefined : 'Required';

  return (
    <SpinMaterial
      spinning={isLoadingModal}
      size="large"
      content={
        <FormFinal
          onSubmit={handleSubmit}
          initialValues={{
            numberDocument,
            namePic,
            divitionPic,
            nameCustomer: customerName,
            address,
            type: typeDownload,
          }}
          mutators={{
            ...arrayMutators,
          }}
          validate={values => {
            const errors = {} as any;
            if (!values.numberDocument) {
              errors.numberDocument = 'Nomor Dokumen wajib diisi';
            }
            if (values?.numberDocument?.length > 50) {
              errors.numberDocument = 'Maksimal ketik 50 karakter';
            }
            if (!values.namePic) {
              errors.namePic = 'Nama PIC wajib diisi';
            }
            if (values?.namePic?.length > 70) {
              errors.namePic = 'Maksimal ketik 70 karakter';
            }
            if (values?.divitionPic?.length > 70) {
              errors.divitionPic = 'Maksimal ketik 70 karakter';
            }
            if (!values.nameCustomer) {
              errors.nameCustomer = 'Nama Pelanggan wajib diisi';
            }
            if (values?.nameCustomer?.length > 70) {
              errors.nameCustomer = 'Maksimal ketik 70 karakter';
            }
            return errors;
          }}
          render={({
            handleSubmit,
            submitting,
            valid,
            form: {
              mutators: { push },
            },
          }) => (
            <div
              style={{
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: 40,
                alignItems: 'flex-end',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 18,
                  alignItems: 'flex-start',
                }}
              >
                <Field name="type">
                  {({ input }) => (
                    <RadioField
                      data={[
                        { value: 'Digital', label: 'Digital' },
                        { value: 'Manual', label: 'Manual' },
                      ]}
                      value={input.value}
                      onChange={value => setTypeDownload(value)}
                    />
                  )}
                </Field>
                <Field
                  name="numberDocument"
                  render={input => (
                    <InputText
                      label="Nomor Dokumen"
                      placeholder="Isi Nomor Dokumen"
                      formStyle={{ margin: 0, width: '100%' }}
                      {...input}
                      onChange={value => setNumberDocument(value)}
                    />
                  )}
                />
                <Field
                  name="namePic"
                  render={input => (
                    <InputText
                      label="Nama PIC"
                      placeholder="Isi Nama PIC"
                      formStyle={{ margin: 0, width: '100%' }}
                      {...input}
                      onChange={value => setNamePic(value)}
                    />
                  )}
                />
                <Field
                  name="divitionPic"
                  render={input => (
                    <InputText
                      label="Divisi/Department PIC"
                      placeholder="Isi Divisi/Department PIC"
                      formStyle={{ margin: 0, width: '100%' }}
                      addtionalLabel="(Optional)"
                      {...input}
                      onChange={value => setDivitionPic(value)}
                    />
                  )}
                />
                <Field
                  name="nameCustomer"
                  render={input => (
                    <InputText
                      label="Nama Pelanggan"
                      placeholder="Isi Nama Pelanggan"
                      formStyle={{ margin: 0, width: '100%' }}
                      {...input}
                      onChange={value => setCustomerName(value)}
                    />
                  )}
                />
                <FieldArray name="address" validate={requiredArray}>
                  {({ fields }) => (
                    <>
                      {() => {
                        setAddress(fields?.value);
                      }}
                      {fields.map((name, index) => (
                        <Field
                          key={name}
                          name={`${name}`}
                          validate={value => requiredAddress(value, index)}
                          render={input => (
                            <div
                              style={{
                                width: '100%',
                                position: 'relative',
                              }}
                            >
                              <InputText
                                label={`Alamat ${index + 1}`}
                                addtionalLabel={index + 1 !== 1 && '(Optional)'}
                                placeholder={`Isi Alamat ${index + 1}`}
                                formStyle={{ margin: 0, width: '100%' }}
                                {...input}
                              />
                              {index + 1 !== 1 && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 8,
                                    color: 'red',
                                    fontWeight: 600,
                                  }}
                                  onClick={() => fields.remove(index)}
                                >
                                  <Icon type="delete" />
                                  <p style={{ margin: 0 }}>Hapus</p>
                                </div>
                              )}
                            </div>
                          )}
                        />
                      ))}
                      <Button
                        disabled={
                          fields?.value.includes('') ||
                          fields?.value.includes(undefined) ||
                          fields?.value.length >= 3
                        }
                        text="Tambah Alamat"
                        borderRadius="small"
                        variant="primary-outline"
                        startIcon="plus"
                        size="small"
                        onClick={() =>
                          fields &&
                          fields?.value?.length < 3 &&
                          push('address', undefined)
                        }
                      />
                    </>
                  )}
                </FieldArray>
              </div>
              <Button
                text="Download"
                borderRadius="small"
                onClick={handleSubmit}
                disabled={submitting || !valid}
                loading={isLoadingDownload}
              />
            </div>
          )}
        />
      }
    />
  );
}
