import * as ActionAuth from '../../../core/action//Auth';
import * as ActionFilter from '../../../core/action/DataDelivery/ActionFormDataDelivery';
import * as ActionLogin from '../../Login/Store';
import * as ActionPagination from '../../../core/action/Pagination/ActionPagination';
import * as ActionToggle from '../../../core/action/toggle';
import * as SelectorApp from '../../App/Selector/AppSelector';
import * as SelectorDeliveryData from '../../DeliveryData/Selector/DeliveryDataSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

function* S_getSearchDataDeliveryProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = yield select(
    SelectorApp.makeIsAuthStatusTokenRefresh()
  );
  const IdBranchFormAuth = yield select(SelectorApp.makeIsAuthBranch());
  const RoleFormAuth = yield select(SelectorApp.makeIsAuthRole());
  const PagePagination = state.Pagination.PagePagination - 1;
  const SizeDataPagination = state.Pagination.SizeDataPagination;
  const RadioButton = state.DataDelivery.Filter.RadioButton;
  const valueSearch =
    state.Toggle.ValueSearch === '' ? null : state.Toggle.ValueSearch;
  const DeliveStat = yield select(
    SelectorDeliveryData.makeDeliveryDataStatusSelector()
  );
  const PhysicalDocument = yield select(
    SelectorDeliveryData.makeDeliveryDataPhysicalDocumentSelector()
  );
  const DateFrom = moment(
    yield select(SelectorDeliveryData.makeDeliveryDataCycleStartSelector())
  ).format('YYYYMMDD');
  const DateTo = moment(
    yield select(SelectorDeliveryData.makeDeliveryDataCycleEndSelector())
  ).format('YYYYMMDD');
  const customer = yield select(
    SelectorDeliveryData.makeDeliveryDataCustomerSelector()
  );
  const customerProduct = yield select(
    SelectorDeliveryData.makeDeliveryDataCustomerProductSelector()
  );
  const branch = yield select(
    SelectorDeliveryData.makeDeliveryDataBranchSelector()
  );
  const courier = yield select(
    SelectorDeliveryData.makeDeliveryDataCourierSelector()
  );
  const Customer = customer === null ? 0 : customer;
  const CustomerProduct = customerProduct === null ? 0 : customerProduct;
  const Branch = branch === null ? 0 : branch;
  const Courier = courier === null ? 0 : courier;
  const paramDeliveStat = `${Object.values(DeliveStat).join('%2C')}`;
  const ConditionBranch = RoleFormAuth === 'Branch' ? IdBranchFormAuth : Branch;

  try {
    yield put(ActionToggle.changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Delivery/SearchDeliveryData/${paramDeliveStat}/${PagePagination}/${SizeDataPagination}?TimeStart=${DateFrom}&TimeEnd=${DateTo}&Name=${valueSearch}&Customer=${Customer}&Product=${CustomerProduct}&Branch=${ConditionBranch}&Courier=${Courier}&DeliveryTime=${RadioButton}&PhysicalDocument=${PhysicalDocument}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionFilter.setTableDeliveryFilter(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(ActionToggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionToggle.changeLoadingFalse());
  }
}
export function* SM_getSearchDataDeliveryAction() {
  yield takeLatest('GET_SEARCH_DATA_DELIVERY', S_getSearchDataDeliveryProcess);
}
