import * as ActionEditProfile from '../../../core/action/Profile/ActionProfile';
import * as ActionToggle from '../../../core/action/toggle/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import EditProfileComponent from '../../../components/Content/Profile/EditProfileComponent';
import { connect } from 'react-redux';

interface IProps {
  typeToggle: any;
  visible: any;
  isLoading: any;
  actionToggle: any;
  actionEditProfile: any;
  FirstName: any;
  LastName: any;
  UserName: any;
  NoTelp: any;
  Email: any;
  Photo: any;
  Address: any;
}
interface IState {
  ValidateFirstName: any;
  ValidateLastName: any;
  ValidateNoTelp: any;
  ValidateEmail: any;
  ValidateAddress: any;
  file: any;
  fileName: any;
  base64URL: any;
}

class EditProfileContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateFirstName: null,
      ValidateLastName: null,
      ValidateNoTelp: null,
      ValidateEmail: null,
      ValidateAddress: null,
      file: null,
      fileName: '',
      base64URL: null,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handlePutFirstName = this.handlePutFirstName.bind(this);
    this.handlePutLastName = this.handlePutLastName.bind(this);
    this.handlePutNoTelp = this.handlePutNoTelp.bind(this);
    this.handlePutEmail = this.handlePutEmail.bind(this);
    this.handleConvert = this.handleConvert.bind(this);
    this.handleGetBase64 = this.handleGetBase64.bind(this);
    this.handlePutAddress = this.handlePutAddress.bind(this);
    this.handleEditProfile = this.handleEditProfile.bind(this);
  }

  handlePutFirstName(event: any) {
    this.props.actionEditProfile.setFirstNameProfile(event.target.value);
    const RegexMinChar = /^.{3,}$/;

    if (event.target.value === '') {
      this.setState({ ValidateFirstName: 'error' });
    } else if (!RegexMinChar.test(event.target.value.toUpperCase())) {
      this.setState({ ValidateFirstName: 'minchar' });
    } else {
      this.setState({ ValidateFirstName: '' });
    }
  }

  handlePutLastName(event: any) {
    this.props.actionEditProfile.setLastNameProfile(event.target.value);
    const RegexMinChar = /^.{3,}$/;
    if (event.target.value === '') {
      this.setState({ ValidateLastName: 'error' });
    } else if (!RegexMinChar.test(event.target.value.toUpperCase())) {
      this.setState({
        ValidateLastName: 'minchar',
      });
    } else {
      this.setState({
        ValidateLastName: '',
      });
    }
  }

  handlePutNoTelp(event: any) {
    const panjang = event.target.value.length;
    const number = parseInt(event.target.value || 0, 10);
    const RegexPhone = /^[0-9\b]+$/;
    const reg = /^-?\d*(\\d*)?$/;

    if (reg.test(event.target.value)) {
      if (isNaN(number)) {
        return;
      }

      if (!('value' in this.props)) {
        this.props.actionEditProfile.setNoTelpProfile(
          event.target.value.replace(/^0+/, '')
        );
      }

      if (event.target.value === '') {
        this.setState({
          ValidateNoTelp: 'error',
        });
      } else if (!RegexPhone.test(event.target.value)) {
        this.setState({
          ValidateNoTelp: 'NonNumeric',
        });
      } else if (panjang < 7) {
        this.setState({
          ValidateNoTelp: 'Length',
        });
      } else {
        this.setState({
          ValidateNoTelp: '',
        });
      }
    }
  }

  handlePutEmail(event: any) {
    const RegexEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    const panjang = event.target.value.length;
    this.props.actionEditProfile.setEmailProfile(event.target.value);
    if (event.target.value === '') {
      this.setState({
        ValidateEmail: 'error',
      });
    } else if (panjang < 8) {
      this.setState({
        ValidateEmail: 'Length',
      });
    } else if (!RegexEmail.test(event.target.value)) {
      this.setState({
        ValidateEmail: 'NotEmail',
      });
    } else {
      this.setState({
        ValidateEmail: '',
      });
    }
  }

  handleConvert(e: any) {
    this.setState({
      fileName: e.target.value,
    });
    let { file } = this.state;
    file = e.target.files[0];
    this.handleGetBase64(file)
      .then((result: any) => {
        file['base64'] = result.toString().split(',')[1];
        this.props.actionEditProfile.setPhotoProfile(result.split(',')[1]);
        this.setState({
          base64URL: result.split(',')[1],
          file,
        });
      })
      .catch(error => {
        console.log(error);
      });

    this.setState({
      file: e.target.files[0],
    });
  }

  handleGetBase64 = (file: any) => {
    return new Promise(resolve => {
      let baseURL
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  handlePutAddress(event: any) {
    this.props.actionEditProfile.setAddressProfile(event.target.value);
    const panjang = event.target.value.length;
    if (event.target.value === '') {
      this.setState({
        ValidateAddress: 'error',
      });
    } else if (panjang < 10) {
      this.setState({
        ValidateAddress: 'Length',
      });
    } else {
      this.setState({
        ValidateAddress: '',
      });
    }
  }

  handleCloseModal() {
    this.props.actionToggle.changeModal();
    this.props.actionToggle.clearJenisToggle();
    this.props.actionEditProfile.getDataProfile();
    this.setState({
      ValidateFirstName: null,
      ValidateLastName: null,
      ValidateNoTelp: null,
      ValidateEmail: null,
      ValidateAddress: null,
      file: null,
      fileName: '',
      base64URL: null,
    });
  }

  handleEditProfile() {
    this.props.actionEditProfile.editDataProfile();
    this.setState({
      ValidateFirstName: null,
      ValidateLastName: null,
      ValidateNoTelp: null,
      ValidateEmail: null,
      ValidateAddress: null,
      file: null,
      fileName: '',
      base64URL: null,
    });
  }

  render() {
    return (
      <div>
        <EditProfileComponent
          typeToggle={this.props.typeToggle}
          visible={this.props.visible}
          isLoading={this.props.isLoading}
          firstName={this.props.FirstName}
          lastName={this.props.LastName}
          userName={this.props.UserName}
          noTelp={this.props.NoTelp}
          email={this.props.Email}
          photo={this.props.Photo}
          address={this.props.Address}
          validateFirstName={
            this.state.ValidateFirstName === 'minchar'
              ? 'error'
              : '' || this.state.ValidateFirstName === 'error'
              ? 'error'
              : ''
          }
          validateLastName={
            this.state.ValidateLastName === 'minchar'
              ? 'error'
              : '' || this.state.ValidateLastName === 'error'
              ? 'error'
              : ''
          }
          validateNoTelp={
            this.state.ValidateNoTelp === 'error'
              ? 'error'
              : '' || this.state.ValidateNoTelp === 'NonNumeric'
              ? 'error'
              : '' || this.state.ValidateNoTelp === 'Length'
              ? 'error'
              : ''
          }
          validateEmail={
            this.state.ValidateEmail === 'error'
              ? 'error'
              : '' || this.state.ValidateEmail === 'NotEmail'
              ? 'error'
              : '' || this.state.ValidateEmail === 'Length'
              ? 'error'
              : ''
          }
          validateAddress={
            this.state.ValidateAddress === 'Length'
              ? 'error'
              : '' || this.state.ValidateAddress === 'error'
              ? 'error'
              : ''
          }
          helpValidateFirstName={
            this.state.ValidateFirstName === 'minchar'
              ? 'Nama depan harus lebih dari 3 karakter'
              : '' || this.state.ValidateFirstName === 'error'
              ? 'Nama depan wajib diisi'
              : ''
          }
          helpValidateLastName={
            this.state.ValidateLastName === 'minchar'
              ? 'Nama belakang harus lebih dari 3 karakter'
              : '' || this.state.ValidateLastName === 'error'
              ? 'Nama belakang wajib diisi'
              : ''
          }
          helpValidateNoTelp={
            this.state.ValidateNoTelp === 'error'
              ? 'No telpon wajib diisi'
              : '' || this.state.ValidateNoTelp === 'NonNumeric'
              ? 'Tidak boleh selain angka!'
              : '' || this.state.ValidateNoTelp === 'Length'
              ? 'No telpon harus lebih dari 7 karakter'
              : ''
          }
          helpValidateEmail={
            this.state.ValidateEmail === 'error'
              ? 'Email  wajib diisi'
              : '' || this.state.ValidateEmail === 'NotEmail'
              ? 'Maaf format email salah'
              : '' || this.state.ValidateEmail === 'Length'
              ? 'Email harus lebih dari 8 karakter'
              : ''
          }
          helpValidateAddress={
            this.state.ValidateAddress === 'Length'
              ? 'Alamat  harus lebih dari 10 karakter'
              : '' || this.state.ValidateAddress === 'error'
              ? 'Alamat wajib diisi'
              : ''
          }
          handlePutFirstName={this.handlePutFirstName}
          handlePutLastName={this.handlePutLastName}
          handlePutNoTelp={this.handlePutNoTelp}
          handlePutEmail={this.handlePutEmail}
          handlePutAddress={this.handlePutAddress}
          handleConvert={this.handleConvert}
          handleEditProfile={this.handleEditProfile}
          handleCloseModal={this.handleCloseModal}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  typeToggle: state.Toggle.jenisToggle,
  isLoading: state.Toggle.isLoading,
  FirstName: state.Profile.FirstName,
  LastName: state.Profile.LastName,
  UserName: state.Profile.UserName,
  NoTelp: state.Profile.NoTelp,
  Email: state.Profile.Email,
  Photo: state.Profile.Photo,
  Address: state.Profile.Address,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionEditProfile: bindActionCreators(ActionEditProfile, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(EditProfileContainer);
