export const complaintStatusObj = {
  Proses: 'Proses', //0
  Selesai: 'Selesai', //1
  DalamAntrian: 'Dalam Antrian', //2
  KonfirmasiPelanggan: 'Konfirmasi Pelanggan', //3
  Empty: 'Empty', //4
  Review: 'Review', //5
};

export const pickupStatus = [
  {
    id: 'AssignPickup',
    name: 'Proses',
  },
  {
    id: 'SuccessPickup',
    name: 'Selesai',
  },
  {
    id: 'UploadPickup',
    name: 'Dalam Antrian',
  },
];
export const complaintStatus = [
  {
    id: 'Proses',
    name: complaintStatusObj.Proses,
  },
  {
    id: 'Selesai',
    name: complaintStatusObj.Selesai,
  },
  {
    id: 'DalamAntrian',
    name: complaintStatusObj.DalamAntrian,
  },
  {
    id: 'KonfirmasiPelanggan',
    name: complaintStatusObj.KonfirmasiPelanggan,
  },
  {
    id: 'Review',
    name: complaintStatusObj.Review,
  },
];
export const prefixComplaintSearchForm = [
  {
    id: 'NoPolis',
    name: 'No Polis',
  },
  {
    id: 'Barcode',
    name: 'No Barcode',
  },
  {
    id: 'Waybill',
    name: 'Waybill',
  },
  {
    id: 'Ticket',
    name: 'Nomor Tiket',
  },
];
export const typeDocument = [
  {
    id: 'Card',
    name: 'Kartu',
  },
  {
    id: 'Policy',
    name: 'Polis',
  },
  {
    id: 'Letter',
    name: 'Surat',
  },
  {
    id: 'Others',
    name: 'Lainnya',
  },
];
export const prefixValueSearch = [
  {
    id: 'waybill',
    name: 'Waybill',
  },
  // {
  //   id: 'barcode',
  //   name: 'Barcode',
  // },
  // {
  //   id: 'accountNumber',
  //   name: 'No Account',
  // },
  // {
  //   id: 'referenceNumber',
  //   name: 'No Referensi',
  // },
  // {
  //   id: 'policeNumber',
  //   name: 'No Polis',
  // },
  // {
  //   id: 'rekeningNumber',
  //   name: 'No Rekening',
  // },
  // {
  //   id: 'name',
  //   name: 'Nama',
  // },
];
export const complaintReportType = [
  {
    id: 'pdf',
    name: 'Detail PDF',
  },
  {
    id: 'excel',
    name: 'Summary Excel',
  },
];
export const EnumHeaderTrackingPickup = (i: any) => {
  if (i.itemStatus === 'UploadPickup') {
    return 'Terima Permintaan Pick-up';
  } else if (i.itemStatus === 'AssignPickup') {
    return 'Kurir Pick-up telah ditetapkan';
  } else if (i.itemStatus === 'SuccessPickup') {
    return 'Selesai Pick-up';
  }
  return null;
};
export const EnumContentPickupTracking = (i: any) => {
  if (i.itemStatus === 'SuccessPickup') {
    return `Paket sudah di Pickup oleh ${i.courierName} (${i.courierCode})`;
  } else if (i.itemStatus === 'AssignPickup') {
    return `Paket akan di Pickup oleh ${i.courierName} (${i.courierCode})`;
  } else if (i.itemStatus === 'UploadPickup') {
    return `Data permintaan Pick-up telah diterima dari ${i.customerName} (${i.codeActor})`;
  }
  return null;
};
export const EnumLabelSearchType = (i: any) => {
  if (i === 'NomerPolis') {
    return 'No Polis';
  } else if (i === 'NomerRekening') {
    return 'No Rekening';
  } else if (i === 'NomerMail') {
    return 'No Mail';
  } else if (i === 'Name') {
    return 'Nama';
  } else if (i === 'NomerReferensi') {
    return 'No Referensi';
  }
  return null;
};
