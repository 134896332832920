export function changeBranchManualProcessVendor(BranchId: any) {
  return {
    type: 'CHANGE_BRANCH_MANUAL_PROCESS_VENDOR_FORM',
    BranchId,
  };
}

export function changeCourierManualProcessVendor(CourierId: any) {
  return {
    type: 'CHANGE_COURIER_MANUAL_PROCESS_VENDOR_FORM',
    CourierId,
  };
}

export function changeFileNameManualProcessVendor(FileName: any) {
  return {
    type: 'CHANGE_FILE_NAME_MANUAL_PROCESS_VENDOR_FORM',
    FileName,
  };
}

export function changeFileDataManualProcessVendor(FileData: any) {
  return {
    type: 'CHANGE_FILE_DATA_MANUAL_PROCESS_VENDOR_FORM',
    FileData,
  };
}

export function changeFormatDateManualProcessVendor(FormatDate: any) {
  return {
    type: 'CHANGE_FORMAT_DATE_MANUAL_PROCESS_VENDOR_FORM',
    FormatDate,
  };
}

export function changeTypeUploadManualProcessVendor(TypeUpload: any) {
  return {
    type: 'CHANGE_TYPE_UPLOAD_MANUAL_PROCESS_VENDOR_FORM',
    TypeUpload,
  };
}

export function changeCheckBoxManualProcessVendor() {
  return {
    type: 'CHANGE_CHECKBOX_MANUAL_PROCESS_VENDOR_FORM',
  };
}

export function changeCheckBoxCourierManualProcessVendor() {
  return {
    type: 'CHANGE_CHECKBOX_COURIER_MANUAL_PROCESS_VENDOR_FORM',
  };
}

export function getBranchManualProcessVendorForm() {
  return {
    type: 'GET_BRANCH_MANUAL_PROCESS_VENDOR_FROM',
  };
}

export function downloadSampleManualProcessVendorForm() {
  return {
    type: 'DOWNLOAD_SAMPLE_MANUAL_PROCESS_VENDOR_FROM',
  };
}

export function clearCourierManualProcessVendorForm() {
  return {
    type: 'CLEAR_COURIER_MANUAL_PROCESS_VENDOR_FROM',
  };
}

export function clearManualProcessVendorForm() {
  return {
    type: 'CLEAR_MANUAL_PROCESS_VENDOR_FROM',
  };
}

export function clearCourierAndBranchVendorForm() {
  return {
    type: 'CLEAR_BRANCH_AND_COURIER_VENDOR_FROM',
  };
}

export function saveManualProcessVendorForm() {
  return {
    type: 'SAVE_MANUAL_PROCESS_VENDOR_FROM',
  };
}
