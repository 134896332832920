import { UseBaseQueryOptions } from '@tanstack/react-query';
import instance from 'config/interceptor';

export interface IBranch {
  totalDatas: number;
  totalPages: number;
  page: number;
  data: IBranchData[];
}

export interface IBranchData {
  id: number;
  name: string;
  uniqueCode: string;
  address: string;
  isDeleted: string;
  phoneNumber: string;
  geoLocation: string;
  isPartner: boolean;
}

export interface IAlternateBranchData {
  id: number;
  name: string;
  uniqueCode: string;
  isPartner: boolean;
}

export const getBranches = async (
  params: UseBaseQueryOptions
): Promise<IBranchData[]> => {
  const [, page, size] = params.queryKey || [];
  const { data } = await instance.get(`/Branch/${page}/${size}`);
  return data.data;
};

export const getListBranch = async () => {
  const { data } = await instance.get(`/branchs`);
  return data;
};
