import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import history from 'core/utils/History';
import { getSingleRole } from 'services/RoleServices';
import ViewRoleFormComponent from '../Component/ViewRoleFormComponent';

export default function ViewRoleFormContainer() {
  const { id }: { id: string } = useParams();

  const { data: dataSingleRole, isLoading: isLoadingRole } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataSingleRole'],
    queryFn: () => getSingleRole(id),
  });

  const defaultDataLevel = {
    levelAkses: '',
    roleName: '',
    accountType: '',
    isActive: true,
  };

  const [dataLevel, setDataLevel] = useState<any>(defaultDataLevel);
  const [dataMenus, setDataAllMenus] = useState<Array<any>>([]);

  useEffect(() => {
    if (dataSingleRole?.data) {
      const dataRole = dataSingleRole?.data;
      setDataLevel({
        levelAkses: dataRole.levelId,
        roleName: dataRole.roleName,
        accountType: dataRole.accountType,
        isActive: dataRole.isActive,
      });
      const dataAllMenus = dataRole?.menus || [];
      if (dataAllMenus.length > 0) {
        setDataAllMenus(dataAllMenus);
        const dataMenus = [] as any;
        dataAllMenus.forEach(menu => {
          menu['disableCheckbox'] = true;
          if (menu?.tabMenus?.length > 0) {
            menu?.tabMenus.map(tabmenu => {
              tabmenu['disableCheckbox'] = true;
            });
          }
          if (menu?.subMenus?.length > 0) {
            menu?.subMenus.map(submenu => {
              submenu['disableCheckbox'] = true;
              if (submenu?.tabMenus?.length > 0) {
                submenu?.tabMenus.map(tabmenu => {
                  tabmenu['disableCheckbox'] = true;
                });
              }
            });
          }
          dataMenus.push(menu);
        });
        setDataAllMenus(dataMenus);
      }
    }
  }, [dataSingleRole]);

  const handleBtnBack = () => {
    history.goBack();
  };

  return (
    <ViewRoleFormComponent
      handleBtnBack={handleBtnBack}
      isLoadingMenus={isLoadingRole}
      dataAllMenus={dataMenus}
      dataLevel={dataLevel}
    />
  );
}
