import React, { Component } from 'react';
import { Row, Col, Input, Icon } from 'antd';
import ModalMaterial from '../../../materials/ModalMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';
import FormInputTextMaterial from '../../../materials/FormInputTextMaterial';
import ButtonMaterial from '../../../materials/ButtonMaterial';

interface IProps {
  typeToggle: any;
  visible: any;
  isLoading: any;
  firstName: any;
  lastName: any;
  userName: any;
  noTelp: any;
  email: any;
  photo: any;
  address: any;
  validateFirstName: any;
  validateLastName: any;
  validateNoTelp: any;
  validateEmail: any;
  validateAddress: any;
  helpValidateFirstName: any;
  helpValidateLastName: any;
  helpValidateNoTelp: any;
  helpValidateEmail: any;
  helpValidateAddress: any;
  handlePutFirstName: any;
  handlePutLastName: any;
  handlePutNoTelp: any;
  handlePutEmail: any;
  handlePutAddress: any;
  handleConvert: any;
  handleEditProfile: any;
  handleCloseModal: any;
}
export default class EditProfileComponent extends Component<IProps> {
  render() {
    let ValidateButton =
      this.props.firstName === null ||
      this.props.firstName === '' ||
      this.props.lastName === null ||
      this.props.lastName === '' ||
      this.props.validateFirstName === 'error' ||
      this.props.validateLastName === 'error' ||
      this.props.noTelp === null ||
      this.props.noTelp === '' ||
      this.props.validateNoTelp === 'error' ||
      this.props.email === null ||
      this.props.email === '' ||
      this.props.validateEmail === 'error' ||
      this.props.address === null ||
      this.props.address === '' ||
      this.props.validateAddress === 'error'
        ? true
        : false;

    return (
      <div>
        <ModalMaterial
          className={''}
          width={'75%'}
          style={{}}
          teksTitle={'UBAH PROFIL'}
          visible={
            this.props.visible === true &&
            this.props.typeToggle === 'EditProfile'
              ? true
              : false
          }
          onCancel={this.props.handleCloseModal}
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={
                <div style={{ paddingTop: '5px' }}>
                  <Row>
                    <Col span={12}>
                      <div
                        style={{
                          paddingRight: '20px',
                          paddingLeft: '30px',
                          paddingTop: '20px',
                        }}
                      >
                        <div>
                          <span>
                            <b>Nama Depan</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.firstName}
                            className={''}
                            disabled={false}
                            addonBefore={''}
                            placeholder={'Masukan Nama Depan'}
                            prefix={''}
                            maxLength={'30'}
                            style={{ borderRadius: '10px' }}
                            size=""
                            type={''}
                            onChange={this.props.handlePutFirstName}
                            onBlur={this.props.handlePutFirstName}
                            validatestatus={this.props.validateFirstName}
                            help={this.props.helpValidateFirstName}
                            onPressEnter={''}
                          />
                        </div>

                        <div>
                          <span>
                            <b>Nama Belakang</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.lastName}
                            className={''}
                            disabled={false}
                            addonBefore={''}
                            placeholder={'Masukan Nama Belakang'}
                            prefix={''}
                            maxLength={30}
                            style={{ borderRadius: '10px' }}
                            size=""
                            type={''}
                            onChange={this.props.handlePutLastName}
                            onBlur={this.props.handlePutLastName}
                            validatestatus={this.props.validateLastName}
                            help={this.props.helpValidateLastName}
                            onPressEnter={''}
                          />
                        </div>

                        <div>
                          <span>
                            <b>Nama Pengguna</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.userName}
                            className={''}
                            disabled={true}
                            addonBefore={''}
                            placeholder={''}
                            prefix={''}
                            maxLength={''}
                            style={{ borderRadius: '10px' }}
                            size=""
                            type={''}
                            onChange={''}
                            onBlur={''}
                            validatestatus={''}
                            help={''}
                            onPressEnter={''}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col span={12}>
                      <div
                        style={{
                          paddingLeft: '20px',
                          paddingRight: '30px',
                          paddingTop: '20px',
                        }}
                      >
                        <div style={{ marginTop: '2px' }}>
                          <span>
                            <b>No Telp</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.noTelp}
                            className={''}
                            disabled={false}
                            addonBefore={'+62'}
                            placeholder={'Masukan no telp'}
                            prefix={''}
                            maxLength={'15'}
                            style={{ borderRadius: '10px' }}
                            size=""
                            type={''}
                            onChange={this.props.handlePutNoTelp}
                            onBlur={this.props.handlePutNoTelp}
                            validatestatus={this.props.validateNoTelp}
                            help={this.props.helpValidateNoTelp}
                            onPressEnter={''}
                          />
                        </div>

                        <div>
                          <span>
                            <b>Email</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.email}
                            className={''}
                            disabled={false}
                            addonBefore={''}
                            placeholder={'Masukan Email'}
                            prefix={''}
                            maxLength={50}
                            style={{ borderRadius: '10px' }}
                            size=""
                            type={''}
                            onChange={this.props.handlePutEmail}
                            onBlur={this.props.handlePutEmail}
                            validatestatus={this.props.validateEmail}
                            help={this.props.helpValidateEmail}
                            onPressEnter={''}
                          />
                        </div>

                        <div className="fileUploadInput">
                          <span>
                            <b>Foto </b>
                          </span>
                          <Input
                            onChange={this.props.handleConvert}
                            suffix={<Icon type="upload" />}
                            multiple={false}
                            type="file"
                            accept=".png, .jpg, .jpeg"
                          />
                        </div>
                        <div style={{ paddingTop: '5px' }}>
                          <span
                            style={{
                              fontStyle: 'italic',
                              color: '#969696',
                              fontSize: 12,
                            }}
                          >
                            Maksimal size 100 KB
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div style={{ marginLeft: 30, marginRight: 30 }}>
                    <span>
                      <b>Alamat Lengkap</b>
                    </span>
                    <FormInputTextMaterial
                      validateType={true}
                      value={this.props.address}
                      className={''}
                      disabled={false}
                      addonBefore={''}
                      placeholder={'Masukan alamat lengkap'}
                      prefix={''}
                      maxLength={225}
                      style={{ borderRadius: '10px' }}
                      size=""
                      type={'textArea'}
                      onChange={this.props.handlePutAddress}
                      onBlur={this.props.handlePutAddress}
                      validatestatus={this.props.validateAddress}
                      help={this.props.helpValidateAddress}
                      onPressEnter={''}
                    />
                  </div>
                  <div
                    style={{
                      textAlign: 'right',
                      marginRight: 30,
                      marginTop: 20,
                    }}
                  >
                    <ButtonMaterial
                      disabled={ValidateButton}
                      handleSubmit={this.props.handleEditProfile}
                      teksButton={'Simpan'}
                      size={''}
                      shape={'round'}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={{}}
                    />
                  </div>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
