import * as ActionGeneralData from '../../../../module/App/Store/GeneralDataAction';
import * as ActionGetDataStatus from '../../../../core/action/Customer/ActionTableStatusDefault';
import * as ActionToggle from '../../../../core/action/toggle/index';
import * as Branch from '../../../../core/action/Branch/ActionAllBranch';
import * as CourierTable from '../../../../core/action/ManageAccount/Courier/ActionTableCourier';
import * as CustomerTable from '../../../../core/action/Customer/ActionTableCustomer';
import * as ListManualProcess from '../../../../core/action/ManualProcess/ActionListMprocess';
import * as ManualProcessForm from '../../../../core/action/ManualProcess/ActionManualProcess';
import * as SubmitManualProcess from '../../../../core/action/ManualProcess/ActionSubmitManualProcess';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import ManualProcessComponent from '../../../../components/Content/ManualProcess/Internal/ManualProcessComponent';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

interface IProps {
  GetCustomer: any;
  GetBranch: any;
  GetCourier: any;
  GetDate: any;
  GetReceiveName: any;
  GetWaybill: any;
  GetInfoMprocess: any;
  GetInfoOthersMprocess: any;
  GetRadiobuttonStatus: any;
  GetReason: any;
  GetReasonOthers: any;
  GetListSubmit: any;
  GetRadiobuttonType: any;
  GetBarcodeCheckBox: any;
  ListCourier: any;
  ListCustomer: any;
  StatusDefault: any;
  StatusCustom: any;
  actionManualProcessForm: any;
  actionCourierTable: any;
  actionBranch: any;
  actionCustomerTable: any;
  actionGetDataStatus: any;
  actionSubmitManualProcess: any;
  actionListManualProcess: any;
  actionToggle: any;
  actionGeneralData: any;
  BranchList: any;
  isLoading: any;
  modal: any;
  LoadingSelectBranch: any;
  TypeLoadingSelectBranch: any;
  LoadingSelectCourier: any;
  TypeLoadingSelectCourier: any;
  LoadingSelectCustomer: any;
  TypeLoadingSelectCustomer: any;
  LoadingSelectStatus: any;
  TypeLoadingSelectStatus: any;
}

interface IState {
  ValidateBranchMprocess: any;
  ValidateMprocessDate: any;
  ValidateCourierMprocess: any;
  ValidateCustomerMprocess: any;
  ValidateReasonMprocess: any;
  ValidateReasonOthersMprocess: any;
  ValidateWaybillMprocess: any;
  ValidateReceiveNameMprocess: any;
  ValidateInfoMprocess: any;
  ValidateInfoOthersMprocess: any;
  valueMprocessDate: any;
  radioButtonType: any;
  isFreeText: any;
  RegexReplaceOthers: any;
  RegexReplaceInfoOthers: any;
  ButtonType: any;
  CollapsListMprocess: any;
  CollapsKeyListMprocess: any;
}
const { confirm } = Modal;

class ManualProcessContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      radioButtonType: null,
      RegexReplaceOthers: null,
      isFreeText: null,
      RegexReplaceInfoOthers: null,
      ButtonType: null,
      valueMprocessDate: null,
      ValidateMprocessDate: null,
      ValidateBranchMprocess: null,
      ValidateCourierMprocess: null,
      ValidateCustomerMprocess: null,
      ValidateReasonMprocess: null,
      ValidateReasonOthersMprocess: null,
      ValidateWaybillMprocess: null,
      ValidateReceiveNameMprocess: null,
      ValidateInfoMprocess: null,
      ValidateInfoOthersMprocess: null,
      CollapsListMprocess: null,
      CollapsKeyListMprocess: null,
    };

    this.handleChangeRadioButtonType =
      this.handleChangeRadioButtonType.bind(this);
    this.handleRadiobutton = this.handleRadiobutton.bind(this);
    this.handleGetBranch = this.handleGetBranch.bind(this);
    this.handlePutBranchMprocess = this.handlePutBranchMprocess.bind(this);
    this.handleBlurBranchMprocess = this.handleBlurBranchMprocess.bind(this);
    this.handleGetCourier = this.handleGetCourier.bind(this);
    this.handleResetCourier = this.handleResetCourier.bind(this);
    this.handlePutCourierMprocess = this.handlePutCourierMprocess.bind(this);
    this.handleBlurCourierMprocess = this.handleBlurCourierMprocess.bind(this);
    this.handleGetCustomer = this.handleGetCustomer.bind(this);
    this.handlePutCustomerMprocess = this.handlePutCustomerMprocess.bind(this);
    this.handleBlurCustomerMprocess =
      this.handleBlurCustomerMprocess.bind(this);
    this.handlePutManualProcessDate =
      this.handlePutManualProcessDate.bind(this);
    this.handleValidateManualProcessDate =
      this.handleValidateManualProcessDate.bind(this);
    this.handlePutReceiveName = this.handlePutReceiveName.bind(this);
    this.handleBlurReceiveName = this.handleBlurReceiveName.bind(this);
    this.handlePutWaybill = this.handlePutWaybill.bind(this);
    this.handleBlurWaybill = this.handleBlurWaybill.bind(this);
    this.handlePutInfoMprocess = this.handlePutInfoMprocess.bind(this);
    this.handleBlurInfoMprocess = this.handleBlurInfoMprocess.bind(this);
    this.handlePutInfoOthersMprocess =
      this.handlePutInfoOthersMprocess.bind(this);
    this.handleBlurInfoOthersMprocess =
      this.handleBlurInfoOthersMprocess.bind(this);
    this.handleClearInfoOthersForm = this.handleClearInfoOthersForm.bind(this);
    this.handleClearCustomerStatusOthers =
      this.handleClearCustomerStatusOthers.bind(this);
    this.handleGetCustomerStatus = this.handleGetCustomerStatus.bind(this);
    this.handlePutCustomerStatus = this.handlePutCustomerStatus.bind(this);
    this.handleBlurCustomerStatus = this.handleBlurCustomerStatus.bind(this);
    this.handlePutCustomerStatusOthers =
      this.handlePutCustomerStatusOthers.bind(this);
    this.handleBlurCustomerStatusOthers =
      this.handleBlurCustomerStatusOthers.bind(this);
    this.handleResetReason = this.handleResetReason.bind(this);
    this.handleCollapsListMprocess = this.handleCollapsListMprocess.bind(this);
    this.handleRemoveAllListMprocess =
      this.handleRemoveAllListMprocess.bind(this);
    this.handleModalRadiobutton = this.handleModalRadiobutton.bind(this);
    this.handleViewStatusDelivery = this.handleViewStatusDelivery.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleChangeBarcodeCheckBox =
      this.handleChangeBarcodeCheckBox.bind(this);
  }
  handleChangeBarcodeCheckBox() {
    this.props.actionManualProcessForm.changeCheckBoxWaybillOrBarcode();
  }
  handleChangeRadioButtonType() {
    if (
      this.props.GetRadiobuttonType === null ||
      this.props.GetRadiobuttonType === '' ||
      this.props.GetRadiobuttonType === 'forAdmin'
    ) {
      this.props.actionManualProcessForm.changeRadiobuttonTypeMprocess(
        'forSuperAdmin'
      );
      this.props.actionManualProcessForm.changeRadiobuttonStatusMprocess(
        'ReturnToSuccess'
      );
      this.props.actionManualProcessForm.clearReasonForm();
      this.props.actionManualProcessForm.clearReceiveNameForm();
      this.props.actionManualProcessForm.clearFormWhenChangeRadiobutton();
    } else {
      this.props.actionManualProcessForm.changeRadiobuttonTypeMprocess(
        'forAdmin'
      );
      this.props.actionManualProcessForm.changeRadiobuttonStatusMprocess(
        'Success'
      );
      this.props.actionManualProcessForm.clearReasonForm();
      this.props.actionManualProcessForm.clearReceiveNameForm();
      this.props.actionManualProcessForm.clearFormWhenChangeRadiobutton();
    }
  }

  handleModalRadiobutton() {
    this.props.actionToggle.changeModal();
  }

  handleRadiobutton(event: any) {
    this.setState({
      RegexReplaceOthers: null,
      isFreeText: false,
    });
    if (event.target.value === 1) {
      this.props.actionManualProcessForm.changeRadiobuttonStatusMprocess(
        'Success'
      );
      this.props.actionManualProcessForm.clearReasonForm();
    } else if (event.target.value === 2) {
      this.props.actionManualProcessForm.changeRadiobuttonStatusMprocess(
        'Return'
      );
      this.props.actionManualProcessForm.clearReasonForm();
      this.props.actionManualProcessForm.clearReceiveNameForm();
      this.setState({
        ValidateReceiveNameMprocess: null,
      });
    } else if (event.target.value === 3) {
      this.props.actionManualProcessForm.changeRadiobuttonStatusMprocess(
        'ReturnToSuccess'
      );
      this.props.actionManualProcessForm.clearReasonForm();
    } else if (event.target.value === 4) {
      this.props.actionManualProcessForm.changeRadiobuttonStatusMprocess(
        'SuccessToReturn'
      );
      this.props.actionManualProcessForm.clearReasonForm();
      this.props.actionManualProcessForm.clearReceiveNameForm();
      this.setState({
        ValidateReceiveNameMprocess: null,
      });
    }
  }

  handleGetBranch() {
    this.props.actionGeneralData.getInternalBranchList();
  }

  handlePutBranchMprocess(event: any) {
    this.props.actionManualProcessForm.changeAreaCodeIdMprocess(event);
    this.handleResetCourier();
    if (event === null) {
      this.setState({
        ValidateBranchMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateBranchMprocess: null,
      });
    }
  }

  handleBlurBranchMprocess() {
    if (this.props.GetBranch === null) {
      this.setState({
        ValidateBranchMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateBranchMprocess: null,
      });
    }
  }

  handleResetCourier() {
    this.props.actionManualProcessForm.clearCourierForm();
    this.props.actionCourierTable.clearCourierTable();
  }

  handleGetCourier() {
    this.props.actionCourierTable.getCourierTableBranch();
  }

  handlePutCourierMprocess(event: any) {
    this.props.actionManualProcessForm.changeCourierCodeMprocess(event);
    if (event === null) {
      this.setState({
        ValidateCourierMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateCourierMprocess: null,
      });
    }
  }

  handleBlurCourierMprocess() {
    if (this.props.GetCourier === null) {
      this.setState({
        ValidateCourierMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateCourierMprocess: null,
      });
    }
  }

  handleGetCustomer() {
    this.props.actionCustomerTable.GetTableCustomer();
  }

  handlePutCustomerMprocess(event: any) {
    this.props.actionManualProcessForm.changeCustomer(event);
    if (event === null) {
      this.setState({
        ValidateCustomerMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateCustomerMprocess: null,
      });
    }
  }

  handleBlurCustomerMprocess() {
    if (this.props.GetCustomer === null) {
      this.setState({
        ValidateCustomerMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateCustomerMprocess: null,
      });
    }
  }

  handlePutManualProcessDate(event: any) {
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      this.props.actionManualProcessForm.changeDateManualProcess(dateValue);
    } else {
      this.props.actionManualProcessForm.changeDateManualProcess(null);
    }
    this.setState({
      valueMprocessDate: event,
    });
    if (event === '') {
      this.setState({
        ValidateMprocessDate: 'error',
      });
    } else {
      this.setState({
        ValidateMprocessDate: '',
      });
    }
  }

  handleValidateManualProcessDate(event: any) {
    this.setState({
      ValidateMprocessDate: event,
    });
    if (this.state.ValidateMprocessDate === true) {
      if (
        this.props.GetDate === null ||
        this.props.GetDate === undefined ||
        this.props.GetDate === 'Invalid date'
      ) {
        this.setState({
          ValidateMprocessDate: 'error',
        });
      } else {
        this.setState({
          ValidateMprocessDate: '',
        });
      }
    } else {
    }
  }

  handlePutReceiveName(event: any) {
    this.props.actionManualProcessForm.changeReceiveName(
      event.target.value.toUpperCase()
    );
    if (event.target.value === null || event.target.value === '') {
      this.setState({
        ValidateReceiveNameMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateReceiveNameMprocess: null,
      });
    }
  }

  handleBlurReceiveName(event: any) {
    if (
      this.props.GetReceiveName === null ||
      this.props.GetReceiveName === ''
    ) {
      this.setState({
        ValidateReceiveNameMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateReceiveNameMprocess: null,
      });
    }
  }

  handlePutWaybill(event: any) {
    this.props.actionManualProcessForm.changeWaybillManualProcess(
      event.target.value
    );
    if (event.target.value === null || event.target.value === '') {
      this.setState({
        ValidateWaybillMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateWaybillMprocess: null,
      });
    }
  }

  handleBlurWaybill(event: any) {
    if (this.props.GetWaybill === null || this.props.GetWaybill === '') {
      this.setState({
        ValidateWaybillMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateWaybillMprocess: null,
      });
    }
  }

  handlePutInfoMprocess(event: any) {
    this.props.actionManualProcessForm.changeInfoManualProcess(event);
    this.setState({
      RegexReplaceInfoOthers: event.replace(/\s/g, '').toLowerCase(),
    });
    if (event === null) {
      this.setState({
        ValidateInfoMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateInfoMprocess: null,
      });
    }
  }

  handleBlurInfoMprocess(event: any) {
    if (this.props.GetInfoMprocess === null) {
      this.setState({
        ValidateInfoMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateInfoMprocess: null,
      });
    }
  }

  handlePutInfoOthersMprocess(event: any) {
    this.props.actionManualProcessForm.changeInfoOthersManualProcess(
      event.target.value
    );
    if (event.target.value === null || event.target.value === '') {
      this.setState({
        ValidateInfoOthersMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateInfoOthersMprocess: null,
      });
    }
  }

  handleBlurInfoOthersMprocess() {
    if (this.props.GetInfoOthersMprocess === null) {
      this.setState({
        ValidateInfoOthersMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateInfoOthersMprocess: null,
      });
    }
  }

  handleClearInfoOthersForm() {
    this.props.actionManualProcessForm.clearInfoOthersForm();
  }

  handleModal() {
    const handleOk = () => {
      this.setState({
        valueMprocessDate: null,
      });
      this.handleChangeRadioButtonType();
    };
    confirm({
      title: (
        <p style={{ color: '#FCC642' }}>Anda yakin, Ingin Mengubah Status ?</p>
      ),
      icon: 'question-circle',
      // content: '',
      okText: 'Ya',
      cancelText: 'Tidak',
      onOk() {
        handleOk();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  handleGetCustomerStatus() {
    this.props.actionGetDataStatus.getCustomDataStatus();
  }

  handlePutCustomerStatus(event: any, label: any) {
    this.props.actionManualProcessForm.changeReason([
      { desc: event, isFreeText: label.props.label, id: label.key },
    ]);

    this.setState({
      RegexReplaceOthers: event.replace(/\s/g, '').toLowerCase(),
      isFreeText: label.props.label,
    });
    if (event === null) {
      this.setState({
        ValidateReasonMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateReasonMprocess: null,
      });
    }
  }

  handleBlurCustomerStatus() {
    const getReasonBlurValue =
      this.props.GetReason[0] !== undefined
        ? this.props.GetReason[0].desc
        : null;
    if (getReasonBlurValue === null) {
      this.setState({
        ValidateReasonMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateReasonMprocess: null,
      });
    }
  }
  handlePutCustomerStatusOthers(event: any) {
    this.props.actionManualProcessForm.changeReasonOthers(event.target.value);

    if (event.target.value === null || event.target.value === '') {
      this.setState({
        ValidateReasonOthersMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateReasonOthersMprocess: null,
      });
    }
  }

  handleBlurCustomerStatusOthers() {
    if (this.props.GetReasonOthers === null) {
      this.setState({
        ValidateReasonOthersMprocess: 'error',
      });
    } else {
      this.setState({
        ValidateReasonOthersMprocess: null,
      });
    }
  }

  handleClearCustomerStatusOthers() {
    this.props.actionManualProcessForm.clearReasonOthersForm();
  }

  handleResetReason() {
    this.setState({
      ValidateReceiveNameMprocess: null,
      isFreeText: false,
    });
    this.props.actionManualProcessForm.clearReceiveNameForm();
    this.props.actionManualProcessForm.clearReasonForm();
    this.props.actionGetDataStatus.clearDefaultDataStatus();
    this.props.actionGetDataStatus.clearCustomDataStatus();
  }

  handleSubmit() {
    this.props.actionSubmitManualProcess.submitManualProcess();
  }

  handleCollapsListMprocess(event: any) {
    this.setState({
      CollapsListMprocess: !this.state.CollapsListMprocess,
      CollapsKeyListMprocess: event,
    });
  }

  handleRemoveAllListMprocess() {
    this.props.actionListManualProcess.clearListMprocess();
  }

  handleViewStatusDelivery(event: any) {
    const url = '/delivery/status?' + event;
    window.open(url, '_blank');
  }

  render() {
    const getReasonValue =
      this.props.GetReason[0] !== undefined
        ? this.props.GetReason[0].desc
        : null;
    return (
      <div>
        <ManualProcessComponent
          loadingSelectBranch={
            this.props.LoadingSelectBranch === true &&
            this.props.TypeLoadingSelectBranch === 'selectbranch'
              ? true
              : false
          }
          loadingSelectCourier={
            this.props.LoadingSelectCourier === true &&
            this.props.TypeLoadingSelectCourier === 'selectCourier'
              ? true
              : false
          }
          loadingSelectCustomer={
            this.props.LoadingSelectCustomer === true &&
            this.props.TypeLoadingSelectCustomer === 'selectCustomer'
              ? true
              : false
          }
          loadingSelectStatus={
            this.props.LoadingSelectStatus === true &&
            this.props.TypeLoadingSelectStatus === 'selectStatus'
              ? true
              : false
          }
          handleViewStatusDelivery={this.handleViewStatusDelivery}
          handleChangeRadioButtonType={this.handleChangeRadioButtonType}
          handleModalRadiobutton={this.handleModalRadiobutton}
          handleModal={this.handleModal}
          GetRadiobuttonType={this.props.GetRadiobuttonType}
          ValidateBranchMprocess={this.state.ValidateBranchMprocess}
          ValueGetBranch={
            this.props.GetBranch === null ? (
              <span style={{ color: '#969696' }}>Pilih Posko</span>
            ) : (
              this.props.GetBranch
            )
          }
          GetBranch={this.props.GetBranch}
          handleBlurBranchMprocess={this.handleBlurBranchMprocess}
          ValidateCourierMprocess={this.state.ValidateCourierMprocess}
          handleBlurCourierMprocess={this.handleBlurCourierMprocess}
          disableCourierForm={
            this.props.GetBranch === null || this.props.GetBranch === ''
              ? true
              : false
          }
          ListCourier={this.props.ListCourier}
          ValueGetCourier={
            this.props.GetCourier === null ? (
              <span style={{ color: '#969696' }}>Pilih Kurir</span>
            ) : (
              this.props.GetCourier
            )
          }
          ListCustomer={this.props.ListCustomer}
          ValidateCustomerMprocess={this.state.ValidateCustomerMprocess}
          handleBlurCustomerMprocess={this.handleBlurCustomerMprocess}
          ValueGetCustomer={
            this.props.GetCustomer === null ? (
              <span style={{ color: '#969696' }}>Pilih Pelanggan</span>
            ) : (
              this.props.GetCustomer
            )
          }
          handlePutBranchMprocess={this.handlePutBranchMprocess}
          handleResetCourier={this.handleResetCourier}
          handleValidateManualProcessDate={this.handleValidateManualProcessDate}
          ValidateMprocessDate={this.state.ValidateMprocessDate}
          GetDate={this.props.GetDate}
          GetReceiveName={this.props.GetReceiveName}
          ValidateReceiveName={
            this.props.GetRadiobuttonStatus === 'Return' ||
            this.props.GetRadiobuttonStatus === 'SuccessToReturn' ||
            (this.props.GetRadiobuttonStatus === 'Success' &&
              getReasonValue === null) ||
            (this.props.GetRadiobuttonStatus === 'ReturnToSuccess' &&
              getReasonValue === null)
              ? true
              : false
          }
          GetWaybill={this.props.GetWaybill}
          GetInfoMprocess={this.props.GetInfoMprocess}
          ValueGetInfoMprocess={
            this.props.GetInfoMprocess === null ? (
              <span style={{ color: '#969696' }}>Pilih Keterangan</span>
            ) : (
              this.props.GetInfoMprocess
            )
          }
          handleBlurInfoMprocess={this.handleBlurInfoMprocess}
          ValidateInfoMprocess={this.state.ValidateInfoMprocess}
          handlePutWaybill={this.handlePutWaybill}
          ValidateWaybillMprocess={this.state.ValidateWaybillMprocess}
          handleBlurWaybill={this.handleBlurWaybill}
          handlePutReceiveName={this.handlePutReceiveName}
          handleGetBranch={this.handleGetBranch}
          handleGetCourier={this.handleGetCourier}
          handlePutCourierMprocess={this.handlePutCourierMprocess}
          handleGetCustomer={this.handleGetCustomer}
          handleResetReason={this.handleResetReason}
          handleBlurReceiveName={this.handleBlurReceiveName}
          ValidateReceiveNameMprocess={this.state.ValidateReceiveNameMprocess}
          handlePutCustomerMprocess={this.handlePutCustomerMprocess}
          handlePutManualProcessDate={this.handlePutManualProcessDate}
          handlePutInfoMprocess={this.handlePutInfoMprocess}
          GetInfoOthersMprocess={this.props.GetInfoOthersMprocess}
          handlePutInfoOthersMprocess={this.handlePutInfoOthersMprocess}
          handleBlurInfoOthersMprocess={this.handleBlurInfoOthersMprocess}
          handleRadiobutton={this.handleRadiobutton}
          GetRadiobuttonStatus={this.props.GetRadiobuttonStatus}
          ValidateReasonForm={
            this.props.GetCustomer === '' || this.props.GetCustomer === null
              ? true
              : false
          }
          GetReason={getReasonValue}
          ListStatus={
            this.props.StatusCustom.length === 0
              ? this.props.StatusDefault
              : this.props.StatusCustom
          }
          handleGetCustomerStatus={this.handleGetCustomerStatus}
          handlePutCustomerStatus={this.handlePutCustomerStatus}
          ValidateReasonMprocess={this.state.ValidateReasonMprocess}
          handleBlurCustomerStatus={this.handleBlurCustomerStatus}
          handleRemoveAllListMprocess={this.handleRemoveAllListMprocess}
          handlePutCustomerStatusOthers={this.handlePutCustomerStatusOthers}
          handleBlurCustomerStatusOthers={this.handleBlurCustomerStatusOthers}
          GetReasonOthers={this.props.GetReasonOthers}
          ValidateReasonOthersMprocess={
            this.state.ValidateReasonOthersMprocess === 'error' ? 'error' : ''
          }
          ValidateInfoOthersMprocess={
            this.state.ValidateInfoOthersMprocess === 'error' ? 'error' : ''
          }
          helpInfoOthersMprocess={
            this.state.ValidateInfoOthersMprocess === 'error'
              ? 'Keterangan lain-lain tidak boleh kosong!'
              : ''
          }
          handleClearInfoOthersForm={this.handleClearInfoOthersForm}
          handleClearCustomerStatusOthers={this.handleClearCustomerStatusOthers}
          RegexReplaceOthers={this.state.RegexReplaceOthers}
          RegexReplaceInfoOthers={this.state.RegexReplaceInfoOthers}
          handleSubmit={this.handleSubmit}
          GetListSubmit={this.props.GetListSubmit}
          ValidateButtonSubmit={
            this.props.GetRadiobuttonStatus === 'Success' ||
            this.props.GetRadiobuttonStatus === 'ReturnToSuccess'
              ? !this.props.GetRadiobuttonStatus === true ||
                !this.props.GetCourier === true ||
                (this.state.RegexReplaceOthers !== 'lain-lain' &&
                this.state.isFreeText !== true
                  ? !getReasonValue === true
                  : !this.props.GetReasonOthers === true) ||
                !this.props.GetDate === true ||
                !this.props.GetReceiveName === true ||
                !this.props.GetWaybill === true ||
                (this.state.RegexReplaceInfoOthers !== 'lain-lain'
                  ? !this.props.GetInfoMprocess === true
                  : !this.props.GetInfoOthersMprocess === true)
                ? true
                : false
              : !this.props.GetRadiobuttonStatus === true ||
                !this.props.GetCourier === true ||
                (this.state.RegexReplaceOthers !== 'lain-lain' &&
                this.state.isFreeText !== true
                  ? !getReasonValue === true
                  : !this.props.GetReasonOthers === true) ||
                !this.props.GetDate === true ||
                !this.props.GetWaybill === true ||
                (this.state.RegexReplaceInfoOthers !== 'lain-lain'
                  ? !this.props.GetInfoMprocess === true
                  : !this.props.GetInfoOthersMprocess === true)
              ? true
              : false
          }
          handleCollapsListMprocess={this.handleCollapsListMprocess}
          CollapsListMprocess={this.state.CollapsListMprocess}
          CollapsKeyListMprocess={this.state.CollapsKeyListMprocess}
          BranchList={this.props.BranchList}
          isLoading={this.props.isLoading}
          modal={this.props.modal}
          GetBarcodeCheckBox={this.props.GetBarcodeCheckBox}
          handleChangeBarcodeCheckBox={this.handleChangeBarcodeCheckBox}
          valueMprocessDate={this.state.valueMprocessDate}
          isFreeText={this.state.isFreeText}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  GetRadiobuttonStatus:
    state.ManualProcess.ManualProcessForm.RadiobuttonStatusMprocess,
  GetBranch: state.ManualProcess.ManualProcessForm.AreaCodeIdMprocess,
  GetCourier: state.ManualProcess.ManualProcessForm.CourierCodeMprocess,
  GetCustomer: state.ManualProcess.ManualProcessForm.CustomerMprocess,
  GetDate: state.ManualProcess.ManualProcessForm.DateMprocess,
  GetReceiveName: state.ManualProcess.ManualProcessForm.ReceiveNameMprocess,
  GetWaybill: state.ManualProcess.ManualProcessForm.WaybillMprocess,
  GetInfoMprocess: state.ManualProcess.ManualProcessForm.InformationMprocess,
  GetInfoOthersMprocess:
    state.ManualProcess.ManualProcessForm.InformationOthersMprocess,
  GetReason: state.ManualProcess.ManualProcessForm.ReasonMprocess,
  GetReasonOthers: state.ManualProcess.ManualProcessForm.ReasonOthersMprocess,
  GetBarcodeCheckBox: state.ManualProcess.ManualProcessForm.BarcodeCheckBox,
  GetListSubmit: state.ManualProcess.ListSubmitManualProcess,
  GetRadiobuttonType:
    state.ManualProcess.PropertiesManualProcess.RadiobuttonTypeMprocess,
  BranchList: state.GeneralDataListState.internalBranchList,
  ListCourier: state.Table.TableCourier,
  ListCustomer: state.Table.TableCustomer,
  StatusDefault: state.Customer.DefaultDataStatus,
  StatusCustom: state.Customer.CustomDataStatus,
  isLoading: state.Toggle.isLoading,
  modal: state.Toggle.modal,

  LoadingSelectBranch: state.Branch.PropertiesBranch.LoadingBranch,
  TypeLoadingSelectBranch: state.Branch.PropertiesBranch.TypeLoadingBranch,

  LoadingSelectCourier: state.Courier.PropertiesCourier.LoadingCourier,
  TypeLoadingSelectCourier: state.Courier.PropertiesCourier.TypeLoadingCourier,

  LoadingSelectCustomer: state.Customer.PropertiesCustomer.LoadingCustomer,
  TypeLoadingSelectCustomer:
    state.Customer.PropertiesCustomer.TypeLoadingCustomer,

  LoadingSelectStatus: state.Customer.PropertiesCustomer.LoadingStatus,
  TypeLoadingSelectStatus: state.Customer.PropertiesCustomer.TypeLoadingStatus,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionBranch: bindActionCreators(Branch, dispatch),
  actionManualProcessForm: bindActionCreators(ManualProcessForm, dispatch),
  actionSubmitManualProcess: bindActionCreators(SubmitManualProcess, dispatch),
  actionCourierTable: bindActionCreators(CourierTable, dispatch),
  actionCustomerTable: bindActionCreators(CustomerTable, dispatch),
  actionGetDataStatus: bindActionCreators(ActionGetDataStatus, dispatch),
  actionListManualProcess: bindActionCreators(ListManualProcess, dispatch),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionGeneralData: bindActionCreators(ActionGeneralData, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ManualProcessContainer);
