import { Action } from 'redux';
import { IFetchLevelAction } from './LevelAction';

const initialState: any = {
  levelDataList: [],
  moduleList: [],
};

export default function LevelProcessReducer(
  state = initialState,
  action: Action
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_LEVEL_LIST_DATA': {
      const fetchLevelDataListAction = action as IFetchLevelAction;
      newState.levelDataList = fetchLevelDataListAction.data;
      return { ...newState };
    }
    case 'FETCH_MODULE_LIST_DATA': {
      const fetchModuleListAction = action as IFetchLevelAction;
      newState.moduleList = fetchModuleListAction.data;
      return { ...newState };
    }
    case 'CLEAR_LEVEL_LIST_DATA': {
      newState.levelDataList = [];
      return { ...newState };
    }
    case 'CLEAR_MODULE_LIST_DATA': {
      newState.moduleList = [];
      return { ...newState };
    }
  }
  return state;
}
