export function setListResendSubmit(ListResendSubmit:any)
{
    return({
        type:"SET_LIST_RESEND_SUBMIT",
        ListResendSubmit
    })
}
export function clearListResendSubmit()
{
    return({
        type:"CLEAR_LIST_RESEND_SUBMIT"
    })
}