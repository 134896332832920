import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';

interface IProps {
  handleDetailAdmin: any;
  handleEditAdmin: any;
  handleResetPassword: any;
  typeButton: any;
}
export default class ButtonDetailBranchComponent extends Component<IProps> {
  render() {
    return this.props.typeButton === 'reset' ? (
      <ButtonMaterial
        handleSubmit={this.props.handleResetPassword}
        className={'btnResetPassword'}
        icon={'lock'}
      />
    ) : this.props.typeButton === 'detail' ? (
      <ButtonMaterial
        handleSubmit={this.props.handleDetailAdmin}
        className={'btnDetail'}
        icon={'eye'}
      />
    ) : (
      <ButtonMaterial
        handleSubmit={this.props.handleEditAdmin}
        className={'btnEdit'}
        icon={'edit'}
      />
    );
  }
}
