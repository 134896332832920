import * as ActionAuth from '../../../action/Auth';
import * as ActionFormVendor from '../../../action/ManualProcess/ActionManualProcessVendor';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle/index';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../../ErrorMassage/index';
import React from 'react';
import axios from 'axios';
import moment from 'moment';

export function* S_submitManualProcessVendor() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const BranchId = state.ManualProcess.ManualProcessVendorForm.BranchId;
  const CourierId = state.ManualProcess.ManualProcessVendorForm.CourierId;
  const FileData = state.ManualProcess.ManualProcessVendorForm.FileData;
  const CheckBox = state.ManualProcess.ManualProcessVendorForm.CheckBox;
  const FormatDate = state.ManualProcess.ManualProcessVendorForm.FormatDate;
  const TypeUpload = state.ManualProcess.ManualProcessVendorForm.TypeUpload;
  const isChangeCourier =
    state.ManualProcess.ManualProcessVendorForm.isChangeCourier;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;

  let DateNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Result No Scan ' + DateNow + '.txt');
    document.body.appendChild(link);
    link.click();
  }
  try {
    yield put(toggle.changeLoadingTrue());
    const key = 'download';
    const { data } = yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/Delivery/ManualUpdate/VendorNInternal`,
      {
        Branch: BranchId || 0,
        courier: CourierId || 0,
        file: FileData,
        zipFile: CheckBox,
        formatDate: FormatDate,
        Internal: TypeUpload === 'Internal' ? true : false,
        isChangeCourier,
      },
      {
        // responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);

          // let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );

    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionFormVendor.clearManualProcessVendorForm());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      const errorMsg = error.response.data.Message;
      if (error.response.status === 401) {
        yield put(ActionLogin.submitTokenRefrash());
      } else if (errorMsg === 'File not csv') {
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              <b>Update dokumen gagal</b>
            </p>
          ),
          content: (
            <div>
              <p style={{ color: '#C0C0C0' }}>
                {'File yang di upload bukan csv'}
              </p>
            </div>
          ),
          onOk() {},
        });
      } else if (errorMsg === 'datetime cannot convert') {
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              <b>Format tanggal tidak sesuai</b>
            </p>
          ),
          content: (
            <div>
              <p style={{ color: '#C0C0C0' }}>
                {'Format tanggal pada file csv tidak sesuai'}
              </p>
            </div>
          ),
          onOk() {},
        });
      } else if (errorMsg.substring(0, 12) === 'waybil tidak') {
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              <b>Update dokumen gagal</b>
            </p>
          ),
          content: (
            <div>
              <p style={{ color: '#C0C0C0' }}>{error.response.data.Message}</p>
            </div>
          ),
          onOk() {},
        });
      } else if (errorMsg === 'File not zip') {
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              <b>Update dokumen gagal</b>
            </p>
          ),
          content: (
            <div>
              <p style={{ color: '#C0C0C0' }}>
                {'File yang di upload bukan zip'}
              </p>
            </div>
          ),
          onOk() {},
        });
      } else if (errorMsg === 'File csv not multiple') {
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              <b>Update dokumen gagal</b>
            </p>
          ),
          content: (
            <div>
              <p style={{ color: '#C0C0C0' }}>
                {'Terdapat lebih dari satu csv'}
              </p>
            </div>
          ),
          onOk() {},
        });
      } else if (errorMsg === 'Csv not found') {
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              <b>Update dokumen gagal</b>
            </p>
          ),
          content: (
            <div>
              <p style={{ color: '#C0C0C0' }}>
                {'File csv didalam zip tidak ada'}
              </p>
            </div>
          ),
          onOk() {},
        });
      } else {
        const propsError = ErrorMassage('Simpan Manual Rekanan');
        const code = propsError.filter((person: any) =>
          person.Code.toString().toLowerCase().includes(error.response.status)
        );
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              {code.map((data: any) => data.TitleMassage)}
            </p>
          ),
          content: (
            <div>
              <p style={{ color: '#C0C0C0' }}>
                {code.map((data: any) => data.Massage)}
              </p>
            </div>
          ),
          onOk() {},
        });
      }
    }
  }
}
export function* SM_submitManualProcessVendor() {
  yield takeLatest(
    'SAVE_MANUAL_PROCESS_VENDOR_FROM',
    S_submitManualProcessVendor
  );
}
