import React, { Component } from 'react';
import CustomerManifestContainer from '../../../../containers/Content/Manifest/CustomerManifest/CustomerManifestContainer';
import TableCustomerManifestContainer from '../../../../containers/Content/Manifest/CustomerManifest/TableCustomerManifestContainer';

export default class index extends Component {
  render() {
    return (
      <div>
        <div>
          <CustomerManifestContainer />
        </div>
        <div>
          <TableCustomerManifestContainer />
        </div>
      </div>
    );
  }
}
