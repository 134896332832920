import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionCustomerManifest from '../../../../core//action/Manifest/CustomerManifest/ActionCustomerManifest';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionToggle from '../../../action/toggle';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import React from 'react';
import axios from 'axios';
import moment from 'moment';

export function* S_downloadCustomerManifest(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const key = 'download';
  const ScanDate = state.Manifest.CustomerManifestForm.ScanDate;
  const returnName = state.Manifest.CustomerManifestForm.ReturnName;
  const returnDate = state.Manifest.CustomerManifestForm.ReturnDate;
  const address = state.Manifest.CustomerManifestForm.Address;
  const city = state.Manifest.CustomerManifestForm.City;
  const RadioButton =
    state.Manifest.CustomerManifestForm.RadioButton === 'Success'
      ? true
      : false;
  const Customer =
    state.Manifest.CustomerManifestForm.Customer === null
      ? 0
      : state.Manifest.CustomerManifestForm.Customer;
  const Product =
    state.Manifest.CustomerManifestForm.Product === null
      ? 0
      : state.Manifest.CustomerManifestForm.Product;
  const DataArray = state.Manifest.ListCustomerManifest;
  const treatmentDocument = action.treatmentDocument;

  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const file_name2 = 'Manifest ' + moment(ScanDate).format('YYYYMMDD') + '.zip';
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = file_name2;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }
  try {
    Modal.info({
      title: <p style={{ color: '#11beff' }}>{<b>Download Data</b>}</p>,
      content: (
        <div>
          <p>Sedang mendownload data, mohon tunggu</p>
        </div>
      ),
    });
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Manifest/Customer/PDFNew`,
      {
        // idGroup: DataArray,
        userScan: DataArray[0].userCode,
        returnTo: returnName,
        customerId: Customer,
        customerProductId: Product,
        success: RadioButton,
        scanTime: DataArray[0].scanTime,
        returnTime: returnDate,
        address: address,
        city: city,
        treatmentDocument: treatmentDocument || '',
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);

          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionToggle.changeModal());
    yield put(ActionToggle.clearJenisToggle());
    yield put(ActionCustomerManifest.clearCustomerManifestPopupForm());
  } catch (error) {
    if (error.response.data.Message === 'No delivery found!') {
      message.warning({
        content: 'Manifest Pelanggan Tidak Tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* SM_downloadCustomerManifest() {
  yield takeLatest(
    'DOWNLOAD_CUSTOMER_MANIFEST_FORM',
    S_downloadCustomerManifest
  );
}
