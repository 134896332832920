import * as ActionAuth from '../../../core/action/Auth';
import * as ActionCallCenter from '../../CallCenter/Store/CallCenterAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionDataGeneral from '../../App/Store/GeneralDataAction';
import * as ActionLogin from '../../Login/Store';
import * as SelectorCallCenter from '../../CallCenter/Selector/CallCenterSelector';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { getFormValues } from 'redux-form';
import moment from 'moment';

function* S_getFormatCsvListProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const formValue = yield select(getFormValues('callCenterForm'));
  try {
    yield put(ActionDataGeneral.loadingGetData('FormatCsv'));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Setting/CustomCsv/Callcenter/Customer/${formValue.customer}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionCallCenter.fetchFormatCsvList(data));
    yield put(ActionDataGeneral.loadingGetData('FormatCsv'));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}
function* S_getCustomerProductListProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const formValue = yield select(getFormValues('callCenterForm'));
  try {
    yield put(ActionDataGeneral.loadingGetData('CustomerProduct'));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Customer/product/customer/${formValue.customer}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionCallCenter.fetchCustomerProductList(data.data));
    yield put(ActionDataGeneral.loadingGetData('CustomerProduct'));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}
function* S_getCourierListProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const formValue = yield select(getFormValues('callCenterForm'));
  try {
    yield put(ActionDataGeneral.loadingGetData('Courier'));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Courier/branch/${formValue.branch}?isAll=false`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionCallCenter.fetchCourierList(data.data));
    yield put(ActionDataGeneral.loadingGetData('Courier'));
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}
function* S_submitUploadCallCenterProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const csvFile = yield select(SelectorCallCenter.makeCsvFileSelector());
  const csvFileName = yield select(SelectorCallCenter.makeFileNameSelector());
  const formValue = yield select(getFormValues('callCenterForm'));
  const DateNow = moment(Date.now()).format('THH:mm:ss');
  const deliveryDate = `${formValue.deliveryDate}${DateNow}`;
  try {
    yield put(ActionComponent.processLoading(true));
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Delivery/Upload/Callcenter`,
      {
        customerId: formValue.customer,
        documentType: formValue.documentType,
        productId: formValue.product,
        branchId: formValue.branch,
        courierId: formValue.courier,
        customCsvId: formValue.formatCsv,
        deliveryTime: deliveryDate,
        fileUpload: csvFile,
        fileName: csvFileName,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionCallCenter.fetchDataSuccessKu(data.successKu));
    yield put(ActionCallCenter.fetchDataFailedKu(data.failedKu));
    yield put(ActionCallCenter.fetchDataSuccessAttempt(data.successAttempt));
    yield put(ActionCallCenter.fetchDataFailedAttempt(data.failedAttempt));
    yield put(ActionCallCenter.fetchDataListCsv(data.listResultCsv));
    yield put(ActionComponent.openModal('Notification'));
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}
function* S_downloadResultUploadCallCenterProcess(action) {
  const state = yield select();
  const key = 'download';
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const resultList = yield select(SelectorCallCenter.makeDataCsvListSelector());
  const DateNow = moment(new Date()).format('DDMMYYYY');
  const fileName2 = `result_callcenter_${DateNow}.txt`;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const fileName = fileName2;
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
  try {
    yield put(ActionComponent.processLoading(true));
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Delivery/upload/downloadResult`,
      {
        listResult: resultList,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const percentProgress = Math.floor((loaded * 100) / total);

          if (percentProgress < 100) {
            message.loading({
              content: `Loading ${percentProgress}%'`,
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    if (error.response.data.Message === 'Internal Server Error') {
      message.warning({
        content: 'Data Tidak Tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(ActionComponent.processLoading(false));
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}
export function* SM_getFormatCsvListAction() {
  yield takeLatest(
    'CC_GET_FORMAT_CSV_LIST_REQUESTED',
    S_getFormatCsvListProcess
  );
}
export function* SM_getCustomerProductListAction() {
  yield takeLatest(
    'CC_GET_CUSTOMER_PRODUCT_LIST_REQUESTED',
    S_getCustomerProductListProcess
  );
}
export function* SM_getCourierListAction() {
  yield takeLatest('CC_GET_COURIER_LIST_REQUESTED', S_getCourierListProcess);
}
export function* SM_submitUploadCallCenterAction() {
  yield takeLatest(
    'SUBMIT_UPLOAD_CALL_CENTER_REQUESTED',
    S_submitUploadCallCenterProcess
  );
}
export function* SM_downloadResultUploadCallCenterAction() {
  yield takeLatest(
    'DOWNLOAD_RESULT_CSV_CALL_CENTER_REQUESTED',
    S_downloadResultUploadCallCenterProcess
  );
}
