import * as ActionAuth from '../../action/Auth';
import * as ActionDataStatus from '../../action/Customer/ActionTableStatusDefault';
import * as ActionLastAccess from '../../../core/action/LastAccess';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionRadiobutton from '../../../core/action/Customer/ActionRadiobuttonStatus';
import * as LoadingStatus from '../../action/Customer/ActionStatusLoading';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_GetDefaultDataStatus(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const router = state.router.location.pathname;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(LoadingStatus.setLoadingStatus());
    if (router === '/customer/config') {
      yield put(toggle.changeLoadingTrue());
    }

    const ReasonDeliveryStatus =
      router === '/customer/config'
        ? state.Customer.PropertiesCustomer.ReasonDeliveryStatus
        : router === '/manualProcess'
        ? state.ManualProcess.ManualProcessForm.RadiobuttonStatusMprocess ===
            'ReturnToSuccess' ||
          state.ManualProcess.ManualProcessForm.RadiobuttonStatusMprocess ===
            'Success'
          ? 'Success'
          : 'Return'
        : '';

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Setting/CustomStatus?id=0&stat=' +
        ReasonDeliveryStatus,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionDataStatus.setDefaultDataStatus(data.data));
    yield put(LoadingStatus.setLoadingStatus());
    if (router === '/customer/config') {
      yield put(ActionLastAccess.changeLastAccessFiture('defaultDataStatus'));
      yield put(ActionRadiobutton.changeRadiobutton(1));
      yield put(toggle.changeLoadingFalse());
    }
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_GetCustomDataStatus(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const router = state.router.location.pathname;
  if (router === '/customer/config') {
    yield put(toggle.changeLoadingTrue());
  }
  const Radiobutton = state.Customer.PropertiesCustomer.Radiobutton;
  const ChangeType = state.Customer.PropertiesCustomer.ChangeType;

  const ReasonDeliveryStatus =
    router === '/customer/config'
      ? state.Customer.PropertiesCustomer.ReasonDeliveryStatus
      : router === '/manualProcess'
      ? state.ManualProcess.ManualProcessForm.RadiobuttonStatusMprocess ===
          'ReturnToSuccess' ||
        state.ManualProcess.ManualProcessForm.RadiobuttonStatusMprocess ===
          'Success'
        ? 'Success'
        : 'Return'
      : '';

  const IdCustomer =
    router === '/customer/config'
      ? state.Customer.PropertiesCustomer.IdCustomer
      : router === '/manualProcess'
      ? state.ManualProcess.ManualProcessForm.CustomerMprocess
      : '';
  const LastAccess = state.LastAccess.Fiture;
  try {
    yield put(LoadingStatus.setLoadingStatus());
    yield put(LoadingStatus.setTypeLoadingStatus('selectStatus'));

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Setting/CustomStatus?id=' +
        IdCustomer +
        '&stat=' +
        ReasonDeliveryStatus,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionDataStatus.setCustomDataStatus(data.data));
    yield put(LoadingStatus.setLoadingStatus());
    if (router === '/customer/config') {
      if (ChangeType === 'RadioButton') {
        if (data.data.length === 0 && Radiobutton === 1) {
          yield put(ActionRadiobutton.changeRadiobutton(2));
          yield put(
            ActionLastAccess.changeLastAccessFiture('customDataStatus')
          );
        } else if (data.data.length === 0 && Radiobutton === 2) {
          yield put(ActionDataStatus.getDefaultDataStatus());
          yield put(
            ActionLastAccess.changeLastAccessFiture('defaultDataStatus')
          );
          yield put(ActionRadiobutton.changeRadiobutton(1));
        } else if (
          data.data.length === 0 &&
          Radiobutton === null &&
          LastAccess === null
        ) {
          yield put(ActionDataStatus.getDefaultDataStatus());
          yield put(
            ActionLastAccess.changeLastAccessFiture('defaultDataStatus')
          );
          yield put(ActionRadiobutton.changeRadiobutton(1));
        } else {
          yield put(ActionRadiobutton.changeRadiobutton(2));
          yield put(
            ActionLastAccess.changeLastAccessFiture('customDataStatus')
          );
        }
      } else {
        if (data.data.length === 0) {
          yield put(
            ActionLastAccess.changeLastAccessFiture('defaultDataStatus')
          );
          yield put(ActionRadiobutton.changeRadiobutton(1));
          yield put(ActionDataStatus.getDefaultDataStatus());
        } else {
          yield put(ActionRadiobutton.changeRadiobutton(2));
          yield put(
            ActionLastAccess.changeLastAccessFiture('customDataStatus')
          );
        }
      }
      yield put(toggle.changeLoadingFalse());
    } else if (router === '/manualProcess') {
      if (data.data.length === 0) {
        yield put(ActionDataStatus.getDefaultDataStatus());
      } else {
      }
    }
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_GetDefaultDataStatus() {
  yield takeLatest('GET_DEFAULT_DATA_STATUS', S_GetDefaultDataStatus);
}
export function* SM_GetCustomDataStatus() {
  yield takeLatest('GET_CUSTOM_DATA_STATUS', S_GetCustomDataStatus);
}
