import { Col, DatePicker, Form, Row, Select } from 'antd';

import ButtonMaterial from 'materials/ButtonMaterial';
import CardMaterial from 'materials/CardMaterial';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';

const { Option } = Select;

interface IProps {
  roleAccount: any;
  branchAuth: any;
  ValueFirstDate: any;
  ValueLastDate: any;
  GetFirstDate: any;
  GetLastDate: any;
  GetCustomer: any;
  GetBranch: any;
  GetCheckboxToday: any;
  lastAccessFiture: any;
  ValidateFirstDate: any;
  helpFirstDate: any;
  ValidateLastDate: any;
  ListBranch: any;
  ListCustomer: any;
  helpLastDate: any;
  handleValidateLastDate: any;
  handleChangeFirstDate: any;
  handleChangeLastDate: any;
  handleValidateFirstDate: any;
  handleGetBranch: any;
  handleGetCustomer: any;
  handleBlurBranch: any;
  handleBlurCustomer: any;
  handleSelectBranch: any;
  handleSelectCustomer: any;
  loadingSelectBranch: any;
  loadingSelectCustomer: any;
  ValidateCustomer: any;
  helpValidateCustomer: any;
  ValidateBranch: any;
  helpValidateBranch: any;
  handleChangeCheckboxToday: any;
  validateLastDate: any;
  validateDownloadButton: any;
  handleReset: any;
  handleDownload: any;
  isLoading: any;
}

export default function CourierBonusReportComponent({
  roleAccount,
  branchAuth,
  ValueFirstDate,
  ValueLastDate,
  GetFirstDate,
  GetLastDate,
  GetCustomer,
  GetBranch,
  GetCheckboxToday,
  lastAccessFiture,
  ValidateFirstDate,
  helpFirstDate,
  ValidateLastDate,
  ListBranch,
  ListCustomer,
  helpLastDate,
  handleValidateLastDate,
  handleChangeFirstDate,
  handleChangeLastDate,
  handleValidateFirstDate,
  handleGetBranch,
  handleGetCustomer,
  handleBlurBranch,
  handleBlurCustomer,
  handleSelectBranch,
  handleSelectCustomer,
  loadingSelectBranch,
  loadingSelectCustomer,
  ValidateCustomer,
  helpValidateCustomer,
  ValidateBranch,
  helpValidateBranch,
  handleChangeCheckboxToday,
  validateLastDate,
  validateDownloadButton,
  handleReset,
  handleDownload,
  isLoading,
}: IProps) {
  const formatDate = 'YYYY-MM-DD';
  const firstDateV = GetFirstDate;
  const minDate = (d: any) =>
    !d ||
    d.isBefore('2020-01-01') ||
    d.isAfter(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
  const minDate2 = (d: any) =>
    !d ||
    d.isBefore(moment(GetFirstDate).add(0, 'day').format(formatDate)) ||
    d.isAfter(moment(GetFirstDate).add(30, 'day').format(formatDate));

  return (
    <div style={{ paddingBottom: '35px' }}>
      <SpinMaterial
        spinning={isLoading}
        style={{}}
        size={'large'}
        content={
          <CardMaterial
            extra={''}
            title={<b className="titleCard">LAPORAN PENGIRIMAN</b>}
            style={{ borderRadius: '10px' }}
            content={
              <div>
                <Row>
                  <Col span={12} className="spacingRight">
                    <div>
                      <span>
                        <b>Tanggal Cycle</b>
                      </span>
                      <Form.Item
                        validateStatus={ValidateFirstDate}
                        help={helpFirstDate}
                      >
                        <DatePicker
                          className={'datePickerDefault'}
                          locale={locale}
                          format={'YYYY-MM-DD'}
                          placeholder={'Pilih Tanggal'}
                          onOpenChange={handleValidateFirstDate}
                          value={ValueFirstDate}
                          onChange={handleChangeFirstDate}
                          disabledDate={minDate}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Pelanggan</b>
                      </span>
                      <Form.Item
                        validateStatus={ValidateCustomer}
                        help={helpValidateCustomer}
                      >
                        <Select
                          loading={loadingSelectCustomer}
                          style={{ width: '100%' }}
                          showSearch
                          mode="multiple"
                          placeholder="Pilih Pelanggan"
                          allowClear={true}
                          // onDeselect={handleBlurCustomer}
                          // onSelect={handleBlurCustomer}
                          onChange={handleSelectCustomer}
                          onBlur={handleBlurCustomer}
                          value={GetCustomer}
                          onFocus={handleGetCustomer}
                        >
                          {ListCustomer.length === GetCustomer.length ? null : (
                            <Option key={''} value={'SetAllCustomer'}>
                              {'Pilih Semua Pelanggan'}
                            </Option>
                          )}
                          {ListCustomer.map((data: any, index: any) => (
                            <Option key={index} value={data.id}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12} className="spacingLeft">
                    <div>
                      <span>
                        <b>Sampai Tanggal</b>
                      </span>
                      <Form.Item
                        validateStatus={ValidateLastDate}
                        help={helpLastDate}
                      >
                        <DatePicker
                          className={'datePickerDefault'}
                          locale={locale}
                          defaultPickerValue={moment(firstDateV, formatDate)}
                          format={'YYYY-MM-DD'}
                          placeholder={'Pilih Tanggal'}
                          onOpenChange={handleValidateLastDate}
                          value={ValueLastDate}
                          onChange={handleChangeLastDate}
                          disabledDate={minDate2}
                          disabled={validateLastDate}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Posko</b>
                      </span>
                      <Form.Item
                        validateStatus={ValidateBranch}
                        help={helpValidateBranch}
                      >
                        <Select
                          loading={loadingSelectBranch}
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          disabled={false}
                          showSearch
                          mode="multiple"
                          placeholder="Pilih Posko"
                          allowClear={true}
                          onDeselect={handleBlurBranch}
                          onSelect={handleBlurBranch}
                          onChange={handleSelectBranch}
                          onBlur={handleBlurBranch}
                          value={GetBranch}
                          onFocus={handleGetBranch}
                        >
                          {ListBranch.length === GetBranch.length ? null : (
                            <Option key={''} value={'SetAllBranch'}>
                              {'Pilih Semua Posko'}
                            </Option>
                          )}
                          {ListBranch.map((data: any, index: any) => (
                            <Option key={index} value={data.id}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div>
                      {/* <Checkbox
                          onChange={handleChangeCheckboxToday}
                          checked={GetCheckboxToday}
                        />
                        <span className={'checkboxSpan'}>To Day</span> */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className={'spacingDownloadButton'}>
                    <ButtonMaterial
                      disabled={validateDownloadButton}
                      handleSubmit={handleDownload}
                      teksButton={<b>Download</b>}
                      size={'middle'}
                      shape={'round'}
                      className={
                        validateDownloadButton === true
                          ? 'validateDownloadButtonDisabled'
                          : 'validateDownloadBtnEnable2'
                      }
                      type={'primary'}
                      icon={''}
                      style={{}}
                    />
                  </div>
                  <div>
                    <ButtonMaterial
                      disabled={false}
                      handleSubmit={handleReset}
                      teksButton={<b>Reset</b>}
                      size={'middle'}
                      shape={'round'}
                      className={'validateResetButtonEnable'}
                      type={'primary'}
                      icon={''}
                      style={{}}
                    />
                  </div>
                </Row>
              </div>
            }
          />
        }
      />
    </div>
  );
}
