import * as ActionDetailBranch from 'core/action/Branch/ActionDetailBranch';
import * as ActionEditBranch from 'core/action/Branch/ActionEditBranch';
import * as ActionToggle from 'core/action/toggle/index';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import BranchDetailComponent from 'module/Branch/Component/BranchDetailComponent';
import { connect } from 'react-redux';
import history from 'core/utils/History';

interface IProps {
  idBranch: any;
  actionDetailBranch: any;
  actionEditBranch: any;
  actionToggle: any;
  GroupNameBranch: any;
  NameBranch: any;
  AreaCode: any;
  Address: any;
  PhoneNumber: any;
  Province: any;
  City: any;
  SubDistrict: any;
  Village: any;
  AdminBranch: any;
  PostalCode: any;
  isLoading: any;
  RouterBack: any;
  DetailBranch: any;
  getLatitudeBranch: any;
  getLongitudeBranch: any;
  getPartner: any;
}
function BranchDetailContainer({
  idBranch,
  actionDetailBranch,
  actionEditBranch,
  actionToggle,
  GroupNameBranch,
  NameBranch,
  AreaCode,
  Address,
  PhoneNumber,
  Province,
  City,
  SubDistrict,
  Village,
  AdminBranch,
  PostalCode,
  isLoading,
  RouterBack,
  DetailBranch,
  getLatitudeBranch,
  getLongitudeBranch,
  getPartner,
}: IProps) {
  useEffect(() => {
    if (idBranch == null) {
      history.push(RouterBack);
    } else {
      actionDetailBranch.changeDetailBranch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleBackDetail() {
    history.push('/branch');
  }
  function handleEditDetail() {
    actionToggle.changeModal();
    actionEditBranch.changeDetailEditBranch();
  }
  return (
    <div>
      <BranchDetailComponent
        GroupNameBranch={GroupNameBranch}
        NameBranch={NameBranch}
        AreaCode={AreaCode}
        Address={Address}
        PhoneNumber={PhoneNumber}
        Province={Province}
        City={City}
        SubDistrict={SubDistrict}
        Village={Village}
        AdminBranch={AdminBranch}
        PostalCode={PostalCode}
        getLatitudeBranch={getLatitudeBranch}
        getLongitudeBranch={getLongitudeBranch}
        getPartner={getPartner}
        isLoading={isLoading}
        DetailBranch={DetailBranch}
        handleBackDetail={handleBackDetail}
        handleEditDetail={handleEditDetail}
      />
    </div>
  );
}
const mapStateProps = (state: any) => ({
  idBranch: state.Branch.PropertiesBranch.idBranch,
  DetailBranch: state.Branch.PropertiesBranch.BranchDetail,
  NameBranch: state.Branch.DetailBranch.NameBranch,
  GroupNameBranch: state.Branch.DetailBranch.GroupNameBranch,
  AreaCode: state.Branch.DetailBranch.AreaCode,
  Address: state.Branch.DetailBranch.Address,
  PhoneNumber: state.Branch.DetailBranch.PhoneNumber,
  Province: state.Branch.DetailBranch.Province,
  City: state.Branch.DetailBranch.City,
  SubDistrict: state.Branch.DetailBranch.SubDistrict,
  Village: state.Branch.DetailBranch.Village,
  getLatitudeBranch: state.Branch.DetailBranch.LatitudeBranch,
  getLongitudeBranch: state.Branch.DetailBranch.LongitudeBranch,
  getPartner: state.Branch.DetailBranch.Partner,
  AdminBranch: state.Branch.DetailBranch.NameAdminBranch,
  PostalCode: state.Branch.DetailBranch.PostalCode,
  isLoading: state.Toggle.isLoading,
  RouterBack: state.Navigation.router,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionDetailBranch: bindActionCreators(ActionDetailBranch, dispatch),
  actionEditBranch: bindActionCreators(ActionEditBranch, dispatch),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(BranchDetailContainer);
