import React from 'react';
import { Table } from 'antd';
import SpinMaterial from 'materials/SpinMaterial';
import CardMaterial from 'materials/CardMaterial';
import { ColumnAlignTable } from 'interface/general';
import { SmsBlastFailedLogType } from 'module/SmsBlast/container/FailedLog';
import moment from 'moment-timezone';
import { formatDateToUtc } from 'lib/util';

type FailedLogComponentProps = {
  dataSmsBlast: SmsBlastFailedLogType[];
  page: number;
  setPage: (value: number) => void;
  isLoading: boolean;
};

export default function FailedLogComponent({
  dataSmsBlast = [],
  setPage,
  page,
  isLoading = false,
}: FailedLogComponentProps) {
  const looppage = (page - 1) * 10 + 1;
  const columnsTable = [
    {
      title: 'No',
      width: 80,
      align: 'center' as ColumnAlignTable,
      render: (text: any, record: any, index: any) => (
        <span>{page > 1 ? index + looppage : index + 1}</span>
      ),
    },
    {
      title: 'Tanggal Cycle',
      dataIndex: 'cycle',
      align: 'center' as ColumnAlignTable,
      render: (text: string) => (
        <>{moment(text.toString(), 'YYYYMMDD').format('DD-MM-YYYY')}</>
      ),
    },
    {
      title: 'Pelanggan',
      dataIndex: 'customerName',
      align: 'center' as ColumnAlignTable,
    },
    {
      title: 'Produk',
      dataIndex: 'customerProductName',
      align: 'center' as ColumnAlignTable,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: string) => (
        <p style={{ color: '#EA1A1A', margin: 0 }}>{text}</p>
      ),
      align: 'center' as ColumnAlignTable,
    },
    {
      title: 'Tanggal Proses SMS',
      dataIndex: 'proccessDateSms',
      render: (text: string) => (
        <>{formatDateToUtc(text, 'DD-MM-YYYY HH:mm:ss')}</>
      ),
      align: 'center' as ColumnAlignTable,
    },
    {
      title: 'Keterangan',
      dataIndex: 'description',
      align: 'center' as ColumnAlignTable,
      render: (text: string) => <>{text || '-'}</>,
    },
  ];
  return (
    <SpinMaterial spinning={isLoading} size={'large'}>
      <CardMaterial style={{ borderRadius: '10px 10px 0 0' }}>
        <span className="titleSearchCard">LOG GAGAL SMS BLAST</span>
      </CardMaterial>
      <Table
        columns={columnsTable}
        bordered
        dataSource={dataSmsBlast}
        pagination={{
          onChange(page) {
            setPage(page);
          },
          pageSize: 10,
          total: dataSmsBlast.length,
          current: page,
        }}
      />
    </SpinMaterial>
  );
}
