import { Action } from 'redux';

const initialState = {
  FirstDate: null,
  LastDate: null,
  CustomerId: null,
  ProductId: [],
};
export interface IAuthAction extends Action<string> {}
export interface IChangeFirstDate extends IAuthAction {
  FirstDate: any;
}
export interface IChangeLastDate extends IAuthAction {
  LastDate: any;
}
export interface IChangeCustomerId extends IAuthAction {
  CustomerId: any;
}
export interface IChangeProductId extends IAuthAction {
  ProductId: any;
}
export default function AuthReducer(state = initialState, action: IAuthAction) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_FIRST_DATE_BAST':
      const changeFirstDateBast = action as IChangeFirstDate;
      newState.FirstDate = changeFirstDateBast.FirstDate;

      return { ...newState };
    case 'CHANGE_LAST_DATE_BAST':
      const changeLastDateBast = action as IChangeLastDate;
      newState.LastDate = changeLastDateBast.LastDate;

      return { ...newState };
    case 'CHANGE_CUSTOMER_ID_BAST':
      const changeCustomerIdBast = action as IChangeCustomerId;
      newState.CustomerId = changeCustomerIdBast.CustomerId;

      return { ...newState };
    case 'CHANGE_PRODUCT_ID_BAST':
      const changeProductIdBast = action as IChangeProductId;
      newState.ProductId = changeProductIdBast.ProductId;

      return { ...newState };
    case 'CLEAR_PRODUCT_ID_BAST':
      newState.ProductId = [];

      return { ...newState };
    case 'CLEAR_FORM_BAST':
      newState.FirstDate = null;
      newState.LastDate = null;
      newState.CustomerId = null;
      newState.ProductId = [];

      return { ...newState };
  }
  return state;
}
