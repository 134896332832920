import { Action } from 'redux';

export interface IFetchPickupDataAction extends Action {
  data: object;
}

export function fetchCustomerProductList(data: object): IFetchPickupDataAction {
  return {
    type: 'FETCH_CUSTOMER_PRODUCT_LIST',
    data,
  };
}
export function fetchCourierList(data: object): IFetchPickupDataAction {
  return {
    type: 'FETCH_COURIER_LIST',
    data,
  };
}
export function fetchPickupDataList(data: object): IFetchPickupDataAction {
  return {
    type: 'FETCH_PICKUP_DATA_LIST',
    data,
  };
}
export function fetchCountingDataStatus(data: object): IFetchPickupDataAction {
  return {
    type: 'FETCH_COUNTING_DATA_STATUS_PICKUP',
    data,
  };
}
export function getCustomerProduct() {
  return {
    type: 'PICKUP_DATA_GET_CUSTOMER_PRODUCT_LIST_REQUESTED',
  };
}
export function getCourier() {
  return {
    type: 'PICKUP_DATA_GET_COURIER_LIST_REQUESTED',
  };
}
export function getPickupData() {
  return {
    type: 'GET_PICKUP_DATA_LIST_REQUESTED',
  };
}
export function getCountingStatusPickup() {
  return {
    type: 'GET_COUNTING_STATUS_PICKUP_LIST_REQUESTED',
  };
}
export function clearCustomerProduct() {
  return {
    type: 'CLEAR_CUSTOMER_PRODUCT_LIST_PICKUP',
  };
}
export function clearCourier() {
  return {
    type: 'CLEAR_COURIER_LIST_PICKUP',
  };
}
export function clearDataTablePickup() {
  return {
    type: 'CLEAR_DATA_TABLE_PICKUP',
  };
}
export function resetPickupDataForm() {
  return {
    type: 'RESET_PICKUP_DATA_FORM',
  };
}
