import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionComponent from '../../../App/Store/ComponentAction';
import * as ActionDataGeneral from '../../../App/Store/GeneralDataAction';
import * as ActionLogin from '../../../Login/Store';
import * as ActionPickupData from '../Store/PickupDataAction';
import * as SelectorApp from '../../../App/Selector/AppSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { change, getFormValues } from 'redux-form';

import axios from 'axios';
import moment from 'moment';

function* S_getCustProductListProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const formValue = yield select(getFormValues('pickupDataForm'));
  try {
    yield put(ActionDataGeneral.loadingGetData('CustomerProduct'));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Customer/product/customer/${formValue.customerPickup}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionPickupData.fetchCustomerProductList(data.data));
    yield put(ActionDataGeneral.loadingGetData('CustomerProduct'));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionDataGeneral.loadingGetData('CustomerProduct'));
  }
}
function* S_getCourierPickupDataProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const router = state.router.location.pathname;

  const formValue = yield select(getFormValues('pickupDataForm'));
  const isAllCourier = router === '/pickupData' ? true : false;
  try {
    yield put(ActionDataGeneral.loadingGetData('Courier'));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Courier/branch/${formValue.branchPickup}?isAll=${isAllCourier}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionPickupData.fetchCourierList(data.data));
    yield put(ActionDataGeneral.loadingGetData('Courier'));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionDataGeneral.loadingGetData('Courier'));
  }
}
function* S_getPickupDataProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const formValue = yield select(getFormValues('pickupDataForm'));
  const pagePagination = yield select(SelectorApp.makePagePagination());
  const pagePaginationValue = pagePagination - 1;
  const sizeDataPagination = yield select(SelectorApp.makeSizeDataPagination());
  const formatStartDate = moment(formValue.startDate).format('YYYYMMDD');
  const formatFinishDate = moment(formValue.finishDate).format('YYYYMMDD');
  const baseUrl = `/RequestPickup/PickupData/${formatStartDate}/${formatFinishDate}/${pagePaginationValue}/${sizeDataPagination}`;
  const pickupStatusValue =
    '&PickupStatus=' + formValue.pickupStatus.join(`&PickupStatus=`);
  const coreValue = () => {
    if (
      !formValue.customerPickup === false ||
      !formValue.customerProduct === false ||
      !formValue.branchPickup === false ||
      !formValue.courierPickup === false
    ) {
      return `&CycleDate=${formValue.dateType}${pickupStatusValue}`;
    } else {
      return `?CycleDate=${formValue.dateType}${pickupStatusValue}`;
    }
  };

  let apiLinkValue = '';
  const apiLink = () => {
    if (
      !formValue.customerPickup === false &&
      !formValue.customerProduct === false &&
      !formValue.branchPickup === false &&
      !formValue.courierPickup === false
    ) {
      apiLinkValue = `?CustomerId=${formValue.customerPickup}&ProductId=${formValue.customerProduct}&BranchId=${formValue.branchPickup}&CourierId=${formValue.courierPickup}`;
    } else if (
      !formValue.customerPickup === false &&
      !formValue.customerProduct === false &&
      !formValue.branchPickup === false
    ) {
      apiLinkValue = `?CustomerId=${formValue.customerPickup}&ProductId=${formValue.customerProduct}&BranchId=${formValue.branchPickup}`;
    } else if (
      !formValue.customerPickup === false &&
      !formValue.customerProduct === false
    ) {
      apiLinkValue = `?CustomerId=${formValue.customerPickup}&ProductId=${formValue.customerProduct}`;
    } else if (
      !formValue.customerPickup === false &&
      !formValue.branchPickup === false &&
      !formValue.courierPickup === false
    ) {
      apiLinkValue = `?CustomerId=${formValue.customerPickup}&BranchId=${formValue.branchPickup}&CourierId=${formValue.courierPickup}`;
    } else if (
      !formValue.customerPickup === false &&
      !formValue.branchPickup === false
    ) {
      apiLinkValue = `?CustomerId=${formValue.customerPickup}&BranchId=${formValue.branchPickup}`;
    } else if (!formValue.customerPickup === false) {
      apiLinkValue = `?CustomerId=${formValue.customerPickup}`;
    } else if (
      !formValue.branchPickup === false &&
      !formValue.courierPickup === false
    ) {
      apiLinkValue = `?BranchId=${formValue.branchPickup}&CourierId=${formValue.courierPickup}`;
    } else if (!formValue.branchPickup === false) {
      apiLinkValue = `?BranchId=${formValue.branchPickup}`;
    } else {
      apiLinkValue = '';
    }
  };
  try {
    yield put(ActionComponent.processLoading(true));
    apiLink();
    const linkGetData =
      formValue.searchData === true
        ? `${baseUrl}${apiLinkValue}${coreValue()}&ShipToName=${
            formValue.searchValue === undefined ? '' : formValue.searchValue
          }`
        : `${baseUrl}${apiLinkValue}${coreValue()}`;
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}${linkGetData}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComponent.setTotalPagePagination(data.totalPages * 10));
    yield put(ActionPickupData.fetchPickupDataList(data.data));
    if (formValue.searchData === true) {
      yield put(change('pickupDataForm', 'searchData', null));
    } else {
      yield put(ActionPickupData.getCountingStatusPickup());
    }
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}
function* S_getCountingStatusPickupProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const formValue = yield select(getFormValues('pickupDataForm'));
  const formatStartDate = moment(formValue.startDate).format('YYYYMMDD');
  const formatFinishDate = moment(formValue.finishDate).format('YYYYMMDD');
  const baseUrl = `/RequestPickup/PickupData/Counting/${formatStartDate}/${formatFinishDate}`;
  const pickupStatusValue =
    '&PickupStatus=' + formValue.pickupStatus.join(`&PickupStatus=`);
  const coreValue = () => {
    if (
      !formValue.customerPickup === false ||
      !formValue.customerProduct === false ||
      !formValue.branchPickup === false ||
      !formValue.courierPickup === false
    ) {
      return `&CycleDate=${formValue.dateType}${pickupStatusValue}`;
    } else {
      return `?CycleDate=${formValue.dateType}${pickupStatusValue}`;
    }
  };

  let apiLinkValue = '';
  const apiLink = () => {
    if (
      !formValue.customerPickup === false &&
      !formValue.customerProduct === false &&
      !formValue.branchPickup === false &&
      !formValue.courierPickup === false
    ) {
      apiLinkValue = `?CustomerId=${formValue.customerPickup}&ProductId=${formValue.customerProduct}&BranchId=${formValue.branchPickup}&CourierId=${formValue.courierPickup}`;
    } else if (
      !formValue.customerPickup === false &&
      !formValue.customerProduct === false &&
      !formValue.branchPickup === false
    ) {
      apiLinkValue = `?CustomerId=${formValue.customerPickup}&ProductId=${formValue.customerProduct}&BranchId=${formValue.branchPickup}`;
    } else if (
      !formValue.customerPickup === false &&
      !formValue.customerProduct === false
    ) {
      apiLinkValue = `?CustomerId=${formValue.customerPickup}&ProductId=${formValue.customerProduct}`;
    } else if (
      !formValue.customerPickup === false &&
      !formValue.branchPickup === false &&
      !formValue.courierPickup === false
    ) {
      apiLinkValue = `?CustomerId=${formValue.customerPickup}&BranchId=${formValue.branchPickup}&CourierId=${formValue.courierPickup}`;
    } else if (
      !formValue.customerPickup === false &&
      !formValue.branchPickup === false
    ) {
      apiLinkValue = `?CustomerId=${formValue.customerPickup}&BranchId=${formValue.branchPickup}`;
    } else if (!formValue.customerPickup === false) {
      apiLinkValue = `?CustomerId=${formValue.customerPickup}`;
    } else if (
      !formValue.branchPickup === false &&
      !formValue.courierPickup === false
    ) {
      apiLinkValue = `?BranchId=${formValue.branchPickup}&CourierId=${formValue.courierPickup}`;
    } else if (!formValue.branchPickup === false) {
      apiLinkValue = `?BranchId=${formValue.branchPickup}`;
    } else {
      apiLinkValue = '';
    }
  };
  try {
    apiLink();
    yield put(ActionComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}${baseUrl}${apiLinkValue}${coreValue()}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionPickupData.fetchCountingDataStatus(data));
    yield put(change('pickupDataForm', 'searchData', null));
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}
export function* SM_getCustProductListAction() {
  yield takeLatest(
    'PICKUP_DATA_GET_CUSTOMER_PRODUCT_LIST_REQUESTED',
    S_getCustProductListProcess
  );
}
export function* SM_getCourierPickupDataAction() {
  yield takeLatest(
    'PICKUP_DATA_GET_COURIER_LIST_REQUESTED',
    S_getCourierPickupDataProcess
  );
}
export function* SM_getPickupDataAction() {
  yield takeLatest('GET_PICKUP_DATA_LIST_REQUESTED', S_getPickupDataProcess);
}
export function* SM_getCountingStatusPickupAction() {
  yield takeLatest(
    'GET_COUNTING_STATUS_PICKUP_LIST_REQUESTED',
    S_getCountingStatusPickupProcess
  );
}
