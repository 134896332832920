import * as ActionWaybillDigital from '../../../../core/action/Report/DigitalWaybillReport/ActionDigitalWaybill';
import * as toggle from '../../../../core/action/toggle';

import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import DigitalWaybillByUploadComponent from 'module/Report/Component/DigitalWaybill/DigitalWaybillByUploadComponent';
import { connect } from 'react-redux';

interface IProps {
  actionToggle: any;
  actionWaybillDigital: any;
  FileCsv: any;
  CheckboxBarcode: any;
  CheckboxBarcodeReverse: any;
  getFlagFileNameUpload: any;
  CountSuccess: any;
  CountFailed: any;
  visible: any;
  jenisToggle: any;
  isLoading: any;
  LastAccess: any;
}

function DigitalWaybillByUploadContainer({
  actionToggle,
  actionWaybillDigital,
  FileCsv,
  CheckboxBarcode,
  CheckboxBarcodeReverse,
  getFlagFileNameUpload,
  CountSuccess,
  CountFailed,
  visible,
  jenisToggle,
  isLoading,
  LastAccess,
}: IProps) {
  const [file, setfile]: any = useState(null);
  const [fileName, setfileName]: any = useState(null);
  const [validateFlagFileName, setValidateFlagFileName]: any = useState(null);

  useEffect(() => {
    actionToggle.clearJenisToggle();
    setfile(null);
    setfileName('');
    setValidateFlagFileName(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClearModalForm() {
    actionWaybillDigital.getDeleteDigitalWaybillByUpload();
    actionToggle.changeModal();
    actionToggle.clearJenisToggle();
    actionWaybillDigital.clearFormByUpload();
    setfile(null);
    setfileName('');
    setValidateFlagFileName(null);
  }
  function handleCheckboxBarcode(event: any) {
    actionWaybillDigital.changeCbBarcode();
    if (event.target.checked === false) {
      if (CheckboxBarcodeReverse === true) {
        actionWaybillDigital.changeCbBarcodeReverse();
      }
    }
  }
  function handleCheckboxBarcodeReverse() {
    actionWaybillDigital.changeCbBarcodeReverse();
  }
  function handleConvert(e: any) {
    setfileName(e.target.value);
    let fileData = file;
    fileData = e.target.files[0];
    handleGetBase64(fileData)
      .then((result: any) => {
        actionWaybillDigital.changeFileCsv(result.split(',')[1]);
        setfile(fileData);
      })
      .catch(err => {
        console.log(err);
      });

    setfile(e.target.files[0]);
  }

  function handlePutFlagFileName(event: any) {
    actionWaybillDigital.changeFlagFileNameUpload(event);

    if (event === null || event.length === 0) {
      setValidateFlagFileName('error');
    } else {
      setValidateFlagFileName(null);
    }
  }
  function handleBlurFlagFilename() {
    if (getFlagFileNameUpload === null) {
      setValidateFlagFileName('error');
    } else {
      setValidateFlagFileName(null);
    }
  }
  const handleGetBase64 = (file: any) => {
    return new Promise(resolve => {
      let baseURL;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  function handleDownloadTemplate() {
    actionToggle.changeJenisToggle('WaybillDigitalByUpload');
    actionWaybillDigital.downloadDigitalWaybillTemplate();
  }

  function handleDownloadByUpload() {
    actionToggle.changeJenisToggle('WaybillDigitalByUpload');
    actionWaybillDigital.downloadDigitalWaybillByUpload();
    setfile(null);
    setfileName('');
  }

  function handleDownloadResultUpload() {
    actionWaybillDigital.getErrorDigitalWaybillByUpload();
  }

  return (
    <div>
      <DigitalWaybillByUploadComponent
        visible={jenisToggle === 'WaybillDigitalByUpload' ? true : false}
        handleClearModal={handleClearModalForm}
        handleCheckboxBarcode={handleCheckboxBarcode}
        handleCheckboxBarcodeReverse={handleCheckboxBarcodeReverse}
        helpFlagFileName={
          validateFlagFileName === 'error' ? 'Penamaan File wajib diisi' : ''
        }
        validateFlagFileName={validateFlagFileName === 'error' ? 'error' : ''}
        handlePutFlagFileName={handlePutFlagFileName}
        getFlagFileNameUpload={getFlagFileNameUpload}
        handleBlurFlagFilename={handleBlurFlagFilename}
        LastAccess={LastAccess}
        jenisToggle={jenisToggle}
        handleConvert={handleConvert}
        handleDownload={handleDownloadByUpload}
        handleDownloadTemplate={handleDownloadTemplate}
        fileCsv={FileCsv}
        checkboxBarcode={CheckboxBarcode}
        checkboxBarcodeReverse={CheckboxBarcodeReverse}
        fileName={fileName}
        validationBtnDownload={
          FileCsv === null ||
          FileCsv === '' ||
          getFlagFileNameUpload === null ||
          getFlagFileNameUpload === ''
            ? true
            : false
        }
        countSuccess={CountSuccess}
        countFailed={CountFailed}
        handleDownloadResult={handleDownloadResultUpload}
        handleClearResult={handleClearModalForm}
        isLoading={isLoading}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  jenisToggle: state.Toggle.jenisToggle,
  LastAccess: state.LastAccess.Fiture,
  isLoading: state.Toggle.isLoading,
  FileCsv: state.Report.DigitalWaybillReportForm.FileCsv,
  CheckboxBarcode: state.Report.DigitalWaybillReportForm.CheckboxBarcode,
  CheckboxBarcodeReverse:
    state.Report.DigitalWaybillReportForm.CheckboxBarcodeReverse,
  getFlagFileNameUpload:
    state.Report.DigitalWaybillReportForm.FlagFileNameUpload,
  CountSuccess: state.Report.DigitalWaybillReportForm.CountSuccess,
  CountFailed: state.Report.DigitalWaybillReportForm.CountFailed,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionWaybillDigital: bindActionCreators(ActionWaybillDigital, dispatch),
  actionToggle: bindActionCreators(toggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DigitalWaybillByUploadContainer);
