import '../../../styles/TableStyle/index.css'

import { Col, Row, Table } from 'antd';
import React, { Component } from 'react';

import ButtonCustomerContainer from '../../../containers/Content/Customer/ButtonCustomerContainer';
import ButtonMaterial from '../../../materials/ButtonMaterial';
import CardMaterial from '../../../materials/CardMaterial';
import FormCustomerContainer from '../../../containers/Content/Customer/FormCustomerContainer';
import SearchMatrial from '../../../materials/SearchMatrial';
import SpinMaterial from '../../../materials/SpinMaterial';

interface IProps
{
    data:any
    isLoading:any
    nameSearch:any
    ValueSearch:any
    PagePagination:any
    TotalPagePagination:any
    dataSource:any
    handlePage:any
    handleSearch:any
    handleSubmitSearch: any
    handleModal:any
}
export default class CustomerComponent extends Component<IProps> {
    render() {
        const looppage = (this.props.PagePagination-1)*10+1
        const columns = [
            {
                title: <div style={{textAlign:'center'}}><b>No</b></div>,
                width: 50,
                render:(text:any,record:any,index:any)=><span>{
                  this.props.PagePagination>1?index+looppage:index+1
                }</span>,
                key:'no'
            },
            {
                title: <b>Nama</b>,
                dataIndex: 'name',
                key: 'name',
                width: 120,
            },
            {
                title: <b>Kode</b>,
                dataIndex: 'code',
                key: 'code',
                render:(code:any)=><span style={{color: 'red'}}>{code}</span>,
                width: 80,
            },
            {
                title: <b>Jenis</b>,
                dataIndex: 'customerType',
                key: 'customerType',
                width: 100,
            },
            {
                title: <b>Email</b>,
                dataIndex: 'email',
                key: 'email',
                render:(email:any)=><span style={{color: 'red'}}>{email}</span>,
                width: 100,
            },
            {
                title: <b>Aksi</b>,
                key: 'action',
                width: 90,
                render: (record:any) => (
                    <div>
                        <Row>
                            <Col span={8}>
                                <div style={{float:'right', paddingRight:'10px'}}>
                                    <ButtonCustomerContainer
                                        idCustomer={record.id} 
                                        typeButton={'detail'}/>
                                </div>
                            </Col>
                            
                            <Col span={8}>
                                <div style={{paddingRight:'5px'}}>
                                    <ButtonCustomerContainer
                                        idCustomer={record.id}
                                        typeButton={'edit'}/>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div style={{float:'right'}}>
                                    <ButtonCustomerContainer
                                        idCustomer={record.id}
                                        typeButton={'config'}/>
                                </div>
                            </Col>
                        </Row>
                        
                        
                    </div>
                    
                ),
            },
        ];
        return (
            <div style={{padding:'35px'}}>
                <FormCustomerContainer/>
                <div>
                <SpinMaterial
                    spinning={this.props.isLoading}
                    style={{}}
                    size={"large"}
                    content={
                        <div>
                            <CardMaterial
                                extra={""}
                                title={""}
                                    style={{}}
                                    content={
                                    <div>
                                        <Row>
                                            <Col span={12}>
                                                <div>
                                                    <span style={{color:'#11BEFF', paddingRight:'20px'}}>
                                                        <b>
                                                            SEMUA PELANGGAN
                                                        </b>
                                                    </span>
                                                    <SearchMatrial
                                                        value={this.props.ValueSearch}
                                                        style={{borderRadius:'10px', width:'50%'}}
                                                        placeholder={'Cari nama atau kode'}
                                                        onChange={this.props.handleSearch}
                                                        onPressEnter={this.props.handleSubmitSearch}
                                                    />
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div style={{float:'right'}}>
                                                    <ButtonMaterial
                                                        disabled={""}
                                                        handleSubmit={this.props.handleModal}
                                                        teksButton={<b>TAMBAHKAN PELANGGAN</b>}
                                                        size={""}
                                                        shape={""}
                                                        className={""}
                                                        type={"link"}
                                                        icon={""}
                                                        style={{
                                                            color: '#11BEFF',
                                                            paddingRight:'15px',}}/>

                                                    <ButtonMaterial
                                                        disabled={""}
                                                        handleSubmit={this.props.handleModal}
                                                        teksButton={""}
                                                        size={""}
                                                        shape={"circle"}
                                                        className={""}
                                                        type={"primary"}
                                                        icon={"plus"}
                                                        style={{
                                                            color: '#fafafa' ,
                                                            paddingBottom:'3px',
                                                            background:'#31E116',
                                                            borderColor:'#31E116'}}/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>}>
                                </CardMaterial>
                                <Table
                                    rowKey={record => record.id}
                                    pagination={{onChange: page => { this.props.handlePage(page)
                              
                                    }, pageSize:10,
                                    total: this.props.TotalPagePagination,
                                    current:
                                        this.props.PagePagination === 0
                                          ? 1
                                          : this.props.PagePagination, }}
                                    bordered={false}
                                    dataSource={this.props.data}
                                    // dataSource={this.props.dataSource==0 && this.props.nameSearch==false?this.props.data:this.props.dataSource}
                                    columns={columns} />
                        </div>
                    }>
                </SpinMaterial>
                </div>
            </div>
        )
    }
}
