import ButtonMaterial from 'materials/ButtonMaterial';
import React from 'react';

interface IProps {
  handleDetailComplain: any;
  handleEditFormComplaint: any;
  typeButton: any;
  ticketStatus: any;
}
export default function ButtonComplaintComponent({
  handleDetailComplain,
  handleEditFormComplaint,
  typeButton,
  ticketStatus,
}: IProps) {
  return (
    <div>
      {typeButton === 'Update' ? (
        <ButtonMaterial
          disabled={''}
          handleSubmit={handleDetailComplain}
          teksButton={''}
          size={''}
          shape={''}
          className={''}
          type={'primary'}
          icon={'eye'}
          style={{
            borderRadius: '8px',
            color: '#fafafa',
            paddingBottom: '3px',
            background: '#7956EE',
            borderColor: '#7956EE',
          }}
        />
      ) : (
        <ButtonMaterial
          disabled={ticketStatus === 1 ? true : false}
          handleSubmit={handleEditFormComplaint}
          teksButton={''}
          size={''}
          shape={''}
          className={''}
          type={'primary'}
          icon={'edit'}
          style={
            ticketStatus === 1
              ? {
                  borderRadius: '8px',
                  paddingBottom: '3px',
                }
              : {
                  borderRadius: '8px',
                  color: '#fafafa',
                  paddingBottom: '3px',
                  background: '#FCC642',
                  borderColor: '#FCC642',
                }
          }
        />
      )}
    </div>
  );
}
