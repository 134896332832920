export function changeModeEmergency(ModeEmergency:any)
{
    return({
        type:"CHANGE_MODE_EMERGENCY",
        ModeEmergency
    })
}
export function changeDocumentType(DocumentType:any)
{
    return({
        type:"CHANGE_DOCUMENT_TYPE_EMERGENCY",
        DocumentType
    })
}
export function clearDocumentType()
{
    return({
        type:"CLEAR_DOCUMENT_TYPE_EMERGENCY"
    })
}
export function changeDistrict(District:any)
{
    return({
        type:"CHANGE_DISTRICT_EMERGENCY",
        District
    })
}
export function ClearDistrict()
{
    return({
        type:"CLEAR_DISTRICT_EMERGENCY"
    })
}
export function ChangeBranch(Branch:any)
{
    return({
        type:"CHANGE_BRANCH_EMERGENCY",
        Branch
    })
}
export function ClearBranch()
{
    return({
        type:"CLEAR_BRANCH_EMERGENCY"
    })
}
export function ChangeCourier(Courier:any)
{
    return({
        type:"CHANGE_COURIER_EMERGENCY",
        Courier
    })
}
export function ClearCourier()
{
    return({
        type:"CLEAR_COURIER_EMERGENCY"
    })
}
export function ChangeWeight(Weight:any)
{
    return({
        type:"CHANGE_WEIGHT_EMERGENCY",
        Weight
    })
}
export function ClearWeight()
{
    return({
        type:"CLEAR_WEIGHT_EMERGENCY"
    })
}
export function ChangeKoli(Koli:any)
{
    return({
        type:"CHANGE_KOLI_EMERGENCY",
        Koli
    })
}
export function ClearKoli()
{
    return({
        type:"CLEAR_KOLI_EMERGENCY"
    })
}
export function ChangeListKoli(ListKoli:any)
{
    return({
        type:"CHANGE_LIST_KOLI_EMERGENCY",
        ListKoli
    })
}
export function ClearListKoli()
{
    return({
        type:"CLEAR_LIST_KOLI_EMERGENCY"
    })
}
export function ChangeDeliveryTime(DeliveryTime:any)
{
    return({
        type:"CHANGE_DELIVERY_TIME_EMERGENCY",
        DeliveryTime
    })
}
export function ClearDeliveryTime()
{
    return({
        type:"CLEAR_DELIVERY_TIME_EMERGENCY"
    })
}
export function ChangeFinishTime(FinishTime:any)
{
    return({
        type:"CHANGE_FINISH_TIME_EMERGENCY",
        FinishTime
    })
}
export function ClearFinishTime()
{
    return({
        type:"CLEAR_FINISH_TIME_EMERGENCY"
    })
}
export function ChangeNumberResi(NumberResi:any)
{
    return({
        type:"CHANGE_NUMBER_RESI_EMERGENCY",
        NumberResi
    })
}
export function ClearNumberResi()
{
    return({
        type:"CLEAR_NUMBER_RESI_EMERGENCY"
    })
}
export function ChangeWaybill(Waybill:any)
{
    return({
        type:"CHANGE_WAYBILL_EMERGENCY",
        Waybill
    })
}
export function ClearWaybill()
{
    return({
        type:"CLEAR_WAYBILL_EMERGENCY"
    })
}
export function ChangeCsv(Csv:any)
{
    return({
        type:"CHANGE_CSV_EMERGENCY",
        Csv
    })
}
export function ChangeBarcodeColumn(BarcodeColumn:any)
{
    return({
        type:"CHANGE_BARCODE_COLUMN_EMERGENCY",
        BarcodeColumn
    })
}
export function ChangeTypeDelivery(TypeDelivery:any)
{
    return({
        type:"CHANGE_TYPE_DELIVERY_EMERGENCY",
        TypeDelivery
    })
}
export function ChangeReceiverName(ReceiverName:any)
{
    return({
        type:"CHANGE_RECEIVER_NAME_EMERGENCY",
        ReceiverName
    })
}
export function ChangeDeliveryNote(DeliveryNote:any)
{
    return({
        type:"CHANGE_DELIVERY_NOTE_EMERGENCY",
        DeliveryNote,
    })
}
export function ChangeFileName(FileName:any)
{
    return({
        type:"CHANGE_FILE_NAME_EMERGENCY",
        FileName,
    })
}
export function ChangeCountSuccess(CountSuccess:any)
{
    return({
        type:"CHANGE_COUNT_SUCCESS_EMERGENCY",
        CountSuccess,
    })
}
export function ChangeCountFailed(CountFailed:any)
{
    return({
        type:"CHANGE_COUNT_FAILED_EMERGENCY",
        CountFailed,
    })
}
export function ClearModalEmergency()
{
    return({
        type:"CLEAR_MODAL_EMERGENCY"
    })
}
export function ClearCsv()
{
    return({
        type:"CLEAR_CSV_EMERGENCY"
    })
}
export function ClearConditionMode()
{
    return({
        type:"CLEAR_CONDITION_MODE"
    })
}
export function ClearAllEmergancyForm()
{
    return({
        type:"CLEAR_ALL_EMERGENCY_FORM"
    })
}
export function clearCourierAndBranchEmergencyForm()
{
    return({
        type:"CLEAR_BRANCH_AND_COURIER_EMERGENCY"
    })
}
export function SubmitEmergencyScan()
{
    return({
        type:"SUBMIT_EMERGENCY_SCAN"
    })
}
export function SubmitEmergencyUpload()
{
    return({
        type:"SUBMIT_EMERGENCY_UPLOAD"
    })
}
export function DownloadTemplateEmergencyUpload()
{
    return({
        type:"DOWNLOAD_TEMPLATE_EMERGENCY_UPLOAD"
    })
}
export function getCountEmergency()
{
    return({
        type:"GET_COUNT_EMERGENCY"
    })
}
export function getDeleteEmergency()
{
    return({
        type:"GET_DELETE_EMERGENCY"
    })
}
export function DownloadResultEmergency()
{
    return({
        type:"DOWNLOAD_RESULT_EMERGENCY"
    })
}

