import '../../../styles/ModalStyle/ModalStyle.css';

import { Checkbox, Col, Form, Row } from 'antd';
import React, { Component } from 'react';

import FormInputTextMaterial from '../../../materials/FormInputTextMaterial';
import TextArea from 'antd/lib/input/TextArea';

interface IProps {
  GroupNameBranch: any;
  NameBranch: any;
  Province: any;
  City: any;
  SubDistrict: any;
  AreaCode: any;
  PhoneNumber: any;
  Village: any;
  PostalCode: any;
  Address: any;
  AdminBranch: any;
  Latitude: any;
  Longitude: any;
  Partner: any;
}

export default class FormDetailBranchComponent extends Component<IProps> {
  render() {
    const valueGroupNameBranch = this.props.GroupNameBranch===null||this.props.GroupNameBranch===0?'-':this.props.GroupNameBranch
    return (
      <div>
        <Row>
          <Col span={12}>
            <div className={'marginRightFormDetailbranch'}>
              <Form>
                <div className="padding-top-form-input">
                  <span>
                    <b>Nama Posko</b>
                  </span>
                  <FormInputTextMaterial
                    onPressEnter={''}
                    validateType={false}
                    value={this.props.NameBranch}
                    className={'inputFormBranch'}
                    disabled={false}
                    addonBefore={''}
                    placeholder={''}
                    prefix={''}
                    maxLength={''}
                    style={{}}
                    size=""
                    type={''}
                    onChange={''}
                    onBlur={''}
                    validatestatus={''}
                    help={''}
                  />
                </div>

                <div className="padding-top-form-input">
                  <span>
                    <b>Provinsi</b>
                  </span>
                  <FormInputTextMaterial
                    onPressEnter={''}
                    validateType={false}
                    value={this.props.Province}
                    className={'inputFormBranch'}
                    disabled={false}
                    addonBefore={''}
                    placeholder={''}
                    prefix={''}
                    maxLength={''}
                    style={{}}
                    size=""
                    type={''}
                    onChange={''}
                    onBlur={''}
                    validatestatus={''}
                    help={''}
                  />
                </div>

                <div className="padding-top-form-input">
                  <span>
                    <b>Kabupaten / Kota</b>
                  </span>
                  <FormInputTextMaterial
                    onPressEnter={''}
                    validateType={false}
                    value={this.props.City}
                    className={'inputFormBranch'}
                    disabled={false}
                    addonBefore={''}
                    placeholder={''}
                    prefix={''}
                    maxLength={''}
                    style={{}}
                    size=""
                    type={''}
                    onChange={''}
                    onBlur={''}
                    validatestatus={''}
                    help={''}
                  />
                </div>

                <div className="padding-top-form-input">
                  <span>
                    <b>Kecamatan</b>
                  </span>
                  <FormInputTextMaterial
                    onPressEnter={''}
                    validateType={false}
                    value={this.props.SubDistrict}
                    className={'inputFormBranch'}
                    disabled={false}
                    addonBefore={''}
                    placeholder={''}
                    prefix={''}
                    maxLength={''}
                    style={{}}
                    size=""
                    type={''}
                    onChange={''}
                    onBlur={''}
                    validatestatus={''}
                    help={''}
                  />
                </div>
              </Form>
            </div>
          </Col>

          <Col span={12}>
            <div className={'marginRightFormDetailbranch'}>
              <Form>
                <div className="padding-top-form-input">
                  <span>
                    <b>Kode Wilayah</b>
                  </span>
                  <FormInputTextMaterial
                    onPressEnter={''}
                    validateType={false}
                    value={this.props.AreaCode}
                    className={'inputFormBranch'}
                    disabled={false}
                    addonBefore={''}
                    placeholder={''}
                    prefix={''}
                    maxLength={''}
                    style={{}}
                    size=""
                    type={''}
                    onChange={''}
                    onBlur={''}
                    validatestatus={''}
                    help={''}
                  />
                </div>

                <div className="padding-top-form-input">
                  <span>
                    <b>No Telp </b>
                  </span>
                  <FormInputTextMaterial
                    onPressEnter={''}
                    validateType={false}
                    value={this.props.PhoneNumber}
                    className={'inputFormBranch'}
                    disabled={false}
                    addonBefore={'+62'}
                    placeholder={''}
                    prefix={''}
                    maxLength={''}
                    style={{}}
                    size=""
                    type={''}
                    onChange={''}
                    onBlur={''}
                    validatestatus={''}
                    help={''}
                  />
                </div>
                <div className="padding-top-form-input">
                  <span>
                    <b>Kode pos</b>
                  </span>
                  <FormInputTextMaterial
                    onPressEnter={''}
                    validateType={false}
                    value={this.props.PostalCode}
                    className={'inputFormBranch'}
                    disabled={false}
                    addonBefore={''}
                    placeholder={''}
                    prefix={''}
                    maxLength={''}
                    style={{}}
                    size=""
                    type={''}
                    onChange={''}
                    onBlur={''}
                    validatestatus={''}
                    help={''}
                  />
                </div>
                <div className="padding-top-form-input">
                  <span>
                    <b>Admin posko</b>
                  </span>
                  <FormInputTextMaterial
                    onPressEnter={''}
                    validateType={false}
                    value={this.props.AdminBranch}
                    className={'inputFormBranch'}
                    disabled={false}
                    addonBefore={''}
                    placeholder={''}
                    prefix={''}
                    maxLength={''}
                    style={{}}
                    size=""
                    type={''}
                    onChange={''}
                    onBlur={''}
                    validatestatus={''}
                    help={''}
                  />
                </div>
              </Form>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className={'marginRightFormDetailbranch'}>
              <form>
                <div className="padding-top-form-input">
                  <span>
                    <b>Desa / Kelurahan</b>
                  </span>
                  <FormInputTextMaterial
                    onPressEnter={''}
                    validateType={false}
                    value={this.props.Village}
                    className={'inputFormBranch'}
                    disabled={false}
                    addonBefore={''}
                    placeholder={''}
                    prefix={''}
                    maxLength={''}
                    style={{}}
                    size=""
                    type={''}
                    onChange={''}
                    onBlur={''}
                    validatestatus={''}
                    help={''}
                  />
                </div>
              </form>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className={'marginRightFormDetailbranch'}>
              <span>
                <b>Rekanan</b>
              </span>
              <div className={'checkboxDetailBranch'}>
                <Checkbox checked={this.props.Partner} />
                <span className={'titleCheckboxDetailBranch'}>Pilih rekanan</span>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className={'marginLeftFormDetailbranch'}>
              <form>
                <div className="padding-top-form-input">
                  <span>
                    <b>Lokasi Posko</b>
                  </span>
                  <div className={'spacingTopBranchForm'}>
                    <span className={'poskoLocation'}>
                      {this.props.Latitude === null ||
                      this.props.Longitude === null
                        ? '- , -'
                        : `${this.props.Latitude}, ${this.props.Longitude}`}
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </Col>
          <Col span={12}>
          <div className={'marginRightFormDetailbranch'}>
              <form>
                <div className="padding-top-form-input">
                  <span>
                    <b>Grup Posko</b>
                  </span>
                  <FormInputTextMaterial
                    onPressEnter={''}
                    validateType={false}
                    value={valueGroupNameBranch}
                    className={'inputFormBranch'}
                    disabled={false}
                    addonBefore={''}
                    placeholder={''}
                    prefix={''}
                    maxLength={''}
                    style={{}}
                    size=""
                    type={''}
                    onChange={''}
                    onBlur={''}
                    validatestatus={''}
                    help={''}
                  />
                </div>
              </form>
            </div>
          </Col>
        </Row>

        <div className="padding-top-form-input">
          <span>
            <b>Alamat lengkap</b>
          </span>
          <TextArea
            className={'formAddressBranch'}
            value={this.props.Address}
          ></TextArea>
        </div>
      </div>
    );
  }
}
