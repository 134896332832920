import { createSelector } from 'reselect';

const selectorData = (state: any) => state.CallCenterState;


// SELECTOR CALL CENTER FORM
export const makeFormatCsvListSelector = () =>
  createSelector(selectorData, state => state.formatCsvList);
export const makeCustomerProductListSelector = () =>
  createSelector(selectorData, state => state.customerProductList);
export const makeCourierListSelector = () =>
  createSelector(selectorData, state => state.courierList);
export const makeFileNameSelector = () =>
  createSelector(selectorData, state => state.fileName);
export const makeCsvFileSelector = () =>
  createSelector(selectorData, state => state.csvFile);
export const makeDataCsvSuccessKuSelector = () =>
  createSelector(selectorData, state => state.successKu);
export const makeDataCsvFailedKuSelector = () =>
  createSelector(selectorData, state => state.failedKu);
export const makeDataCsvSuccessAttemptSelector = () =>
  createSelector(selectorData, state => state.successAttempt);
export const makeDataCsvFailedAttemptSelector = () =>
  createSelector(selectorData, state => state.failedAttempt);
export const makeDataCsvListSelector = () =>
  createSelector(selectorData, state => state.listResultCsv);