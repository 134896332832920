import React from 'react';
import moment from 'moment';
import { useMutation } from '@tanstack/react-query';
import ModalStatus from 'materials/ModalStatus';
import { downloadBlob } from 'lib/util';
import { downloadManifestBrankas } from 'services/ManifestSaveBrankas';
import ModalDownloadListManifestComponent from '../Component';

type ModalDownloadListManifestProps = {
  selectedData: any;
  isShow: boolean;
  onCancel: () => void;
};

export default function ModalDownloadListManifest({
  isShow,
  selectedData,
  onCancel,
}: ModalDownloadListManifestProps) {
  const { mutate: downloadManifestSafeCode, isLoading: isLoadingDownload } =
    useMutation(downloadManifestBrankas, {
      onSuccess: res => {
        if (res.status === 200) {
          downloadBlob(
            res.data,
            `DaftarManifest_${moment(selectedData?.createdAt).format(
              'YYYYMMDD'
            )}`
          );
          onCancel();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Menjalankan Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const handleDownloadManifest = () => {
    downloadManifestSafeCode(selectedData.bundleCode);
  };

  return (
    <ModalDownloadListManifestComponent
      isShow={isShow}
      onCancel={onCancel}
      selectedData={selectedData}
      isLoadingDownload={isLoadingDownload}
      onClickDownload={handleDownloadManifest}
    />
  );
}
