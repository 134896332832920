import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../../action/toggle';
import {
  clearListData,
  setListData,
} from '../../../action/Delivery/Document/ActionFormPrintWaybill';

import axios from 'axios';

export function* S_getDataScan(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(changeLoadingTrue());
    yield put(clearListData());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Delivery/listDownload',
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(setListData(data));
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(changeLoadingFalse());
  }
}
export function* SM_getDataScan() {
  yield takeLatest('GET_DATA_SCAN', S_getDataScan);
}
