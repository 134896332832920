import React from 'react';
import styles from './CountData.module.css';

type CountDataProps = {
  label: string;
  value: string | number;
  variant?: 'primary' | 'success' | 'warning';
};

const CountData = ({ label, value, variant = 'primary' }: CountDataProps) => {
  return (
    <div
      className={styles.wrapperCount}
      style={{
        color:
          variant === 'success'
            ? '#00AB44'
            : variant === 'warning'
            ? '#212121'
            : '#1890ff',
      }}
    >
      <p>
        {label}:{' '}
        <b style={{ ...(variant === 'warning' && { color: '#ECA722' }) }}>
          {value}
        </b>
      </p>
    </div>
  );
};

export default CountData;
