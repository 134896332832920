export function setCycleDateStart(CycleDateStart: any) {
  return {
    type: 'SET_CYCLE_DATE_START_DR',
    CycleDateStart,
  };
}
export function setCycleDateLast(CycleDateLast: any) {
  return {
    type: 'SET_CYCLE_DATE_LAST_DR',
    CycleDateLast,
  };
}
export function setCustomerId(CustomerId: any) {
  return {
    type: 'SET_CUSTOMER_ID_DR',
    CustomerId,
  };
}
export function setBranchId(BranchId: any) {
  return {
    type: 'SET_BRANCH_ID_DR',
    BranchId,
  };
}
export function setCheckBoxToday() {
  return {
    type: 'SET_CHECKBOX_TODAY_DR',
  };
}
export function clearCustomer() {
  return {
    type: 'CLEAR_CUSTOMER_DR',
  };
}
export function clearBranch() {
  return {
    type: 'CLEAR_BRANCH_DR',
  };
}
export function clearAllFormDeliveryReport() {
  return {
    type: 'CLEAR_ALL_FORM_DR',
  };
}
export function downloadDeliveryReport() {
  return {
    type: 'DOWNLOAD_DELIVERY_REPORT',
  };
}
