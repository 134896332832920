import { useMutation, useQuery } from '@tanstack/react-query';
import { Modal } from 'antd';
import Button from 'components/Button';
import SyncDataComponent from 'containers/Content/ManualProcess/SyncData/Component/SyncDataComponent';
import ModalDownloadProgress from 'materials/ModalDownloadProgress';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import {
  getCustomerList,
  getProductListByCustomer,
} from 'services/GeneralService';
import {
  getListSyncDataDelivery,
  mutateSyncData,
} from 'services/SyncDataServices';
import ModalDetailSyncDataContainer from './ModalDetailSyncDataContainer';

export default function SyncDataContainer(_props: any) {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentSize] = useState<number>(10);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(undefined);
  const [modalDetailSyncData, setModalDetailSyncData] =
    useState<boolean>(false);
  const [detailSyncData, setDetailSyncData] = useState<any>(null);

  const {
    data: dataSyncDataDelivery,
    isFetching: isFetchingDataSyncDataDelivery,
    refetch: refetchDataSyncDataDelivery,
  } = useQuery(
    ['dataSyncDataDelivery', currentPage, currentSize],
    getListSyncDataDelivery,
    {
      enabled: true,
    }
  );

  const { data: dataCustomers, isFetching: isFetchingCustomers } = useQuery(
    ['dataCustomers'],
    getCustomerList,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataProducts, isFetching: isFetchingProducts } = useQuery(
    ['dataProducts', selectedCustomer],
    getProductListByCustomer,
    {
      refetchOnWindowFocus: false,
      enabled: selectedCustomer !== undefined,
      onError: error => {
        console.log('error', error);
      },
    }
  );

  const listCustomer =
    dataCustomers &&
    dataCustomers.length > 0 &&
    dataCustomers.map((item: any) => ({
      id: item.id,
      name: item.name,
    }));
  const listProduct =
    dataProducts &&
    dataProducts.length > 0 &&
    dataProducts.map((item: any) => ({
      id: item.id,
      name: item.name,
    }));

  const columnData = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <b>No</b>
        </div>
      ),
      render: (_text, _record, index) => (
        <span>
          {currentPage > 0 ? index + (currentPage * 10 + 1) : index + 1}
        </span>
      ),
      key: 'no',
      width: 10,
    },
    {
      title: <b>Tanggal Sync Data</b>,
      dataIndex: 'syncDeliveryDate',
      key: 'syncDeliveryDate',
      render: (itemProcessTime: any) => (
        <span>{moment(itemProcessTime).format('DD-MM-YYYY HH:mm:ss')}</span>
      ),
      width: 60,
    },
    {
      title: <b>Tanggal Pengiriman</b>,
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      width: 60,
    },
    {
      title: <b>Diproses Oleh</b>,
      dataIndex: 'actorSync',
      key: 'actorSync',
      width: 60,
    },
    {
      title: <b>Pelanggan</b>,
      dataIndex: 'customer',
      key: 'customer',
      width: 60,
    },
    {
      title: <b>Produk</b>,
      dataIndex: 'customerProduct',
      key: 'customerProduct',
      width: 60,
    },
    {
      title: <b>Jumlah Waybill</b>,
      render: (row: any) => {
        const { waybillSuccess, totalWaybill } = row;
        return (
          <span>
            {waybillSuccess}/{totalWaybill}
          </span>
        );
      },
      width: 60,
    },

    {
      title: <b>Aksi</b>,
      key: 'action',
      width: 60,
      render: record => {
        return (
          <Button
            icon="eye"
            variant="info"
            onClick={() => {
              setDetailSyncData(record);
              setModalDetailSyncData(true);
            }}
          />
        );
      },
    },
  ];

  const mutateSyncDataDelivery = useMutation(mutateSyncData, {
    onSuccess: () => {
      Modal.success({
        title: (
          <p style={{ color: '#31E116' }}>
            <b>Sinkronisasi data berhasil</b>
          </p>
        ),

        onOk() {
          refetchDataSyncDataDelivery();
        },
      });
    },
    onError: (error: any) => {
      console.log('error', error.response.data);
      Modal.error({
        title: (
          <b style={{ color: '#C0C0C0' }}>{error.response.data.Message}</b>
        ),
      });
    },
  });

  const handleSyncDataDelivery = values => {
    const { typePeriode, periodeDelivery, statusDelivery, customerProduct } =
      values;
    mutateSyncDataDelivery.mutate({
      filterDateType: typePeriode,
      startDate: moment(periodeDelivery[0]).format('YYYYMMDD'),
      endDate: moment(periodeDelivery[1]).format('YYYYMMDD'),
      deliveryStats: statusDelivery,
      customerProducts: [customerProduct],
    });
  };
  return (
    <>
      <SyncDataComponent
        columnData={columnData}
        currentPage={currentPage}
        dataSyncDataDelivery={dataSyncDataDelivery?.data || []}
        handleSyncDataDelivery={handleSyncDataDelivery}
        isFetchingCustomers={isFetchingCustomers}
        isFetchingDataSyncDataDelivery={isFetchingDataSyncDataDelivery}
        isFetchingProducts={isFetchingProducts}
        listCustomer={listCustomer}
        listProduct={listProduct}
        setCurrentPage={setCurrentPage}
        setSelectedCustomer={setSelectedCustomer}
        totalDatas={dataSyncDataDelivery?.totalDatas || 0}
        totalPages={dataSyncDataDelivery?.totalPages || 0}
      />
      <ModalDownloadProgress
        loading={mutateSyncDataDelivery.isLoading}
        title="Sinkronisasi Data"
        description="Mohon tunggu sedang sinkronisasi data API pelangggan"
      />
      <ModalDetailSyncDataContainer
        modalDetailSyncData={modalDetailSyncData}
        setModalDetailSyncData={setModalDetailSyncData}
        detailSyncData={detailSyncData}
        setDetailSyncData={setDetailSyncData}
      />
    </>
  );
}
