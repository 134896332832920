import * as ActionAuth from '../../action/Auth';
import * as ActionCustomerConfig from '../../action/Customer/ActionCustomerStatusConfig';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionTableStatusCustom from '../../../core/action/Customer/ActionTableStatusDefault';
import * as ActionToggle from '../../action/toggle/index';

import {
    call,
    put,
    select,
    takeLatest
} from 'redux-saga/effects';

import axios from 'axios';

export function* S_deleteStatus(action:any)
{
        const state = yield select()
        const token = state.Auth.Token;
        const config = {
          Authorization: `bearer ${token}`,
          'Content-type': 'application/json',
        };
        const IdStatus = state.Customer.PropertiesCustomer.IdStatus
        const statusTokenRefresh = state.Auth.StatusTokenRefresh;
    try
    {
        yield put(ActionToggle.changeLoadingTrue())

        yield call(
            axios.put,process.env.REACT_APP_URL+'/Setting/CustomStatus/deactivate',
            {
                id: IdStatus,
            },
            { headers: config }

        )
        if(statusTokenRefresh===true)
        {
            yield put(ActionAuth.changeStatusTokenRefresh(false))
        }
        yield put(ActionToggle.clearJenisToggle())
        yield put(ActionCustomerConfig.changeClearStatusConfigForm())
        yield put(ActionTableStatusCustom.clearCustomDataStatus())
        yield put(ActionToggle.changeLoadingFalse())
        yield put(ActionTableStatusCustom.getCustomDataStatus())

    } catch (error) {
        yield put(ActionLogin.errorHandling(action, error));
    }
}

export function* SM_deleteStatus()
{
    yield takeLatest("DELETE_STATUS", S_deleteStatus)
}