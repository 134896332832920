import React, { useState } from 'react';
import { Form, Input, InputNumber, Select, Switch } from 'antd';
import CIcon from 'apps/assets/components/CIcon';

interface IProps {
  input?: any;
  idComponent?: any;
  placeholder?: any;
  meta?: any;
  label?: any;
  disabled?: any;
  formClassName?: any;
  formStyle?: any;
  addtionalLabel?: any;
  isLabelActive?: boolean;
  onChange?: (value: any) => void;
}

interface IPropsText extends IProps {
  icon?: any;
  className?: any;
  disableOnChange?: any;
  textArea?: any;
  classNameLabel?: any;
  maxlength?: any;
  additionalValue?: any;
  addonBefore?: any;
  additionalHandleEnterFunc?: any;
  regex?: any;
  onChange?: any;
  onBlur?: any;
  value?: any;
  type?: string;
  min?: number;
  max?: number;
  isOnlyNumber?: boolean;
}
interface IPropsFile extends IProps {
  icon?: any;
  className?: any;
  disableOnChange?: any;
  textArea?: any;
  classNameLabel?: any;
  maxlength?: any;
  additionalValue?: any;
  multiple?: any;
  accept?: any;
  additionalHandleChangeFunc?: any;
}

interface IPropsTextNumber extends IProps {
  icon?: any;
  min?: any;
  max?: any;
  defaultValue?: any;
}

interface IPropsSelect extends IProps {
  icon?: any;
  data: any;
  dafaultItemName?: any;
  loading?: any;
  mode?: any;
  additionalHandleChangeFunc?: any;
  additionalHandleOnFocusFunc?: any;
  combineValue?: any;
  disabledByKey?: any;
  disabledByKeyNegation?: any;
  className?: any;
  customOption?: any;
  handleSelectedlevel?: any;
}

const defaultStyle = { color: 'rgba(0,0,0,.25)' };
const defaultStyleInput = { width: '100%' };
const { Search } = Input;

export const InputText = ({
  input,
  meta,
  placeholder,
  idComponent,
  icon,
  label,
  disabled,
  disableOnChange,
  classNameLabel,
  className,
  textArea,
  formClassName,
  formStyle,
  maxlength,
  additionalValue,
  addtionalLabel,
  addonBefore,
  additionalHandleEnterFunc,
  onChange = () => undefined,
  value,
  type = 'text',
  min,
  max,
  isOnlyNumber = false,
}: IPropsText) => {
  const handleOnChange = (e: any) => {
    let value = e.target.value;
    const reg = /^-?[0-9]*(\.[0-9]*)?$/;

    if (value.startsWith(' ')) {
      value = value.trim();
    }
    if (maxlength && value.length > maxlength) {
      return;
    }

    if (
      type === 'number' &&
      ((isNaN(value) && reg.test(value)) || value === '-' || value === ',')
    ) {
      return;
    }

    if (
      isOnlyNumber &&
      ((!reg.test(value) && isNaN(value)) || value === '-' || value === ',')
    ) {
      return;
    }

    onChange(value);
    input?.onChange(value);
  };

  if (additionalValue !== undefined) {
    input?.onChange(additionalValue);
  }
  return (
    <div style={{ width: '100%' }}>
      {label && (
        <span>
          <b>{label}</b>
          <span style={{ color: '#BFBFBF' }}>
            {addtionalLabel ? ` ${addtionalLabel}` : null}
          </span>
        </span>
      )}
      <Form.Item
        validateStatus={
          meta?.touched && meta?.error !== undefined ? 'error' : ''
        }
        help={meta?.touched && meta?.error !== undefined ? meta?.error : ''}
        colon={false}
        className={formClassName}
        style={formStyle}
      >
        {textArea ? (
          <Input.TextArea
            placeholder={placeholder}
            onChange={handleOnChange}
            onBlur={input?.onBlur}
            id={idComponent}
            value={input?.value}
            autoSize={{ minRows: 4 }}
            disabled={disabled}
            className={className}
            maxLength={maxlength}
            style={{ marginTop: 8 }}
          />
        ) : (
          <Input
            placeholder={placeholder}
            prefix={icon ? <CIcon type={icon} style={defaultStyle} /> : null}
            addonBefore={addonBefore}
            onChange={disableOnChange ? undefined : handleOnChange}
            onBlur={input?.onBlur}
            id={idComponent}
            value={input?.value || value}
            disabled={disabled}
            className={className}
            maxLength={maxlength}
            onPressEnter={additionalHandleEnterFunc}
            type={type}
            min={min}
            max={max}
          />
        )}
      </Form.Item>
    </div>
  );
};

export const InputFile = ({
  input,
  meta: { dirty, error },
  label,
  addtionalLabel,
  idComponent,
  disabled,
  className,
  formClassName,
  multiple,
  accept,
  additionalHandleChangeFunc,
}: IPropsFile) => {
  const handleOnChange = (e: any) => {
    input.onChange(e.target.value);
    additionalHandleChangeFunc(e.target.files[0]);
  };
  const labelTitle = label ? (
    <span>
      <b>{label}</b>
      <span style={{ color: '#BFBFBF' }}>
        {addtionalLabel ? ` ${addtionalLabel}` : null}
      </span>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={dirty && error !== undefined ? 'error' : ''}
      help={dirty && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
      className={formClassName}
    >
      <Input
        suffix={<CIcon type={'upload'} />}
        onChange={handleOnChange}
        id={idComponent}
        value={input.value}
        disabled={disabled}
        className={className}
        multiple={multiple}
        type="file"
        accept={accept}
      />
    </Form.Item>
  );
};

export const TextInputSearch = ({
  placeholder,
  idComponent,
  disabled,
  className,
  label,
  additionalHandleEnterFunc,
  regex,
  onChange,
  onBlur,
  value,
}: IPropsText) => {
  const labelTitle = label ? (
    <span>
      <b>{label}</b>
    </span>
  ) : null;
  const handleOnChange = (e: any) =>
    onChange(
      regex === 'noSpaceInFirstChar'
        ? e.target.value.replace(/^\s/, '')
        : e.target.value
    );
  return (
    <div>
      <span className="titleSearchCard">{labelTitle}</span>
      <Input.Search
        placeholder={placeholder}
        onChange={handleOnChange}
        onPressEnter={additionalHandleEnterFunc}
        onBlur={onBlur}
        id={idComponent}
        value={value}
        disabled={disabled}
        // style={{ borderRadius: '10px', width: '50%' }}
        className={className}
      />
    </div>
  );
};

export const TextNumberInput = ({
  input,
  placeholder,
  idComponent,
  icon,
  min,
  max,
  defaultValue,
  disabled,
}: IPropsTextNumber) => {
  return (
    <InputNumber
      style={{ width: 60, marginLeft: 3 }}
      placeholder={placeholder}
      prefix={icon ? <CIcon type={icon} style={defaultStyle} /> : null}
      id={idComponent}
      min={min}
      max={max}
      defaultValue={defaultValue}
      disabled={disabled}
      {...input}
    />
  );
};
export const InputTextNumber = ({
  input,
  placeholder,
  meta: { touched, error },
  idComponent,
  icon,
  label,
  min,
  max,
  defaultValue,
  disabled,
}: IPropsTextNumber) => {
  const labelTitle = label ? (
    <span>
      <b className="capital">{label}</b>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <InputNumber
        style={defaultStyleInput}
        placeholder={placeholder}
        prefix={icon ? <CIcon type={icon} style={defaultStyle} /> : null}
        id={idComponent}
        min={min}
        max={max}
        defaultValue={defaultValue}
        disabled={disabled}
        {...input}
      />
    </Form.Item>
  );
};
export const InputSwitch = ({
  input,
  meta: { touched, error },
  idComponent,
  label,
  disabled,
  isLabelActive = false,
  onChange = () => undefined,
  formStyle,
}: IProps) => {
  const labelTitle = label ? (
    <span>
      <b className="capital">{label}</b>
    </span>
  ) : null;
  const handleOnChange = (e: any) => {
    input.onChange(e);
    onChange(e);
  };
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
      style={formStyle}
    >
      <div
        style={{ display: 'flex', alignItems: 'center', gap: 8 }}
        onClick={e => e.stopPropagation()}
      >
        <Switch
          defaultChecked={false}
          disabled={disabled}
          checked={input.value === '' ? false : input.value}
          onChange={handleOnChange}
        />
        {isLabelActive && (
          <p
            style={{
              margin: 0,
              fontWeight: 700,
              color: input.value ? '#1890FF' : '#EA1A1A',
            }}
          >
            {input.value ? 'Aktif' : 'Nonaktif'}
          </p>
        )}
      </div>
    </Form.Item>
  );
};
export const InputSelect = ({
  input,
  meta: { touched, error },
  data,
  mode,
  placeholder,
  dafaultItemName,
  idComponent,
  label,
  additionalHandleChangeFunc,
  additionalHandleOnFocusFunc,
  disabled,
  combineValue,
  disabledByKey,
  disabledByKeyNegation,
  className,
  formClassName,
  customOption,
  loading,
  addtionalLabel,
  handleSelectedlevel = () => undefined,
}: IPropsSelect) => {
  const items = data.map((item: any, key: any) => {
    return (
      <Select.Option
        key={`${item.id}${key}`}
        value={item.id}
        disabled={
          disabledByKey
            ? disabledByKeyNegation === false
              ? item[disabledByKey]
              : !item[disabledByKey]
            : false
        }
      >
        {combineValue
          ? `${item[combineValue[1]]} ${combineValue[0]} ${
              item[combineValue[2]]
            }`
          : item.name}
      </Select.Option>
    );
  });

  const optionLabelAndCode = data.map((item: any, key: any) => {
    return (
      <Select.Option value={item.id}>
        {item.firstName}
        {` (${item.code})`}
      </Select.Option>
    );
  });
  const [count, setcount] = useState(0);

  const handleOnChange = (e, label) => {
    input.onChange(e);
    handleSelectedlevel(e);
    setcount(count + 1);
  };
  const labelTitle = (
    <span>
      <b>{label}</b>
      <span style={{ color: '#BFBFBF' }}>
        {addtionalLabel ? ` ${addtionalLabel}` : null}
      </span>
    </span>
  );

  return (
    <Form.Item
      validateStatus={
        (count > 0 || input.value) && error !== undefined ? 'error' : ''
      }
      help={(count > 0 || input.value) && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
      id={idComponent}
      className={formClassName}
    >
      <Select
        placeholder={placeholder}
        onChange={handleOnChange}
        // onBlur={input.onBlur}
        loading={loading}
        mode={mode}
        onSelect={additionalHandleChangeFunc}
        onFocus={additionalHandleOnFocusFunc}
        id={idComponent}
        value={input.value || undefined}
        style={defaultStyleInput}
        disabled={disabled}
        className={className}
        showSearch={true}
        optionFilterProp="children"
      >
        {dafaultItemName ? (
          <Select.Option value="">{dafaultItemName}</Select.Option>
        ) : (
          ''
        )}
        {customOption === 'labelAndCode' ? optionLabelAndCode : items}
      </Select>
    </Form.Item>
  );
};
export const InputSelectPrefix = ({
  input,
  data,
  dafaultItemName,
  idComponent,
  additionalHandleChangeFunc,
  disabled,
  combineValue,
  disabledByKey,
  disabledByKeyNegation,
  className,
  customOption,
}: IPropsSelect) => {
  const items = data.map((item: any, key: any) => {
    return (
      <Select.Option
        key={`${item.id}${key}`}
        value={item.id}
        disabled={
          disabledByKey
            ? disabledByKeyNegation === false
              ? item[disabledByKey]
              : !item[disabledByKey]
            : false
        }
      >
        {combineValue
          ? `${item[combineValue[1]]} ${combineValue[0]} ${
              item[combineValue[2]]
            }`
          : item.name}
      </Select.Option>
    );
  });

  const optionLabelAndCode = data.map((item: any, key: any) => {
    return (
      <Select.Option value={item.id}>
        {item.firstName}
        {` (${item.code})`}
      </Select.Option>
    );
  });

  const handleOnChange = (e: any) => {
    input.onChange(e);
  };

  return (
    <Select
      onChange={handleOnChange}
      // onBlur={input.onBlur}
      onSelect={additionalHandleChangeFunc}
      id={idComponent}
      value={input.value}
      style={defaultStyleInput}
      disabled={disabled}
      className={className}
    >
      <Select.Option value="">{dafaultItemName}</Select.Option>
      {customOption === 'labelAndCode' ? optionLabelAndCode : items}
    </Select>
  );
};

export const InputPassword = ({
  input,
  meta: { touched, error },
  idComponent,
  icon,
  label,
  placeholder,
  disabled,
}: IPropsText) => {
  const handleOnChange = (e: any) => input.onChange(e.target.value);
  const labelTitle = label ? (
    <span>
      <b className="capital">{label}</b>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <Input.Password
        prefix={icon ? <CIcon type={icon} style={defaultStyle} /> : null}
        onChange={handleOnChange}
        onBlur={input.onBlur}
        id={idComponent}
        value={input.value}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Form.Item>
  );
};
export const InputSearch = ({
  input,
  meta: { touched, error },
  placeholder,
  idComponent,
  icon,
  label,
  disabled,
  disableOnChange,
  classNameLabel,
  className,
  formClassName,
  maxlength,
  additionalValue,
  addtionalLabel,
  addonBefore,
}: IPropsText) => {
  const handleOnChange = (e: any) => input.onChange(e.target.value);
  if (additionalValue !== undefined) {
    input.onChange(additionalValue);
  }
  const labelTitle = label ? (
    <span>
      <b className={classNameLabel}>{label}</b>
      <span style={{ color: '#BFBFBF' }}>
        {addtionalLabel ? ` ${addtionalLabel}` : null}
      </span>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
      className={formClassName}
    >
      <Search
        placeholder={placeholder}
        prefix={icon ? <CIcon type={icon} style={defaultStyle} /> : null}
        addonBefore={addonBefore}
        onChange={disableOnChange ? undefined : handleOnChange}
        onBlur={input.onBlur}
        id={idComponent}
        value={input.value}
        disabled={disabled}
        className={className}
        maxLength={maxlength}
      />
    </Form.Item>
  );
};
