import '../Component/LoginComponent';

import * as ActionAuth from '../../../core/action/Auth';
import * as ActionDeliverylBranch from '../../../core/action/Branch/ActionDeliveryBranch';
import * as ClearAuth from '../../../core/action/Logout/index';
import * as SelectorLogin from 'module/Login/Selector/LoginSelector';
import * as getDataProfile from '../../../core/action/Profile/ActionProfile';
import * as toggle from '../../../core/action/toggle/index';

import {
  GetRole,
  changeToken,
  changeTokenRefresh,
  changeUsernameAuth,
  changeSideMenus,
} from '../../../core/action/Auth';
import { Modal, notification } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import React from 'react';
import axios from 'axios';
import history from '../../../core/utils/History';

export function* S_loginProcess() {
  try {
    yield put(toggle.changeLoadingTrue());
    const state = yield select();
    const token = state.Auth.Token;
    const config = {
      Authorization: `bearer ${token}`,
    };
    const usernameLogin = yield select(SelectorLogin.usernameSelector());
    const passwordLogin = yield select(SelectorLogin.passwordSelector());
    const response = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Auth/Login',
      {
        username: usernameLogin,
        password: passwordLogin,
      },
      { headers: config }
    );

    if (response.data) {
      yield put(changeToken(response.data.token));
      yield put(changeTokenRefresh(response.data.tokenRefresh));
      yield put(changeUsernameAuth(usernameLogin));
      yield put(GetRole(usernameLogin, response.data.token));
      yield put(changeSideMenus(response.data.menu));

      yield put(getDataProfile.getDataProfile());
      yield put(ActionDeliverylBranch.getDeliveryBranch());
      yield put(toggle.changeLoadingFalse());
    }

    if (response !== null) {
      Modal.success({
        title: (
          <p style={{ color: '#31E116' }}>
            <b>Berhasil masuk</b>
          </p>
        ),
        content: (
          <div>
            <p style={{ color: 'black' }}>
              Anda sekarang adalah {usernameLogin}
            </p>
          </div>
        ),
        onOk() {},
      });
      history.push('/');
    }
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    if (error.response?.status === 500) {
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            Nama Pengguna atau Sandi anda salah
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>Silahkan coba lagi</p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}
export function* S_loginTokenRefrash(action: any) {
  const openNotification = () => {
    notification.warning({
      message: 'Sesi berakhir',
      description: 'Silahkan login kembali',
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  };
  const state = yield select();
  const token = state.Auth.Token;
  const tokenRefresh = state.Auth.TokenRefresh;
  try {
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Token/refresh/${tokenRefresh}`,
      `"${token}"`,
      { headers: { 'Content-Type': 'application/json' } }
    );

    yield put(ActionAuth.changeStatusTokenRefresh(true));
    yield put(ActionAuth.changeToken(data.token));

    if (action.lastAction !== null) {
      yield put(action.lastAction.actionData);
    } else {
    }
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    if (error.response?.status === 500) {
      yield put(ClearAuth.changeClearToken());
      yield put(ClearAuth.changeClearTokenRefresh());
      yield put(ClearAuth.changeClearUsernameAuth());
      yield put(ClearAuth.changeClearDataProfileAuth());
      yield put(toggle.changeJenisToggle('logout'));
      yield put(toggle.clearJenisToggle());
      openNotification();
    }
  }
}

export function* S_loginTokenRefreshTemporary() {
  const openNotification = () => {
    notification.warning({
      message: 'Sesi berakhir',
      description: 'Silahkan login kembali',
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  };
  const state = yield select();
  const token = state.Auth.Token;
  const tokenRefresh = state.Auth.TokenRefresh;
  try {
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Token/refresh/` + tokenRefresh,
      `"${token}"`,
      { headers: { 'Content-Type': 'application/json' } }
    );

    yield put(ActionAuth.changeStatusTokenRefresh(true));
    yield put(ActionAuth.changeToken(data.token));
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    if (error.response?.status === 500) {
      yield put(ClearAuth.changeClearToken());
      yield put(ClearAuth.changeClearTokenRefresh());
      yield put(ClearAuth.changeClearUsernameAuth());
      yield put(ClearAuth.changeClearDataProfileAuth());
      yield put(toggle.changeJenisToggle('logout'));
      yield put(toggle.clearJenisToggle());
      openNotification();
    }
  }
}

export function* SM_login() {
  yield takeLatest('LOGIN_REQUESTED', S_loginProcess);
}
export function* SM_loginTokenRefrash() {
  yield takeLatest('REQUEST_TOKEN_REFRASH', S_loginTokenRefrash);
}
export function* SM_loginTokenRefreshTemporary() {
  yield takeLatest(
    'REQUEST_TOKEN_REFRESH_TEMPORARY',
    S_loginTokenRefreshTemporary
  );
}
