export function getGroupList()
{
    return({
        type:"GET_GROUP_LIST"
    })
}
export function clearGroupList()
{
    return({
        type:"CLEAR_GROUP_LIST"
    })
}
export function addGroupList()
{
    return({
        type:"ADD_GROUP_LIST"
    })
}
export function editGroupList()
{
    return({
        type:"EDIT_GROUP_LIST"
    })
}
export function clearGroupName()
{
    return({
        type:"CLEAR_GROUP_NAME_FORM"
    })
}
export function clearGroupId()
{
    return({
        type:"CLEAR_MANIFEST_GROUP_ID"
    })
}
export function changeValidateGroupName(ValidateGroupName:any)
{
    return({
        type:"CHANGE_VALIDATE_GROUP_NAME",
        ValidateGroupName
    })
}
export function clearValidateGroupName()
{
    return({
        type:"CLEAR_VALIDATE_GROUP_NAME"
    })
}
export function changeGroupNameForm(GroupNameForm:any)
{
    return({
        type:"CHANGE_GROUP_NAME_MANIFEST_FORM",
        GroupNameForm
    })
}
export function changeGroupId(GroupId:any)
{
    return({
        type:"CHANGE_GROUP_ID",
        GroupId
    })
}