import { Action } from 'redux';

const initialState = {
  propertiesResendSingleData: {
      waybill:null,
      deliveryDate:null,
      disableForm:true,
  },
  name:null,
  phoneNumber:null,
  address:null,
  convertAddress:[],
};

export interface IAuthAction extends Action<string> {}

export interface IChangeWaybill extends IAuthAction {
  waybill: any;
}
export interface IChangeDeliveryDate extends IAuthAction {
  deliveryDate: any;
}
export interface IChangeDisableForm extends IAuthAction {
    disableForm: any;
}
export interface IChangeName extends IAuthAction {
  name: any;
}
export interface IChangePhoneNumber extends IAuthAction {
  phoneNumber: any;
}
export interface IChangeAddress extends IAuthAction {
  address: any;
}
export interface IChangeConvertAddress extends IAuthAction {
    convertAddress: any;
}

export default function AuthReducer(state = initialState, action: IAuthAction) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_WAYBILL_RS':
      const changeWaybillRS = action as IChangeWaybill;
      newState.propertiesResendSingleData.waybill = changeWaybillRS.waybill;

      return { ...newState };
    
      case 'CHANGE_DELIVERY_DATE_RS':
      const changeDeliveryDate = action as IChangeDeliveryDate;
      newState.propertiesResendSingleData.deliveryDate = changeDeliveryDate.deliveryDate;

      return { ...newState };
      
      case 'CHANGE_DISABLE_FORM_RS':
      const changeDisableForm = action as IChangeDisableForm;
      newState.propertiesResendSingleData.disableForm = changeDisableForm.disableForm;

      return { ...newState };
      
      case 'CHANGE_NAME_RS':
      const changeName = action as IChangeName;
      newState.name = changeName.name;

      return { ...newState };
      
      case 'CHANGE_PHONE_NUMBER_RS':
      const changePhoneNumer = action as IChangePhoneNumber;
      newState.phoneNumber = changePhoneNumer.phoneNumber;

      return { ...newState };
      
      case 'CHANGE_ADDRESS_RS':
      const changeAddress = action as IChangeAddress;
      newState.address = changeAddress.address;

      return { ...newState };
      
      case 'CHANGE_CONVERT_ADDRESS_RS':
      const changeConvertAddress = action as IChangeConvertAddress;
      newState.convertAddress = changeConvertAddress.convertAddress;

      return { ...newState };

    case 'CLEAR_WAYBILL_RS':
      newState.propertiesResendSingleData.waybill = null;

      return { ...newState };
    case 'CLEAR_DELIVERY_DATE_RS':
      newState.propertiesResendSingleData.deliveryDate = null;

      return { ...newState };
    case 'CLEAR_ADDRESS_RS':
        newState.address = null;

      return { ...newState };
    
    case 'CLEAR_FORM_EXCEPT_PROPERTIES_RS':
        newState.name = null;
      newState.phoneNumber = null;
      newState.address = null;
      newState.convertAddress = [];
      return { ...newState };
    
    case 'CLEAR_ALL_FORM_RS':
      newState.name = null;
      newState.phoneNumber = null;
      newState.address = null;
      newState.convertAddress = [];
      newState.propertiesResendSingleData.waybill = null;
      newState.propertiesResendSingleData.deliveryDate = null;
      newState.propertiesResendSingleData.disableForm = true;
      return { ...newState };
  }
  return { ...newState };
}
