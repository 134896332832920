import * as ActionAuth from '../../../action/Auth';
import * as ActionDistribution from '../../../action/Delivery/Document/ActionFormDistribusi';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

export function* S_GetTotalSacn(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const CycleDate = moment(
    state.Delivery.DistributionDocument.CycleDate
  ).format('YYYYMMDD');
  const CustomerProduct = state.Delivery.DistributionDocument.CustomerProduct;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Delivery/statusScan/' +
        CycleDate +
        '/' +
        CustomerProduct,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionDistribution.setScan(data.scan));
    yield put(ActionDistribution.setUnScan(data.unScan));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* S_GetListScan(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const CycleDate = moment(
    state.Delivery.DistributionDocument.CycleDate
  ).format('YYYYMMDD');
  const CustomerProduct = state.Delivery.DistributionDocument.CustomerProduct;
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Delivery/listScanOrUnscan/' +
        CycleDate +
        '/' +
        CustomerProduct +
        '/ScanBarcode',
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionDistribution.setListScan(data));
    yield put(toggle.changeJenisToggle('scan'));
    yield put(toggle.changeModal());
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* S_GetListUnScan(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const CycleDate = moment(
    state.Delivery.DistributionDocument.CycleDate
  ).format('YYYYMMDD');
  const CustomerProduct = state.Delivery.DistributionDocument.CustomerProduct;
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Delivery/listScanOrUnscan/' +
        CycleDate +
        '/' +
        CustomerProduct +
        '/Uploaded',
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionDistribution.setListUnScan(data));
    yield put(toggle.changeJenisToggle('unscan'));
    yield put(toggle.changeModal());
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_ScanByBarcode(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const WaybillBarcode = state.Delivery.DistributionDocument.Waybill;
  const Dimention = state.Delivery.DistributionDocument.AmplopType;
  const customerProductId = state.Delivery.DistributionDocument.CustomerProduct;
  const DeliveryType = state.Delivery.DistributionDocument.DeliveryType;
  const DeliveryDistrictType =
    state.Delivery.DistributionDocument.DeliveryDistrictType;
  const BranchId = state.Delivery.DistributionDocument.BranchId;
  const CourierId = state.Delivery.DistributionDocument.CourierId;
  const ProductType = state.Delivery.DistributionDocument.ProductType;
  const CourierCode = state.Delivery.DistributionDocument.CourierCode;
  const CourierName = state.Delivery.DistributionDocument.CourierName;
  const ReceiptNumber = state.Delivery.DistributionDocument.ReceiptNumber;
  const koli = state.Delivery.DistributionDocument.koli;
  const weight = state.Delivery.DistributionDocument.weight;
  const Checkbox = state.Delivery.DistributionDocument.Checkbox;
  const IsReverseBarcode = state.Delivery.DistributionDocument.IsReverseBarcode;
  const CheckboxKoli = state.Delivery.DistributionDocument.CheckboxKoli;
  const CycleDate = moment(
    state.Delivery.DistributionDocument.CycleDate
  ).format('YYYYMMDD');
  const Status =
    CourierName === null
      ? WaybillBarcode + ' Assign to Posko'
      : WaybillBarcode + ' Assign to ' + CourierCode + '-' + CourierName;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.put,
      Checkbox === true
        ? process.env.REACT_APP_URL + '/Delivery/scanByBarcode'
        : process.env.REACT_APP_URL + '/Delivery/scanByWaybill',
      {
        customerProductId: customerProductId === null ? 0 : customerProductId,
        noResi: ReceiptNumber,
        productType: ProductType === null ? 0 : ProductType,
        trueOrFalseKoli: CheckboxKoli,
        barcode:
          Checkbox === true
            ? IsReverseBarcode
              ? WaybillBarcode.split('').reverse().join('')
              : WaybillBarcode
            : 'string',
        waybill: Checkbox === true ? 'string' : WaybillBarcode,
        dimention: Dimention,
        cycle:
          CycleDate === 'Invalid date' || CycleDate === null ? '' : CycleDate,
        deliveryType: DeliveryType,
        deliveryDistrictType: DeliveryDistrictType,
        branchId: BranchId,
        courierId: CourierId === null ? 0 : CourierId,
        koli: koli,
        totalWeight: weight === '' || weight === null ? 0 : weight,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionDistribution.setStatus(Status));
    yield put(toggle.changeLoadingFalse());
    if (customerProductId === null || customerProductId === undefined) {
    } else {
      yield put(ActionDistribution.getValueTotalScan());
    }
    yield put(ActionDistribution.ClearWaybill());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* S_GetCourierCodeAndName(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const CourierId = state.Delivery.DistributionDocument.CourierId;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Courier/' + CourierId,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionDistribution.setCourierCode(data.code));
    yield put(ActionDistribution.setCourierName(data.firstName));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}
export function* SM_GetListScan() {
  yield takeLatest('GET_LIST_SCAN', S_GetListScan);
}
export function* SM_GetListUnScan() {
  yield takeLatest('GET_LIST_UN_SCAN', S_GetListUnScan);
}
export function* SM_ScanByBarcode() {
  yield takeLatest('CHANGE_SCAN_BY_BARCODE', S_ScanByBarcode);
}
export function* SM_GetTotalSacn() {
  yield takeLatest('GET_VALUE_TOTAL_SCAN', S_GetTotalSacn);
}
export function* SM_GetCourierCodeAndName() {
  yield takeLatest('GET_COURIER_CODE_AND_NAME', S_GetCourierCodeAndName);
}
