import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Radio,
  Row,
  Select,
  Tooltip,
} from 'antd';

import ButtonMaterial from 'materials/ButtonMaterial';
import CardMaterial from 'materials/CardMaterial';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';
import moment from 'moment';

const { Option } = Select;
interface IProps {
  isLoading: any;
  ValueCycleStartDate: any;
  CycleDateStart: any;
  Customer: any;
  CustomerProduct: any;
  CheckboxIsMonth: any;
  listCustomer: any;
  listCustomerProduct: any;
  LoadingSelectCustomer: any;
  LoadingTypeSelectCustomer: any;
  LoadingSelectCustomerProduct: any;
  LoadingTypeSelectCustomerProduct: any;
  RadiobuttonIsDetailInvoice: any;
  handlePutRadioButtonIsDetail: any;
  ValidateCycleDateStart: any;
  ValidateCustomer: any;
  ValidateCustomerProduct: any;
  handlePutCycleStart: any;
  handleValidateCycleStart: any;
  handlePutCustomer: any;
  handleGetCustomer: any;
  handleGetCustomerProduct: any;
  handleBlurCustomer: any;
  handleBlurCustomerProduct: any;
  handlePutCustomerProduct: any;
  handleResetCustomer: any;
  handleResetAll: any;
  handleDownloadInvoice: any;
  handlePutCheckbox: any;
}
export default function InvoiceComponent({
  isLoading,
  ValueCycleStartDate,
  CycleDateStart,
  Customer,
  CustomerProduct,
  CheckboxIsMonth,
  listCustomer,
  listCustomerProduct,
  LoadingSelectCustomer,
  LoadingSelectCustomerProduct,
  RadiobuttonIsDetailInvoice,
  handlePutRadioButtonIsDetail,
  ValidateCycleDateStart,
  ValidateCustomer,
  ValidateCustomerProduct,
  handlePutCycleStart,
  handleValidateCycleStart,
  handlePutCustomer,
  handleGetCustomer,
  handleGetCustomerProduct,
  handleBlurCustomer,
  handleBlurCustomerProduct,
  handlePutCustomerProduct,
  handleResetCustomer,
  handleResetAll,
  handleDownloadInvoice,
  handlePutCheckbox,
}: IProps) {
  const { MonthPicker } = DatePicker;
  const ValidateButtonDownload =
    CycleDateStart === null ||
    ValidateCycleDateStart === 'error' ||
    Customer === null ||
    ValidateCustomer === 'error' ||
    CustomerProduct === [] ||
    CustomerProduct.length === 0 ||
    ValidateCustomerProduct === 'error'
      ? true
      : false;
  return (
    <div>
      <SpinMaterial
        spinning={isLoading}
        style={{}}
        size={'large'}
        content={
          <CardMaterial
            extra={''}
            title={<b className="titleCard">FAKTUR</b>}
            style={{ borderRadius: '10px' }}
            content={
              <div>
                <Row>
                  <Col span={12} className="spacingRight">
                    <div>
                      <span>
                        <b>Tanggal Cycle</b>
                      </span>
                      <Row>
                        <Col span={18}>
                          <Form.Item
                            validateStatus={
                              ValidateCycleDateStart === 'error' ? 'error' : ''
                            }
                            help={
                              ValidateCycleDateStart === 'error'
                                ? 'Cycle tidak boleh kosong'
                                : ''
                            }
                          >
                            {CheckboxIsMonth === false ? (
                              <DatePicker
                                className={'datePickerDefault'}
                                format={'YYYY-MM-DD'}
                                defaultPickerValue={moment(
                                  CycleDateStart,
                                  'YYYY-MM-DD'
                                )}
                                placeholder={'Pilih Tanggal'}
                                onOpenChange={handleValidateCycleStart}
                                value={ValueCycleStartDate}
                                onChange={handlePutCycleStart}
                              />
                            ) : (
                              <MonthPicker
                                className={'datePickerDefault'}
                                format={'YYYY-MM'}
                                placeholder={'Pilih Bulan'}
                                onOpenChange={handleValidateCycleStart}
                                value={ValueCycleStartDate}
                                onChange={handlePutCycleStart}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Tooltip
                            placement="top"
                            title={'Ceklis untuk format bulanan'}
                          >
                            <Checkbox
                              style={{
                                paddingTop: 10,
                                paddingLeft: '24px',
                              }}
                              checked={CheckboxIsMonth}
                              onChange={handlePutCheckbox}
                            />
                          </Tooltip>
                          <span className={'checkboxSpan'}>Bulanan</span>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <span className={'fontWeight'}>
                        Laporan Invoice Detail
                      </span>
                      <Row className={'spacingTop'}>
                        <Col span={24}>
                          <Radio.Group
                            onChange={handlePutRadioButtonIsDetail}
                            value={RadiobuttonIsDetailInvoice}
                          >
                            <Radio className={'spacingRight'} value={true}>
                              Ya
                            </Radio>
                            <Radio value={false}>Tidak</Radio>
                          </Radio.Group>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col span={12} className="spacingLeft">
                    <div>
                      <span>
                        <b>Pelanggan</b>
                      </span>
                      <Form.Item
                        validateStatus={
                          ValidateCustomer === 'error' ? 'error' : ''
                        }
                        help={
                          ValidateCustomer === 'error'
                            ? 'Pelanggan tidak boleh kosong'
                            : ''
                        }
                      >
                        <Select
                          loading={LoadingSelectCustomer}
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          disabled={false}
                          showSearch
                          placeholder={'Pilih Pelanggan'}
                          onSelect={handleResetCustomer}
                          onChange={handlePutCustomer}
                          onBlur={handleBlurCustomer}
                          value={
                            Customer === null ? (
                              <span className="colorPlaceholderDropDown">
                                Pilih Pelanggan
                              </span>
                            ) : (
                              Customer
                            )
                          }
                          onFocus={handleGetCustomer}
                        >
                          {listCustomer.map((data: any, index: any) => (
                            <Option key={index} value={data.id}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Produk</b>
                      </span>
                      <Form.Item
                        validateStatus={
                          ValidateCustomerProduct === 'error' ? 'error' : ''
                        }
                        help={
                          ValidateCustomerProduct === 'error'
                            ? 'Produk tidak boleh kosong!!!'
                            : ''
                        }
                      >
                        <Select
                          loading={LoadingSelectCustomerProduct}
                          mode="multiple"
                          value={CustomerProduct}
                          disabled={Customer === null ? true : false}
                          placeholder="Pilih Produk"
                          showSearch
                          allowClear={true}
                          onFocus={handleGetCustomerProduct}
                          onDeselect={handleBlurCustomerProduct}
                          onSelect={handleBlurCustomerProduct}
                          onBlur={handlePutCustomerProduct}
                          onChange={handlePutCustomerProduct}
                          style={{ width: '100%' }}
                        >
                          {listCustomerProduct.length ===
                          CustomerProduct.length ? null : (
                            <Option key={''} value={'SetAllProduct'}>
                              {'Pilih Semua Produk'}
                            </Option>
                          )}
                          {listCustomerProduct.map((data: any, index: any) => (
                            <Option key={index} value={data.id}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="spacingDownloadButton">
                    <ButtonMaterial
                      disabled={ValidateButtonDownload}
                      handleSubmit={handleDownloadInvoice}
                      teksButton={<b>Download</b>}
                      size={''}
                      shape={'round'}
                      className={
                        ValidateButtonDownload === true
                          ? 'validateDownloadButtonDisabled'
                          : 'validateDownloadBtnEnable2'
                      }
                      type={'primary'}
                      icon={''}
                      style={{}}
                    />
                  </div>
                  <div>
                    <ButtonMaterial
                      disabled={false}
                      handleSubmit={handleResetAll}
                      teksButton={<b>Reset</b>}
                      size={'middle'}
                      shape={'round'}
                      className={'validateResetButtonEnable'}
                      type={'primary'}
                      icon={''}
                      style={{
                        float: 'right',
                        background: '#FF5858',
                        borderColor: '#FF5858',
                        colorFont: '#FFFFFF',
                        height: '35px',
                        width: '100px',
                      }}
                    />
                  </div>
                </Row>
              </div>
            }
          />
        }
      />
    </div>
  );
}
