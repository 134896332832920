import { Action } from 'redux';
import { IFetchComplaintReportAction } from './ComplaintReportAction';

const initialState: any = {
  customerName: null,
  customerProductList: [],
};

export default function PickUpProcessReducer(
  state = initialState,
  action: Action
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_COMPLAINT_REPORT_CUSTOMER_PRODUCT_LIST': {
      const fetchCustomerProductListAction =
        action as IFetchComplaintReportAction;
      newState.customerProductList = fetchCustomerProductListAction.data;
      return { ...newState };
    }
    case 'CHANGE_CUSTOMER_NAME_COMPLAINT_REPORT': {
      const changeCustomerNameAction = action as IFetchComplaintReportAction;
      newState.customerName = changeCustomerNameAction.data;
      return { ...newState };
    }
    case 'CLEAR_CUSTOMER_PRODUCT_LIST_PICKUP': {
      newState.customerProductList = [];
      return { ...newState };
    }
  }
  return state;
}
