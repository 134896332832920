import * as ActionAddCustomer from '../../../core/action/Customer/ActionAddCustomer';
import * as ActionDetailCustomer from '../../../core/action/Customer/ActionDetailCustomer';
import * as ActionEditCustomer from '../../../core/action/Customer/ActionEditCustomer';
import * as ActionFieldCustomer from '../../../core/action/Customer/ActionFieldCustomer';
import * as ActionToggle from '../../../core/action/toggle/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import FormCustomerComponent from '../../../components/Content/Customer/FormCustomerComponent';
import { connect } from 'react-redux';

interface IProps {
  typeToggle: any;
  visible: any;
  isLoading: any;
  actionToggle: any;
  actionFieldCustomer: any;
  actionAddCustomer: any;
  actionEditCustomer: any;
  actionDetailCustomer: any;
  CustomerName: any;
  CustomerCode: any;
  CustomerType: any;
  CustomerTelephone: any;
  CustomerEmail: any;
  CustomerTypeOther: any;
  CustomerAddress: any;
  CustomerAccess: any;
  EditCustomer: any;
}
interface IState {
  ValidateCustomerName: any;
  ValidateCustomerCode: any;
  ValidateCustomerType: any;
  ValidateCustomerTelephone: any;
  ValidateCustomerEmail: any;
  ValidateCustomerTypeOther: any;
  ValidateCustomerAddress: any;
  ValidateCustomerAccess: any;
}
class FormCustomerContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateCustomerName: null,
      ValidateCustomerCode: null,
      ValidateCustomerType: null,
      ValidateCustomerTelephone: null,
      ValidateCustomerEmail: null,
      ValidateCustomerTypeOther: null,
      ValidateCustomerAddress: null,
      ValidateCustomerAccess: null,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCustomerName = this.handleCustomerName.bind(this);
    this.handleCustomerCode = this.handleCustomerCode.bind(this);
    this.handleCustomerType = this.handleCustomerType.bind(this);
    this.handleCustomerTelephone = this.handleCustomerTelephone.bind(this);
    this.handleCustomerEmail = this.handleCustomerEmail.bind(this);
    this.handleCustomerTypeOther = this.handleCustomerTypeOther.bind(this);
    this.handleCustomerAddress = this.handleCustomerAddress.bind(this);
    this.handleCustomerAccess = this.handleCustomerAccess.bind(this);
    this.handleBlurCustomerType = this.handleBlurCustomerType.bind(this);
    this.handleBlurCustomerAccess = this.handleBlurCustomerAccess.bind(this);
    this.handleAddCustomer = this.handleAddCustomer.bind(this);
    this.handleEditCustomer = this.handleEditCustomer.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  handleCustomerName(event: any) {
    this.props.actionFieldCustomer.setCustomerName(event.target.value);
    const RegexMinChar = /^.{6,}$/;
    if (event.target.value === '') {
      this.setState({
        ValidateCustomerName: 'error',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        ValidateCustomerName: 'minchar',
      });
    } else {
      this.setState({
        ValidateCustomerName: null,
      });
    }
  }

  handleCustomerCode(event: any) {
    this.props.actionFieldCustomer.setCustomerCode(
      event.target.value.toUpperCase()
    );
    const RegexMinChar = /^.{2,}$/;
    const RegexHuruf = /^[a-zA-Z]*$/;
    if (event.target.value === '') {
      this.setState({
        ValidateCustomerCode: 'error',
      });
    } else if (!RegexHuruf.test(event.target.value)) {
      this.setState({
        ValidateCustomerCode: 'huruf',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        ValidateCustomerCode: 'minchar',
      });
    } else {
      this.setState({
        ValidateCustomerCode: null,
      });
    }
  }

  handleCustomerType(event: any) {
    this.props.actionFieldCustomer.setCustomerType(event);
    if (event === 'Lainnya') {
      if (
        this.props.CustomerTypeOther === null ||
        this.props.CustomerTypeOther === ''
      ) {
        this.setState({
          ValidateCustomerTypeOther: 'error',
        });
      } else {
        this.setState({
          ValidateCustomerTypeOther: null,
        });
      }
    } else {
      this.props.actionFieldCustomer.ClearDataCustomertypeother();
      this.setState({
        ValidateCustomerType: null,
        ValidateCustomerTypeOther: null,
      });
    }
  }

  handleCustomerTypeOther(event: any) {
    this.props.actionFieldCustomer.setCustomerTypeOther(event.target.value);
    const RegexMinChar = /^.{6,}$/;
    if (event.target.value === '') {
      this.setState({
        ValidateCustomerTypeOther: 'error',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        ValidateCustomerTypeOther: 'minchar',
      });
    } else {
      this.setState({
        ValidateCustomerTypeOther: null,
      });
    }
  }

  handleCustomerTelephone(event: any) {
    const number = parseInt(event.target.value || 1, 10);
    const regex = /^[0-9\b]+$/;
    const RegexMinChar = /^.{6,}$/;

    if (isNaN(number)) {
      return;
    }

    if (!('value' in this.props)) {
      this.props.actionFieldCustomer.setCustomerTelephone(
        event.target.value.replace(/^0+/, '')
      );

      if (event.target.value === '') {
        this.setState({
          ValidateCustomerTelephone: 'error',
        });
      } else if (!regex.test(event.target.value)) {
        this.setState({
          ValidateCustomerTelephone: 'NonNumeric',
        });
      } else if (!RegexMinChar.test(event.target.value)) {
        this.setState({
          ValidateCustomerTelephone: 'minchar',
        });
      } else {
        this.setState({
          ValidateCustomerTelephone: '',
        });
      }
    }
  }

  handleCustomerEmail(event: any) {
    this.props.actionFieldCustomer.setCustomerEmail(event.target.value);
    const RegexEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    if (event.target.value === '') {
      this.setState({
        ValidateCustomerEmail: 'error',
      });
    } else if (!RegexEmail.test(event.target.value)) {
      this.setState({
        ValidateCustomerEmail: 'NotEmail',
      });
    } else {
      this.setState({
        ValidateCustomerEmail: null,
      });
    }
  }

  handleCustomerAddress(event: any) {
    this.props.actionFieldCustomer.setCustomerAddress(event.target.value);
    const RegexMinChar = /^.{11,}$/;
    if (event.target.value === '') {
      this.setState({
        ValidateCustomerAddress: 'error',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        ValidateCustomerAddress: 'minchar',
      });
    } else {
      this.setState({
        ValidateCustomerAddress: null,
      });
    }
  }

  handleCustomerAccess(event: any) {
    this.props.actionFieldCustomer.setCustomerAccess(event);

    if (event === null || event.length === 0) {
      this.setState({
        ValidateCustomerAccess: 'error',
      });
    } else {
      this.setState({
        ValidateCustomerAccess: null,
      });
    }
  }

  handleBlurCustomerAccess() {
    if (
      this.props.CustomerAccess === null ||
      this.props.CustomerAccess.length === 0
    ) {
      this.setState({
        ValidateCustomerAccess: 'error',
      });
    } else {
      this.setState({
        ValidateCustomerAccess: null,
      });
    }
  }

  handleCloseModal() {
    if (this.props.EditCustomer === true) {
      this.props.actionDetailCustomer.changeDetailCustomer();
    } else {
      this.props.actionFieldCustomer.changeClearAllDataCustomer();
    }
    this.props.actionToggle.changeModal();
    this.props.actionToggle.clearJenisToggle();
    this.setState({
      ValidateCustomerName: null,
      ValidateCustomerCode: null,
      ValidateCustomerType: null,
      ValidateCustomerTypeOther: null,
      ValidateCustomerTelephone: null,
      ValidateCustomerEmail: null,
      ValidateCustomerAddress: null,
      ValidateCustomerAccess: null,
    });
  }

  handleBlurCustomerType() {
    if (this.props.CustomerType == null) {
      this.setState({
        ValidateCustomerType: 'error',
      });
    } else {
      this.setState({
        ValidateCustomerType: null,
      });
    }
  }

  handleAddCustomer() {
    this.props.actionAddCustomer.submitAddCustomer();
  }

  handleEditCustomer() {
    this.props.actionEditCustomer.submitEditCustomer();
  }

  handleModal() {
    this.props.actionToggle.changeJenisToggle('AddNewReportGrup');
    this.props.actionToggle.changeModal();
  }

  render() {
    return (
      <div>
        <FormCustomerComponent
          typeToggle={this.props.typeToggle}
          visible={this.props.visible}
          isLoading={this.props.isLoading}
          ValidateButton={
            this.props.CustomerName === null ||
            this.props.CustomerName === '' ||
            this.props.CustomerCode === null ||
            this.props.CustomerCode === '' ||
            this.state.ValidateCustomerCode === 'minchar' ||
            this.props.CustomerEmail === null ||
            this.props.CustomerEmail === '' ||
            this.state.ValidateCustomerEmail === 'NotEmail' ||
            this.props.CustomerTelephone === null ||
            this.props.CustomerTelephone === '' ||
            this.state.ValidateCustomerTelephone === 'NonNumeric' ||
            this.props.CustomerType === null ||
            this.props.CustomerType === '' ||
            this.props.CustomerAddress === null ||
            this.props.CustomerAddress === '' ||
            this.props.CustomerAccess.length === 0 ||
            this.state.ValidateCustomerAccess === 'error'
              ? true
              : false
          }
          CustomerName={this.props.CustomerName}
          CustomerCode={this.props.CustomerCode}
          CustomerType={this.props.CustomerType}
          CustomerTelephone={this.props.CustomerTelephone}
          CustomerEmail={this.props.CustomerEmail}
          CustomerTypeOther={this.props.CustomerTypeOther}
          CustomerAddress={this.props.CustomerAddress}
          CustomerAccess={this.props.CustomerAccess}
          ValidateCustomerName={
            this.state.ValidateCustomerName === 'error'
              ? 'error'
              : '' || this.state.ValidateCustomerName === 'minchar'
              ? 'error'
              : ''
          }
          ValidateCustomerCode={
            this.state.ValidateCustomerCode === 'error' ||
            this.state.ValidateCustomerCode === 'minchar' ||
            this.state.ValidateCustomerCode === 'huruf'
              ? 'error'
              : ''
          }
          ValidateCustomerType={
            this.state.ValidateCustomerType === 'error' ? 'error' : ''
          }
          ValidateCustomerTelephone={
            this.state.ValidateCustomerTelephone === 'error'
              ? 'error'
              : '' || this.state.ValidateCustomerTelephone === 'NonNumeric'
              ? 'error'
              : '' || this.state.ValidateCustomerTelephone === 'minchar'
              ? 'error'
              : ''
          }
          ValidateCustomerEmail={
            this.state.ValidateCustomerEmail === 'error'
              ? 'error'
              : '' || this.state.ValidateCustomerEmail === 'NotEmail'
              ? 'error'
              : ''
          }
          ValidateCustomerTypeOther={
            this.state.ValidateCustomerTypeOther === 'error'
              ? 'error'
              : '' || this.state.ValidateCustomerTypeOther === 'minchar'
              ? 'error'
              : ''
          }
          ValidateCustomerAddress={
            this.state.ValidateCustomerAddress === 'error'
              ? 'error'
              : '' || this.state.ValidateCustomerAddress === 'minchar'
              ? 'error'
              : ''
          }
          ValidateCustomerAccess={
            this.state.ValidateCustomerAccess === 'error' ? 'error' : ''
          }
          helpValidateCustomerName={
            this.state.ValidateCustomerName === 'error'
              ? 'Nama wajib diisi'
              : '' || this.state.ValidateCustomerName === 'minchar'
              ? 'Nama harus lebih dari 5 karakter'
              : ''
          }
          helpValidateCustomerCode={
            this.state.ValidateCustomerCode === 'error'
              ? 'Kode wajib diisi'
              : '' || this.state.ValidateCustomerCode === 'minchar'
              ? 'Kode harus 2 karakter'
              : '' || this.state.ValidateCustomerCode === 'huruf'
              ? 'Tidak boleh selain huruf'
              : ''
          }
          helpValidateCustomerType={
            this.state.ValidateCustomerType === 'error'
              ? 'Jenis pelanggan wajib diisi'
              : ''
          }
          helpValidateCustomerTelephone={
            this.state.ValidateCustomerTelephone === 'error'
              ? 'No telpon wajib diisi'
              : '' || this.state.ValidateCustomerTelephone === 'NonNumeric'
              ? 'Tidak boleh selain angka'
              : '' || this.state.ValidateCustomerTelephone === 'minchar'
              ? 'No telpon harus lebih dari 5 karakter'
              : ''
          }
          helpValidateCustomerEmail={
            this.state.ValidateCustomerEmail === 'error'
              ? 'Email wajib diisi'
              : '' || this.state.ValidateCustomerEmail === 'NotEmail'
              ? 'Email tidak valid'
              : ''
          }
          helpValidateCustomerTypeOther={
            this.state.ValidateCustomerTypeOther === 'error'
              ? 'Keterangan wajib diisi'
              : '' || this.state.ValidateCustomerTypeOther === 'minchar'
              ? 'Keterangan harus lebih dari 5 karakter'
              : ''
          }
          helpValidateCustomerAddress={
            this.state.ValidateCustomerAddress === 'error'
              ? 'Alamat wajib diisi'
              : '' || this.state.ValidateCustomerAddress === 'minchar'
              ? 'Alamat harus lebih dari 10 karakter'
              : ''
          }
          helpValidateCustomerAccess={
            this.state.ValidateCustomerAccess === 'error'
              ? 'Akses portal pelanggan wajib diisi'
              : ''
          }
          handleCloseModal={this.handleCloseModal}
          handleCustomerName={this.handleCustomerName}
          handleCustomerCode={this.handleCustomerCode}
          handleCustomerType={this.handleCustomerType}
          handleCustomerTelephone={this.handleCustomerTelephone}
          handleCustomerEmail={this.handleCustomerEmail}
          handleCustomerTypeOther={this.handleCustomerTypeOther}
          handleCustomerAddress={this.handleCustomerAddress}
          handleCustomerAccess={this.handleCustomerAccess}
          handleBlurCustomerType={this.handleBlurCustomerType}
          handleBlurCustomerAccess={this.handleBlurCustomerAccess}
          handleAddCustomer={this.handleAddCustomer}
          handleEditCustomer={this.handleEditCustomer}
          handleModal={this.handleModal}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  typeToggle: state.Toggle.jenisToggle,
  isLoading: state.Toggle.isLoading,
  EditCustomer: state.Customer.PropertiesCustomer.EditCustomer,
  CustomerName: state.Customer.DetailCustomer.CustomerName,
  CustomerCode: state.Customer.DetailCustomer.CustomerCode,
  CustomerType: state.Customer.DetailCustomer.CustomerType,
  CustomerTelephone: state.Customer.DetailCustomer.CustomerTelephone,
  CustomerEmail: state.Customer.DetailCustomer.CustomerEmail,
  CustomerTypeOther: state.Customer.DetailCustomer.CustomerTypeOther,
  CustomerAddress: state.Customer.DetailCustomer.CustomerAddress,
  CustomerAccess: state.Customer.DetailCustomer.CustomerAccess,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionFieldCustomer: bindActionCreators(ActionFieldCustomer, dispatch),
  actionAddCustomer: bindActionCreators(ActionAddCustomer, dispatch),
  actionEditCustomer: bindActionCreators(ActionEditCustomer, dispatch),
  actionDetailCustomer: bindActionCreators(ActionDetailCustomer, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FormCustomerContainer);
