import { Action } from 'redux';

const initialState: any = {
  sidebarIsColapse: false,
  isLoading: false,
  pagePagination: 0,
  totalPagePagination: 1,
  sizeDataPagination: 10,
  countingNotif:0,
  showModalResultUpload: false,
  showModalNotification: false,
  showModalAssignCourier: false,
  showModalUploadForAssignCourier: false,
  showModalComplaint: false,
  showModalComplaintEdit: false,
  showModalAssignCourierComplaint: false,
  showModalChooseBarcodeManifest: false,
};

export interface ILoadingAction extends Action {
  isLoading?: boolean;
}
export interface ISetPagePaginationAction extends Action {
  pagePagination: any;
}
export interface ISetTotalPagePaginationAction extends Action {
  totalPagePagination: any;
}
export interface ISetSizePagePaginationAction extends Action {
  sizeDataPagination: any;
}
export interface ISetCountingNotifAction extends Action {
  countingNotif: any;
}
export interface IOpenModalAction extends Action {
  component: string;
}

export default function ComponentReducer(state = initialState, action: Action) {
  if (!action) {
    return state;
  }
  const newState = Object.assign({}, state);

  switch (action.type) {
    case 'OPEN_SIDEBAR':
      newState.sidebarIsColapse = !state.sidebarIsColapse;
      return { ...newState };
      
    case 'PROCESS_LOADING': {
      const loadingAction = action as ILoadingAction;
      newState.isLoading = loadingAction.isLoading;
      return { ...newState };
    }

    case 'SET_PAGE_PAGINATION_COMPONENT':
      const setPagePagination = action as ISetPagePaginationAction;
      newState.pagePagination = setPagePagination.pagePagination;
      return { ...newState };

    case 'SET_TOTAL_PAGE_PAGINATION_COMPONENT':
      const setTotalPagePagination = action as ISetTotalPagePaginationAction;
      newState.totalPagePagination = setTotalPagePagination.totalPagePagination;
      return { ...newState };

    case 'SET_SIZE_DATA_PAGINATION_COMPONENT':
      const setSizeDataPagination = action as ISetSizePagePaginationAction;
      newState.sizeDataPagination = setSizeDataPagination.sizeDataPagination;
      return { ...newState };
    
    case 'SET_COUNTING_NOTIF_COMPONENT':
      const setCountingNotig = action as ISetCountingNotifAction;
      newState.countingNotif = setCountingNotig.countingNotif;
      return { ...newState };

    case 'CLEAR_ALL_PAGINATION_COMPONENT':
      newState.pagePagination = 0;
      newState.totalPagePagination = 1;
      newState.sizeDataPagination = 10;
      return { ...newState };

    case 'SHOW_MODAL': {
      const openModalAction = action as IOpenModalAction;
      const component = openModalAction.component;
      newState[`showModal${component}`] = !state[`showModal${component}`];
      return { ...newState };
    }
  }
  return state;
}
