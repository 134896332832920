import * as ActionDownloadInvoice from 'core/action/Report/InvoiceReport/ActionDownloadInvoice';
import * as ActionInvoice from 'core/action/Report/InvoiceReport/ActionSetFormInvoice';
import * as ActionListCustomer from 'core/action/Customer/ActionListCustomer';
import * as ActionListCustomerProduct from 'core/action/CustomerProduct/ActionListCustomerProduct';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import InvoiceComponent from 'module/Report/Component/Invoice/InvoiceComponent';
import { connect } from 'react-redux';
import moment from 'moment';

interface IProps {
  actionInvoice: any;
  actionListCustomer: any;
  actionListCustomerProduct: any;
  actionDownloadInvoice: any;
  isLoading: any;
  CheckboxIsMonth: any;
  RadiobuttonIsDetailInvoice: any;
  CycleDateStart: any;
  Customer: any;
  CustomerProduct: any;
  listCustomer: any;
  listCustomerProduct: any;
  LoadingSelectCustomer: any;
  LoadingTypeSelectCustomer: any;
  LoadingSelectCustomerProduct: any;
  LoadingTypeSelectCustomerProduct: any;
}

function InvoiceContainer({
  actionInvoice,
  actionListCustomer,
  actionListCustomerProduct,
  actionDownloadInvoice,
  isLoading,
  CheckboxIsMonth,
  RadiobuttonIsDetailInvoice,
  CycleDateStart,
  Customer,
  CustomerProduct,
  listCustomer,
  listCustomerProduct,
  LoadingSelectCustomer,
  LoadingTypeSelectCustomer,
  LoadingSelectCustomerProduct,
  LoadingTypeSelectCustomerProduct,
}: IProps) {
  const [ValueCycleStartDate, setValueCycleStartDate]: any = useState(null);
  const [ValidateCycleDateStart, setValidateCycleDateStart]: any =
    useState(null);
  const [ValidateConditionCycleStart, setValidateConditionCycleStart]: any =
    useState(null);
  const [ValidateConditionCycleFinish, setValidateConditionCycleFinish]: any =
    useState(null);
  const [ValidateCustomer, setValidateCustomer]: any = useState(null);
  const [ValidateCustomerProduct, setValidateCustomerProduct]: any =
    useState(null);

  console.log(ValidateConditionCycleFinish);

  function handleDownloadInvoice() {
    actionDownloadInvoice.downloadInvoice();
  }
  function handleResetAll() {
    actionInvoice.clearAllFormInvoice();
    actionListCustomer.clearListCustomer();
    actionListCustomerProduct.clearListCustomerProduct();
    setValueCycleStartDate(null);
    setValidateCycleDateStart(null);
    setValidateConditionCycleStart(null);
    setValidateConditionCycleFinish(null);
    setValidateCustomer(null);
    setValidateCustomerProduct(null);
  }
  function handleResetCustomer() {
    actionListCustomerProduct.clearListCustomerProduct();
    actionInvoice.clearCustomerProductId();
  }
  function handleGetCustomer() {
    actionListCustomer.getListCustomer();
  }
  function handleGetCustomerProduct() {
    actionListCustomerProduct.getListCustomerProductNoType();
  }
  function handlePutCycleStart(event: any) {
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      actionInvoice.setCycleDateStart(dateValue);
    } else {
      actionInvoice.setCycleDateStart(null);
    }
    setValueCycleStartDate(event);

    if (event === '' || event === null) {
      setValidateCycleDateStart('error');
    } else {
      setValidateCycleDateStart(null);
    }
  }

  function handleValidateCycleStart(event: any) {
    setValidateConditionCycleStart(event);
    if (ValidateConditionCycleStart === true) {
      if (
        CycleDateStart === null ||
        CycleDateStart === undefined ||
        CycleDateStart === 'Invalid date'
      ) {
        setValidateCycleDateStart('error');
      } else {
        setValidateCycleDateStart(null);
      }
    } else {
    }
  }
  function handlePutCheckbox(event) {
    actionInvoice.setChecboxIsMonth(event.target.checked);
  }
  function handlePutRadioButtonIsDetail(event) {
    actionInvoice.setRadiobuttonIsDetailInvoice(event.target.value);
  }
  function handlePutCustomer(event: any) {
    actionInvoice.setCustomerId(event);
    if (event === null) {
      setValidateCustomer('error');
    } else {
      setValidateCustomer(null);
    }
  }
  function handleBlurCustomer() {
    if (Customer === null) {
      setValidateCustomer('error');
    } else {
      setValidateCustomer(null);
    }
  }
  function handleBlurCustomerProduct() {
    if (CustomerProduct.length === undefined || CustomerProduct.length < 0) {
      setValidateCustomerProduct('error');
    } else {
      setValidateCustomerProduct(null);
    }
  }
  function handlePutCustomerProduct(event: any) {
    const searchSelectAll = event.toString().search('SetAllProduct');
    if (searchSelectAll >= 0) {
      actionInvoice.setCustomerProductId(
        listCustomerProduct.map((data: any, index: any) => data.id)
      );
    } else {
      actionInvoice.setCustomerProductId(event);
    }

    if (
      CustomerProduct === null ||
      CustomerProduct === [] ||
      event.length === 0
    ) {
      setValidateCustomerProduct('error');
    } else {
      setValidateCustomerProduct(null);
    }
  }
  return (
    <div>
      <InvoiceComponent
        isLoading={isLoading}
        CheckboxIsMonth={CheckboxIsMonth}
        RadiobuttonIsDetailInvoice={RadiobuttonIsDetailInvoice}
        ValueCycleStartDate={ValueCycleStartDate}
        CycleDateStart={CycleDateStart}
        Customer={Customer}
        CustomerProduct={CustomerProduct}
        listCustomer={listCustomer}
        listCustomerProduct={listCustomerProduct}
        LoadingSelectCustomer={LoadingSelectCustomer}
        LoadingTypeSelectCustomer={LoadingTypeSelectCustomer}
        LoadingSelectCustomerProduct={LoadingSelectCustomerProduct}
        LoadingTypeSelectCustomerProduct={LoadingTypeSelectCustomerProduct}
        ValidateCycleDateStart={ValidateCycleDateStart}
        ValidateCustomer={ValidateCustomer}
        ValidateCustomerProduct={ValidateCustomerProduct}
        handlePutCycleStart={handlePutCycleStart}
        handlePutCustomer={handlePutCustomer}
        handlePutCustomerProduct={handlePutCustomerProduct}
        handleValidateCycleStart={handleValidateCycleStart}
        handleGetCustomer={handleGetCustomer}
        handleGetCustomerProduct={handleGetCustomerProduct}
        handleBlurCustomer={handleBlurCustomer}
        handleBlurCustomerProduct={handleBlurCustomerProduct}
        handleResetCustomer={handleResetCustomer}
        handleResetAll={handleResetAll}
        handleDownloadInvoice={handleDownloadInvoice}
        handlePutCheckbox={handlePutCheckbox}
        handlePutRadioButtonIsDetail={handlePutRadioButtonIsDetail}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  isLoading: state.Toggle.isLoading,
  CycleDateStart: state.Report.InvoiceReport.CycleDateStart,
  CheckboxIsMonth: state.Report.InvoiceReport.CheckboxIsMonth,
  RadiobuttonIsDetailInvoice:
    state.Report.InvoiceReport.RadiobuttonIsDetailInvoice,
  Customer: state.Report.InvoiceReport.CustomerId,
  CustomerProduct: state.Report.InvoiceReport.CustomerProductId,
  listCustomer: state.Customer.ListCustomer,
  listCustomerProduct: state.CustomerProduct.ListCustomerProduct,
  LoadingSelectCustomer: state.Customer.PropertiesCustomer.LoadingCustomer,
  LoadingTypeSelectCustomer:
    state.Customer.PropertiesCustomer.TypeLoadingCustomer,
  LoadingSelectCustomerProduct:
    state.CustomerProduct.PropertiesCustomerProduct.LoadingCustomerProduct,
  LoadingTypeSelectCustomerProduct:
    state.CustomerProduct.PropertiesCustomerProduct.TypeLoadingCustomerProduct,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionInvoice: bindActionCreators(ActionInvoice, dispatch),
  actionDownloadInvoice: bindActionCreators(ActionDownloadInvoice, dispatch),
  actionListCustomer: bindActionCreators(ActionListCustomer, dispatch),
  actionListCustomerProduct: bindActionCreators(
    ActionListCustomerProduct,
    dispatch
  ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(InvoiceContainer);
