import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as formAdmin from '../../../action/ManageAccount/AdminBranch/ActionFormDetailAdminBranch';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_formDetailAdminBranch(action: any) {
  const state = yield select();
  const IdAdmin = state.AdminBranch.PropertiesAdminBranch.idAdmin;
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Profile/Branch/' + IdAdmin,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(formAdmin.changeAdminCode(data.branchName));
    yield put(formAdmin.changeAdminAddress(data.address));
    yield put(formAdmin.changeAdminStatus(data.isActive));
    yield put(formAdmin.changeAdminRolePermission(data.rolePermission));
    yield put(formAdmin.changeAdminLastName(data.lastName));
    yield put(formAdmin.changeAdminPhoto(data.photo));
    yield put(formAdmin.changeAdminEmail(data.email));
    yield put(formAdmin.changeAdminPhoneNumber(data.phone));
    yield put(formAdmin.changeAdminDob(data.dob));
    yield put(formAdmin.changeAdminName(data.firstName));
    yield put(formAdmin.changeAdminAccountType(data.accountType));
    yield put(formAdmin.changeAdminUsername(data.code));
    // yield put(toggle.changeJenisToggle('DetailAdmin'))
    yield put(toggle.changeModal());
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}

export function* SM_detailAdminBranch() {
  yield takeLatest('CHANGE_ADMIN_DETAIL', S_formDetailAdminBranch);
}
