import {
  Button,
  Checkbox,
  Col,
  Form,
  Icon,
  Input,
  Radio,
  Row,
  Select,
  Tooltip,
} from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../materials/CardMaterial';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';

const { Option } = Select;

interface IProps {
  BranchId: any;
  CourierId: any;
  FileData: any;
  FormatDate: any;
  FileName: any;
  BranchList: any;
  CheckBox: any;
  TypeUpload: any;
  validateBranch: any;
  validateCourier: any;
  validateFormatDate: any;
  helpValidateBranch: any;
  helpValidateCourier: any;
  helpValidateFormatDate: any;
  handleChangeTypeUpload: any;
  handleGetBranch: any;
  handlePutBranch: any;
  handlePutFormatDate: any;
  handleBlurBranch: any;
  handleResetCourier: any;
  handleGetCourier: any;
  handleSelectCourier: any;
  handleBlurSelectKurir: any;
  loadingSelectBranch: any;
  loadingSelectCourier: any;
  handleConvert: any;
  handleSave: any;
  handleCheckBox: any;
  handleDownloadSample: any;
  ListCourier: any;
  isLoading: any;
  isChangeCourier: any;
  handleCheckBoxCourier: any;
}

export default class ManualProcessVendorComponent extends Component<IProps> {
  render() {
    const validateButtonSave =
      (this.props.isChangeCourier &&
        (this.props.BranchId === null || this.props.CourierId === null)) ||
      this.props.FileData === null ||
      this.props.FormatDate === null ||
      this.props.FormatDate === ''
        ? true
        : false;
    return (
      <div style={{ paddingBottom: '35px' }}>
        <SpinMaterial
          spinning={this.props.isLoading}
          style={{}}
          size={'large'}
          content={
            <React.Fragment>
              <CardMaterial
                extra={''}
                title={
                  <b className="titleCard">PROSES MANUAL - UNGGAH SELESAI</b>
                }
                style={{ borderRadius: '10px' }}
                content={
                  <React.Fragment>
                    <Radio.Group
                      onChange={this.props.handleChangeTypeUpload}
                      value={this.props.TypeUpload}
                      style={{ padding: '10px' }}
                    >
                      <Radio style={{ marginRight: 40 }} value={'Internal'}>
                        Internal
                      </Radio>
                      <Radio value={'Vendor'}>Vendor</Radio>
                    </Radio.Group>
                  </React.Fragment>
                }
              />
              <CardMaterial
                extra={''}
                title={null}
                style={{ borderRadius: '10px', marginTop: '25px' }}
                content={
                  <div>
                    <Row>
                      <Col span={12} style={{ paddingRight: '20px' }}>
                        <div>
                          <span>
                            <b>
                              {this.props.TypeUpload === 'Internal'
                                ? 'Posko'
                                : 'Rekanan'}
                            </b>
                          </span>
                          <Form.Item
                            validateStatus={this.props.validateBranch}
                            help={this.props.helpValidateBranch}
                          >
                            <Select
                              loading={this.props.loadingSelectBranch}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              disabled={!this.props.isChangeCourier}
                              showSearch
                              onSelect={this.props.handleResetCourier}
                              onChange={this.props.handlePutBranch}
                              onBlur={this.props.handleBlurBranch}
                              onFocus={this.props.handleGetBranch}
                              value={
                                this.props.BranchId === null ? (
                                  <span style={{ color: '#969696' }}>
                                    {this.props.TypeUpload === 'Internal'
                                      ? 'Pilih Posko'
                                      : 'Pilih Rekanan'}
                                  </span>
                                ) : (
                                  this.props.BranchId
                                )
                              }
                            >
                              {this.props.BranchList.map(
                                (data: any, index: any) => (
                                  <Option key={index} value={data.id}>
                                    {data.name}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                        <div>
                          <span>
                            <b>Kurir</b>
                          </span>
                          <Form.Item
                            validateStatus={this.props.validateCourier}
                            help={this.props.helpValidateCourier}
                            style={{ marginBottom: 8 }}
                          >
                            <Select
                              loading={this.props.loadingSelectCourier}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              disabled={
                                this.props.BranchId === null ? true : false
                              }
                              showSearch
                              onChange={this.props.handleSelectCourier}
                              onBlur={this.props.handleBlurSelectKurir}
                              onFocus={this.props.handleGetCourier}
                              value={
                                this.props.CourierId === null ? (
                                  <span style={{ color: '#969696' }}>
                                    Pilih Kurir
                                  </span>
                                ) : (
                                  this.props.CourierId
                                )
                              }
                            >
                              {this.props.ListCourier.map(
                                (data: any, index: any) => (
                                  <Option
                                    key={index}
                                    value={data.id}
                                    label={data.code}
                                  >
                                    {data.firstName}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                          <Checkbox
                            style={{
                              paddingLeft: 5,
                              marginBottom: 20,
                            }}
                            checked={this.props.isChangeCourier}
                            onChange={this.props.handleCheckBoxCourier}
                          />
                          <span className={'checkboxSpanSeparatorAddress'}>
                            Ubah Kurir
                          </span>
                        </div>
                      </Col>
                      <Col span={12} style={{ paddingLeft: '20px' }}>
                        <div>
                          <span>
                            <b style={{ paddingRight: '8px' }}>
                              Format Tanggal
                            </b>
                            <Tooltip
                              title={
                                <div>
                                  <div>
                                    <span>Contoh :</span>
                                  </div>
                                  <div>
                                    <span>dd/MM/yyyy</span>
                                  </div>
                                  <div>
                                    <span>MM/dd/yyyy</span>
                                  </div>
                                </div>
                              }
                            >
                              <Icon type="question-circle-o" />
                            </Tooltip>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.FormatDate}
                            disabled={false}
                            placeholder={'Contoh : dd/MM/yyyy'}
                            maxLength={30}
                            style={{ borderRadius: '10px' }}
                            onChange={this.props.handlePutFormatDate}
                            onBlur={this.props.handlePutFormatDate}
                            validatestatus={this.props.validateFormatDate}
                            help={this.props.helpValidateFormatDate}
                          />
                        </div>
                        <div
                          className="fileUploadInput"
                          style={{ marginTop: 3 }}
                        >
                          <span>
                            <b>
                              File{' '}
                              {this.props.CheckBox === true ? 'ZIP' : 'CSV'}
                            </b>
                          </span>
                          <Row style={{ marginBottom: 8 }}>
                            <Col span={18}>
                              <Input
                                onChange={this.props.handleConvert}
                                value={this.props.FileName}
                                suffix={<Icon type="upload" />}
                                multiple={false}
                                type="file"
                                accept={
                                  this.props.CheckBox === true ? '.zip' : '.csv'
                                }
                              />
                            </Col>
                            <Col span={6}>
                              <Tooltip
                                placement="top"
                                title={'Ceklis untuk file zip'}
                              >
                                <Checkbox
                                  style={{
                                    paddingTop: 5,
                                    paddingLeft: '24px',
                                  }}
                                  checked={this.props.CheckBox}
                                  onChange={this.props.handleCheckBox}
                                />
                              </Tooltip>
                              <span
                                style={{
                                  paddingLeft: '18px',
                                  fontStyle: 'italic',
                                  color: '#969696',
                                }}
                              >
                                ZIP
                              </span>
                            </Col>
                          </Row>
                          <Button
                            onClick={this.props.handleDownloadSample}
                            type="link"
                            style={{
                              float: 'left',
                              marginLeft: '-10px',
                            }}
                          >
                            <u style={{ fontStyle: 'italic' }}>
                              Download sample file
                            </u>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div style={{ marginLeft: 20, float: 'right' }}>
                        <ButtonMaterial
                          disabled={validateButtonSave}
                          handleSubmit={this.props.handleSave}
                          teksButton={<b>Simpan</b>}
                          size={'middle'}
                          shape={'round'}
                          type={'primary'}
                          style={
                            validateButtonSave === true
                              ? {
                                  float: 'right',
                                  height: '35px',
                                  width: '100px',
                                }
                              : {
                                  float: 'right',
                                  colorFont: '#11BEFF',
                                  height: '35px',
                                  width: '100px',
                                }
                          }
                        />
                      </div>
                    </Row>
                  </div>
                }
              />
            </React.Fragment>
          }
        />
      </div>
    );
  }
}
