import * as ActionListCustomer from '../../../../core/action/Customer/ActionListCustomer';
import * as ActionToggle from '../../../../core/action/toggle';
import * as ActionUpdateAddress from '../../../../core/action/ManualProcess/ActionUpdateAddress';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import UpdateAddressComponent from '../../../../components/Content/ManualProcess/UpdateAddress/UpdateAddressComponent';
import { connect } from 'react-redux';

interface IProps {
  getWaybill: any;
  getAddress: any;
  getName: any;
  getPhoneNumber: any;
  getRadioButton: any;
  getCustomer: any;
  isLoading: any;
  actionUpdateAddress: any;
  actionListCustomer: any;
  listCustomer: any;
  listCustomerProduct: any;
  listTemplateUpload: any;
  getCustomerProduct: any;
  getTemplateUpload: any;
  getCycleDate: any;
  actionToggle: any;
  visible: any;
  jenisToggle: any;
  resultUpload: any;
}
interface IState {
  validateWaybill: any;
  validateCustomer: any;
  validateBtnSubmit: any;
  validateCustomerProduct: any;
  validateTemplateUpload: any;
  validateCycleDate: any;
  formatFile: any;
  fileName: any;
  file: any;
  base64URL: any;
}
class UpdateAddressContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      validateWaybill: null,
      validateCustomer: null,
      validateCustomerProduct: null,
      validateTemplateUpload: null,
      formatFile: null,
      fileName: '',
      file: null,
      base64URL: null,
      validateBtnSubmit: true,
      validateCycleDate: null,
    };
    this.handleReset = this.handleReset.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeRadioButton = this.handleChangeRadioButton.bind(this);
    this.handleChangeWaybill = this.handleChangeWaybill.bind(this);
    this.handleBlurWaybill = this.handleBlurWaybill.bind(this);
    this.handlePutAddress = this.handlePutAddress.bind(this);
    this.handleGetCustomer = this.handleGetCustomer.bind(this);
    this.handlePutCustomer = this.handlePutCustomer.bind(this);
    this.handlePutCustomerProduct = this.handlePutCustomerProduct.bind(this);
    this.handleGetTemplateUpload = this.handleGetTemplateUpload.bind(this);
    this.handlePutTemplateUpload = this.handlePutTemplateUpload.bind(this);
    this.handleClearCustomerProduct =
      this.handleClearCustomerProduct.bind(this);
    this.handleGetCustomerProduct = this.handleGetCustomerProduct.bind(this);
    this.handlePutCycleDate = this.handlePutCycleDate.bind(this);
    this.handleConvert = this.handleConvert.bind(this);
    this.handleSubmitUpload = this.handleSubmitUpload.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleDownloadResult = this.handleDownloadResult.bind(this);
    this.handleBlurCustomer = this.handleBlurCustomer.bind(this);
    this.handleBlurCustomerProduct = this.handleBlurCustomerProduct.bind(this);
    this.handleBlurTemplateUpload = this.handleBlurTemplateUpload.bind(this);
    this.handleBlurCycleDate = this.handleBlurCycleDate.bind(this);
  }

  handleReset() {
    this.props.actionUpdateAddress.clearAllForm();
  }
  handleChangeRadioButton(event: any) {
    this.props.actionUpdateAddress.changeRadioButton(event.target.value);
  }

  handleSearch() {
    this.props.actionUpdateAddress.searchWaybill();
  }
  handleGetTemplateUpload() {
    this.props.actionUpdateAddress.getTemplateUpload();
  }
  handlePutTemplateUpload(event: any, label: any) {
    const lengthFormatFile = label.props.children.length;
    let result = label.props.children.substring(
      lengthFormatFile - 2,
      lengthFormatFile
    );
    if (result === 'x)') {
      this.setState({
        formatFile: '.xlsx',
      });
    } else if (result === 't)') {
      this.setState({
        formatFile: '.txt',
      });
    } else if (result === 's)') {
      this.setState({
        formatFile: '.xls',
      });
    } else {
      this.setState({
        formatFile: '.csv',
      });
    }
    this.props.actionUpdateAddress.changeTemplateUpload(event);
    if (event === null) {
      this.setState({
        validateTemplateUpload: 'error',
      });
    } else {
      this.setState({
        validateTemplateUpload: null,
      });
    }
  }
  handleGetCustomer() {
    this.props.actionListCustomer.getListCustomer();
  }
  handleGetCustomerProduct() {
    this.props.actionUpdateAddress.getListCustomerProduct();
  }
  handlePutCustomer(event: any) {
    this.props.actionUpdateAddress.changeCustomer(event);
    if (event === null) {
      this.setState({
        validateCustomer: 'error',
      });
    } else {
      this.setState({
        validateCustomer: null,
      });
    }
  }
  handleBlurCustomer() {
    if (!this.props.getCustomer) {
      this.setState({
        validateCustomer: 'error',
      });
    } else {
      this.setState({
        validateCustomer: null,
      });
    }
  }
  handlePutCustomerProduct(event: any) {
    this.props.actionUpdateAddress.changeCustomerProduct(event);
    if (event === null) {
      this.setState({
        validateCustomerProduct: 'error',
      });
    } else {
      this.setState({
        validateCustomerProduct: null,
      });
    }
  }
  handleBlurCustomerProduct() {
    if (!this.props.getCustomerProduct) {
      this.setState({
        validateCustomerProduct: 'error',
      });
    } else {
      this.setState({
        validateCustomerProduct: null,
      });
    }
  }
  handleBlurTemplateUpload() {
    if (!this.props.getTemplateUpload) {
      this.setState({
        validateTemplateUpload: 'error',
      });
    } else {
      this.setState({
        validateTemplateUpload: null,
      });
    }
  }
  handleChangeWaybill(event: any) {
    this.props.actionUpdateAddress.changeWaybillSearch(event.target.value);
    const RegexMinChar = /(?=^.{16,}$)/;
    if (event.target.value === null || event.target.value === '') {
      this.setState({
        validateWaybill: 'error',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        validateWaybill: 'minchar',
      });
    } else {
      this.setState({
        validateWaybill: '',
      });
    }
  }

  handleBlurWaybill() {
    if (this.props.getWaybill === null || this.props.getWaybill === '') {
      this.setState({
        validateWaybill: 'error',
      });
    } else {
      this.setState({
        validateWaybill: null,
      });
    }
  }
  handlePutCycleDate(event) {
    this.props.actionUpdateAddress.changeCycleDateUpload(event);
    if (!event) {
      this.setState({
        validateCycleDate: 'error',
      });
    } else {
      this.setState({
        validateCycleDate: '',
      });
    }
  }
  handleBlurCycleDate(event) {
    this.setState({
      validateCycleDate: event,
    });
    if (this.state.validateCycleDate === true) {
      if (
        this.props.getCycleDate === null ||
        this.props.getCycleDate === undefined ||
        this.props.getCycleDate === 'Invalid date'
      ) {
        this.setState({
          validateCycleDate: 'error',
        });
      } else {
        this.setState({
          validateCycleDate: '',
        });
      }
    }
  }
  handleClearCustomerProduct() {
    this.props.actionUpdateAddress.changeCustomerProduct(null);
    this.props.actionUpdateAddress.changeTemplateUpload(null);
  }
  handlePutAddress(event: any, data: any) {
    const valueAddress = event.target.value;
    const convAddress =
      this.props.getAddress === null
        ? []
        : Object.keys(this.props.getAddress)[0];
    let vAddress =
      this.props.getAddress !== null
        ? this.props.getAddress[`${convAddress}`]
        : '';
    let validateBtnSbmt = vAddress.length !== 0 ? false : true;
    if (valueAddress.startsWith(' ') === true) {
      this.props.getAddress[data] = valueAddress.trim();
    } else {
      this.props.getAddress[data] = valueAddress;
    }
    this.props.actionUpdateAddress.changeAddress(this.props.getAddress);
    this.setState({
      validateBtnSubmit: validateBtnSbmt,
    });
  }
  handleConvert(e: any) {
    this.setState({
      fileName: e.target.value,
    });
    let { file } = this.state;
    file = e.target.files[0];
    this.getBase64(file)
      .then((result: any) => {
        file['base64'] = result.toString().split(',')[1];
        this.props.actionUpdateAddress.changeFileUpload(result.split(',')[1]);
        this.setState({
          base64URL: result.split(',')[1],
          file,
        });
      })
      .catch(err => {
        console.log(err);
      });

    this.setState({
      file: e.target.files[0],
    });
    this.props.actionUpdateAddress.changeFileName(file.name);
  }

  getBase64 = (file: any) => {
    return new Promise(resolve => {
      let baseURL;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  handleSubmit() {
    this.props.actionUpdateAddress.submitUpdateAddress();
  }
  async handleSubmitUpload() {
    await this.props.actionUpdateAddress.submitUpdateAddressUpload();this.setState({
        fileName: null,
      });
  }
  handleDownloadResult() {
    this.props.actionUpdateAddress.downloadResult();
  }
  handleCloseModal() {
    this.props.actionUpdateAddress.fetchResultUpload(null);
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle(null);
  }
  render() {
    return (
      <React.Fragment>
        <UpdateAddressComponent
          handleDownloadResult={this.handleDownloadResult}
          handleGetCustomerProduct={this.handleGetCustomerProduct}
          handleChangeWaybill={this.handleChangeWaybill}
          handleBlurWaybill={this.handleBlurWaybill}
          getWaybill={this.props.getWaybill}
          validateBtnSubmit={this.state.validateBtnSubmit}
          validateWaybill={
            this.state.validateWaybill === 'error'
              ? 'error'
              : '' || this.state.validateWaybill === 'minchar'
              ? 'error'
              : ''
          }
          helpWaybill={
            this.state.validateWaybill === 'error'
              ? 'Waybill wajib diisi'
              : '' || this.state.validateWaybill === 'minchar'
              ? 'Waybill harus lebih dari 15 karakter'
              : ''
          }
          validateCustomer={
            this.state.validateCustomer === 'error' ? 'error' : ''
          }
          helpCustomer={
            this.state.validateCustomer === 'error'
              ? 'Pelanggan wajib diisi'
              : ''
          }
          handleBlurCustomer={this.handleBlurCustomer}
          validateCustomerProduct={
            this.state.validateCustomerProduct === 'error' ? 'error' : ''
          }
          helpCustomerProduct={
            this.state.validateCustomerProduct === 'error'
              ? 'Produk wajib diisi'
              : ''
          }
          handleBlurCustomerProduct={this.handleBlurCustomerProduct}
          handlePutCustomerProduct={this.handlePutCustomerProduct}
          handleReset={this.handleReset}
          handleSearch={this.handleSearch}
          isLoading={this.props.isLoading}
          getName={this.props.getName}
          getPhoneNumber={this.props.getPhoneNumber}
          handlePutAddress={this.handlePutAddress}
          getAddress={this.props.getAddress}
          handleSubmit={this.handleSubmit}
          validateBtnReset={this.props.getAddress === null ? true : false}
          handleChangeRadioButton={this.handleChangeRadioButton}
          getRadioButton={this.props.getRadioButton}
          handleGetCustomer={this.handleGetCustomer}
          handlePutCustomer={this.handlePutCustomer}
          validateForm={this.props.getCustomer === null ? true : false}
          getCustomer={this.props.getCustomer}
          getCustomerProduct={this.props.getCustomerProduct}
          listCustomer={this.props.listCustomer}
          listCustomerProduct={this.props.listCustomerProduct}
          handleClearCustomerProduct={this.handleClearCustomerProduct}
          handleGetTemplateUpload={this.handleGetTemplateUpload}
          handlePutTemplateUpload={this.handlePutTemplateUpload}
          listTemplateUpload={this.props.listTemplateUpload}
          getTemplateUpload={this.props.getTemplateUpload}
          handlePutCycleDate={this.handlePutCycleDate}
          getCycleDate={this.props.getCycleDate}
          formatFile={this.state.formatFile}
          handleConvert={this.handleConvert}
          fileName={this.state.fileName}
          handleSubmitUpload={this.handleSubmitUpload}
          handleCloseModal={this.handleCloseModal}
          visible={this.props.visible}
          jenisToggle={this.props.jenisToggle}
          resultUpload={this.props.resultUpload}
          handleBlurTemplateUpload={this.handleBlurTemplateUpload}
          handleBlurCycleDate={this.handleBlurCycleDate}
          validateCycleDate={
            this.state.validateCycleDate === 'error' ? 'error' : ''
          }
          helpCycleDate={
            this.state.validateCycleDate === 'error'
              ? 'Tanggal cycle wajib diisi'
              : ''
          }
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  getRadioButton: state.ManualProcess.updateAddress.radioButton,
  getWaybill: state.ManualProcess.updateAddress.waybill,
  getName: state.ManualProcess.updateAddress.name,
  getPhoneNumber: state.ManualProcess.updateAddress.phoneNumber,
  getAddress: state.ManualProcess.updateAddress.address,
  getCustomer: state.ManualProcess.updateAddress.customer,
  getCustomerProduct: state.ManualProcess.updateAddress.customerProduct,
  getTemplateUpload: state.ManualProcess.updateAddress.templateUpload,
  getCycleDate: state.ManualProcess.updateAddress.cycleDate,
  listCustomer: state.Customer.ListCustomer,
  listCustomerProduct: state.ManualProcess.updateAddress.customerProductList,
  listTemplateUpload: state.ManualProcess.updateAddress.templateUploadList,
  resultUpload: state.ManualProcess.updateAddress.resultUpload,
  isLoading: state.Toggle.isLoading,
  visible: state.Toggle.modal,
  jenisToggle: state.Toggle.jenisToggle,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionUpdateAddress: bindActionCreators(ActionUpdateAddress, dispatch),
  actionListCustomer: bindActionCreators(ActionListCustomer, dispatch),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(UpdateAddressContainer);
