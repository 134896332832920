import { Action } from 'redux';

const initialState = {
  UploadDate: null,
};

export interface IAuthAction extends Action<string> {}

export interface IChangeUploadDate extends IAuthAction {
  UploadDate: any;
}

export default function AuthReducer(state = initialState, action: IAuthAction) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_UPLOAD_DATE_SOFTCOPY':
      const changeUploadDate = action as IChangeUploadDate;
      newState.UploadDate = changeUploadDate.UploadDate;

      return { ...newState };

    case 'CLEAR_UPLOAD_SOFTCOPY':
      newState.UploadDate = null;

      return { ...newState };
  }
  return { ...newState };
}
