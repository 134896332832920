import React, { Component } from 'react';
import CourierManifestFormContainer from '../../../../containers/Content/Manifest/CourierManifest/CourierManifestFormContainer';
import TableCourierManifestContainer from '../../../../containers/Content/Manifest/CourierManifest/TableCourierManifestContainer';

export default class index extends Component {
  render() {
    return (
      <div>
        <div>
          <CourierManifestFormContainer />
        </div>
        <div>
          <TableCourierManifestContainer />
        </div>
      </div>
    );
  }
}
