import React from 'react';
import styles from './dataTable.module.css';
import { Row, Col, Table } from 'antd';
import SpinMaterial from 'materials/SpinMaterial';
import CardMaterial from 'materials/CardMaterial';
import SelectField from 'components/SelectField';
import ButtonMaterial from 'materials/ButtonMaterial';
import { ColumnAlignTable, ColumnFixedTable } from 'interface/general';
import { PERFORMANCE_POSKO_INDICATOR } from 'lib/constants';

type DataTablePerformaProps = {
  isLoadingTable: boolean;
  dataTable: any;
  handleChangeFilter: (name: string, value: any) => void;
  handleDownload: () => void;
};

export default function DataTablePerforma({
  dataTable,
  isLoadingTable,
  handleChangeFilter,
  handleDownload,
}: DataTablePerformaProps) {
  const columnsTable = [
    {
      title: <b>Key Performance Indicator</b>,
      dataIndex: 'indicator',
      align: 'center' as ColumnAlignTable,
      fixed: 'left' as ColumnFixedTable,
      className: styles.columnGray,
    },
    {
      title: <b>Rata-rata</b>,
      align: 'center' as ColumnAlignTable,
      dataIndex: 'average',
      fixed: 'left' as ColumnFixedTable,
      width: 150,
    },
    ...Array.from({ length: 31 }, (_, i) => ({
      title: (i + 1).toString(),
      dataIndex: `date.${i + 1}`,
      align: 'center' as ColumnAlignTable,
    })),
  ];

  const dataSet = [] as any;

  for (const key in dataTable) {
    dataSet.push({
      indicator: PERFORMANCE_POSKO_INDICATOR[key],
      date: JSON.parse(dataTable[key].date),
      average: dataTable[key].average,
    });
  }

  return (
    <SpinMaterial spinning={isLoadingTable} size="large">
      <CardMaterial style={{ borderRadius: '10px 10px 0 0' }}>
        <Row>
          <Col span={18}>
            <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
              <span className="titleSearchCard" style={{ padding: 0 }}>
                SEMUA DATA
              </span>
            </div>
          </Col>
        </Row>
      </CardMaterial>
      <Table
        columns={columnsTable}
        bordered
        dataSource={dataSet}
        scroll={{ x: 'auto' }}
        pagination={false}
        footer={() => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              gap: 8,
            }}
          >
            <SelectField
              placeholder="Pilih Jenis Form"
              style={{ width: '200px' }}
              data={[{ id: 'excel', name: 'EXCEL (.xlsx)' }]}
              value={'excel'}
            />
            <ButtonMaterial
              icon="download"
              type="download"
              handleSubmit={handleDownload}
              style={{
                ...(dataSet.length > 0 && {
                  background: '#874FFF',
                  color: 'white',
                }),
              }}
              disabled={dataSet.length === 0}
            />
          </div>
        )}
      />
    </SpinMaterial>
  );
}
