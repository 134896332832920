export function GetTableCustomReportDataCustomer()
{
    return({
        type:"GET_TABLE_CUSTOM_REPORT_DATA_CUSTOMER"
    })
}
export function SetTableCustomReportDataCustomer(TableCustomReportDataCustomer:any)
{
    return({
        type:"SET_TABLE_CUSTOM_REPORT_DATA_CUSTOMER",
        TableCustomReportDataCustomer
    })
}
export function ClearTableCustomReportDataCustomer()
{
    return({
        type:"CLEAR_TABLE_CUSTOM_REPORT_DATA_CUSTOMER"
    })
}