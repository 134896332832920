import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../../action/toggle';

import axios from 'axios';

export function* S_downloadTemplateEmergencyUpload(action: any) {
  const state = yield select();
  const key = 'download';
  const token = state.Auth.Token;
  const ModeEmergency = state.ManualProcess.EmergencyProcess.ModeEmergency;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const file_name_csv = 'Template Upload Emergency.csv';
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = file_name_csv;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }

  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Delivery/TemplateEmergencyUpdate?Mode=' +
        ModeEmergency,
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);

          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_downloadTemplateEmergencyUpload() {
  yield takeLatest(
    'DOWNLOAD_TEMPLATE_EMERGENCY_UPLOAD',
    S_downloadTemplateEmergencyUpload
  );
}
