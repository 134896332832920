import * as ActionCustomDataReportCustomer from '../../../core/action/Customer/ActionCustomDataReportCustomer';
import * as ActionDeleteCustomReportData from '../../../core/action/Customer/ActionDeleteCustomReportCustomer';
import * as ActionDetailCustomReportData from '../../../core/action/Customer/ActionDetailCustomReportCustomer';
import * as ActionTableCustomReportData from '../../../core/action/Customer/ActionTableCustomReportDataCustomer';
import * as toggle from '../../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import TableDetailConfigReportComponent from '../../../components/Content/Customer/TableDetailConfigReportComponent';
import { connect } from 'react-redux';

interface IProps {
  actionToggle: any;
  actionTableCustomReportData: any;
  actionCustomDataReportCustomer: any;
  actionDetailCustomReportData: any;
  actionDeleteCustomReportData: any;
  dataTableCustomReportData: any;
  getCustomername: any;
  visible: any;
  jenisToggle: any;
  isLoading: any;
}
class TableDetailConfigReportContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleFormCustomReportDataCustomer = this.handleFormCustomReportDataCustomer.bind(
      this
    );
    this.handleEditFormCustomReportDataCustomer = this.handleEditFormCustomReportDataCustomer.bind(
      this
    );
    this.handleDeleteCustomReport = this.handleDeleteCustomReport.bind(this);
    this.handleClearDeleteCustomReport = this.handleClearDeleteCustomReport.bind(
      this
    );
    this.handlePutIdCustomReport = this.handlePutIdCustomReport.bind(this);
  }
  handleFormCustomReportDataCustomer() {
    this.props.actionCustomDataReportCustomer.clearAllCdrc();
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle('AddCustomReportDataCustomer');
    this.props.actionCustomDataReportCustomer.setPositionCdrc(
      this.props.dataTableCustomReportData.length + 1
    );
  }
  handleEditFormCustomReportDataCustomer(event: any) {
    this.props.actionCustomDataReportCustomer.setIdCustomReportCustomerCdrc(
      event
    );
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle('EditCustomReportDataCustomer');
    this.props.actionCustomDataReportCustomer.getDetailCdrc();
  }
  handleDeleteCustomReport() {
    this.props.actionDeleteCustomReportData.changeDeleteCustomReportCustomer();
  }
  handleClearDeleteCustomReport() {
    this.props.actionCustomDataReportCustomer.clearAllCdrc();
  }
  handlePutIdCustomReport(event: any) {
    this.props.actionCustomDataReportCustomer.setIdCustomReportCustomerCdrc(event);
  }
  componentWillMount() {
    if (this.props.dataTableCustomReportData.length === 0) {
      this.props.actionTableCustomReportData.GetTableCustomReportDataCustomer();
    }
  }
  render() {
    return (
      <div>
        <TableDetailConfigReportComponent
          handleFormCustomReportDataCustomer={
            this.handleFormCustomReportDataCustomer
          }
          handleEditFormCustomReportDataCustomer={
            this.handleEditFormCustomReportDataCustomer
          }
          handleDeleteCustomReport={this.handleDeleteCustomReport}
          handleClearDeleteCustomReport={this.handleClearDeleteCustomReport}
          handlePutIdCustomReport={this.handlePutIdCustomReport}
          getCustomername={this.props.getCustomername}
          visible={this.props.visible}
          jenisToggle={this.props.jenisToggle}
          isLoading={this.props.isLoading}
          dataTableCustomReportData={this.props.dataTableCustomReportData}
        />
      </div>
    );
  }
}
const mapStateProps = (state: any) => ({
  visible: state.Toggle.modal,
  jenisToggle: state.Toggle.jenisToggle,
  isLoading: state.Toggle.isLoading,
  getCustomername: state.Customer.DetailCustomer.CustomerName,
  dataTableCustomReportData:
    state.Customer.FormCustomerConfigReport.CustomDataReport
      .TableCustomReportDataCustomer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(toggle, dispatch),
  actionTableCustomReportData: bindActionCreators(
    ActionTableCustomReportData,
    dispatch
  ),
  actionCustomDataReportCustomer: bindActionCreators(
    ActionCustomDataReportCustomer,
    dispatch
  ),
  actionDetailCustomReportData: bindActionCreators(
    ActionDetailCustomReportData,
    dispatch
  ),
  actionDeleteCustomReportData: bindActionCreators(
    ActionDeleteCustomReportData,
    dispatch
  ),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(TableDetailConfigReportContainer);
