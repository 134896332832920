import { Button, Col, Dropdown, Icon, Input, Menu, Row, Table } from 'antd';
import React, { Component } from 'react';

import ButtonDetailContainer from '../../../containers/Content/DeliveryPriority/ButtonDetailContainer';
import ButtonMaterial from '../../../materials/ButtonMaterial';
import CardMaterial from '../../../materials/CardMaterial';
import DeliveryPriorityaddFormContainer from '../../../containers/Content/DeliveryPriority/DeliveryPriorityaddFormContainer';
import SpinMaterial from '../../../materials/SpinMaterial';

interface IProps {
  isLoading: any;
  handleModal: any;
  handlePage: any;
  handleFilter: any;
  PagePagination: any;
  TotalPagePagination: any;
  ListDeliveryPriority: any;
  getFilterType: any;
  handleSearch: any;
  handlePutWaybillSearch: any;
  getWaybillForm: any;
}

export default class DeliveryPriorityComponent extends Component<IProps> {
  render() {
    const filterLabel =
      this.props.getFilterType === null
        ? 'Filter'
        : this.props.getFilterType === 'process'
        ? 'Proses'
        : this.props.getFilterType === 'finished'
        ? 'Selesai'
        : 'Semua';
    const filter = (
      <Menu
        onClick={this.props.handleFilter}
        selectedKeys={[this.props.getFilterType]}
      >
        <Menu.Item key="all">
          <Button type="link">&nbsp; Semua</Button>
        </Menu.Item>
        <Menu.Item key="process">
          <Button type="link">&nbsp; Proses</Button>
        </Menu.Item>
        <Menu.Item key="finished">
          <Button type="link">&nbsp; Selesai</Button>
        </Menu.Item>
      </Menu>
    );
    const looppage = (this.props.PagePagination - 1) * 10 + 1;
    const columns = [
      {
        title: <b>No</b>,
        render: (text: any, record: any, index: any) => (
          <span>
            {this.props.PagePagination > 1 ? index + looppage : index + 1}
          </span>
        ),
        key: 'no',
        width: 50,
      },
      {
        title: <b>Waybill</b>,
        dataIndex: 'waybillCode',
        key: 'waybillCode',
        render: (waybillCode: any) => (
          <span style={{ color: 'red' }}>{waybillCode}</span>
        ),
        width: 200,
      },
      {
        title: <b>Nama Nasabah</b>,
        dataIndex: 'shipToName',
        key: 'shipToName',
        width: 150,
      },
      {
        title: <b>Kurir</b>,
        dataIndex: 'courierCode',
        key: 'courierCode',
        render: (courierCode: any) => (
          <span style={{ color: 'red' }}>{courierCode}</span>
        ),
        width: 90,
      },
      {
        title: <b>Status</b>,
        dataIndex: 'statusTicket',
        key: 'statusTicket',
        render: (statusTicket: any) => (
          <span>
            {statusTicket === true ? (
              <span style={{ color: '#31E116' }}>Proses</span>
            ) : (
              'Selesai'
            )}
          </span>
        ),
        width: 90,
      },
      {
        title: <b>Aksi</b>,
        key: 'action',
        width: 90,
        render: (record: any) => (
          <div style={{ marginRight: 50 }}>
            <ButtonDetailContainer id={record.id} />
          </div>
        ),
      },
    ];
    return (
      <div>
        <DeliveryPriorityaddFormContainer />
        <SpinMaterial
          spinning={this.props.isLoading}
          style={{}}
          size={'large'}
          content={
            <div style={{ padding: '35px' }}>
              <CardMaterial
                title={''}
                extra={''}
                style={{}}
                content={
                  <div>
                    <Row>
                      <Col span={16}>
                        <div>
                          <span
                            style={{ color: '#11BEFF', paddingRight: '20px' }}
                          >
                            <b>KIRIMAN PRIORITAS</b>
                          </span>
                          <Input.Search
                            style={{ borderRadius: '10px', width: '40%' }}
                            placeholder={'Cari berdasarkan waybill'}
                            onChange={this.props.handlePutWaybillSearch}
                            onPressEnter={this.props.handleFilter}
                            value={this.props.getWaybillForm}
                          />
                          <Dropdown overlay={filter} trigger={['click']}>
                            <Button type="link" style={{ color: 'black' }}>
                              <span style={{ color: 'black' }}>
                                {filterLabel}&nbsp;&nbsp;
                              </span>
                              <Icon type="down" />
                            </Button>
                          </Dropdown>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div style={{ float: 'right' }}>
                          <ButtonMaterial
                            disabled={''}
                            handleSubmit={this.props.handleModal}
                            teksButton={<b>TAMBAHKAN TIKET</b>}
                            size={''}
                            shape={''}
                            className={''}
                            type={'link'}
                            icon={''}
                            style={{
                              color: '#11BEFF',
                              paddingRight: '15px',
                            }}
                          />

                          <ButtonMaterial
                            disabled={''}
                            handleSubmit={this.props.handleModal}
                            teksButton={''}
                            size={''}
                            shape={'circle'}
                            className={''}
                            type={'primary'}
                            icon={'plus'}
                            style={{
                              color: '#fafafa',
                              paddingBottom: '3px',
                              background: '#31E116',
                              borderColor: '#31E116',
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                }
              />
              <Table
                bordered={false}
                pagination={{
                  onChange: page => {
                    this.props.handlePage(page);
                  },
                  pageSize: 10,
                  total: this.props.TotalPagePagination,
                  current:
                    this.props.PagePagination === 0
                      ? 1
                      : this.props.PagePagination,
                }}
                dataSource={this.props.ListDeliveryPriority}
                columns={columns}
              />
            </div>
          }
        />
      </div>
    );
  }
}
