export function changeCourierName(CourierName: any) {
  return {
    type: 'CHANGE_COURIER_NAME_FORM',
    CourierName,
  };
}
export function changeCourierLastName(CourierLastName: any) {
  return {
    type: 'CHANGE_COURIER_LAST_NAME_FORM',
    CourierLastName,
  };
}
export function changeCourierEmail(CourierEmail: any) {
  return {
    type: 'CHANGE_COURIER_EMAIL_FORM',
    CourierEmail,
  };
}
export function changeCourierPhoneNumber(CourierPhoneNumber: any) {
  return {
    type: 'CHANGE_COURIER_PHONE_NUMBER_FORM',
    CourierPhoneNumber,
  };
}
export function changeCourierDob(CourierDob: any) {
  return {
    type: 'CHANGE_COURIER_DOB_FORM',
    CourierDob,
  };
}
export function changeCourierPhoto(CourierPhoto: any) {
  return {
    type: 'CHANGE_COURIER_PHOTO_FORM',
    CourierPhoto,
  };
}
export function changeCourierCode(CourierCode: any) {
  return {
    type: 'CHANGE_COURIER_CODE_FORM',
    CourierCode,
  };
}
export function changeCourierAddress(CourierAddress: any) {
  return {
    type: 'CHANGE_COURIER_ADDRESS_FORM',
    CourierAddress,
  };
}
export function changeCourierStatus(CourierStatus: any) {
  return {
    type: 'CHANGE_COURIER_STATUS_FORM',
    CourierStatus,
  };
}
export function changeAreaCodeId(AreaCodeId: any) {
  return {
    type: 'CHANGE_AREA_CODE_ID_FORM',
    AreaCodeId,
  };
}
export function changeBranchCourier(BranchCourier: any) {
  return {
    type: 'CHANGE_BRANCH_COURIER_FORM',
    BranchCourier,
  };
}

export function changeClearDataCourierForm() {
  return {
    type: 'CHANGE_CLEAR_DATA_COURIER_FORM',
  };
}
