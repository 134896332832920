import ButtonMaterial from 'materials/ButtonMaterial';
import React from 'react';

interface IProps {
  handleDetailBranch: any;
}
export default function ButtonDetailBranchComponent({
  handleDetailBranch,
}: IProps) {
  return (
    <div>
      <ButtonMaterial
        handleSubmit={handleDetailBranch}
        teksButton={'Lihat Detail'}
        type={'primary'}
        style={{ width: '100%' }}
      />
    </div>
  );
}
