import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionPagination from '../../../action/Pagination/ActionPagination';
import * as ActionScanManifest from '../../../action/Manifest/ScanManifest/ActionScanManifest';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

export function* S_GetListScanManifest(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const RadioButton =
    state.ScanManifest.ScanManifestForm.ManifestRadioButtonForm === 'Success'
      ? true
      : false;
  const username = state.Auth.UsernameAuth;
  const Cycle = moment(
    state.ScanManifest.ScanManifestForm.ManifestCycleDateForm
  ).format(
    state.ScanManifest.ScanManifestForm.ManifestMonthly ? 'YYYYMM' : 'YYYYMMDD'
  );
  const Customer =
    state.ScanManifest.ScanManifestForm.ManifestCustomerForm === null
      ? 0
      : state.ScanManifest.ScanManifestForm.ManifestCustomerForm;
  const Product =
    state.ScanManifest.ScanManifestForm.ManifestProductForm === null
      ? 0
      : state.ScanManifest.ScanManifestForm.ManifestProductForm;
  const PagePagination = state.Pagination.PagePagination;
  const SizeDataPagination = state.Pagination.SizeDataPagination;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const Monthly = state.ScanManifest.ScanManifestForm.ManifestMonthly;
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/GroupManifest/Scan/${Cycle}/${Customer}/${Product}/${RadioButton}/${Monthly}/${username}/${PagePagination}/${SizeDataPagination}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionScanManifest.clearTableManifest());
    yield put(ActionScanManifest.changeTableScanManifest(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(ActionScanManifest.changeShowTable(true));
    yield put(toggle.changeLoadingFalse());
    yield put(toggle.changeJenisToggle(null));
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(toggle.changeJenisToggle(null));
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* S_SearchDataScanManifest(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const RadioButton =
    state.ScanManifest.ScanManifestForm.ManifestRadioButtonForm === 'Success'
      ? true
      : false;
  const username = state.Auth.UsernameAuth;
  const Cycle = moment(
    state.ScanManifest.ScanManifestForm.ManifestCycleDateForm
  ).format(
    state.ScanManifest.ScanManifestForm.ManifestMonthly ? 'YYYYMM' : 'YYYYMMDD'
  );
  const Customer =
    state.ScanManifest.ScanManifestForm.ManifestCustomerForm === null
      ? 0
      : state.ScanManifest.ScanManifestForm.ManifestCustomerForm;
  const Product =
    state.ScanManifest.ScanManifestForm.ManifestProductForm === null
      ? 0
      : state.ScanManifest.ScanManifestForm.ManifestProductForm;
  const searchValue = 
    !state.ScanManifest.SearchValue === true
      ? ""
      : state.ScanManifest.SearchValue;
  const PagePagination = state.Pagination.PagePagination;
  const SizeDataPagination = state.Pagination.SizeDataPagination;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const Monthly = state.ScanManifest.ScanManifestForm.ManifestMonthly;

  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/GroupManifest/Scan/ValueSearch/${Cycle}/${Customer}/${Product}/${RadioButton}/${Monthly}/${username}/${PagePagination}/${SizeDataPagination}?valueSearch=${searchValue}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionScanManifest.clearTableManifest());
    yield put(ActionScanManifest.changeTableScanManifest(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(ActionScanManifest.changeShowTable(true));
    yield put(toggle.changeLoadingFalse());
    yield put(toggle.changeJenisToggle(null));
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(toggle.changeJenisToggle(null));
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* S_GetDuplicateBarcodeScanManifest(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const RadioButton =
    state.ScanManifest.ScanManifestForm.ManifestRadioButtonForm === 'Success'
      ? true
      : false;
  const username = state.Auth.UsernameAuth;
  const Cycle = moment(
    state.ScanManifest.ScanManifestForm.ManifestCycleDateForm
  ).format(
    state.ScanManifest.ScanManifestForm.ManifestMonthly ? 'YYYYMM' : 'YYYYMMDD'
  );
  const Customer =
    state.ScanManifest.ScanManifestForm.ManifestCustomerForm === null
      ? 0
      : state.ScanManifest.ScanManifestForm.ManifestCustomerForm;
  const Product =
    state.ScanManifest.ScanManifestForm.ManifestProductForm === null
      ? 0
      : state.ScanManifest.ScanManifestForm.ManifestProductForm;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const Monthly = state.ScanManifest.ScanManifestForm.ManifestMonthly;
  const ResponseScan = state.ScanManifest.ResponseScan;
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Delivery/Barcode/Cycle/${Cycle}/${Customer}/${Product}/${RadioButton}/${Monthly}/${username}/true/${ResponseScan.barcode}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionScanManifest.clearTableManifest());
    yield put(ActionScanManifest.fetchListMultipleBarcode(data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(ActionScanManifest.changeShowTable(true));
    yield put(toggle.changeLoadingFalse());
    yield put(toggle.changeJenisToggle(null));
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(toggle.changeJenisToggle(null));
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_GetDuplicateBarcodeScanManifest() {
  yield takeLatest(
    'GET_DUPLICATE_BARCODE_SCAN_MANIFEST',
    S_GetDuplicateBarcodeScanManifest
  );
}
export function* SM_SearchDataScanManifest() {
  yield takeLatest('GET_SEARCH_MANIFEST_DATA_TABLE', S_SearchDataScanManifest);
}
export function* SM_GetListScanManifest() {
  yield takeLatest('GET_TABLE_SCAN_MANIFEST', S_GetListScanManifest);
}
