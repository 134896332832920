import * as ActionBastReport from 'core/action/Report/BastReport/ActionBastReport';
import * as ActionListCustomer from 'core/action/Customer/ActionListCustomer';
import * as ActionListCustomerProduct from 'core/action/CustomerProduct/ActionListCustomerProduct';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import BastReportComponent from 'module/Report/Component/BastReport/BastReportComponent';
import { connect } from 'react-redux';
import moment from 'moment';

interface IProps {
  actionBastReport: any;
  actionListCustomer: any;
  getFirstDate: any;
  getLastDate: any;
  getCustomer: any;
  getCustomerProduct: any;
  listCustomer: any;
  isLoading: any;
  loadingCustomer: any;
  loadingSelectCustomerProduct: any;
  listCustomerProduct: any;
  actionListCustomerProduct: any;
}

function BastReportContainer({
  actionBastReport,
  actionListCustomer,
  getFirstDate,
  getLastDate,
  getCustomer,
  getCustomerProduct,
  listCustomer,
  isLoading,
  loadingCustomer,
  loadingSelectCustomerProduct,
  listCustomerProduct,
  actionListCustomerProduct,
}: IProps) {
  const [valueFirstDate, setValueFirstDate]: any = useState(null);
  const [validateFirstDate, setValidateFirstDate]: any = useState(null);
  const [validateFirstDate2, setValidateFirstDate2]: any = useState(null);
  const [valueLastDate, setValueLastDate]: any = useState(null);
  const [validateLastDate, setValidateLastDate]: any = useState(null);
  const [validateCustomer, setValidateCustomer]: any = useState(null);
  const [validateCustomerProduct, setValidateCustomerProduct]: any =
    useState(null);

  function handlePutFirstDate(event: any) {
    setValueFirstDate(event);

    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      const lastDateValueConvert = moment(getLastDate).format('YYYYMMDD');
      const dateValueConvert = moment(dateValue).format('YYYYMMDD');
      actionBastReport.changeFirstDate(dateValue);
      if (dateValueConvert > lastDateValueConvert) {
        setValueLastDate(event);

        actionBastReport.changeLastDate(dateValue);
      } else if (dateValueConvert === lastDateValueConvert) {
        setValueLastDate(event);
      } else {
        setValueLastDate(null);

        actionBastReport.changeLastDate(null);
      }
      setValidateFirstDate(null);
    } else {
      setValidateFirstDate('error');
      setValueLastDate(null);
      actionBastReport.changeFirstDate(null);
      actionBastReport.changeLastDate(null);
    }
  }
  function handleValidateFirstDate(event: any) {
    setValidateFirstDate2(event);

    if (validateFirstDate2 === true) {
      if (
        getFirstDate === null ||
        getFirstDate === undefined ||
        getFirstDate === 'Invalid date'
      ) {
        setValidateFirstDate('error');
      } else {
        setValidateFirstDate('');
      }
    }
  }
  function handlePutLastDate(event: any) {
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      actionBastReport.changeLastDate(dateValue);
    } else {
      actionBastReport.changeLastDate(null);
    }
    setValueLastDate(event);

    if (event === '') {
      setValidateLastDate('error');
    } else {
      setValidateLastDate('');
    }
  }
  function handleValidateLastDate(event: any) {
    if (event === true) {
      if (
        getLastDate === null ||
        getLastDate === undefined ||
        getLastDate === 'Invalid date'
      ) {
        setValidateLastDate('error');
      } else {
        setValidateLastDate('');
      }
    }
  }
  function handlePutCustomer(event: any) {
    actionBastReport.changeCustomerId(event);

    if (event === null) {
      setValidateCustomer('error');
    } else {
      setValidateCustomer(null);
    }
  }
  function handleBlurCustomer() {
    if (getCustomer === null) {
      setValidateCustomer('error');
    } else {
      setValidateCustomer(null);
    }
  }
  function handleGetCustomer() {
    actionListCustomer.getListCustomer();
  }
  function handleClearCustProduct() {
    actionBastReport.clearCustomerProduct();
  }
  function handlePutCustomerProduct(event: any) {
    if (event[0] === 'Pilih Semua Produk') {
      actionBastReport.changeProductId(
        listCustomerProduct.map((data: any, index: any) => data.id)
      );
    } else {
      actionBastReport.changeProductId(event);
    }
    if (
      getCustomerProduct === null ||
      getCustomerProduct === [] ||
      event.length === 0
    ) {
      setValidateCustomerProduct('error');
    } else {
      setValidateCustomerProduct(null);
    }
  }
  function handleGetCustomerProduct() {
    actionListCustomerProduct.getListCustomerProductNoType();
  }
  function handleBlurCustomerProduct() {
    if (
      getCustomerProduct.length === undefined ||
      getCustomerProduct.length < 0
    ) {
      setValidateCustomerProduct('error');
    } else {
      setValidateCustomerProduct(null);
    }
  }
  function handleDownload() {
    actionBastReport.downloadBast();
  }
  function handleReset() {
    actionBastReport.clearForm();
    setValueFirstDate(null);
    setValidateFirstDate(null);
    setValidateFirstDate2(null);
    setValueLastDate(null);
    setValidateCustomer(null);
    setValidateCustomerProduct(null);
  }
  return (
    <React.Fragment>
      <BastReportComponent
        handlePutFirstDate={handlePutFirstDate}
        handleValidateFirstDate={handleValidateFirstDate}
        valueFirstDate={valueFirstDate}
        getFirstDate={getFirstDate}
        validateFirstDate={validateFirstDate === 'error' ? 'error' : ''}
        helpFirstDate={
          validateFirstDate === 'error' ? 'Tanggal wajib diisi' : ''
        }
        handlePutLastDate={handlePutLastDate}
        handleValidateLastDate={handleValidateLastDate}
        getLastDate={getLastDate}
        valueLastDate={valueLastDate}
        validateLastDate={validateLastDate === 'error' ? 'error' : ''}
        helpLastDate={validateLastDate === 'error' ? 'Tanggal wajib diisi' : ''}
        loadingSelectCustomer={loadingCustomer}
        handlePutCustomer={handlePutCustomer}
        handleBlurCustomer={handleBlurCustomer}
        handleClearCustProduct={handleClearCustProduct}
        getCustomer={getCustomer}
        listCustomer={listCustomer}
        handleGetCustomer={handleGetCustomer}
        validateCustomer={validateCustomer === 'error' ? 'error' : ''}
        helpCustomer={
          validateCustomer === 'error' ? 'Pelanggan wajib diisi' : ''
        }
        loadingSelectCustomerProduct={loadingSelectCustomerProduct}
        listCustomerProduct={listCustomerProduct}
        handlePutCustomerProduct={handlePutCustomerProduct}
        getCustomerProduct={getCustomerProduct}
        validateCustomerProduct={
          validateCustomerProduct === 'error' ? 'error' : ''
        }
        helpCustomerProduct={
          validateCustomerProduct === 'error' ? 'Produk wajib diisi' : ''
        }
        handleGetCustomerProduct={handleGetCustomerProduct}
        handleBlurCustomerProduct={handleBlurCustomerProduct}
        validateDownloadButton={
          getFirstDate === null ||
          getLastDate === null ||
          getCustomerProduct === null ||
          getCustomerProduct.length === 0
        }
        validateResetButton={
          getFirstDate !== null ||
          getLastDate !== null ||
          getCustomerProduct.length !== 0
            ? false
            : true
        }
        handleDownload={handleDownload}
        handleReset={handleReset}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => ({
  getFirstDate: state.ReportBast.FirstDate,
  getLastDate: state.ReportBast.LastDate,
  getCustomer: state.ReportBast.CustomerId,
  getCustomerProduct: state.ReportBast.ProductId,
  listCustomer: state.Customer.ListCustomer,
  isLoading: state.Toggle.isLoading,
  loadingCustomer: state.Customer.PropertiesCustomer.LoadingCustomer,
  loadingSelectCustomerProduct:
    state.CustomerProduct.PropertiesCustomerProduct.LoadingCustomerProduct,
  listCustomerProduct: state.CustomerProduct.ListCustomerProduct,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionBastReport: bindActionCreators(ActionBastReport, dispatch),
  actionListCustomer: bindActionCreators(ActionListCustomer, dispatch),
  actionListCustomerProduct: bindActionCreators(
    ActionListCustomerProduct,
    dispatch
  ),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(BastReportContainer);
