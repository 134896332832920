import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionToogle from '../../../action/toggle/index';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

export function* S_DownloadResultCountManifest(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const key = 'download';
  const RadioButton =
    state.ScanManifest.ScanManifestForm.ManifestRadioButtonForm === 'Success'
      ? true
      : false;
  const Cycle = moment(
    state.ScanManifest.ScanManifestForm.ManifestCycleDateForm
  ).format(
    state.ScanManifest.ScanManifestForm.ManifestMonthly ? 'YYYYMM' : 'YYYYMMDD'
  );
  const Customer =
    state.ScanManifest.ScanManifestForm.ManifestCustomerForm === null
      ? 0
      : state.ScanManifest.ScanManifestForm.ManifestCustomerForm;
  const Product =
    state.ScanManifest.ScanManifestForm.ManifestProductForm === null
      ? 0
      : state.ScanManifest.ScanManifestForm.ManifestProductForm;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const Monthly = state.ScanManifest.ScanManifestForm.ManifestMonthly;
  const Condition = state.ScanManifest.ScanManifestForm.Condition;
  const username = state.Auth.UsernameAuth;
  function nameFile(e: any) {
    switch (e) {
      case 'Scan':
        return 'Manifest yang sudah di Scan';
      case 'UnScan':
        return 'Manifest yang belum di Scan';
      case 'Total':
        return 'Manifest total';
      case 'ScanUser':
        return 'Manifest yang sudah di scan user';
    }
    return null;
  }

  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = nameFile(Condition) + '.xls';
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }
  try {
    yield put(ActionToogle.changeLoadingTrue());

    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/GroupManifest/DownloadResultCountManifest`,
      {
        conditionResultManifest: Condition,
        cycle: Cycle,
        customerId: Customer,
        customerProductId: Product,
        success: RadioButton,
        groupManifest: 0,
        monthly: Monthly,
        userCode: username,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);

          // let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionToogle.changeLoadingFalse());
  } catch (error) {
    if (error.response.data.Message === 'Network Error') {
      message.error({
        content: 'Download Gagal',
        key,
        duration: 3,
      });
      yield put(ActionToogle.changeLoadingFalse());
    } else if (error.response.data.Message === 'No delivery found!') {
      message.error({
        content: 'Data Pengiriman tidak tersedia',
        key,
        duration: 3,
      });
      yield put(ActionToogle.changeLoadingFalse());
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* SM_DownloadResultCountManifest() {
  yield takeLatest(
    'DOWNLOAD_RESULT_COUNT_MANIFEST',
    S_DownloadResultCountManifest
  );
}
