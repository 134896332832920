import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import styles from './ModalSelectBarcodeMultiple.module.css';
import SpinMaterial from 'materials/SpinMaterial';
import ModalMaterial from 'materials/ModalMaterial';
import { ScanBarcodeMultiple } from '../../module/DeliveryBetweenPosts/Component/BaggingDocument/Components/ModalScanWaybil';
import { ColumnAlignTable } from 'interface/general';
import { SingleCheckbox } from 'components/SingleCheckbox';
import Button from 'components/Button';

type ModalSelectBarcodeMultipleProps = {
  isShowModal: boolean;
  handleCloseModal: () => void;
  dataScanBarcode: ScanBarcodeMultiple[];
  handleSubmit: (value: any) => void;
  isLoadingScan: boolean;
  okText?: string;
  isCourier?: boolean;
};

export default function ModalSelectBarcodeMultiple({
  isShowModal,
  handleCloseModal,
  dataScanBarcode,
  handleSubmit,
  isLoadingScan = false,
  isCourier = false,
  okText = 'Pilih',
}: ModalSelectBarcodeMultipleProps) {
  const [dataScanBarcodeWaybill, setDataScanBarcodeWaybill] = useState<any[]>(
    []
  );

  useEffect(() => {
    if (dataScanBarcode.length > 0 && dataScanBarcodeWaybill.length === 0) {
      const formatData = dataScanBarcode.map(item => ({
        ...item,
        isSelect: false,
      }));

      setDataScanBarcodeWaybill(formatData);
    }

    if (dataScanBarcode.length === 0 && dataScanBarcodeWaybill.length > 0) {
      setDataScanBarcodeWaybill([]);
    }
  }, [dataScanBarcode, dataScanBarcodeWaybill]);

  const handleCheckAllScanBarcode = () => {
    const dataScan = [...dataScanBarcodeWaybill];
    const dataScanSelect = [...dataScanBarcodeWaybill].filter(
      item => !!item.isSelect
    );

    dataScan.forEach(item => {
      if (dataScanSelect.length !== dataScan.length) {
        item.isSelect = true;
      } else {
        item.isSelect = !item.isSelect;
      }
    });

    setDataScanBarcodeWaybill(dataScan);
  };

  const handleCheckSingle = (index: number) => {
    const dataScan = [...dataScanBarcodeWaybill];
    dataScan[index].isSelect = !dataScan[index].isSelect;

    setDataScanBarcodeWaybill(dataScan);
  };

  const handleSubmitWaybill = () => {
    const dataScan = [...dataScanBarcodeWaybill];

    handleSubmit(
      dataScan.filter(item => item.isSelect).map(item => item.waybill)
    );
  };

  const dataScan = [...dataScanBarcodeWaybill];
  const dataScanSelect = [...dataScanBarcodeWaybill].filter(
    item => !!item.isSelect
  );
  const isCheckAll = dataScan.length === dataScanSelect.length;

  const columnsTable = [
    {
      title: 'Waybill',
      dataIndex: 'waybill',
      align: 'center' as ColumnAlignTable,
    },
    {
      title: 'Barcode',
      dataIndex: 'barcode',
      width: 250,
      align: 'center' as ColumnAlignTable,
    },
    {
      title: 'Produk',
      dataIndex: 'customerProduct',
      align: 'center' as ColumnAlignTable,
    },
    ...(isCourier
      ? [
          {
            title: 'Kurir',
            dataIndex: 'courier',
            align: 'center' as ColumnAlignTable,
            render: text => text || '-',
          },
        ]
      : []),
    {
      title: 'Tanggal Cycle',
      dataIndex: 'cycle',
      align: 'center' as ColumnAlignTable,
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      align: 'center' as ColumnAlignTable,
    },
    {
      title: (
        <SingleCheckbox
          value={isCheckAll}
          onChange={() => handleCheckAllScanBarcode()}
          indeterminate={!isCheckAll && dataScanSelect.length > 0}
        />
      ),
      align: 'center' as ColumnAlignTable,
      render: (text: any, record: any, index: any) => (
        <SingleCheckbox
          value={record.isSelect}
          onChange={() => handleCheckSingle(index)}
        />
      ),
    },
  ];

  return (
    <ModalMaterial
      teksTitle="Pilih Barcode untuk di-Scan"
      width="80%"
      visible={isShowModal}
      onCancel={handleCloseModal}
    >
      <SpinMaterial spinning={isLoadingScan} size={'large'}>
        <div style={{ marginTop: 24, padding: '0 24px' }}>
          <p className={styles.textDesc}>
            Karena Barcode duplikat, pilih salah satu data
          </p>
          <Table
            columns={columnsTable}
            bordered
            dataSource={dataScanBarcodeWaybill}
            scroll={{ x: 'auto' }}
          />
          <div className={styles.wrapperAction}>
            <Button
              text={okText}
              className={styles.widthButton}
              onClick={handleSubmitWaybill}
            />
          </div>
        </div>
      </SpinMaterial>
    </ModalMaterial>
  );
}
