import * as ActionCustomerLoading from '../../action/Customer/ActionCustomerLoading';
import * as ActionListCustomCsvCustomer from '../../action/Customer/ActionListCustomCsvCustomer';
import * as ActionLogin from '../../../module/Login/Store';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_GetListCustomCsvCustomer() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const IdCustomer = state.Delivery.UnggahDocument.CustomerId;
  try {
    yield put(ActionCustomerLoading.setLoadingCustomer());
    yield put(
      ActionCustomerLoading.setTypeLoadingCustomer('selectCustomCustomer')
    );

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Setting/CustomCsv/Customer/' +
        IdCustomer +
        '/Active',
      { headers: config }
    );
    yield put(ActionCustomerLoading.setLoadingCustomer());
    yield put(ActionCustomerLoading.clearTypeLoadingCustomer());
    yield put(ActionListCustomCsvCustomer.setListCustomCsvCustomer(data));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
    } else {
      yield put(ActionCustomerLoading.setLoadingCustomer());
      yield put(ActionCustomerLoading.clearTypeLoadingCustomer());
    }
  }
}

export function* SM_GetListCustomCsvCustomer() {
  yield takeLatest('GET_LIST_CUSTOM_CSV_CUSTOMER', S_GetListCustomCsvCustomer);
}
