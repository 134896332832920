import React, {Component} from 'react';
import {connect} from 'react-redux';
import TableDataStatusDefaultComponent from '../../../components/Content/Customer/TableDataStatusDefaultComponent';
import * as toggle from '../../../core/action/toggle';
import * as ActionTableStatusDefault from '../../../core/action/Customer/ActionTableStatusDefault';
import { bindActionCreators, compose } from 'redux';

interface IProps
{
    data:any
    GetCustomerName:any
    GetReasonDeliveryStatus:any
    actionTableStatusDefault:any
    actionToggle:any
    isLoading:any
}

interface IState
{
    sRT:any
    dataSource:any
    page:any
}

class TableDefaultDataStatusContainer extends Component <IProps, IState>
{
    constructor(props:any)
    {
        super(props)
        this.state={
            sRT:null,
            dataSource: this.props.data,
            page: 0
        }
        this.handlePage = this.handlePage.bind(this)
    }
    handlePage(event:any)
    {
        this.setState({
            page:event,
        });
    }
    render(){
        return(
            <TableDataStatusDefaultComponent
                dataSource={this.state.dataSource}
                data={this.props.data}
                handlePage={this.handlePage}
                page={this.state.page}
                isLoading={this.props.isLoading}
                GetCustomerName={this.props.GetCustomerName}
                GetReasonDeliveryStatus={this.props.GetReasonDeliveryStatus}
            />
        )
    }
}
const mapStateToProps = (state:any)=>({
    data:state.Customer.DefaultDataStatus,
    isLoading:state.Toggle.isLoading,
    GetCustomerName : state.Customer.DetailCustomer.CustomerName,
    GetReasonDeliveryStatus : state.Customer.PropertiesCustomer.ReasonDeliveryStatus,
})
const mapDispatchToProps = (dispatch:any) => ({
    actionTableStatusDefault : bindActionCreators(ActionTableStatusDefault, dispatch),
    actionToggle : bindActionCreators(toggle, dispatch)
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
    withConnect
)(TableDefaultDataStatusContainer)