import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import FormSynchronizedWeekly from '../Component/FormSynchronizedWeeklyComponent';
import * as ActionSynchronizedWeekly from '../../../core/action/Holiday/ActionFormSynchronizedWeekly';
import * as ActionAddSynchronizedWeekly from '../../../core/action/Holiday/ActionAddHolidaySynchroniz';
import * as ActionPropertiesHoliday from '../../../core/action/Holiday/ActionPropertiesHoliday';
import * as ActionFormHoliday from '../../../core/action/Holiday/ActionFormManualHoliday';

interface IProps {
  YearSynchronized: any;
  ListHolidaySynchroniz: any;
  actionSynchronizedWeekly: any;
  actionPropertiesHoliday: any;
  actionFormHoliday: any;
  actionAddSynchronizedWeekly: any;
}

const FormSynchronizedWeeklyContainer = (props: IProps) => {
  const [ValidateYearSynchronized, setValidateYearSynchronized] =
    useState<any>(null);
  const [
    ValidateConditionYearSynchronized,
    setValidateConditionYearSynchronized,
  ] = useState<any>(null);

  const handleDisbleFormSynchronized = () => {
    props.actionPropertiesHoliday.setSynchronizeWeekly();
    props.actionFormHoliday.clearAllFormManualHoliday();
  };
  const handlePutYear = (event: any) => {
    var now = new Date();
    const RegexMinChar = /^.{4,}$/;
    const regex = /^-?[0-9]*(\.[0-9]*)?$/;
    const number = parseInt(event.target.value || 1, 10);
    var currentYear = now.getFullYear();

    if (isNaN(number)) {
      return;
    }
    if (!regex.test(event.target.value)) {
    } else {
      props.actionSynchronizedWeekly.setYearSynchronized(
        event.target.value.replace(/^0+/, '')
      );

      if (event.target.value === '' || null) {
        setValidateYearSynchronized('error');
      } else if (!RegexMinChar.test(event.target.value)) {
        setValidateYearSynchronized('minchar');
      } else if (event.target.value < currentYear) {
        setValidateYearSynchronized('minusyear');
      } else {
        setValidateYearSynchronized(null);
      }
    }
  };
  const handleValidateYear = (event: any) => {
    setValidateConditionYearSynchronized(event);
    if (ValidateConditionYearSynchronized === true) {
      if (
        props.YearSynchronized === null ||
        props.YearSynchronized === undefined ||
        props.YearSynchronized === 'Invalid date'
      ) {
        setValidateConditionYearSynchronized('error');
      } else {
        setValidateConditionYearSynchronized(null);
      }
    }
  };
  const handleAddHolodaySynchronized = () => {
    const DataHolidaySynchroniz = props.ListHolidaySynchroniz.map(String);
    const CompareListSynchroniz = DataHolidaySynchroniz.indexOf(
      props.YearSynchronized
    );

    if (CompareListSynchroniz >= 0) {
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            Tahun {props.YearSynchronized} sudah di sinkron!!!
          </p>
        ),
        content: <p style={{ color: '#C0C0C0' }}>Silakan ubah inputan tahun</p>,
      });
    } else {
      props.actionAddSynchronizedWeekly.addHolidaySynchroniz();
    }
  };

  return (
    <div>
      <FormSynchronizedWeekly
        YearSynchronized={props.YearSynchronized}
        ValidateYearSynchronized={ValidateYearSynchronized}
        handlePutYear={handlePutYear}
        handleValidateYear={handleValidateYear}
        handleAddHolodaySynchronized={handleAddHolodaySynchronized}
        handleDisbleFormSynchronized={handleDisbleFormSynchronized}
      />
    </div>
  );
};

const mapStateProps = (state: any) => ({
  YearSynchronized: state.Holiday.FormSynchronizedWeekly.YearSynchronized,
  ListHolidaySynchroniz: state.Holiday.FormSynchronizedWeekly.HolidaySynchroniz,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionSynchronizedWeekly: bindActionCreators(
    ActionSynchronizedWeekly,
    dispatch
  ),
  actionPropertiesHoliday: bindActionCreators(
    ActionPropertiesHoliday,
    dispatch
  ),
  actionFormHoliday: bindActionCreators(ActionFormHoliday, dispatch),
  actionAddSynchronizedWeekly: bindActionCreators(
    ActionAddSynchronizedWeekly,
    dispatch
  ),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(FormSynchronizedWeeklyContainer);
