import * as ActionAuth from '../../action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionRestoreData from '../../action/RestoreData/ActionRestoreData';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_deleteWaybillRestoreData(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const Waybill = state.RestoreData.FormRestoreData.Waybill;
  const RB = state.RestoreData.FormRestoreData.RadioButtonW;
  const uploadDateW = state.RestoreData.FormRestoreData.UploadDateW;
  const body = {
    waybillCode: [Waybill],
  };

  try {
    yield put(toggle.changeLoadingTrue());
    const { data } =
      RB === 'Delete'
        ? yield call(
            axios.delete,
            process.env.REACT_APP_URL +
              '/Delivery/BackupData/DeleteSingleWaybill',
            { data: body, headers: config }
          )
        : yield call(
            axios.delete,
            `${process.env.REACT_APP_URL}/Delivery/RestoreData/RestoreSingleWaybill`,
            { data: body, headers: config }
          );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    if (uploadDateW === null) {
    } else {
      yield put(ActionRestoreData.clearListWaybillRestoreData());
      yield put(ActionRestoreData.submitGetDataRestoreData());
    }
    yield put(ActionRestoreData.setListResultDeleteData(data));
    yield put(ActionRestoreData.clearWaybillRestoreData());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());
    if (data.substring(0, 6) === 'Ticket') {
      yield put(toggle.changeModal2());
      yield put(toggle.changeJenisToggle2('failedDeleteWaybill'));
    } else {
      Modal.success({
        title: (
          <p style={{ color: '#31E116' }}>
            {RB === 'Delete' ? (
              <b>Berhasil di hapus</b>
            ) : (
              <b>Berhasil di restore</b>
            )}
          </p>
        ),

        onOk() {},
      });
    }
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}

export function* SM_deleteWaybillRestoreData() {
  yield takeLatest(
    'SUBMIT_DELETE_RESTORE_DATA_BY_WAYBILL',
    S_deleteWaybillRestoreData
  );
}
