import instance from 'config/interceptor';

export const getAllCustomers = async () => {
  try {
    return await instance.get(process.env.REACT_APP_URL + `/customers`);
  } catch (error) {
    return error;
  }
};

// Test new function
export const getCustomerList = async () => {
  const { data } = await instance.get('/customers');
  return data.data;
};

export const getProductListByCustomer = async params => {
  const [, id] = params.queryKey || [];

  const { data } = await instance.get(`/Customer/product/customer/${id}`);
  return data.data;
};

export const getAllBranch = async () => {
  try {
    return await instance.get(process.env.REACT_APP_URL + `/branchs`);
  } catch (error) {
    return error;
  }
};

export const getCourierWithBranch = async params => {
  const { branchItems, isAll = true } = params[1];
  let link = `/GetBranch/branchId`;
  if (branchItems) {
    const branchIds = Array.isArray(branchItems) ? branchItems : [branchItems];
    const queryParam = `BranchId=${branchIds.join('&BranchId=')}`;
    link = `${link}?${queryParam}`;
  }
  if (!isAll) {
    link = link.concat(`&isAll=${isAll}`);
  }

  try {
    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const getProductByCustomers = async params => {
  const { id, active = false, typeProduct = 'Card' } = params[1];
  let link = `/Customer/product/customer/${id}`;
  if (active)
    link = `/Customer/product/customer/status/${typeProduct}/${id}/Active`;

  try {
    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};
