import { Action } from 'redux';
import { IFetchLogActivityAction } from './LogActivityAction';

const initialState: any = {
  logActivityDataList: [],
};

export default function LogActivityProcessReducer(
  state = initialState,
  action: Action
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_LOG_ACTIVITY_LIST_DATA': {
      const fetchLogActivityDataListAction = action as IFetchLogActivityAction;
      newState.logActivityDataList = fetchLogActivityDataListAction.data;
      return { ...newState };
    }
    case 'CLEAR_LOG_ACTIVITY_LIST_DATA': {
      newState.logActivityDataList = [];
      return { ...newState };
    }
  }
  return state;
}
