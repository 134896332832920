import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as Branch from '../../../action/Branch/ActionAllBranch';
import * as LoadingBranch from '../../../action/Branch/ActionLoadingBranch';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_getBranchManualProcessVendor(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(LoadingBranch.setLoadingBranch());
    yield put(LoadingBranch.setTypeLoadingBranch('selectbranch'));

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/branchs/vendor',
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(Branch.setBranch(data));
    yield put(LoadingBranch.setLoadingBranch());
    yield put(LoadingBranch.clearTypeLoadingBranch());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(LoadingBranch.setLoadingBranch());
    yield put(LoadingBranch.clearTypeLoadingBranch());
  }
}

export function* SM_getBranchManualProcessVendor() {
  yield takeLatest(
    'GET_BRANCH_MANUAL_PROCESS_VENDOR_FROM',
    S_getBranchManualProcessVendor
  );
}
