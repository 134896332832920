import * as ActionAuth from '../../../core/action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as Address from '../../action/Address/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_GetSubDistrict(action: any) {
  try {
    yield put(Address.setLoadingAddress());
    yield put(Address.setLoadingTypeAddress('selectSubDistrict'));
    const state = yield select();
    const idCity = state.Branch.DetailBranch.City;
    const token = state.Auth.Token;
    const statusTokenRefresh = state.Auth.StatusTokenRefresh;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-type': 'application/json',
    };
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Address/subdistrict/0/1000?cityId=${idCity}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(Address.SetSubDistrict(data.data));
    yield put(Address.setLoadingAddress());
    yield put(Address.clearLoadingTypeAddress());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* SM_GetSubDistrict() {
  yield takeLatest('GET_SUBDISTRICT', S_GetSubDistrict);
}
