import React, { Component } from 'react';
import { persistor, store } from '../core/store/ConfigStore';

import { ConnectedRouter } from 'connected-react-router';
import Navigation from '../navigation/indexNavigation';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import history from '../core/utils/History';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// import Navigation from '../navigation/indexNavigations';






export default class App extends Component {
  render() {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
        },
      },
    });
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ConnectedRouter history={history}>
            <QueryClientProvider client={queryClient}>
              <Navigation />
            </QueryClientProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    );
  }
}
