import * as ActionAuth from '../../../action/Auth';
import * as ActionCustomerReport from '../../../action/Report/CustomerReport/ActionCustomerReportForm';
import * as ActionLogin from '../../../../module/Login/Store';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_GetStatusDeliverySuccessCustomerReport(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const CustomerId = state.Report.CustomerReportForm.CustomerId;

  try {
    yield put(ActionCustomerReport.clearListStatusDeliveryCustomerReportForm());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Setting/CustomStatus?id=' +
        CustomerId +
        '&stat=Success',
      { headers: config }
    );

    if (data.data.length === 0) {
      const { data } = yield call(
        axios.get,
        process.env.REACT_APP_URL + '/Setting/CustomStatus?id=0&stat=Success',
        { headers: config }
      );
      const labelStatus = data.data.map((data: any) => data.description);
      yield put(
        ActionCustomerReport.changeListStatusSuccessCustomerReport(labelStatus)
      );
      yield put(
        ActionCustomerReport.changeStatusSuccessCustomerReport(labelStatus)
      );
    } else {
      const labelStatus = data.data.map((data: any) => data.description);
      yield put(
        ActionCustomerReport.changeListStatusSuccessCustomerReport(labelStatus)
      );
      yield put(
        ActionCustomerReport.changeStatusSuccessCustomerReport(labelStatus)
      );
    }
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_GetStatusDeliveryReturnCustomerReport(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const CustomerId = state.Report.CustomerReportForm.CustomerId;
  try {
    yield put(ActionCustomerReport.clearListStatusDeliveryCustomerReportForm());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Setting/CustomStatus?id=' +
        CustomerId +
        '&stat=Return',
      { headers: config }
    );

    if (data.data.length === 0) {
      const { data } = yield call(
        axios.get,
        process.env.REACT_APP_URL + '/Setting/CustomStatus?id=0&stat=Return',
        { headers: config }
      );
      const labelStatus = data.data.map((data: any) => data.description);
      yield put(
        ActionCustomerReport.changeListStatusReturnCustomerReport(labelStatus)
      );
      yield put(
        ActionCustomerReport.changeStatusReturnCustomerReport(labelStatus)
      );
    } else {
      const labelStatus = data.data.map((data: any) => data.description);
      yield put(
        ActionCustomerReport.changeListStatusReturnCustomerReport(labelStatus)
      );
      yield put(
        ActionCustomerReport.changeStatusReturnCustomerReport(labelStatus)
      );
    }
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_GetStatusDeliverySuccessCustomerReport() {
  yield takeLatest(
    'GET_STATUS_DELIVERY_SUCCESS_CUSTOMER_REPORT_FORM',
    S_GetStatusDeliverySuccessCustomerReport
  );
}
export function* SM_GetStatusDeliveryReturnCustomerReport() {
  yield takeLatest(
    'GET_STATUS_DELIVERY_RETURN_CUSTOMER_REPORT_FORM',
    S_GetStatusDeliveryReturnCustomerReport
  );
}
