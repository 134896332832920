import React, { Component } from 'react'
import FormCsvCustomerContainer from '../../../containers/Content/Customer/FormCsvCustomerContainer';
import SpinMaterial from '../../../materials/SpinMaterial';

interface IProps
{
    isLoading:any
}
export default class CustomCsvCustomerComponent extends Component<IProps> {
    render() {
        return (
            <div>
                <SpinMaterial
                    spinning={this.props.isLoading}
                    style={{}}
                    size={"large"}
                    content={
                        <FormCsvCustomerContainer/>
                    }/>
            </div>
        )
    }
}
