import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionPagination from '../../../action/Pagination/ActionPagination';
import * as ActionServerVoip from '../../../action/Voip/Server/ActionServer';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../../action/toggle';

import { ErrorMassage } from '../../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_getDataTableServerVoip() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const PagePagination = state.Pagination.PagePagination;
  const SizeDataPagination = state.Pagination.SizeDataPagination;
  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/VoipSetting/${PagePagination}/${SizeDataPagination}`,
      { headers: config }
    );

    yield put(ActionServerVoip.setListServerVoip(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      const propsError = ErrorMassage('List Server Voip');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
    yield put(changeLoadingFalse());
  }
}

export function* S_searchDataTableServerVoip() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const searchValue = state.Toggle.ValueSearch;
  const PagePagination = state.Pagination.PagePagination;
  const SizeDataPagination = state.Pagination.SizeDataPagination;
  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      searchValue !== ''
        ? `${process.env.REACT_APP_URL}/VoipSetting/ByIpAddress?IpAddress='${searchValue}`
        : `${process.env.REACT_APP_URL}/VoipSetting/${PagePagination}/${SizeDataPagination}`,

      { headers: config }
    );

    if (data.data.length === 0) {
      Modal.error({
        title: <p style={{ color: '#FF0000' }}>Pencarian Tidak Ditemukan</p>,

        onOk() {},
      });
    }
    yield put(ActionServerVoip.setListServerVoip(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      const propsError = ErrorMassage('List Server Voip');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
    yield put(changeLoadingFalse());
  }
}

export function* SM_getDataTableServerVoip() {
  yield takeLatest('GET_LIST_SERVER_VOIP', S_getDataTableServerVoip);
}

export function* SM_searchDataTableServerVoip() {
  yield takeLatest('SUBMIT_SEARCH_SERVER_VOIP', S_searchDataTableServerVoip);
}
