import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_checkServerVoip(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const IpServer1 = state.Voip.ServerVoip.IpServer1;
  const IpServer2 = state.Voip.ServerVoip.IpServer2;
  const IpServer3 = state.Voip.ServerVoip.IpServer3;
  const IpServer4 = state.Voip.ServerVoip.IpServer4;
  const IpPort = state.Voip.ServerVoip.IpPort;
  const IpAddress = `${IpServer1}.${IpServer2}.${IpServer3}.${IpServer4}:${IpPort}`;

  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/VoipSetting/testPing/${IpAddress}`,

      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    if (data === true) {
      Modal.success({
        title: (
          <p style={{ color: '#31E116' }}>
            <b>IP Berhasil Tersambung</b>
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>IP Dapat Digunakan</p>
          </div>
        ),
        onOk() {},
      });
    } else {
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            <b>IP Gagal Tersambung</b>
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>IP Tidak Dapat Digunakan</p>
          </div>
        ),
        onOk() {},
      });
    }

    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}

export function* SM_checkServerVoip() {
  yield takeLatest('SUBMIT_CHECK_SERVER_VOIP', S_checkServerVoip);
}
