import * as ActionCustomerAccount from '../../../action/ManageAccount/Customer/ActionCustomerAccount';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_addCustomerAccount() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const IdCustomer = state.Customer.PropertiesCustomer.IdCustomer;
  const codeCustomerAccount = state.Customer.CustomerAccount.Username;
  const FirstName = state.Customer.CustomerAccount.FirstName;
  const firstNameFirstUpper =
    FirstName.charAt(0).toUpperCase() + FirstName.slice(1);
  const LastName = state.Customer.CustomerAccount.LastName;
  const lastNameUpper = LastName.charAt(0).toUpperCase() + LastName.slice(1);
  const PhoneNumber = state.Customer.CustomerAccount.PhoneNumber;
  const Email = state.Customer.CustomerAccount.Email;
  const Address = state.Customer.CustomerAccount.Address;
  const CustomerProductId = state.Customer.CustomerAccount.CustomerProductId;
  const ListCustomerProduct = state.CustomerProduct.ListCustomerProduct;
  const StatusAccount = state.Customer.CustomerAccount.StatusAccount;
  try {
    yield put(toggle.changeLoadingTrue());

    const AllProduct: any[] = [];
    if (CustomerProductId[0] === 'Pilih Semua Produk') {
      for (var i = 0; i < ListCustomerProduct.length; i++) {
        AllProduct.push(ListCustomerProduct[i].id);
      }
    } else {
    }
    const ProductPost =
      CustomerProductId[0] === 'Pilih Semua Produk'
        ? AllProduct
        : CustomerProductId;

    yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Profile/Customer',
      {
        firstName: firstNameFirstUpper,
        lastName: lastNameUpper,
        email: Email,
        phone: PhoneNumber,
        customerId: IdCustomer,
        code: codeCustomerAccount,
        productBlock: ProductPost,
        address: Address,
        username: codeCustomerAccount,
        password: codeCustomerAccount,
        isActive: StatusAccount,
      },
      { headers: config }
    );
    yield put(ActionCustomerAccount.clearDataCustomerAccount());
    yield put(ActionCustomerAccount.getCustomerAccount());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Akun Pelanggan Berhasil Ditambahkan</b>
        </p>
      ),

      onOk() {},
    });
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      const propsError = ErrorMassage('Akun Pelanggan');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* SM_addCustomerAccount() {
  yield takeLatest('ADD_CUSTOMER_ACCOUNT', S_addCustomerAccount);
}
