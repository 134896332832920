import * as ActionAllBranch from '../../../core/action/Branch/ActionAllBranch';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionCourierList from '../../../core/action/ManageAccount/Courier/ActionTableCourier';
import * as ActionPickupProcess from '../../PickUpProcess/Store/PickUpProcessAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsPickUpProcess from '../../PickUpProcess/Selector/PickUpProcessSelector';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { change, getFormSyncErrors, getFormValues } from 'redux-form';
import { connect, useDispatch } from 'react-redux';

import DetailPickUpComponent from '../Component/DetailPickUpComponent';
import { createStructuredSelector } from 'reselect';
import history from '../../../core/utils/History';

function DetailPickUpContainer(props) {
  const {
    componentAction,
    allBranchAction,
    actionPickupProcess,
    pagePagination,
    custId,
    formValue,
  } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (custId === null) {
      history.push('/processPickUp');
    } else {
      actionPickupProcess.getDetailPickUpList();
    }
    // eslint-disable-next-line
  }, [actionPickupProcess]);
  const branchValue = formValue ? formValue.branch : '';
  const showModalAssignCourier = () => {
    allBranchAction.getAllBranch();
    componentAction.openModal('AssignCourier');
  };

  const handleCloseModal = () => {
    allBranchAction.clearBranch();
    actionPickupProcess.clearCourierBranchList();
    actionPickupProcess.clearAllBranchList();
    actionPickupProcess.resetPickupForm();
    componentAction.openModal('AssignCourier');
  };
  const handleCloseModalResultUpload = () => {
    actionPickupProcess.clearResultUpload();
    componentAction.openModal('ResultUpload');
  };

  const showModalUploadForAssign = () => {
    componentAction.openModal('UploadForAssignCourier');
  };

  const handleCloseModalUpload = () => {
    actionPickupProcess.clearFormUpload();
    dispatch(change('assignCourierForm', 'fileCsv', null));
    componentAction.openModal('UploadForAssignCourier');
  };

  const handleGetCourierBranch = () => {
    dispatch(change('assignCourierForm', 'courier', null));
    if (branchValue !== undefined) {
      actionPickupProcess.getCourierBranchList();
    }
  };

  const handleSubmitAssignCourier = () => {
    actionPickupProcess.submitAssignCourier();
  };
  const handleBack = () => {
    history.push('/processPickUp');
  };
  const handleDownload = () => {
    actionPickupProcess.downloadPickupProcessData();
  };
  const handleDownloadResult = () => {
    actionPickupProcess.downloadResult();
  };
  const handleDownloadTemplate = () => {
    actionPickupProcess.downloadTemplatePickupFile();
  };
  const handleUpload = () => {
    actionPickupProcess.uploadForAssignPickupData();
  };
  const handleClearCourier = () => {
    dispatch(change('assignCourierForm', 'courier', null));
  };
  const getBase64 = (file: any) => {
    return new Promise(resolve => {
      let baseURL;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleConvertCsv = event => {
    actionPickupProcess.setFileName(event.name);
    getBase64(event)
      .then((result: any) => {
        event['base64'] = result.toString().split(',')[1];
        actionPickupProcess.setCsvPickupProcessData(result.split(',')[1]);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const looppage = (pagePagination - 1) * 10 + 1;
  const columns = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <b>No</b>
        </div>
      ),
      width: 50,
      render: (text: any, record: any, index: any) => (
        <span>{pagePagination > 1 ? index + looppage : index + 1}</span>
      ),
      key: 'no',
    },
    {
      title: 'Waybill',
      dataIndex: 'waybillCode',
      key: 'waybillCode',
      width: 120,
    },
    {
      title: 'Nama Nasabah',
      dataIndex: 'shipToName',
      key: 'shipToName',
      width: 110,
    },
    {
      title: 'Alamat',
      dataIndex: 'shipToAddress',
      render: (shipToAddress: any) => (
        <span>{`${Object.values(JSON.parse(shipToAddress))
          .join(' ')
          .substring(0, 30)}`}</span>
      ),
      key: 'shipToAddress',
      width: 120,
    },
  ];
  const handlePage = page => {
    componentAction.setPagePagination(page);
    actionPickupProcess.getDetailPickUpList();
  };
  return (
    <React.Fragment>
      <DetailPickUpComponent
        columns={columns}
        handlePage={handlePage}
        handleModal={showModalAssignCourier}
        handleModalUpload={showModalUploadForAssign}
        handleCloseModal={handleCloseModal}
        handleCloseModalUpload={handleCloseModalUpload}
        handleGetCourierBranch={handleGetCourierBranch}
        submitAssignCourier={handleSubmitAssignCourier}
        handleBack={handleBack}
        handleDownload={handleDownload}
        handleConvertCsv={handleConvertCsv}
        handleUpload={handleUpload}
        handleClearCourier={handleClearCourier}
        handleDownloadTemplate={handleDownloadTemplate}
        handleCloseModalResultUpload={handleCloseModalResultUpload}
        handleDownloadResult={handleDownloadResult}
        {...props}
      />
    </React.Fragment>
  );
}

const mapStateToProps = createStructuredSelector({
  isLoading: SelectorsComponent.makeIsLoading(),
  pagePagination: SelectorsComponent.makePagePagination(),
  totalPagination: SelectorsComponent.makeTotalPagePagination(),
  detailPickupList:
    SelectorsPickUpProcess.makeDetailPickUpProcessListSelector(),
  allBranch: SelectorsPickUpProcess.makeAllBranchListSelector(),
  courierBranch: SelectorsPickUpProcess.makeCourierBranchListSelector(),
  resultUpload: SelectorsPickUpProcess.makeResultUploadSelector(),
  custId: SelectorsPickUpProcess.makeCustIdSelector(),
  fileName: SelectorsPickUpProcess.makeFileNameCsvPickupProcessSelector(),
  valueCsvFile: SelectorsPickUpProcess.makeCsvPickupProcessSelector(),
  assignCourierModalIsShow: SelectorsComponent.assignCourierModalIsShow(),
  resultUploadModalIsShow: SelectorsComponent.resultUploadModalIsShow(),
  uploadForAssignModalIsShow: SelectorsComponent.uploadForAssignModalIsShow(),
  formValue: getFormValues('assignCourierForm'),
  errorFormValue: getFormSyncErrors('assignCourierForm'),
});

const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
  allBranchAction: bindActionCreators(ActionAllBranch, dispatch),
  actionPickupProcess: bindActionCreators(ActionPickupProcess, dispatch),
  actionCourierList: bindActionCreators(ActionCourierList, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(DetailPickUpContainer);
