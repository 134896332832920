import { Col, Row, Table } from 'antd';
import React, { Component } from 'react';

import ButtonEdit from '../../../../containers/Content/Customer/Product/ButtonCustomerProductContainer';
import ButtonMaterial from '../../../../materials/ButtonMaterial';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../../materials/ModalMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';

interface IProps {
  getGroupProductId: any;
  getGroupName: any;
  getListGroupName: any;
  visible: any;
  handleClearGroupForm: any;
  putGroupName: any;
  validateGroupName: any;
  helpValidateGroupName: any;
  validateButton: any;
  validateButtonCancel: any;
  handleGetDetailGroupName: any;
  handleSubmit: any;
  handleCancel: any;
  page: any;
  stateValidate: any;
}

export default class GroupingReportComponent extends Component<IProps> {
  render() {
    const lengthGroupName =
      this.props.getGroupName === null ? 0 : this.props.getGroupName.length;
    const validateForm = () => {
      let validate;
      if (this.props.getGroupProductId === null) {
        this.props.getGroupName === '' ||
        this.props.validateGroupName === 'notMinChar' ||
        this.props.validateGroupName === 'error'
          ? (validate = 'error')
          : (validate = '');
        return validate;
      } else {
        this.props.getGroupName === '' ||
        this.props.stateValidate === 'notMinChar'
          ? (validate = 'error')
          : (validate = '');
        return validate;
      }
    };
    const validateNotifForm = () => {
      let validate;
      if (this.props.getGroupProductId === null) {
        validate = this.props.helpValidateGroupName;
        return validate;
      } else {
        if (this.props.getGroupName === '') {
          validate = 'Nama Wajib diisi';
        } else if (lengthGroupName < 3) {
          validate = 'Nama minimal 3 karakter';
        } else {
          validate = '';
        }
        return validate;
      }
    };
    const looppage = (this.props.page - 1) * 10 + 1;
    const columns = [
      {
        title: (
          <div style={{ textAlign: 'center' }}>
            <b>No</b>
          </div>
        ),
        width: 60,
        render: (text: any, record: any, index: any) => (
          <span>{this.props.page > 1 ? index + looppage : index + 1}</span>
        ),
        key: 'no',
      },
      {
        title: <b>Nama</b>,
        dataIndex: 'groupingName',
        key: 'groupingName',
        render: (groupingName: any) => (
          <span style={{ color: 'red' }}>{groupingName}</span>
        ),
        width: 200,
      },
      {
        title: <b>Aksi</b>,
        key: 'action',
        width: 90,
        render: (record: any) => (
          <div>
            <Row>
              <Col>
                <div style={{ float: 'right', paddingRight: '28px' }}>
                  <ButtonEdit
                    idGroupProduct={record.idGroupProduct}
                    groupingName={record.groupingName}
                    idCustomerProduct={''}
                  />
                </div>
              </Col>
            </Row>
          </div>
        ),
      },
    ];
    return (
      <React.Fragment>
        <ModalMaterial
          className={''}
          width={'40%'}
          style={{}}
          teksTitle={'TAMBAHKAN GRUP PELANGGAN'}
          visible={this.props.visible}
          onCancel={this.props.handleClearGroupForm}
          content={
            <SpinMaterial
              spinning={false}
              style={{}}
              size={'large'}
              content={
                <div className="defaultPaddingModal">
                  <Table
                    // rowKey={record => record.id}
                    pagination={false}
                    bordered={false}
                    dataSource={this.props.getListGroupName}
                    columns={columns}
                    className='scrollGroupingTbl'
                  />
                  <Row className='spacingTopDefault'>
                    <span>
                      <b>Nama Grup</b>
                    </span>
                    <div>
                      <FormInputTextMaterial
                        validateType={true}
                        value={this.props.getGroupName}
                        className={'borderRadiusDefault'}
                        disabled={false}
                        addonBefore={''}
                        placeholder={'Masukan nama grup'}
                        prefix={''}
                        maxLength={'30'}
                        style={{}}
                        size=""
                        type={''}
                        onChange={this.props.putGroupName}
                        onBlur={this.props.putGroupName}
                        validatestatus={validateForm()}
                        help={validateNotifForm()}
                        onPressEnter={''}
                      />
                    </div>

                    <div className='floatRight'>
                      <ButtonMaterial
                        disabled={this.props.validateButton}
                        handleSubmit={this.props.handleSubmit}
                        teksButton={
                          this.props.getGroupProductId === null
                            ? 'Simpan'
                            : 'Ubah'
                        }
                        size={''}
                        shape={'round'}
                        className={
                          this.props.validateButton === true
                            ? 'buttonDisableDefault'
                            : 'validateSubmitButtonEnable'
                        }
                        type={'primary'}
                        icon={''}
                        style={{}}
                      />
                    </div>

                    <div className='btnCancelGrouping'>
                      <ButtonMaterial
                        disabled={this.props.validateButtonCancel}
                        handleSubmit={this.props.handleCancel}
                        teksButton={'Batal'}
                        size={''}
                        shape={'round'}
                        className={
                          this.props.validateButtonCancel === true
                            ? 'validateResetButtonDisabled'
                            : 'validateResetButtonEnable'
                        }
                        type={'primary'}
                        style={{}}
                        icon={''}
                      />
                    </div>
                  </Row>
                </div>
              }
            />
          }
        />
      </React.Fragment>
    );
  }
}
