import React, { useState, useEffect } from 'react';

import EditLevelFormComponent from '../Component/EditLevelFormComponent';
import history from '../../../../core/utils/History';
import {
  getLevelGroupSingle,
  updateLevelGroup,
} from 'services/LevelAccessServices';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import ModalStatus from 'materials/ModalStatus';
import { reduceRoleFormat } from 'lib/util';

function EditLevelFromContainer() {
  const { id }: { id: string } = useParams();
  const { data, isLoading: isLoadingSingle } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataLevelGroupSingle', { id }],
    queryFn: ({ queryKey }) => getLevelGroupSingle(queryKey),
  });

  const { mutate: submitLevelGroup, isLoading } = useMutation(
    updateLevelGroup,
    {
      onSuccess: res => {
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Data berhasil diubah',
          });
          setDataLevel(defaultDataLevel);
          history.push('/manageLevelAccount');
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    }
  );

  const defaultDataLevel = {
    levelName: '',
    accountType: '',
    isActive: true,
    menus: [],
  };
  const [dataLevel, setDataLevel] = useState<any>(defaultDataLevel);
  const [dataMenus, setDataAllMenus] = useState<Array<any>>([]);

  useEffect(() => {
    if (data) {
      setDataLevel({
        levelName: data?.data?.levelName || '',
        accountType: data?.data?.accountType || '',
        isActive: data?.data?.isActive,
        menus: data?.data?.selected || [],
      });
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const dataAllMenus = data?.data?.menus || [];
      setDataAllMenus(dataAllMenus || []);
    }
  }, [data]);

  const handleBtnBack = () => {
    history.goBack();
  };

  const handleSubmit = value => {
    const { levelName, isActive, accountType } = value;
    let menusReduce = [];
    if (dataMenus.filter(value => value.permission.view === true).length <= 0) {
      ModalStatus({
        status: 'error',
        title: 'Terjadi Kesalahan!!!',
        content: 'Wajib pilih salah satu group akses',
      });
      return;
    }
    menusReduce = reduceRoleFormat(dataMenus);

    setDataLevel({
      levelName,
      accountType,
      isActive,
    });
    const data = {
      idLevel: id,
      levelName,
      accountType,
      isActive,
      menus: menusReduce,
    };

    submitLevelGroup(data);
  };

  return (
    <EditLevelFormComponent
      handleBtnBack={handleBtnBack}
      handleSubmit={handleSubmit}
      dataAllMenus={dataMenus}
      isLoadingSubmit={isLoading}
      isLoadingMenus={isLoadingSingle}
      dataLevel={dataLevel}
      setDataAllMenus={setDataAllMenus}
    />
  );
}

export default EditLevelFromContainer;
