import instance from 'config/interceptor';
import { FilterManifestBrankasProps } from 'module/Manifest/ManifestBrankas/container';

export const getDataTableBrankas = async ({
  page,
  filterManifestBrankas,
}: {
  page: number;
  filterManifestBrankas: FilterManifestBrankasProps;
}) => {
  try {
    const {
      typeManifest: safeCodeType,
      search: freeTextFilter,
      periodeManifest,
    } = filterManifestBrankas;
    let link = `/ManifestSafeBox/${safeCodeType}/${page}/10?`;
    const [startDate, endDate] = periodeManifest;

    if (freeTextFilter) link = link.concat(`&freeTextFilter=${freeTextFilter}`);
    if (startDate)
      link = link.concat(`&startDate=${startDate.format('DDMMYYYY')}`);
    if (endDate) link = link.concat(`&endDate=${endDate.format('DDMMYYYY')}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const getBundleCode = async () => {
  try {
    return await instance.get(process.env.REACT_APP_URL + `/ManifestSafeBox`);
  } catch (error) {
    return error;
  }
};

export const postManifestBrankas = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/ManifestSafeBox`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const updateManifestBrankas = async data => {
  try {
    return await instance.put(
      process.env.REACT_APP_URL + `/ManifestSafeBox/${data.id}`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const getManifestBrankas = async (id: number | null) => {
  try {
    return await instance.get(
      process.env.REACT_APP_URL + `/ManifestSafeBox/${id}`
    );
  } catch (error) {
    return error;
  }
};

export const getManifestBrankasCounting = async (id: number | null) => {
  try {
    return await instance.get(
      process.env.REACT_APP_URL + `/ManifestSafeBox/Counting/${id}`
    );
  } catch (error) {
    return error;
  }
};

export const postScanManifestBrankas = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/ManifestSafeBox/ScanData`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const deleteScanManifestBrankas = async (id: number) => {
  try {
    return await instance.delete(
      process.env.REACT_APP_URL + `/ManifestSafeBox/ScanData/${id}`
    );
  } catch (error) {
    return error;
  }
};

export const getDataTableScanManifestBrankas = async ({
  page,
  id,
}: {
  page: number;
  id: number | null;
}) => {
  try {
    let link = `/ManifestSafeBox/ScanData/${id}/${page}/5?`;

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const downloadManifestBrankas = async (bundleCode: string) => {
  try {
    let link = `/ManifestSafeBox/Bundle/Download/${bundleCode}`;

    return await instance.get(process.env.REACT_APP_URL + link, {
      responseType: 'arraybuffer',
    });
  } catch (error) {
    return error;
  }
};

export const downloadManifestBrankasScan = async (data: {
  id: number | null;
  type: string;
}) => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL +
        `/ManifestSafeBox/Counting/Download/${data.id}?conditionResultManifest=${data.type}`,
      {},
      {
        responseType: 'arraybuffer',
      }
    );
  } catch (error) {
    return error;
  }
};
