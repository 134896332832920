export function EditBranch()
{
    return({
        type:"EDIT_BRANCH_SUBMIT"
    })
}
export function changeDetailEditBranch()
{
    return({
        type:"CHANGE_DETAIL_EDIT_BRANCH"
    })
}