export function changeCycleDS(CycleDS: any) {
  return {
    type: 'CHANGE_CYCLE_DS',
    CycleDS,
  };
}
export function changeSearchTypeDS(SearchTypeDS: any) {
  return {
    type: 'CHANGE_SEARCH_TYPE_DS',
    SearchTypeDS,
  };
}
export function setFilterTypeDS(FilterTypeDS: any) {
  return {
    type: 'SET_FILTER_TYPE_DS',
    FilterTypeDS,
  };
}
export function setValueTypeDS(ValueTypeDS: any) {
  return {
    type: 'SET_VALUE_TYPE_DS',
    ValueTypeDS,
  };
}
export function setDistrictDS(DistrictDS: any) {
  return {
    type: 'SET_DISTRICT_DS',
    DistrictDS,
  };
}
export function setAttempt(Attempt: any) {
  return {
    type: 'SET_ATTEMPT',
    Attempt,
  };
}
export function setReceiptNumber(ReceiptNumber: any) {
  return {
    type: 'SET_RECEIPT_NUMBER_DS',
    ReceiptNumber,
  };
}
export function setDimention(Dimention: any) {
  return {
    type: 'SET_DIMENTION',
    Dimention,
  };
}
export function setTotalGroupDocument(TotalGroupDocument: any) {
  return {
    type: 'SET_TOTAL_GROUP_DOCUMENT',
    TotalGroupDocument,
  };
}
export function setTotalWeight(TotalWeight: any) {
  return {
    type: 'SET_TOTAL_WEIGHT',
    TotalWeight,
  };
}
export function setKoli(Koli: any) {
  return {
    type: 'SET_KOLI',
    Koli,
  };
}
export function setWaybill(Waybill: any) {
  return {
    type: 'SET_WAYBILL',
    Waybill,
  };
}
export function setDataDocument(DataDocument: any) {
  return {
    type: 'SET_DATA_DOCUMENT_DS',
    DataDocument,
  };
}
export function setWaybillForm(WaybillForm: any) {
  return {
    type: 'SET_WAYBILL_FORM',
    WaybillForm,
  };
}
export function setProcessTime(ProcessTime: any) {
  return {
    type: 'SET_PROCESS_TIME',
    ProcessTime,
  };
}
export function setDeliveryTime(DeliveryTime: any) {
  return {
    type: 'SET_DELIVERY_TIME',
    DeliveryTime,
  };
}
export function setFinishedTime(FinishedTime: any) {
  return {
    type: 'SET_FINISHED_TIME',
    FinishedTime,
  };
}
export function setDateEstimation(DateEstimation: any) {
  return {
    type: 'SET_DATE_ESTIMATION',
    DateEstimation,
  };
}
export function setBarcode(Barcode: any) {
  return {
    type: 'SET_BARCODE',
    Barcode,
  };
}
export function setDocumentType(DocumentType: any) {
  return {
    type: 'SET_DOCUMENT_TYPE',
    DocumentType,
  };
}
export function setDeliveryType(DeliveryType: any) {
  return {
    type: 'SET_DELIVERY_TYPE',
    DeliveryType,
  };
}
export function setDeliveryPosition(DeliveryPosition: any) {
  return {
    type: 'SET_DELIVERY_POSITION',
    DeliveryPosition,
  };
}
export function setCourierCode(CourierCode: any) {
  return {
    type: 'SET_COURIER_CODE',
    CourierCode,
  };
}
export function setCourierName(CourierName: any) {
  return {
    type: 'SET_COURIER_NAME',
    CourierName,
  };
}
export function setOwnerName(OwnerName: any) {
  return {
    type: 'SET_OWNER_NAME',
    OwnerName,
  };
}
export function setNoTelp(NoTelp: any) {
  return {
    type: 'SET_NO_TELP',
    NoTelp,
  };
}
export function setAddress(Address: any) {
  return {
    type: 'SET_ADDRESS',
    Address,
  };
}
export function setCity(City: any) {
  return {
    type: 'SET_CITY',
    City,
  };
}
export function setZipCode(ZipCode: any) {
  return {
    type: 'SET_ZIP_CODE',
    ZipCode,
  };
}
export function setDeliveryDuration(DeliveryDuration: any) {
  return {
    type: 'SET_DELIVERY_DURATION',
    DeliveryDuration,
  };
}
export function setImage(Image: any) {
  return {
    type: 'SET_IMAGE',
    Image,
  };
}
export function setSignature(Signature: any) {
  return {
    type: 'SET_SIGNATURE',
    Signature,
  };
}
export function setImageHistory(ImageHistory: any) {
  return {
    type: 'SET_IMAGE_HISTORY',
    ImageHistory,
  };
}
export function setSignatureHistory(SignatureHistory: any) {
  return {
    type: 'SET_SIGNATURE_HISTORY',
    SignatureHistory,
  };
}
export function setStatusDelivery(StatusDelivery: any) {
  return {
    type: 'SET_STATUS_DELIVERY',
    StatusDelivery,
  };
}
export function setReceiverName(ReceiverName: any) {
  return {
    type: 'SET_RECEIVER_NAME',
    ReceiverName,
  };
}
export function setReceiverTitle(ReceiverTitle: any) {
  return {
    type: 'SET_RECEIVER_TITLE',
    ReceiverTitle,
  };
}
export function setReturnedReason(ReturnedReason: any) {
  return {
    type: 'SET_RETURNED_REASON',
    ReturnedReason,
  };
}
export function setReceiverLocation(ReceiverLocation: any) {
  return {
    type: 'SET_RECEIVER_LOCATION',
    ReceiverLocation,
  };
}
export function setAdditionalData(AdditionalData: any) {
  return {
    type: 'SET_ADDITIONAL_DATA',
    AdditionalData,
  };
}
export function setComplaint(Complaint: any) {
  return {
    type: 'SET_COMPLAINT',
    Complaint,
  };
}
export function setOtherData(OtherData: any) {
  return {
    type: 'SET_OTHER_DATA',
    OtherData,
  };
}
export function setHistory(History: any) {
  return {
    type: 'SET_HISTORY',
    History,
  };
}
export function setExportDeliveryStatus(ExportDeliveryStatus: any) {
  return {
    type: 'SET_EXPORT_DELIVERY_STATUS',
    ExportDeliveryStatus,
  };
}
export function setProductImage(ProductImage: any) {
  return {
    type: 'SET_PRODUCT_IMAGE',
    ProductImage,
  };
}
export function setDeliveryStatusBarcode(DeliveryStatusBarcode: any) {
  return {
    type: 'SET_DELIVERY_STATUS_BARCODE',
    DeliveryStatusBarcode,
  };
}
export function setSearchTypeSubmit(SearchTypeSubmit: any) {
  return {
    type: 'SET_SEARCH_TYPE_SUBMIT',
    SearchTypeSubmit,
  };
}
export function clearDeliveryTimeAll() {
  return {
    type: 'CLEAR_DELIVERY_TIME_ALL',
  };
}
export function clearWaybill() {
  return {
    type: 'CLEAR_WAYBILL_DS',
  };
}
export function clearDeliveryStatusBarcode() {
  return {
    type: 'CLEAR_DELIVERY_STATUS_BARCODE',
  };
}
export function clearDeliveryStatus() {
  return {
    type: 'CLEAR_DELIVERY_STATUS',
  };
}
export function clearImageHistory() {
  return {
    type: 'CLEAR_IMAGE_HISTORY',
  };
}
export function clearSignatureHistory() {
  return {
    type: 'CLEAR_SIGNATURE_HISTORY',
  };
}
export function clearDeliveryStatusEcveptWaybill() {
  return {
    type: 'CLEAR_DELIVERY_STATUS_EXCEPT_WAYBILL',
  };
}
