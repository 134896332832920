import React from 'react';
import styles from './BaggingDocument.module.css';
import { Row, Col } from 'antd';
import { Form, Field } from 'react-final-form';

import SelectField from 'components/SelectField';
import InputDatePicker from 'components/InputDatePicker';
import Button from 'components/Button';
import CardMaterial from 'materials/CardMaterial';
import { DataFilterBaggingType } from 'module/DeliveryBetweenPosts/Component';
import { BagTypes, BagStatus } from 'lib/constants';

type BaggingDocumentProps = {
  dataAllBranch: Array<any>;
  isLoadingBranch?: boolean;
  roleAccount: string;
  handleSubmitFilter: (value: any) => void;
  handleResetFilter: () => void;
  dataFilterBagging: DataFilterBaggingType;
  selectedBranchId: number | null | undefined;
};

export default function BaggingDocumentFilter({
  dataAllBranch,
  isLoadingBranch,
  roleAccount,
  handleSubmitFilter,
  handleResetFilter,
  dataFilterBagging,
  selectedBranchId,
}: BaggingDocumentProps) {
  const isBranch = roleAccount === 'Branch';

  return (
    <CardMaterial
      title={<b style={{ color: '#11BEFF' }}>BAGGING DOKUMEN</b>}
      content={
        <Form
          initialValues={{
            typeBag: dataFilterBagging.BaggingTypes,
            dateInput: dataFilterBagging.CreatedBaggingTime,
            status: dataFilterBagging.BaggingStatusDocument,
            branchFrom: dataFilterBagging.OriginalBranch,
            branchTo: dataFilterBagging.DestinationBranch,
          }}
          validate={values => {
            const errors = {} as any;
            if (!values.dateInput) {
              errors.dateInput = 'Tanggal Input wajib di isi';
            }
            return errors;
          }}
          onSubmit={handleSubmitFilter}
        >
          {({ handleSubmit, submitting, valid, form, values }) => (
            <div className={styles.wrapperFilter}>
              <Row gutter={24}>
                <Col md={12} className={styles.filterColumn}>
                  <Field name="typeBag">
                    {({ input, meta }) => (
                      <SelectField
                        allowClear
                        validate
                        label="Jenis Bag"
                        mode="multiple"
                        placeholder="Pilih Jenis Bag"
                        data={BagTypes}
                        value={input.value}
                        onChange={value => input.onChange(value)}
                        onBlur={value => {
                          input.onBlur(value);
                        }}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                      />
                    )}
                  </Field>
                  <Field name="dateInput">
                    {({ input, meta }) => (
                      <InputDatePicker
                        label="Tanggal Input"
                        placeholder="Pilih Tanggal"
                        onOpenChange={input.onBlur}
                        onChange={value => {
                          input.onChange(value);
                        }}
                        value={input.value}
                        error={
                          meta.touched && meta.error !== undefined && meta.error
                        }
                      />
                    )}
                  </Field>
                  <Field name="status">
                    {({ input, meta }) => (
                      <SelectField
                        allowClear
                        validate
                        label="Status"
                        placeholder="Pilih Status"
                        mode="multiple"
                        data={BagStatus}
                        value={input.value}
                        onChange={value => input.onChange(value)}
                        onBlur={value => {
                          input.onBlur(value);
                        }}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={12} className={styles.filterColumn}>
                  <Field name="branchFrom">
                    {({ input, meta }) => (
                      <SelectField
                        validate
                        label="Posko Asal"
                        placeholder="Pilih Posko"
                        data={dataAllBranch}
                        loading={isLoadingBranch}
                        disabled={isBranch}
                        value={input.value}
                        onChange={value => input.onChange(value)}
                        onBlur={value => {
                          input.onBlur(value);
                        }}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                      />
                    )}
                  </Field>
                  <Field name="branchTo">
                    {({ input, meta }) => (
                      <SelectField
                        validate
                        label="Posko Tujuan"
                        placeholder="Pilih Posko"
                        data={dataAllBranch.filter(
                          value => value.id !== values.branchFrom
                        )}
                        loading={isLoadingBranch}
                        value={input.value}
                        onChange={value => input.onChange(value)}
                        onBlur={value => {
                          input.onBlur(value);
                        }}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
              <div className={styles.actionFilter}>
                <Button
                  text="Reset"
                  variant="danger"
                  className={styles.actionWidth}
                  disabled={!valid}
                  onClick={() => {
                    handleResetFilter();
                    form.reset({
                      typeBag: ['Delivery', 'Return'],
                      status: ['Process', 'Success'],
                      branchFrom: selectedBranchId,
                    });
                  }}
                />
                <Button
                  text="Cari"
                  className={styles.actionWidth}
                  onClick={handleSubmit}
                  disabled={submitting || !valid}
                />
              </div>
            </div>
          )}
        </Form>
      }
      style={{ borderRadius: '10px' }}
    />
  );
}
