import * as ActionLogin from '../../../module/Login/Store';
import * as ActionTableCustomerProduct from '../../action/CustomerProduct/ActionTableCustomerProduct';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  changeLoadingFalse,
  changeLoadingTrue,
} from '../../action/toggle/index';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export default function* S_GetDataTableCustomerProduct(props) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const idCustomer = state.Customer.PropertiesCustomer.IdCustomer;
  let statusIsActiveType = 'Active';
  if (props.payload.isActiveType) {
    statusIsActiveType = props.payload.isActiveType;
  }
  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        `/Customer/product/${idCustomer}/${statusIsActiveType}/0/100`,
      { headers: config }
    );

    yield put(ActionTableCustomerProduct.SetTableCustomerProduct(data.data));
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      yield put(changeLoadingFalse());
      const propsError = ErrorMassage('table pelanggan produk');
      const code = propsError.filter((data: any) =>
        data.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}
export function* SM_GetTableCustomerProduct() {
  yield takeLatest('GET_TABLE_CUSTOMER_PRODUCT', S_GetDataTableCustomerProduct);
}
