import { Layout } from 'antd';
import React, { Component } from 'react';
import iconCCC from '../../../image/iconMobile.png';
import '../../../styles/LoginStyle/index.css';

interface IProps {
  isLoading: any;
}

const { Header, Content, Footer } = Layout;

export default class PrivacyPolicyComponent extends Component<IProps> {
  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Header
          style={{
            backgroundColor: '#11BEFF',
          }}
        >
          <div>
            <img src={iconCCC} 
            alt="icon CCC"
            style={{ width: '80px', marginBottom: 8 }} />
            <b style={{ marginLeft: '32px', color: '#fff', fontSize: '20px' }}>
              Smart Courier
            </b>
          </div>
        </Header>
        <Content style={{ backgroundColor: '#fff' }}>
          <div
            style={{
              paddingLeft: 48,
              paddingRight: 48,
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <span>
              <b style={{ fontSize: 25 }}>Kebijakan Privasi</b>
            </span>
            <p></p>
            <p style={{ textAlign: 'justify' }}>
              Kebijakan Privasi ini menjelaskan bagaimana PT. RDS Express
              mengumpulkan, menyimpan, menggunakan, mengolah, menganalisa,
              menguasai, mentransfer, mengungkapkan, menampilkan, membuka dan
              melindungi setiap data atau informasi pribadi anda yang diberikan
              kepada web aplikasi Courier Command Center dan mobile aplikasi
              Smart Courier dalam penggunaan layanan yang kami sediakan. PT. RDS
              Express dengan serius bertanggung jawab berdasarkan peraturan yang
              berlaku di Indonesia tentang data privasi dan berkomitmen untuk
              menghormati hak dan masalah privasi semua Pengguna. Kami mengakui
              pentingnya Data Pribadi yang telah Anda percayakan kepada kami dan
              merupakan tanggung jawab kami.
            </p>

            <span>
              <b style={{ fontSize: 18 }}>
                Pengumpulan Data Pribadi
              </b>
            </span>
            <p style={{ textAlign: 'justify' }}>
              Mengumpulkan Data Pribadi dengan tujuan untuk mengelola dan
              memperlancar proses penggunaan aplikasi, serta tujuan-tujuan
              lainnya selama diizinkan oleh peraturan. Kami juga dapat
              menggabungkan Data Pribadi yang dikumpulkan dengan Data Pribadi
              lainnya yang kami miliki. Adapun Data Pribadi yang dikumpulkan
              dengan cara yang tercantum di bawah ini:
            </p>

            <div>
              <p style={{ textAlign: 'justify' }}>
                1. Data Pribadi yang diserahkan secara mandiri oleh Pengguna:
              </p>
              <p style={{ marginLeft: '10px' }}>
                - Membuat atau memperbarui akun di aplikasi web Courier Command
                Centre atau aplikasi mobile Smart Courier, termasuk diantaranya
                nama pengguna (username), alamat email, nomor telepon, password,
                alamat, foto, dan lain-lain.
              </p>
              <p style={{ marginLeft: '10px' }}>
                - Mempergunakan Layanan pada aplikasi web Courier Command Center
                atau aplikasi mobile Smart Courier , termasuk data transaksi
                yang detil, diantaranya jenis, jumlah dan keterangan dari
                barang milik Pengguna, informasi terkait pengirim dan penerima,
                jumlah transaksi, tanggal dan waktu transaksi, serta detil
                transaksi lainnya.
              </p>
              <p style={{ marginLeft: '10px' }}>
                - Menggunakan fitur yang membutuhkan izin akses terhadap
                perangkat Pengguna.
              </p>

              <p style={{ textAlign: 'justify' }}>
                2. Data Pribadi yang terekam pada saat Pengguna mempergunakan
                aplikasi web Courier Command Center atau aplikasi mobile Smart
                Courier:
              </p>
              <p style={{ marginLeft: '10px' }}>
                - Data lokasi riil atau perkiraannya seperti alamat IP, lokasi
                Wi-Fi, geo-location, dan sebagainya.
              </p>
              <p style={{ marginLeft: '10px' }}>
                - Data berupa waktu dari setiap aktivitas Pengguna, termasuk
                aktivitas pendaftaran, login, transaksi, dan lain sebagainya.
              </p>
              <p style={{ marginLeft: '10px' }}>
                - Data penggunaan atau preferensi Pengguna, diantaranya
                interaksi Pengguna dalam menggunakan aplikasi web Courier
                Command Centre atau aplikasi mobile Smart Courier, pilihan yang
                disimpan, serta pengaturan yang dipilih. Data tersebut diperoleh
                menggunakan cookies, pixel tags, dan teknologi serupa yang
                menciptakan dan mempertahankan pengenal unik.
              </p>
              <p style={{ marginLeft: '10px' }}>
                - Data perangkat, diantaranya jenis perangkat yang digunakan
                untuk mengakses aplikasi web Courier Command Center atau
                aplikasi mobile Smart Courier, termasuk model perangkat keras,
                sistem operasi dan versinya, perangkat lunak, nama file dan
                versinya, pilihan bahasa, pengenal perangkat unik, pengenal
                iklan, nomor seri, informasi gerakan perangkat, dan informasi
                jaringan seluler.
              </p>
              <p style={{ marginLeft: '10px' }}>
                - Data catatan (log), diantaranya catatan pada server yang
                menerima data seperti alamat IP perangkat, tanggal dan waktu
                akses, fitur aplikasi atau laman yang dilihat, proses kerja
                aplikasi dan aktivitas sistem lainnya, jenis peramban, dan
                layanan pihak ketiga.
              </p>

              <p style={{ textAlign: 'justify' }}>3. Ketentuan tambahan:</p>
              <p style={{ marginLeft: '10px' }}>
                - Saat Anda memberikan data pribadi individu lain kepada kami
                Dalam beberapa situasi, Anda dapat memberikan Data Pribadi orang
                lain (seperti pasangan Anda, anggota keluarga atau teman) kepada
                kami. Misalnya, Anda dapat menambahkannya sebagai kontak
                darurat. Jika Anda memberikan kepada kami Data Pribadi mereka,
                Anda menyatakan dan menjamin bahwa Anda telah dan Anda telah
                memperoleh persetujuan mereka untuk Data Pribadi mereka untuk
                dikumpulkan, digunakan, dan diungkapkan sebagaimana diatur dalam
                Kebijakan ini.
              </p>

              <span>
                <b style={{ fontSize: 18 }}>Penggunaan Data Pribadi</b>
              </span>
              <p style={{ textAlign: 'justify' }}>
                Menggunakan keseluruhan atau sebagian Data Pribadi yang
                diperoleh dan dikumpulkan dari Pengguna sebagaimana disebutkan
                dalam bagian sebelumnya untuk hal-hal sebagai berikut:
              </p>
              <p>
                1. Memproses segala bentuk permintaan, aktivitas maupun
                transaksi yang dilakukan oleh Pengguna melalui aplikasi web
                Courier Command Center atau aplikasi mobile Smart Courier, serta
                menginformasikan Pengguna mengenai peningkatan dan pembaruan
                Layanan.
              </p>
              <p>
                2. Penyediaan fitur-fitur untuk memberikan, mewujudkan,
                memelihara dan memperbaiki Layanan kami.
              </p>
              <p>
                3. Membantu Pengguna pada saat berkomunikasi dengan layanan
                pelanggan, diantaranya untuk.
              </p>
              <p style={{ marginLeft: '10px' }}>
                - Memeriksa dan mengatasi permasalahan Pengguna.
              </p>
              <p style={{ marginLeft: '10px' }}>
                - Mengarahkan pertanyaan Pengguna kepada petugas layanan
                pelanggan yang tepat untuk mengatasi permasalahan dan.
              </p>
              <p style={{ marginLeft: '10px' }}>
                - Mengawasi dan memperbaiki tanggapan layanan pelanggan.
              </p>
              <p>
                4. Melakukan monitoring ataupun investigasi terhadap
                transaksi-transaksi mencurigakan atau transaksi yang terindikasi
                mengandung unsur kecurangan atau pelanggaran terhadap Syarat dan
                Ketentuan atau ketentuan hukum yang berlaku, serta melakukan
                tindakan-tindakan yang diperlukan sebagai tindak lanjut dari
                hasil monitoring atau investigasi transaksi tersebut.
              </p>
              <p>
                5. Melindungi keamanan atau integritas Layanan dan setiap
                fasilitas atau peralatan yang digunakan untuk membuat Layanan
                tersedia.
              </p>
              <p>
                6. Dalam keadaan tertentu, PT. RDS Express mungkin perlu untuk
                menggunakan ataupun mengungkapkan Data Pribadi milik Pengguna
                untuk tujuan penegakan hukum atau untuk pemenuhan persyaratan
                hukum dan peraturan yang berlaku, termasuk dalam hal terjadinya
                sengketa atau proses hukum antara Pengguna dan PT. RDS Express.
              </p>
            </div>

            <span>
              <b style={{ fontSize: 18 }}>Pengungkapan Data Pribadi Pengguna</b>
            </span>
            <p style={{ textAlign: 'justify' }}>
              PT. RDS Express tidak akan menggunakan Data Pribadi anda selain
              untuk tujuan yang telah kami beritahukan kepada anda, atau yang
              mana diijinkan oleh hukum dan peraturan setempat. Kami tidak akan
              menawarkan, menerbitkan, memberikan, menjual, mengalihkan,
              mendistribusikan atau meminjamkan Data Pribadi anda dengan pihak
              lainnya di luar kami untuk tujuan komersial, tanpa meminta
              persetujuan dari anda.
            </p>

            <span>
              <b style={{ fontSize: 18 }}>Pemeliharaan Data Pribadi Pengguna</b>
            </span>
            <p style={{ textAlign: 'justify' }}>
              Kami akan memelihara Data Pribadi anda selama anda belum menghapus
              akun anda di dalam aplikasi kami. Ketika Data Pribadi anda tidak
              diperlukan untuk penyediaan Layanan atau tujuan penggunaan Data
              Pribadi, atau kami tidak lagi memiliki tujuan baik hukum maupun
              bisnis untuk memelihara Data Pribadi anda, kami mengambil tindakan
              untuk mencegah akses atau penggunaan Data Pribadi tersebut untuk
              tujuan apapun selain dari pemenuhan kewajiban kami terhadap
              Kebijakan Privasi ini, atau tujuan keamanan, pencegahan dan
              deteksi penipuan.
            </p>

            <span>
              <b style={{ fontSize: 18 }}>
                Penyangkalan Mengenai Keamanan dan Situs Pihak Ketiga
              </b>
            </span>
            <p style={{ textAlign: 'justify' }}>
              PT RDS Express tidak menjamin keamanan Data Pribadi dan/atau
              informasi lainnya yang anda berikan pada situs pihak ketiga.
              Terkait akses situs lainnya tersebut, anda bertanggungjawab atas
              risiko anda sendiri.
            </p>

            <span>
              <b style={{ fontSize: 18 }}>Penyimpanan Informasi</b>
            </span>
            <p style={{ textAlign: 'justify' }}>
              Data Pribadi anda yang kami terima dapat disimpan, ditransfer,
              atau diolah oleh penyedia layanan pihak ketiga. Kami akan
              menggunakan semua upaya yang wajar untuk memastikan bahwa semua
              penyedia layanan pihak ketiga tersebut diwajibkan untuk memberikan
              tingkat perlindungan yang sebanding dengan komitmen kami
              berdasarkan Kebijakan Privasi ini.
            </p>

            <span>
              <b style={{ fontSize: 18 }}>Pembaruan Kebijakan Privasi</b>
            </span>
            <p style={{ textAlign: 'justify' }}>
              PT. RDS Express dapat sewaktu-waktu melakukan perubahan atau
              pembaruan terhadap Kebijakan Privasi ini. Kami menyarankan agar
              Pengguna membaca dan memahami ketentuan dalam Kebijakan Privasi
              ini dari waktu ke waktu untuk mengetahui perubahan apapun. Dengan
              tetap mengakses dan menggunakan Situs maupun Layanan kami lainnya,
              maka Pengguna dianggap menyetujui perubahan-perubahan dalam
              Kebijakan Privasi ini.
            </p>

            <span>
              <b style={{ fontSize: 18 }}>Layanan Pengguna</b>
            </span>
            <p style={{ textAlign: 'justify' }}>
              Jika Anda memiliki pertanyaan mengenai Data Pribadi anda, mohon
              hubungi:
            </p>
            <p style={{ marginLeft: '10px' }}>PT. RDS Express</p>
            <p style={{ marginLeft: '10px' }}>Jl. Kolonel Sugino No. 15B, Duren Sawit, Jakarta Timur 13440</p>
            <p style={{ marginLeft: '10px' }}>021- 28535674</p>
          </div>
        </Content>
        <Footer>Copyright ©2020 PT. RDS Express</Footer>
      </Layout>
    );
  }
}
