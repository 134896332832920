import React, { Component } from 'react';
import CardMaterial from '../../../materials/CardMaterial';
import ButtonMaterial from '../../../materials/ButtonMaterial';
import TableDefaultDataStatusContainer from '../../../containers/Content/Customer/TableDefaultDataStatusContainer';
import TableCustomDataStatusContainer from '../../../containers/Content/Customer/TableCustomDataStatusContainer';
import ModalMaterial from '../../../materials/ModalMaterial';
import {Row, Col, Select, Radio} from 'antd';

interface IProps
{
    handlePutStatus:any
    ValueReasonDeliveryStatus:any
    GetReasonDeliveryStatus:any
    GetCustomerName:any
    disabledRadiobutton:any
    radioButtonValue:any
    handleRadiobutton:any
    handleGetStatusCustomer:any
    handleSubmitDefaultStatus:any
    lastAccessFiture:any
    visible:any
    handleClear:any
    handleBack:any
}

export default class CustomerStatusConfigComponent extends Component <IProps> {
    render() {
        const { Option } = Select;
        
        return (
            <div>
                <div style={{paddingTop:'10px'}}>
                <CardMaterial
                        extra={""}
                        title={<span style={{color:'#11BEFF'}}><b>STATUS PENGIRIMAN</b>
                        <ButtonMaterial
                            disabled={""}
                            handleSubmit={this.props.handleBack}
                            teksButton={<b>Kembali</b>}
                            size={"small"}
                            shape={"round"}
                            className={""}
                            type={"primary"}
                            icon={""}
                            style={{
                                float:'right',
                                colorFont:'#11BEFF',
                                height:"30px",
                                // borderRadius:'12px',
                                background:"#F5487D",
                                borderColor:'#F5487D'}}
                            />
                        </span>}
                        style={{borderRadius:'10px'}}
                        content={
                        <div>
                        <Row>
                            <div>
                            <b>Pilih Status</b>
                            </div>
                            <div>
                            <Select
                            style={{width:'30%', borderRadius:'10px', paddingTop:'5px'}}
                                optionFilterProp="children"
                                showSearch
                                value={this.props.ValueReasonDeliveryStatus}
                                onChange={this.props.handlePutStatus}
                                onSelect={this.props.handleGetStatusCustomer}
                                >
                                    {
                                        <Option key={''} value={'Success'}>{'Diterima'}</Option>
                                    }
                                    {
                                        <Option key={''} value={'Return'}>{'Dikembalikan'}</Option>
                                    }
                            </Select>
                            </div>
                        </Row>
                        <Row style={{paddingTop:'10px'}}>
                            <Col>
                            <Radio.Group onChange={this.props.handleRadiobutton} value={this.props.radioButtonValue}>
                                <Radio value={1} disabled={this.props.disabledRadiobutton}>Default</Radio>
                                <Radio value={2} disabled={this.props.disabledRadiobutton}>Custom</Radio>
                            </Radio.Group>
                            <ModalMaterial
                            className={''}
                            width={'40%'}
                            style={{}}
                            teksTitle={"Apakah anda yakin ?"}
                            visible={this.props.visible}
                            onCancel={this.props.handleClear}
                                content={
                                    <Row>
                                    <div>
                                        <div style={{marginTop:'30px', textAlign:'center'}}>
                                            <span>Jika anda memilih default maka status custom akan dihapus</span>
                                        </div>

                                        <Col>
                                        <div style={{marginTop:'50px',float:'right', paddingRight:'20px'}}>
                                        
                                        <ButtonMaterial
                                            disabled={""}
                                            handleSubmit={this.props.handleSubmitDefaultStatus}
                                            teksButton={<b>Ya</b>}
                                            size={"small"}
                                            shape={""}
                                            className={""}
                                            type={"primary"}
                                            icon={""}
                                            style={{
                                                colorFont:'#11BEFF',
                                                height:'30px',
                                                width:'60px',
                                                borderRadius:'12px',}}
                                            />
                                        
                                        </div>
                                        <div style={{marginTop:'50px',paddingRight:'20px',float:'right'}}>

                                        <ButtonMaterial
                                            disabled={""}
                                            handleSubmit={this.props.handleClear}
                                            teksButton={<b>Tidak</b>}
                                            size={"small"}
                                            shape={""}
                                            className={""}
                                            type={"primary"}
                                            icon={""}
                                            style={{
                                                colorFont:'#11BEFF',
                                                height:"30px",
                                                width:'60px',
                                                borderRadius:'12px',
                                                background:"#F5487D",
                                                borderColor:'#F5487D',
                                            }}
                                        />

                                        </div>
                                        </Col>
                                    </div>
                                    </Row>
                                }/>
                            </Col>
                        </Row>
                        </div>
                        }
                        
                />
                </div>
                <div style={{paddingTop:'10px'}}>
                
                {this.props.lastAccessFiture==='defaultDataStatus'?
                <TableDefaultDataStatusContainer/>:
                this.props.lastAccessFiture==='customDataStatus'?
                <TableCustomDataStatusContainer/>:
                <CardMaterial
                extra={""}
                title={""}
                style={{}}
                content={
                        <div>
                            <Row>
                            <Col>
                                <div style={{ float:'left',color: '#11BEFF',}}>
                                <b>{this.props.GetCustomerName}</b>
                                </div>
                            </Col>   
                            </Row>
                        </div>
                        }
                />
                }
                </div>
            </div>
        )
    }
}
