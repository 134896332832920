import React from 'react';

import ActivationVoipContainer from '../../../containers/Content/Customer/Voip/ActivationVoipContainer';
import CustomerConfigFitureContainer from '../../../containers/Content/Customer/CustomerConfigFitureContainer';
import CustomerCustomCsvContainer from '../../../containers/Content/Customer/CustomCsvCustomerContainer';
import CustomerDetailConfigReportContainer from '../../../containers/Content/Customer/CustomerDetailConfigReportContainer';
import CustomerStatusConfigContainer from '../../../containers/Content/Customer/CustomerStatusConfigContainer';
import FormCsvCustomerContainer from '../../../containers/Content/Customer/FormCsvCustomerContainer';
import CustomerReport from 'module/Customer/Report/container';
import TemplateUpload from 'module/Customer/TemplateUpload/container';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

interface IProps {
  handleChangeTabsCustomer: any;
  lastAccess: any;
}

export default function IndexCustomerConfigComponent(props: IProps) {
  const { handleChangeTabsCustomer, lastAccess } = props;
  let a = '1';

  if (lastAccess === 'configReport' || lastAccess === 'DetailConfigReport') {
    a = '4';
  } else if (lastAccess === 'voipSetting') {
    a = '3';
  } else if (
    lastAccess === 'csvSetting' ||
    lastAccess === 'DetailCustomCsv' ||
    lastAccess === 'EditCustomCsv' ||
    lastAccess === 'AddCustomCsv'
  ) {
    a = '2';
  } else {
    a = '1';
  }

  return (
    <div>
      <Tabs
        style={{ padding: '35px' }}
        defaultActiveKey={a}
        onChange={handleChangeTabsCustomer}
      >
        <TabPane tab={<b>Status Pengiriman</b>} key="1">
          <CustomerStatusConfigContainer />
        </TabPane>
        <TabPane tab={<b>Template Unggah</b>} key="2">
          {lastAccess === 'DetailCustomCsv' ||
          lastAccess === 'EditCustomCsv' ? (
            <CustomerCustomCsvContainer />
          ) : lastAccess === 'AddCustomCsv' ? (
            <FormCsvCustomerContainer />
          ) : (
            <TemplateUpload />
          )}
        </TabPane>
        <TabPane tab={<b>Fitur</b>} key="3">
          <CustomerConfigFitureContainer />
        </TabPane>
        <TabPane tab={<b>VOIP</b>} key="4">
          <ActivationVoipContainer />
        </TabPane>
        <TabPane
          tab={
            <b>
              {lastAccess === 'DetailConfigReport'
                ? 'Laporan/Pengaturan'
                : 'Laporan'}
            </b>
          }
          key="5"
        >
          {lastAccess === 'DetailConfigReport' ? (
            <CustomerDetailConfigReportContainer />
          ) : (
            <CustomerReport />
          )}
        </TabPane>
      </Tabs>
    </div>
  );
}
