import * as ActionPagination from '../../../../core/action/Pagination/ActionPagination';
import * as ActionTableCustomer from '../../../../core/action/Customer/ActionTableCustomer';
import * as ActionToggle from '../../../../core/action/toggle/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import TableManageCustomerComponent from '../../../../components/Content/ManageAccount/Customer/TableManageCustomerComponent';
import { connect } from 'react-redux';

interface IProps {
  data: any;
  isLoading: any;
  actionTableCustomer: any;
  actionToggle: any;
  actionPagination: any;
  PagePagination: any;
  TotalPagePagination: any;
  ValueSearch: any;
}

interface IState {
  sRT: any;
  dataSource: any;
  nameSearch: any;
  page: any;
}

class TableManageCustomerContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      sRT: null,
      dataSource: this.props.data,
      nameSearch: false,
      page: 0,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleResetSearch = this.handleResetSearch.bind(this);
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    this.handlePage = this.handlePage.bind(this);
  }

  handlePage(event: any) {
    this.props.actionPagination.setPagePagination(event);
    this.props.actionTableCustomer.GetTableCustomer();
  }
  handleSearch(event: any) {
    if (event.target.value.length === 0) {
      this.props.actionToggle.changeValueSearch(null);
      this.props.actionTableCustomer.GetTableCustomer();
    } else {
      this.props.actionToggle.changeValueSearch(
        event.target.value.replace(/^\s/, '')
      );
    }
  }
  handleSubmitSearch() {
    if (this.props.ValueSearch === null || this.props.ValueSearch === '') {
    } else {
      this.props.actionPagination.setPagePagination(0);
      this.props.actionTableCustomer.SubmitSearchCustomer();
    }
  }
  handleResetSearch(clearFilters: any) {
    clearFilters();
    this.setState({ sRT: null });
  }
  componentWillMount() {
    this.props.actionTableCustomer.GetTableCustomer();
  }
  render() {
    return (
      <React.Fragment>
        <TableManageCustomerComponent
          data={this.props.data}
          page={this.state.page}
          nameSearch={this.state.nameSearch}
          dataSource={this.state.dataSource}
          isLoading={this.props.isLoading}
          ValueSearch={this.props.ValueSearch}
          handlePage={this.handlePage}
          handleSearch={this.handleSearch}
          handleResetSearch={this.handleResetSearch}
          handleSubmitSearch={this.handleSubmitSearch}
          PagePagination={this.props.PagePagination}
          TotalPagePagination={this.props.TotalPagePagination}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  ValueSearch: state.Toggle.ValueSearch,
  PagePagination: state.Pagination.PagePagination,
  TotalPagePagination: state.Pagination.TotalPagePagination,
  data: state.Table.TableCustomer,
  isLoading: state.Toggle.isLoading,
  jenisToggle: state.Toggle.jenisToggle,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionTableCustomer: bindActionCreators(ActionTableCustomer, dispatch),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionPagination: bindActionCreators(ActionPagination, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TableManageCustomerContainer);
