import * as LoginAction from 'module/Login/Store/index';

import { bindActionCreators, compose } from 'redux';

import LoginComponent from 'module/Login/Component/LoginComponent';
import React from 'react';
import { connect } from 'react-redux';

interface IProps {
  authLoginAction: any;
  isLoading: boolean;
}

function LoginContainer({ authLoginAction, isLoading }: IProps) {
  function handleSubmit(e) {
    e.preventDefault();
    authLoginAction.submitLogin();
  }
  return (
    <div>
      <LoginComponent handleSubmit={handleSubmit} isLoading={isLoading} />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  authLoginAction: bindActionCreators(LoginAction, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(LoginContainer);
