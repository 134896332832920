import '../../../../styles/TableStyle/index.css';
import 'moment/locale/id';

import { Button, Table } from 'antd';
import React, { Component } from 'react';

import ButtonDowloadContainer from '../../../../containers/Content/Manifest/CourierManifest/ButtonDowloadContainer';
import SpinMaterial from '../../../../materials/SpinMaterial';
import moment from 'moment';

moment.locale('id');

interface IProps {
  isLoading: any;
  TableDeliveryData: any;
  ListCourierManifest: any;
  Count: any;
  BranchId: any;
  handleReset: any;
  handlePage: any;
  page: any;
  handleDownload: any;
}
export default class TableCourierManifestComponent extends Component<IProps> {
  render() {
    let looppage = (this.props.page - 1) * 10 + 1;

    const columns = [
      {
        title: <b>No</b>,
        render: (text: any, record: any, index: any) => (
          <span>{this.props.page > 1 ? index + looppage : index + 1}</span>
        ),
        key: 'no',
        width: 40,
      },
      {
        title: <b>Tanggal</b>,
        dataIndex: 'itemDeliveryTime',
        key: 'itemDeliveryTime',
        render: (itemDeliveryTime: any) => (
          <span>{moment(itemDeliveryTime).format('YYYY-MM-DD')}</span>
        ),
        width: 120,
      },
      {
        title: <b>Posko</b>,
        dataIndex: 'branch',
        key: 'branch',
        render: (branch: any) => <span style={{ color: 'red' }}>{branch}</span>,
        width: 120,
      },
      {
        title: <b>Kurir</b>,
        dataIndex: 'courier',
        key: 'courier',
        render: (courier: any) => (
          <span>
            {this.props.BranchId === null ||
            this.props.BranchId === undefined ||
            this.props.BranchId === 0
              ? ''
              : courier}
          </span>
        ),
        width: 120,
      },
      {
        title: <b>Jumlah Dokumen</b>,
        dataIndex: 'countDocument',
        key: 'countDocument',
        render: (countDocument: any) => (
          <span style={{ color: 'red' }}>
            {countDocument.replace('Document', 'Dokumen')}
          </span>
        ),
        width: 120,
      },
      {
        title: <b>Pilih</b>,
        key: 'pilih',
        width: 120,
        render: (record: any) => (
          <div style={{marginRight:57}}>
          <ButtonDowloadContainer
            deliveryDate={record.itemDeliveryTime}
            id={record.id}
            idBranch={record.idBranch}
            idCourier={record.idCourier}
          />
          </div>
        ),
      },
    ];
    return (
      <div>
        <SpinMaterial
          spinning={this.props.isLoading}
          style={{}}
          size={'large'}
          content={
            <div>
              <Table
                pagination={{
                  onChange: page => {
                    this.props.handlePage(page);
                  },
                  pageSize: 10,
                }}
                bordered={false}
                footer={() => (
                  <React.Fragment>
                    {this.props.Count === 0 || this.props.Count === null ? (
                      ''
                    ) : (
                      <div>
                        <span>
                          Total Yang Dipilih ({' '}
                          <span
                            style={{ color: '#31E116', fontWeight: 'bold' }}
                          >
                            {this.props.Count === null ? '0' : this.props.Count}
                          </span>{' '}
                          )
                        </span>
                        <Button
                          onClick={this.props.handleDownload}
                          disabled={
                            this.props.Count === null || this.props.Count === 0
                              ? true
                              : false
                          }
                          style={{
                            paddingBottom: '3px',
                            borderColor: '#7956EE',
                            background: '#7956EE',
                            width: '100px',
                            float: 'right',
                            marginLeft: '15px',
                          }}
                          type="primary"
                          shape="round"
                        >
                          Download
                        </Button>
                        <Button
                          onClick={this.props.handleReset}
                          disabled={
                            this.props.Count === null || this.props.Count === 0
                              ? true
                              : false
                          }
                          style={{
                            paddingBottom: '3px',
                            borderColor: 'red',
                            background: 'red',
                            width: '100px',
                            float: 'right',
                          }}
                          type="primary"
                          shape="round"
                        >
                          Reset
                        </Button>
                      </div>
                    )}
                  </React.Fragment>
                )}
                dataSource={this.props.TableDeliveryData}
                columns={columns}
              />
            </div>
          }
        ></SpinMaterial>
      </div>
    );
  }
}
