import React from 'react';
import { Form, Field } from 'react-final-form';
import styles from './ModalEditCodeRetur.module.css';
import SelectField from 'components/SelectField';
import Button from 'components/Button';
import ModalMaterial from 'materials/ModalMaterial';
import { SelectOptionsInterface } from 'interface/general';

type ModalEditCodeReturComponentProps = {
  onCancel: () => void;
  onSave: () => void;
  onClickAddRetur: () => void;
  isShow: boolean;
  loadingManifestRetur: boolean;
  dataManifestRetur: SelectOptionsInterface[];
  manifestSafeCode: string;
  setManifestSafeCode: (value: string) => void;
};

export default function ModalEditCodeReturComponent({
  onCancel,
  onSave,
  onClickAddRetur,
  isShow,
  dataManifestRetur,
  setManifestSafeCode,
  manifestSafeCode,
  loadingManifestRetur,
}: ModalEditCodeReturComponentProps) {
  return (
    <ModalMaterial
      teksTitle="EDIT KODE BRANKAS"
      visible={isShow}
      width="35%"
      onCancel={onCancel}
      content={
        <Form
          onSubmit={onSave}
          initialValues={{
            manifestSafeCode: manifestSafeCode,
          }}
          validate={values => {
            const errors = {} as any;
            if (!values.manifestSafeCode) {
              errors.manifestSafeCode = 'Kode brankas wajib diisi';
            }
            return errors;
          }}
        >
          {({ handleSubmit, invalid }) => (
            <form>
              <div className={styles.wrapperContent}>
                <Field name="manifestSafeCode">
                  {({ input, meta }) => (
                    <div className={styles.wrapperSelectField}>
                      <SelectField
                        label="Kode Brankas"
                        placeholder="Masukan kode brankas"
                        data={dataManifestRetur}
                        value={input.value || undefined}
                        onChange={value => {
                          input.onChange(value);
                          setManifestSafeCode(value);
                        }}
                        onBlur={value => {
                          input.onBlur(value);
                          setManifestSafeCode(value);
                        }}
                        validate
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                        loading={loadingManifestRetur}
                      />
                      <p
                        onClick={onClickAddRetur}
                        className={styles.textAddKodeRetur}
                      >
                        Tambah Kode Baru
                      </p>
                    </div>
                  )}
                </Field>
                <Button
                  text="Simpan"
                  size="small"
                  borderRadius="small"
                  onClick={handleSubmit}
                  htmlType="submit"
                  disabled={invalid}
                />
              </div>
            </form>
          )}
        </Form>
      }
    />
  );
}
