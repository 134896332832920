import React, { Component } from 'react'
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import CustomCsvCustomerComponent from '../../../components/Content/Customer/CustomCsvCustomerComponent';
import *as ActionDetailCustomCsvCustomer from '../../../core/action/Customer/ActionDetailCustomCsvCustomer';

interface IProps{
    actionDetailCustomCsvCustomer:any
    isLoading:any
}
class CustomCsvCustomerContainer extends Component<IProps> {
    
    componentWillMount()
    {
        this.props.actionDetailCustomCsvCustomer.getDetailCustomCsvCustomer()
    }
    render() {
        return (
            <div>
                <CustomCsvCustomerComponent
                    isLoading={this.props.isLoading}/>
            </div>
        )
    }
}
const mapStateProps = (state:any) => ({
    isLoading:state.Toggle.isLoading,

})

const mapDispatchToProps = (dispatch:any) => ({
    actionDetailCustomCsvCustomer : bindActionCreators(ActionDetailCustomCsvCustomer, dispatch),
})

const withConnect = connect(mapStateProps, mapDispatchToProps)

export default compose(
    withConnect
)(CustomCsvCustomerContainer)

