export function changeClearToken() {
  return {
    type: 'CHANGE_CLEAR_TOKEN',
  };

}
export function changeClearTokenRefresh() {
  return {
    type: 'CHANGE_CLEAR_TOKEN_REFRESH',
  };
}

export function changeClearUsernameAuth() {
  return {
    type: 'CHANGE_CLEAR_USERNAME_AUTH',
  };
}

export function changeClearDataProfileAuth() {
  return {
    type: 'CHANGE_CLEAR_DATA_PROFILE_AUTH',
  };
}

// MENGGUNAKAN REDUCER AUTH
