import { Action } from 'redux';

export interface IFetchPickUpListAction extends Action {
  data: object;
}
export interface ISetCustIdAction extends Action {
  data: object;
}
export interface IFetchResultUploadAction extends Action {
  data: object;
}
export interface ISetFileNameAction extends Action {
  data: object;
}
export interface ISetCsvPickupProcessDataAction extends Action {
  data: object;
}
export function fetchPickUpList(data: object): IFetchPickUpListAction {
  return {
    type: 'FETCH_PICKUP_LIST',
    data,
  };
}

export function fetchDetailPickUpList(data: object): IFetchPickUpListAction {
  return {
    type: 'FETCH_DETAIL_PICKUP_LIST',
    data,
  };
}

export function fetchAllBranchList(data: object): IFetchPickUpListAction {
  return {
    type: 'FETCH_ALL_BRANCH_LIST',
    data,
  };
}

export function fetchCourierBranchList(data: object): IFetchPickUpListAction {
  return {
    type: 'FETCH_COURIER_BRANCH_LIST',
    data,
  };
}

export function setCustId(data: object): ISetCustIdAction {
  return {
    type: 'SET_CUST_ID_PICKUP',
    data,
  };
}
export function fetchResultUpload(data: object): IFetchResultUploadAction {
  return {
    type: 'FETCH_RESULT_UPLOAD_PICKUP',
    data,
  };
}
export function setFileName(data: object): ISetFileNameAction {
  return {
    type: 'SET_FILE_NAME_ASSIGN_CSV',
    data,
  };
}
export function setCsvPickupProcessData(
  data: object
): ISetCsvPickupProcessDataAction {
  return {
    type: 'SET_PICKUP_PROCESS_DATA_CSV',
    data,
  };
}

export function getPickUpCardList() {
  return {
    type: 'GET_PICKUP_LIST_REQUESTED',
  };
}

export function getDetailPickUpList() {
  return {
    type: 'GET_DETAIL_PICKUP_LIST_REQUESTED',
  };
}

export function getCourierBranchList() {
  return {
    type: 'GET_COURIER_BRANCH_LIST_REQUESTED',
  };
}

export function submitAssignCourier() {
  return {
    type: 'SUBMIT_ASSIGN_COURIER_REQUESTED',
  };
}

export function resetPickupForm() {
  return {
    type: 'RESET_PICKUP_FORM',
  };
}
export function clearFormUpload() {
  return {
    type: 'CLEAR_FORM_UPLOAD_PICKUP_PROCESS',
  };
}
export function clearFileName() {
  return {
    type: 'CLEAR_FILE_NAME_PICKUP_PROCESS',
  };
}
export function clearCourierBranchList() {
  return {
    type: 'CLEAR_COURIER_BRANCH_PICKUP_PROCESS',
  };
}
export function clearAllBranchList() {
  return {
    type: 'CLEAR_ALL_BRANCH_PICKUP_PROCESS',
  };
}
export function clearPickupProcessData() {
  return {
    type: 'CLEAR_PICKUP_PROCESS_DATA',
  };
}
export function clearResultUpload() {
  return {
    type: 'CLEAR_RESULT_UPLOAD_PICKUP_PROCESS',
  };
}
export function downloadPickupProcessData() {
  return {
    type: 'DOWNLOAD_PICKUP_DATA_REQUESTED',
  };
}
export function downloadTemplatePickupFile() {
  return {
    type: 'DOWNLOAD_TEMPLATE_PICKUP_FILE_REQUESTED',
  };
}
export function downloadResult() {
  return {
    type: 'DOWNLOAD_RESULT_UPLOAD_PICKUP_FILE_REQUESTED',
  };
}
export function uploadForAssignPickupData() {
  return {
    type: 'UPLOAD_FOR_ASSIGN_PICKUP_DATA_REQUESTED',
  };
}
