import * as ActionCustomerManifest from '../../../../core/action/Manifest/CustomerManifest/ActionCustomerManifest';
import * as toggle from '../../../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import ButtonDownloadComponent from '../../../../components/Content/Manifest/CustomerManifest/ButtonDownloadComponent';
import { connect } from 'react-redux';

interface IProps {
  scanTime: any;
  userCode: any;
  actionToggle: any;
  actionCustomerManifest: any;
  handleStatusTreatmentDocument: any;
}

interface IState {}

class ButtonDownloadContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {};

    this.handleChangeAndPopUpModal = this.handleChangeAndPopUpModal.bind(this);
  }
  handleChangeAndPopUpModal() {
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle('FormDownloadCustomerManifest');
    this.props.actionCustomerManifest.changeListCustomerManifestForm([
      { scanTime: this.props.scanTime, userCode: this.props.userCode },
    ]);
    this.props.handleStatusTreatmentDocument();
  }
  render() {
    return (
      <React.Fragment>
        <ButtonDownloadComponent
          handleChangeAndPopUpModal={this.handleChangeAndPopUpModal}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  TypeToggle: state.Toggle.jenisToggle,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(toggle, dispatch),
  actionCustomerManifest: bindActionCreators(ActionCustomerManifest, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ButtonDownloadContainer);
