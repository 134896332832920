import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';
import { Calendar } from 'antd';
import ModalMaterial from '../../../../materials/ModalMaterial';
import locale from 'antd/es/date-picker/locale/id_ID';

interface IProps
{
    handleSubmitDeliveryDate:any
    visible:any
    handleClearModal:any
    deliveryDate:any
    handlePutDeliveryDate:any
}

export default class FormSetDeliveryDateComponent extends Component <IProps> {
    render() {
        return (
            <React.Fragment>
                <ModalMaterial
                className={''}
                width={'30%'}
                style={{}}
                teksTitle={'Atur Tanggal Kirim'}
                visible={this.props.visible}
                onCancel={this.props.handleClearModal}
                    content={
                        <div style={{padding:25}}>
                            <Calendar
                                locale={locale}
                                fullscreen={false} 
                                value={this.props.deliveryDate}
                                onChange={this.props.handlePutDeliveryDate}
                            />
                            <ButtonMaterial
                                disabled={false}
                                handleSubmit={this.props.handleSubmitDeliveryDate}
                                teksButton={<b>Simpan</b>}
                                size={"middle"}
                                shape={"round"}
                                className={""}
                                type={"primary"}
                                icon={""}
                                style={
                                    {
                                        background:'#7956EE',
                                        borderColor:'#7956EE',
                                        float:"right",
                                        height:"35px",
                                        width:"100px",
                                        color: '#FFFFFF'
                                    }
                                }
                            />
                        </div>
                    }/>
            </React.Fragment>
        )
    }
}
