import { Checkbox, Col, Row } from 'antd';
import React from 'react';

import InputNumberField from 'components/InputNumberField';
import ButtonMaterial from '../../../materials/ButtonMaterial';
import CardMaterial from '../../../materials/CardMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';

interface IProps {
  handleCheckboxSmsDelivery: any;
  handleCheckboxSmsDelivered: any;
  handleCheckboxTelepon: any;
  handleSubmitFiture: any;
  handleEditFiture: any;
  handleBack: any;
  handleCancel: any;
  putSenderAlias: any;
  putSmsDeliveryBody: any;
  putSmsDeliveredBody: any;
  GetSmsDelivery: any;
  GetSmsDelivered: any;
  GetSenderAlias: any;
  GetSmsDeliveryBody: any;
  GetSmsDeliveredBody: any;
  validateSenderAlias: any;
  validateSmsDeliveryBody: any;
  validateSmsDeliveredBody: any;
  helpSenderAlias: any;
  helpSmsDeliveryBody: any;
  helpSmsDeliveredBody: any;
  messageDeliverySms: any;
  messageDeliveredSms: any;
  senderAliasStatus: any;
  GetCall: any;
  GetCustomerName: any;
  ValidateButton: any;
  typeButton: any;
  isLoading: any;
  IsRadius: boolean;
  IsRequestPhone: boolean;
  IsHistory: boolean;
  RadiusMeters: number;
  isMonthFilter: boolean;
  searchMonthFilter: number;
  handleChangeRadiusMeters: (value: number) => void;
  handleCheckboxIsRadius: (value: any) => void;
  handleCheckboxIsRequestPhone: (value: any) => void;
  handleCheckboxIsHistory: (value: any) => void;
  handleChangeIsMonthFilter: (value: any) => void;
  handleChangeSearchMonthFilter: (value: any) => void;
}

export default function CustomerConfigFitureComponent(props: IProps) {
  const {
    isLoading,
    typeButton,
    handleEditFiture,
    handleBack,
    handleCancel,
    handleCheckboxSmsDelivery,
    handleCheckboxSmsDelivered,
    handleCheckboxTelepon,
    handleSubmitFiture,
    GetSmsDelivery,
    GetSmsDelivered,
    GetCall,
    GetCustomerName,
    ValidateButton,
    IsRadius,
    IsRequestPhone,
    IsHistory,
    RadiusMeters,
    handleChangeRadiusMeters,
    handleCheckboxIsRadius,
    handleCheckboxIsRequestPhone,
    handleCheckboxIsHistory,
    isMonthFilter,
    searchMonthFilter,
    handleChangeIsMonthFilter,
    handleChangeSearchMonthFilter,
  } = props;
  return (
    <div>
      <SpinMaterial
        spinning={isLoading}
        style={{}}
        size={'large'}
        content={
          <CardMaterial
            extra={
              <Row>
                <Col span={12}>
                  <div style={{ float: 'right', paddingRight: '10px' }}>
                    <ButtonMaterial
                      disabled={typeButton === 'Add' ? true : false}
                      handleSubmit={handleEditFiture}
                      teksButton={<b>Ubah</b>}
                      size={'small'}
                      shape={'round'}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={
                        typeButton === 'Add'
                          ? {
                              height: '30px',
                              width: '70px',
                              // borderRadius:'12px',
                            }
                          : {
                              colorFont: '#11BEFF',
                              height: '30px',
                              width: '70px',
                              // borderRadius:'12px',
                              background: '#FCC642',
                              borderColor: '#FCC642',
                            }
                      }
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div style={{ float: 'right' }}>
                    <ButtonMaterial
                      disabled={''}
                      handleSubmit={handleBack}
                      teksButton={<b>Kembali</b>}
                      size={''}
                      shape={'round'}
                      className={'btnBackConfig'}
                      type={''}
                      icon={''}
                      style={{}}
                    />
                  </div>
                </Col>
              </Row>
            }
            title={
              <span style={{ color: '#11BEFF' }}>
                <b>{GetCustomerName}</b>
              </span>
            }
            style={{ borderRadius: '10px' }}
            content={
              <div>
                <Row style={{ paddingTop: '10px' }}>
                  <Col span={18}>
                    <div style={{ marginLeft: 30, marginRight: 30 }}>
                      <div style={{ paddingTop: '10px' }}>
                        <Checkbox
                          disabled={typeButton === 'Detail' ? true : false}
                          checked={GetSmsDelivery}
                          onChange={handleCheckboxSmsDelivery}
                        />
                        <span style={{ paddingLeft: '10px' }}>
                          <b>Pengiriman</b>
                        </span>
                        <p className="checkboxNotesConfigFiture">
                          Ceklis jika dibutuhkan SMS ketika kurir mengirimkan
                          dokumen atau barang
                        </p>
                      </div>
                      <div style={{ paddingTop: '10px' }}>
                        <Checkbox
                          disabled={typeButton === 'Detail' ? true : false}
                          checked={GetSmsDelivered}
                          onChange={handleCheckboxSmsDelivered}
                        />
                        <span style={{ paddingLeft: '10px' }}>
                          <b>SMS Terkirim</b>
                        </span>
                        <p className="checkboxNotesConfigFiture">
                          Ceklis jika dibutuhkan SMS ketika dokumen ataupun
                          barang Diterima atau Dikembalikan
                        </p>
                      </div>
                      <div style={{ paddingTop: '10px' }}>
                        <Checkbox
                          disabled={typeButton === 'Detail' ? true : false}
                          checked={GetCall}
                          onChange={handleCheckboxTelepon}
                        />
                        <span style={{ paddingLeft: '10px' }}>
                          <b>Telepon Personal</b>
                        </span>
                        <p className="checkboxNotesConfigFiture">
                          Ceklis bila dibutuhkan untuk kurir dapat menghubungi
                          Pemilik barang / dokumen
                        </p>
                      </div>
                      <div style={{ paddingTop: '10px' }}>
                        <Checkbox
                          disabled={typeButton === 'Detail' ? true : false}
                          checked={IsRadius}
                          onChange={handleCheckboxIsRadius}
                        />
                        <span style={{ paddingLeft: '10px' }}>
                          <b>Radius Lokasi Pengiriman</b>
                        </span>
                        <p className="checkboxNotesConfigFiture">
                          {IsRadius
                            ? 'Jangkauan Radius wajib diisi'
                            : 'Ceklis jika dibutuhkan untuk kurir proses pengiriman di radius lokasi tujuan'}
                        </p>
                        {IsRadius && (
                          <div
                            style={{
                              display: 'flex',
                              gap: 8,
                              alignItems: 'center',
                              paddingLeft: '26px',
                            }}
                          >
                            <p style={{ marginBottom: 0 }}>Radius</p>
                            <InputNumberField
                              maxLength={4}
                              width={80}
                              value={RadiusMeters}
                              onChange={value =>
                                handleChangeRadiusMeters(value)
                              }
                              className="customerRadius"
                              styleInput={{
                                fontWeight: 'bold',
                                textAlign: 'end',
                              }}
                              error={
                                (!RadiusMeters || +RadiusMeters === 0) &&
                                'Radius tidak boleh kurang dari 1 meter'
                              }
                              disabled={!IsRadius || typeButton === 'Detail'}
                            />
                            <p
                              style={{
                                marginBottom: 0,
                                fontWeight: 'bold',
                              }}
                            >
                              Meter
                            </p>
                          </div>
                        )}
                      </div>
                      <div style={{ paddingTop: '10px' }}>
                        <Checkbox
                          disabled={typeButton === 'Detail' ? true : false}
                          checked={IsHistory}
                          onChange={handleCheckboxIsHistory}
                        />
                        <span style={{ paddingLeft: '10px' }}>
                          <b>Tampilkan Riwayat Proses Manual</b>
                        </span>
                        <p className="checkboxNotesConfigFiture">
                          Ceklis jika dibutuhkan untuk melihat riwayat proses
                          manual
                        </p>
                      </div>
                      <div style={{ paddingTop: '10px' }}>
                        <Checkbox
                          disabled={typeButton === 'Detail' ? true : false}
                          checked={IsRequestPhone}
                          onChange={handleCheckboxIsRequestPhone}
                        />
                        <span style={{ paddingLeft: '10px' }}>
                          <b>
                            Permintaan Nomor HP saat konfirmasi Pengembalian di
                            Mobile Apps
                          </b>
                        </span>
                        <p className="checkboxNotesConfigFiture">
                          Ceklis jika dibutuhkan untuk menampilkan Permintaan
                          Nomor HP saat konfirmasi Pengembalian di Mobile Apps
                        </p>
                      </div>
                      <div style={{ paddingTop: '10px' }}>
                        <Checkbox
                          disabled={typeButton === 'Detail' ? true : false}
                          checked={isMonthFilter}
                          onChange={handleChangeIsMonthFilter}
                        />
                        <span style={{ paddingLeft: '10px' }}>
                          <b>
                            {isMonthFilter
                              ? 'Pengaturan Pencarian Portal Pelanggan berdasarkan Bulan Terakhir yang dapat ditampilkan'
                              : 'Pengaturan Pencarian Portal Pelanggan berdasarkan Bulan Terakhir dalam Database'}
                          </b>
                        </span>
                        <p className="checkboxNotesConfigFiture">
                          {isMonthFilter
                            ? 'Atur Pencarian Bulan Terakhir yang ingin ditampilkan.'
                            : 'Ceklis jika dibutuhkan untuk mengatur Pengaturan Pencarian Portal Pelanggan berdasarkan Bulan Terakhir dalam Database'}
                        </p>
                        {isMonthFilter && (
                          <div
                            style={{
                              display: 'flex',
                              gap: 8,
                              alignItems: 'center',
                              paddingLeft: '26px',
                            }}
                          >
                            <InputNumberField
                              maxLength={4}
                              width={80}
                              value={searchMonthFilter}
                              onChange={value =>
                                handleChangeSearchMonthFilter(value)
                              }
                              className="customerRadius"
                              styleInput={{
                                fontWeight: 'bold',
                                textAlign: 'end',
                              }}
                              error={
                                (!searchMonthFilter ||
                                  +searchMonthFilter === 0) &&
                                'Tidak boleh kurang dari 1'
                              }
                              disabled={
                                !isMonthFilter || typeButton === 'Detail'
                              }
                            />
                            <p
                              style={{
                                marginBottom: 0,
                                fontWeight: 'bold',
                              }}
                            >
                              Bulan Terakhir
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div
                      style={{
                        marginLeft: 30,
                        paddingRight: 30,
                        float: 'right',
                      }}
                    >
                      <ButtonMaterial
                        disabled={
                          !RadiusMeters ||
                          +RadiusMeters === 0 ||
                          (isMonthFilter && !searchMonthFilter)
                        }
                        handleSubmit={handleSubmitFiture}
                        teksButton={<b>Simpan</b>}
                        size={'middle'}
                        shape={'round'}
                        className={''}
                        type={'primary'}
                        icon={''}
                        style={
                          typeButton === 'Detail' || ValidateButton === true
                            ? {
                                height: '35px',
                                float: 'right',
                              }
                            : {
                                float: 'right',
                                colorFont: '#11BEFF',
                                height: '35px',
                                background: '#11BEFF',
                                borderColor: '#11BEFF',
                              }
                        }
                      />
                    </div>
                    {typeButton === 'Add' || typeButton === 'Edit' ? (
                      <div style={{ float: 'right' }}>
                        <ButtonMaterial
                          disabled={false}
                          handleSubmit={handleCancel}
                          teksButton={<b>Batal</b>}
                          size={'middle'}
                          shape={'round'}
                          className={''}
                          type={'primary'}
                          icon={''}
                          style={{
                            float: 'right',
                            colorFont: '#11BEFF',
                            height: '35px',
                            width: '80px',
                            // borderRadius:'12px',
                            background: '#FF5858',
                            borderColor: '#FF5858',
                          }}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
              </div>
            }
          />
        }
      />
    </div>
  );
}
