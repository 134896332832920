import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionToogle from '../../../../core/action/toggle';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

export function* S_downloadCustomerReport(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
  };
  const key = 'download';
  const CustomerId = state.Report.CustomerReportForm.CustomerId;
  const CustomerName = state.Report.CustomerReportForm.CustomerName;
  const CustomerReportFileId = state.Report.CustomerReportForm.ReportFormat;
  const FileDataType = state.Report.CustomerReportForm.RadioButton;
  const Month =
    state.Report.CustomerReportForm.DatePickerMonth === true
      ? 'Bulanan'
      : 'Harian';
  const MonthPost = state.Report.CustomerReportForm.DatePickerMonth;
  const PickupDate = moment(state.Report.CustomerReportForm.PickupDate).format(
    Month === 'Bulanan' ? 'YYYYMM' : 'YYYYMMDD'
  );
  const CustomerProduct = state.Report.CustomerReportForm.CustomerProductId;
  const DeliveryStatus = state.Report.CustomerReportForm.DeliveryStatus;
  const TreatmentDocument = state.Report.CustomerReportForm.TreatmentDocument;
  const FileData =
    state.Report.CustomerReportForm.FileData === null
      ? ''
      : state.Report.CustomerReportForm.FileData;
  const IndexColumn =
    state.Report.CustomerReportForm.IndexColumn === null
      ? 0
      : state.Report.CustomerReportForm.IndexColumn;
  const CheckBoxBarcode = state.Report.CustomerReportForm.CheckBoxBarcode;
  const CheckBoxFileName = state.Report.CustomerReportForm.CheckBoxFileName;
  const CheckBoxFullData = state.Report.CustomerReportForm.CheckBoxFullData;
  const CheckBoxToDayData = state.Report.CustomerReportForm.CheckBoxToDayData;
  const TypeDelivery = state.Report.CustomerReportForm.TypeDelivery;
  let DateNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

  const checkStatusSuccess = DeliveryStatus.filter(
    (item: any) => item === 'Success'
  );
  const checkStatusReturn = DeliveryStatus.filter(
    (item: any) => item === 'Return'
  );
  const StatusSuccess =
    checkStatusSuccess.length === 0
      ? []
      : state.Report.CustomerReportForm.StatusSuccess;
  const StatusReturn =
    checkStatusReturn.length === 0
      ? []
      : state.Report.CustomerReportForm.StatusReturn;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const formatEnd = state.Customer.FormCustomerConfigReport.FormatReport;
    const excelFormat = `${'xlsx'}`;
    const csvFormat = `${'csv'}`;
    const txtFormat = `${'txt'}`;
    const formatFile =
      formatEnd === 'Excel' || formatEnd === 'EXCEL'
        ? excelFormat
        : formatEnd === 'CSV'
        ? csvFormat
        : formatEnd === 'TXT'
        ? txtFormat
        : '';
    const file_name = `Laporan ${CustomerName} ${Month} ${PickupDate} ${DateNow}-.${formatFile}`;
    const file_name1 = `Laporan ${CustomerName} ${Month} ${PickupDate} ${DateNow}-.zip`;
    const conditionFileName =
      CheckBoxFileName === false ? file_name : file_name1;

    link.href = url;
    link.setAttribute('download', conditionFileName);
    document.body.appendChild(link);
    link.click();
  }

  try {
    yield put(ActionToogle.changeLoadingTrue());

    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Delivery/DownloadCustomReportCustomer',
      {
        customerId: CustomerId,
        customReportFileId: CustomerReportFileId,
        filterDateType: FileDataType,
        date: PickupDate,
        month: MonthPost,
        customerProducts: CustomerProduct,
        deliveryStats: DeliveryStatus,
        treatmentDocument: TreatmentDocument,
        fileString: FileData,
        coloumn: IndexColumn,
        trueOrFalse: CheckBoxBarcode,
        statusSuccess: StatusSuccess,
        statusReturn: StatusReturn,
        deliveryType: TypeDelivery,
        groupFileName: CheckBoxFileName,
        deliveryDetail: CheckBoxFullData,
        today: CheckBoxToDayData,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);

          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );

    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionToogle.changeLoadingFalse());
  } catch (error) {
    if (error.response.data.Message === 'No delivery found!') {
      message.warning({
        content: 'Laporan Tidak Tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* SM_downloadCustomerReport() {
  yield takeLatest('DOWNLOAD_CUSTOMER_REPORT_FORM', S_downloadCustomerReport);
}
