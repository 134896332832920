export function getValueTotalScan() {
  return {
    type: 'GET_VALUE_TOTAL_SCAN',
  };
}
export function getListScan() {
  return {
    type: 'GET_LIST_SCAN',
  };
}
export function getListUnScan() {
  return {
    type: 'GET_LIST_UN_SCAN',
  };
}
export function GetCourierCodeAndName() {
  return {
    type: 'GET_COURIER_CODE_AND_NAME',
  };
}
export function changeScanByBarcode() {
  return {
    type: 'CHANGE_SCAN_BY_BARCODE',
  };
}
export function clearReceiptNumber() {
  return {
    type: 'CLEAR_RECEIPT_NUMBER_D',
  };
}
export function clearKoliOrWeight() {
  return {
    type: 'CLEAR_KOLI_OR_WEIGHT_D',
  };
}
export function clearListKoli() {
  return {
    type: 'CLEAR_LIST_KOLI_D',
  };
}
export function clearCheckboxKoli() {
  return {
    type: 'CLEAR_CHECKBOX_KOLI_D',
  };
}
export function clearKoli() {
  return {
    type: 'CLEAR_KOLI_D',
  };
}

export function clearCardFilterData() {
  return {
    type: 'CLEAR_CARD_FILTER_DATA',
  };
}
export function clearCardAdditionalData() {
  return {
    type: 'CLEAR_CARD_ADDITIONAL_DATA',
  };
}

export function setCardFilterData(cardFilterData: any) {
  return {
    type: 'SET_CARD_FILTER_DATA',
    cardFilterData,
  };
}
export function setCardAdditionalData(cardAdditionalData: any) {
  return {
    type: 'SET_CARD_ADDITIONAL_DATA',
    cardAdditionalData,
  };
}

export function setVendorBranch(VendorBranch: any) {
  return {
    type: 'SET_VENDOR_BRANCH',
    VendorBranch,
  };
}
export function setReceiptNumber(ReceiptNumber: any) {
  return {
    type: 'SET_RECEIPT_NUMBER',
    ReceiptNumber,
  };
}
export function setCheckbox(Checkbox: any) {
  return {
    type: 'SET_CHECKBOX_D',
    Checkbox,
  };
}
export function setIsReverseBarcode(IsReverseBarcode: any) {
  return {
    type: 'SET_IS_REVERSE_BARCODE_D',
    IsReverseBarcode,
  };
}
export function setCheckboxKoli(CheckboxKoli: any) {
  return {
    type: 'SET_CHECKBOX_KOLI_D',
    CheckboxKoli,
  };
}
export function setWeight(weight: any) {
  return {
    type: 'SET_WEIGHT_D',
    weight,
  };
}
export function setKoli(koli: any) {
  return {
    type: 'SET_KOLI_D',
    koli,
  };
}
export function setListKoli(ListKoli: any) {
  return {
    type: 'SET_LIST_KOLI_D',
    ListKoli,
  };
}
export function setCycleDate(CycleDate: any) {
  return {
    type: 'SET_CYCLE_DATE',
    CycleDate,
  };
}
export function setCustomer(Customer: any) {
  return {
    type: 'SET_CUSTOMER',
    Customer,
  };
}
export function setProductTypeDistribution(ProductTypeDistribution: any) {
  return {
    type: 'SET_PRODUCT_TYPE_DISTRIBUTION',
    ProductTypeDistribution,
  };
}
export function setCustomerProduct(CustomerProduct: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT',
    CustomerProduct,
  };
}
export function setDeliveryType(DeliveryType: any) {
  return {
    type: 'SET_DELIVERY_TYPE',
    DeliveryType,
  };
}
export function setAmplopType(AmplopType: any) {
  return {
    type: 'SET_AMPLOP_TYPE',
    AmplopType,
  };
}
export function setDeliveryDistrictType(DeliveryDistrictType: any) {
  return {
    type: 'SET_DELIVERY_DISTRICT_TYPE',
    DeliveryDistrictType,
  };
}
export function setBranchId(BranchId: any) {
  return {
    type: 'SET_BRANCH_ID',
    BranchId,
  };
}
export function setCourierId(CourierId: any) {
  return {
    type: 'SET_COURIER_ID',
    CourierId,
  };
}
export function setCourierCode(CourierCode: any) {
  return {
    type: 'SET_COURIER_CODE',
    CourierCode,
  };
}
export function setCourierName(CourierName: any) {
  return {
    type: 'SET_COURIER_NAME',
    CourierName,
  };
}
export function setWaybill(Waybill: any) {
  return {
    type: 'SET_WAYBILL',
    Waybill,
  };
}
export function setStatus(Status: any) {
  return {
    type: 'SET_STATUS',
    Status,
  };
}
export function setScan(Scan: any) {
  return {
    type: 'SET_SCAN',
    Scan,
  };
}
export function setUnScan(UnScan: any) {
  return {
    type: 'SET_UN_SCAN',
    UnScan,
  };
}
export function setListScan(ListScan: any) {
  return {
    type: 'SET_LIST_SCAN',
    ListScan,
  };
}
export function setListUnScan(ListUnScan: any) {
  return {
    type: 'SET_LIST_UN_SCAN',
    ListUnScan,
  };
}
export function ClearWaybill() {
  return {
    type: 'CLEAR_WAYBILL',
  };
}
export function ClearCycleDate() {
  return {
    type: 'CLEAR_CYCLE_DATE',
  };
}
export function ClearAllFormDistribution() {
  return {
    type: 'CLEAR_ALL_FORM_DISTRIBUTION',
  };
}
export function ClearCustomerDistribution() {
  return {
    type: 'CLEAR_CUSTOMER_DISTRIBUTION',
  };
}
export function ClearProductTypeDistribution() {
  return {
    type: 'CLEAR_PRODUCT_TYPE_DISTRIBUTION',
  };
}
export function ClearProductDistribution() {
  return {
    type: 'CLEAR_PRODUCT_DISTRIBUTION',
  };
}
export function ClearListScanOrUnScan() {
  return {
    type: 'CLEAR_LIST_SCAN_OR_UNSCAN',
  };
}
export function ClearCourierData() {
  return {
    type: 'CLEAR_COURIER_DATA',
  };
}
