import { Affix, Badge, Col, Icon, Layout, Menu, Row } from 'antd';
import React, { Component } from 'react';
import { iconSidebar } from 'lib/constants';

import { Link } from 'react-router-dom';
import iconCCC from '../../image/iconCCC.png';

//import roles from "../../roles.json"
const { Sider } = Layout;
const { SubMenu } = Menu;

interface IProps {
  collapsed: any;
  router: any;
  routerhistory: any;
  handleCollapsed: any;
  handleActionSelect: any;
  roleAccount: any;
  countingNotif: any;
  countingRequestNotif: any;
  sidebarDatas: Array<any>;
}
export default class SidebarComponent extends Component<IProps> {
  render() {
    const path = this.props.routerhistory.split('/');
    const parentPath = '/' + path[1];

    const getColorSidebar = (item: any) => {
      if (item?.keySubmenus?.includes(parentPath)) {
        return '#1890FF';
      }

      if (parentPath === item.key) {
        return '#1890FF';
      }

      return '#212121';
    };

    const handleActivationMenu = (item: any) => {
      if (item.keySubmenus && item.keySubmenus.length > 0) {
        if (path.length > 3) {
          return item.key;
        }

        return item.keySubmenus.find(
          value => value === this.props.routerhistory
        );
      }

      return item.key;
    };

    const handleSelectionMenu = () => {
      if (
        path.length >= 3 &&
        (parentPath === '/manageLevelAccount' || parentPath === '/role')
      ) {
        return parentPath;
      }
      if (path.length > 3) {
        return parentPath;
      }

      return this.props.routerhistory;
    };

    const props = this.props;

    return (
      <div className={'sidebarDiv'}>
        <Affix offsetTop={1}>
          <Sider
            width="310px"
            theme="light"
            trigger={null}
            collapsible
            collapsed={this.props.collapsed}
          >
            {this.props.collapsed === false ? (
              <Row type="flex" justify="space-around" align="middle">
                <Col span={12} className={'sidebarTitle'}>
                  <Row>
                    <Col span={12} className={'sidebarLayoutImg'}>
                      <img
                        src={iconCCC}
                        alt="Icon CCC"
                        className={'sidebarImg'}
                      />
                    </Col>
                    <Col span={12} style={{ float: 'left' }}>
                      <span className={'sidebarWording'}>
                        Courier <p>Command Center</p>
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={12} style={{ width: '10%' }}>
                  <Icon
                    style={{ fontSize: '23px', float: 'right' }}
                    type={'close'}
                    onClick={this.props.handleCollapsed}
                  />
                </Col>
              </Row>
            ) : (
              <Icon
                className={'sidebarIcon'}
                type={'menu'}
                onClick={this.props.handleCollapsed}
              />
            )}
            <Menu
              className={'listMenuSidebar'}
              theme="light"
              mode="inline"
              selectedKeys={[handleSelectionMenu()]}
            >
              {props.sidebarDatas.map(item =>
                !item.subMenus ? (
                  <Menu.Item
                    key={handleActivationMenu(item)}
                    onClick={() => this.props.handleActionSelect(item.key)}
                    title={item.label}
                    className="sidebar-menu"
                  >
                    <Link
                      to={item.link}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 8,
                      }}
                    >
                      {iconSidebar
                        .find(value => value.key === item.icon)
                        ?.icon(getColorSidebar(item))}
                      {!this.props.collapsed && (
                        <>
                          <span style={{ fontSize: '15px' }}>{item.label}</span>
                          {item.label === 'Keluhan' && (
                            <>
                              {this.props.countingNotif > 0 && (
                                <Badge
                                  count={this.props.countingNotif}
                                  className="complaintNotif"
                                  style={{ marginLeft: 20 }}
                                />
                              )}
                              {this.props.countingRequestNotif > 0 && (
                                <Badge
                                  count={this.props.countingRequestNotif}
                                  style={{
                                    backgroundColor: '#11beef',
                                    marginLeft: 8,
                                  }}
                                  className="complaintNotif"
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Link>
                  </Menu.Item>
                ) : (
                  <SubMenu
                    key={item.key}
                    className="sidebar-submenu"
                    title={
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 8,
                        }}
                      >
                        {iconSidebar
                          .find(value => value.key === item.icon)
                          ?.icon(getColorSidebar(item))}
                        {!this.props.collapsed && (
                          <span style={{ fontSize: '15px' }}>{item.label}</span>
                        )}
                      </div>
                    }
                  >
                    {item.subMenus &&
                      item.subMenus.map(submenu => (
                        <Menu.Item
                          key={submenu.link}
                          onClick={() =>
                            this.props.handleActionSelect(submenu.link)
                          }
                        >
                          <Link to={submenu.link} />
                          <span style={{ fontSize: '15px' }}>
                            ● &nbsp;{submenu.label}
                          </span>
                        </Menu.Item>
                      ))}
                  </SubMenu>
                )
              )}
            </Menu>
          </Sider>
        </Affix>
      </div>
    );
  }
}
