import React, { Component } from 'react'

import ButtonMaterial from '../../../../materials/ButtonMaterial';

interface IProps {
    handleChangeAndDownload:any
}

export default class ButtonDownload extends Component <IProps> {
    render() {
        return (
            <div>
                <ButtonMaterial
                    disabled={''}
                    handleSubmit={this.props.handleChangeAndDownload}
                    teksButton={''}
                    size={''}
                    shape={''}
                    className={''}
                    type={'primary'}
                    icon={'download'}
                    style={{
                    float: 'right',
                    borderRadius: '8px',
                    color: '#fafafa',
                    paddingBottom: '3px',
                    background: '#7956EE',
                    borderColor: '#7956EE',
                    }}
                />
            </div>
        )
    }
}
