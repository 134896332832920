import * as ActionLogin from '../../../module/Login/Store';
import * as ActionTableCustomReportDataCustomer from '../../action/Customer/ActionTableCustomReportDataCustomer';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  changeLoadingFalse,
  changeLoadingTrue,
} from '../../action/toggle/index';

import axios from 'axios';

export function* S_GetTableCustomReportDataCustomer() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
  };
  const IdCustomerReport =
    state.Customer.FormCustomerConfigReport.IdCustomerReport;
  try {
    yield put(changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Setting/CustomReportData/0/10000?customReportFileId=' +
        IdCustomerReport,
      { headers: config }
    );
    yield put(
      ActionTableCustomReportDataCustomer.SetTableCustomReportDataCustomer(
        data.data
      )
    );
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      yield put(changeLoadingFalse());
    }
  }
}
export function* SM_GetTableCustomReportDataCustomer() {
  yield takeLatest(
    'GET_TABLE_CUSTOM_REPORT_DATA_CUSTOMER',
    S_GetTableCustomReportDataCustomer
  );
}
