import * as addCustomerStatus from '../../../core/action/Customer/ActionAddCustomerStatusConfig';
import * as detailCustomStatus from '../../../core/action/Customer/ActionDetailCustomStatus';
import * as editCustomerStatus from '../../../core/action/Customer/ActionEditCustomStatus';
import * as formCustomerStatus from '../../../core/action/Customer/ActionCustomerStatusConfig';
import * as toggle from '../../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import FormCustomerStatusConfigComponent from '../../../components/Content/Customer/FormCustomerStatusConfigComponent';
import { connect } from 'react-redux';

interface IProps {
  actionToggle: any;
  visible: any;
  jenisToggle: any;
  actionFormCustomerStatus: any;
  actionAddCustomerStatus: any;
  actionEditCustomerStatus: any;
  actionDetailCustomStatus: any;

  GetStatusPosition: any;
  GetStatusCode: any;
  GetStatusDescription: any;
  GetStatusLabel: any;
  GetStatusAttempt: any;
  GetStatusYBS: any;
  GetStatusFreeText: any;
  ReasonDeliveryStatus: any;

  isLoading: any;
}

interface IState {
  ValidateCustomerStatusPosition: any;
  ValidateCustomerStatusCode: any;
  ValidateCustomerStatusDescription: any;
  ValidateCustomerStatusLabel: any;
}

class FormCustomerStatusConfigContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateCustomerStatusPosition: null,
      ValidateCustomerStatusCode: null,
      ValidateCustomerStatusDescription: null,
      ValidateCustomerStatusLabel: null,
    };
    this.handleClearFormCustomerStatus =
      this.handleClearFormCustomerStatus.bind(this);
    this.handlePutCustomerStatusPosition =
      this.handlePutCustomerStatusPosition.bind(this);
    this.handlePutCustomerStatusDescription =
      this.handlePutCustomerStatusDescription.bind(this);
    this.handlePutCustomerStatusLabel =
      this.handlePutCustomerStatusLabel.bind(this);
    this.handlePutCustomerStatusCode =
      this.handlePutCustomerStatusCode.bind(this);
    this.handleSubmitCustomerStatus =
      this.handleSubmitCustomerStatus.bind(this);
    this.handleEditCustomerStatus = this.handleEditCustomerStatus.bind(this);
    this.handlePutCustomerStatusAttempt =
      this.handlePutCustomerStatusAttempt.bind(this);
    this.handlePutCustomerStatusFreeText =
      this.handlePutCustomerStatusFreeText.bind(this);
    this.handlePutCustomerStatusYBS =
      this.handlePutCustomerStatusYBS.bind(this);
  }

  handleClearFormCustomerStatus() {
    this.props.actionToggle.changeModal();
    this.props.actionToggle.clearJenisToggle();
    this.props.actionFormCustomerStatus.changeClearStatusConfigForm();
    this.props.actionDetailCustomStatus.changeClearIdStatus();
    this.setState({
      ValidateCustomerStatusPosition: '',
      ValidateCustomerStatusCode: '',
      ValidateCustomerStatusDescription: '',
      ValidateCustomerStatusLabel: '',
    });
  }

  handlePutCustomerStatusPosition(event: any) {
    const number = parseInt(event.target.value || 0, 10);
    const RegexNumber = /^[0-9\b]+$/;

    if (isNaN(number)) {
      return;
    }

    if (!('value' in this.props)) {
      this.props.actionFormCustomerStatus.changePosition(
        event.target.value.replace(/^0+/, '')
      );
    }

    if (event.target.value === '') {
      this.setState({
        ValidateCustomerStatusPosition: 'error',
      });
    } else if (!RegexNumber.test(event.target.value)) {
      this.setState({
        ValidateCustomerStatusPosition: 'NonNumeric',
      });
    } else {
      this.setState({
        ValidateCustomerStatusPosition: '',
      });
    }
  }

  handlePutCustomerStatusDescription(event: any) {
    this.props.actionFormCustomerStatus.changeDescription(event.target.value);
    const RegexMinChar = /^.{2,}$/;
    if (event.target.value === '') {
      this.setState({
        ValidateCustomerStatusDescription: 'error',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        ValidateCustomerStatusDescription: 'minchar',
      });
    } else {
      this.setState({
        ValidateCustomerStatusDescription: '',
      });
    }
  }

  handlePutCustomerStatusLabel(event: any) {
    this.props.actionFormCustomerStatus.changeLabel(event.target.value);
    const RegexMinChar = /^.{2,}$/;
    if (event.target.value === '') {
      this.setState({
        ValidateCustomerStatusLabel: 'error',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        ValidateCustomerStatusLabel: 'minchar',
      });
    } else {
      this.setState({
        ValidateCustomerStatusLabel: '',
      });
    }
  }

  handlePutCustomerStatusCode(event: any) {
    const RegexNoSpace = /^[^\s]+$/;
    // const RegexMinChar = /^.{2,}$/;
    if (!('value' in this.props)) {
      // ==dibawah ini regex untuk tidak menggunakan spasi di awal saja==
      // this.props.actionFormCustomerStatus.changeCode(event.target.value.replace(/^\s/, '').toUpperCase())
      // ==diatas ini regex untuk tidak menggunakan spasi di awal saja==

      // ==dibawah ini regex untuk tidak menggunakan spasi dimana saja==
      this.props.actionFormCustomerStatus.changeCode(
        event.target.value.replace(/\s/g, '').toUpperCase()
      );
      // ==diatas ini regex untuk tidak menggunakan spasi dimana saja==
    }
    if (event.target.value === '') {
      this.setState({
        ValidateCustomerStatusCode: 'error',
      });
    } else if (!RegexNoSpace.test(event.target.value)) {
      this.setState({
        ValidateCustomerStatusCode: 'NoSpace',
      });
    } else {
      this.setState({
        ValidateCustomerStatusCode: '',
      });
    }
  }

  handlePutCustomerStatusAttempt(event: any) {
    this.props.actionFormCustomerStatus.changeAttempt(event);
  }

  handlePutCustomerStatusFreeText(event: any) {
    this.props.actionFormCustomerStatus.changeFreeText(event);
  }

  handlePutCustomerStatusYBS(event: any) {
    this.props.actionFormCustomerStatus.changeYBS(event);
    if (event === true) {
      this.props.actionFormCustomerStatus.changeFreeText(false);
    }
  }

  handleSubmitCustomerStatus() {
    this.props.actionAddCustomerStatus.addCustomerStatus();
  }

  handleEditCustomerStatus() {
    this.props.actionEditCustomerStatus.submitEditStatus();
  }
  render() {
    return (
      <div>
        <FormCustomerStatusConfigComponent
          visible={
            (this.props.visible === true &&
              this.props.jenisToggle === 'AddCustomStatus') ||
            (this.props.visible === true &&
              this.props.jenisToggle === 'EditStatus')
              ? true
              : false
          }
          jenisToggle={this.props.jenisToggle}
          ValidateButton={
            this.props.GetStatusPosition === null ||
            this.props.GetStatusPosition === '' ||
            this.state.ValidateCustomerStatusPosition === 'NonNumeric' ||
            this.props.GetStatusCode === null ||
            this.props.GetStatusCode === '' ||
            this.state.ValidateCustomerStatusCode === 'NoSpace' ||
            this.props.GetStatusDescription === null ||
            this.props.GetStatusDescription === '' ||
            this.props.GetStatusLabel === null ||
            this.props.GetStatusLabel === '' ||
            this.state.ValidateCustomerStatusLabel === 'minchar' ||
            this.state.ValidateCustomerStatusLabel === 'error' ||
            this.state.ValidateCustomerStatusDescription === 'minchar' ||
            this.state.ValidateCustomerStatusDescription === 'error'
              ? true
              : false
          }
          handleClearFormCustomerStatus={this.handleClearFormCustomerStatus}
          PutCustomerStatusPosition={this.handlePutCustomerStatusPosition}
          ValidateCustomerStatusPosition={
            this.state.ValidateCustomerStatusPosition === 'error'
              ? 'error'
              : '' || this.state.ValidateCustomerStatusPosition === 'NonNumeric'
              ? 'error'
              : ''
          }
          helpCustomerStatusPosition={
            this.state.ValidateCustomerStatusPosition === 'error'
              ? 'Posisi wajib diisi'
              : '' || this.state.ValidateCustomerStatusPosition === 'NonNumeric'
              ? 'Tidak boleh selain angka!'
              : ''
          }
          GetStatusPosition={this.props.GetStatusPosition}
          PutCustomerStatusCode={this.handlePutCustomerStatusCode}
          ValidateCustomerStatusCode={
            this.state.ValidateCustomerStatusCode === 'error'
              ? 'error'
              : '' || this.state.ValidateCustomerStatusCode === 'minchar'
              ? 'error'
              : '' || this.state.ValidateCustomerStatusCode === 'NoSpace'
              ? 'error'
              : ''
          }
          helpCustomerStatusCode={
            this.state.ValidateCustomerStatusCode === 'error'
              ? 'Kode wajib diisi'
              : '' || this.state.ValidateCustomerStatusCode === 'minchar'
              ? 'Kode harus lebih dari 1 karakter'
              : '' || this.state.ValidateCustomerStatusCode === 'NoSpace'
              ? 'Tidak boleh spasi / spesial karakter'
              : ''
          }
          GetStatusCode={this.props.GetStatusCode}
          PutCustomerStatusDescription={this.handlePutCustomerStatusDescription}
          PutCustomerStatusLabel={this.handlePutCustomerStatusLabel}
          ValidateCustomerStatusDescription={
            this.state.ValidateCustomerStatusDescription === 'error'
              ? 'error'
              : '' || this.state.ValidateCustomerStatusDescription === 'minchar'
              ? 'error'
              : ''
          }
          helpCustomerStatusDescription={
            this.state.ValidateCustomerStatusDescription === 'error'
              ? 'Deskripsi wajib diisi'
              : '' || this.state.ValidateCustomerStatusDescription === 'minchar'
              ? 'Deskripsi harus lebih dari 1 karakter'
              : ''
          }
          handlePutCustomerStatusAttempt={this.handlePutCustomerStatusAttempt}
          GetStatusAttempt={this.props.GetStatusAttempt}
          GetStatusDescription={this.props.GetStatusDescription}
          GetStatusLabel={this.props.GetStatusLabel}
          ValidateCustomerStatusLabel={
            this.state.ValidateCustomerStatusLabel === 'error'
              ? 'error'
              : '' || this.state.ValidateCustomerStatusLabel === 'minchar'
              ? 'error'
              : ''
          }
          helpCustomerStatusLabel={
            this.state.ValidateCustomerStatusLabel === 'error'
              ? 'Label wajib diisi'
              : '' || this.state.ValidateCustomerStatusLabel === 'minchar'
              ? 'Label harus lebih dari 1 karakter'
              : ''
          }
          handlePutCustomerStatusFreeText={this.handlePutCustomerStatusFreeText}
          GetStatusFreeText={this.props.GetStatusFreeText}
          handlePutCustomerStatusYBS={this.handlePutCustomerStatusYBS}
          GetStatusYBS={this.props.GetStatusYBS}
          ReasonDeliveryStatus={
            this.props.ReasonDeliveryStatus === 'Success'
              ? 'DITERIMA'
              : 'DIKEMBALIKAN'
          }
          isLoading={this.props.isLoading}
          handleSubmitCustomerStatus={this.handleSubmitCustomerStatus}
          handleEditCustomerStatus={this.handleEditCustomerStatus}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  jenisToggle: state.Toggle.jenisToggle,
  GetStatusPosition: state.Customer.FormCustomCustomerStatus.StatusPosition,
  GetStatusCode: state.Customer.FormCustomCustomerStatus.StatusCode,
  GetStatusDescription:
    state.Customer.FormCustomCustomerStatus.StatusDescription,
  GetStatusLabel: state.Customer.FormCustomCustomerStatus.StatusLabel,
  GetStatusAttempt: state.Customer.FormCustomCustomerStatus.StatusAttempt,
  GetStatusFreeText: state.Customer.FormCustomCustomerStatus.StatusFreeText,
  GetStatusYBS: state.Customer.FormCustomCustomerStatus.StatusYBS,
  ReasonDeliveryStatus: state.Customer.PropertiesCustomer.ReasonDeliveryStatus,
  isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(toggle, dispatch),
  actionFormCustomerStatus: bindActionCreators(formCustomerStatus, dispatch),
  actionAddCustomerStatus: bindActionCreators(addCustomerStatus, dispatch),
  actionEditCustomerStatus: bindActionCreators(editCustomerStatus, dispatch),
  actionDetailCustomStatus: bindActionCreators(detailCustomStatus, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FormCustomerStatusConfigContainer);
