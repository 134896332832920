export function getDefaultDataStatus()
{
    return({
        type:"GET_DEFAULT_DATA_STATUS"
    })
}
export function getCustomDataStatus()
{
    return({
        type:"GET_CUSTOM_DATA_STATUS"
    })
}
export function setDefaultDataStatus(DefaultDataStatus:any)
{
    return({
        type:"SET_DEFAULT_DATA_STATUS",
        DefaultDataStatus
    })
}
export function setCustomDataStatus(CustomDataStatus:any)
{
    return({
        type:"SET_CUSTOM_DATA_STATUS",
        CustomDataStatus
    })
}
export function clearDefaultDataStatus()
{
    return({
        type:"CLEAR_DEFAULT_DATA_STATUS"
    })
}
export function clearCustomDataStatus()
{
    return({
        type:"CLEAR_CUSTOM_DATA_STATUS"
    })
}