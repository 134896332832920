import * as ActionAuth from '../../action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as auth from '../../action/Auth/index';
import * as profile from '../../action/Profile/ActionProfile';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_detailProfile(action: any) {
  const state = yield select();
  const username = state.Auth.UsernameAuth;
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Profile/ProfileAccountGet/' + username,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    const fullname = data.firstName + ' ' + data.lastName;
    const shortname = data.firstName.toUpperCase();
    const name =
      data.lastName === null ||
      data.lastName === '' ||
      data.lastName === undefined
        ? shortname
        : fullname;
    var initials = name.match(/\b\w/g) || [];
    const getInit =
      (initials.shift() || '') + (initials.pop() || '').toUpperCase();

    yield put(auth.changePhotoAuth(data.photo));
    yield put(auth.changeInitialsAuth(getInit.toUpperCase()));

    yield put(profile.setPhotoProfile(data.photo));
    yield put(profile.setInitialsProfile(getInit.toUpperCase()));
    yield put(profile.setFirstNameProfile(data.firstName));
    yield put(profile.setLastNameProfile(data.lastName));
    yield put(profile.setUserNameProfile(data.code));
    yield put(profile.setNoTelpProfile(data.phone));
    yield put(profile.setEmailProfile(data.email));
    yield put(profile.setAddressProfile(data.address));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_detailProfile() {
  yield takeLatest('GET_DATA_PROFILE', S_detailProfile);
}
