export function changeRadioButtonCBForm(RadioButton:any)
{
    return({
        type:"CHANGE_RADIOBUTTON_CB_REPORT_FORM",
        RadioButton
    })
}
export function changeFirstDateCBForm(FirstDate:any)
{
    return({
        type:"CHANGE_FIRST_DATE_CB_REPORT_FORM",
        FirstDate
    })
}
export function changeLastDateCBForm(LastDate:any)
{
    return({
        type:"CHANGE_LAST_DATE_CB_REPORT_FORM",
        LastDate
    })
}
export function changeBranchCBForm(Branch:any)
{
    return({
        type:"CHANGE_BRANCH_CB_REPORT_FORM",
        Branch
    })
}
export function changeCourierCBForm(Courier:any)
{
    return({
        type:"CHANGE_COURIER_CB_REPORT_FORM",
        Courier
    })
}
export function changeCourierCodeCBForm(CourierCode:any)
{
    return({
        type:"CHANGE_COURIER_CODE_CB_REPORT_FORM",
        CourierCode
    })
}
export function downloadReportCourierBonus()
{
    return({
        type:"DOWNLOAD_REPORT_COURIER_BONUS",
    })
}
export function clearCourierCBForm()
{
    return({
        type:"CLEAR_COURIER_CB_REPORT_FORM",
    })
}

export function clearCourierBonusFormExceptRadiobutton()
{
    return({
        type:"CLEAR_COURIER_BONUS_REPORT_FROM_EXCEPT_RADIOBUTTON"
    })
}

export function clearCourierBonusBranchFormExceptRadiobutton()
{
    return({
        type:"CLEAR_COURIER_BONUS_BRANCH_REPORT_FROM_EXCEPT_RADIOBUTTON"
    })
}

export function clearCourierBonusForm()
{
    return({
        type:"CLEAR_COURIER_BONUS_REPORT_FROM"
    })
}

export function clearCourierBonusBranchForm()
{
    return({
        type:"CLEAR_COURIER_BONUS_BRANCH_REPORT_FROM"
    })
}