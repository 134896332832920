import { createSelector } from 'reselect';

const selectorData = (state: any) => state.GeneralDataListState;


// SELECTOR GENERAL DATA
export const makeBranchListSelector = () =>
  createSelector(selectorData, state => state.branchList);
export const makeCustomerListSelector = () =>
  createSelector(selectorData, state => state.customerList);
export const makeUserListSelector = () =>
  createSelector(selectorData, state => state.userList);
export const makeLogActivityListSelector = () =>
  createSelector(selectorData, state => state.logActivityList);

// SELECTOR LOADING
export const makeIsLoadingGetCustomer = () =>
  createSelector(selectorData, state => state.loadingGetCustomer);
export const makeIsLoadingGetBranch = () =>
  createSelector(selectorData, state => state.loadingGetBranch);
export const makeIsLoadingGetFormatCsv = () =>
  createSelector(selectorData, state => state.loadingGetFormatCsv);
export const makeIsLoadingGetCourier = () =>
  createSelector(selectorData, state => state.loadingGetCourier);
export const makeIsLoadingGetCustomerProduct = () =>
  createSelector(selectorData, state => state.loadingGetCustomerProduct);